import countries from '../countries_fr.json';

export enum EnumContinentCode {
  AF = 'AF',
  NA = 'NA',
  OC = 'OC',
  AN = 'AN',
  AS = 'AS',
  EU = 'EU',
  SA = 'SA',
  RU = 'RU',
  SC = 'SC',
  UA = 'UA'
}

export const getContinentLabel = (continentCode: EnumContinentCode): string => {
  switch (continentCode) {
    default:
      return continentCode;
    case EnumContinentCode.AF:
      return 'Afrique';
    case EnumContinentCode.NA:
      return 'Amérique du nord';
    case EnumContinentCode.OC:
      return 'Océanie';
    case EnumContinentCode.AN:
      return 'Antarctique';
    case EnumContinentCode.AS:
      return 'Asie';
    case EnumContinentCode.EU:
      return 'Europe';
    case EnumContinentCode.SA:
      return 'Amérique du sud';
    case EnumContinentCode.RU:
      return 'Russie';
    case EnumContinentCode.SC:
      return 'Scandinavie';
    case EnumContinentCode.UA:
      return 'Ukraine';
  }
};

export const getCountryLabel = (countryCode: string | null): string => {
  if (countryCode && countries[countryCode as keyof typeof countries]) {
    return countries[countryCode as keyof typeof countries];
  }

  return '';
};
