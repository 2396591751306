import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../../services/remote.service';
import { AclService } from '../../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { ISimulateurAircraftModel } from 'src/app/interfaces/simulateur-aircraft-model.interface';

@Component({
  selector: 'app-simulateur-aircraft-model-view',
  templateUrl: './simulateur-aircraft-model-view.component.html',
  styleUrls: ['./simulateur-aircraft-model-view.component.scss']
})
export class SimulateurAircraftModelViewComponent implements OnInit {
  loading: boolean = false;
  simulateurAircraftModelId: string;
  simulateurAircraftModel: ISimulateurAircraftModel | null = null;
  isLogged: boolean = false;

  EnumAcl = EnumAcl;

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private aclService: AclService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.simulateurAircraftModelId = this.activatedRoute.snapshot.paramMap.get(
        'simulateurAircraftModelId'
      );

      this.loadData();
    });
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadSimulateurAircraftModel();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadSimulateurAircraftModel(): Promise<void> {
    this.loading = true;

    const doc = await this.remoteService.getDocument(
      'simulateurAircraftModels',
      this.simulateurAircraftModelId
    );

    this.simulateurAircraftModel = doc as ISimulateurAircraftModel;

    this.loading = false;
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.simulateurAircraftModelsDelete)) {
      const result = confirm(
        "La suppression du modèle d'avion sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        this.remoteService
          .deleteDocumentInCollection('simulateurAircraftModels', this.simulateurAircraftModelId)
          .then(() => {
            alert('La suppression a été effectuée avec succès.');

            this.router.navigate(['/admin/simulateur-aircraft-models/list']);
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  isNaN(val: any): boolean {
    return isNaN(val);
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  formatPrice(value: number, currency: string = 'EUR'): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }
}
