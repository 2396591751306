export const getEnumTvaRateLabel = (tvaRate: EnumTvaRate): string => {
  switch (tvaRate) {
    default:
      return parseFloat(tvaRate) + '%';
    case EnumTvaRate.other:
      return 'Autre';
  }
};

export enum EnumTvaRate {
  'zero' = '0',
  'five' = '5.5',
  'ten' = '10',
  'twenty' = '20',
  'other' = 'other'
}

export const getTvaRateSelected = (value: number): EnumTvaRate => {
  for (const tvaRate of Object.values(EnumTvaRate)) {
    if (tvaRate !== EnumTvaRate.other && value === parseFloat(tvaRate)) {
      return tvaRate;
    }
  }

  return EnumTvaRate.other;
};
