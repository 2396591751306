import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { AircraftModelFirestoreService } from './aircraft-models-firestore.service';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';

@Injectable({
  providedIn: 'root'
})
export class AircraftModelService {
  constructor(private aircraftModelFirestoreService: AircraftModelFirestoreService) {}

  create(aircraftModel: IAircraftModel): Promise<string> {
    return this.aircraftModelFirestoreService.create(aircraftModel);
  }

  update(aircraftModel: IAircraftModel): Promise<string> {
    return this.aircraftModelFirestoreService.update(aircraftModel);
  }

  delete(id: string): Promise<void> {
    return this.aircraftModelFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IAircraftModel> {
    return this.aircraftModelFirestoreService.docValue$(id);
  }

  getAll(): Observable<IAircraftModel[]> {
    return this.aircraftModelFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.aircraftModelFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IAircraftModel[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.aircraftModelFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }
}
