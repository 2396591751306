import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';

@Component({
  selector: 'app-encaissement-type-edit',
  templateUrl: './encaissement-type-edit.component.html',
  styleUrls: ['./encaissement-type-edit.component.scss']
})
export class EncaissementTypeEditComponent implements OnInit {
  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  encaissementType: IEncaissementType | null = null;
  encaissementTypeId: string;

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]]
    });

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.encaissementTypeId = this.activatedRoute.snapshot.paramMap.get('encaissementTypeId');

      if (this.encaissementTypeId) {
        await this.aclService.checkAclAccess(EnumAcl.encaissementTypesEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.encaissementTypesAdd);
        this.form.enable();
      }
    });
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadEncaissementType();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadEncaissementType(): Promise<void> {
    const doc = await this.remoteService.getDocument('encaissementTypes', this.encaissementTypeId);

    this.encaissementType = doc as IEncaissementType;

    this.setEncaissementType();
  }

  setEncaissementType(): void {
    if (this.form && this.encaissementType) {
      this.form.get('name').setValue(this.encaissementType.name);

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.status == 'VALID') {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      if (this.encaissementTypeId) {
        this.remoteService
          .updateDocumentToCollection('encaissementTypes', this.encaissementTypeId, data)
          .then(() => {
            this.sending = false;
            this.redirectAfterSaving();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      } else {
        this.remoteService
          .addDocumentToCollection('encaissementTypes', data)
          .then((docId: string) => {
            this.encaissementTypeId = docId;

            this.sending = false;
            this.redirectAfterSaving();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      }
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/encaissement-types/view/' + this.encaissementTypeId]);
  }
}
