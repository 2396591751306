import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { AircraftService } from 'src/app/services/aircrafts/aircrafts.service';
import { Subscription } from 'rxjs';
import { IAircraft, getAircraftAge } from 'src/app/interfaces/aircraft.interface';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { getAircraftStatusLabel } from 'src/app/enums/aircraft-status.enum';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { AircraftModelService } from 'src/app/services/aircraft-models/aircraft-models.service';

@Component({
  selector: 'app-aircraft-view',
  templateUrl: './aircraft-view.component.html',
  styleUrls: ['./aircraft-view.component.scss']
})
export class AircraftViewComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  getAircraftStatusLabel = getAircraftStatusLabel;
  getAircraftAge = getAircraftAge;

  loading: boolean = false;
  loadingAirline: boolean = false;
  loadingAircraftModel: boolean = false;

  aircraftId: string;
  aircraft: IAircraft | null = null;

  airline: IAirline | null = null;
  aircraftModel: IAircraftModel | null = null;

  isLogged: boolean = false;

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private aclService: AclService,
    private aircraftService: AircraftService,
    private airlineService: AirlineService,
    private aircraftModelService: AircraftModelService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.aircraftId = this.activatedRoute.snapshot.paramMap.get('aircraftId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadAircraft();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadAircraft(): void {
    this.loading = true;

    this.subscriptions.add(
      this.aircraftService.getFromId(this.aircraftId).subscribe(async (aircraft: IAircraft) => {
        this.loading = true;

        this.aircraft = aircraft;

        if (this.aircraft.airlineId) {
          this.loadAirline(this.aircraft.airlineId);
        }
        if (this.aircraft.aircraftModelId) {
          this.loadAircraftModel(this.aircraft.aircraftModelId);
        }

        this.loading = false;
      })
    );
  }

  loadAirline(airlineId: string): void {
    this.loadingAirline = true;

    this.subscriptions.add(
      this.airlineService.getFromId(airlineId).subscribe((airline: IAirline) => {
        this.loadingAirline = true;

        this.airline = airline;

        this.loadingAirline = false;
      })
    );
  }

  loadAircraftModel(aircraftModelId: string): void {
    this.loadingAircraftModel = true;

    this.subscriptions.add(
      this.aircraftModelService
        .getFromId(aircraftModelId)
        .subscribe((aircraftModel: IAircraftModel) => {
          this.loadingAircraftModel = true;

          this.aircraftModel = aircraftModel;

          this.loadingAircraftModel = false;
        })
    );
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.aircraftsDelete)) {
      const result = confirm(
        "La suppression de l'avion sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        this.aircraftService
          .delete(this.aircraftId)
          .then(() => {
            alert('La suppression a été effectuée avec succès.');

            this.router.navigate(['/admin/aircrafts/list']);
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
