import { EnumCurrency } from './enums/currency.enum';
import { EnumEnquiryTarget } from './enums/enquiry-target.enum';
import { EnumLanguage } from './enums/language.enum';
import { IAircraftModel } from './interfaces/aircraft-model.interface';
import { IAirport } from './interfaces/airport.interface';
import countries from './countries_fr.json';
import continents from '../assets/json/continents.json';
import { formatInTimeZone } from 'date-fns-tz';

export const addZeroToDigit = (value: number, totalLength: number = 2): string => {
  return String(value).padStart(totalLength, '0');
};

export const getQuarterNumberAccordingToMonth = (monthNumber: number): number => {
  return Math.ceil(monthNumber / 3);
};

export const getMonthLabelByQuarter = (quarterNumber: 1 | 2 | 3 | 4 | number): string => {
  switch (quarterNumber) {
    case 1:
      return 'T1';
    case 2:
      return 'T2';
    case 3:
      return 'T3';
    case 4:
      return 'T4';
    default:
      return 'T?';
  }
};

export const monthsLabel: { [language: string]: string[] } = {
  fr: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  es: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  de: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ]
};

export const monthsShortLabel: { [language: string]: string[] } = {
  fr: ['jan', 'fev', 'mar', 'avr', 'mai', 'jui', 'jui', 'aou', 'sept', 'oct', 'nov', 'dec'],
  en: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'kul', 'aug', 'sept', 'oct', 'nov', 'dec'],
  es: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  de: ['jan', 'feb', 'mär', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dez']
};

export const getMonthLabel = (
  monthNumber: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | number
): string => {
  switch (monthNumber) {
    case 1:
      return 'Janvier';
    case 2:
      return 'Février';
    case 3:
      return 'Mars';
    case 4:
      return 'Avril';
    case 5:
      return 'Mai';
    case 6:
      return 'Juin';
    case 7:
      return 'Juillet';
    case 8:
      return 'Août';
    case 9:
      return 'Septembre';
    case 10:
      return 'Octobre';
    case 11:
      return 'Novembre';
    case 12:
      return 'Décembre';
    default:
      return 'Mois : ?';
  }
};

export const formatPrice = (
  value: number,
  currency: EnumCurrency,
  minimumFractionDigits: number = 2,
  forcePlusSign: boolean = false
): string => {
  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency,
    minimumFractionDigits
  });

  return forcePlusSign && value > 0 ? '+' + formatter.format(value) : formatter.format(value);
};

export const roundNumber = (value: number, decimals: number = 2): number => {
  return parseFloat(value.toFixed(decimals));
};

export const roundNumberWithPlusSign = (value: number): string => {
  const result: number = roundNumber(value);

  return result > 0 ? '+' + result.toString() : result.toString();
};

export const convertKtsToKmH = (value: number): number => {
  return value * 1.852;
};

export const degreesToRadians = (degrees: number): number => {
  return (degrees * Math.PI) / 180;
};

export const getDistanceBetweenPoints = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
): number => {
  // The radius of the planet earth in meters
  const R = 6371; // Radius of the earth in km
  const dLat = degreesToRadians(lat2 - lat1); // deg2rad below
  const dLon = degreesToRadians(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(lat1)) *
      Math.cos(degreesToRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km

  return d;
};

export const estimatedFlyTimeInHours = (
  aircraftModel: IAircraftModel,
  airportDepart: IAirport,
  airportArrival: IAirport
): number => {
  let distanceInKm: number;
  let speedInKts: number = 370;
  let flyTimeInHours: number = 0;

  distanceInKm = getDistanceBetweenPoints(
    airportDepart.latitude,
    airportDepart.longitude,
    airportArrival.latitude,
    airportArrival.longitude
  );

  if (aircraftModel.speedInKts) {
    flyTimeInHours = distanceInKm / convertKtsToKmH(speedInKts);
  } else {
    // Default speed
    if (aircraftModel.target === EnumEnquiryTarget.helico) {
      speedInKts = 97;
    } else {
      speedInKts = 370;
    }

    flyTimeInHours = distanceInKm / convertKtsToKmH(speedInKts);

    if (flyTimeInHours > 7) {
      // If flight is more than 7 hours, plane fly faster
      speedInKts = 450;
      flyTimeInHours = distanceInKm / convertKtsToKmH(speedInKts);
    }
  }

  flyTimeInHours += 0.25; // Add 15 minutes of takeoff

  return flyTimeInHours;
};

export const estimatedFlyTimeInMinutes = (
  aircraftModel: IAircraftModel,
  airportDepart: IAirport,
  airportArrival: IAirport
): number => {
  const flyTimeInHours: number = estimatedFlyTimeInHours(
    aircraftModel,
    airportDepart,
    airportArrival
  );

  return flyTimeInHours * 60;
};

export const estimatedFlyTimeInTimeFormat = (
  aircraftModel: IAircraftModel,
  airportDepart: IAirport,
  airportArrival: IAirport
): string => {
  const flyTimeInHours: number = estimatedFlyTimeInHours(
    aircraftModel,
    airportDepart,
    airportArrival
  );

  return convertHourToTime(flyTimeInHours);
};

export const convertHourToTime = (value: number): string => {
  let totalMinutes = value * 60;
  let hours = totalMinutes / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);

  return addZeroToDigit(rhours) + ':' + addZeroToDigit(rminutes);
};

export const convertMinutesToTime = (
  value: number,
  separatorHour: string = ':',
  separatorMin: string = ''
): string => {
  let hours = value / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);

  return addZeroToDigit(rhours) + separatorHour + addZeroToDigit(rminutes) + separatorMin;
};

export const chunk = (array: any[], size: number): any[][] => {
  const chunked_arr: any[][] = [];

  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }

  return chunked_arr;
};

export const formatDateFromStr = (
  dateStr: string,
  language: EnumLanguage = EnumLanguage.fr
): Date | null => {
  dateStr = dateStr
    .replaceAll('/', '-')
    .replaceAll(' ', '-')
    .replaceAll('.', '-')
    .replaceAll(',', '')
    .replaceAll('--', '-')
    .toLocaleLowerCase();

  // Replace month by number
  for (let i = 0; i < monthsLabel[language].length; i++) {
    dateStr = dateStr.replaceAll(
      monthsLabel[language][i].toLocaleLowerCase(),
      addZeroToDigit(i + 1)
    );
    dateStr = dateStr.replaceAll(
      monthsShortLabel[language][i].toLocaleLowerCase(),
      addZeroToDigit(i + 1)
    );
  }

  // Remove days suffixes
  for (const suffix of ['st', 'nd', 'rd', 'th']) {
    dateStr = dateStr.replaceAll(suffix, '');
  }

  const dateStrSplitted: string[] = dateStr.split('-');

  if (dateStrSplitted.length === 3) {
    if (
      dateStrSplitted[0].length === 4 &&
      dateStrSplitted[1].length === 2 &&
      dateStrSplitted[2].length === 2
    ) {
      return new Date(dateStr);
    } else if (
      dateStrSplitted[0].length === 2 &&
      dateStrSplitted[1].length === 2 &&
      dateStrSplitted[2].length === 4
    ) {
      if (parseInt(dateStrSplitted[1]) > 12) {
        return new Date(dateStrSplitted[2] + '-' + dateStrSplitted[0] + '-' + dateStrSplitted[1]);
      } else {
        return new Date(dateStrSplitted[2] + '-' + dateStrSplitted[1] + '-' + dateStrSplitted[0]);
      }
    } else if (dateStrSplitted[0].length === 4) {
      return new Date(
        dateStrSplitted[0] +
          '-' +
          addZeroToDigit(parseInt(dateStrSplitted[1])) +
          '-' +
          addZeroToDigit(parseInt(dateStrSplitted[2]))
      );
    } else if (dateStrSplitted[2].length === 4) {
      if (parseInt(dateStrSplitted[1]) > 12) {
        return new Date(
          dateStrSplitted[2] +
            '-' +
            addZeroToDigit(parseInt(dateStrSplitted[0])) +
            '-' +
            addZeroToDigit(parseInt(dateStrSplitted[1]))
        );
      } else {
        return new Date(
          dateStrSplitted[2] +
            '-' +
            addZeroToDigit(parseInt(dateStrSplitted[1])) +
            '-' +
            addZeroToDigit(parseInt(dateStrSplitted[0]))
        );
      }
    } else if (
      [1, 2].includes(dateStrSplitted[1].length) &&
      [1, 2].includes(dateStrSplitted[0].length) &&
      [2, 4].includes(dateStrSplitted[2].length)
    ) {
      let date: Date = new Date(
        (dateStrSplitted[2].length === 2 ? '20' + dateStrSplitted[2] : dateStrSplitted[2]) +
          '-' +
          addZeroToDigit(parseInt(dateStrSplitted[0])) +
          '-' +
          addZeroToDigit(parseInt(dateStrSplitted[1]))
      );

      if (!isNaN(date.getTime())) {
        return date;
      } else {
        // Error formatting date. It means month and day are reversed
        return new Date(
          (dateStrSplitted[2].length === 2 ? '20' + dateStrSplitted[2] : dateStrSplitted[2]) +
            '-' +
            addZeroToDigit(parseInt(dateStrSplitted[1])) +
            '-' +
            addZeroToDigit(parseInt(dateStrSplitted[0]))
        );
      }
    } else if (typeof dateStrSplitted[1] === 'string' && dateStrSplitted[1].length) {
      if (monthsShortLabel[language].includes(dateStrSplitted[1])) {
        return new Date(
          (dateStrSplitted[2].length === 2 ? '20' + dateStrSplitted[2] : dateStrSplitted[2]) +
            '-' +
            addZeroToDigit(monthsShortLabel[language].indexOf(dateStrSplitted[1]) + 1) +
            '-' +
            addZeroToDigit(parseInt(dateStrSplitted[0]))
        );
      }
    }
  }

  return null;
};

export const capitalize = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const cleanString = (text: string): string => {
  return text.replaceAll(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, '');
};

export const escapeRegExp = (text: string): string => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const getCountryTitle = (countryCode: string | null): string | null => {
  if (countryCode && countries[countryCode as keyof typeof countries]) {
    return countries[countryCode as keyof typeof countries];
  }

  return countryCode;
};

export const getContinentTitle = (continentCode: string | null): string | null => {
  if (continentCode && continents[continentCode as keyof typeof continents]) {
    return continents[continentCode as keyof typeof continents];
  }

  return continentCode;
};

export const convertUtcDateTimeToLocal = (
  date: string,
  time: string,
  timezone: string,
  dateFormat: string = 'yyyy-MM-dd HH:mm'
): string => {
  if (time === '') {
    time = '00:00';
  }

  return formatInTimeZone(new Date(date + 'T' + time + ':00Z'), timezone, dateFormat);
};
