export enum EnumEnquiryType {
  business = 'business',
  commercial = 'commercial',
  cargo = 'cargo',
  sport = 'sport',
  helico = 'helico'
}

export const getEnumEnquiryTypeLabel = (type: EnumEnquiryType): string => {
  switch (type) {
    default:
      return type;
    case EnumEnquiryType.business:
      return "Aviation d'affaires";
    case EnumEnquiryType.commercial:
      return 'Aviation commerciale';
    case EnumEnquiryType.cargo:
      return 'Aviation cargo';
    case EnumEnquiryType.sport:
      return 'Aviation sport';
    case EnumEnquiryType.helico:
      return 'Hélicoptère';
  }
};

export const getEnumEnquiryPrefix = (type: EnumEnquiryType): string => {
  switch (type) {
    default:
      return type;
    case EnumEnquiryType.business:
      return 'JET';
    case EnumEnquiryType.commercial:
      return 'INC';
    case EnumEnquiryType.cargo:
      return 'CAR';
    case EnumEnquiryType.sport:
      return 'SPO';
    case EnumEnquiryType.helico:
      return 'HEL';
  }
};

export const getEnquiryTypeFromEnquiryRef = (enquiryRef: string): EnumEnquiryType | null => {
  for (const enquiryType of Object.values(EnumEnquiryType)) {
    if (enquiryRef.match(getEnumEnquiryPrefix(enquiryType))) {
      return enquiryType;
    }
  }

  return null;
};
