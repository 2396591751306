<div class="skeleton-text" *ngIf="loading"></div>

<div *ngIf="!loading">
    <div *ngIf="!optionsSuggested.length" class="alert alert-danger">
        Aucun contact dans Pipedrive pour cette organisation (#{{ organizationId }})
    </div>

    <select
        class="form-select"
        id="personId"
        [(ngModel)]="value"
        (change)="updatePerson()"
        required
        [disabled]="disabled"
        *ngIf="optionsSuggested.length"
    >
        <option
            [value]="option.value"
            *ngFor="let option of optionsSuggested"
            [innerHtml]="option.title"
        ></option>
    </select>
</div>
