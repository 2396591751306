import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { RemoteService } from '../services/remote.service';
import { AuthService } from '../services/auth/auth.service';
import { Subscription } from 'rxjs';
import { IUser } from '../interfaces/user.interface';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  form: FormGroup;
  sending: boolean = false;
  rootUrl: string = './';

  user: IUser;

  private subscriptions = new Subscription();

  constructor(private router: Router, private authService: AuthService) {
    this.rootUrl = window['rootUrl'];
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  loadUserProfile() {
    const authSub = this.authService.currentUser$.subscribe(async user => {
      this.user = user;

      if (this.user) {
        this.router.navigate(['/admin/dashboard']);
      }
    });
    this.subscriptions.add(authSub);
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.status == 'VALID') {
      this.sending = true;
      this.form.disable();

      this.authService
        .signInWithEmailAndPassword(this.form.value.email, this.form.value.password)
        .then(
          () => {
            this.form.reset();
            this.loadUserProfile();
          },
          err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          }
        )
        .finally(() => {
          this.form.enable();
        });
    }
  }
}
