import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';
import { EncaissementTypeFirestoreService } from './encaissement-types-firestore.service';

@Injectable({
  providedIn: 'root'
})
export class EncaissementTypeService {
  constructor(private encaissementTypeFirestoreService: EncaissementTypeFirestoreService) {}

  create(encaissementType: IEncaissementType): Promise<string> {
    return this.encaissementTypeFirestoreService.create(encaissementType);
  }

  update(encaissementType: IEncaissementType): Promise<string> {
    return this.encaissementTypeFirestoreService.update(encaissementType);
  }

  delete(id: string): Promise<void> {
    return this.encaissementTypeFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IEncaissementType> {
    return this.encaissementTypeFirestoreService.docValue$(id);
  }

  getAll(): Observable<IEncaissementType[]> {
    return this.encaissementTypeFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.encaissementTypeFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IEncaissementType[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.encaissementTypeFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }
}
