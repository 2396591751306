<breadcrumbs
    [items]="[
        {
            title: 'Liste des comptes bancaires',
            path: '/admin/bank-accounts/list'
        },
        {
            title: bankAccount ? bankAccount.name : '',
            path: '/admin/bank-accounts/view/' + bankAccountId
        }
    ]"
></breadcrumbs>

<div class="row justify-content-center">
    <div class="col-md-6">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="skeleton-text"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && bankAccount">
                <h3 [innerHtml]="bankAccount.name" class="text-center"></h3>

                <ul class="list-unstyled">
                    <li><strong>Intitulé du compte</strong> : {{ bankAccount.accountLabel }}</li>
                    <li><strong>IBAN</strong> : {{ bankAccount.iban }}</li>
                    <li><strong>BIC</strong> : {{ bankAccount.bic }}</li>
                    <li><strong>Devise principale</strong> : {{ bankAccount.defaultCurrency }}</li>
                    <li>
                        <strong>Activé pour la facturation ?</strong> :
                        {{ bankAccount.enableForInvoicing ? "Oui" : "Non" }}
                    </li>
                </ul>

                <div class="text-center mt-4">
                    <a
                        [routerLink]="'/admin/bank-accounts/edit/' + bankAccount.id"
                        class="btn btn-primary mr-4"
                        *ngIf="hasAclAccess(EnumAcl.bankAccountsEdit)"
                        >Éditer</a
                    >
                    <button
                        type="button"
                        (click)="delete()"
                        class="btn btn-primary ml-4"
                        *ngIf="hasAclAccess(EnumAcl.bankAccountsDelete)"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
