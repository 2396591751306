import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { AirlineSupervisorFirestoreService } from './airline-supervisors-firestore.service';
import { IAirlineSupervisor } from 'src/app/interfaces/airline-supervisor.interface';

@Injectable({
  providedIn: 'root'
})
export class AirlineSupervisorService {
  constructor(private airlineSupervisorFirestoreService: AirlineSupervisorFirestoreService) {}

  create(AirlineSupervisor: IAirlineSupervisor): Promise<string> {
    return this.airlineSupervisorFirestoreService.create(AirlineSupervisor);
  }

  createWithId(id: string, AirlineSupervisor: IAirlineSupervisor): Promise<string> {
    return this.airlineSupervisorFirestoreService.createWithId(id, AirlineSupervisor);
  }

  update(AirlineSupervisor: IAirlineSupervisor): Promise<string> {
    return this.airlineSupervisorFirestoreService.update(AirlineSupervisor);
  }

  delete(id: string): Promise<void> {
    return this.airlineSupervisorFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IAirlineSupervisor> {
    return this.airlineSupervisorFirestoreService.docValue$(id);
  }

  getAll(): Observable<IAirlineSupervisor[]> {
    return this.airlineSupervisorFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.airlineSupervisorFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IAirlineSupervisor[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.airlineSupervisorFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }

  getForAirline(airlineId: string): Observable<IAirlineSupervisor[]> {
    return this.airlineSupervisorFirestoreService.collectionValues$(ref =>
      ref.where('airlineId', '==', airlineId)
    );
  }
}
