import { Injectable } from '@angular/core';
import { FunctionsService } from '../functions/functions.service';

@Injectable({
  providedIn: 'root'
})
export class BackupsService {
  constructor(private functionsService: FunctionsService) {}

  getBackupFilesList(): Promise<{
    firestore: string[];
    auth: string[];
  }> {
    return this.functionsService.callFunctionAsPromise('getBackupFilesList', {});
  }

  forceBackup(): Promise<void> {
    return this.functionsService.callFunctionAsPromise('forceBackup', {});
  }

  applyBackUp(firestoreFile: string, authFile: string): Promise<void> {
    return this.functionsService.callFunctionAsPromise('applyBackUp', {
      firestoreFile,
      authFile
    });
  }
}
