export enum EnumAircraftStatus {
  active = 'Active',
  activeParked = 'Active (parked)',
  onOrder = 'On order',
  scrapped = 'Scrapped',
  stored = 'Stored',
  writtenOff = 'Written off'
}

export const getAircraftStatusLabel = (status: EnumAircraftStatus): string => {
  switch (status) {
    default:
      return status;
    case EnumAircraftStatus.active:
      return 'Actif';
    case EnumAircraftStatus.activeParked:
      return 'Actif (parqué)';
    case EnumAircraftStatus.onOrder:
      return 'En commande';
    case EnumAircraftStatus.scrapped:
      return 'Démantelé';
    case EnumAircraftStatus.stored:
      return 'Stocké';
    case EnumAircraftStatus.writtenOff:
      return 'Détruit après accident';
  }
};
