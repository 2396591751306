import { Injectable } from '@angular/core';
import { IFlightChecklist } from 'src/app/interfaces/flight-checklist.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class FlightChecklistFirestoreService extends FirestoreService<IFlightChecklist> {
  protected basePath = 'flightChecklists';
}
