<div class="row">
    <div class="offset-md-8 col-md-4 text-end">
        <div class="form-group mb-3">
            <a
                type="button"
                routerLink="/admin/invoices/import"
                class="btn btn-primary"
                (click)="toggleSearch()"
                *ngIf="hasAclAccess(EnumAcl.invoicesImport)"
                >Import</a
            >
            <button
                type="button"
                class="btn btn-primary"
                (click)="toggleSearch()"
                [innerText]="isSearchListing ? 'Fermer' : 'Rechercher'"
            ></button>
        </div>
    </div>
</div>

<div class="row" *ngIf="isSearchListing">
    <ng-container *ngFor="let searchableField of searchableFields">
        <div class="col-md-3" *ngIf="!searchableField.hidden">
            <div class="form-group">
                <label [innerText]="searchableField.label"></label>
                <input
                    class="form-control"
                    type="text"
                    *ngIf="searchableField.type === 'text'"
                    [(ngModel)]="searchableField.value"
                    (keyup)="updateInvoiceFilter()"
                    debounce="500"
                />

                <div class="input-group mb-3" *ngIf="searchableField.type === 'date'">
                    <div class="dropdown">
                        <button
                            class="btn btn-outline-secondary dropdown-toggle"
                            type="button"
                            [innerText]="searchableField.operator"
                            [id]="'operator-' + searchableField.field"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        ></button>
                        <div
                            class="dropdown-menu dropdown-menu-end"
                            aria-labelledby="dropdownMenuButton-status"
                        >
                            <button
                                class="dropdown-item"
                                type="button"
                                *ngFor="let operator of operatorsList"
                                [innerText]="operator"
                                [class.active]="operator === searchableField.operator"
                                [disabled]="operator === searchableField.operator"
                                (click)="
                                    updateSearchableFieldOperator(searchableField.field, operator)
                                "
                            ></button>
                        </div>
                    </div>

                    <input
                        class="form-control"
                        type="date"
                        *ngIf="searchableField.type === 'date'"
                        [(ngModel)]="searchableField.value"
                        (keyup)="updateInvoiceFilter()"
                        (change)="updateInvoiceFilter()"
                        debounce="500"
                    />
                </div>

                <div class="input-group mb-3" *ngIf="searchableField.type === 'number'">
                    <div class="dropdown">
                        <button
                            class="btn btn-outline-secondary dropdown-toggle"
                            type="button"
                            [innerText]="searchableField.operator"
                            [id]="'operator-' + searchableField.field"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        ></button>
                        <div
                            class="dropdown-menu dropdown-menu-end"
                            aria-labelledby="dropdownMenuButton-status"
                        >
                            <button
                                class="dropdown-item"
                                type="button"
                                *ngFor="let operator of operatorsList"
                                [innerText]="operator"
                                [class.active]="operator === searchableField.operator"
                                [disabled]="operator === searchableField.operator"
                                (click)="
                                    updateSearchableFieldOperator(searchableField.field, operator)
                                "
                            ></button>
                        </div>
                    </div>

                    <input
                        class="form-control"
                        type="text"
                        [(ngModel)]="searchableField.value"
                        (keyup)="updateInvoiceFilter()"
                        debounce="500"
                    />
                </div>

                <select
                    class="form-select"
                    [(ngModel)]="searchableField.value"
                    (change)="updateInvoiceFilter()"
                    *ngIf="searchableField.type === 'select'"
                >
                    <option value="">Tous</option>
                    <option
                        [value]="option.value"
                        *ngFor="let option of searchableField.options"
                        [innerHtml]="option.title"
                    ></option>
                </select>
                <select
                    class="form-select"
                    [(ngModel)]="searchableField.value"
                    (change)="updateInvoiceFilter()"
                    *ngIf="searchableField.type === 'boolean'"
                >
                    <option value="">Tous</option>
                    <option [ngValue]="true">Oui</option>
                    <option [ngValue]="false">Non</option>
                </select>

                <pipedrive-search-organization-input
                    *ngIf="searchableField.type === 'client'"
                    (setValueToFormControl)="setValueToFormControl($event)"
                    [inputName]="searchableField.field"
                    [defaultValue]="searchableField.value"
                ></pipedrive-search-organization-input>

                <app-enquiry-ref-picker
                    *ngIf="searchableField.type === 'enquiry'"
                    [contractRefOnly]="false"
                    [defaultValue]="searchableField.value"
                    [inputName]="searchableField.field"
                    (setValueToFormControl)="setValueToFormControl($event)"
                ></app-enquiry-ref-picker>

                <div *ngIf="searchableField.type === 'facetSearch'">
                    <input
                        class="form-control"
                        type="text"
                        [(ngModel)]="searchableField.value"
                        (keyup)="searchFacet(searchableField)"
                        debounce="500"
                        *ngIf="!searchableField.facetValue"
                    />

                    <div *ngIf="searchableField.facetOptions.length" class="list-group">
                        <button
                            type="button"
                            class="list-group-item list-group-item-action"
                            *ngFor="let option of searchableField.facetOptions"
                            [innerHtml]="option.highlighted"
                            (click)="assignFacetOption(searchableField, option)"
                        ></button>
                    </div>

                    <div class="form-control fake-input" *ngIf="searchableField.facetValue">
                        <span class="fake-input-text" [innerText]="searchableField.facetValue">
                        </span>
                        <img
                            [src]="rootUrl + 'assets/img/icon-close-dark.svg'"
                            class="clear-btn"
                            (click)="clearFacetValue(searchableField)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<div class="table-responsive">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th
                    scope="col"
                    *ngFor="let field of paginationService.pagination[paginationName]?.fields"
                    [innerHtml]="field.title"
                ></th>
            </tr>
        </thead>
        <tbody *ngIf="paginationService.loading || searchLoading">
            <tr *ngFor="let number of [].constructor(10)">
                <td *ngFor="let field of paginationService.pagination[paginationName]?.fields">
                    <div class="skeleton-text"></div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="!paginationService.loading && !searchLoading">
            <tr
                *ngIf="!invoicesDisplayed || !invoicesDisplayed.length"
                class="table-warning text-center"
            >
                <td [attr.colspan]="paginationService.pagination[paginationName].fields?.length">
                    <span *ngIf="invoiceType === null || invoiceType === 'all-without-proforma'"
                        >Aucune facture.</span
                    >
                    <span
                        *ngIf="
                            invoiceType === EnumInvoiceType.creditNote ||
                            paginationName === 'invoices-credit-note-proforma-credit-note-list'
                        "
                        >Aucun avoir.</span
                    >
                    <span *ngIf="invoiceType === EnumInvoiceType.proformaCreditNote"
                        >Aucune proforma d'avoir.</span
                    >
                    <span *ngIf="invoiceType === EnumInvoiceType.proforma">Aucune proforma.</span>
                    <span *ngIf="invoiceType === EnumInvoiceType.definitive"
                        >Aucune facture émise.</span
                    >
                    <span *ngIf="invoiceType === 'cancelled'">Aucune facture annulée.</span>
                    <span
                        *ngIf="
                            invoiceType === EnumInvoiceType.purchase ||
                            invoiceType === EnumInvoiceType.purchaseCreditNote
                        "
                        >Aucune facture d'achat.</span
                    >
                </td>
            </tr>
            <tr
                *ngFor="let invoice of invoicesDisplayed"
                [routerLink]="'/admin/invoices/view/' + invoice.id"
                class="clickable"
                [class.table-success]="
                    coloredTr && getInvoiceDaysLate(invoice) <= -15 && !invoice.isFullyPayed
                "
                [class.table-warning]="
                    coloredTr &&
                    getInvoiceDaysLate(invoice) >= -5 &&
                    getInvoiceDaysLate(invoice) < -1 &&
                    !invoice.isFullyPayed
                "
                [class.table-danger]="
                    invoice.invoiceType === EnumInvoiceType.purchaseCreditNote ||
                    (coloredTr && getInvoiceDaysLate(invoice) >= -1 && !invoice.isFullyPayed)
                "
            >
                <td [innerText]="invoice.ref"></td>
                <td
                    [innerText]="getEnumInvoiceTypeLabel(invoice.invoiceType)"
                    *ngIf="
                        invoiceType === null ||
                        invoiceType === EnumInvoiceType.purchase ||
                        invoiceType === EnumInvoiceType.purchaseCreditNote
                    "
                ></td>
                <td [innerText]="invoice.issueDate | date : 'dd/MM/yyyy'"></td>
                <td [innerText]="invoice.dueDate | date : 'dd/MM/yyyy'"></td>
                <td>
                    <span
                        class="badge bg-danger text-light"
                        [innerText]="getInvoiceDaysLate(invoice) + ' jours'"
                        *ngIf="getInvoiceDaysLate(invoice) > 0 && !invoice.isFullyPayed"
                    ></span>
                </td>
                <td *ngIf="invoiceType !== EnumInvoiceType.purchase">
                    <ng-container *ngIf="enquiriesObj[invoice.enquiryId]">
                        <div *ngFor="let itinerary of enquiriesObj[invoice.enquiryId].itineraries">
                            <div
                                *ngIf="enquiriesObj[invoice.enquiryId].itineraries.length > 1"
                                [innerHtml]="itinerary.title"
                            ></div>
                            <div
                                *ngIf="itinerary.dateDepart"
                                [innerHtml]="itinerary.dateDepart | date : 'dd/MM/yyyy'"
                            ></div>
                        </div>
                    </ng-container>
                </td>
                <td>
                    <div
                        *ngIf="
                            enquiriesObj[invoice.enquiryId] &&
                            enquiriesObj[invoice.enquiryId].refContractTitle
                        "
                        [innerHtml]="
                            getDisplayedEnquiryRefTitle(
                                enquiriesObj[invoice.enquiryId],
                                'refContract'
                            )
                        "
                    ></div>
                </td>
                <td>
                    <span [innerText]="invoice.clientBillingInfos.clientName"></span>
                </td>
                <td [innerText]="invoice.invoiceObject ? invoice.invoiceObject : ''"></td>
                <td [innerText]="invoice.sentToClient ? 'Oui' : 'Non'"></td>
                <td [innerText]="formatPrice(invoice.amountHtTotal, invoice.currency)"></td>
                <td [innerText]="formatPrice(invoice.amountTvaTotal, invoice.currency)"></td>
                <td [innerText]="formatPrice(invoice.amountTtcTotal, invoice.currency)"></td>
                <td [innerText]="invoice.soldedDate ? invoice.soldedDate : ''"></td>
                <td [innerText]="bankAccountsObj[invoice.bankAccount]?.name"></td>
                <td [innerText]="formatPrice(invoice.amountSoldePaid, invoice.currency)"></td>
                <td [innerText]="formatPrice(invoice.amountSoldeLeft, invoice.currency)"></td>
                <td [innerText]="invoice.internalNote ? invoice.internalNote : ''"></td>
                <!-- 
                <td>
                    {{
                        invoice.requestDate
                            ? invoice.requestDate
                            : (invoice.created | date: "dd/MM/yyyy HH:mm")
                    }}
                </td>
                <td>
                    <span
                        [innerHtml]="
                            usersObj[invoice.requestedBy].lastname +
                            ' ' +
                            usersObj[invoice.requestedBy].firstname
                        "
                        *ngIf="usersObj[invoice.requestedBy]"
                    ></span>
                </td> -->
            </tr>
        </tbody>
    </table>
</div>

<nav
    aria-label="Liste navigation"
    class="mt-4 mb-4"
    *ngIf="!paginationService.loading && !searchLoading && !isSearchListing"
>
    <ul class="pagination justify-content-center">
        <li
            class="page-item"
            [class.disabled]="paginationService.pagination[paginationName].currentPage === 0"
        >
            <button type="button" class="page-link" (click)="loadPrevPage(paginationName)">
                Précédent
            </button>
        </li>
        <li class="page-item disabled">
            <span
                class="page-link"
                [innerHtml]="paginationService.pagination[paginationName].currentPage + 1"
            ></span>
        </li>
        <li
            class="page-item"
            [class.disabled]="
                paginationService.pagination[paginationName].data &&
                (!paginationService.pagination[paginationName].data.length ||
                    paginationService.pagination[paginationName].data.length <
                        paginationService.pagination[paginationName].limit)
            "
        >
            <button type="button" class="page-link" (click)="loadNextPage(paginationName)">
                Suivant
            </button>
        </li>
    </ul>
</nav>
