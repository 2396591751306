<breadcrumbs
    [items]="[
        {
            title: 'Liste des modèles d\'avions pour le simulateur',
            path: '/admin/simulateur-aircraft-models/list'
        },
        {
            title: simulateurAircraftModel ? simulateurAircraftModel.title : '',
            path: '/admin/simulateur-aircraft-models/view/' + simulateurAircraftModelId
        }
    ]"
></breadcrumbs>

<div class="row justify-content-center">
    <div class="col-md-8">
        <div class="card">
            <div class="card-body">
                <ul *ngIf="loading" class="list-unstyled">
                    <li *ngFor="let number of [].constructor(4)">
                        <div class="skeleton-text"></div>
                    </li>
                </ul>
                <div *ngIf="!loading && simulateurAircraftModel">
                    <ul class="list-unstyled">
                        <li><strong>Id</strong> : {{ simulateurAircraftModel.slug }}</li>
                        <li><strong>Nom</strong> : {{ simulateurAircraftModel.title }}</li>
                        <li *ngIf="simulateurAircraftModel.speedInKmh">
                            <strong>Vitesse</strong> :
                            {{
                                isNaN(simulateurAircraftModel.speedInKmh)
                                    ? ""
                                    : simulateurAircraftModel.speedInKmh + " km/h"
                            }}
                        </li>
                        <li *ngIf="simulateurAircraftModel.rangeMaxInKms">
                            <strong>Autonomie maximum</strong> :
                            {{
                                isNaN(simulateurAircraftModel.rangeMaxInKms)
                                    ? ""
                                    : simulateurAircraftModel.rangeMaxInKms + " kms"
                            }}
                        </li>

                        <li *ngIf="simulateurAircraftModel.paxMax">
                            <strong>Passagers maximum</strong> :
                            {{
                                isNaN(simulateurAircraftModel.paxMax)
                                    ? ""
                                    : simulateurAircraftModel.paxMax + " passager(s)"
                            }}
                        </li>

                        <li *ngIf="simulateurAircraftModel.flyTimeInHours">
                            <strong>Durée de vol maximum</strong> :
                            {{
                                isNaN(simulateurAircraftModel.flyTimeInHours)
                                    ? ""
                                    : simulateurAircraftModel.flyTimeInHours + " h"
                            }}
                        </li>

                        <li *ngIf="simulateurAircraftModel.flyTimeInHours">
                            <strong>Prix par heure</strong> :
                            {{
                                isNaN(simulateurAircraftModel.priceByHour)
                                    ? ""
                                    : formatPrice(simulateurAircraftModel.priceByHour)
                            }}
                        </li>

                        <li *ngIf="simulateurAircraftModel.flyTimeInHours">
                            <strong>Prix par passager</strong> :
                            {{
                                isNaN(simulateurAircraftModel.priceByPax)
                                    ? ""
                                    : formatPrice(simulateurAircraftModel.priceByPax)
                            }}
                        </li>

                        <li>
                            <strong>Longueur de piste minimum</strong> :
                            {{
                                isNaN(simulateurAircraftModel.minHardSurfaceRunwayInMeters)
                                    ? "0"
                                    : simulateurAircraftModel.minHardSurfaceRunwayInMeters
                            }}
                            mètres
                        </li>

                        <li>
                            <strong>Catégorie incendie</strong> :
                            {{
                                simulateurAircraftModel.fireCategories?.length
                                    ? simulateurAircraftModel.fireCategories.join(", ")
                                    : "Aucune"
                            }}
                        </li>

                        <li>
                            <strong>Créé le :</strong>
                            {{ simulateurAircraftModel.created | date : "dd/MM/yyyy" }}
                        </li>
                        <li>
                            <strong>Image : </strong>
                            <div *ngIf="simulateurAircraftModel.imageUrl">
                                <img [src]="simulateurAircraftModel.imageUrl" />
                            </div>
                            <span *ngIf="!simulateurAircraftModel.imageUrl">Aucune image</span>
                        </li>
                        <li>
                            <strong>Description : </strong>
                            <div *ngIf="simulateurAircraftModel.description">
                                <quill-view
                                    [content]="simulateurAircraftModel.description"
                                    format="html"
                                    theme="snow"
                                ></quill-view>
                            </div>
                            <span *ngIf="!simulateurAircraftModel.description"
                                >Aucune description</span
                            >
                        </li>
                    </ul>
                    <div class="mb-4 text-center">
                        <a
                            routerLink="/admin/simulateur-aircraft-models/list"
                            class="btn btn-primary"
                            >Retour liste</a
                        >
                        <a
                            [routerLink]="
                                '/admin/simulateur-aircraft-models/edit/' +
                                simulateurAircraftModel.id
                            "
                            class="btn btn-primary"
                            *ngIf="hasAclAccess(EnumAcl.simulateurAircraftModelsEdit)"
                            >Modifier</a
                        ><br />
                        <br />
                        <button
                            type="button"
                            (click)="delete()"
                            class="btn btn-outline-primary"
                            *ngIf="hasAclAccess(EnumAcl.simulateurAircraftModelsDelete)"
                        >
                            Supprimer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
