import { NgModule } from '@angular/core';
import { FlightsCalendarComponent } from './flights-calendar.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CalendarModule } from '../calendar/calendar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnquiryFlightsTableModule } from '../enquiry-flights-table/enquiry-flights-table.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    CalendarModule,
    EnquiryFlightsTableModule
  ],
  declarations: [FlightsCalendarComponent],
  exports: [FlightsCalendarComponent]
})
export class FlightsCalendarModule {}
