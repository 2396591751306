import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { PipedriveService } from '../../../services/pipedrive.service';
import { IPipedrivePerson, getPipedrivePersonTitle } from 'src/app/interfaces/pipedrive.interface';

@Component({
  selector: 'pipedrive-search-person-input',
  templateUrl: './search-person-input.component.html',
  styleUrls: ['./search-person-input.component.scss']
})
export class PipedriveSearchPersonInputComponent implements OnChanges {
  @Input('isInvalid') isInvalid: boolean;
  @Input('inputName') inputName: string;
  @Input('disabled') disabled: boolean = false;
  @Input('defaultValue') defaultValue: string;
  @Input('organizationId') organizationId: string;
  @Output() setValueToFormControl: EventEmitter<{
    fields: Array<{
      name: string;
      value: string;
    }>;
  }> = new EventEmitter();
  value: string;
  title: string;
  persons: IPipedrivePerson[] = [];
  optionsSuggested: Array<{
    title: string;
    value: string;
  }> = [];
  // initiated: boolean = false
  rootUrl: string = '../';
  loading: boolean = false;

  constructor(private pipedriveService: PipedriveService) {
    this.rootUrl = window['rootUrl'];
  }

  ngOnChanges() {
    // if (!this.initiated && this.defaultValue) {
    this.value = this.defaultValue;

    // this.initiated = true
    // }

    this.updatePersonsOptions();
  }

  async updatePersonsOptions(): Promise<void> {
    this.loading = true;
    this.optionsSuggested = [];

    try {
      this.persons = await this.pipedriveService.getAllPersonsOfOrganizations(this.organizationId);

      this.optionsSuggested = [];

      for (const person of this.persons) {
        this.optionsSuggested.push({
          title: getPipedrivePersonTitle(person),
          value: person.id
        });
      }

      this.loading = false;
    } catch (err) {
      console.error(err);
      this.loading = false;
    }
  }

  updatePerson(): void {
    this.setValueToFormControl.emit({
      fields: [
        {
          name: this.inputName,
          value: this.value
        }
      ]
    });
  }
}
