import { Component, OnInit, NgZone } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import { AlgoliaService, IAlgoliaFilter } from 'src/app/services/algolia.service';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import {
  EnumCurrency,
  getEnumCurrencyLabel,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { format } from 'date-fns';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  EnumSuggestionInvoiceReason,
  ISuggestedInvoice,
  getSuggestionInvoiceReasonLabel
} from 'src/app/enums/suggested-invoice.enum';
import { IInvoice, getInvoiceTitle } from 'src/app/interfaces/invoice.interface';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';
import { IEncaissement } from 'src/app/interfaces/encaissement.interface';

@Component({
  selector: 'app-encaissement-edit',
  templateUrl: './encaissement-edit.component.html',
  styleUrls: ['./encaissement-edit.component.scss']
})
export class EncaissementEditComponent implements OnInit {
  EnumCurrency = EnumCurrency;

  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getEnumCurrencyLabel = getEnumCurrencyLabel;
  getSuggestionInvoiceReasonLabel = getSuggestionInvoiceReasonLabel;
  getInvoiceTitle = getInvoiceTitle;

  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  encaissement: IEncaissement | null = null;
  encaissementId: string;
  invoiceId: string;
  invoice: IInvoice | null = null;

  bankAccountsList: IBankAccount[] = [];
  encaissementTypesList: IEncaissementType[] = [];

  suggestionInvoices: ISuggestedInvoice[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private algoliaService: AlgoliaService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      libelle: ['', [Validators.required]],
      encaissementTypeId: ['', [Validators.required]],
      isCredit: [false],
      isDebit: [false],
      amount: [0, [Validators.required]],
      currency: ['EUR', [Validators.required]],
      bankAccountId: ['', [Validators.required]],
      invoiceId: [''],
      date: ['', [Validators.required]],
      isReconciled: [false, [Validators.required]],
      comment: ['']
    });

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.encaissementId = this.activatedRoute.snapshot.paramMap.get('encaissementId');
      this.invoiceId = this.activatedRoute.snapshot.paramMap.get('invoiceId');

      if (this.encaissementId) {
        await this.aclService.checkAclAccess(EnumAcl.encaissementsEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.encaissementsAdd);

        if (this.invoiceId) {
          this.form.get('invoiceId').setValue(this.invoiceId);
          this.loadInvoice();
        }

        this.form.enable();
      }
    });

    this.loadBankAccounts();
    this.loadEncaissementTypes();
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadEncaissement();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadEncaissement(): Promise<void> {
    const doc = await this.remoteService.getDocument('encaissements', this.encaissementId);

    this.encaissement = doc as IEncaissement;

    this.setEncaissement();
  }

  setEncaissement(): void {
    if (this.form && this.encaissement) {
      this.form.get('libelle').setValue(this.encaissement.libelle);
      this.form.get('encaissementTypeId').setValue(this.encaissement.encaissementTypeId);
      this.form.get('isCredit').setValue(this.encaissement.isCredit);
      this.form.get('isDebit').setValue(this.encaissement.isDebit);
      this.form.get('amount').setValue(this.encaissement.amount);
      this.form.get('currency').setValue(this.encaissement.currency);
      this.form.get('bankAccountId').setValue(this.encaissement.bankAccountId);
      this.form.get('invoiceId').setValue(this.encaissement.invoiceId);
      this.form.get('date').setValue(this.encaissement.date);
      this.form.get('isReconciled').setValue(this.encaissement.isReconciled);
      this.form.get('comment').setValue(this.encaissement.comment);

      this.getSuggestionInvoices();

      this.form.enable();
    }
  }

  setEncaissementAccordingToInvoice(): void {
    if (this.form && this.invoice) {
      console.log(
        '🚀 ~ file: encaissement-edit.component.ts:144 ~ EncaissementEditComponent ~ setEncaissementAccordingToInvoice ~ this.invoice:',
        this.invoice
      );
      // this.form.get('encaissementTypeId').setValue(this.encaissement.encaissementTypeId);
      this.form.get('amount').setValue(this.invoice.amountSoldeLeft);
      this.form.get('currency').setValue(this.invoice.currency);
      this.form.get('invoiceId').setValue(this.invoice.id);
      this.form.get('date').setValue(format(new Date(), 'yyyy-MM-dd'));

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.status == 'VALID') {
      let data = Object.assign({}, this.form.value);

      data.isDebit = data.amount >= 0;
      data.isCredit = data.amount < 0;

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      if (this.encaissementId) {
        this.remoteService
          .updateDocumentToCollection('encaissements', this.encaissementId, data)
          .then(() => {
            this.sending = false;
            this.redirectAfterSaving(data);
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      } else {
        this.remoteService
          .addDocumentToCollection('encaissements', data)
          .then((docId: string) => {
            this.encaissementId = docId;

            this.sending = false;
            this.redirectAfterSaving(data);
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      }
    }
  }

  redirectAfterSaving(data: any): void {
    if (data.invoiceId) {
      this.router.navigate(['/admin/invoices/view/' + data.invoiceId]);
    } else {
      this.router.navigate(['/admin/encaissements/view/' + this.encaissementId]);
    }
  }

  async loadBankAccounts(): Promise<void> {
    const docs = await this.remoteService.getAllDocuments('bankAccounts', {
      field: 'name',
      direction: 'asc'
    });

    this.bankAccountsList = [];
    for (const doc of docs) {
      this.bankAccountsList.push(doc as IBankAccount);
    }
  }

  async loadEncaissementTypes(): Promise<void> {
    const docs = await this.remoteService.getAllDocuments('encaissementTypes', {
      field: 'name',
      direction: 'asc'
    });

    this.encaissementTypesList = [];

    for (const doc of docs) {
      const encaissementType: IEncaissementType = doc as IEncaissementType;
      this.encaissementTypesList.push(encaissementType);

      if (
        !this.form.value.encaissementTypeId &&
        encaissementType.name.toLocaleLowerCase().match('virement')
      ) {
        this.form.get('encaissementTypeId').setValue(encaissementType.id);
      }
    }
  }

  async setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string;
    }>;
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      formControl.setValue(field.value);

      formControl.markAsTouched();
      formControl.updateValueAndValidity();

      if (field.name === 'invoiceId') {
        this.getSuggestionInvoices();
      }
    }
  }

  async loadInvoice(): Promise<void> {
    if (this.invoiceId) {
      const doc = await this.remoteService.getDocument('invoices', this.invoiceId);

      this.invoice = doc as IInvoice;

      this.setEncaissementAccordingToInvoice();
    }
  }

  selectSuggestedInvoice(suggestionInvoice: ISuggestedInvoice): void {
    this.form.get('invoiceId').setValue(suggestionInvoice.invoice.id);
    this.form.get('invoiceId').updateValueAndValidity();

    this.suggestionInvoices = [];
  }

  async getSuggestionInvoices(): Promise<void> {
    this.suggestionInvoices = [];

    if (!this.form.value.invoiceId) {
      const invoiceRefs: string[] = this.extractInvoiceRefFromText(this.form.value.libelle);
      if (invoiceRefs) {
        for (const invoiceRef of invoiceRefs) {
          const invoicesByRef: IInvoice[] = await this.searchInvoiceInAlgoliaByRef(invoiceRef);

          this.appendSuggestedInvoicesToList(
            invoicesByRef,
            EnumSuggestionInvoiceReason.matchingInvoiceRef
          );
        }
      }

      const invoicesByAmount: IInvoice[] = await this.searchInvoiceSameAmount(
        this.form.value.amount,
        this.form.value.currency
      );

      this.appendSuggestedInvoicesToList(
        invoicesByAmount,
        EnumSuggestionInvoiceReason.sameAmountTtcTotal
      );

      const enquiryRefs: string[] = this.extractEnquiryRefFromText(this.form.value.libelle);

      if (enquiryRefs) {
        for (const enquiryRef of enquiryRefs) {
          const enquiries: IEnquiry[] = await this.searchEnquiryByRef(enquiryRef);

          if (enquiries.length) {
            for (const enquiry of enquiries) {
              const invoicesByEnquiry: IInvoice[] = await this.searchInvoiceByEnquiryId(enquiry.id);

              this.appendSuggestedInvoicesToList(
                invoicesByEnquiry,
                EnumSuggestionInvoiceReason.enquiryMatch
              );
            }
          }
        }
      }
    }

    this.sortSuggestionInvoicesByTotalReasons();
  }

  appendSuggestedInvoicesToList(invoices: IInvoice[], reason: EnumSuggestionInvoiceReason): void {
    for (const invoice of invoices) {
      let existingIndex = -1;
      for (let i = 0; i < this.suggestionInvoices.length; i++) {
        if (this.suggestionInvoices[i].invoice.id === invoice.id) {
          existingIndex = i;
        }
      }

      if (existingIndex === -1) {
        this.suggestionInvoices.push({
          reasons: [reason],
          invoice: invoice
        });
      } else {
        if (this.suggestionInvoices[existingIndex].reasons.indexOf(reason) === -1) {
          this.suggestionInvoices[existingIndex].reasons.push(reason);
        }
      }
    }
  }

  sortSuggestionInvoicesByTotalReasons(): void {
    this.suggestionInvoices = this.suggestionInvoices.sort((a, b) => {
      if (
        a.reasons.length > b.reasons.length ||
        a.reasons.indexOf(EnumSuggestionInvoiceReason.matchingInvoiceRef) !== -1
      ) {
        return -1;
      } else if (a.reasons.length < b.reasons.length) {
        return 1;
      }
    });
  }

  extractInvoiceRefFromText(text: string): string[] {
    return text.match(new RegExp(/([FA]-\d{6}-\d+)/g));
  }

  extractEnquiryRefFromText(text: string): string[] {
    return text.match(new RegExp(/([A-Z]{3}\d{2}\.\d+)/g));
  }

  async searchInvoiceInAlgoliaByRef(invoiceRef: string): Promise<IInvoice[]> {
    if (invoiceRef.length) {
      try {
        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          invoiceRef,
          ['ref'],
          []
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchEnquiryByRef(enquiryRef: string): Promise<IEnquiry[]> {
    if (enquiryRef.length) {
      try {
        const enquiries: IEnquiry[] = await this.remoteService.searchEnquiryByRef(enquiryRef);

        return enquiries;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchInvoiceInAlgoliaByEnquiryRef(enquiryRef: string): Promise<IInvoice[]> {
    if (enquiryRef.length) {
      try {
        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          enquiryRef,
          ['ref'],
          []
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchInvoiceByEnquiryId(enquiryId: string): Promise<IInvoice[]> {
    if (enquiryId !== null) {
      try {
        const filters: IAlgoliaFilter[] = [];

        filters.push({
          field: 'enquiryId',
          type: 'string',
          operator: '=',
          value: enquiryId
        });

        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          '',
          ['ref', 'enquiryId'],
          filters
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchInvoiceSameAmount(amount: number, currency: string): Promise<IInvoice[]> {
    if (amount !== null && currency !== null) {
      try {
        const filters: IAlgoliaFilter[] = [];

        filters.push({
          field: 'amountTtcTotal',
          type: 'number',
          operator: '=',
          value: amount
        });
        filters.push({
          field: 'currency',
          type: 'string',
          operator: '=',
          value: currency
        });

        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          '',
          ['ref', 'amountTtcTotal', 'currency'],
          filters
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  formatPrice(value: number, currency: string = 'EUR'): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }
}
