import { EnumContinentCode } from '../enums/continent-code.enum';
import { IStoreData } from './store-data.interface';
import countries from '../countries_fr.json';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { EnumLanguage } from '../enums/language.enum';

export interface IAirport extends IStoreData {
  title: string;
  latitude: number;
  longitude: number;
  countryCode: string;
  continentCode: EnumContinentCode;
  iataCode: string;
  icaoCode: string;
  isLieuDit: boolean;
  isInSimulateurPrix: boolean;
  visibleInSimulateurPrixMap: boolean;
  isJetAirport?: boolean;
  isHighDemand?: boolean;
  city?: string;
  distancefromCity?: string;
  fireCategory?: string;
  longestHardSurfaceRunwayInFt?: string;
  timezoneId?: string;
  offset?: number;
  mapUrl?: string;
  helicopterTransfers: IHelicopterTransfer[];
  simulateurInformation: string;
}

export interface IHelicopterTransfer {
  timeInMin: number;
  price: number;
  airportDestinationId: string;
}

export const getAirportIataCode = (airport: IAirport): string => {
  return airport?.iataCode ?? airport?.title;
};

export const getAirportCity = (airport: IAirport): string => {
  return airport?.city ?? airport?.title;
};

export const getDefaultAirportAddressDetails = (
  airport: IAirport,
  language: EnumLanguage
): string => {
  switch (language) {
    case EnumLanguage.fr:
      return [
        '<strong>' + airport.title + '</strong>',
        airport.city + ', ' + countries[airport.countryCode],
        'Plan d’accès : <a href="http://www.google.com/maps/place/' +
          airport.latitude +
          ',' +
          airport.longitude +
          '">ici</a>'
      ].join('<br>');
    default:
      return [
        '<strong>' + airport.title + '</strong>',
        airport.city + ', ' + countries[airport.countryCode],
        'Acces map : <a href="http://www.google.com/maps/place/' +
          airport.latitude +
          ',' +
          airport.longitude +
          '">here</a>'
      ].join('<br>');
  }
};

export const getAirportUTCTime = (
  airport: IAirport | null,
  date: string,
  localTime: string
): Date | null => {
  if (airport && airport.timezoneId && date && localTime) {
    return zonedTimeToUtc(date + ' ' + localTime, airport.timezoneId);
  } else {
    return null;
  }
};

export const getAirportLocalTimeFromUTC = (airport: IAirport, date: Date): string | null => {
  if (airport.timezoneId && date) {
    return formatInTimeZone(date, airport.timezoneId, 'HH:mm');
  } else {
    return null;
  }
};

export const getAirportUTCTimeString = (
  airport: IAirport,
  date: string,
  localTime: string
): string => {
  if (airport.timezoneId && date && localTime) {
    const utcTime: Date | null = getAirportUTCTime(airport, date, localTime);

    if (utcTime) {
      return formatInTimeZone(utcTime, 'UTC', 'HH:mm') + ' GMT/UTC';
    }
  }

  return '??? GMT/UTC';
};
