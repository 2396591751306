import { EnumEnquiryType, getEnumEnquiryTypeLabel } from '../enums/enquiry-type.enum';
import { IStoreData } from './store-data.interface';

export interface IEmployeeBonusRule extends IStoreData {
  name: string;
  enquiryTypes: EnumEnquiryType[];
  minimumNbEnquiries: number;
  minimumMarginHT: number;
  amountBonus: number;
  dateStart: Date;
  dateEnd: Date | null;
  hasDateEnd: boolean;
}

export const getEmployeeBonusRuleDepartmentList = (item: IEmployeeBonusRule): string[] => {
  const labels: string[] = [];

  if (item.enquiryTypes) {
    for (const enquiryType of item.enquiryTypes) {
      labels.push(getEnumEnquiryTypeLabel(enquiryType));
    }
  }

  return labels;
};
