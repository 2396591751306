<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" [class.active]="!items.length">
            <a routerLink="/admin" *ngIf="items.length">Tableau de bord</a>
            <span *ngIf="!items.length">Tableau de bord</span>
        </li>
        <ng-container *ngFor="let item of items; let i = index">
            <li class="breadcrumb-item" [class.active]="i >= items.length - 1" *ngIf="item">
                <div
                    class="dropdown"
                    *ngIf="i < items.length - 1 && item.children && item.children.length"
                >
                    <button
                        class="btn btn-link dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                    >
                        {{ item.title }}
                    </button>
                    <div class="dropdown-menu">
                        <a
                            class="dropdown-item"
                            [routerLink]="child.path"
                            *ngFor="let child of item.children"
                            [innerText]="child.title"
                            [class.disabled]="item.title === child.title"
                        ></a>
                    </div>
                </div>

                <a
                    [routerLink]="item.path"
                    [innerHtml]="item.title"
                    *ngIf="i < items.length - 1 && !(item.children && item.children.length)"
                ></a>
                <span *ngIf="i >= items.length - 1" [innerHtml]="item.title"></span>
            </li>
        </ng-container>
    </ol>
</nav>
