import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { CurrencyExchangeRateFirestoreService } from './currency-exchange-rates-firestore.service';
import { ICurrencyExchangeRate } from 'src/app/interfaces/currency-exchange-rate.interface';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class CurrencyExchangeRateService {
  constructor(private currencyExchangeRateFirestoreService: CurrencyExchangeRateFirestoreService) {}

  create(currencyExchangeRate: ICurrencyExchangeRate): Promise<string> {
    return this.currencyExchangeRateFirestoreService.create(currencyExchangeRate);
  }

  createWithId(id: string, currencyExchangeRate: ICurrencyExchangeRate): Promise<string> {
    return this.currencyExchangeRateFirestoreService.createWithId(id, currencyExchangeRate);
  }

  update(currencyExchangeRate: ICurrencyExchangeRate): Promise<string> {
    return this.currencyExchangeRateFirestoreService.update(currencyExchangeRate);
  }

  delete(id: string): Promise<void> {
    return this.currencyExchangeRateFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<ICurrencyExchangeRate> {
    return this.currencyExchangeRateFirestoreService.docValue$(id);
  }

  getAll(): Observable<ICurrencyExchangeRate[]> {
    return this.currencyExchangeRateFirestoreService.collectionValues$();
  }

  getToday(): Observable<ICurrencyExchangeRate> {
    const id: string = format(new Date(), 'yyyy-MM-dd');

    return this.currencyExchangeRateFirestoreService.docValue$(id);
  }

  resetPagination(paginationName: string = 'list'): void {
    this.currencyExchangeRateFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<ICurrencyExchangeRate[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.currencyExchangeRateFirestoreService.paginate(
      conditions,
      orderBy,
      20,
      paginationName
    );
  }
}
