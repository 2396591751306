<div *ngIf="value" class="form-group" [class.no-margin]="noMargin">
    <div class="form-control fake-input" [class.disabled]="disabled">
        <span class="fake-input-text" [innerHtml]="value.title"></span>
        <img
            [src]="rootUrl + 'assets/img/icon-close-dark.svg'"
            class="clear-btn"
            (click)="clearValue()"
            *ngIf="!disabled"
        />
    </div>
</div>

<div [style.display]="!value ? 'block' : 'none'" class="form-group" [class.no-margin]="noMargin">
    <input
        type="text"
        [name]="inputName"
        class="form-control"
        [class.list-displayed]="optionsSuggested.length"
        list="browsers"
        (keyup)="updateAutocomplete($event.srcElement.value)"
        debounce="500"
        [placeholder]="placeholder"
        #searchInput
        (focus)="updateAutocomplete($event.srcElement.value)"
        [class.is-invalid]="isInvalid"
        [disabled]="disabled"
    />

    <div class="invalid-tooltip" *ngIf="isInvalid">
        <div *ngIf="isInvalid">Ce champ est obligatoire.</div>
    </div>

    <div *ngIf="optionsSuggested.length && !disabled" class="list-group">
        <button
            type="button"
            class="list-group-item list-group-item-action"
            *ngFor="let airline of optionsSuggested"
            [innerHtml]="airline.title"
            (click)="updateFormValue(airline)"
        ></button>
    </div>
</div>
