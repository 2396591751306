<breadcrumbs
    [items]="[
        {
            title: 'Liste des factures',
            path: '/admin/invoices/list'
        },
        {
            title: getInvoiceTitle(invoice),
            path: '/admin/invoices/view/' + invoice?.id
        },
        {
            title: 'Liste des encaissements',
            path: '/admin/encaissements/list/invoice/' + invoice?.id
        },
        {
            title: encaissement ? encaissement.libelle : '',
            path: '/admin/encaissements/view/' + encaissementId
        }
    ]"
></breadcrumbs>

<div class="row justify-content-center">
    <div class="col-md-6">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="skeleton-text"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && encaissement">
                <h3 [innerHtml]="encaissement.libelle" class="text-center"></h3>

                <ul class="list-unstyled">
                    <li><strong>Montant</strong> : {{ encaissement.amount }}</li>
                    <li><strong>Type</strong> : {{ encaissementType?.name }}</li>
                    <li><strong>Devise</strong> : {{ encaissement.currency }}</li>
                    <li><strong>Compte bancaire</strong> : {{ bankAccount?.name ?? "Aucun" }}</li>
                    <li><strong>Date</strong> : {{ encaissement.date }}</li>
                    <li>
                        <strong>Facture / Avoir</strong> :
                        <a
                            [routerLink]="'/admin/invoices/view/' + invoice.id"
                            [innerText]="invoice?.ref"
                            *ngIf="invoice"
                        ></a>
                        <em *ngIf="!invoice" class="text-muted">Lié à aucune facture</em>
                    </li>
                    <li>
                        <strong>Rapproché avec le compte bancaire ?</strong> :
                        {{ encaissement.isReconciled ? "Oui" : "Non" }}
                    </li>
                    <li>
                        <strong>Commentaire</strong> :
                        <p [class.text-muted]="!encaissement.comment.length">
                            {{ encaissement.comment.length ? encaissement.comment : "Aucun" }}
                        </p>
                    </li>
                </ul>

                <div class="text-center mt-4">
                    <a
                        [routerLink]="'/admin/encaissements/edit/' + encaissement.id"
                        class="btn btn-primary mr-4"
                        *ngIf="hasAclAccess(EnumAcl.encaissementsEdit)"
                        >Éditer</a
                    >
                    <button
                        type="button"
                        (click)="delete()"
                        class="btn btn-primary ml-4"
                        *ngIf="hasAclAccess(EnumAcl.encaissementsDelete)"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
