<breadcrumbs
    [items]="[
        {
            title: 'Liste des utilisateurs',
            path: '/admin/users/list'
        }
    ]"
></breadcrumbs>

<div class="btn-toolbar mb-4" role="toolbar">
    <a routerLink="/admin/users/add" class="btn btn-primary" *ngIf="hasAclAccess(EnumAcl.usersAdd)"
        >+ Nouvel utilisateur</a
    >
</div>
<div class="table-responsive">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th
                    scope="col"
                    class="sortable"
                    *ngFor="let field of paginationService.pagination[currentPagination].fields"
                    [class.is-sorted]="
                        paginationService.pagination[currentPagination].orderBy.field == field.code
                    "
                    [class.asc]="
                        paginationService.pagination[currentPagination].orderBy.field ==
                            field.code &&
                        paginationService.pagination[currentPagination].orderBy.direction == 'asc'
                    "
                    [class.desc]="
                        paginationService.pagination[currentPagination].orderBy.field ==
                            field.code &&
                        paginationService.pagination[currentPagination].orderBy.direction == 'desc'
                    "
                    (click)="paginationService.sortField(currentPagination, field.code)"
                    [innerHtml]="field.title"
                ></th>
            </tr>
        </thead>
        <tbody *ngIf="paginationService.loading">
            <tr *ngFor="let number of [].constructor(10)">
                <td *ngFor="let field of paginationService.pagination[currentPagination].fields">
                    <div class="skeleton-text"></div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="!paginationService.loading">
            <tr
                *ngIf="
                    !paginationService.pagination[currentPagination].data ||
                    !paginationService.pagination[currentPagination].data.length
                "
                class="table-warning text-center"
            >
                <td [attr.colspan]="paginationService.pagination[currentPagination].fields.length">
                    Aucun utilisateur n'a été trouvé.
                </td>
            </tr>
            <tr
                *ngFor="let user of paginationService.pagination[currentPagination].data"
                [routerLink]="'/admin/users/view/' + user.id"
                class="clickable"
                [class.tr-muted]="user.isDisabled"
            >
                <td [innerHtml]="user.firstname"></td>
                <td [innerHtml]="user.lastname"></td>
                <td [innerHtml]="user.initials"></td>
                <td>
                    <a
                        [href]="'mailto:' + user.email"
                        target="_blank"
                        [innerHtml]="user.email"
                        *ngIf="user.email"
                    ></a>
                </td>
                <td
                    [innerHtml]="
                        userGroupsObj[user.userGroupId] ? userGroupsObj[user.userGroupId].title : ''
                    "
                ></td>
                <td [innerHtml]="user.created | date : 'dd/MM/yyyy'"></td>
                <td [innerHtml]="user.isDisabled ? 'Oui' : 'Non'"></td>
            </tr>
        </tbody>
    </table>
</div>

<nav aria-label="Liste navigation" class="mt-4 mb-4" *ngIf="!paginationService.loading">
    <ul class="pagination justify-content-center">
        <li
            class="page-item"
            [class.disabled]="paginationService.pagination[currentPagination].currentPage === 0"
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadPreviousPage(currentPagination)"
            >
                Précédent
            </button>
        </li>
        <li class="page-item disabled">
            <span
                class="page-link"
                [innerHtml]="paginationService.pagination[currentPagination].currentPage + 1"
            ></span>
        </li>
        <li
            class="page-item"
            [class.disabled]="
                paginationService.pagination[currentPagination].data &&
                (!paginationService.pagination[currentPagination].data.length ||
                    paginationService.pagination[currentPagination].data.length <
                        paginationService.pagination[currentPagination].limit)
            "
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadNextPage(currentPagination)"
            >
                Suivant
            </button>
        </li>
    </ul>
</nav>
