import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { IEnquiryPaymentSummary } from 'src/app/interfaces/enquiry-payment-summary.interface';

@Injectable({
  providedIn: 'root'
})
export class EnquiryPaymentSummaryFirestoreService extends FirestoreService<IEnquiryPaymentSummary> {
  protected basePath = 'enquiryPaymentSummaries';
}
