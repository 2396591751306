import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { getYear } from 'date-fns';
import { Subscription } from 'rxjs';
import {
  EnumCurrency,
  getDefaultCurrency,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { IFinancialGoal } from 'src/app/interfaces/financial-goal.interface';
import { IFinancialReport } from 'src/app/interfaces/financial-report.interface';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import { EnumPeriod, getPeriodLabel } from '../financial-reports.component';
import {
  formatPrice,
  getMonthLabel,
  getMonthLabelByQuarter,
  roundNumber
} from 'src/app/misc.utils';
import { FinancialReportsService } from 'src/app/services/financial-reports/financial-reports.service';
import { FinancialGoalsService } from 'src/app/services/financial-goals/financial-goals.service';

interface IFinancialReportAllAndTypes {
  financialReports: {
    label: string;
    financialReport: IFinancialReport | null;
  }[];
  all: IFinancialReport | null;
}

@Component({
  selector: 'app-financial-report-total',
  templateUrl: './financial-report-total.component.html',
  styleUrls: ['./financial-report-total.component.scss']
})
export class FinancialReportTotalComponent implements OnInit, OnDestroy, OnChanges {
  @Input('currentYear') currentYear: number = getYear(new Date());
  @Input('currentCurrency') currentCurrency: EnumCurrency | null = null;
  @Input('currentPeriod') currentPeriod: EnumPeriod = EnumPeriod.months;

  @Output() updateCurrentPeriod: EventEmitter<EnumPeriod> = new EventEmitter();

  @ViewChild('modalYearlyGoal', { static: false }) modalYearlyGoalElement: ElementRef;

  EnumPeriod = EnumPeriod;

  getPeriodLabel = getPeriodLabel;
  getMonthLabelByQuarter = getMonthLabelByQuarter;
  getMonthLabel = getMonthLabel;
  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getUserFullname = getUserFullname;
  formatPrice = formatPrice;
  roundNumber = roundNumber;
  getDefaultCurrency = getDefaultCurrency;

  faEdit = faEdit;

  formGoal: FormGroup = new FormGroup({
    yearlyGoal: new FormControl(0, Validators.required),
    quarterlyGoal: new FormControl(0, Validators.required),
    monthlyGoal: new FormControl(0, Validators.required),
    userId: new FormControl(null)
  });

  savingGoal: boolean = false;
  loading: boolean = false;
  loadingYearsOnly: boolean = false;

  financialGoals: IFinancialGoal[] = [];
  financialGoalsByUserId: { [userId: string]: IFinancialGoal | null } = {};
  companyFinancialGoal: IFinancialGoal | null = null;

  loadingUsers: boolean = false;
  usersObj: { [key: string]: IUser } = {};
  usersDisplayedInModalList: string[] = [];

  subscriptions = new Subscription();
  subscriptionsForYear = new Subscription();

  financialReports: {
    [year: string]: IFinancialReport[];
  } = {};
  financialReportsByPeriod: {
    [period: string]: {
      [year: string]: IFinancialReportAllAndTypes;
    };
  } = {};

  constructor(
    private financialReportsService: FinancialReportsService,
    private financialGoalsService: FinancialGoalsService
  ) {
    this.resetFinancialReportsDisplayedData(this.currentYear);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentYear || changes.currentCurrency) {
      this.loadAll();
    }
  }

  ngOnDestroy(): void {
    this.subscriptionsForYear.unsubscribe();
    this.subscriptions.unsubscribe();

    this.removeModal();
  }

  loadAll(): void {
    this.loading = true;

    this.resetSubscriptionForYear();

    this.loadFinancialReports();
    this.loadFinancialReportsYearsOnly();
  }

  resetSubscriptionForYear(): void {
    if (this.subscriptionsForYear) {
      this.subscriptionsForYear.unsubscribe();

      this.subscriptionsForYear = new Subscription();
    }
  }

  loadFinancialReportsYearsOnly(): void {
    this.loadingYearsOnly = true;

    // By years
    this.subscriptionsForYear.add(
      this.financialReportsService
        .getAllForCurrencyOnlyYears(this.currentCurrency)
        .subscribe((financialReports: IFinancialReport[]) => {
          if (typeof this.financialReportsByPeriod[EnumPeriod.years] === 'undefined') {
            this.financialReportsByPeriod[EnumPeriod.years] = {};
          }

          if (
            typeof this.financialReportsByPeriod[EnumPeriod.years][this.currentYear] === 'undefined'
          ) {
            this.financialReportsByPeriod[EnumPeriod.years][this.currentYear] = {
              all: null,
              financialReports: []
            };
          }

          this.financialReportsByPeriod[EnumPeriod.years][this.currentYear].financialReports = [];

          for (const financialReport of financialReports) {
            this.financialReportsByPeriod[EnumPeriod.years][this.currentYear].financialReports.push(
              {
                label: financialReport.year.toString(),
                financialReport
              }
            );
          }

          this.financialReportsByPeriod[EnumPeriod.years][this.currentYear].financialReports.sort(
            (a, b) => (a.label > b.label ? -1 : 1)
          );

          // Fake financial reports for all years
          this.financialReportsByPeriod[EnumPeriod.years][this.currentYear].all = {
            currency: this.currentCurrency,
            year: null,
            quarter: null,
            month: null,
            nbContracts: 0,
            nbCompleted: 0,
            completedPercent: 0,
            marginHT: 0,
            marginPercent: 0,
            ca: 0,
            usersId: []
          } as IFinancialReport;

          for (const financialReport of this.financialReportsByPeriod[EnumPeriod.years][
            this.currentYear
          ].financialReports) {
            for (const field of ['ca', 'nbContracts', 'nbCompleted', 'marginHT']) {
              this.financialReportsByPeriod[EnumPeriod.years][this.currentYear].all[field] +=
                financialReport.financialReport[field];
            }
          }

          if (this.financialReportsByPeriod[EnumPeriod.years][this.currentYear].all.ca) {
            this.financialReportsByPeriod[EnumPeriod.years][this.currentYear].all.marginPercent =
              roundNumber(
                (this.financialReportsByPeriod[EnumPeriod.years][this.currentYear].all.marginHT *
                  100) /
                  this.financialReportsByPeriod[EnumPeriod.years][this.currentYear].all.ca
              );
          }

          if (this.financialReportsByPeriod[EnumPeriod.years][this.currentYear].all.nbContracts) {
            this.financialReportsByPeriod[EnumPeriod.years][this.currentYear].all.completedPercent =
              roundNumber(
                (this.financialReportsByPeriod[EnumPeriod.years][this.currentYear].all.nbCompleted *
                  100) /
                  this.financialReportsByPeriod[EnumPeriod.years][this.currentYear].all.nbContracts
              );
          }

          this.loadingYearsOnly = false;
        })
    );
  }

  loadFinancialReports(): void {
    this.subscriptionsForYear.add(
      this.financialReportsService
        .getAllForCurrencyForYear(this.currentCurrency, this.currentYear)
        .subscribe((financialReports: IFinancialReport[]) => {
          this.financialReports[this.currentYear] = financialReports;

          this.organiseFinancialReports(this.currentYear);
        })
    );

    // Load data for previous year
    this.subscriptionsForYear.add(
      this.financialReportsService
        .getAllForCurrencyForYear(this.currentCurrency, this.currentYear - 1)
        .subscribe((financialReports: IFinancialReport[]) => {
          this.financialReports[this.currentYear - 1] = financialReports;

          this.organiseFinancialReports(this.currentYear - 1);
        })
    );

    this.subscriptionsForYear.add(
      this.financialGoalsService
        .getAllForCurrencyForYear(this.currentCurrency, this.currentYear)
        .subscribe((financialGoals: IFinancialGoal[]) => {
          this.financialGoals = financialGoals;

          this.companyFinancialGoal = null;
          this.financialGoalsByUserId = {};

          for (const financialGoal of this.financialGoals) {
            if (!financialGoal.userId) {
              this.companyFinancialGoal = financialGoal;
            } else {
              this.financialGoalsByUserId[financialGoal.userId] = financialGoal;
            }
          }
        })
    );
  }

  async submitGoalForm(): Promise<void> {
    this.formGoal.markAsTouched();

    if (this.formGoal.status === 'VALID') {
      let data: IFinancialGoal = Object.assign(
        {
          currency: this.currentCurrency,
          year: this.currentYear
        },
        this.formGoal.value
      );

      this.savingGoal = true;

      this.formGoal.disable();

      data.id = data.currency + '_' + data.year + '_' + (data.userId ?? 'all');

      this.financialGoalsService
        .createWithId(data.id, data)
        .then(async id => {
          this.formGoal.reset();
          this.formGoal.enable();
          this.savingGoal = false;

          this.removeModal();
        })
        .catch(err => {
          console.log(err);
          this.savingGoal = false;

          alert(err.message);
        })
        .finally(() => {
          this.formGoal.enable();
        });
    }
  }

  removeModal(): void {
    window['$'](this.modalYearlyGoalElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  getPeriods(): EnumPeriod[] {
    return Object.values(EnumPeriod);
  }

  showYearlyGoalModal(userId: string | null = null): void {
    this.formGoal.get('userId').setValue(userId);

    if (userId) {
      if (this.financialGoalsByUserId[userId]) {
        this.assignFinancialGoalToFormGoal(this.financialGoalsByUserId[userId]);
      } else if (this.usersObj[userId] && this.usersObj[userId].goals[this.currentYear]) {
        this.formGoal
          .get('yearlyGoal')
          .setValue(this.usersObj[userId].goals[this.currentYear].year.goal ?? 0);
        this.formGoal
          .get('quarterlyGoal')
          .setValue(this.usersObj[userId].goals[this.currentYear].q1.goal ?? 0);
        this.formGoal
          .get('monthlyGoal')
          .setValue(
            this.usersObj[userId].goals[this.currentYear].q1.goal
              ? Math.round(this.usersObj[userId].goals[this.currentYear].q1.goal / 3)
              : 0
          );
      }
    } else {
      this.assignFinancialGoalToFormGoal(this.companyFinancialGoal);
    }

    window['$'](this.modalYearlyGoalElement.nativeElement).modal('show');
  }

  assignFinancialGoalToFormGoal(financialGoal: IFinancialGoal | null = null): void {
    this.formGoal.get('yearlyGoal').setValue(financialGoal?.yearlyGoal ?? 0);
    this.formGoal.get('quarterlyGoal').setValue(financialGoal?.quarterlyGoal ?? 0);
    this.formGoal.get('monthlyGoal').setValue(financialGoal?.monthlyGoal ?? 0);
  }

  changeCurrentPeriod(newPeriod: EnumPeriod): void {
    this.currentPeriod = newPeriod;

    this.updateCurrentPeriod.emit(this.currentPeriod);
  }

  organiseFinancialReports(year: number): void {
    this.resetFinancialReportsDisplayedData(year);

    for (const financialReport of this.financialReports[year]) {
      if (financialReport.month) {
        this.financialReportsByPeriod[EnumPeriod.months][year].financialReports[
          financialReport.month - 1
        ].financialReport = financialReport;
      } else if (financialReport.quarter) {
        this.financialReportsByPeriod[EnumPeriod.quarters][year].financialReports[
          financialReport.quarter - 1
        ].financialReport = financialReport;
      } else {
        this.financialReportsByPeriod[EnumPeriod.months][year].all = financialReport;
        this.financialReportsByPeriod[EnumPeriod.quarters][year].all = financialReport;
      }
    }

    this.loading = false;
  }

  resetFinancialReportsDisplayedData(year: number): void {
    this.formGoal.reset();

    for (const period of this.getPeriods()) {
      if (typeof this.financialReportsByPeriod[period] === 'undefined') {
        this.financialReportsByPeriod[period] = {};
      }

      this.financialReportsByPeriod[period][year] = {
        financialReports: [],
        all: null
      };

      let countItems: number = 0;
      switch (period) {
        case EnumPeriod.months:
          countItems = 12;
          break;
        case EnumPeriod.quarters:
          countItems = 4;
          break;
      }

      for (let i = 1; i <= countItems; i++) {
        this.financialReportsByPeriod[period][year].financialReports.push({
          label: period === EnumPeriod.quarters ? this.getMonthLabelByQuarter(i) : getMonthLabel(i),
          financialReport: null
        });
      }
    }
  }

  cloneVariable(variable: any): any {
    return JSON.parse(JSON.stringify(variable));
  }

  getPercentageToGoal(
    period: EnumPeriod,
    marginHT: number,
    compareToGoalPercentage: number = 100
  ): number {
    let goal: number = this.getCompanyFinancialGoalAccordingToPeriod(period);

    if (goal > 0) {
      goal += ((compareToGoalPercentage - 100) * goal) / 100;

      return this.roundNumber((marginHT * 100) / goal);
    }

    return 0;
  }

  getCompanyFinancialGoalAccordingToPeriod(period: EnumPeriod): number {
    let goal: number = 0;

    if (this.companyFinancialGoal) {
      switch (period) {
        case EnumPeriod.months:
          goal = this.companyFinancialGoal.monthlyGoal;
          break;
        case EnumPeriod.quarters:
          goal = this.companyFinancialGoal.quarterlyGoal;
          break;
        case EnumPeriod.years:
          goal = this.companyFinancialGoal.yearlyGoal;
          break;
      }
    }

    return goal;
  }
}
