import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { Subscription } from 'rxjs';
import { AircraftService } from 'src/app/services/aircrafts/aircrafts.service';
import { IAircraft } from 'src/app/interfaces/aircraft.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { AircraftModelService } from 'src/app/services/aircraft-models/aircraft-models.service';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { getCountryLabel, getContinentLabel } from 'src/app/enums/continent-code.enum';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-aircraft-edit',
  templateUrl: './aircraft-edit.component.html',
  styleUrls: ['./aircraft-edit.component.scss']
})
export class AircraftEditComponent implements OnInit {
  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  aircraft: IAircraft | null = null;
  aircraftId: string;
  airlineId: string;
  airline: IAirline | null = null;
  countriesList: Array<{
    title: string;
    value: string;
  }> = [];
  statusList: Array<{
    title: string;
    value: string;
  }> = [
    {
      title: 'Actif',
      value: 'Active'
    },
    {
      title: 'Actif (parqué)',
      value: 'Active (parked)'
    },
    {
      title: 'En commande',
      value: 'On order'
    },
    {
      title: 'Démantelé',
      value: 'Scrapped'
    },
    {
      title: 'Stocké',
      value: 'Stored'
    },
    {
      title: 'Détruit après accident',
      value: 'Written off'
    }
  ];
  aircraftModels: IAircraftModel[] = [];
  @ViewChild('modalAircraftModelAdd', { static: false }) modalAircraftModelAddElement: ElementRef;
  triggerSaveAicraftModal: boolean = false;

  aircraftModel: IAircraftModel | null = null;

  subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private aircraftService: AircraftService,
    private airlineService: AirlineService,
    private aircraftModelService: AircraftModelService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.remoteService.aircraftModelsObservable.subscribe(
      (aircraftModels: IAircraftModel[]) => (this.aircraftModels = aircraftModels)
    );

    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      serialNumber: [''],
      status: ['', [Validators.required]],
      model: [''],
      aircraftModelId: ['', [Validators.required]],
      type: [''],
      registration: [''],
      airlineContinentCode: [''],
      airlineCountryCode: [''],
      airlineContinentTitle: [''],
      airlineCountryTitle: [''],
      airlineId: [''],
      airlineTitle: [''],
      firstFlightDate: [''],
      testRegistration: [''],
      seatTotal: [''],
      seatFirst: [''],
      seatBusiness: [''],
      seatPremiumEconomy: [''],
      seatEconomy: [''],
      engines: [''],
      url: [''],
      weight: [''],
      volume: [''],
      isCargo: [false],
      history: new FormArray([]),
      homebase: [''],
      imageOutsideUrl: [''],
      imageInsideUrl: [''],
      imageOutsideZoomLevel: [1],
      imageInsideZoomLevel: [1]
    });

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      this.aircraftId = this.activatedRoute.snapshot.paramMap.get('aircraftId');
      this.airlineId = this.activatedRoute.snapshot.paramMap.get('airlineId');

      if (!this.aircraftId) {
        await this.aclService.checkAclAccess(EnumAcl.aircraftsAdd);
        this.form.enable();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.aircraftsEdit);
      }

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.removeModal();
  }

  removeModal(): void {
    window['$'](this.modalAircraftModelAddElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      await this.remoteService.loadAircraftModels();

      if (this.aircraftId) {
        this.loadAircraft();
      } else if (this.airlineId) {
        this.loadAirline(this.airlineId);
      }
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadAirline(airlineId: string): void {
    this.subscriptions.add(
      this.airlineService.getFromId(airlineId).subscribe((airline: IAirline) => {
        this.airline = airline;

        this.form.get('airlineId').setValue(this.airline?.id ?? null);
        this.form.get('airlineTitle').setValue(this.airline?.title ?? null);
        this.form.get('airlineCountryCode').setValue(this.airline?.countryCode ?? null);
        this.form.get('airlineContinentCode').setValue(this.airline?.continentCode ?? null);
        this.form
          .get('airlineCountryTitle')
          .setValue(getContinentLabel(this.airline?.continentCode));
        this.form.get('airlineContinentTitle').setValue(getCountryLabel(this.airline?.countryCode));
      })
    );
  }

  loadAircraft(): void {
    this.subscriptions.add(
      this.aircraftService.getFromId(this.aircraftId).subscribe((aircraft: IAircraft) => {
        this.aircraft = aircraft;

        this.setAircraft();

        if (this.aircraft.aircraftModelId) {
          this.loadAircraftModel(this.aircraft.aircraftModelId);
        }
      })
    );
  }

  loadAircraftModel(aircraftModelId: string): void {
    this.subscriptions.add(
      this.aircraftModelService
        .getFromId(aircraftModelId)
        .subscribe((aircraftModel: IAircraftModel) => {
          this.aircraftModel = aircraftModel;
        })
    );
  }

  setAircraft(): void {
    if (this.form && this.aircraft) {
      this.form.get('serialNumber').setValue(this.aircraft.serialNumber);
      this.form.get('status').setValue(this.aircraft.status);
      this.form.get('aircraftModelId').setValue(this.aircraft.aircraftModelId);
      this.form.get('model').setValue(this.aircraft.model);
      this.form.get('type').setValue(this.aircraft.type);
      this.form.get('registration').setValue(this.aircraft.registration);
      this.form.get('airlineId').setValue(this.aircraft.airlineId);
      this.form.get('airlineCountryCode').setValue(this.aircraft.airlineCountryCode);
      this.form.get('airlineContinentCode').setValue(this.aircraft.airlineContinentCode);
      this.form.get('airlineCountryTitle').setValue(this.aircraft.airlineCountryTitle);
      this.form.get('airlineContinentTitle').setValue(this.aircraft.airlineContinentTitle);
      this.form.get('airlineTitle').setValue(this.aircraft.airlineTitle);
      this.form.get('firstFlightDate').setValue(this.aircraft.firstFlightDate);
      this.form.get('testRegistration').setValue(this.aircraft.testRegistration);
      this.form.get('seatFirst').setValue(this.aircraft.seatFirst);
      this.form.get('seatBusiness').setValue(this.aircraft.seatBusiness);
      this.form.get('seatPremiumEconomy').setValue(this.aircraft.seatPremiumEconomy);
      this.form.get('seatEconomy').setValue(this.aircraft.seatEconomy);
      this.form.get('engines').setValue(this.aircraft.engines);
      this.form.get('url').setValue(this.aircraft.url);
      this.form.get('weight').setValue(this.aircraft.weight);
      this.form.get('volume').setValue(this.aircraft.volume);
      this.form.get('isCargo').setValue(this.aircraft.isCargo);
      this.form.get('homebase').setValue(this.aircraft.homebase);
      this.form.get('imageOutsideUrl').setValue(this.aircraft.imageOutsideUrl);
      this.form.get('imageInsideUrl').setValue(this.aircraft.imageInsideUrl);
      this.form.get('imageOutsideZoomLevel').setValue(this.aircraft.imageOutsideZoomLevel);
      this.form.get('imageInsideZoomLevel').setValue(this.aircraft.imageInsideZoomLevel);

      for (const i in this.aircraft.history) {
        this.addOneHistory();

        const historyFormArray = this.form.get('history') as FormArray;

        historyFormArray
          .at(parseInt(i))
          .get('registration')
          .setValue(this.aircraft.history[i].registration);
        historyFormArray
          .at(parseInt(i))
          .get('deliveryDate')
          .setValue(this.aircraft.history[i].deliveryDate);
        historyFormArray
          .at(parseInt(i))
          .get('airlineId')
          .setValue(this.aircraft.history[i].airlineId);
        historyFormArray
          .at(parseInt(i))
          .get('airlineTitle')
          .setValue(this.aircraft.history[i].airlineTitle);
        historyFormArray
          .at(parseInt(i))
          .get('airlineContinentCode')
          .setValue(this.aircraft.history[i].airlineContinentCode);
        historyFormArray
          .at(parseInt(i))
          .get('airlineCountryCode')
          .setValue(this.aircraft.history[i].airlineCountryCode);
        historyFormArray
          .at(parseInt(i))
          .get('airlineContinentTitle')
          .setValue(this.aircraft.history[i].airlineContinentTitle);
        historyFormArray
          .at(parseInt(i))
          .get('airlineCountryTitle')
          .setValue(this.aircraft.history[i].airlineCountryTitle);
        historyFormArray.at(parseInt(i)).get('remark').setValue(this.aircraft.history[i].remark);
      }

      this.form.enable();
    }
  }

  updateModel(): void {
    for (const aircraftModel of this.aircraftModels) {
      if (aircraftModel.id === this.form.value.aircraftModelId) {
        this.form.get('model').setValue(aircraftModel.family);
        this.form.get('type').setValue(aircraftModel.slug);
        break;
      }
    }
  }

  addOneHistory(): void {
    const historyFormArray = this.form.get('history') as FormArray;

    historyFormArray.push(
      new FormGroup({
        registration: new FormControl('', [Validators.required]),
        deliveryDate: new FormControl('', [Validators.required]),
        airlineId: new FormControl(''),
        airlineTitle: new FormControl(''),
        airlineContinentCode: new FormControl(''),
        airlineCountryCode: new FormControl(''),
        airlineContinentTitle: new FormControl(''),
        airlineCountryTitle: new FormControl(''),
        remark: new FormControl('')
      })
    );

    this.form.updateValueAndValidity();
  }

  async submitForm(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.status == 'VALID') {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      data['isActive'] = ['Active', 'Active (parked)'].indexOf(data['status']) !== -1;
      data['classes'] = [];

      data['seatTotal'] = 0;
      for (const field of ['seatFirst', 'seatBusiness', 'seatPremiumEconomy', 'seatEconomy']) {
        if (data[field]) {
          data['seatTotal'] += data[field];

          switch (field) {
            case 'seatFirst':
              data['classes'].push('F');
              break;
            case 'seatBusiness':
              data['classes'].push('C');
              break;
            case 'seatPremiumEconomy':
              data['classes'].push('W');
              break;
            case 'seatEconomy':
              data['classes'].push('Y');
              break;
          }
        }
      }

      this.sending = true;

      this.form.disable();

      if (this.aircraftId) {
        this.remoteService
          .updateDocumentToCollection('aircrafts', this.aircraftId, data)
          .then(() => {
            this.sending = false;
            this.router.navigate(['/admin/aircrafts/view/' + this.aircraftId]);
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      } else {
        let model: string = this.form.value.aircraftModelId;

        for (const aircraftModel of this.aircraftModels) {
          if (aircraftModel.id === this.form.value.aircraftModelId) {
            model = aircraftModel.family;
            break;
          }
        }

        if (data['serialNumber']) {
          this.remoteService
            .addDocumentToCollectionWithId('aircrafts', model + '-' + data['serialNumber'], data)
            .then((docId: string) => {
              this.aircraftId = docId;

              this.sending = false;
              this.router.navigate(['/admin/aircrafts/view/' + this.aircraftId]);
            })
            .catch(err => {
              this.sending = false;
              this.form.enable();

              alert(err.message);
            });
        } else {
          this.remoteService
            .addDocumentToCollection('aircrafts', data)
            .then((docId: string) => {
              this.aircraftId = docId;

              this.sending = false;
              this.router.navigate(['/admin/aircrafts/view/' + this.aircraftId]);
            })
            .catch(err => {
              this.sending = false;
              this.form.enable();

              alert(err.message);
            });
        }
      }
    }
  }

  setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string;
    }>;
  }): void {
    for (let field of $event.fields) {
      const nameList: Array<string> = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }
      formControl.setValue(field.value);

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  addContactCategory(): void {
    const companyContactsFormArray = this.form.get('companyContacts') as FormArray;

    companyContactsFormArray.push(
      new FormGroup({
        title: new FormControl('', [Validators.required]),
        contacts: new FormArray([])
      })
    );

    this.form.updateValueAndValidity();
  }

  deleteOneHistory(i: number): void {
    const result = confirm(
      'La suppression de cet historique sera permanente. Êtes-vous sûr de vouloir continuer?'
    );

    if (result) {
      const historysFormArray = this.form.get('history') as FormArray;

      historysFormArray.removeAt(i);

      this.form.updateValueAndValidity();
    }
  }

  updateWeightAndVolume(): void {
    if (!this.form.value.isCargo) {
      // Cargo removed, we reset its values
      for (const fieldCargo of ['weight', 'volume']) {
        this.form.get(fieldCargo).setValue(null);
      }
    }
  }

  addAircraftModel(): void {
    window['$'](this.modalAircraftModelAddElement.nativeElement).modal('show');
  }

  saveAircraftModel(): void {
    this.triggerSaveAicraftModal = !this.triggerSaveAicraftModal;
  }

  getCreatedAircraftModel(aircraftModelId: string): void {
    window['$'](this.modalAircraftModelAddElement.nativeElement).modal('hide');

    if (aircraftModelId) {
      this.form.get('aircraftModelId').setValue(aircraftModelId);
    }
  }

  triggerInputFile(fileFieldName: string): void {
    if (this.form.enabled) {
      document.getElementById(fileFieldName + 'Input').click();
    }
  }

  changeInputFile(fileFieldName: string, fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.upload(fileFieldName, fileInput.target.files[0]);
    }
  }

  upload(fileFieldName: string, file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      this.form.disable();

      const result = this.remoteService.upload(
        'aircrafts/images',
        this.remoteService.generateRandomId(),
        file,
        'file'
      );

      result['task']
        .snapshotChanges()
        .pipe(
          finalize(() => {
            result['ref'].getDownloadURL().subscribe((downloadUrl: string) => {
              this.form.get(fileFieldName).setValue(downloadUrl);

              (<HTMLInputElement>document.getElementById(fileFieldName + 'Input')).value = '';

              this.form.enable();

              this.form.updateValueAndValidity();
            });
          })
        )
        .subscribe();
    });
  }

  deleteImage(fileFieldName: string): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
      this.form.get(fileFieldName).setValue(null);
    }
  }
}
