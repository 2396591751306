export enum EnumCrawlerUrlStatus {
  notStarted = 'notStarted',
  started = 'started',
  fetchedRemoteContent = 'fetchedRemoteContent',
  completed = 'completed',
  error = 'error'
}

export const getEnumCrawlerUrlStatusLabel = (status: EnumCrawlerUrlStatus): string => {
  switch (status) {
    default:
      return status;
    case EnumCrawlerUrlStatus.notStarted:
      return 'Non débuté';
    case EnumCrawlerUrlStatus.started:
      return 'Débuté';
    case EnumCrawlerUrlStatus.fetchedRemoteContent:
      return 'Contenu récupéré';
    case EnumCrawlerUrlStatus.completed:
      return 'Terminé';
    case EnumCrawlerUrlStatus.error:
      return 'Erreur';
  }
};

export const getEnumCrawlerUrlStatusColor = (status: EnumCrawlerUrlStatus): string => {
  switch (status) {
    default:
      return 'primary';
    case EnumCrawlerUrlStatus.notStarted:
      return 'warning';
    case EnumCrawlerUrlStatus.completed:
      return 'success';
    case EnumCrawlerUrlStatus.error:
      return 'danger';
  }
};
