import { IInvoice } from '../interfaces/invoice.interface';

export enum EnumSuggestionInvoiceReason {
  matchingInvoiceRef = 'matchingInvoiceRef',
  sameAmountTtcTotal = 'sameAmountTtcTotal',
  enquiryMatch = 'enquiryMatch'
}

export const getSuggestionInvoiceReasonLabel = (reason: EnumSuggestionInvoiceReason): string => {
  switch (reason) {
    default:
      return reason;
    case EnumSuggestionInvoiceReason.matchingInvoiceRef:
      return 'Référence de facture identique';
    case EnumSuggestionInvoiceReason.sameAmountTtcTotal:
      return 'Même montant total TTC';
    case EnumSuggestionInvoiceReason.enquiryMatch:
      return "Facture d'une demande qui matche";
  }
};

export interface ISuggestedInvoice {
  reasons: EnumSuggestionInvoiceReason[];
  invoice: IInvoice;
}
