import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { EncaissementFirestoreService } from './encaissements-firestore.service';
import { IEncaissement } from 'src/app/interfaces/encaissement.interface';

@Injectable({
  providedIn: 'root'
})
export class EncaissementService {
  constructor(private encaissementFirestoreService: EncaissementFirestoreService) {}

  create(encaissement: IEncaissement): Promise<string> {
    return this.encaissementFirestoreService.create(encaissement);
  }

  update(encaissement: IEncaissement): Promise<string> {
    return this.encaissementFirestoreService.update(encaissement);
  }

  delete(id: string): Promise<void> {
    return this.encaissementFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IEncaissement> {
    return this.encaissementFirestoreService.docValue$(id);
  }

  getAll(): Observable<IEncaissement[]> {
    return this.encaissementFirestoreService.collectionValues$();
  }

  getAllForInvoice(invoiceId: string): Observable<IEncaissement[]> {
    return this.encaissementFirestoreService.collectionValues$(ref =>
      ref.where('invoiceId', '==', invoiceId).orderBy('created', 'asc')
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.encaissementFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IEncaissement[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.encaissementFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }
}
