<breadcrumbs
    [items]="[
        {
            title: 'Liste des pays',
            path: '/admin/countries/list'
        },
        {
            title: country ? countriesFr[country.code] ?? country.code : '',
            path: '/admin/countries/view/' + countryId
        }
    ]"
></breadcrumbs>

<div class="row justify-content-center">
    <div class="col-md-6">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="skeleton-text"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && country">
                <h3
                    [innerHtml]="countriesFr[country.code] ?? country.code"
                    class="text-center"
                ></h3>

                <ul class="list-unstyled">
                    <li>
                        <strong>Est incident diplomatique ?</strong> :
                        {{ country.hasDiplomaticDifficulties ? "Oui" : "Non" }}
                    </li>
                </ul>

                <div class="text-center mt-4">
                    <a
                        [routerLink]="'/admin/countries/edit/' + country.id"
                        class="btn btn-primary mr-4"
                        *ngIf="hasAclAccess(EnumAcl.countriesEdit)"
                        >Éditer</a
                    >
                    <button
                        type="button"
                        (click)="delete()"
                        class="btn btn-primary ml-4"
                        *ngIf="hasAclAccess(EnumAcl.countriesDelete)"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
