<breadcrumbs
    [items]="
        employeeBonusRuleId
            ? [
                  {
                      title: 'Liste des règles des bonus des commerciaux',
                      path: '/admin/employee-bonus-rules/list'
                  },
                  {
                      title: employeeBonusRule?.name,
                      path: '/admin/employee-bonus-rules/view/' + employeeBonusRuleId
                  },
                  {
                      title: 'Édition d\'une règle',
                      path: '/admin/employee-bonus-rules/edit/' + employeeBonusRuleId
                  }
              ]
            : [
                  {
                      title: 'Liste des règles des bonus des commerciaux',
                      path: '/admin/employee-bonus-rules/list'
                  },
                  {
                      title: 'Ajout d\'une règle',
                      path: '/admin/employee-bonus-rules/add'
                  }
              ]
    "
></breadcrumbs>

<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="name">Nom</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="name"
                            [class.is-invalid]="
                                (form.touched || form.controls['name'].touched) &&
                                form.controls['name'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['name'].touched) &&
                                    form.controls['name'].errors &&
                                    form.controls['name'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Département(s) concerné(s)</label>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                name="enquiryTypes"
                                class="form-check-input"
                                id="enquiryType-all"
                                [checked]="
                                    form.value.enquiryTypes.length === getEnquiryTypes().length
                                "
                                (change)="toggleAllEnquiryType()"
                            />
                            <label class="form-check-label" for="enquiryType-all"
                                >Tous les départements
                            </label>
                        </div>
                        <ng-container
                            *ngIf="form.value.enquiryTypes.length !== getEnquiryTypes().length"
                        >
                            <div class="form-check" *ngFor="let enquiryType of getEnquiryTypes()">
                                <input
                                    type="checkbox"
                                    name="enquiryTypes"
                                    class="form-check-input"
                                    [id]="'enquiryType-' + enquiryType"
                                    [checked]="form.value.enquiryTypes.includes(enquiryType)"
                                    (change)="toggleEnquiryType(enquiryType)"
                                />
                                <label
                                    class="form-check-label"
                                    [attr.for]="'enquiryType-' + enquiryType"
                                    [innerText]="getEnumEnquiryTypeLabel(enquiryType)"
                                >
                                </label>
                            </div>
                        </ng-container>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['enquiryTypes'].touched) &&
                                    form.controls['enquiryTypes'].errors &&
                                    form.controls['enquiryTypes'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="minimumNbEnquiries">Nombre de demandes minimum par mois</label>
                        <input
                            minimum="0"
                            type="text"
                            class="form-control"
                            formControlName="minimumNbEnquiries"
                            [class.is-invalid]="
                                (form.touched || form.controls['minimumNbEnquiries'].touched) &&
                                form.controls['minimumNbEnquiries'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['minimumNbEnquiries'].touched) &&
                                    form.controls['minimumNbEnquiries'].errors &&
                                    form.controls['minimumNbEnquiries'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="minimumMarginHT">Marge minimum par contrat par mois</label>
                        <div class="input-group mb-3">
                            <input
                                type="number"
                                minimum="0"
                                class="form-control"
                                formControlName="minimumMarginHT"
                                [class.is-invalid]="
                                    (form.touched || form.controls['minimumMarginHT'].touched) &&
                                    form.controls['minimumMarginHT'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <span
                                class="input-group-text"
                                [innerText]="getEnumCurrencySymbol(getDefaultCurrency())"
                            ></span>
                        </div>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['minimumMarginHT'].touched) &&
                                    form.controls['minimumMarginHT'].errors &&
                                    form.controls['minimumMarginHT'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="amountBonus">Montant du bonus</label>
                        <div class="input-group mb-3">
                            <input
                                type="number"
                                class="form-control"
                                formControlName="amountBonus"
                                [class.is-invalid]="
                                    (form.touched || form.controls['amountBonus'].touched) &&
                                    form.controls['amountBonus'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <span
                                class="input-group-text"
                                [innerText]="getEnumCurrencySymbol(getDefaultCurrency())"
                            ></span>
                        </div>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['amountBonus'].touched) &&
                                    form.controls['amountBonus'].errors &&
                                    form.controls['amountBonus'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-end">
                        <div class="col-sm-6">
                            <div class="form-check mb-2">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="hasDateEnd"
                                    formControlName="hasDateEnd"
                                    (change)="updatedHasDateEnd()"
                                />
                                <label class="form-check-label" for="hasDateEnd"
                                    >A une date de fin ?</label
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="dateStart">Date de début</label>
                                <select
                                    class="form-select"
                                    formControlName="dateStart"
                                    [class.is-invalid]="
                                        (form.touched || form.controls['dateStart'].touched) &&
                                        form.controls['dateStart'].status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                >
                                    <option
                                        [value]="filter.value"
                                        *ngFor="let filter of filtersByPeriod"
                                        [innerHtml]="filter.title"
                                        [disabled]="
                                            form.value.dateEnd && filter.value > form.value.dateEnd
                                        "
                                    ></option>
                                </select>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || form.controls['dateStart'].touched) &&
                                            form.controls['dateStart'].errors &&
                                            form.controls['dateStart'].errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group" *ngIf="form.value.hasDateEnd">
                                <label for="dateEnd">Date de fin</label>
                                <select
                                    class="form-select"
                                    formControlName="dateEnd"
                                    [class.is-invalid]="
                                        (form.touched || form.controls['dateEnd'].touched) &&
                                        form.controls['dateEnd'].status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                >
                                    <option
                                        [value]="filter.value"
                                        *ngFor="let filter of filtersByPeriod"
                                        [innerHtml]="filter.title"
                                        [disabled]="filter.value < form.value.dateStart"
                                    ></option>
                                </select>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || form.controls['dateEnd'].touched) &&
                                            form.controls['dateEnd'].errors &&
                                            form.controls['dateEnd'].errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="
                    employeeBonusRuleId
                        ? '/admin/employee-bonus-rules/view/' + employeeBonusRuleId
                        : '/admin/employee-bonus-rules/list'
                "
                class="btn btn-outline-primary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>
