import { IStoreData } from './store-data.interface';

export interface IAirlineSupervisor extends IStoreData {
  airlineId: string;
  firstname: string;
  lastname: string;
  phoneNumber: string;
  comment: string;
}

export const getAirlineSupervisorFullname = (
  airlineSupervisor: IAirlineSupervisor | null
): string => {
  return airlineSupervisor ? airlineSupervisor.firstname + ' ' + airlineSupervisor.lastname : '';
};
