import { Injectable } from '@angular/core';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class BankAccountFirestoreService extends FirestoreService<IBankAccount> {
  protected basePath = 'bankAccounts';
}
