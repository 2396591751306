export enum EnumItineraryType {
  oneWay = 'one-way',
  roundTrip = 'round-trip',
  multipleDestinations = 'multiple-destinations'
}

export const getEnumItineraryTypeLabel = (itineraryType: EnumItineraryType): string => {
  switch (itineraryType) {
    default:
      return itineraryType;
    case EnumItineraryType.oneWay:
      return 'One Way';
    case EnumItineraryType.roundTrip:
      return 'Round Trip';
    case EnumItineraryType.multipleDestinations:
      return 'Multiple destinations';
  }
};

export const getEnumItineraryTypeIconUrl = (itineraryType: EnumItineraryType): string => {
  switch (itineraryType) {
    default:
      return itineraryType;
    case EnumItineraryType.oneWay:
      return 'assets/img/icon-right-arrow-white.svg';
    case EnumItineraryType.roundTrip:
      return 'assets/img/icon-round-trip-white.svg';
    case EnumItineraryType.multipleDestinations:
      return 'assets/img/icon-multiple-destinations-white.svg';
  }
};

export const getEnumItineraryTypeMaxTrips = (itineraryType: EnumItineraryType): number | null => {
  switch (itineraryType) {
    default:
      return null;
    case EnumItineraryType.oneWay:
      return 1;
    case EnumItineraryType.roundTrip:
      return 2;
    case EnumItineraryType.multipleDestinations:
      return null;
  }
};
