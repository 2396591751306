<div class="row align-items-center justify-content-center h-100 mb-4">
    <div class="col-md-6 col-xl-4">
        <div class="text-center">
            <img [src]="rootUrl + 'assets/img/logo.png'" alt="Artheau Aviation" />
            <h1>Votre mot de passe</h1>
        </div>
        <form (ngSubmit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()">
            <div class="form-group" *ngIf="!currentEmail">
                <label for="email">Adresse email :</label>
                <input
                    formControlName="email"
                    id="email"
                    class="form-control"
                    type="email"
                    required
                    [class.is-invalid]="
                        form.controls['email'].invalid &&
                        (form.controls['email'].dirty || form.controls['email'].touched)
                    "
                />
                <div
                    *ngIf="
                        form.controls['email'].invalid &&
                        (form.controls['email'].dirty || form.controls['email'].touched) &&
                        form.controls['email'].errors.required
                    "
                    class="invalid-tooltip"
                >
                    Ce champ est obligatoire.
                </div>
            </div>
            <div class="form-group">
                <label for="password">Mot de passe :</label>
                <input
                    formControlName="password"
                    id="password"
                    class="form-control"
                    type="password"
                    required
                    [class.is-invalid]="
                        form.controls['password'].invalid &&
                        (form.controls['password'].dirty || form.controls['password'].touched)
                    "
                />
                <div
                    *ngIf="
                        form.controls['password'].invalid &&
                        (form.controls['password'].dirty || form.controls['password'].touched) &&
                        form.controls['password'].errors.required
                    "
                    class="invalid-tooltip"
                >
                    Ce champ est obligatoire.
                </div>
                <div
                    *ngIf="
                        form.controls['password'].invalid &&
                        (form.controls['password'].dirty || form.controls['password'].touched) &&
                        form.controls['password'].errors['minlength']
                    "
                    class="invalid-tooltip"
                >
                    Le mot de passe doit faire minimum
                    {{
                        form.controls["password"].errors["minlength"]["requiredLength"]
                    }}
                    caractères.
                </div>
            </div>
            <div class="form-group">
                <label for="confirmPassword">Confirmer mot de passe :</label>
                <input
                    formControlName="confirmPassword"
                    id="confirmPassword"
                    class="form-control"
                    type="password"
                    required
                    [class.is-invalid]="
                        form.controls['confirmPassword'].invalid &&
                        (form.controls['confirmPassword'].dirty ||
                            form.controls['confirmPassword'].touched)
                    "
                />
                <div
                    *ngIf="
                        form.controls['confirmPassword'].invalid &&
                        (form.controls['confirmPassword'].dirty ||
                            form.controls['confirmPassword'].touched) &&
                        form.controls['confirmPassword'].errors.required
                    "
                    class="invalid-tooltip"
                >
                    Ce champ est obligatoire.
                </div>
                <div
                    *ngIf="
                        form.controls['confirmPassword'].invalid &&
                        (form.controls['confirmPassword'].dirty ||
                            form.controls['confirmPassword'].touched) &&
                        form.controls['confirmPassword'].errors['isMatching'] === false
                    "
                    class="invalid-tooltip"
                >
                    Les mots de passe ne sont pas identiques.
                </div>
            </div>

            <div class="text-center">
                <button
                    type="submit"
                    class="btn btn-primary"
                    [innerHtml]="sending ? 'Sauvegarde en cours ...' : 'Sauvegarder'"
                    [disabled]="form.disabled"
                ></button>
            </div>
        </form>
    </div>
</div>
