import { Component, OnDestroy, OnInit } from '@angular/core';
import { faArrowLeft, faArrowRight, faHome } from '@fortawesome/free-solid-svg-icons';
import { addDays, format } from 'date-fns';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import { EnquiryFlightService } from 'src/app/services/enquiry-flights/enquiry-flights.service';

@Component({
  selector: 'app-flights-calendar',
  templateUrl: './flights-calendar.component.html',
  styleUrls: ['./flights-calendar.component.scss']
})
export class FlightsCalendarComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  todayStr: string = format(new Date(), 'yyyy-MM-dd');
  firstDateStr: string = format(new Date(), 'yyyy-MM-dd');

  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faHome = faHome;

  calendarDates: string[] = [];

  enquiryFlights: IEnquiryFlight[] = [];
  loadingEnquiryFlights: boolean = false;

  nbDaysDisplayed: number = 5;

  private subscriptions = new Subscription();
  private subscriptionsFlights = new Subscription();

  constructor(private enquiryFlightService: EnquiryFlightService) {}

  ngOnInit(): void {
    this.refreshCalendarDate();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsFlights.unsubscribe();

    window['$']('.tooltip').remove();

    this.removeModal();
  }

  removeModal(): void {
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  refreshCalendarDate(): void {
    this.calendarDates = [];

    for (let i = 0; i < this.nbDaysDisplayed; i++) {
      this.calendarDates.push(format(addDays(new Date(this.firstDateStr), i), 'yyyy-MM-dd'));
    }

    this.loadEnquiryFlights();
  }

  loadEnquiryFlights(): void {
    if (this.calendarDates) {
      this.loadingEnquiryFlights = true;

      if (this.subscriptionsFlights) {
        this.subscriptionsFlights.unsubscribe();
      }

      this.subscriptionsFlights = new Subscription();

      this.subscriptionsFlights.add(
        this.enquiryFlightService
          .getAllForDates(this.calendarDates)
          .subscribe((enquiryFlights: IEnquiryFlight[]) => {
            this.enquiryFlights = enquiryFlights;

            this.enquiryFlights.sort((a: IEnquiryFlight, b: IEnquiryFlight) => {
              if (a.departDate < b.departDate) {
                return -1;
              } else if (a.departDate > b.departDate) {
                return 1;
              } else {
                if (a.departTimeLocal < b.departTimeLocal) {
                  return -1;
                } else if (a.departTimeLocal > b.departTimeLocal) {
                  return 1;
                }
              }

              return 0;
            });

            this.loadingEnquiryFlights = false;
          })
      );
    }
  }

  goPrevPage(): void {
    this.firstDateStr = format(
      addDays(new Date(this.firstDateStr), this.nbDaysDisplayed * -1),
      'yyyy-MM-dd'
    );

    this.refreshCalendarDate();
  }

  goNextPage(): void {
    this.firstDateStr = format(
      addDays(new Date(this.firstDateStr), this.nbDaysDisplayed),
      'yyyy-MM-dd'
    );

    this.refreshCalendarDate();
  }

  goBackToday(): void {
    this.firstDateStr = this.todayStr;

    this.refreshCalendarDate();
  }

  updatedDate(dateStr: string): void {
    this.firstDateStr = dateStr;

    this.refreshCalendarDate();
  }

  updateTooltip(): void {
    window['$']('.tooltip').remove();

    window['$']('[rel="tooltip"]').tooltip({
      html: true,
      boundary: 'window'
    });
  }
}
