export const environment = {
  environmentType: 'staging',
  production: true,
  firebase: {
    apiKey: 'AIzaSyClx40DP1SKazdpM8naE83NVqw0GssTeUk',
    authDomain: 'artheau-aviation-preprod.firebaseapp.com',
    projectId: 'artheau-aviation-preprod',
    storageBucket: 'artheau-aviation-preprod.appspot.com',
    messagingSenderId: '187841639258',
    appId: '1:187841639258:web:0b313372aaa15a980db963',
    measurementId: 'G-63XLF6WQEV'
  },
  automaticallyCreatePipedriveDealForNewEnquiry: false
};
