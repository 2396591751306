import { Component, Input, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import { EnumAcl } from 'src/app/enums/acl.enum';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  EnumExportRequestStatus,
  getExportRequestLabel,
  getExportRequestStatusLabel,
  getExportRequestTypeLabel
} from 'src/app/interfaces/export-request.interface';

@Component({
  selector: 'app-list-export-requests',
  templateUrl: './list-export-requests.component.html',
  styleUrls: ['./list-export-requests.component.scss']
})
export class ListExportRequestsComponent implements OnInit {
  @Input() embed: boolean = false;

  EnumAcl = EnumAcl;
  EnumExportRequestStatus = EnumExportRequestStatus;

  getExportRequestTypeLabel = getExportRequestTypeLabel;
  getExportRequestStatusLabel = getExportRequestStatusLabel;
  getExportRequestLabel = getExportRequestLabel;

  rootUrl: string = './';
  currentPagination: string = 'export-requests-list';

  constructor(public paginationService: PaginationService, private aclService: AclService) {}

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.exportRequestsList);

    this.paginationService.pagination[this.currentPagination].currentPage = 0;

    this.fetchData();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    await this.paginationService.fetchData(this.currentPagination);
  }
}
