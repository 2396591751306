<div class="flights-calendar">
    <div class="flights-calendar-header">
        <div class="flights-calendar-header-start">
            <button
                type="button"
                class="flights-calendar-nav"
                (click)="goBackToday()"
                [disabled]="loadingEnquiryFlights"
                *ngIf="!calendarDates.includes(todayStr)"
            >
                <fa-icon [icon]="faHome"></fa-icon>
                Aujourd'hui
            </button>
        </div>
        <div class="flights-calendar-header-center">
            <button
                type="button"
                class="flights-calendar-nav"
                (click)="goPrevPage()"
                [disabled]="loadingEnquiryFlights"
            >
                <fa-icon [icon]="faArrowLeft"></fa-icon>
            </button>
            <button
                type="button"
                class="flights-calendar-header-dates"
                *ngIf="calendarDates.length"
                data-bs-toggle="modal"
                data-bs-target="#calendarModal"
            >
                <span
                    [innerText]="calendarDates[0] | date : 'EE dd MMMM yyyy' : undefined : 'fr'"
                ></span>
                -
                <span
                    [innerText]="
                        calendarDates[calendarDates.length - 1]
                            | date : 'EE dd MMMM yyyy' : undefined : 'fr'
                    "
                ></span>
            </button>
            <button
                type="button"
                class="flights-calendar-nav"
                (click)="goNextPage()"
                [disabled]="loadingEnquiryFlights"
            >
                <fa-icon [icon]="faArrowRight"></fa-icon>
            </button>
        </div>
        <div class="flights-calendar-header-end"></div>
    </div>
</div>

<app-enquiry-flights-table
    [loading]="loadingEnquiryFlights"
    [enquiryFlights]="enquiryFlights"
></app-enquiry-flights-table>

<div
    class="modal fade"
    id="calendarModal"
    tabindex="-1"
    aria-labelledby="calendarModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="calendarModalLabel">Sélection date</h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <app-calendar
                    [dateStr]="firstDateStr"
                    (updatedDate)="updatedDate($event)"
                ></app-calendar>
            </div>
        </div>
    </div>
</div>
