<breadcrumbs
    [items]="[
        {
            title: 'Liste des encaissements',
            path: '/admin/encaissements/list'
        }
    ]"
></breadcrumbs>

<div class="row">
    <div class="col-md-6 mb-4">
        <a
            [routerLink]="
                invoiceId
                    ? '/admin/encaissements/add/invoice/' + invoiceId
                    : '/admin/encaissements/add'
            "
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.encaissementsAdd)"
            >+ Nouvel encaissement</a
        >
    </div>
    <div class="col-md-6 mb-4 text-end">
        <a
            routerLink="/admin/encaissements/import"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.encaissementsImport)"
            >Importer opérations bancaires</a
        >
    </div>
</div>

<div class="table-responsive">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <ng-container
                    *ngFor="let field of paginationService.pagination[currentPagination].fields"
                >
                    <th
                        scope="col"
                        class="sortable"
                        [class.is-sorted]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                            field.code
                        "
                        [class.asc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'asc'
                        "
                        [class.desc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'desc'
                        "
                        (click)="paginationService.sortField(currentPagination, field.code)"
                        [innerHtml]="field.title"
                        *ngIf="!(field.code === 'invoiceId' && invoiceId)"
                    ></th>
                </ng-container>
            </tr>
        </thead>
        <tbody *ngIf="paginationService.loading">
            <tr *ngFor="let number of [].constructor(10)">
                <td *ngFor="let field of paginationService.pagination[currentPagination].fields">
                    <div class="skeleton-text"></div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="!paginationService.loading">
            <tr
                *ngIf="
                    !paginationService.pagination[currentPagination].data ||
                    !paginationService.pagination[currentPagination].data.length
                "
                class="table-warning text-center"
            >
                <td [attr.colspan]="paginationService.pagination[currentPagination].fields.length">
                    Aucun encaissement n'a été trouvé.
                </td>
            </tr>
            <tr
                *ngFor="let encaissement of paginationService.pagination[currentPagination].data"
                [routerLink]="'/admin/encaissements/view/' + encaissement.id"
                class="clickable"
            >
                <td [innerHtml]="encaissement.libelle"></td>
                <td [innerHtml]="encaissementTypesObj[encaissement.encaissementTypeId]?.name"></td>
                <td [innerHtml]="encaissement.amount"></td>
                <td [innerHtml]="encaissement.currency"></td>
                <td [innerHtml]="bankAccountsObj[encaissement.bankAccountId]?.name"></td>
                <td
                    [innerHtml]="getInvoiceTitle(invoicesObj[encaissement.invoiceId])"
                    *ngIf="!invoiceId"
                ></td>
                <td [innerHtml]="encaissement.date"></td>
                <td [innerHtml]="encaissement.isReconciled ? 'Oui' : 'Non'"></td>
                <td
                    [innerHtml]="
                        usersObj[encaissement.createdBy]
                            ? getUserFullname(usersObj[encaissement.createdBy])
                            : ''
                    "
                ></td>
                <td [innerHtml]="encaissement.created | date : 'dd/MM/yyyy HH:mm'"></td>
            </tr>
        </tbody>
    </table>
</div>

<nav aria-label="Liste navigation" class="mt-4 mb-4" *ngIf="!paginationService.loading">
    <ul class="pagination justify-content-center">
        <li
            class="page-item"
            [class.disabled]="paginationService.pagination[currentPagination].currentPage === 0"
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadPreviousPage(currentPagination)"
            >
                Précédent
            </button>
        </li>
        <li class="page-item disabled">
            <span
                class="page-link"
                [innerHtml]="paginationService.pagination[currentPagination].currentPage + 1"
            ></span>
        </li>
        <li
            class="page-item"
            [class.disabled]="
                paginationService.pagination[currentPagination].data &&
                (!paginationService.pagination[currentPagination].data.length ||
                    paginationService.pagination[currentPagination].data.length <
                        paginationService.pagination[currentPagination].limit)
            "
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadNextPage(currentPagination)"
            >
                Suivant
            </button>
        </li>
    </ul>
</nav>
