import { Component, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Event, Router, RoutesRecognized } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';
moment.locale('fr');

import { RemoteService } from './services/remote.service';
import { AclService } from './services/acl.service';
import { EnumAcl } from './enums/acl.enum';

import { environment } from '../environments/environment';
import { IUserGroup } from './interfaces/user-group.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('menuCloseBtn', { static: false }) menuCloseBtnElement: ElementRef;
  rootUrl: string = './';
  hideLoading: boolean = false;
  hideMenu: boolean = true;
  currentUrl: string;
  isLogged: boolean = false;
  menuItems: Array<{
    title: string;
    url: string | false;
    children: Array<{
      title: string;
      url: string;
    }>;
  }> = [];
  isStaging: boolean = false;

  constructor(
    private router: Router,
    private remoteService: RemoteService,
    private aclService: AclService,
    private zone: NgZone,
    private translateService: TranslateService
  ) {
    // Path to assets
    window['rootUrl'] = './';

    if (environment.environmentType === 'staging') {
      this.isStaging = true;
    }

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.addLangs(['en', 'fr']);
    this.translateService.setDefaultLang('en');

    let browserLang = this.translateService.getBrowserLang(),
      currentLang = browserLang.match(/en|fr/) ? browserLang : 'en';
    this.translateService.use(currentLang);

    this.rootUrl = window['rootUrl'];

    this.router.events.subscribe((event: Event) => {
      if (event instanceof RoutesRecognized) {
        this.currentUrl = event.url;

        // An event triggered when the Router parses the URL and the routes are recognized.
        this.hideMenu =
          (this.currentUrl.match('admin') === null && this.currentUrl.match('public') === null) ||
          (!this.isLogged && this.currentUrl.match('public') !== null);
      }
    });
    this.remoteService.isLoggedObservable.subscribe((isLogged: boolean) => {
      if (isLogged != null) {
        this.isLogged = isLogged;

        this.zone.run(() => {
          this.hideLoading = true;

          this.checkAndRedirectToAdmin(isLogged);

          this.setUpMenuLink();
        });
      }
    });
    this.remoteService.userGroupObservable.subscribe((userGroup: IUserGroup | null) => {
      if (userGroup) {
        this.setUpMenuLink();
      }
    });
  }

  async setUpMenuLink(): Promise<void> {
    this.menuItems = [];

    this.menuItems.push({
      title: 'Tableau de bord',
      url: '/admin/dashboard',
      children: []
    });
    if (this.aclService.hasAclAccess(EnumAcl.flightsCalendarDashboard)) {
      this.menuItems.push({
        title: 'Calendrier des vols',
        url: '/admin/flight-calendar',
        children: []
      });
    }
    if (this.aclService.hasAclAccess(EnumAcl.usersStatistics)) {
      this.menuItems.push({
        title: 'Statistiques',
        url: '/admin/statistics',
        children: []
      });
    } else if (this.aclService.hasAclAccess(EnumAcl.usersUserOwnStatistics)) {
      this.menuItems.push({
        title: 'Mes statistiques',
        url: '/admin/statistics/current-user',
        children: []
      });
    }
    if (this.aclService.hasAclAccess(EnumAcl.enquiryPaymentsSummaryDisplay)) {
      this.menuItems.push({
        title: 'Finance',
        url: '/admin/finance',
        children: []
      });
    } else if (this.aclService.hasAclAccess(EnumAcl.enquiryPaymentsSummaryOwnedEnquiries)) {
      this.menuItems.push({
        title: 'Finance',
        url: '/admin/finance',
        children: []
      });
    }
    if (this.aclService.hasAclAccess(EnumAcl.clientsList)) {
      this.menuItems.push({
        title: 'Clients',
        url: '/admin/clients/list',
        children: []
      });
    }
    if (this.aclService.hasAclAccess(EnumAcl.airlinesList)) {
      this.menuItems.push({
        title: 'Compagnies aériennes',
        url: '/admin/airlines/list',
        children: []
      });
    }
    if (this.aclService.hasAclAccess(EnumAcl.usersList)) {
      this.menuItems.push({
        title: 'Utilisateurs',
        url: '/admin/users/list',
        children: []
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.invoicesList)) {
      this.menuItems.push({
        title: 'Factures',
        url: '/admin/invoices/list',
        children: []
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.encaissementsList)) {
      this.menuItems.push({
        title: 'Encaissements',
        url: '/admin/encaissements/list',
        children: []
      });
    }

    const settingsChildrenLinks: Array<{
      title: string;
      url: string;
    }> = [];

    if (this.aclService.hasAclAccess(EnumAcl.aircraftModelsList)) {
      settingsChildrenLinks.push({
        title: "Modèles d'avion",
        url: '/admin/aircraft-models/list'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.aircraftsList)) {
      settingsChildrenLinks.push({
        title: 'Appareils aériens',
        url: '/admin/aircrafts/list'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.simulateurAircraftModelsList)) {
      settingsChildrenLinks.push({
        title: "Modèles d'avion du simulateur",
        url: '/admin/simulateur-aircraft-models/list'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.airportsList)) {
      settingsChildrenLinks.push({
        title: 'Aéroports',
        url: '/admin/airports/list'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.lieuxDitsList)) {
      settingsChildrenLinks.push({
        title: 'Lieux-dits',
        url: '/admin/lieux-dits/list'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.emailTemplatesList)) {
      settingsChildrenLinks.push({
        title: "Templates d'email",
        url: '/admin/email-templates/list'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.userGroupsList)) {
      settingsChildrenLinks.push({
        title: "Groupes d'utilisateurs",
        url: '/admin/user-groups/list'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.encaissementTypesList)) {
      settingsChildrenLinks.push({
        title: "Types d'encaissements",
        url: '/admin/encaissement-types/list'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.bankAccountsList)) {
      settingsChildrenLinks.push({
        title: 'Comptes bancaires',
        url: '/admin/bank-accounts/list'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.flightBriefCateringsList)) {
      settingsChildrenLinks.push({
        title: 'Caterings des flight briefs',
        url: '/admin/flight-brief-caterings/list'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.crawlerUrlsList)) {
      settingsChildrenLinks.push({
        title: 'Scripts extracteur de données externes',
        url: '/admin/crawler-urls/list'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.backupsList)) {
      settingsChildrenLinks.push({
        title: 'Sauvegardes automatiques',
        url: '/admin/backups'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.countriesList)) {
      settingsChildrenLinks.push({
        title: 'Pays',
        url: '/admin/countries'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.flightChecklistTemplatesList)) {
      settingsChildrenLinks.push({
        title: 'Modèles de checklist des vols',
        url: '/admin/flight-checklist-templates'
      });
    }

    settingsChildrenLinks.push({
      title: 'Mon compte',
      url: '/admin/my-account'
    });

    if (settingsChildrenLinks.length) {
      if (settingsChildrenLinks.length === 1) {
        this.menuItems.push(Object.assign({ children: [] }, settingsChildrenLinks[0]));
      } else {
        this.menuItems.push({
          title: 'Paramètres',
          url: false,
          children: settingsChildrenLinks
        });
      }
    }
  }

  checkAndRedirectToAdmin(isLogged: boolean): void {
    if (this.currentUrl.match('set-password')) {
      return;
    }

    if (this.currentUrl) {
      if (!isLogged && this.currentUrl.match('admin')) {
        this.zone.run(() => {
          this.router.navigate(['/login']);
        });
      } else if (isLogged && !this.currentUrl.match('admin') && !this.currentUrl.match('public')) {
        this.zone.run(() => {
          this.router.navigate(['/admin']);
        });
      }
    } else {
      setTimeout(() => {
        this.checkAndRedirectToAdmin(isLogged);
      }, 300);
    }
  }

  openMenuItem(menuItem: { title: string; url: string | false }): void {
    if (menuItem.url) {
      if (window.innerWidth < 992) {
        this.menuCloseBtnElement.nativeElement.click();
      }

      this.router.navigate([menuItem.url]);
    }
  }
}
