<breadcrumbs [items]="breadcrumbItems"></breadcrumbs>

<form (submit)="submitForm()" [formGroup]="form" class="main-form">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-4 mb-4">
                <div class="form-group">
                    <label for="clientTitle">Client</label>
                    <input
                        class="form-control"
                        id="clientTitle"
                        formControlName="clientTitle"
                        type="text"
                        required
                        [class.is-invalid]="
                            (form.touched || form.controls['clientTitle'].touched) &&
                            form.controls['clientTitle'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    />

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['clientTitle'].touched) &&
                                form.controls['clientTitle'].errors &&
                                form.controls['clientTitle'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>

                <label for="contactFirstname">Contact</label>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <input
                                class="form-control"
                                id="contactFirstname"
                                formControlName="contactFirstname"
                                type="text"
                                placeholder="Prénom du contact"
                                required
                                [class.is-invalid]="
                                    (form.touched || form.controls['contactFirstname'].touched) &&
                                    form.controls['contactFirstname'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            form.controls['contactFirstname'].touched) &&
                                        form.controls['contactFirstname'].errors &&
                                        form.controls['contactFirstname'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <input
                                class="form-control"
                                id="contactLastname"
                                formControlName="contactLastname"
                                type="text"
                                placeholder="Nom du contact"
                                required
                                [class.is-invalid]="
                                    (form.touched || form.controls['contactLastname'].touched) &&
                                    form.controls['contactLastname'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            form.controls['contactLastname'].touched) &&
                                        form.controls['contactLastname'].errors &&
                                        form.controls['contactLastname'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <input
                        class="form-control"
                        id="contactPhone"
                        formControlName="contactPhone"
                        type="tel"
                        placeholder="Téléphone du contact"
                        [class.is-invalid]="
                            (form.touched || form.controls['contactPhone'].touched) &&
                            form.controls['contactPhone'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    />

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['contactPhone'].touched) &&
                                form.controls['contactPhone'].errors &&
                                form.controls['contactPhone'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <input
                        class="form-control"
                        id="contactEmail"
                        formControlName="contactEmail"
                        type="email"
                        placeholder="Email du contact"
                        [class.is-invalid]="
                            (form.touched || form.controls['contactEmail'].touched) &&
                            form.controls['contactEmail'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    />

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['contactEmail'].touched) &&
                                form.controls['contactEmail'].errors &&
                                form.controls['contactEmail'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-4">
                <div class="form-group">
                    <label for="companyTitle">Entreprise</label>
                    <input
                        class="form-control"
                        id="companyTitle"
                        formControlName="companyTitle"
                        type="text"
                        required
                        [class.is-invalid]="
                            (form.touched || form.controls['companyTitle'].touched) &&
                            form.controls['companyTitle'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    />

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['companyTitle'].touched) &&
                                form.controls['companyTitle'].errors &&
                                form.controls['companyTitle'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>

                <label for="agentFirstname">Agent</label>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <input
                                class="form-control"
                                id="agentFirstname"
                                formControlName="agentFirstname"
                                type="text"
                                placeholder="Prénom de l'agent"
                                required
                                [class.is-invalid]="
                                    (form.touched || form.controls['agentFirstname'].touched) &&
                                    form.controls['agentFirstname'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || form.controls['agentFirstname'].touched) &&
                                        form.controls['agentFirstname'].errors &&
                                        form.controls['agentFirstname'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <input
                                class="form-control"
                                id="agentLastname"
                                formControlName="agentLastname"
                                type="text"
                                placeholder="Nom de l'agent"
                                required
                                [class.is-invalid]="
                                    (form.touched || form.controls['agentLastname'].touched) &&
                                    form.controls['agentLastname'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || form.controls['agentLastname'].touched) &&
                                        form.controls['agentLastname'].errors &&
                                        form.controls['agentLastname'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <input
                        class="form-control"
                        id="agentPhone"
                        formControlName="agentPhone"
                        type="tel"
                        placeholder="Téléphone de l'agent"
                        [class.is-invalid]="
                            (form.touched || form.controls['agentPhone'].touched) &&
                            form.controls['agentPhone'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    />

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['agentPhone'].touched) &&
                                form.controls['agentPhone'].errors &&
                                form.controls['agentPhone'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <input
                        class="form-control"
                        id="agentEmail"
                        formControlName="agentEmail"
                        type="email"
                        placeholder="Email de l'agent"
                        [class.is-invalid]="
                            (form.touched || form.controls['agentEmail'].touched) &&
                            form.controls['agentEmail'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    />

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['agentEmail'].touched) &&
                                form.controls['agentEmail'].errors &&
                                form.controls['agentEmail'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 mb-4">
                <div class="form-group">
                    <label
                        for="date"
                        [innerHtml]="getDefaultStringTranslation('QUOTATION.DATE')"
                    ></label>
                    <input
                        class="form-control"
                        id="date"
                        formControlName="date"
                        type="date"
                        required
                        [class.is-invalid]="
                            (form.touched || form.controls['date'].touched) &&
                            form.controls['date'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                        min="2000-01-01"
                    />

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['date'].touched) &&
                                form.controls['date'].errors &&
                                form.controls['date'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                        <div
                            *ngIf="
                                (form.touched || form.controls['date'].touched) &&
                                form.controls['date'].errors &&
                                form.controls['date'].errors['dateBelowMinimum']
                            "
                        >
                            La date doit être supérieure à
                            {{
                                form.controls["date"].errors &&
                                    form.controls["date"].errors["dateMinimum"]
                                    | date : "dd/MM/yyyy"
                            }}.
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label
                        for="title"
                        [innerHtml]="getDefaultStringTranslation('QUOTATION.QUOTATION_NUMBER')"
                    ></label>
                    <input
                        class="form-control"
                        id="title"
                        formControlName="title"
                        type="text"
                        required
                        [class.is-invalid]="
                            (form.touched || form.controls['title'].touched) &&
                            form.controls['title'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    />

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['title'].touched) &&
                                form.controls['title'].errors &&
                                form.controls['title'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label
                        for="versionNumber"
                        [innerHtml]="getDefaultStringTranslation('QUOTATION.VERSION')"
                    ></label>
                    <input
                        class="form-control"
                        id="versionNumber"
                        formControlName="versionNumber"
                        type="text"
                        min="1"
                        step="1"
                        required
                        [class.is-invalid]="
                            (form.touched || form.controls['versionNumber'].touched) &&
                            form.controls['versionNumber'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                    />

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['versionNumber'].touched) &&
                                form.controls['versionNumber'].errors &&
                                form.controls['versionNumber'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="language">Langue</label>
                    <select
                        class="form-select"
                        id="language"
                        formControlName="language"
                        required
                        [class.is-invalid]="
                            (form.touched || form.controls['language'].touched) &&
                            form.controls['language'].status == 'INVALID'
                                ? 'danger'
                                : ''
                        "
                        (change)="changedLanguage()"
                    >
                        <option
                            [value]="language"
                            *ngFor="let language of getLanguages()"
                            [innerHtml]="getLanguageLabel(language)"
                        ></option>
                    </select>

                    <div class="invalid-tooltip">
                        <div
                            *ngIf="
                                (form.touched || form.controls['language'].touched) &&
                                form.controls['language'].errors &&
                                form.controls['language'].errors['required']
                            "
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="plan-de-vol">
            <div class="header-with-line">
                <span [innerHtml]="getDefaultStringTranslation('QUOTATION.FLIGHT_PLAN')"></span>
            </div>

            <div class="table-responsive">
                <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <th [innerHtml]="getDefaultStringTranslation('QUOTATION.DATE')"></th>
                            <th
                                [innerHtml]="getDefaultStringTranslation('QUOTATION.FLIGHT_PLAN')"
                            ></th>
                            <th
                                [innerHtml]="
                                    getDefaultStringTranslation('QUOTATION.TIME_DEPARTURE')
                                "
                                *ngIf="form.value.enquiryType !== EnumEnquiryType.cargo"
                            ></th>
                            <th
                                [innerHtml]="getDefaultStringTranslation('QUOTATION.TIME_ARRIVAL')"
                                *ngIf="form.value.enquiryType !== EnumEnquiryType.cargo"
                            ></th>
                            <th
                                [innerHtml]="getDefaultStringTranslation('QUOTATION.FLIGHT_TIME')"
                                *ngIf="form.value.enquiryType !== EnumEnquiryType.cargo"
                            ></th>
                            <th
                                [innerHtml]="getDefaultStringTranslation('QUOTATION.PASSENGERS')"
                                *ngIf="form.value.enquiryType !== EnumEnquiryType.cargo"
                            ></th>
                            <th
                                [innerHtml]="
                                    getDefaultStringTranslation('QUOTATION.CARGO') + ' (kg)'
                                "
                                *ngIf="form.value.enquiryType === EnumEnquiryType.cargo"
                            ></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody
                        *ngIf="
                            form &&
                            form.value.trips &&
                            !form.value.trips.length &&
                            enquiry &&
                            enquiry.itineraries.length
                        "
                    >
                        <tr>
                            <td colspan="9">
                                <div class="row justify-content-center">
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <select
                                                class="form-select"
                                                id="pick-itinerary"
                                                [(ngModel)]="selectedItineraryId"
                                                [ngModelOptions]="{ standalone: true }"
                                            >
                                                <option [value]="null">
                                                    Choisissez l'itinéraire concerné
                                                </option>
                                                <option
                                                    [value]="itinerary.id"
                                                    *ngFor="let itinerary of enquiry.itineraries"
                                                    [innerHtml]="itinerary.title"
                                                ></option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody
                        formArrayName="trips"
                        *ngIf="form && form.value.trips && form.value.trips.length"
                    >
                        <tr [formGroupName]="i" *ngFor="let trip of trips.controls; let i = index">
                            <td>
                                <div class="form-group">
                                    <input
                                        class="form-control trip-date"
                                        id="date"
                                        formControlName="date"
                                        type="date"
                                        required
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form.get('trips').get(i.toString()).get('date')
                                                    .touched) &&
                                            form.get('trips').get(i.toString()).get('date')
                                                .status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                        (keyup)="updateLegDate(i)"
                                        (change)="updateLegDate(i)"
                                        min="2000-01-01"
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.get('trips').get(i.toString()).get('date')
                                                        .touched) &&
                                                form.get('trips').get(i.toString()).get('date')
                                                    .errors &&
                                                form.get('trips').get(i.toString()).get('date')
                                                    .errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.get('trips').get(i.toString()).get('date')
                                                        .touched) &&
                                                form.get('trips').get(i.toString()).get('date')
                                                    .errors &&
                                                form.get('trips').get(i.toString()).get('date')
                                                    .errors['dateBelowMinimum']
                                            "
                                        >
                                            La date doit être supérieure à
                                            {{
                                                form.get("trips").get(i.toString()).get("date")
                                                    .errors &&
                                                    form.get("trips").get(i.toString()).get("date")
                                                        .errors["dateMinimum"]
                                                    | date : "dd/MM/yyyy"
                                            }}.
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <app-airport-picker
                                                [inputName]="'trips.' + i + '.airportDepart'"
                                                [defaultValue]="
                                                    form && form.value['trips'][i]['airportDepart']
                                                "
                                                [disabled]="form.disabled || sending"
                                                [isInvalid]="
                                                    (form.touched ||
                                                        form
                                                            .get('trips')
                                                            .get(i.toString())
                                                            .get('airportDepart').touched) &&
                                                    form
                                                        .get('trips')
                                                        .get(i.toString())
                                                        .get('airportDepart').status == 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                                (setValueToFormControl)="
                                                    setValueToFormControl($event)
                                                "
                                                [noMargin]="true"
                                            >
                                            </app-airport-picker>

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            form
                                                                .get('trips')
                                                                .get(i.toString())
                                                                .get('airportDepart').touched) &&
                                                        form
                                                            .get('trips')
                                                            .get(i.toString())
                                                            .get('airportDepart').errors &&
                                                        form
                                                            .get('trips')
                                                            .get(i.toString())
                                                            .get('airportDepart').errors['required']
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <app-airport-picker
                                                [inputName]="'trips.' + i + '.airportDestination'"
                                                [defaultValue]="
                                                    form &&
                                                    form.value['trips'][i]['airportDestination']
                                                "
                                                [disabled]="form.disabled || sending"
                                                [isInvalid]="
                                                    (form.touched ||
                                                        form
                                                            .get('trips')
                                                            .get(i.toString())
                                                            .get('airportDestination').touched) &&
                                                    form
                                                        .get('trips')
                                                        .get(i.toString())
                                                        .get('airportDestination').status ==
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                                (setValueToFormControl)="
                                                    setValueToFormControl($event)
                                                "
                                                [noMargin]="true"
                                            >
                                            </app-airport-picker>

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            form
                                                                .get('trips')
                                                                .get(i.toString())
                                                                .get('airportDestination')
                                                                .touched) &&
                                                        form
                                                            .get('trips')
                                                            .get(i.toString())
                                                            .get('airportDestination').errors &&
                                                        form
                                                            .get('trips')
                                                            .get(i.toString())
                                                            .get('airportDestination').errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="form.value.enquiryType !== EnumEnquiryType.cargo">
                                <div class="form-group">
                                    <input
                                        class="form-control"
                                        id="time"
                                        formControlName="time"
                                        type="time"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form.get('trips').get(i.toString()).get('time')
                                                    .touched) &&
                                            form.get('trips').get(i.toString()).get('time')
                                                .status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                        (keyup)="
                                            setEstimatedArrivalTimeIfNotProvidedForTrip(i, true)
                                        "
                                        (change)="
                                            setEstimatedArrivalTimeIfNotProvidedForTrip(i, true)
                                        "
                                    />
                                </div>
                            </td>
                            <td *ngIf="form.value.enquiryType !== EnumEnquiryType.cargo">
                                <select
                                    class="form-select"
                                    id="aircraftModelId"
                                    formControlName="aircraftModelId"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            form
                                                .get('trips')
                                                .get(i.toString())
                                                .get('aircraftModelId').touched) &&
                                        form.get('trips').get(i.toString()).get('aircraftModelId')
                                            .status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="updateAircraftModelForTrip(i)"
                                >
                                    <option [ngValue]="null">Appareil pour estimer</option>
                                    <option
                                        [value]="aircraftModel.id"
                                        *ngFor="let aircraftModel of aircraftModels"
                                        [innerText]="aircraftModel.title"
                                    ></option>
                                </select>
                                <div class="form-group">
                                    <input
                                        class="form-control"
                                        id="arrivalTime"
                                        formControlName="arrivalTime"
                                        type="time"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form
                                                    .get('trips')
                                                    .get(i.toString())
                                                    .get('arrivalTime').touched) &&
                                            form.get('trips').get(i.toString()).get('arrivalTime')
                                                .status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                        (keyup)="tripTimesUpdated(i)"
                                        (change)="tripTimesUpdated(i)"
                                    />
                                </div>
                                <div
                                    class="arrival-estimated"
                                    *ngIf="getEstimatedArrivalTimeForTrip(i)"
                                >
                                    Heure locale d'arrivée estimée pour
                                    {{
                                        aircraftModelsObj[form.value.trips[i].aircraftModelId]
                                            ?.title
                                    }}
                                    :
                                    {{ getEstimatedArrivalTimeForTrip(i) }}
                                </div>
                            </td>
                            <td *ngIf="form.value.enquiryType !== EnumEnquiryType.cargo">
                                <div class="form-group">
                                    <input
                                        placeholder="HH:mm"
                                        class="form-control"
                                        id="flyTimeInHours"
                                        formControlName="flyTimeInHours"
                                        type="text"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form
                                                    .get('trips')
                                                    .get(i.toString())
                                                    .get('flyTimeInHours').touched) &&
                                            form
                                                .get('trips')
                                                .get(i.toString())
                                                .get('flyTimeInHours').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />
                                </div>
                            </td>
                            <td *ngIf="form.value.enquiryType !== EnumEnquiryType.cargo">
                                <div class="form-group">
                                    <input
                                        class="form-control"
                                        id="passengersTotal"
                                        formControlName="passengersTotal"
                                        type="text"
                                        min="1"
                                        step="1"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form
                                                    .get('trips')
                                                    .get(i.toString())
                                                    .get('arrivalTime').touched) &&
                                            form
                                                .get('trips')
                                                .get(i.toString())
                                                .get('passengersTotal').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form
                                                        .get('trips')
                                                        .get(i.toString())
                                                        .get('passengersTotal').touched) &&
                                                form
                                                    .get('trips')
                                                    .get(i.toString())
                                                    .get('passengersTotal').errors &&
                                                form
                                                    .get('trips')
                                                    .get(i.toString())
                                                    .get('passengersTotal').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="form.value.enquiryType === EnumEnquiryType.cargo">
                                <div class="form-group">
                                    <input
                                        class="form-control"
                                        id="weight"
                                        formControlName="weight"
                                        type="text"
                                        min="1"
                                        step="1"
                                        required
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form
                                                    .get('trips')
                                                    .get(i.toString())
                                                    .get('arrivalTime').touched) &&
                                            form.get('trips').get(i.toString()).get('weight')
                                                .status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form
                                                        .get('trips')
                                                        .get(i.toString())
                                                        .get('weight').touched) &&
                                                form.get('trips').get(i.toString()).get('weight')
                                                    .errors &&
                                                form.get('trips').get(i.toString()).get('weight')
                                                    .errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="nowrap">
                                <button
                                    type="button"
                                    (click)="addTripBelow(i)"
                                    class="btn btn-primary"
                                    *ngIf="i < trips.length - 1"
                                >
                                    +
                                </button>
                                <button
                                    type="button"
                                    (click)="removeTrip(i)"
                                    class="btn btn-danger"
                                >
                                    X
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="text-center mt-4 mb-4">
                <button type="button" (click)="addTripAndPrefill()" class="btn btn-outline-primary">
                    + Ajouter
                </button>
            </div>

            <div class="mt-2 mb-4" id="observations-trips">
                <div class="form-group">
                    <textarea
                        formControlName="observationsTrips"
                        class="form-control"
                        id="observationsTrips"
                        rows="5"
                    ></textarea>
                </div>
            </div>

            <div
                class="mb-4"
                [innerHtml]="getListOfAirportsGmt()"
                id="airports-timezone"
                *ngIf="form.value.trips && form.value.trips.length"
            ></div>
        </div>

        <div id="images">
            <div id="images-col-1">
                <img [src]="form.value.googleMapStaticUrl" *ngIf="form.value.googleMapStaticUrl" />
            </div>
            <div id="images-col-2">
                <div class="image-upload-container" *ngFor="let imageField of ['image1', 'image2']">
                    <div
                        class="image-upload-content"
                        [class.no-image]="!form.value[imageField + 'Url']"
                        (click)="triggerInputFile(imageField + 'Url')"
                    >
                        <div class="image-container" *ngIf="form.value[imageField + 'Url']">
                            <div
                                class="image"
                                [id]="imageField"
                                [style.background-image]="
                                    'url(' + form.value[imageField + 'Url'] + ')'
                                "
                                [style.webkitTransform]="
                                    'scale(' + form.value[imageField + 'ZoomLevel'] + ')'
                                "
                                [style.transform]="
                                    'scale(' + form.value[imageField + 'ZoomLevel'] + ')'
                                "
                            ></div>
                        </div>
                        <div class="image-upload-legend">
                            <span *ngIf="!form.disabled">
                                {{
                                    form.value[imageField + "Url"]
                                        ? "Éditer l'image"
                                        : "Ajouter une image"
                                }}
                            </span>
                            <span *ngIf="form.disabled">...</span>
                        </div>
                        <input
                            type="file"
                            [id]="imageField + 'Url' + 'Input'"
                            class="form-control"
                            (change)="changeInputFile(imageField + 'Url', $event)"
                            accept="image/*"
                        />
                    </div>
                    <div class="form-group form-group-range" *ngIf="form.value[imageField + 'Url']">
                        <label>Zoom</label>
                        <input
                            type="range"
                            class="form-control-range"
                            [formControlName]="imageField + 'ZoomLevel'"
                            min="0.5"
                            max="4"
                            value="1"
                            step="0.1"
                        />
                    </div>
                    <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        (click)="deleteImage(imageField + 'Url')"
                        [disabled]="form.disabled"
                        *ngIf="form.value[imageField + 'Url']"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
            <div id="images-col-3">
                <div class="image-upload-container" *ngFor="let imageField of ['image3']">
                    <div
                        class="image-upload-content"
                        [class.no-image]="!form.value[imageField + 'Url']"
                        (click)="triggerInputFile(imageField + 'Url')"
                    >
                        <div class="image-container" *ngIf="form.value[imageField + 'Url']">
                            <div
                                class="image"
                                [id]="imageField"
                                [style.background-image]="
                                    'url(' + form.value[imageField + 'Url'] + ')'
                                "
                                [style.webkitTransform]="
                                    'scale(' + form.value[imageField + 'ZoomLevel'] + ')'
                                "
                                [style.transform]="
                                    'scale(' + form.value[imageField + 'ZoomLevel'] + ')'
                                "
                            ></div>
                        </div>
                        <div class="image-upload-legend">
                            <span *ngIf="form.enabled">
                                {{
                                    form.value[imageField + "Url"]
                                        ? "Éditer l'image"
                                        : "Ajouter une image"
                                }}
                            </span>
                            <span *ngIf="!form.enabled">...</span>
                        </div>
                        <input
                            type="file"
                            [id]="imageField + 'Url' + 'Input'"
                            class="form-control"
                            (change)="changeInputFile(imageField + 'Url', $event)"
                            accept="image/*"
                        />
                    </div>
                    <div class="form-group form-group-range" *ngIf="form.value[imageField + 'Url']">
                        <label>Zoom</label>
                        <input
                            type="range"
                            class="form-control-range"
                            [formControlName]="imageField + 'ZoomLevel'"
                            min="0.5"
                            max="4"
                            value="1"
                            step="0.1"
                        />
                    </div>
                    <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        (click)="deleteImage(imageField + 'Url')"
                        [disabled]="form.disabled"
                        *ngIf="form.value[imageField + 'Url']"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>

        <div id="cotation">
            <div class="header-with-line">
                <span [innerHtml]="getDefaultStringTranslation('QUOTATION.QUOTATION')"></span>
            </div>

            <div class="table-responsive">
                <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <th
                                [innerHtml]="getDefaultStringTranslation('QUOTATION.OBSERVATIONS')"
                            ></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>
                                <div class="form-group">
                                    <textarea
                                        formControlName="notes"
                                        class="form-control"
                                        id="notes"
                                        rows="10"
                                    ></textarea>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive">
                <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <th
                                [innerHtml]="
                                    getDefaultStringTranslation('QUOTATION.AIRCRAFT.TITLE')
                                "
                            ></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td formArrayName="aircraftInfos">
                                <div
                                    class="row"
                                    [formGroupName]="i"
                                    *ngFor="
                                        let aircraftInfo of aircraftInfos.controls;
                                        let i = index
                                    "
                                >
                                    <div class="col-md-6">
                                        <div
                                            class="form-group"
                                            *ngIf="form.value.aircraftInfos[i].hasTitle"
                                        >
                                            <input
                                                class="form-control"
                                                formControlName="title"
                                                type="text"
                                            />
                                        </div>
                                        <div
                                            class="form-group"
                                            *ngIf="form.value.aircraftInfos[i].hasSubTitle"
                                        >
                                            <input
                                                class="form-control form-control-bold"
                                                formControlName="subTitle"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <input
                                                class="form-control"
                                                [class.form-control-bold]="
                                                    form.value.aircraftInfos[i].type === 'price'
                                                "
                                                formControlName="value"
                                                type="text"
                                                [placeholder]="
                                                    form.value.aircraftInfos[i].placeholder
                                                        ? 'Ex: ' +
                                                          form.value.aircraftInfos[i].placeholder
                                                        : ''
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <button
                                            type="button"
                                            (click)="
                                                removeCotationFormArrayItem('aircraftInfos', i)
                                            "
                                            class="btn btn-danger"
                                        >
                                            X
                                        </button>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button
                                        type="button"
                                        (click)="addCotationFormArrayItem('aircraftInfos')"
                                        class="btn btn-primary"
                                    >
                                        +
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive">
                <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <th
                                [innerHtml]="getDefaultStringTranslation('QUOTATION.PRICE.TITLE')"
                            ></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td formArrayName="priceInfos">
                                <div
                                    class="row"
                                    [formGroupName]="i"
                                    *ngFor="let priceInfo of priceInfos.controls; let i = index"
                                >
                                    <div class="col-md-6">
                                        <div
                                            class="form-group"
                                            *ngIf="form.value.priceInfos[i].hasTitle"
                                        >
                                            <input
                                                class="form-control"
                                                formControlName="title"
                                                type="text"
                                            />
                                        </div>
                                        <div
                                            class="form-group"
                                            *ngIf="form.value.priceInfos[i].hasSubTitle"
                                        >
                                            <input
                                                class="form-control form-control-bold"
                                                formControlName="subTitle"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <input
                                                class="form-control"
                                                [class.form-control-bold]="
                                                    form.value.priceInfos[i].type === 'price'
                                                "
                                                formControlName="value"
                                                type="text"
                                                [placeholder]="
                                                    form.value.priceInfos[i].placeholder
                                                        ? 'Ex: ' +
                                                          form.value.priceInfos[i].placeholder
                                                        : ''
                                                "
                                                (keyup)="updatedPriceValue(i)"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                        <button
                                            type="button"
                                            (click)="removeCotationFormArrayItem('priceInfos', i)"
                                            class="btn btn-danger"
                                        >
                                            X
                                        </button>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button
                                        type="button"
                                        (click)="addCotationFormArrayItem('priceInfos')"
                                        class="btn btn-primary"
                                    >
                                        +
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div id="services" formArrayName="servicesSections">
            <div
                [formGroupName]="i"
                *ngFor="let servicesSection of servicesSections.controls; let i = index"
                class="service-section"
            >
                <div class="row">
                    <div class="col-md-11">
                        <div class="header-with-line">
                            <input class="form-control" formControlName="title" type="text" />
                        </div>
                    </div>
                    <div class="col-md-1">
                        <button
                            type="button"
                            (click)="removeServicesSectionsFormArrayItem(i)"
                            class="btn btn-danger"
                        >
                            X
                        </button>
                    </div>
                </div>

                <div formArrayName="services">
                    <div
                        class="mb-3"
                        [formGroupName]="j"
                        *ngFor="let service of getServicesInSection(i).controls; let j = index"
                    >
                        <div class="row">
                            <div class="col-md-11">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <input type="checkbox" formControlName="checked" />
                                        </div>
                                    </div>
                                    <input
                                        class="form-control"
                                        formControlName="title"
                                        type="text"
                                    />
                                    <input
                                        class="form-control text-end"
                                        formControlName="comment"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div class="col-md-1">
                                <button
                                    type="button"
                                    (click)="removeServiceItemFromSectionsFormArray(i, j)"
                                    class="btn btn-danger"
                                >
                                    X
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="text-center mt-4">
                        <button
                            type="button"
                            (click)="addServiceItemToSectionsFormArray(i)"
                            class="btn btn-primary"
                        >
                            + Ajout service
                        </button>
                    </div>
                </div>
            </div>

            <div class="text-center mt-4">
                <button
                    type="button"
                    (click)="addServicesSectionsFormArrayItem()"
                    class="btn btn-primary"
                >
                    + Ajout section
                </button>
            </div>
        </div>

        <div
            id="reservation"
            *ngIf="
                [EnumEnquiryType.business, EnumEnquiryType.helico].indexOf(
                    form.value.enquiryType
                ) !== -1
            "
        >
            <div class="header-with-line">
                <span [innerHtml]="getDefaultStringTranslation('QUOTATION.BOOKING')"></span>
            </div>

            <div class="row">
                <div class="col-md-8 mb-4">
                    <div class="payment-conditions">
                        <div class="form-group">
                            <label>{{
                                getDefaultStringTranslation("QUOTATION.TOTAL_AMOUNT")
                            }}</label>
                            <div class="input-group">
                                <input
                                    class="form-control"
                                    id="totalAmountPayment"
                                    formControlName="totalAmountPayment"
                                    type="number"
                                    min="0"
                                    step="1"
                                />
                                <div class="input-group-append">
                                    <select
                                        class="form-select"
                                        id="totalAmountPaymentCurrency"
                                        formControlName="totalAmountPaymentCurrency"
                                    >
                                        <option
                                            [value]="currency"
                                            *ngFor="let currency of getCurrencies()"
                                            [innerHtml]="
                                                getEnumCurrencySymbol(currency) +
                                                ' (' +
                                                getEnumCurrencyLabel(currency) +
                                                ')'
                                            "
                                        ></option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <fieldset
                            id="payment-conditions"
                            formGroupName="paymentConditionsSettings"
                            class="mb-4"
                        >
                            <legend
                                [innerHtml]="
                                    getDefaultStringTranslation(
                                        'QUOTATION.PAYMENT_CONDITIONS_TITLE'
                                    )
                                "
                            ></legend>

                            <ul class="list-group">
                                <li class="list-group-item" formGroupName="firstDeposit">
                                    <div class="form-group">
                                        <label for="payment-conditions-first-deposit-percent">{{
                                            getDefaultStringTranslation(
                                                "QUOTATION.PERCENT_FIRST_DEPOSIT"
                                            )
                                        }}</label>
                                        <div class="input-group">
                                            <input
                                                type="number"
                                                min="0"
                                                max="100"
                                                step="1"
                                                formControlName="percent"
                                                class="form-control"
                                                id="payment-conditions-first-deposit-percent"
                                            />
                                            <div class="input-group-append">
                                                <span class="input-group-text"
                                                    >% =
                                                    {{
                                                        getValuePercentOfTotalAmount(
                                                            form.value.paymentConditionsSettings
                                                                .firstDeposit.percent
                                                        )
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <input
                                            type="text"
                                            formControlName="text"
                                            class="form-control"
                                            id="payment-conditions-first-deposit-text"
                                        />
                                    </div>
                                </li>
                                <li class="list-group-item" formGroupName="lastDeposit">
                                    <div class="form-group">
                                        <label for="payment-conditions-last-deposit-percent">{{
                                            getDefaultStringTranslation("QUOTATION.PERCENT_BALANCE")
                                        }}</label>
                                        <div class="input-group">
                                            <input
                                                type="number"
                                                min="0"
                                                max="100"
                                                step="1"
                                                formControlName="percent"
                                                class="form-control"
                                            />
                                            <div class="input-group-append">
                                                <span class="input-group-text"
                                                    >% =
                                                    {{
                                                        getValuePercentOfTotalAmount(
                                                            form.value.paymentConditionsSettings
                                                                .lastDeposit.percent
                                                        )
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="payment-conditions-last-deposit-days-before">{{
                                            getDefaultStringTranslation(
                                                "QUOTATION.BALANCE_HOW_MANY_DAYS_BEFORE_FLIGHT"
                                            )
                                        }}</label>
                                        <input
                                            type="number"
                                            min="0"
                                            step="1"
                                            formControlName="daysBefore"
                                            class="form-control"
                                            id="payment-conditions-last-deposit-days-before"
                                        />
                                    </div>

                                    <div class="form-group">
                                        <input
                                            type="text"
                                            formControlName="text"
                                            class="form-control"
                                            id="payment-conditions-last-deposit-text"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </fieldset>
                        <fieldset
                            id="cancelling-conditions"
                            formGroupName="cancellingConditionsSettings"
                            class="mb-4"
                        >
                            <legend
                                [innerHtml]="
                                    getDefaultStringTranslation(
                                        'QUOTATION.CANCELLING_CONDITIONS_TITLE'
                                    )
                                "
                            ></legend>

                            <ul class="list-group">
                                <li class="list-group-item" formGroupName="firstStep">
                                    <div class="form-group">
                                        <label for="cancelling-conditions-first-step-percent">{{
                                            getDefaultStringTranslation(
                                                "QUOTATION.PERCENT_FIRST_DEPOSIT"
                                            )
                                        }}</label>
                                        <div class="input-group">
                                            <input
                                                type="number"
                                                min="0"
                                                max="100"
                                                step="1"
                                                formControlName="percent"
                                                class="form-control"
                                                id="cancelling-conditions-first-step-percent"
                                            />
                                            <div class="input-group-append">
                                                <span class="input-group-text"
                                                    >% =
                                                    {{
                                                        getValuePercentOfTotalAmount(
                                                            form.value.cancellingConditionsSettings
                                                                .firstStep.percent
                                                        )
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="cancelling-conditions-first-step-days-before">{{
                                            getDefaultStringTranslation(
                                                "QUOTATION.BALANCE_HOW_MANY_DAYS_BEFORE_FLIGHT"
                                            )
                                        }}</label>
                                        <input
                                            type="number"
                                            min="0"
                                            step="1"
                                            formControlName="daysBefore"
                                            class="form-control"
                                            id="cancelling-conditions-first-step-days-before"
                                        />
                                    </div>

                                    <div class="form-group">
                                        <input
                                            type="text"
                                            formControlName="text"
                                            class="form-control"
                                            id="cancelling-conditions-first-step-text"
                                        />
                                    </div>
                                </li>
                                <li class="list-group-item" formGroupName="secondStep">
                                    <div class="form-group">
                                        <label for="cancelling-conditions-second-step-percent">{{
                                            getDefaultStringTranslation(
                                                "QUOTATION.PERCENT_SECOND_DEPOSIT"
                                            )
                                        }}</label>
                                        <div class="input-group">
                                            <input
                                                type="number"
                                                min="0"
                                                max="100"
                                                step="1"
                                                formControlName="percent"
                                                class="form-control"
                                                id="cancelling-conditions-second-step-percent"
                                            />
                                            <div class="input-group-append">
                                                <span class="input-group-text"
                                                    >% =
                                                    {{
                                                        getValuePercentOfTotalAmount(
                                                            form.value.cancellingConditionsSettings
                                                                .secondStep.percent
                                                        )
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <label
                                        for="cancelling-conditions-second-step-days-before-start"
                                        class="mb-2"
                                        >{{
                                            getDefaultStringTranslation(
                                                "QUOTATION.BALANCE_BETWEEN_X_AND_Y_DAYS_BEFORE_FLIGHT"
                                            )
                                        }}</label
                                    >
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label
                                                    for="cancelling-conditions-second-step-days-before-start"
                                                    >X</label
                                                >
                                                <input
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    formControlName="daysBeforeStart"
                                                    class="form-control"
                                                    id="cancelling-conditions-second-step-days-before-start"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label
                                                    for="cancelling-conditions-second-step-days-before-end"
                                                    >Y</label
                                                >
                                                <input
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    formControlName="daysBeforeEnd"
                                                    class="form-control"
                                                    id="cancelling-conditions-second-step-days-before-end"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <input
                                            type="text"
                                            formControlName="text"
                                            class="form-control"
                                            id="cancelling-conditions-second-step-text"
                                        />
                                    </div>
                                </li>
                                <li class="list-group-item" formGroupName="thirdStep">
                                    <div class="form-group">
                                        <label for="cancelling-conditions-third-step-percent">{{
                                            getDefaultStringTranslation(
                                                "QUOTATION.PERCENT_THIRD_DEPOSIT"
                                            )
                                        }}</label>
                                        <div class="input-group">
                                            <input
                                                type="number"
                                                min="0"
                                                max="100"
                                                step="1"
                                                formControlName="percent"
                                                class="form-control"
                                                id="cancelling-conditions-third-step-percent"
                                            />
                                            <div class="input-group-append">
                                                <span class="input-group-text"
                                                    >% =
                                                    {{
                                                        getValuePercentOfTotalAmount(
                                                            form.value.cancellingConditionsSettings
                                                                .thirdStep.percent
                                                        )
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <label
                                        for="cancelling-conditions-third-step-days-before-start"
                                        class="mb-2"
                                        >{{
                                            getDefaultStringTranslation(
                                                "QUOTATION.BALANCE_BETWEEN_X_AND_Y_HOURS_BEFORE_FLIGHT"
                                            )
                                        }}</label
                                    >
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label
                                                    for="cancelling-conditions-third-step-days-before-start"
                                                    >X</label
                                                >
                                                <input
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    formControlName="daysBeforeStart"
                                                    class="form-control"
                                                    id="cancelling-conditions-third-step-days-before-start"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label
                                                    for="cancelling-conditions-third-step-hours-before-end"
                                                    >Y</label
                                                >
                                                <input
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    formControlName="hoursBeforeEnd"
                                                    class="form-control"
                                                    id="cancelling-conditions-third-step-hours-before-end"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            formControlName="text"
                                            class="form-control"
                                            id="cancelling-conditions-third-step-text"
                                        />
                                    </div>
                                </li>
                                <li class="list-group-item" formGroupName="fourthStep">
                                    <div class="form-group">
                                        <label for="cancelling-conditions-fourth-step-percent">{{
                                            getDefaultStringTranslation("QUOTATION.PERCENT_BALANCE")
                                        }}</label>
                                        <div class="input-group">
                                            <input
                                                type="number"
                                                min="0"
                                                max="100"
                                                step="1"
                                                formControlName="percent"
                                                class="form-control"
                                                id="cancelling-conditions-fourth-step-percent"
                                            />
                                            <div class="input-group-append">
                                                <span class="input-group-text"
                                                    >% =
                                                    {{
                                                        getValuePercentOfTotalAmount(
                                                            form.value.cancellingConditionsSettings
                                                                .fourthStep.percent
                                                        )
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label
                                            for="cancelling-conditions-fourth-step-days-hours-before"
                                            >{{
                                                getDefaultStringTranslation(
                                                    "QUOTATION.BALANCE_HOW_MANY_HOURS_BEFORE_FLIGHT"
                                                )
                                            }}</label
                                        >
                                        <input
                                            type="number"
                                            min="0"
                                            step="1"
                                            formControlName="hoursBefore"
                                            class="form-control"
                                            id="cancelling-conditions-fourth-step-hours-before"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            formControlName="text"
                                            class="form-control"
                                            id="cancelling-conditions-fourth-step-text"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </fieldset>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="signature-square">
                        <strong
                            [innerHtml]="
                                getDefaultStringTranslation('QUOTATION.GOOD_FOR_AGREEMENT')
                            "
                        ></strong
                        ><br />
                        {{ getDefaultStringTranslation("QUOTATION.GOOD_FOR_AGREEMENT_TEXT") }}
                    </div>
                </div>
            </div>
        </div>

        <div
            id="garanties-and-engagements"
            *ngIf="[EnumEnquiryType.commercial].indexOf(form.value.enquiryType) !== -1"
        >
            <div class="header-with-line">
                <span
                    [innerHtml]="
                        getDefaultStringTranslation('QUOTATION.OUR_GUARANTIES_AND_ENGAGEMENTS')
                    "
                ></span>
            </div>

            <div class="form-group">
                <textarea
                    formControlName="garantiesAndEngagements"
                    class="form-control"
                    id="garantiesAndEngagements"
                    rows="5"
                ></textarea>
            </div>
        </div>
    </div>

    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="
                    quotationId
                        ? '/admin/quotations/view/' + quotationId
                        : enquiryId
                        ? '/admin/quotations/list/enquiry/' + enquiryId
                        : '/admin/quotations/list'
                "
                class="btn btn-outline-primary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>
