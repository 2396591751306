import { Injectable } from '@angular/core';
import { IAircraft } from 'src/app/interfaces/aircraft.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AircraftFirestoreService extends FirestoreService<IAircraft> {
  protected basePath = 'aircrafts';
}
