<ul class="nav nav-pills mb-3 justify-content-center" role="tablist">
    <li class="nav-item" role="presentation" *ngFor="let period of getPeriods()">
        <button
            class="nav-link"
            [class.active]="currentPeriod === period"
            [id]="'airline-period-' + period"
            type="button"
            [attr.aria-selected]="currentPeriod === period"
            [innerText]="getPeriodLabel(period)"
            (click)="changeCurrentPeriod(period)"
        ></button>
    </li>
</ul>

<div class="tab-content">
    <div
        class="tab-pane fade"
        *ngFor="let period of getPeriods()"
        [class.show]="currentPeriod === period"
        [class.active]="currentPeriod === period"
        [id]="'airline-period-' + period"
        role="tabpanel"
        [attr.aria-labelledby]="'airline-period-' + period"
        tabindex="0"
    >
        <div class="card mb-4" *ngIf="loading || loadingYearsOnly">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-end">Marge</th>
                                <th class="text-center">Parts</th>
                                <th class="text-center">Nb</th>
                                <th class="text-center">Parts</th>
                                <th class="text-center">Clotûrés</th>
                                <th class="text-end">CA</th>
                                <th class="text-center">Parts</th>
                                <th class="text-center">Marges</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="loading">
                            <tr *ngFor="let row of [].constructor(12)">
                                <td *ngFor="let number of [].constructor(9)">
                                    <div class="skeleton-text"></div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="loading">
                            <tr>
                                <th *ngFor="let number of [].constructor(9)">
                                    <div class="skeleton-text"></div>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!(loading || loadingYearsOnly)">
            <div class="card mb-4" *ngFor="let financialReportAirlineAllAndAirlines of getData()">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th
                                        [innerText]="financialReportAirlineAllAndAirlines.label"
                                    ></th>
                                    <th class="text-end">Marge</th>
                                    <th class="text-center">Parts</th>
                                    <th class="text-center">Nb</th>
                                    <th class="text-center">Parts</th>
                                    <th class="text-center">Clotûrés</th>
                                    <th class="text-end">CA</th>
                                    <th class="text-center">Parts</th>
                                    <th class="text-center">Marges</th>
                                </tr>
                            </thead>
                            <tbody
                                *ngIf="
                                    !financialReportAirlineAllAndAirlines.financialReportAirlines
                                        .length
                                "
                            >
                                <tr class="text-muted">
                                    <td
                                        [attr.colspan]="
                                            currentPeriod !== EnumPeriod.months ? 12 : 11
                                        "
                                        class="text-center"
                                    >
                                        Aucune donnée à afficher
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr
                                    *ngFor="
                                        let financialReportAirline of financialReportAirlineAllAndAirlines.financialReportAirlines
                                    "
                                >
                                    <td [innerText]="financialReportAirline.airlineTitle"></td>
                                    <td
                                        class="text-end"
                                        [innerText]="
                                            formatPrice(
                                                financialReportAirline.marginHT || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="
                                            (financialReportAirlineAllAndAirlines.virtualFields[
                                                financialReportAirline.airlineId
                                            ][
                                                EnumFinancialReportAirlineVirtualField
                                                    .marginHTPercent
                                            ] || 0) + '%'
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="financialReportAirline.nbContracts || 0"
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="
                                            (financialReportAirlineAllAndAirlines.virtualFields[
                                                financialReportAirline.airlineId
                                            ][
                                                EnumFinancialReportAirlineVirtualField
                                                    .nbContractsPercent
                                            ] || 0) + '%'
                                        "
                                    ></td>
                                    <td class="text-center">
                                        {{ financialReportAirline.nbCompleted || 0 }}
                                        ({{ financialReportAirline.completedPercent || 0 }}%)
                                    </td>
                                    <td
                                        class="text-end"
                                        [innerText]="
                                            formatPrice(
                                                financialReportAirline.ca || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="
                                            (financialReportAirlineAllAndAirlines.virtualFields[
                                                financialReportAirline.airlineId
                                            ][EnumFinancialReportAirlineVirtualField.caPercent] ||
                                                0) + '%'
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="
                                            (financialReportAirline.marginPercent || 0) + '%'
                                        "
                                    ></td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Total</th>
                                    <th
                                        class="text-end"
                                        [innerText]="
                                            formatPrice(
                                                financialReportAirlineAllAndAirlines.all
                                                    ?.marginHT || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [innerText]="
                                            roundNumber(
                                                financialReportAirlineAllAndAirlines.virtualFields
                                                    .all[
                                                    EnumFinancialReportAirlineVirtualField
                                                        .marginHTPercent
                                                ],
                                                0
                                            ) + '%'
                                        "
                                    ></th>

                                    <th
                                        class="text-center"
                                        [innerText]="
                                            financialReportAirlineAllAndAirlines.all?.nbContracts ||
                                            0
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [innerText]="
                                            roundNumber(
                                                financialReportAirlineAllAndAirlines.virtualFields
                                                    .all[
                                                    EnumFinancialReportAirlineVirtualField
                                                        .nbContractsPercent
                                                ],
                                                0
                                            ) + '%'
                                        "
                                    ></th>
                                    <th class="text-center">
                                        {{
                                            financialReportAirlineAllAndAirlines.all?.nbCompleted ||
                                                0
                                        }}
                                        ({{
                                            financialReportAirlineAllAndAirlines.all
                                                ?.completedPercent || 0
                                        }}%)
                                    </th>
                                    <th
                                        class="text-end"
                                        [innerText]="
                                            formatPrice(
                                                financialReportAirlineAllAndAirlines.all?.ca || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [innerText]="
                                            roundNumber(
                                                financialReportAirlineAllAndAirlines.virtualFields
                                                    .all[
                                                    EnumFinancialReportAirlineVirtualField.caPercent
                                                ],
                                                0
                                            ) + '%'
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [innerText]="
                                            (financialReportAirlineAllAndAirlines.all
                                                ?.marginPercent || 0) + '%'
                                        "
                                    ></th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
