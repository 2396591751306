import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FboEditComponent } from './fbo-edit.component';
import { AirportPickerModule } from 'src/app/components/airport-picker/airport-picker.module';
import { AutocompleteInputModule } from 'src/app/components/autocomplete-input/autocomplete-input.module';
import { BreadcrumbsModule } from 'src/app/components/breadcrumbs/breadcrumbs.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AirportPickerModule,
    FontAwesomeModule,
    AutocompleteInputModule,
    BreadcrumbsModule
  ],
  declarations: [FboEditComponent],
  exports: [FboEditComponent]
})
export class FboEditModule {}
