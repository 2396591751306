import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { IFinancialReportDepartment } from 'src/app/interfaces/financial-report-department.interface';

@Injectable({
  providedIn: 'root'
})
export class FinancialReportDepartmentsFirestoreService extends FirestoreService<IFinancialReportDepartment> {
  protected basePath = 'financialReportsByDepartments';
}
