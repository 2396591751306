import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { UserService } from '../user/user.service';
import { switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthFirestoreService } from '../firestore/auth-firestore.service';
import { IUser } from 'src/app/interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private fireAuthService: AngularFireAuth,
    private authFirestoreService: AuthFirestoreService,
    private userService: UserService
  ) {}

  get currentUser$(): Observable<IUser> {
    return this.fireAuthService.authState.pipe(
      tap(credential => this.authFirestoreService.updateCurrentUserUid(credential?.uid)),
      switchMap((credential: any) =>
        credential ? this.userService.getFromAuthUid(credential.uid) : of(null)
      )
    );
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<any> {
    return this.fireAuthService.signInWithEmailAndPassword(email, password);
  }

  signInAnonymously(): Promise<any> {
    return this.fireAuthService.signInAnonymously();
  }

  confirmCredentialsBeforeSensitiveUpdates(password: string): Promise<any> {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, password);
    return user.reauthenticateWithCredential(credential);
  }

  updateEmail(newEmail: string): Promise<void> {
    return firebase.auth().currentUser.updateEmail(newEmail);
  }

  updatePassword(newPassword: string): Promise<void> {
    return firebase.auth().currentUser.updatePassword(newPassword);
  }

  resetPassword(email: string): Promise<void> {
    return this.fireAuthService.sendPasswordResetEmail(email);
  }

  deleteCurrentUser(): Promise<void> {
    return firebase.auth().currentUser.delete();
  }

  signOutCurrentUser() {
    return this.fireAuthService.signOut();
  }
}
