import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  currentPagination: string = 'users-list';
  userGroupsObj: object = {};

  EnumAcl = EnumAcl;

  constructor(
    public paginationService: PaginationService,
    private remoteService: RemoteService,
    private aclService: AclService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.usersList);

    this.fetchData();
    this.loadUserGroups();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    await this.paginationService.fetchData(this.currentPagination);
  }

  async loadUserGroups(): Promise<void> {
    const userGroupsObj: Array<object> = await this.remoteService.getAllDocuments('userGroups');

    for (const userGroupObj of userGroupsObj) {
      const userGroup: IUserGroup = userGroupObj as IUserGroup;

      this.userGroupsObj[userGroup.id] = userGroup;
    }
  }
}
