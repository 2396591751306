import { Injectable, NgZone } from '@angular/core';
import { Router } from "@angular/router";

import { RemoteService } from '../services/remote.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardService  {

	constructor(
		private router: Router,
		private remoteService: RemoteService,
		private zone: NgZone
	) {
	}

	canActivate(): Promise<boolean> {
		return new Promise((resolve,reject) => {
			this.remoteService.isLoggedObservable.subscribe((isLogged: boolean) => {
				if (isLogged !== null && !isLogged) {
					this.zone.run(() => {
						this.router.navigate(['/login'])
					})
					resolve(false)
				}

				resolve(true)
			})
		})
	}

}
