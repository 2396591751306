import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnInit
} from '@angular/core';

import { PipedriveService } from '../../../services/pipedrive.service';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { IPipedriveOrganization } from 'src/app/interfaces/pipedrive.interface';

@Component({
  selector: 'pipedrive-search-organization-input',
  templateUrl: './search-organization-input.component.html',
  styleUrls: ['./search-organization-input.component.scss']
})
export class PipedriveSearchOrganizationInputComponent implements OnInit, OnChanges {
  @Input('isInvalid') isInvalid: boolean;
  @Input('inputName') inputName: string;
  @Input('disabled') disabled: boolean = false;
  @Input('defaultValue') defaultValue: string;
  @Output() setValueToFormControl: EventEmitter<{
    fields: Array<{
      name: string;
      value: string;
      label: string;
    }>;
  }> = new EventEmitter();
  value: string;
  title: string;
  organizations: IPipedriveOrganization[] = [];
  optionsSuggested: Array<{
    title: string;
    value: string;
  }> = [];
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  // initiated: boolean = false
  rootUrl: string = '../';

  faExternalLink = faExternalLink;

  constructor(private pipedriveService: PipedriveService) {
    this.rootUrl = window['rootUrl'];
  }

  ngOnInit(): void {
    this.updateTitle();
  }

  ngOnChanges() {
    // if (!this.initiated && this.defaultValue) {
    this.value = this.defaultValue;

    // this.initiated = true
    // }

    this.updateTitle();
  }

  async updateTitle(): Promise<void> {
    if (this.value && (!this.organizations?.length || !this.isOrganizationInCache(this.value))) {
      const organization: IPipedriveOrganization = await this.pipedriveService.getOrganization(
        this.value.toString()
      );

      if (organization) {
        this.organizations.push(organization);
      }
    }

    for (const organization of this.organizations) {
      if (organization.id === this.value) {
        this.title = organization.name;

        break;
      }
    }
  }

  isOrganizationInCache(organizationId: string): boolean {
    for (const organization of this.organizations) {
      if (organizationId === organization.id) {
        return true;
      }
    }

    return false;
  }

  updateFormValue(value: string): void {
    this.value = value;
    this.updateTitle();

    let label: string;

    for (const option of this.optionsSuggested) {
      if (option.value === value) {
        label = option.title;
        break;
      }
    }

    this.setValueToFormControl.emit({
      fields: [
        {
          name: this.inputName,
          value: this.value,
          label
        }
      ]
    });

    this.optionsSuggested = [];
  }

  async updateAutocomplete(query: string): Promise<void> {
    this.optionsSuggested = [];

    if (query && query.length > 2) {
      try {
        this.organizations = await this.pipedriveService.searchOrganization(query);

        this.optionsSuggested = [];

        for (const organization of this.organizations) {
          this.optionsSuggested.push({
            title: organization.name,
            value: organization.id
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  clearValue(): void {
    this.updateFormValue('');
    this.updateAutocomplete('');

    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    });
  }
}
