import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { getYear } from 'date-fns';
import {
  EnumCurrency,
  getDefaultCurrency,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { EnumPeriod, getPeriodLabel } from '../financial-reports.component';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import {
  getMonthLabelByQuarter,
  getMonthLabel,
  formatPrice,
  roundNumber,
  roundNumberWithPlusSign
} from 'src/app/misc.utils';
import { IFinancialReportUser } from 'src/app/interfaces/financial-report-user.interface';
import { IFinancialGoal } from 'src/app/interfaces/financial-goal.interface';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FinancialGoalsService } from 'src/app/services/financial-goals/financial-goals.service';
import { FinancialReportUsersService } from 'src/app/services/financial-report-users/financial-report-users.service';
import { UserService } from 'src/app/services/user/user.service';
import { IFinancialReportStaff } from 'src/app/interfaces/financial-report-staff.interface';
import { FinancialReportStaffsService } from 'src/app/services/financial-report-staffs/financial-report-staffs.service';

enum EnumFinancialReportUserVirtualField {
  marginHTPercent = 'marginHTPercent',
  goalPercent = 'goalPercent',
  y2y = 'y2y',
  gap = 'gap',
  mysM = 'mysM',
  averageCaForAllEmployes = 'averageCaForAllEmployes',
  averageCaForAllSales = 'averageCaForAllSales',
  averageCaForAllEmployesByMonth = 'averageCaForAllEmployesByMonth',
  averageCaForAllSalesByMonth = 'averageCaForAllSalesByMonth',
  averageCaForAllEmployesByMonthY2Y = 'averageCaForAllEmployesByMonthY2Y',
  averageCaForAllSalesByMonthY2Y = 'averageCaForAllSalesByMonthY2Y'
}

interface IFinancialReportUserVirtualFields {
  marginHTPercent: number;
  goalPercent: number;
  y2y: number;
  gap: number;
  mysM: number;
  averageCaForAllEmployes: number;
  averageCaForAllSales: number;
  averageCaForAllEmployesByMonth: number;
  averageCaForAllSalesByMonth: number;
  averageCaForAllEmployesByMonthY2Y: number;
  averageCaForAllSalesByMonthY2Y: number;
}

interface IFinancialReportUserAllAndTypes {
  label: string;
  all: IFinancialReportUser | null;
  financialReportUsersByUserId: IFinancialReportUserByUser;
  financialReportUsersByUsers: IFinancialReportUser[];
  virtualFields: IFinancialReportUserVirtualFieldsAllAndUsers;
}
interface IFinancialReportUserByUser {
  [userId: string]: IFinancialReportUser;
}

interface IFinancialReportUserVirtualFieldsAllAndUsers {
  [userId: string]: IFinancialReportUserVirtualFields | null;
  all: IFinancialReportUserVirtualFields | null;
}

@Component({
  selector: 'app-financial-report-team',
  templateUrl: './financial-report-team.component.html',
  styleUrls: ['./financial-report-team.component.scss']
})
export class FinancialReportTeamComponent implements OnInit, OnDestroy, OnChanges {
  @Input('currentYear') currentYear: number = getYear(new Date());
  @Input('currentCurrency') currentCurrency: EnumCurrency | null = null;
  @Input('currentPeriod') currentPeriod: EnumPeriod = EnumPeriod.months;

  @Output() updateCurrentPeriod: EventEmitter<EnumPeriod> = new EventEmitter();

  @ViewChild('modalYearlyGoal', { static: false }) modalYearlyGoalElement: ElementRef;
  @ViewChild('modalStaff', { static: false }) modalStaffElement: ElementRef;

  EnumPeriod = EnumPeriod;
  EnumFinancialReportUserVirtualField = EnumFinancialReportUserVirtualField;

  getPeriodLabel = getPeriodLabel;
  getMonthLabelByQuarter = getMonthLabelByQuarter;
  getMonthLabel = getMonthLabel;
  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getUserFullname = getUserFullname;
  formatPrice = formatPrice;
  roundNumber = roundNumber;
  roundNumberWithPlusSign = roundNumberWithPlusSign;
  getDefaultCurrency = getDefaultCurrency;

  faEdit = faEdit;

  formGoal: FormGroup = new FormGroup({
    yearlyGoal: new FormControl(0, Validators.required),
    quarterlyGoal: new FormControl(0, Validators.required),
    monthlyGoal: new FormControl(0, Validators.required),
    userId: new FormControl(null)
  });

  savingGoal: boolean = false;
  loading: boolean = false;
  loadingYearsOnly: boolean = false;

  financialReportUsers: { [year: string]: IFinancialReportUser[] } = {};
  financialReportUsersOnlyYears: IFinancialReportUser[] = [];
  financialReportUsersByPeriod: {
    [period: string]: {
      [year: string]: IFinancialReportUserAllAndTypes[];
    };
  } = {};

  financialGoals: IFinancialGoal[] = [];
  financialGoalsByUserId: { [userId: string]: IFinancialGoal | null } = {};
  companyFinancialGoal: IFinancialGoal | null = null;
  financialReportStaffsByYear: { [year: string]: IFinancialReportStaff | null } = {};
  loadingFinancialReportStaff: boolean = false;

  subscriptions = new Subscription();
  subscriptionsForYear = new Subscription();

  loadingUsers: boolean = false;
  usersObj: { [key: string]: IUser } = {};

  formStaff: FormGroup = new FormGroup({
    nbSales: new FormControl(0, Validators.required),
    nbEmployes: new FormControl(0, Validators.required)
  });

  savingStaff: boolean = false;

  constructor(
    private financialGoalsService: FinancialGoalsService,
    private financialReportUsersService: FinancialReportUsersService,
    private financialReportStaffsService: FinancialReportStaffsService,
    private userService: UserService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadAllUsers();

    if (changes.currentYear || changes.currentCurrency) {
      this.loadAll();
    }
  }

  ngOnDestroy(): void {
    this.subscriptionsForYear.unsubscribe();
    this.subscriptions.unsubscribe();

    this.removeModal();
  }

  removeModal(): void {
    window['$'](this.modalYearlyGoalElement.nativeElement).modal('hide');
    window['$'](this.modalStaffElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  getData(): IFinancialReportUserAllAndTypes[] {
    switch (this.currentPeriod) {
      case EnumPeriod.years:
        return this.financialReportUsersByPeriod[this.currentPeriod].all;
      default:
        return this.financialReportUsersByPeriod[this.currentPeriod][this.currentYear];
    }
  }

  getPeriods(): EnumPeriod[] {
    return Object.values(EnumPeriod);
  }

  changeCurrentPeriod(newPeriod: EnumPeriod): void {
    this.currentPeriod = newPeriod;

    this.updateCurrentPeriod.emit(this.currentPeriod);
  }

  getPercentage(value: number, total: number): number {
    if (total !== 0) {
      return this.roundNumber((value * 100) / total);
    }

    return 0;
  }

  showYearlyGoalModal(userId: string | null = null): void {
    this.formGoal.get('userId').setValue(userId);

    if (userId) {
      if (this.financialGoalsByUserId[userId]) {
        this.assignFinancialGoalToFormGoal(this.financialGoalsByUserId[userId]);
      } else if (this.usersObj[userId] && this.usersObj[userId].goals[this.currentYear]) {
        this.formGoal
          .get('yearlyGoal')
          .setValue(this.usersObj[userId].goals[this.currentYear].year.goal ?? 0);
        this.formGoal
          .get('quarterlyGoal')
          .setValue(this.usersObj[userId].goals[this.currentYear].q1.goal ?? 0);
        this.formGoal
          .get('monthlyGoal')
          .setValue(
            this.usersObj[userId].goals[this.currentYear].q1.goal
              ? Math.round(this.usersObj[userId].goals[this.currentYear].q1.goal / 3)
              : 0
          );
      }
    } else {
      this.assignFinancialGoalToFormGoal(this.companyFinancialGoal);
    }

    window['$'](this.modalYearlyGoalElement.nativeElement).modal('show');
  }

  assignFinancialGoalToFormGoal(financialGoal: IFinancialGoal | null = null): void {
    this.formGoal.get('yearlyGoal').setValue(financialGoal?.yearlyGoal ?? 0);
    this.formGoal.get('quarterlyGoal').setValue(financialGoal?.quarterlyGoal ?? 0);
    this.formGoal.get('monthlyGoal').setValue(financialGoal?.monthlyGoal ?? 0);
  }

  loadAll(): void {
    this.loading = true;

    this.resetSubscriptionForYear();

    this.loadFinancialReports();
    this.loadFinancialReportUsersYearsOnly();
    this.loadFinancialReportStaff();
  }

  resetSubscriptionForYear(): void {
    if (this.subscriptionsForYear) {
      this.subscriptionsForYear.unsubscribe();

      this.subscriptionsForYear = new Subscription();
    }
  }

  loadFinancialReports(): void {
    this.subscriptionsForYear.add(
      this.financialGoalsService
        .getAllForCurrencyForYear(this.currentCurrency, this.currentYear)
        .subscribe((financialGoals: IFinancialGoal[]) => {
          this.financialGoals = financialGoals;

          this.companyFinancialGoal = null;
          this.financialGoalsByUserId = {};

          for (const financialGoal of this.financialGoals) {
            if (!financialGoal.userId) {
              this.companyFinancialGoal = financialGoal;
            } else {
              this.financialGoalsByUserId[financialGoal.userId] = financialGoal;
            }
          }
        })
    );

    this.subscriptionsForYear.add(
      this.financialReportUsersService
        .getAllForCurrencyForYear(this.currentCurrency, this.currentYear)
        .subscribe((financialReportUsers: IFinancialReportUser[]) => {
          this.financialReportUsers[this.currentYear] = financialReportUsers;

          this.organiseFinancialReportUsers(this.currentYear);
        })
    );

    // Load data for previous year
    this.subscriptionsForYear.add(
      this.financialReportUsersService
        .getAllForCurrencyForYear(this.currentCurrency, this.currentYear - 1)
        .subscribe((financialReportUsers: IFinancialReportUser[]) => {
          this.financialReportUsers[this.currentYear - 1] = financialReportUsers;

          this.organiseFinancialReportUsers(this.currentYear - 1);
        })
    );
  }

  resetFinancialReportUsersDisplayedData(year: number): void {
    for (const period of this.getPeriods()) {
      if (typeof this.financialReportUsersByPeriod[period] === 'undefined') {
        this.financialReportUsersByPeriod[period] = {};
      }

      this.financialReportUsersByPeriod[period][year] = [];

      const objectByUsers: IFinancialReportUserByUser = {};
      const virtualFieldsByUsers: IFinancialReportUserVirtualFieldsAllAndUsers = {
        all: {} as IFinancialReportUserVirtualFields
      };

      for (const field in virtualFieldsByUsers) {
        for (const virtualField of Object.values(EnumFinancialReportUserVirtualField)) {
          virtualFieldsByUsers[field][virtualField] = 0;
        }
      }

      let countItems: number = 1;
      switch (period) {
        case EnumPeriod.months:
          countItems = 12;
          break;
        case EnumPeriod.quarters:
          countItems = 4;
          break;
      }

      for (let i = 1; i <= countItems; i++) {
        this.financialReportUsersByPeriod[period][year].push({
          label:
            period === EnumPeriod.quarters
              ? this.getMonthLabelByQuarter(i) + ' ' + this.currentYear
              : getMonthLabel(i) + ' ' + this.currentYear,
          all: null,
          financialReportUsersByUserId: this.cloneVariable(objectByUsers),
          financialReportUsersByUsers: [],
          virtualFields: this.cloneVariable(virtualFieldsByUsers)
        });
      }
    }
  }

  cloneVariable(variable: any): any {
    return JSON.parse(JSON.stringify(variable));
  }

  organiseFinancialReportUsers(year: number): void {
    this.resetFinancialReportUsersDisplayedData(year);

    for (const financialReportUser of this.financialReportUsers[year]) {
      if (financialReportUser.month) {
        if (financialReportUser.userId) {
          this.financialReportUsersByPeriod[EnumPeriod.months][year][
            financialReportUser.month - 1
          ].financialReportUsersByUserId[financialReportUser.userId] =
            this.cloneVariable(financialReportUser);
        } else {
          this.financialReportUsersByPeriod[EnumPeriod.months][year][
            financialReportUser.month - 1
          ].all = this.cloneVariable(financialReportUser);
        }
      } else if (financialReportUser.quarter) {
        if (financialReportUser.userId) {
          this.financialReportUsersByPeriod[EnumPeriod.quarters][year][
            financialReportUser.quarter - 1
          ].financialReportUsersByUserId[financialReportUser.userId] =
            this.cloneVariable(financialReportUser);
        } else {
          this.financialReportUsersByPeriod[EnumPeriod.quarters][year][
            financialReportUser.quarter - 1
          ].all = this.cloneVariable(financialReportUser);
        }
      }
    }

    for (const period of this.getPeriods()) {
      for (const year in this.financialReportUsersByPeriod[period]) {
        for (let i = 0; i < this.financialReportUsersByPeriod[period][year].length; i++) {
          let financialReportUserAllAndTypes = this.financialReportUsersByPeriod[period][year][i];

          financialReportUserAllAndTypes.financialReportUsersByUsers = Object.values(
            financialReportUserAllAndTypes.financialReportUsersByUserId
          );
          financialReportUserAllAndTypes.financialReportUsersByUsers.sort((a, b) =>
            a.userFullName < b.userFullName ? -1 : 1
          );

          financialReportUserAllAndTypes = this.setVirtualFieldsForFinancialReportUser(
            period,
            parseInt(year),
            i,
            financialReportUserAllAndTypes
          );
        }
      }
    }

    this.loading = false;
  }

  setVirtualFieldsForFinancialReportUser(
    period: EnumPeriod,
    year: number | 'all',
    index: number,
    financialReportUserAllAndTypes: IFinancialReportUserAllAndTypes
  ): IFinancialReportUserAllAndTypes {
    let previousYearData: IFinancialReportUserAllAndTypes | null = null;

    if (year === 'all') {
      if (
        this.financialReportUsersByPeriod[period].all &&
        this.financialReportUsersByPeriod[period].all[index + 1]
      ) {
        previousYearData = this.financialReportUsersByPeriod[period].all[index + 1];
      }
    } else {
      if (
        this.financialReportUsersByPeriod[period][year - 1] &&
        this.financialReportUsersByPeriod[period][year - 1][index]
      ) {
        previousYearData = this.financialReportUsersByPeriod[period][year - 1][index];
      }
    }

    const selectedYear: number = year !== 'all' ? year : this.currentYear;

    for (const userId in this.usersObj) {
      if (typeof financialReportUserAllAndTypes.virtualFields[userId] === 'undefined') {
        financialReportUserAllAndTypes.virtualFields[userId] =
          {} as IFinancialReportUserVirtualFields;

        for (const virtualField of Object.values(EnumFinancialReportUserVirtualField)) {
          financialReportUserAllAndTypes.virtualFields[userId][virtualField] = 0;
        }
      }

      for (const virtualField of Object.values(EnumFinancialReportUserVirtualField)) {
        switch (virtualField) {
          case EnumFinancialReportUserVirtualField.goalPercent:
            if (
              financialReportUserAllAndTypes.financialReportUsersByUserId[userId] &&
              this.financialGoalsByUserId[userId]
            ) {
              let objectiveGoal: number = 0;

              switch (period) {
                case EnumPeriod.months:
                  objectiveGoal = this.financialGoalsByUserId[userId].monthlyGoal ?? 0;
                  break;
                case EnumPeriod.quarters:
                  objectiveGoal = this.financialGoalsByUserId[userId].quarterlyGoal ?? 0;
                  break;
                case EnumPeriod.years:
                  objectiveGoal = this.financialGoalsByUserId[userId].yearlyGoal ?? 0;
                  break;
              }

              if (objectiveGoal !== 0) {
                financialReportUserAllAndTypes.virtualFields[userId][virtualField] = roundNumber(
                  (financialReportUserAllAndTypes.financialReportUsersByUserId[userId].marginHT *
                    100) /
                    objectiveGoal
                );
              }
            }
            break;
          case EnumFinancialReportUserVirtualField.mysM:
            if (financialReportUserAllAndTypes.financialReportUsersByUserId[userId]) {
              let dividedBy: number = 1;

              switch (period) {
                case EnumPeriod.quarters:
                  dividedBy = 3;
                  break;
                case EnumPeriod.years:
                  dividedBy = 12;
                  break;
              }

              financialReportUserAllAndTypes.virtualFields[userId][virtualField] = roundNumber(
                financialReportUserAllAndTypes.financialReportUsersByUserId[userId].marginHT /
                  dividedBy
              );
            }
            break;
          case EnumFinancialReportUserVirtualField.averageCaForAllEmployes:
            if (
              financialReportUserAllAndTypes.financialReportUsersByUserId[userId] &&
              this.financialReportStaffsByYear[selectedYear]?.nbEmployes
            ) {
              financialReportUserAllAndTypes.virtualFields[userId][virtualField] = roundNumber(
                financialReportUserAllAndTypes.financialReportUsersByUserId[userId].ca /
                  this.financialReportStaffsByYear[selectedYear].nbEmployes
              );
            }
            break;
          case EnumFinancialReportUserVirtualField.averageCaForAllSales:
            if (
              financialReportUserAllAndTypes.financialReportUsersByUserId[userId] &&
              this.financialReportStaffsByYear[selectedYear]?.nbSales
            ) {
              financialReportUserAllAndTypes.virtualFields[userId][virtualField] = roundNumber(
                financialReportUserAllAndTypes.financialReportUsersByUserId[userId].ca /
                  this.financialReportStaffsByYear[selectedYear].nbSales
              );
            }
            break;
          case EnumFinancialReportUserVirtualField.averageCaForAllEmployesByMonth:
            if (
              financialReportUserAllAndTypes.financialReportUsersByUserId[userId] &&
              this.financialReportStaffsByYear[selectedYear]?.nbEmployes
            ) {
              let dividedBy: number = 1;

              switch (period) {
                case EnumPeriod.quarters:
                  dividedBy = 3;
                  break;
                case EnumPeriod.years:
                  dividedBy = 12;
                  break;
              }

              financialReportUserAllAndTypes.virtualFields[userId][virtualField] = roundNumber(
                financialReportUserAllAndTypes.financialReportUsersByUserId[userId].ca /
                  this.financialReportStaffsByYear[selectedYear].nbEmployes /
                  dividedBy
              );
            }
            break;
          case EnumFinancialReportUserVirtualField.averageCaForAllSalesByMonth:
            if (
              financialReportUserAllAndTypes.financialReportUsersByUserId[userId] &&
              this.financialReportStaffsByYear[selectedYear]?.nbSales
            ) {
              let dividedBy: number = 1;

              switch (period) {
                case EnumPeriod.quarters:
                  dividedBy = 3;
                  break;
                case EnumPeriod.years:
                  dividedBy = 12;
                  break;
              }

              financialReportUserAllAndTypes.virtualFields[userId][virtualField] = roundNumber(
                financialReportUserAllAndTypes.financialReportUsersByUserId[userId].ca /
                  this.financialReportStaffsByYear[selectedYear].nbSales /
                  dividedBy
              );
            }
            break;
          case EnumFinancialReportUserVirtualField.averageCaForAllEmployesByMonthY2Y:
            if (
              this.financialReportUsersByPeriod[period][year] &&
              this.financialReportUsersByPeriod[period][year][index] &&
              this.financialReportUsersByPeriod[period][year][index].financialReportUsersByUserId[
                userId
              ] &&
              previousYearData &&
              previousYearData.financialReportUsersByUserId[userId] &&
              this.financialReportStaffsByYear[selectedYear]
            ) {
              let dividedBy: number = 1;

              switch (period) {
                case EnumPeriod.quarters:
                  dividedBy = 3;
                  break;
                case EnumPeriod.years:
                  dividedBy = 12;
                  break;
              }

              const valueCurrentYear: number = roundNumber(
                financialReportUserAllAndTypes.financialReportUsersByUserId[userId].ca /
                  this.financialReportStaffsByYear[selectedYear].nbEmployes /
                  dividedBy
              );
              const valuePreviousYear: number = this.financialReportStaffsByYear[selectedYear - 1]
                ? roundNumber(
                    financialReportUserAllAndTypes.financialReportUsersByUserId[userId].ca /
                      this.financialReportStaffsByYear[selectedYear - 1].nbEmployes /
                      dividedBy
                  )
                : 0;

              financialReportUserAllAndTypes.virtualFields[userId][virtualField] = valuePreviousYear
                ? valueCurrentYear / valuePreviousYear
                : 1;
            }
            break;
          case EnumFinancialReportUserVirtualField.averageCaForAllSalesByMonthY2Y:
            if (
              this.financialReportUsersByPeriod[period][year] &&
              this.financialReportUsersByPeriod[period][year][index] &&
              this.financialReportUsersByPeriod[period][year][index].financialReportUsersByUserId[
                userId
              ] &&
              previousYearData &&
              previousYearData.financialReportUsersByUserId[userId] &&
              this.financialReportStaffsByYear[selectedYear]
            ) {
              let dividedBy: number = 1;

              switch (period) {
                case EnumPeriod.quarters:
                  dividedBy = 3;
                  break;
                case EnumPeriod.years:
                  dividedBy = 12;
                  break;
              }

              const valueCurrentYear: number = roundNumber(
                financialReportUserAllAndTypes.financialReportUsersByUserId[userId].ca /
                  this.financialReportStaffsByYear[selectedYear].nbSales /
                  dividedBy
              );
              const valuePreviousYear: number = this.financialReportStaffsByYear[selectedYear - 1]
                ? roundNumber(
                    financialReportUserAllAndTypes.financialReportUsersByUserId[userId].ca /
                      this.financialReportStaffsByYear[selectedYear - 1].nbSales /
                      dividedBy
                  )
                : 0;

              financialReportUserAllAndTypes.virtualFields[userId][virtualField] = valuePreviousYear
                ? valueCurrentYear / valuePreviousYear
                : 1;
            }
            break;
          case EnumFinancialReportUserVirtualField.y2y:
            if (
              this.financialReportUsersByPeriod[period][year] &&
              this.financialReportUsersByPeriod[period][year][index] &&
              this.financialReportUsersByPeriod[period][year][index].financialReportUsersByUserId[
                userId
              ] &&
              previousYearData &&
              previousYearData.financialReportUsersByUserId[userId]
            ) {
              financialReportUserAllAndTypes.virtualFields[userId][virtualField] =
                this.financialReportUsersByPeriod[period][year][index].financialReportUsersByUserId[
                  userId
                ].marginHT - previousYearData.financialReportUsersByUserId[userId].marginHT;
            }
            break;
          case EnumFinancialReportUserVirtualField.gap:
            if (
              this.financialReportUsersByPeriod[period][year] &&
              this.financialReportUsersByPeriod[period][year][index] &&
              this.financialReportUsersByPeriod[period][year][index].financialReportUsersByUserId[
                userId
              ] &&
              this.financialReportUsersByPeriod[period][year][index].all &&
              previousYearData &&
              previousYearData.financialReportUsersByUserId[userId] &&
              previousYearData.all
            ) {
              financialReportUserAllAndTypes.virtualFields[userId][virtualField] =
                this.getPercentage(
                  this.financialReportUsersByPeriod[period][year][index]
                    .financialReportUsersByUserId[userId].marginHT,
                  this.financialReportUsersByPeriod[period][year][index].all.marginHT
                ) -
                this.getPercentage(
                  previousYearData.financialReportUsersByUserId[userId].marginHT,
                  previousYearData.all.marginHT
                );
            }
            break;
          default:
            const field: string = (virtualField as string).replace('Percent', '');

            if (
              financialReportUserAllAndTypes.financialReportUsersByUserId[userId] &&
              financialReportUserAllAndTypes.financialReportUsersByUserId[userId][field] &&
              financialReportUserAllAndTypes.all &&
              financialReportUserAllAndTypes.all[field]
            ) {
              financialReportUserAllAndTypes.virtualFields[userId][virtualField as string] =
                this.getPercentage(
                  financialReportUserAllAndTypes.financialReportUsersByUserId[userId][field],
                  financialReportUserAllAndTypes.all[field]
                );
            }

            break;
        }
      }
    }

    for (const virtualField of Object.values(EnumFinancialReportUserVirtualField)) {
      financialReportUserAllAndTypes.virtualFields.all[virtualField as string] = 0;

      switch (virtualField) {
        case EnumFinancialReportUserVirtualField.goalPercent:
          if (financialReportUserAllAndTypes.all && this.companyFinancialGoal) {
            let objectiveGoal: number = 0;

            switch (period) {
              case EnumPeriod.months:
                objectiveGoal = this.companyFinancialGoal.monthlyGoal ?? 0;
                break;
              case EnumPeriod.quarters:
                objectiveGoal = this.companyFinancialGoal.quarterlyGoal ?? 0;
                break;
              case EnumPeriod.years:
                objectiveGoal = this.companyFinancialGoal.yearlyGoal ?? 0;
                break;
            }

            if (objectiveGoal !== 0) {
              financialReportUserAllAndTypes.virtualFields.all[virtualField] = roundNumber(
                (financialReportUserAllAndTypes.all.marginHT * 100) / objectiveGoal
              );
            }
          }
          break;
        case EnumFinancialReportUserVirtualField.y2y:
          if (
            this.financialReportUsersByPeriod[period][year] &&
            this.financialReportUsersByPeriod[period][year][index] &&
            this.financialReportUsersByPeriod[period][year][index].all &&
            previousYearData &&
            previousYearData.all
          ) {
            financialReportUserAllAndTypes.virtualFields.all[virtualField] =
              this.financialReportUsersByPeriod[period][year][index].all.marginHT -
              previousYearData.all.marginHT;
          }
          break;
        case EnumFinancialReportUserVirtualField.gap:
          if (
            this.financialReportUsersByPeriod[period][year] &&
            this.financialReportUsersByPeriod[period][year][index] &&
            this.financialReportUsersByPeriod[period][year][index].all &&
            this.financialReportUsersByPeriod[period][year][index].all &&
            previousYearData &&
            previousYearData.all
          ) {
            financialReportUserAllAndTypes.virtualFields.all[virtualField] =
              ((this.financialReportUsersByPeriod[period][year][index].all.marginHT -
                previousYearData.all.marginHT) *
                100) /
              previousYearData.all.marginHT;
          }
          break;
        default:
          for (const userId in this.usersObj) {
            financialReportUserAllAndTypes.virtualFields.all[virtualField as string] +=
              financialReportUserAllAndTypes.virtualFields[userId][virtualField];
          }

          break;
      }
    }

    return financialReportUserAllAndTypes;
  }

  loadAllUsers(): void {
    this.loadingUsers = true;

    this.subscriptions.add(
      this.userService.getAll().subscribe((users: IUser[]) => {
        for (const user of users) {
          this.usersObj[user.id] = user;
        }

        setTimeout(() => {
          this.loadingUsers = false;
        }, 1000);
      })
    );
  }

  loadFinancialReportUsersYearsOnly(): void {
    this.loadingYearsOnly = true;

    // By years
    this.subscriptionsForYear.add(
      this.financialReportUsersService
        .getAllForCurrencyOnlyYears(this.currentCurrency)
        .subscribe((financialReportUsers: IFinancialReportUser[]) => {
          this.financialReportUsersOnlyYears = financialReportUsers;

          this.organiseFinancialReportUsersYearsOnly();

          this.loadingYearsOnly = false;
        })
    );
  }

  loadFinancialReportStaff(): void {
    this.loadingFinancialReportStaff = true;

    this.subscriptionsForYear.add(
      this.financialReportStaffsService
        .getAll()
        .subscribe((financialReportStaffs: IFinancialReportStaff[]) => {
          for (const financialReportStaff of financialReportStaffs) {
            this.financialReportStaffsByYear[financialReportStaff.year.toString()] =
              financialReportStaff;
          }

          this.organiseFinancialReportUsers(this.currentYear);
          this.organiseFinancialReportUsersYearsOnly();

          this.loadingFinancialReportStaff = false;
        })
    );
  }

  organiseFinancialReportUsersYearsOnly(): void {
    this.financialReportUsersByPeriod[EnumPeriod.years] = {
      all: []
    };

    const financialReportUsersByYearObj: {
      [year: string]: IFinancialReportUserAllAndTypes;
    } = {};

    for (const financialReportUser of this.financialReportUsersOnlyYears) {
      if (typeof financialReportUsersByYearObj[financialReportUser.year] === 'undefined') {
        const objectByUsers: IFinancialReportUserByUser = {};
        const virtualFieldsByUsers: IFinancialReportUserVirtualFieldsAllAndUsers = {
          all: {} as IFinancialReportUserVirtualFields
        };

        for (const field in virtualFieldsByUsers) {
          for (const virtualField of Object.values(EnumFinancialReportUserVirtualField)) {
            virtualFieldsByUsers[field][virtualField] = 0;
          }
        }

        financialReportUsersByYearObj[financialReportUser.year] = {
          label: financialReportUser.year.toString(),
          all: null,
          financialReportUsersByUserId: this.cloneVariable(objectByUsers),
          financialReportUsersByUsers: [],
          virtualFields: this.cloneVariable(virtualFieldsByUsers)
        };
      }

      if (financialReportUser.userId) {
        financialReportUsersByYearObj[financialReportUser.year].financialReportUsersByUserId[
          financialReportUser.userId
        ] = financialReportUser;
      } else {
        financialReportUsersByYearObj[financialReportUser.year].all = financialReportUser;
      }
    }

    this.financialReportUsersByPeriod[EnumPeriod.years].all = Object.values(
      financialReportUsersByYearObj
    ).reverse();

    for (let i = 0; i < this.financialReportUsersByPeriod[EnumPeriod.years].all.length; i++) {
      let financialReportUserAllAndTypes =
        this.financialReportUsersByPeriod[EnumPeriod.years].all[i];

      financialReportUserAllAndTypes.financialReportUsersByUsers = Object.values(
        financialReportUserAllAndTypes.financialReportUsersByUserId
      );
      financialReportUserAllAndTypes.financialReportUsersByUsers.sort((a, b) =>
        a.userFullName < b.userFullName ? -1 : 1
      );

      financialReportUserAllAndTypes = this.setVirtualFieldsForFinancialReportUser(
        EnumPeriod.years,
        'all',
        i,
        financialReportUserAllAndTypes
      );
    }

    this.loadingYearsOnly = false;
  }

  async submitGoalForm(): Promise<void> {
    this.formGoal.markAsTouched();

    if (this.formGoal.status === 'VALID') {
      let data: IFinancialGoal = Object.assign(
        {
          currency: this.currentCurrency,
          year: this.currentYear
        },
        this.formGoal.value
      );

      this.savingGoal = true;

      this.formGoal.disable();

      data.id = data.currency + '_' + data.year + '_' + (data.userId ?? 'all');

      this.financialGoalsService
        .createWithId(data.id, data)
        .then(async id => {
          this.formGoal.reset();
          this.formGoal.enable();
          this.savingGoal = false;

          this.removeModal();
        })
        .catch(err => {
          console.log(err);
          this.savingGoal = false;

          alert(err.message);
        })
        .finally(() => {
          this.formGoal.enable();
        });
    }
  }

  showStaffModal(): void {
    if (this.financialReportStaffsByYear[this.currentYear]) {
      this.formStaff
        .get('nbSales')
        .setValue(this.financialReportStaffsByYear[this.currentYear].nbSales ?? 0);
      this.formStaff
        .get('nbEmployes')
        .setValue(this.financialReportStaffsByYear[this.currentYear].nbEmployes ?? 0);
    }

    window['$'](this.modalStaffElement.nativeElement).modal('show');
  }

  async submitStaffForm(): Promise<void> {
    this.formStaff.markAsTouched();

    if (this.formStaff.status === 'VALID') {
      let data: IFinancialReportStaff = Object.assign(
        {
          year: this.currentYear
        },
        this.formStaff.value
      );

      this.savingStaff = true;

      this.formStaff.disable();

      data.id = data.year.toString();

      this.financialReportStaffsService
        .createWithId(data.id, data)
        .then(async id => {
          this.formStaff.reset();
          this.formStaff.enable();
          this.savingStaff = false;

          this.removeModal();
        })
        .catch(err => {
          console.log(err);
          this.savingStaff = false;

          alert(err.message);
        })
        .finally(() => {
          this.formStaff.enable();
        });
    }
  }
}
