<div class="row align-items-center justify-content-center h-100">
  <div class="col-md-6 col-xl-4 mb-4">
    <div class="text-center">
      <img [src]="rootUrl + 'assets/img/logo.png'" alt="Artheau Aviation" width="250">
      <h1>Mot de passe oublié</h1>
    </div>
    <form (ngSubmit)="submitForm()" [formGroup]="form"  (keydown.enter)="submitForm()">
      <div class="form-group">
        <label for="email">Adresse email :</label>
        <input formControlName="email" id="email" class="form-control" type="email" required [class.is-invalid]="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)">
        <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched) && form.controls['email'].errors.required" class="invalid-tooltip">Ce champ est obligatoire.</div>
      </div>

      <div class="text-center">
        <button type="submit" class="btn btn-primary" [innerHtml]="sending ? 'Envoie en cours ...' : 'Sauvegarder'" [disabled]="form.disabled"></button><br>
        <br>
        <a routerLink="/login">Vous avez déjà un compte ? Se connecter</a>
      </div>
    </form>
  </div>
</div>
