<div class="row mt-4">
    <div class="col-sm-6 col-xl-3">
        <div class="form-group">
            <select
                class="form-select"
                id="currentYear"
                [(ngModel)]="currentYear"
                required
                [disabled]="
                    !hasAclAccess(EnumAcl.reportsSwitchYears) || !yearsList || !yearsList.length
                "
                (change)="changeYear()"
            >
                <option
                    [ngValue]="year.value"
                    *ngFor="let year of yearsList"
                    [innerHtml]="year.title"
                ></option>
            </select>
        </div>
    </div>
</div>

<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="month" *ngIf="hasAclAccess(EnumAcl.reportsDisplayByMonth)">
        <button
            class="nav-link"
            [class.active]="hasAclAccess(EnumAcl.reportsDisplayByMonth)"
            id="month-tab"
            data-bs-toggle="tab"
            href="#month"
            role="tab"
            aria-controls="month"
            aria-selected="true"
        >
            Rapport par mois
        </button>
    </li>
    <li class="nav-item" role="trimester" *ngIf="hasAclAccess(EnumAcl.reportsDisplayByTrimester)">
        <a
            class="nav-link"
            [class.active]="
                hasAclAccess(EnumAcl.reportsDisplayByTrimester) &&
                !hasAclAccess(EnumAcl.reportsDisplayByMonth)
            "
            id="trimester-tab"
            data-bs-toggle="tab"
            href="#trimester"
            role="tab"
            aria-controls="trimester"
            aria-selected="false"
            >Rapport par trimestre</a
        >
    </li>
    <li class="nav-item" role="year" *ngIf="hasAclAccess(EnumAcl.reportsDisplayByYear)">
        <a
            class="nav-link"
            [class.active]="
                hasAclAccess(EnumAcl.reportsDisplayByYear) &&
                !hasAclAccess(EnumAcl.reportsDisplayByMonth) &&
                !hasAclAccess(EnumAcl.reportsDisplayByTrimester)
            "
            id="year-tab"
            data-bs-toggle="tab"
            href="#year"
            role="tab"
            aria-controls="year"
            aria-selected="false"
            >Rapport par année</a
        >
    </li>
    <li class="nav-item" role="goal" *ngIf="hasAclAccess(EnumAcl.reportsGoal)">
        <a
            class="nav-link"
            [class.active]="
                hasAclAccess(EnumAcl.reportsGoal) &&
                !hasAclAccess(EnumAcl.reportsDisplayByYear) &&
                !hasAclAccess(EnumAcl.reportsDisplayByMonth) &&
                !hasAclAccess(EnumAcl.reportsDisplayByTrimester)
            "
            id="goal-tab"
            data-bs-toggle="tab"
            href="#goal"
            role="tab"
            aria-controls="goal"
            aria-selected="false"
            >Objectif</a
        >
    </li>
</ul>

<div class="tab-content">
    <div
        class="tab-pane"
        [class.active]="hasAclAccess(EnumAcl.reportsDisplayByMonth)"
        id="month"
        role="tabpanel"
        aria-labelledby="month-tab"
    >
        <div class="table-reponsive mb-4">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th></th>
                        <th class="text-end">Prix d'achat TC et CC</th>
                        <th class="text-end">Prix de vente TC et CC</th>
                        <th class="text-end">Marge brut</th>
                        <th class="text-end">Marge %</th>
                        <th class="text-center">Nb demandes</th>
                        <th class="text-center">Nb confirmées</th>
                        <th class="text-end">Ratio D/C</th>
                        <th
                            class="text-end"
                            *ngIf="data.goals[currentYear] && data.goals[currentYear].month"
                        >
                            Delta marge {{ currentYear - 1 }}
                        </th>
                        <th
                            class="text-end"
                            *ngIf="data.goals[currentYear] && data.goals[currentYear].month"
                        >
                            Objectif : {{ formatPrice(data.goals[currentYear].month) }} / M<br />
                            Delta objectif
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="loading">
                    <tr *ngFor="let number of [].constructor(12)">
                        <td *ngFor="let cell of [].constructor(10)">
                            <div class="skeleton-text"></div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="!loading">
                    <tr
                        *ngFor="let dataLine of data.months.content"
                        [class.disabled]="dataLine.countEnquiries <= 0"
                    >
                        <th [innerHtml]="dataLine.title"></th>
                        <td
                            [innerHtml]="formatPrice(dataLine.priceNetTTC)"
                            [class.text-success]="dataLine.priceNetTTC > 0"
                            [class.text-danger]="dataLine.priceNetTTC < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="formatPrice(dataLine.priceSell)"
                            [class.text-success]="dataLine.priceSell > 0"
                            [class.text-danger]="dataLine.priceSell < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="formatPrice(dataLine.margeBrut)"
                            [class.text-success]="dataLine.margeBrut > 0"
                            [class.text-danger]="dataLine.margeBrut < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="dataLine.margeBrutPercent + '%'"
                            [class.text-success]="dataLine.margeBrutPercent > 0"
                            [class.text-danger]="dataLine.margeBrutPercent < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="dataLine.countEnquiries"
                            [class.text-success]="dataLine.countEnquiries > 0"
                            [class.text-danger]="dataLine.countEnquiries < 0"
                            class="text-center"
                        ></td>
                        <td
                            [innerHtml]="dataLine.countConfirmed"
                            [class.text-success]="dataLine.countConfirmed > 0"
                            [class.text-danger]="dataLine.countConfirmed < 0"
                            class="text-center"
                        ></td>
                        <td
                            [innerHtml]="dataLine.ratioConversion + '%'"
                            [class.text-success]="dataLine.ratioConversion > 0"
                            [class.text-danger]="dataLine.ratioConversion < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="formatPrice(dataLine.deltaMarge)"
                            [class.text-success]="dataLine.deltaMarge > 0"
                            [class.text-danger]="dataLine.deltaMarge < 0"
                            class="text-end"
                            *ngIf="data.goals[currentYear] && data.goals[currentYear].month"
                        ></td>
                        <td
                            [innerHtml]="
                                formatPrice(dataLine.deltaObjectif) +
                                '<br>' +
                                dataLine.deltaObjectifPercent +
                                '%'
                            "
                            [class.text-success]="dataLine.deltaObjectif > 0"
                            [class.text-danger]="dataLine.deltaObjectif < 0"
                            class="text-end"
                            *ngIf="data.goals[currentYear] && data.goals[currentYear].month"
                        ></td>
                    </tr>
                </tbody>
                <tfoot *ngIf="!loading && data.months.total">
                    <tr class="table-primary">
                        <th>Total</th>
                        <th
                            [innerHtml]="formatPrice(data.months.total.priceNetTTC)"
                            [class.text-success]="data.months.total.priceNetTTC > 0"
                            [class.text-danger]="data.months.total.priceNetTTC < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="formatPrice(data.months.total.priceSell)"
                            [class.text-success]="data.months.total.priceSell > 0"
                            [class.text-danger]="data.months.total.priceSell < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="formatPrice(data.months.total.margeBrut)"
                            [class.text-success]="data.months.total.margeBrut > 0"
                            [class.text-danger]="data.months.total.margeBrut < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="data.months.total.margeBrutPercent + '%'"
                            [class.text-success]="data.months.total.margeBrutPercent > 0"
                            [class.text-danger]="data.months.total.margeBrutPercent < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="data.months.total.countEnquiries"
                            [class.text-success]="data.months.total.countEnquiries > 0"
                            [class.text-danger]="data.months.total.countEnquiries < 0"
                            class="text-center"
                        ></th>
                        <th
                            [innerHtml]="data.months.total.countConfirmed"
                            [class.text-success]="data.months.total.countConfirmed > 0"
                            [class.text-danger]="data.months.total.countConfirmed < 0"
                            class="text-center"
                        ></th>
                        <th
                            [innerHtml]="data.months.total.ratioConversion + '%'"
                            [class.text-success]="data.months.total.ratioConversion > 0"
                            [class.text-danger]="data.months.total.ratioConversion < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="formatPrice(data.months.total.deltaMarge)"
                            [class.text-success]="data.months.total.deltaMarge > 0"
                            [class.text-danger]="data.months.total.deltaMarge < 0"
                            class="text-end"
                            *ngIf="data.goals[currentYear] && data.goals[currentYear].month"
                        ></th>
                        <th
                            [innerHtml]="
                                formatPrice(data.months.total.deltaObjectif) +
                                '<br>' +
                                data.months.total.deltaObjectifPercent +
                                '%'
                            "
                            [class.text-success]="data.months.total.deltaObjectif > 0"
                            [class.text-danger]="data.months.total.deltaObjectif < 0"
                            class="text-end"
                            *ngIf="data.goals[currentYear] && data.goals[currentYear].month"
                        ></th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div
        class="tab-pane"
        [class.active]="
            hasAclAccess(EnumAcl.reportsDisplayByTrimester) &&
            !hasAclAccess(EnumAcl.reportsDisplayByMonth)
        "
        id="trimester"
        role="tabpanel"
        aria-labelledby="trimester-tab"
    >
        <div class="table-reponsive mb-4">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th></th>
                        <th class="text-end">Prix d'achat TC et CC</th>
                        <th class="text-end">Prix de vente TC et CC</th>
                        <th class="text-end">Marge brut</th>
                        <th class="text-end">Marge %</th>
                        <th class="text-center">Nb demandes</th>
                        <th class="text-center">Nb confirmées</th>
                        <th class="text-end">Ratio D/C</th>
                        <th
                            class="text-end"
                            *ngIf="data.goals[currentYear] && data.goals[currentYear].trimester"
                        >
                            Delta marge {{ currentYear - 1 }}
                        </th>
                        <th
                            class="text-end"
                            *ngIf="data.goals[currentYear] && data.goals[currentYear].trimester"
                        >
                            Objectif : {{ formatPrice(data.goals[currentYear].trimester) }} / T<br />
                            Delta objectif
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="loading">
                    <tr *ngFor="let number of [].constructor(12)">
                        <td *ngFor="let cell of [].constructor(8)">
                            <div class="skeleton-text"></div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="!loading">
                    <tr
                        *ngFor="let dataLine of data.trimesters.content"
                        [class.disabled]="dataLine.countEnquiries <= 0"
                    >
                        <th [innerHtml]="dataLine.title"></th>
                        <td
                            [innerHtml]="formatPrice(dataLine.priceNetTTC)"
                            [class.text-success]="dataLine.priceNetTTC > 0"
                            [class.text-danger]="dataLine.priceNetTTC < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="formatPrice(dataLine.priceSell)"
                            [class.text-success]="dataLine.priceSell > 0"
                            [class.text-danger]="dataLine.priceSell < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="formatPrice(dataLine.margeBrut)"
                            [class.text-success]="dataLine.margeBrut > 0"
                            [class.text-danger]="dataLine.margeBrut < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="dataLine.margeBrutPercent + '%'"
                            [class.text-success]="dataLine.margeBrutPercent > 0"
                            [class.text-danger]="dataLine.margeBrutPercent < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="dataLine.countEnquiries"
                            [class.text-success]="dataLine.countEnquiries > 0"
                            [class.text-danger]="dataLine.countEnquiries < 0"
                            class="text-center"
                        ></td>
                        <td
                            [innerHtml]="dataLine.countConfirmed"
                            [class.text-success]="dataLine.countConfirmed > 0"
                            [class.text-danger]="dataLine.countConfirmed < 0"
                            class="text-center"
                        ></td>
                        <td
                            [innerHtml]="dataLine.ratioConversion + '%'"
                            [class.text-success]="dataLine.ratioConversion > 0"
                            [class.text-danger]="dataLine.ratioConversion < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="formatPrice(dataLine.deltaMarge)"
                            [class.text-success]="dataLine.deltaMarge > 0"
                            [class.text-danger]="dataLine.deltaMarge < 0"
                            class="text-end"
                            *ngIf="data.goals[currentYear] && data.goals[currentYear].trimester"
                        ></td>
                        <td
                            [innerHtml]="
                                formatPrice(dataLine.deltaObjectif) +
                                '<br>' +
                                dataLine.deltaObjectifPercent +
                                '%'
                            "
                            [class.text-success]="dataLine.deltaObjectif > 0"
                            [class.text-danger]="dataLine.deltaObjectif < 0"
                            class="text-end"
                            *ngIf="data.goals[currentYear] && data.goals[currentYear].trimester"
                        ></td>
                    </tr>
                </tbody>
                <tfoot *ngIf="!loading && data.trimesters.total">
                    <tr class="table-primary">
                        <th>Total</th>
                        <th
                            [innerHtml]="formatPrice(data.trimesters.total.priceNetTTC)"
                            [class.text-success]="data.trimesters.total.priceNetTTC > 0"
                            [class.text-danger]="data.trimesters.total.priceNetTTC < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="formatPrice(data.trimesters.total.priceSell)"
                            [class.text-success]="data.trimesters.total.priceSell > 0"
                            [class.text-danger]="data.trimesters.total.priceSell < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="formatPrice(data.trimesters.total.margeBrut)"
                            [class.text-success]="data.trimesters.total.margeBrut > 0"
                            [class.text-danger]="data.trimesters.total.margeBrut < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="data.trimesters.total.margeBrutPercent + '%'"
                            [class.text-success]="data.trimesters.total.margeBrutPercent > 0"
                            [class.text-danger]="data.trimesters.total.margeBrutPercent < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="data.trimesters.total.countEnquiries"
                            [class.text-success]="data.trimesters.total.countEnquiries > 0"
                            [class.text-danger]="data.trimesters.total.countEnquiries < 0"
                            class="text-center"
                        ></th>
                        <th
                            [innerHtml]="data.trimesters.total.countConfirmed"
                            [class.text-success]="data.trimesters.total.countConfirmed > 0"
                            [class.text-danger]="data.trimesters.total.countConfirmed < 0"
                            class="text-center"
                        ></th>
                        <th
                            [innerHtml]="data.trimesters.total.ratioConversion + '%'"
                            [class.text-success]="data.trimesters.total.ratioConversion > 0"
                            [class.text-danger]="data.trimesters.total.ratioConversion < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="formatPrice(data.trimesters.total.deltaMarge)"
                            [class.text-success]="data.trimesters.total.deltaMarge > 0"
                            [class.text-danger]="data.trimesters.total.deltaMarge < 0"
                            class="text-end"
                            *ngIf="data.goals[currentYear] && data.goals[currentYear].trimester"
                        ></th>
                        <th
                            [innerHtml]="
                                formatPrice(data.trimesters.total.deltaObjectif) +
                                '<br>' +
                                data.trimesters.total.deltaObjectifPercent +
                                '%'
                            "
                            [class.text-success]="data.trimesters.total.deltaObjectif > 0"
                            [class.text-danger]="data.trimesters.total.deltaObjectif < 0"
                            class="text-end"
                            *ngIf="data.goals[currentYear] && data.goals[currentYear].trimester"
                        ></th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div
        class="tab-pane"
        [class.active]="
            hasAclAccess(EnumAcl.reportsDisplayByYear) &&
            !hasAclAccess(EnumAcl.reportsDisplayByMonth) &&
            !hasAclAccess(EnumAcl.reportsDisplayByTrimester)
        "
        id="year"
        role="tabpanel"
        aria-labelledby="year-tab"
    >
        <div class="table-reponsive mb-4">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th></th>
                        <th class="text-end">Prix d'achat TC et CC</th>
                        <th class="text-end">Prix de vente TC et CC</th>
                        <th class="text-end">Marge brut</th>
                        <th class="text-end">Marge %</th>
                        <th class="text-center">Nb demandes</th>
                        <th class="text-center">Nb confirmées</th>
                        <th class="text-end">Ratio D/C</th>
                        <th class="text-end">Delta marge année précédente</th>
                        <th class="text-end">Delta objectif</th>
                    </tr>
                </thead>
                <tbody *ngIf="loading">
                    <tr *ngFor="let number of [].constructor(12)">
                        <td *ngFor="let cell of [].constructor(10)">
                            <div class="skeleton-text"></div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="!loading">
                    <tr
                        *ngFor="let dataLine of data.years.content"
                        [class.disabled]="dataLine.countEnquiries <= 0"
                    >
                        <th [innerHtml]="dataLine.title"></th>
                        <td
                            [innerHtml]="formatPrice(dataLine.priceNetTTC)"
                            [class.text-success]="dataLine.priceNetTTC > 0"
                            [class.text-danger]="dataLine.priceNetTTC < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="formatPrice(dataLine.priceSell)"
                            [class.text-success]="dataLine.priceSell > 0"
                            [class.text-danger]="dataLine.priceSell < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="formatPrice(dataLine.margeBrut)"
                            [class.text-success]="dataLine.margeBrut > 0"
                            [class.text-danger]="dataLine.margeBrut < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="dataLine.margeBrutPercent + '%'"
                            [class.text-success]="dataLine.margeBrutPercent > 0"
                            [class.text-danger]="dataLine.margeBrutPercent < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="dataLine.countEnquiries"
                            [class.text-success]="dataLine.countEnquiries > 0"
                            [class.text-danger]="dataLine.countEnquiries < 0"
                            class="text-center"
                        ></td>
                        <td
                            [innerHtml]="dataLine.countConfirmed"
                            [class.text-success]="dataLine.countConfirmed > 0"
                            [class.text-danger]="dataLine.countConfirmed < 0"
                            class="text-center"
                        ></td>
                        <td
                            [innerHtml]="dataLine.ratioConversion + '%'"
                            [class.text-success]="dataLine.ratioConversion > 0"
                            [class.text-danger]="dataLine.ratioConversion < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="formatPrice(dataLine.deltaMarge)"
                            [class.text-success]="dataLine.deltaMarge > 0"
                            [class.text-danger]="dataLine.deltaMarge < 0"
                            class="text-end"
                        ></td>
                        <td
                            [innerHtml]="
                                data.goals[dataLine.title] && data.goals[dataLine.title].year
                                    ? formatPrice(dataLine.deltaObjectif) +
                                      '<br>' +
                                      dataLine.deltaObjectifPercent +
                                      '%'
                                    : ''
                            "
                            [class.text-success]="dataLine.deltaObjectif > 0"
                            [class.text-danger]="dataLine.deltaObjectif < 0"
                            class="text-end"
                        ></td>
                    </tr>
                </tbody>
                <tfoot *ngIf="!loading && data.years.total">
                    <tr class="table-primary">
                        <th>Total</th>
                        <th
                            [innerHtml]="formatPrice(data.years.total.priceNetTTC)"
                            [class.text-success]="data.years.total.priceNetTTC > 0"
                            [class.text-danger]="data.years.total.priceNetTTC < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="formatPrice(data.years.total.priceSell)"
                            [class.text-success]="data.years.total.priceSell > 0"
                            [class.text-danger]="data.years.total.priceSell < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="formatPrice(data.years.total.margeBrut)"
                            [class.text-success]="data.years.total.margeBrut > 0"
                            [class.text-danger]="data.years.total.margeBrut < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="data.years.total.margeBrutPercent + '%'"
                            [class.text-success]="data.years.total.margeBrutPercent > 0"
                            [class.text-danger]="data.years.total.margeBrutPercent < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="data.years.total.countEnquiries"
                            [class.text-success]="data.years.total.countEnquiries > 0"
                            [class.text-danger]="data.years.total.countEnquiries < 0"
                            class="text-center"
                        ></th>
                        <th
                            [innerHtml]="data.years.total.countConfirmed"
                            [class.text-success]="data.years.total.countConfirmed > 0"
                            [class.text-danger]="data.years.total.countConfirmed < 0"
                            class="text-center"
                        ></th>
                        <th
                            [innerHtml]="data.years.total.ratioConversion + '%'"
                            [class.text-success]="data.years.total.ratioConversion > 0"
                            [class.text-danger]="data.years.total.ratioConversion < 0"
                            class="text-end"
                        ></th>
                        <th
                            [innerHtml]="formatPrice(data.years.total.deltaMarge)"
                            [class.text-success]="data.years.total.deltaMarge > 0"
                            [class.text-danger]="data.years.total.deltaMarge < 0"
                            class="text-end"
                        ></th>
                        <th></th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div
        class="tab-pane"
        [class.active]="
            hasAclAccess(EnumAcl.reportsGoal) &&
            !hasAclAccess(EnumAcl.reportsDisplayByYear) &&
            !hasAclAccess(EnumAcl.reportsDisplayByMonth) &&
            !hasAclAccess(EnumAcl.reportsDisplayByTrimester)
        "
        id="goal"
        role="tabpanel"
        aria-labelledby="goal-tab"
    >
        <form
            (submit)="submitForm()"
            [formGroup]="form"
            (keydown.enter)="submitForm()"
            class="main-form"
        >
            <div class="row">
                <div class="col-md">
                    <div class="form-group">
                        <label for="goalYear">Objectif de l'année {{ currentYear }}</label>
                        <input
                            class="form-control"
                            id="goalYear"
                            formControlName="goalYear"
                            type="number"
                            min="0"
                            [class.is-invalid]="
                                (form.touched || form.controls['goalYear'].touched) &&
                                form.controls['goalYear'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['goalYear'].touched) &&
                                    form.controls['goalYear'].errors &&
                                    form.controls['goalYear'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="form-group">
                        <label for="goalTrimester">Objectif par trimestre {{ currentYear }}</label>
                        <input
                            class="form-control"
                            id="goalTrimester"
                            formControlName="goalTrimester"
                            type="number"
                            min="0"
                            [class.is-invalid]="
                                (form.touched || form.controls['goalTrimester'].touched) &&
                                form.controls['goalTrimester'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['goalTrimester'].touched) &&
                                    form.controls['goalTrimester'].errors &&
                                    form.controls['goalTrimester'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="form-group">
                        <label for="goalMonth">Objectif par mois {{ currentYear }}</label>
                        <input
                            class="form-control"
                            id="goalMonth"
                            formControlName="goalMonth"
                            type="number"
                            min="0"
                            [class.is-invalid]="
                                (form.touched || form.controls['goalMonth'].touched) &&
                                form.controls['goalMonth'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['goalMonth'].touched) &&
                                    form.controls['goalMonth'].errors &&
                                    form.controls['goalMonth'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center">
                <button
                    type="submit"
                    class="btn btn-primary mb-4"
                    [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                    [disabled]="form.disabled || !form.valid || sending"
                ></button>
            </div>
        </form>
    </div>
</div>
