import { Component, OnDestroy, OnInit } from '@angular/core';
import { getYear } from 'date-fns';
import { Subscription } from 'rxjs';
import { EnumCurrency, getEnumCurrencySymbol } from 'src/app/enums/currency.enum';
import { IEnquiryPaymentSummary } from 'src/app/interfaces/enquiry-payment-summary.interface';
import { EnquiryPaymentSummaryService } from 'src/app/services/enquiry-payment-summaries/enquiry-payment-summaries.service';

export enum EnumPeriod {
  months = 'months',
  quarters = 'quarters',
  years = 'years'
}

export const getPeriodLabel = (period: EnumPeriod): string => {
  switch (period) {
    default:
      return period;
    case EnumPeriod.months:
      return 'Mois';
    case EnumPeriod.quarters:
      return 'Trimestres';
    case EnumPeriod.years:
      return 'Années';
  }
};

enum EnumFinancialReportTab {
  total = 'total',
  team = 'team',
  department = 'department',
  clients = 'clients',
  airlines = 'airlines'
}

interface ITab {
  value: EnumFinancialReportTab;
  title: string;
}

@Component({
  selector: 'app-financial-reports',
  templateUrl: './financial-reports.component.html',
  styleUrls: ['./financial-reports.component.scss']
})
export class FinancialReportsComponent implements OnInit, OnDestroy {
  yearsList: {
    title: string;
    value: number;
  }[] = [];
  currentYear: number = getYear(new Date());
  tabOpened: EnumFinancialReportTab = EnumFinancialReportTab.total;
  currentPeriod: EnumPeriod = EnumPeriod.months;
  currentCurrency: EnumCurrency | null = null;

  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getPeriodLabel = getPeriodLabel;

  EnumPeriod = EnumPeriod;
  EnumFinancialReportTab = EnumFinancialReportTab;

  firstYear: number = 2013;

  tabs: ITab[] = [
    {
      title: 'Année comptable',
      value: EnumFinancialReportTab.total
    },
    {
      title: 'Équipe',
      value: EnumFinancialReportTab.team
    },
    {
      title: 'Département',
      value: EnumFinancialReportTab.department
    },
    {
      title: 'Clients',
      value: EnumFinancialReportTab.clients
    },
    {
      title: 'Airlines',
      value: EnumFinancialReportTab.airlines
    }
  ];

  lastEnquiryPaymentSummary: IEnquiryPaymentSummary | null = null;

  subscriptionsLastEnquiryPaymentSummary = new Subscription();

  constructor(private enquiryPaymentSummaryService: EnquiryPaymentSummaryService) {}

  ngOnInit(): void {
    this.generateYearsList();

    this.getLastEnquiryPaymentSummary();
  }

  ngOnDestroy(): void {
    this.subscriptionsLastEnquiryPaymentSummary.unsubscribe();
  }

  getPeriods(): EnumPeriod[] {
    return Object.values(EnumPeriod);
  }

  getCurrencies(): Array<EnumCurrency | null> {
    return [...[null], ...Object.values(EnumCurrency)];
  }

  generateYearsList(): void {
    this.yearsList = [];

    const lastYear: number = this.lastEnquiryPaymentSummary
      ? this.lastEnquiryPaymentSummary.periodYear
      : this.currentYear;

    for (let year = lastYear; year >= this.firstYear; year--) {
      this.yearsList.push({
        title: year + ' (Année ' + (year - this.firstYear + 1) + ')',
        value: year
      });
    }
  }

  changeCurrentCurrency(newCurrency: EnumCurrency): void {
    this.currentCurrency = newCurrency;
  }

  changeCurrentPeriod(newPeriod: EnumPeriod): void {
    this.currentPeriod = newPeriod;
  }

  updateTabOpened(newTab: ITab): void {
    this.tabOpened = newTab.value;
  }

  getLastEnquiryPaymentSummary(): void {
    this.subscriptionsLastEnquiryPaymentSummary.add(
      this.enquiryPaymentSummaryService
        .getLast()
        .subscribe((lastEnquiryPaymentSummary: IEnquiryPaymentSummary) => {
          this.lastEnquiryPaymentSummary = lastEnquiryPaymentSummary;

          this.generateYearsList();
        })
    );
  }
}
