<breadcrumbs
    [items]="[
        {
            title: getEnquiryBreadcrumbTitle(enquiry),
            path: '/admin/enquiry/view/' + enquiryId
        }
    ]"
    *ngIf="isLogged"
></breadcrumbs>

<div class="mt-4" *ngIf="!isLogged"></div>

<div class="table-responsive main-table" *ngIf="loading">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th><div class="skeleton-text"></div></th>
                <th scope="col" class="sortable" *ngFor="let field of fields">
                    <div class="skeleton-text"></div>
                </th>
            </tr>
        </thead>
        <tbody *ngFor="let number of [].constructor(7)">
            <tr>
                <td><div class="skeleton-text"></div></td>
                <td scope="col" class="sortable" *ngFor="let field of fields">
                    <div class="skeleton-text"></div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="row mb-4" *ngIf="!loading && enquiry">
    <div class="col-lg-3 order-last">
        <div class="card text-center" *ngIf="enquiry">
            <div class="card-body">
                <h4 *ngIf="enquiry.refContractTitle">
                    <strong
                        [innerHtml]="
                            'N° contrat <br>' + getDisplayedEnquiryRefTitle(enquiry, 'refContract')
                        "
                    ></strong>
                </h4>
                <h4
                    [innerHtml]="
                        'N° demande <br>' + getDisplayedEnquiryRefTitle(enquiry, 'refEnquiry')
                    "
                    *ngIf="enquiry.refEnquiryTitle"
                ></h4>
            </div>
        </div>

        <div
            class="alert alert-warning"
            *ngIf="enquiry.status === EnumEnquiryStatus.cancelled && enquiry.reasonCancelled"
        >
            <p>Le dossier est <strong>annulé</strong>.</p>
            <p class="mb-0">
                <strong>Raison :</strong><br />
                {{ getEnumEnquiryCancelledReasonLabel(enquiry.reasonCancelled.reason) }}
            </p>
            <div *ngIf="enquiry.reasonCancelled.comment">
                <strong>Commentaire :</strong>
                <div [innerHtml]="enquiry.reasonCancelled.comment" class="cancelled-comment"></div>
            </div>
        </div>
    </div>
    <div class="col-lg">
        <div class="btn-toolbar justify-content-center mb-4" id="btns" *ngIf="!isLogged">
            <div class="btn-group">
                <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#enquiry-detail"
                >
                    Détails de la demande
                </button>
                <a routerLink="/login" class="btn btn-primary">Se connecter pour intéragir</a>
            </div>
        </div>

        <div class="btn-toolbar justify-content-center mb-4" id="" *ngIf="isLogged && enquiry">
            <div class="btn-group">
                <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#enquiry-detail"
                >
                    Détails de la demande
                </button>
                <a
                    [routerLink]="'/admin/enquiry/edit/' + enquiryId"
                    class="btn btn-primary"
                    *ngIf="
                        (enquiry.status !== EnumEnquiryStatus.confirmed &&
                            hasAclAccess(EnumAcl.enquiriesEdit)) ||
                        (enquiry.status === EnumEnquiryStatus.confirmed &&
                            hasAclAccess(EnumAcl.enquiriesEditForConfirmedEnquiry))
                    "
                    >Éditer</a
                >
                <a
                    [routerLink]="'/admin/enquiry/select-airlines/' + enquiryId"
                    class="btn btn-primary"
                    [class.disabled]="enquiry.status === EnumEnquiryStatus.confirmed"
                    *ngIf="hasAclAccess(EnumAcl.enquiriesSelectAirlines)"
                    >Sélection des compagnies</a
                >
                <a
                    [routerLink]="'/admin/enquiry/send-message-to-airlines/' + enquiryId"
                    class="btn btn-primary"
                    [class.disabled]="enquiry.status === EnumEnquiryStatus.confirmed"
                    *ngIf="hasAclAccess(EnumAcl.enquirieSendMessageToAirlines)"
                    >Contactez les compagnies</a
                >
                <a
                    [routerLink]="'/admin/enquiry/relance-airline/' + enquiryId"
                    class="btn btn-primary"
                    [class.disabled]="enquiry.status === EnumEnquiryStatus.confirmed"
                    *ngIf="hasAclAccess(EnumAcl.enquiriesRelanceAirline)"
                    >Relancez les compagnies</a
                >
                <a
                    [routerLink]="'/admin/enquiry/cancel-email-airline/' + enquiryId"
                    class="btn btn-primary"
                    [class.disabled]="
                        ![EnumEnquiryStatus.confirmed, EnumEnquiryStatus.cancelled].includes(
                            enquiry.status
                        )
                    "
                    *ngIf="hasAclAccess(EnumAcl.enquiriesCancelledEmailToAirline)"
                    >Email d'annulation pour les compagnies</a
                >
                <div class="dropdown">
                    <button
                        class="btn btn-primary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton-status"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        [class.disabled]="
                            !hasAclAccess(EnumAcl.enquiriesSwitchStatus) ||
                            (enquiry.status === EnumEnquiryStatus.confirmed &&
                                !hasAclAccess(EnumAcl.enquiriesSwitchStatusFromConfirmed))
                        "
                    >
                        Statut : {{ getEnumEnquiryStatusLabel(enquiry.status) }}
                    </button>
                    <div
                        class="dropdown-menu dropdown-menu-end"
                        aria-labelledby="dropdownMenuButton-status"
                    >
                        <button
                            type="button"
                            class="dropdown-item"
                            *ngFor="let enquiryStatus of getEnquiryStatus()"
                            [innerHtml]="
                                'Changer le statut en \'' +
                                getEnumEnquiryStatusLabel(enquiryStatus) +
                                '\''
                            "
                            (click)="changeStatus(enquiryStatus)"
                            [class.disabled]="enquiry.status === enquiryStatus"
                        ></button>
                    </div>
                </div>
                <a
                    [routerLink]="'/admin/quotations/list/enquiry/' + enquiryId"
                    class="btn btn-primary"
                    *ngIf="hasAclAccess(EnumAcl.quotationsList)"
                    >Devis</a
                >
                <a
                    [routerLink]="'/admin/invoices/list/enquiry/' + enquiryId"
                    class="btn btn-primary"
                    *ngIf="hasAclAccess(EnumAcl.invoicesList)"
                    >Factures</a
                >
                <a
                    [routerLink]="'/admin/flight-briefs/list/enquiry/' + enquiryId"
                    class="btn btn-primary"
                    *ngIf="hasAclAccess(EnumAcl.flightBriefsList)"
                    >Flight briefs</a
                >
            </div>
            <div class="btn-group ml-2">
                <a [routerLink]="'/public/enquiry/' + enquiryId" class="btn btn-primary"
                    >Lien public</a
                >
                <button
                    type="button"
                    (click)="copyPublicUrl()"
                    class="btn btn-primary"
                    rel="tooltip"
                    data-bs-title="Copier le lien public"
                >
                    <img
                        [src]="rootUrl + 'assets/img/icon-copy-white.svg'"
                        alt="Copy"
                        height="20"
                    />
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!loading && enquiry">
    <div class="alert alert-success text-center" *ngIf="enquiry.isCompleted">
        Ce dossier a été clotûré le {{ enquiry.dateCompleted | date : "dd/MM/yyyy à HH:mm" }}.

        <div class="mt-2" *ngIf="hasAclAccess(EnumAcl.enquiriesSetCommission)">
            <button type="button" class="btn btn-primary btn-sm" (click)="reopenEnquiry()">
                Réouvrir
            </button>
        </div>
    </div>

    <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
        <li
            class="nav-item"
            role="presentation"
            *ngIf="enquiry.status === EnumEnquiryStatus.confirmed"
        >
            <button
                class="nav-link active"
                [attr.aria-selected]="
                    enquiry.status === EnumEnquiryStatus.confirmed ? 'true' : 'false'
                "
                id="flights-tab"
                data-bs-toggle="tab"
                data-bs-target="#flights-tab-pane"
                type="button"
                role="tab"
                aria-controls="flights-tab-pane"
            >
                Vols
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button
                class="nav-link"
                [class.active]="enquiry.status !== EnumEnquiryStatus.confirmed"
                [attr.aria-selected]="
                    enquiry.status !== EnumEnquiryStatus.confirmed ? 'true' : 'false'
                "
                id="cotations-tab"
                data-bs-toggle="tab"
                data-bs-target="#cotations-tab-pane"
                type="button"
                role="tab"
                aria-controls="cotations-tab-pane"
            >
                Cotations
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button
                class="nav-link"
                id="documents-overview-tab"
                data-bs-toggle="tab"
                data-bs-target="#documents-overview-tab-pane"
                type="button"
                role="tab"
                aria-controls="documents-overview-tab-pane"
                aria-selected="false"
            >
                Vue d'ensemble des documents
            </button>
        </li>
        <!-- <li class="nav-item" role="presentation">
            <button
                class="nav-link"
                id="quotations-tab"
                data-bs-toggle="tab"
                data-bs-target="#quotations-tab-pane"
                type="button"
                role="tab"
                aria-controls="quotations-tab-pane"
                aria-selected="false"
            >
                Devis
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button
                class="nav-link"
                id="proformas-tab"
                data-bs-toggle="tab"
                data-bs-target="#proformas-tab-pane"
                type="button"
                role="tab"
                aria-controls="proformas-tab-pane"
                aria-selected="false"
            >
                Proformas
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button
                class="nav-link"
                id="invoices-tab"
                data-bs-toggle="tab"
                data-bs-target="#invoices-tab-pane"
                type="button"
                role="tab"
                aria-controls="invoices-tab-pane"
                aria-selected="false"
            >
                Factures
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button
                class="nav-link"
                id="credit-notes-tab"
                data-bs-toggle="tab"
                data-bs-target="#credit-notes-tab-pane"
                type="button"
                role="tab"
                aria-controls="credit-notes-tab-pane"
                aria-selected="false"
            >
                Avoirs
            </button>
        </li> -->
    </ul>
    <div class="tab-content" id="myTabContent">
        <div
            class="tab-pane fade show active"
            id="flights-tab-pane"
            role="tabpanel"
            aria-labelledby="flights-tab"
            tabindex="0"
            *ngIf="enquiry.status === EnumEnquiryStatus.confirmed"
        >
            <app-enquiry-flights-cards
                [loading]="loadingEnquiryFlights"
                [enquiryFlights]="enquiryFlights"
                [hideEnquiryColumns]="true"
                [enquiry]="enquiry"
            ></app-enquiry-flights-cards>
        </div>
        <div
            class="tab-pane fade"
            id="cotations-tab-pane"
            role="tabpanel"
            aria-labelledby="cotations-tab"
            tabindex="0"
            [class.show]="enquiry.status !== EnumEnquiryStatus.confirmed"
            [class.active]="enquiry.status !== EnumEnquiryStatus.confirmed"
        >
            <div class="alert alert-warning text-center" *ngIf="!lines.length">
                Aucune compagnie aérienne n'a été contactée.<br />
                <br />
                <a
                    [routerLink]="'/admin/enquiry/select-airlines/' + enquiryId"
                    class="btn btn-primary mb-1"
                    [class.disabled]="enquiry.status === EnumEnquiryStatus.confirmed"
                    *ngIf="enquiry"
                    >Sélection des compagnies</a
                >
            </div>

            <div class="table-responsive main-table" *ngIf="lines.length">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            <th
                                scope="col"
                                class="sortable text-center"
                                *ngFor="let field of fields"
                                [class.is-sorted]="orderBy.field == field.code"
                                [class.asc]="
                                    orderBy.field == field.code && orderBy.direction == 'asc'
                                "
                                [class.desc]="
                                    orderBy.field == field.code && orderBy.direction == 'desc'
                                "
                                (click)="sortField(field.code)"
                                [innerHtml]="field.title"
                            ></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let line of lines"
                            [class.table-success]="
                                line.status === EnumEnquiryCotationStatus.confirmed
                            "
                        >
                            <td class="text-start">
                                <a
                                    class="btn btn-primary"
                                    [routerLink]="[
                                        '/admin/quotations/add/' + enquiry.id,
                                        line.offerId,
                                        line.cotationId ? line.cotationId : ''
                                    ]"
                                    rel="tooltip"
                                    data-bs-title="Créer un devis"
                                    data-bs-placement="left"
                                    *ngIf="isLogged && hasAclAccess(EnumAcl.quotationsList)"
                                    ><img
                                        [src]="rootUrl + 'assets/img/icon-document-white.svg'"
                                        width="16"
                                        alt="Créer un devis"
                                /></a>

                                <div
                                    class="btn-group"
                                    *ngIf="
                                        isLogged &&
                                        ((enquiry.status !== EnumEnquiryStatus.confirmed &&
                                            hasAclAccess(EnumAcl.enquiriesAddEditCotation)) ||
                                            (enquiry.status === EnumEnquiryStatus.confirmed &&
                                                hasAclAccess(
                                                    EnumAcl.enquiriesAddEditCotationForConfirmedEnquiry
                                                )))
                                    "
                                >
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        [routerLink]="
                                            '/admin/enquiry/send-message-to-airlines/' +
                                            enquiry.id +
                                            '/' +
                                            line.airlineId
                                        "
                                        *ngIf="
                                            line.status === EnumEnquiryCotationStatus.toContact &&
                                            enquiry.status !== EnumEnquiryStatus.confirmed &&
                                            hasAclAccess(EnumAcl.enquirieSendMessageToAirlines)
                                        "
                                        rel="tooltip"
                                        data-bs-title="Contacter la compagnie aérienne"
                                        data-bs-placement="left"
                                    >
                                        <img
                                            [src]="rootUrl + 'assets/img/icon-envelope-white.svg'"
                                            width="16"
                                            alt="Contacter"
                                        />
                                    </button>

                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        [routerLink]="
                                            '/admin/enquiry/relance-airline/' +
                                            enquiry.id +
                                            '/' +
                                            line.airlineId
                                        "
                                        rel="tooltip"
                                        [title]="
                                            line.followedUp ? 'Relancer à nouveau' : 'Relancer'
                                        "
                                        data-bs-placement="left"
                                        *ngIf="
                                            line.status ===
                                                EnumEnquiryCotationStatus.waitingForAnswer &&
                                            enquiry.status !== EnumEnquiryStatus.confirmed &&
                                            hasAclAccess(EnumAcl.enquiriesRelanceAirline)
                                        "
                                    >
                                        <img
                                            [src]="rootUrl + 'assets/img/icon-envelope-white.svg'"
                                            width="16"
                                            alt="Contacter"
                                        />
                                    </button>

                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        [routerLink]="
                                            '/admin/enquiry/cancel-email-airline/' +
                                            enquiry.id +
                                            '/' +
                                            line.airlineId
                                        "
                                        rel="tooltip"
                                        title="Envoyer un email d'annulation"
                                        data-bs-placement="left"
                                        *ngIf="
                                            line.status === EnumEnquiryCotationStatus.answered &&
                                            [
                                                EnumEnquiryStatus.confirmed,
                                                EnumEnquiryStatus.cancelled
                                            ].includes(enquiry.status) &&
                                            hasAclAccess(EnumAcl.enquiriesCancelledEmailToAirline)
                                        "
                                    >
                                        <img
                                            [src]="rootUrl + 'assets/img/icon-envelope-white.svg'"
                                            width="16"
                                            alt="Contacter"
                                        />
                                    </button>

                                    <div *ngIf="line.offerId && line.cotationId">
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            (click)="editCotation(line.offerId, line.cotationId)"
                                            rel="tooltip"
                                            data-bs-title="Éditer la cotation"
                                            data-bs-placement="left"
                                        >
                                            <img
                                                [src]="rootUrl + 'assets/img/icon-edit-white.svg'"
                                                width="16"
                                                alt="Editer"
                                            />
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            (click)="addCotationToOffer(line.offerId)"
                                            rel="tooltip"
                                            data-bs-title="Ajouter une cotation à cette compagnie et appareil (par exemple, pour un autre leg)"
                                            data-bs-placement="left"
                                        >
                                            <img
                                                [src]="rootUrl + 'assets/img/icon-plus-white.svg'"
                                                width="16"
                                                alt="Ajouter"
                                            />
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            (click)="deleteCotation(line.cotationId)"
                                            rel="tooltip"
                                            data-bs-title="Supprimer la cotation"
                                            data-bs-placement="left"
                                        >
                                            <img
                                                [src]="rootUrl + 'assets/img/icon-close-white.svg'"
                                                width="16"
                                                alt="Supprimer"
                                            />
                                        </button>
                                    </div>
                                    <div *ngIf="line.offerId && !line.cotationId">
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            (click)="addCotationToOffer(line.offerId)"
                                            rel="tooltip"
                                            data-bs-title="Éditer la cotation"
                                            data-bs-placement="left"
                                        >
                                            <img
                                                [src]="rootUrl + 'assets/img/icon-edit-white.svg'"
                                                width="16"
                                                alt="Editer"
                                            />
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            (click)="deleteOffer(line.offerId)"
                                            rel="tooltip"
                                            data-bs-title="Supprimer cette compagnie et appareil"
                                            data-bs-placement="left"
                                        >
                                            <img
                                                [src]="rootUrl + 'assets/img/icon-close-white.svg'"
                                                width="16"
                                                alt="Supprimer"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span
                                    class="badge"
                                    [class.bg-primary]="
                                        line.status === EnumEnquiryCotationStatus.waitingForAnswer
                                    "
                                    [class.bg-success]="
                                        [
                                            EnumEnquiryCotationStatus.answered,
                                            EnumEnquiryCotationStatus.confirmed
                                        ].indexOf(line.status) !== -1
                                    "
                                    [class.bg-danger]="
                                        line.status === EnumEnquiryCotationStatus.rejected
                                    "
                                    [class.bg-warning]="
                                        line.status === EnumEnquiryCotationStatus.toContact
                                    "
                                    *ngIf="
                                        enquiry.status === EnumEnquiryStatus.confirmed ||
                                        !hasAclAccess(EnumAcl.enquiriesSwitchCotationStatus)
                                    "
                                    [innerHtml]="statusLabelObj[line.status]?.title"
                                ></span>

                                <div
                                    class="dropdown"
                                    *ngIf="
                                        enquiry.status !== EnumEnquiryStatus.confirmed &&
                                        hasAclAccess(EnumAcl.enquiriesSwitchCotationStatus)
                                    "
                                >
                                    <button
                                        type="button"
                                        class="btn btn-sm dropdown-toggle"
                                        [class.btn-primary]="
                                            line.status ===
                                            EnumEnquiryCotationStatus.waitingForAnswer
                                        "
                                        [class.btn-success]="
                                            line.status === EnumEnquiryCotationStatus.answered
                                        "
                                        [class.btn-danger]="
                                            [
                                                EnumEnquiryCotationStatus.rejected,
                                                EnumEnquiryCotationStatus.cancelled
                                            ].includes(line.status)
                                        "
                                        [class.btn-warning]="
                                            line.status === EnumEnquiryCotationStatus.toContact
                                        "
                                        [attr.id]="'dropdownMenuButton-line-status'"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        (click)="openDropdownCotationStatus()"
                                        [innerHtml]="statusLabelObj[line.status]?.title"
                                    ></button>
                                    <div
                                        class="dropdown-menu"
                                        [attr.aria-labelledby]="'dropdownMenuButton-line-status'"
                                    >
                                        <button
                                            type="button"
                                            class="dropdown-item"
                                            *ngFor="let status of statusLabelList"
                                            [innerHtml]="
                                                'Changer le statut en \'' + status.title + '\''
                                            "
                                            (click)="
                                                line.cotationId
                                                    ? changeOneCotationStatus(
                                                          line.cotationId,
                                                          status.value
                                                      )
                                                    : changeOfferStatus(line.offerId, status.value)
                                            "
                                            [class.disabled]="line.status === status.value"
                                        ></button>
                                    </div>
                                </div>
                            </td>
                            <td [innerHtml]="line.airlineTitle"></td>
                            <td [innerHtml]="line.airlineCountryTitle"></td>
                            <td>
                                <span [innerHtml]="line.legsTitle"></span>

                                <div
                                    *ngIf="
                                        line.legsId &&
                                        legsList[line.itineraryId] &&
                                        line.legsId.length &&
                                        line.legsId.length < legsList[line.itineraryId].length
                                    "
                                >
                                    <img
                                        [src]="rootUrl + 'assets/img/icon-warning-orange.svg'"
                                        width="24"
                                        alt="Attention"
                                        rel="tooltip"
                                        [title]="
                                            'Tous les legs ne sont pas présent sur cette cotation (' +
                                            line.legsId.length +
                                            '/' +
                                            legsList[line.itineraryId].length +
                                            ')'
                                        "
                                    />
                                </div>
                            </td>
                            <td>
                                {{ line.aircraftTitle.toUpperCase() }}

                                <div *ngIf="line.aircraftErrorMessages.length">
                                    <img
                                        [src]="rootUrl + 'assets/img/icon-warning-orange.svg'"
                                        width="24"
                                        alt="Attention"
                                        rel="tooltip"
                                        [title]="line.aircraftErrorMessages.join('<hr>')"
                                    />
                                </div>
                            </td>
                            <td
                                class="text-center"
                                [innerHtml]="line.ageAverage ? line.ageAverage + ' ans' : 'Inconnu'"
                            ></td>
                            <td
                                class="text-center"
                                [innerHtml]="line.capacityAircraft"
                                *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                            ></td>
                            <td
                                class="text-center"
                                [innerHtml]="line.weightAircraft + ' kg'"
                                *ngIf="enquiry.type === EnumEnquiryType.cargo"
                            ></td>
                            <td
                                class="text-center"
                                [innerHtml]="line.volumeAircraft + ' m<sup>3</sup>'"
                                *ngIf="enquiry.type === EnumEnquiryType.cargo"
                            ></td>
                            <td class="text-center" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                                <span [innerHtml]="line.nbPax"></span>

                                <div *ngIf="line.nbPax > line.capacityAircraft">
                                    <img
                                        [src]="rootUrl + 'assets/img/icon-warning-orange.svg'"
                                        width="24"
                                        alt="Attention"
                                        rel="tooltip"
                                        data-bs-title="Il y a plus de passagers que la capacité de l'appareil"
                                    />
                                </div>
                            </td>
                            <td class="text-end">
                                <div>
                                    {{
                                        line.buyingPriceInCurrency !== null
                                            ? formatPrice(line.buyingPriceInCurrency, line.currency)
                                            : ""
                                    }}
                                </div>
                                <div *ngIf="line.currency !== defaultCurrency">
                                    {{
                                        line.buyingPrice !== null
                                            ? "= " + formatPrice(line.buyingPrice, defaultCurrency)
                                            : ""
                                    }}
                                </div>

                                <div *ngIf="line.isNationalFlight && line.buyingPrice">
                                    <img
                                        [src]="rootUrl + 'assets/img/icon-warning-orange.svg'"
                                        width="24"
                                        alt="Attention"
                                        rel="tooltip"
                                        data-bs-title="Attention: les prix sont avec TVA car c'est un vol national"
                                    />
                                </div>
                            </td>
                            <td class="text-end" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                                <div>
                                    {{
                                        line.taxesTotalPaxInCurrency !== null
                                            ? formatPrice(
                                                  line.taxesTotalPaxInCurrency,
                                                  line.currency
                                              )
                                            : ""
                                    }}
                                </div>
                                <div *ngIf="line.currency !== defaultCurrency">
                                    {{
                                        line.taxesTotalPax !== null
                                            ? "= " +
                                              formatPrice(line.taxesTotalPax, defaultCurrency)
                                            : ""
                                    }}
                                </div>
                            </td>
                            <td class="text-end" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                                <div>
                                    {{
                                        line.cateringInCurrency !== null
                                            ? formatPrice(line.cateringInCurrency, line.currency)
                                            : ""
                                    }}
                                </div>
                                <div *ngIf="line.currency !== defaultCurrency">
                                    {{
                                        line.catering !== null
                                            ? "= " + formatPrice(line.catering, defaultCurrency)
                                            : ""
                                    }}
                                </div>
                            </td>
                            <td class="text-end">
                                <div>
                                    {{
                                        line.priceNetTTCInCurrency !== null
                                            ? formatPrice(line.priceNetTTCInCurrency, line.currency)
                                            : ""
                                    }}
                                </div>
                                <div *ngIf="line.currency !== defaultCurrency">
                                    {{
                                        line.priceNetTTC !== null
                                            ? "= " + formatPrice(line.priceNetTTC, defaultCurrency)
                                            : ""
                                    }}
                                </div>

                                <div *ngIf="line.isNationalFlight && line.priceNetTTC">
                                    <img
                                        [src]="rootUrl + 'assets/img/icon-warning-orange.svg'"
                                        width="24"
                                        alt="Attention"
                                        rel="tooltip"
                                        data-bs-title="Attention: les prix sont avec TVA car c'est un vol national"
                                    />
                                </div>
                            </td>
                            <td class="text-end" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                                <div>
                                    {{
                                        line.pricePPInCurrency !== null
                                            ? formatPrice(line.pricePPInCurrency, line.currency)
                                            : ""
                                    }}
                                </div>
                                <div *ngIf="line.currency !== defaultCurrency">
                                    {{
                                        line.pricePP !== null
                                            ? "= " + formatPrice(line.pricePP, defaultCurrency)
                                            : ""
                                    }}
                                </div>
                            </td>
                            <td class="text-end">
                                <div>
                                    {{
                                        line.sellingPriceInCurrency !== null
                                            ? formatPrice(
                                                  line.sellingPriceInCurrency,
                                                  line.currency
                                              )
                                            : ""
                                    }}
                                </div>
                                <div *ngIf="line.currency !== defaultCurrency">
                                    {{
                                        line.sellingPrice !== null
                                            ? "= " + formatPrice(line.sellingPrice, defaultCurrency)
                                            : ""
                                    }}
                                </div>

                                <div *ngIf="line.isNationalFlight && line.sellingPrice">
                                    <img
                                        [src]="rootUrl + 'assets/img/icon-warning-orange.svg'"
                                        width="24"
                                        alt="Attention"
                                        rel="tooltip"
                                        data-bs-title="Attention: les prix sont avec TVA car c'est un vol national"
                                    />
                                </div>
                            </td>
                            <td class="text-end">
                                <div>
                                    {{
                                        line.marginInCurrency !== null
                                            ? formatPrice(line.marginInCurrency, line.currency)
                                            : ""
                                    }}
                                </div>
                                <div *ngIf="line.currency !== defaultCurrency">
                                    {{
                                        line.margin !== null
                                            ? "= " + formatPrice(line.margin, defaultCurrency)
                                            : ""
                                    }}
                                </div>

                                <em
                                    *ngIf="
                                        line.marginOnConfirmed &&
                                        line.marginOnConfirmed !== line.margin
                                    "
                                    [innerText]="
                                        'Marge à la confirmation : ' +
                                        formatPrice(line.marginOnConfirmed, defaultCurrency)
                                    "
                                ></em>
                            </td>
                            <td class="text-end">
                                <div>
                                    {{
                                        line.marginPercentInCurrency !== null
                                            ? line.marginPercentInCurrency
                                            : ""
                                    }}
                                </div>
                                <div *ngIf="line.currency !== defaultCurrency">
                                    {{
                                        line.marginPercent !== null
                                            ? "= " + line.marginPercent + "%"
                                            : ""
                                    }}
                                </div>
                            </td>
                            <td class="text-end" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                                <div>
                                    {{
                                        line.sellingPricePPInCurrency !== null
                                            ? formatPrice(
                                                  line.sellingPricePPInCurrency,
                                                  line.currency
                                              )
                                            : ""
                                    }}
                                </div>
                                <div *ngIf="line.currency !== defaultCurrency">
                                    {{
                                        line.sellingPricePP !== null
                                            ? "= " +
                                              formatPrice(line.sellingPricePP, defaultCurrency)
                                            : ""
                                    }}
                                </div>
                            </td>
                            <td [innerHtml]="line.notes"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            class="tab-pane fade"
            id="documents-overview-tab-pane"
            role="tabpanel"
            aria-labelledby="documents-overview-tab"
            tabindex="0"
        >
            <div
                class="alert alert-warning text-center"
                *ngIf="
                    !quotations.length &&
                    !proformas.length &&
                    !invoices.length &&
                    !creditNotes.length &&
                    !flightBriefs.length
                "
            >
                Aucun document à afficher
            </div>
            <div class="card mt-4" *ngIf="quotations.length">
                <div class="card-header">Devis</div>
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Nom</th>
                                <th scope="col">Status</th>
                                <th scope="col">Langue</th>
                                <th scope="col">Date de création</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let quotation of quotations"
                                [routerLink]="'/admin/quotations/view/' + quotation.id"
                                class="clickable"
                            >
                                <td [innerHtml]="quotation.title"></td>
                                <td [innerHtml]="quotation.statusLabel"></td>
                                <td [innerHtml]="getLanguageLabel(quotation.language)"></td>
                                <td [innerHtml]="quotation.created | date : 'dd/MM/yyyy'"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card mt-4" *ngIf="proformas.length">
                <div class="card-header">Proformas</div>
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Facture</th>
                                <th scope="col">Date de facture</th>
                                <th scope="col">Date d'échéance</th>
                                <th scope="col">Retard</th>
                                <th scope="col">Libellé</th>
                                <th scope="col">Envoyé ?</th>
                                <th scope="col">Montant HT</th>
                                <th scope="col">Montant TVA</th>
                                <th scope="col">Montant TTC</th>
                                <th scope="col">Date du réglement</th>
                                <th scope="col">Compte bancaire</th>
                                <th scope="col">Montant reglé</th>
                                <th scope="col">Montant restant</th>
                                <th scope="col">Notes internes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let invoice of proformas"
                                [routerLink]="'/admin/invoices/view/' + invoice.id"
                                class="clickable"
                                [class.table-success]="
                                    invoiceDiffDueDate(invoice) <= -15 && !invoice.isFullyPayed
                                "
                                [class.table-warning]="
                                    invoiceDiffDueDate(invoice) >= -5 &&
                                    invoiceDiffDueDate(invoice) < -1 &&
                                    !invoice.isFullyPayed
                                "
                                [class.table-danger]="
                                    invoiceDiffDueDate(invoice) >= -1 && !invoice.isFullyPayed
                                "
                            >
                                <td [innerText]="getInvoiceTitle(invoice)"></td>
                                <td [innerText]="invoice.issueDate | date : 'dd/MM/yyyy'"></td>
                                <td [innerText]="invoice.dueDate | date : 'dd/MM/yyyy'"></td>
                                <td>
                                    <span
                                        class="badge bg-danger text-light"
                                        [innerText]="invoiceDiffDueDate(invoice) + ' jours'"
                                        *ngIf="
                                            invoiceDiffDueDate(invoice) > 0 && !invoice.isFullyPayed
                                        "
                                    ></span>
                                </td>
                                <td
                                    [innerText]="invoice.invoiceObject ? invoice.invoiceObject : ''"
                                ></td>
                                <td [innerText]="invoice.sentToClient ? 'Oui' : 'Non'"></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountHtTotal, invoice.currency)
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountTvaTotal, invoice.currency)
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountTtcTotal, invoice.currency)
                                    "
                                ></td>
                                <td [innerText]="invoice.soldedDate ? invoice.soldedDate : ''"></td>
                                <td [innerText]="bankAccountsObj[invoice.bankAccount]?.name"></td>
                                <td
                                    [innerText]="
                                        formatPrice(
                                            invoiceGetAmountSoldePaid(invoice),
                                            invoice.currency
                                        )
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(
                                            invoiceGetAmountSoldeLeft(invoice),
                                            invoice.currency
                                        )
                                    "
                                ></td>
                                <td
                                    [innerText]="invoice.internalNote ? invoice.internalNote : ''"
                                ></td>
                                <!-- 
                <td>
                    {{
                        invoice.requestDate
                            ? invoice.requestDate
                            : (invoice.created | date: "dd/MM/yyyy HH:mm")
                    }}
                </td>
                <td>
                    <span
                        [innerHtml]="
                            usersObj[invoice.requestedBy].lastname +
                            ' ' +
                            usersObj[invoice.requestedBy].firstname
                        "
                        *ngIf="usersObj[invoice.requestedBy]"
                    ></span>
                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card mt-4" *ngIf="invoices.length">
                <div class="card-header">Factures</div>
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Facture</th>
                                <th scope="col">Type</th>
                                <th scope="col">Date de facture</th>
                                <th scope="col">Date d'échéance</th>
                                <th scope="col">Retard</th>
                                <th scope="col">Libellé</th>
                                <th scope="col">Envoyé ?</th>
                                <th scope="col">Montant HT</th>
                                <th scope="col">Montant TVA</th>
                                <th scope="col">Montant TTC</th>
                                <th scope="col">Date du réglement</th>
                                <th scope="col">Compte bancaire</th>
                                <th scope="col">Montant reglé</th>
                                <th scope="col">Montant restant</th>
                                <th scope="col">Notes internes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let invoice of invoices"
                                [routerLink]="'/admin/invoices/view/' + invoice.id"
                                class="clickable"
                                [class.table-success]="
                                    invoiceDiffDueDate(invoice) <= -15 && !invoice.isFullyPayed
                                "
                                [class.table-warning]="
                                    invoiceDiffDueDate(invoice) >= -5 &&
                                    invoiceDiffDueDate(invoice) < -1 &&
                                    !invoice.isFullyPayed
                                "
                                [class.table-danger]="
                                    invoiceDiffDueDate(invoice) >= -1 && !invoice.isFullyPayed
                                "
                            >
                                <td [innerText]="getInvoiceTitle(invoice)"></td>
                                <td [innerText]="getEnumInvoiceTypeLabel(invoice.invoiceType)"></td>
                                <td [innerText]="invoice.issueDate | date : 'dd/MM/yyyy'"></td>
                                <td [innerText]="invoice.dueDate | date : 'dd/MM/yyyy'"></td>
                                <td>
                                    <span
                                        class="badge bg-danger text-light"
                                        [innerText]="invoiceDiffDueDate(invoice) + ' jours'"
                                        *ngIf="
                                            invoiceDiffDueDate(invoice) > 0 && !invoice.isFullyPayed
                                        "
                                    ></span>
                                </td>
                                <td
                                    [innerText]="invoice.invoiceObject ? invoice.invoiceObject : ''"
                                ></td>
                                <td [innerText]="invoice.sentToClient ? 'Oui' : 'Non'"></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountHtTotal, invoice.currency)
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountTvaTotal, invoice.currency)
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountTtcTotal, invoice.currency)
                                    "
                                ></td>
                                <td [innerText]="invoice.soldedDate ? invoice.soldedDate : ''"></td>
                                <td [innerText]="bankAccountsObj[invoice.bankAccount]?.name"></td>
                                <td
                                    [innerText]="
                                        formatPrice(
                                            invoiceGetAmountSoldePaid(invoice),
                                            invoice.currency
                                        )
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(
                                            invoiceGetAmountSoldeLeft(invoice),
                                            invoice.currency
                                        )
                                    "
                                ></td>
                                <td
                                    [innerText]="invoice.internalNote ? invoice.internalNote : ''"
                                ></td>
                                <!-- 
                <td>
                    {{
                        invoice.requestDate
                            ? invoice.requestDate
                            : (invoice.created | date: "dd/MM/yyyy HH:mm")
                    }}
                </td>
                <td>
                    <span
                        [innerHtml]="
                            usersObj[invoice.requestedBy].lastname +
                            ' ' +
                            usersObj[invoice.requestedBy].firstname
                        "
                        *ngIf="usersObj[invoice.requestedBy]"
                    ></span>
                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card mt-4" *ngIf="creditNotes.length">
                <div class="card-header">Avoirs</div>
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Facture</th>
                                <th scope="col">Date de facture</th>
                                <th scope="col">Date d'échéance</th>
                                <th scope="col">Retard</th>
                                <th scope="col">Libellé</th>
                                <th scope="col">Envoyé ?</th>
                                <th scope="col">Montant HT</th>
                                <th scope="col">Montant TVA</th>
                                <th scope="col">Montant TTC</th>
                                <th scope="col">Date du réglement</th>
                                <th scope="col">Compte bancaire</th>
                                <th scope="col">Montant reglé</th>
                                <th scope="col">Montant restant</th>
                                <th scope="col">Notes internes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let invoice of creditNotes"
                                [routerLink]="'/admin/invoices/view/' + invoice.id"
                                class="clickable"
                                [class.table-success]="
                                    invoiceDiffDueDate(invoice) <= -15 && !invoice.isFullyPayed
                                "
                                [class.table-warning]="
                                    invoiceDiffDueDate(invoice) >= -5 &&
                                    invoiceDiffDueDate(invoice) < -1 &&
                                    !invoice.isFullyPayed
                                "
                                [class.table-danger]="
                                    invoiceDiffDueDate(invoice) >= -1 && !invoice.isFullyPayed
                                "
                            >
                                <td [innerText]="getInvoiceTitle(invoice)"></td>
                                <td [innerText]="invoice.issueDate | date : 'dd/MM/yyyy'"></td>
                                <td [innerText]="invoice.dueDate | date : 'dd/MM/yyyy'"></td>
                                <td>
                                    <span
                                        class="badge bg-danger text-light"
                                        [innerText]="invoiceDiffDueDate(invoice) + ' jours'"
                                        *ngIf="
                                            invoiceDiffDueDate(invoice) > 0 && !invoice.isFullyPayed
                                        "
                                    ></span>
                                </td>
                                <td
                                    [innerText]="invoice.invoiceObject ? invoice.invoiceObject : ''"
                                ></td>
                                <td [innerText]="invoice.sentToClient ? 'Oui' : 'Non'"></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountHtTotal, invoice.currency)
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountTvaTotal, invoice.currency)
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountTtcTotal, invoice.currency)
                                    "
                                ></td>
                                <td [innerText]="invoice.soldedDate ? invoice.soldedDate : ''"></td>
                                <td [innerText]="bankAccountsObj[invoice.bankAccount]?.name"></td>
                                <td
                                    [innerText]="
                                        formatPrice(
                                            invoiceGetAmountSoldePaid(invoice),
                                            invoice.currency
                                        )
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(
                                            invoiceGetAmountSoldeLeft(invoice),
                                            invoice.currency
                                        )
                                    "
                                ></td>
                                <td
                                    [innerText]="invoice.internalNote ? invoice.internalNote : ''"
                                ></td>
                                <!-- 
            <td>
                {{
                    invoice.requestDate
                        ? invoice.requestDate
                        : (invoice.created | date: "dd/MM/yyyy HH:mm")
                }}
            </td>
            <td>
                <span
                    [innerHtml]="
                        usersObj[invoice.requestedBy].lastname +
                        ' ' +
                        usersObj[invoice.requestedBy].firstname
                    "
                    *ngIf="usersObj[invoice.requestedBy]"
                ></span>
            </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card mt-4" *ngIf="flightBriefs.length">
                <div class="card-header">Flight briefs</div>
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Version</th>
                                <th scope="col">Langue</th>
                                <th scope="col">Date de création</th>
                                <th scope="col">Créé par ?</th>
                                <th scope="col">Date d'envoi</th>
                                <th scope="col">Si envoyé, par qui ?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let flightBrief of flightBriefs"
                                [routerLink]="'/admin/flight-briefs/view/' + flightBrief.id"
                                class="clickable"
                            >
                                <td [innerHtml]="flightBrief.title"></td>
                                <td [innerHtml]="getLanguageLabel(flightBrief.language)"></td>
                                <td [innerHtml]="flightBrief.created | date : 'dd/MM/yyyy'"></td>
                                <td [innerHtml]="flightBrief.createdByFullname"></td>
                                <ng-container *ngIf="!flightBrief.isSent">
                                    <td>À envoyer</td>
                                    <td>-</td>
                                </ng-container>
                                <ng-container *ngIf="flightBrief.isSent">
                                    <td
                                        [innerHtml]="flightBrief.sentDate | date : 'dd/MM/yyyy'"
                                    ></td>
                                    <td [innerHtml]="flightBrief.sentByFullname"></td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div
            class="tab-pane fade"
            id="quotations-tab-pane"
            role="tabpanel"
            aria-labelledby="quotations-tab"
            tabindex="0"
        >
            <div class="alert alert-warning text-center" *ngIf="!quotations.length">
                Aucun devis à afficher
            </div>
            <div class="table-responsive" *ngIf="quotations.length">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Nom</th>
                            <th scope="col">Status</th>
                            <th scope="col">Langue</th>
                            <th scope="col">Date de création</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let quotation of quotations"
                            [routerLink]="'/admin/quotations/view/' + quotation.id"
                            class="clickable"
                        >
                            <td [innerHtml]="quotation.title"></td>
                            <td [innerHtml]="quotation.statusLabel"></td>
                            <td [innerHtml]="getLanguageLabel(quotation.language)"></td>
                            <td [innerHtml]="quotation.created | date : 'dd/MM/yyyy'"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            class="tab-pane fade"
            id="proformas-tab-pane"
            role="tabpanel"
            aria-labelledby="proformas-tab"
            tabindex="0"
        >
            <div class="alert alert-warning text-center" *ngIf="!proformas.length">
                Aucune proforma à afficher
            </div>
            <div class="table-responsive" *ngIf="proformas.length">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Facture</th>
                            <th scope="col">Date de facture</th>
                            <th scope="col">Date d'échéance</th>
                            <th scope="col">Retard</th>
                            <th scope="col">Libellé</th>
                            <th scope="col">Envoyé ?</th>
                            <th scope="col">Montant HT</th>
                            <th scope="col">Montant TVA</th>
                            <th scope="col">Montant TTC</th>
                            <th scope="col">Date du réglement</th>
                            <th scope="col">Compte bancaire</th>
                            <th scope="col">Montant reglé</th>
                            <th scope="col">Montant restant</th>
                            <th scope="col">Notes internes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let invoice of proformas"
                            [routerLink]="'/admin/invoices/view/' + invoice.id"
                            class="clickable"
                            [class.table-success]="
                                invoiceDiffDueDate(invoice) <= -15 && !invoice.isFullyPayed
                            "
                            [class.table-warning]="
                                invoiceDiffDueDate(invoice) >= -5 &&
                                invoiceDiffDueDate(invoice) < -1 &&
                                !invoice.isFullyPayed
                            "
                            [class.table-danger]="
                                invoiceDiffDueDate(invoice) >= -1 && !invoice.isFullyPayed
                            "
                        >
                            <td [innerText]="getInvoiceTitle(invoice)"></td>
                            <td [innerText]="invoice.issueDate | date : 'dd/MM/yyyy'"></td>
                            <td [innerText]="invoice.dueDate | date : 'dd/MM/yyyy'"></td>
                            <td>
                                <span
                                    class="badge bg-danger text-light"
                                    [innerText]="invoiceDiffDueDate(invoice) + ' jours'"
                                    *ngIf="invoiceDiffDueDate(invoice) > 0 && !invoice.isFullyPayed"
                                ></span>
                            </td>
                            <td
                                [innerText]="invoice.invoiceObject ? invoice.invoiceObject : ''"
                            ></td>
                            <td [innerText]="invoice.sentToClient ? 'Oui' : 'Non'"></td>
                            <td
                                [innerText]="formatPrice(invoice.amountHtTotal, invoice.currency)"
                            ></td>
                            <td
                                [innerText]="formatPrice(invoice.amountTvaTotal, invoice.currency)"
                            ></td>
                            <td
                                [innerText]="formatPrice(invoice.amountTtcTotal, invoice.currency)"
                            ></td>
                            <td [innerText]="invoice.soldedDate ? invoice.soldedDate : ''"></td>
                            <td [innerText]="bankAccountsObj[invoice.bankAccount]?.name"></td>
                            <td
                                [innerText]="
                                    formatPrice(
                                        invoiceGetAmountSoldePaid(invoice),
                                        invoice.currency
                                    )
                                "
                            ></td>
                            <td
                                [innerText]="
                                    formatPrice(
                                        invoiceGetAmountSoldeLeft(invoice),
                                        invoice.currency
                                    )
                                "
                            ></td>
                            <td [innerText]="invoice.internalNote ? invoice.internalNote : ''"></td>
                            <!-- 
                <td>
                    {{
                        invoice.requestDate
                            ? invoice.requestDate
                            : (invoice.created | date: "dd/MM/yyyy HH:mm")
                    }}
                </td>
                <td>
                    <span
                        [innerHtml]="
                            usersObj[invoice.requestedBy].lastname +
                            ' ' +
                            usersObj[invoice.requestedBy].firstname
                        "
                        *ngIf="usersObj[invoice.requestedBy]"
                    ></span>
                </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            class="tab-pane fade"
            id="invoices-tab-pane"
            role="tabpanel"
            aria-labelledby="invoices-tab"
            tabindex="0"
        >
            <div class="alert alert-warning text-center" *ngIf="!invoices.length">
                Aucune facture à afficher
            </div>
            <div class="table-responsive" *ngIf="invoices.length">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Facture</th>
                            <th scope="col">Date de facture</th>
                            <th scope="col">Date d'échéance</th>
                            <th scope="col">Retard</th>
                            <th scope="col">Libellé</th>
                            <th scope="col">Envoyé ?</th>
                            <th scope="col">Montant HT</th>
                            <th scope="col">Montant TVA</th>
                            <th scope="col">Montant TTC</th>
                            <th scope="col">Date du réglement</th>
                            <th scope="col">Compte bancaire</th>
                            <th scope="col">Montant reglé</th>
                            <th scope="col">Montant restant</th>
                            <th scope="col">Notes internes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let invoice of invoices"
                            [routerLink]="'/admin/invoices/view/' + invoice.id"
                            class="clickable"
                            [class.table-success]="
                                invoiceDiffDueDate(invoice) <= -15 && !invoice.isFullyPayed
                            "
                            [class.table-warning]="
                                invoiceDiffDueDate(invoice) >= -5 &&
                                invoiceDiffDueDate(invoice) < -1 &&
                                !invoice.isFullyPayed
                            "
                            [class.table-danger]="
                                invoiceDiffDueDate(invoice) >= -1 && !invoice.isFullyPayed
                            "
                        >
                            <td [innerText]="getInvoiceTitle(invoice)"></td>
                            <td [innerText]="invoice.issueDate | date : 'dd/MM/yyyy'"></td>
                            <td [innerText]="invoice.dueDate | date : 'dd/MM/yyyy'"></td>
                            <td>
                                <span
                                    class="badge bg-danger text-light"
                                    [innerText]="invoiceDiffDueDate(invoice) + ' jours'"
                                    *ngIf="invoiceDiffDueDate(invoice) > 0 && !invoice.isFullyPayed"
                                ></span>
                            </td>
                            <td
                                [innerText]="invoice.invoiceObject ? invoice.invoiceObject : ''"
                            ></td>
                            <td [innerText]="invoice.sentToClient ? 'Oui' : 'Non'"></td>
                            <td
                                [innerText]="formatPrice(invoice.amountHtTotal, invoice.currency)"
                            ></td>
                            <td
                                [innerText]="formatPrice(invoice.amountTvaTotal, invoice.currency)"
                            ></td>
                            <td
                                [innerText]="formatPrice(invoice.amountTtcTotal, invoice.currency)"
                            ></td>
                            <td [innerText]="invoice.soldedDate ? invoice.soldedDate : ''"></td>
                            <td [innerText]="bankAccountsObj[invoice.bankAccount]?.name"></td>
                            <td
                                [innerText]="
                                    formatPrice(
                                        invoiceGetAmountSoldePaid(invoice),
                                        invoice.currency
                                    )
                                "
                            ></td>
                            <td
                                [innerText]="
                                    formatPrice(
                                        invoiceGetAmountSoldeLeft(invoice),
                                        invoice.currency
                                    )
                                "
                            ></td>
                            <td [innerText]="invoice.internalNote ? invoice.internalNote : ''"></td>
                            <!-- 
                <td>
                    {{
                        invoice.requestDate
                            ? invoice.requestDate
                            : (invoice.created | date: "dd/MM/yyyy HH:mm")
                    }}
                </td>
                <td>
                    <span
                        [innerHtml]="
                            usersObj[invoice.requestedBy].lastname +
                            ' ' +
                            usersObj[invoice.requestedBy].firstname
                        "
                        *ngIf="usersObj[invoice.requestedBy]"
                    ></span>
                </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            class="tab-pane fade"
            id="credit-notes-tab-pane"
            role="tabpanel"
            aria-labelledby="credit-notes-tab"
            tabindex="0"
        >
            <div class="alert alert-warning text-center" *ngIf="!creditNotes.length">
                Aucun avoir à afficher
            </div>
            <div class="table-responsive" *ngIf="creditNotes.length">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Facture</th>
                            <th scope="col">Date de facture</th>
                            <th scope="col">Date d'échéance</th>
                            <th scope="col">Retard</th>
                            <th scope="col">Libellé</th>
                            <th scope="col">Envoyé ?</th>
                            <th scope="col">Montant HT</th>
                            <th scope="col">Montant TVA</th>
                            <th scope="col">Montant TTC</th>
                            <th scope="col">Date du réglement</th>
                            <th scope="col">Compte bancaire</th>
                            <th scope="col">Montant reglé</th>
                            <th scope="col">Montant restant</th>
                            <th scope="col">Notes internes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let invoice of creditNotes"
                            [routerLink]="'/admin/invoices/view/' + invoice.id"
                            class="clickable"
                            [class.table-success]="
                                invoiceDiffDueDate(invoice) <= -15 && !invoice.isFullyPayed
                            "
                            [class.table-warning]="
                                invoiceDiffDueDate(invoice) >= -5 &&
                                invoiceDiffDueDate(invoice) < -1 &&
                                !invoice.isFullyPayed
                            "
                            [class.table-danger]="
                                invoiceDiffDueDate(invoice) >= -1 && !invoice.isFullyPayed
                            "
                        >
                            <td [innerText]="getInvoiceTitle(invoice)"></td>
                            <td [innerText]="invoice.issueDate | date : 'dd/MM/yyyy'"></td>
                            <td [innerText]="invoice.dueDate | date : 'dd/MM/yyyy'"></td>
                            <td>
                                <span
                                    class="badge bg-danger text-light"
                                    [innerText]="invoiceDiffDueDate(invoice) + ' jours'"
                                    *ngIf="invoiceDiffDueDate(invoice) > 0 && !invoice.isFullyPayed"
                                ></span>
                            </td>
                            <td
                                [innerText]="invoice.invoiceObject ? invoice.invoiceObject : ''"
                            ></td>
                            <td [innerText]="invoice.sentToClient ? 'Oui' : 'Non'"></td>
                            <td
                                [innerText]="formatPrice(invoice.amountHtTotal, invoice.currency)"
                            ></td>
                            <td
                                [innerText]="formatPrice(invoice.amountTvaTotal, invoice.currency)"
                            ></td>
                            <td
                                [innerText]="formatPrice(invoice.amountTtcTotal, invoice.currency)"
                            ></td>
                            <td [innerText]="invoice.soldedDate ? invoice.soldedDate : ''"></td>
                            <td [innerText]="bankAccountsObj[invoice.bankAccount]?.name"></td>
                            <td
                                [innerText]="
                                    formatPrice(
                                        invoiceGetAmountSoldePaid(invoice),
                                        invoice.currency
                                    )
                                "
                            ></td>
                            <td
                                [innerText]="
                                    formatPrice(
                                        invoiceGetAmountSoldeLeft(invoice),
                                        invoice.currency
                                    )
                                "
                            ></td>
                            <td [innerText]="invoice.internalNote ? invoice.internalNote : ''"></td>
                            <!-- 
            <td>
                {{
                    invoice.requestDate
                        ? invoice.requestDate
                        : (invoice.created | date: "dd/MM/yyyy HH:mm")
                }}
            </td>
            <td>
                <span
                    [innerHtml]="
                        usersObj[invoice.requestedBy].lastname +
                        ' ' +
                        usersObj[invoice.requestedBy].firstname
                    "
                    *ngIf="usersObj[invoice.requestedBy]"
                ></span>
            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" #modalCancelled>
        <form
            (submit)="saveStatusCancelled()"
            [formGroup]="formStatusCancelled"
            (keydown.enter)="saveStatusCancelled()"
            class="main-form"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Dossier annulé</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="reason">Raison d'annulation</label>
                            <select
                                class="form-select"
                                id="reason"
                                formControlName="reason"
                                required
                                [class.is-invalid]="
                                    (formStatusCancelled.touched ||
                                        formStatusCancelled.controls['reason'].touched) &&
                                    formStatusCancelled.controls['reason'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            >
                                <option value="">Sélectionner une raison</option>
                                <option
                                    [value]="reason"
                                    *ngFor="let reason of getCancelledReasons()"
                                    [innerHtml]="getEnumEnquiryCancelledReasonLabel(reason)"
                                ></option>
                            </select>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (formStatusCancelled.touched ||
                                            formStatusCancelled.controls['reason'].touched) &&
                                        formStatusCancelled.controls['reason'].errors &&
                                        formStatusCancelled.controls['reason'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="comment">Commentaire</label>
                            <textarea
                                formControlName="comment"
                                class="form-control"
                                id="comment"
                                [class.is-invalid]="
                                    (formStatusCancelled.touched ||
                                        formStatusCancelled.controls['comment'].touched) &&
                                    formStatusCancelled.controls['comment'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            ></textarea>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (formStatusCancelled.touched ||
                                            formStatusCancelled.controls['comment'].touched) &&
                                        formStatusCancelled.controls['comment'].errors &&
                                        formStatusCancelled.controls['comment'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Annuler
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            (click)="saveStatusCancelled()"
                            [disabled]="formStatusCancelled.disabled || !formStatusCancelled.valid"
                        >
                            Sauvegarder
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="modal" tabindex="-1" role="dialog" #modalCotationEdit>
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <form
                    [formGroup]="form"
                    class="main-form"
                    (keydown.enter)="saveCotation()"
                    *ngIf="form"
                >
                    <div class="modal-header">
                        <h5 class="modal-title">Édition de la cotation</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6 col-lg-3">
                                <div class="form-group">
                                    <label>Compagnie</label>
                                    <div class="form-control fake-input disabled">
                                        <a
                                            [routerLink]="
                                                '/admin/airlines/view/' +
                                                editingOffer.aircraftCompiled.airlineId
                                            "
                                            target="_blank"
                                            class="fake-input-text"
                                            [innerHtml]="
                                                editingOffer && editingOffer.aircraftCompiled
                                                    ? editingOffer.aircraftCompiled.airlineTitle
                                                    : ''
                                            "
                                        ></a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <div class="form-group">
                                    <label>Nationalité</label>
                                    <div class="form-control fake-input disabled">
                                        <span
                                            class="fake-input-text"
                                            [innerHtml]="
                                                editingOffer && editingOffer.aircraftCompiled
                                                    ? getCountryLabel(
                                                          editingOffer.aircraftCompiled
                                                              .airlineCountryCode
                                                      )
                                                    : ''
                                            "
                                        ></span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-lg-6">
                                <div class="form-group">
                                    <label>Appareil</label>

                                    <select
                                        class="form-select"
                                        id="aircraftCompiledId"
                                        formControlName="aircraftCompiledId"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form.get('aircraftCompiledId').touched) &&
                                            form.get('aircraftCompiledId').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    >
                                        <option
                                            [value]="aircraftCompiled.id"
                                            *ngFor="
                                                let aircraftCompiled of airlinesAircrafts[
                                                    editingOffer.aircraftCompiled.airlineId
                                                ]
                                            "
                                            [innerHtml]="
                                                aircraftCompiled.isCargo
                                                    ? aircraftCompiled.modelTitle.toUpperCase() +
                                                      ' / ' +
                                                      aircraftCompiled.weight +
                                                      ' kg' +
                                                      ' / ' +
                                                      aircraftCompiled.volume +
                                                      ' m3'
                                                    : aircraftCompiled.modelTitle.toUpperCase() +
                                                      ' / ' +
                                                      aircraftCompiled.seatTotal +
                                                      ' PAX'
                                            "
                                        ></option>
                                    </select>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.get('aircraftCompiledId').touched) &&
                                                form.get('aircraftCompiledId').errors &&
                                                form.get('aircraftCompiledId').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <div
                                    class="form-group"
                                    *ngIf="enquiry && enquiry.itineraries?.length > 1"
                                >
                                    <label for="itineraryId">Leg</label>
                                    <select
                                        class="form-select"
                                        id="itineraryId"
                                        formControlName="itineraryId"
                                        [class.is-invalid]="
                                            (form.touched || form.get('itineraryId').touched) &&
                                            form.get('itineraryId').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                        (change)="updateItineraryIdOfCotation()"
                                    >
                                        <option value=""></option>
                                        <option
                                            [value]="itinerary.value"
                                            *ngFor="let itinerary of itinerariesList"
                                            [innerHtml]="itinerary.title"
                                        ></option>
                                    </select>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched || form.get('itineraryId').touched) &&
                                                form.get('itineraryId').errors &&
                                                form.get('itineraryId').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="form-group"
                                    *ngIf="
                                        form.get('itineraryId').value &&
                                        legsList[form.get('itineraryId').value].length
                                    "
                                >
                                    <label *ngIf="enquiry && enquiry.itineraries?.length === 1"
                                        >Leg</label
                                    >

                                    <div
                                        class="form-check"
                                        *ngFor="let leg of legsList[form.get('itineraryId').value]"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            [attr.id]="leg.value"
                                            [checked]="
                                                form.get('legsId').value.indexOf(leg.value) !== -1
                                            "
                                            (click)="updateLegsId(leg.value)"
                                        />
                                        <label
                                            class="form-check-label"
                                            [attr.for]="leg.value"
                                            [innerHtml]="leg.title"
                                        ></label>
                                    </div>

                                    <div
                                        class="text-danger"
                                        *ngIf="
                                            (form.touched || form.get('legsId').touched) &&
                                            form.get('legsId').errors &&
                                            form.get('legsId').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                                <div class="form-group">
                                    <label for="nbPax">Nb pax</label>
                                    <input
                                        class="form-control"
                                        id="nbPax"
                                        formControlName="nbPax"
                                        min="0"
                                        type="number"
                                        required
                                        [class.is-invalid]="
                                            (form.touched || form.get('nbPax').touched) &&
                                            form.get('nbPax').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched || form.get('nbPax').touched) &&
                                                form.get('nbPax').errors &&
                                                form.get('nbPax').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="currency">Devise</label>
                                    <select
                                        class="form-select"
                                        id="currency"
                                        formControlName="currency"
                                        required
                                        [class.is-invalid]="
                                            (form.touched || form.controls['currency'].touched) &&
                                            form.controls['currency'].status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                        (change)="updatedCurrency()"
                                    >
                                        <option
                                            [value]="currency"
                                            *ngFor="let currency of getCurrencies()"
                                            [innerHtml]="
                                                getEnumCurrencySymbol(currency) +
                                                ' (' +
                                                getEnumCurrencyLabel(currency) +
                                                ')'
                                            "
                                        ></option>
                                    </select>
                                    <div
                                        class="text-muted"
                                        *ngIf="
                                            form.value.currency &&
                                            form.value.currency !== getDefaultCurrency()
                                        "
                                    >
                                        Aujourd'hui
                                        {{ todayCurrencyExchangeRate.date | date : "HH:mm" }}
                                        :<br />
                                        1 {{ getDefaultCurrency() }} =
                                        {{
                                            todayCurrencyExchangeRate.currencies[
                                                form.value.currency
                                            ]
                                        }}
                                        {{ form.value.currency }}
                                    </div>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.controls['currency'].touched) &&
                                                form.controls['currency'].errors &&
                                                form.controls['currency'].errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <fieldset
                                    [class.has-different-currency]="
                                        form.value.currency !== defaultCurrency
                                    "
                                >
                                    <legend>Prix d'achat</legend>
                                    <div
                                        class="form-group"
                                        *ngFor="let fieldType of ['InCurrency', '']"
                                        [class.d-none]="
                                            fieldType === '' &&
                                            form.value.currency === defaultCurrency
                                        "
                                    >
                                        <div class="input-group">
                                            <input
                                                class="form-control"
                                                [id]="'buyingPrice' + fieldType"
                                                [formControlName]="'buyingPrice' + fieldType"
                                                type="number"
                                                required
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        form.get('buyingPrice' + fieldType)
                                                            .touched) &&
                                                    form.get('buyingPrice' + fieldType).status ==
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                                (keyup)="
                                                    updatedValue(
                                                        'buyingPrice',
                                                        fieldType === 'InCurrency'
                                                    )
                                                "
                                                [placeholder]="
                                                    'Montant en ' +
                                                    getEnumCurrencySymbol(defaultCurrency)
                                                "
                                            />
                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            form.get('buyingPrice' + fieldType)
                                                                .touched) &&
                                                        form.get('buyingPrice' + fieldType)
                                                            .errors &&
                                                        form.get('buyingPrice' + fieldType).errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>

                                            <div class="input-group-append">
                                                <div
                                                    class="input-group-text"
                                                    [innerHtml]="
                                                        getEnumCurrencySymbol(
                                                            fieldType === 'InCurrency'
                                                                ? form.value.currency
                                                                : defaultCurrency
                                                        )
                                                    "
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>

                            <div class="col-sm-6" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                                <div class="form-group">
                                    <label for="cateringByPersonIncluded"
                                        >Catering par passager inclus dans prix d'achat ?</label
                                    >
                                    <select
                                        class="form-select"
                                        id="cateringByPersonIncluded"
                                        formControlName="cateringByPersonIncluded"
                                        required
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form.get('cateringByPersonIncluded').touched) &&
                                            form.get('cateringByPersonIncluded').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                        (change)="updateByPersonIncludedField('cateringByPerson')"
                                    >
                                        <option [ngValue]="true">Oui</option>
                                        <option [ngValue]="false">Non</option>
                                    </select>
                                </div>

                                <fieldset
                                    *ngIf="!form.get('cateringByPersonIncluded').value"
                                    [class.has-different-currency]="
                                        form.value.currency !== defaultCurrency
                                    "
                                >
                                    <legend>Catering par passager</legend>

                                    <div
                                        class="form-group"
                                        *ngFor="let fieldType of ['InCurrency', '']"
                                        [class.d-none]="
                                            fieldType === '' &&
                                            form.value.currency === defaultCurrency
                                        "
                                    >
                                        <div class="input-group">
                                            <input
                                                class="form-control"
                                                [id]="'cateringByPerson' + fieldType"
                                                [formControlName]="'cateringByPerson' + fieldType"
                                                type="number"
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        form.get('cateringByPerson' + fieldType)
                                                            .touched) &&
                                                    form.get('cateringByPerson' + fieldType)
                                                        .status == 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                                (keyup)="
                                                    updatedValue(
                                                        'cateringByPerson',
                                                        fieldType === 'InCurrency'
                                                    )
                                                "
                                                [placeholder]="
                                                    'Montant en ' +
                                                    getEnumCurrencySymbol(defaultCurrency)
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            form.get('cateringByPerson' + fieldType)
                                                                .touched) &&
                                                        form.get('cateringByPerson' + fieldType)
                                                            .errors &&
                                                        form.get('cateringByPerson' + fieldType)
                                                            .errors['isZeroOrBelow']
                                                    "
                                                >
                                                    Ce champ ne peut être inférieur à zéro.
                                                </div>
                                            </div>

                                            <div class="input-group-append">
                                                <div
                                                    class="input-group-text"
                                                    [innerHtml]="
                                                        getEnumCurrencySymbol(
                                                            fieldType === 'InCurrency'
                                                                ? form.value.currency
                                                                : defaultCurrency
                                                        )
                                                    "
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>

                            <div class="col-sm-6" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                                <div class="form-group">
                                    <label for="taxesByPersonIncluded"
                                        >Taxes par passager inclus dans prix d'achat ?</label
                                    >
                                    <select
                                        class="form-select"
                                        id="taxesByPersonIncluded"
                                        formControlName="taxesByPersonIncluded"
                                        required
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form.get('taxesByPersonIncluded').touched) &&
                                            form.get('taxesByPersonIncluded').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                        (change)="updateByPersonIncludedField('taxesByPerson')"
                                    >
                                        <option [ngValue]="true">Oui</option>
                                        <option [ngValue]="false">Non</option>
                                    </select>
                                </div>

                                <ng-container
                                    *ngIf="
                                        !form.value.taxesByPersonIncluded &&
                                        form.value.legsId.length
                                    "
                                >
                                    <ul class="nav nav-pills" id="myTab" role="tablist">
                                        <li class="nav-item">
                                            <a
                                                class="nav-link"
                                                [class.active]="
                                                    form.value.isTaxesByPersonType === 'leg'
                                                "
                                                id="taxesByPersonByLeg-tab"
                                                data-bs-toggle="tab"
                                                href="#taxesByPersonByLeg"
                                                role="tab"
                                                aria-controls="taxesByPersonByLeg"
                                                (click)="updateTaxesByPersonType('leg')"
                                                >Taxes par passager par leg</a
                                            >
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                class="nav-link"
                                                [class.active]="
                                                    form.value.isTaxesByPersonType === 'airport'
                                                "
                                                id="taxesByPersonByAirport-tab"
                                                data-bs-toggle="tab"
                                                href="#taxesByPersonByAirport"
                                                role="tab"
                                                aria-controls="taxesByPersonByAirport"
                                                (click)="updateTaxesByPersonType('airport')"
                                                >Taxes par passager par aéroport</a
                                            >
                                        </li>
                                    </ul>

                                    <!-- Tab panes -->
                                    <div class="tab-content">
                                        <div
                                            class="tab-pane active"
                                            [class.active]="
                                                form.value.isTaxesByPersonType === 'leg'
                                            "
                                            id="taxesByPersonByLeg"
                                            role="tabpanel"
                                            aria-labelledby="taxesByPersonByLeg-tab"
                                        >
                                            <ul
                                                class="list-group"
                                                formArrayName="taxesByPersonByLeg"
                                            >
                                                <li
                                                    class="list-group-item"
                                                    [formGroupName]="i"
                                                    *ngFor="
                                                        let taxByPersonByLeg of form.get(
                                                            'taxesByPersonByLeg'
                                                        )['controls'];
                                                        let i = index
                                                    "
                                                >
                                                    <div
                                                        class="form-group"
                                                        *ngFor="let fieldType of ['InCurrency', '']"
                                                        [class.d-none]="
                                                            fieldType === '' &&
                                                            form.value.currency === defaultCurrency
                                                        "
                                                    >
                                                        <label
                                                            [for]="
                                                                'taxesByPersonByLeg' + i + fieldType
                                                            "
                                                            *ngIf="fieldType === 'InCurrency'"
                                                            [innerHtml]="
                                                                legsObj[
                                                                    form.value.taxesByPersonByLeg[i]
                                                                        .legId
                                                                ].title
                                                            "
                                                        ></label>
                                                        <div class="input-group">
                                                            <input
                                                                class="form-control"
                                                                [id]="
                                                                    'taxesByPersonByLeg' +
                                                                    i +
                                                                    'amount' +
                                                                    fieldType
                                                                "
                                                                [formControlName]="
                                                                    'amount' + fieldType
                                                                "
                                                                type="number"
                                                                [class.is-invalid]="
                                                                    (form.touched ||
                                                                        form
                                                                            .get(
                                                                                'taxesByPersonByLeg'
                                                                            )
                                                                            .get(i.toString())
                                                                            .get(
                                                                                'amount' + fieldType
                                                                            ).touched) &&
                                                                    form
                                                                        .get('taxesByPersonByLeg')
                                                                        .get(i.toString())
                                                                        .get('amount' + fieldType)
                                                                        .status == 'INVALID'
                                                                        ? 'danger'
                                                                        : ''
                                                                "
                                                                (keyup)="
                                                                    updatedTaxesByPersonByLeg(
                                                                        i,
                                                                        fieldType === 'InCurrency'
                                                                    )
                                                                "
                                                                [placeholder]="
                                                                    fieldType === ''
                                                                        ? 'Montant en ' +
                                                                          getEnumCurrencySymbol(
                                                                              defaultCurrency
                                                                          )
                                                                        : ''
                                                                "
                                                            />

                                                            <div class="invalid-tooltip">
                                                                <div
                                                                    *ngIf="
                                                                        (form.touched ||
                                                                            form
                                                                                .get(
                                                                                    'taxesByPersonByLeg'
                                                                                )
                                                                                .get(i.toString())
                                                                                .get(
                                                                                    'amount' +
                                                                                        fieldType
                                                                                ).touched) &&
                                                                        form
                                                                            .get(
                                                                                'taxesByPersonByLeg'
                                                                            )
                                                                            .get(i.toString())
                                                                            .get(
                                                                                'amount' + fieldType
                                                                            ).errors &&
                                                                        form
                                                                            .get(
                                                                                'taxesByPersonByLeg'
                                                                            )
                                                                            .get(i.toString())
                                                                            .get(
                                                                                'amount' + fieldType
                                                                            ).errors['required']
                                                                    "
                                                                >
                                                                    Ce champ est obligatoire.
                                                                </div>
                                                            </div>

                                                            <div class="input-group-append">
                                                                <div
                                                                    class="input-group-text"
                                                                    [innerHtml]="
                                                                        getEnumCurrencySymbol(
                                                                            fieldType ===
                                                                                'InCurrency'
                                                                                ? form.value
                                                                                      .currency
                                                                                : defaultCurrency
                                                                        )
                                                                    "
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="nbPax">Nb pax</label>
                                                        <input
                                                            class="form-control"
                                                            id="nbPax"
                                                            formControlName="nbPax"
                                                            min="0"
                                                            type="number"
                                                            required
                                                            (keyup)="updatedTaxesByPersonByLeg(i)"
                                                            [class.is-invalid]="
                                                                (form.touched ||
                                                                    form
                                                                        .get('taxesByPersonByLeg')
                                                                        .get(i.toString())
                                                                        .get('nbPax').touched) &&
                                                                form
                                                                    .get('taxesByPersonByLeg')
                                                                    .get(i.toString())
                                                                    .get('nbPax').status ==
                                                                    'INVALID'
                                                                    ? 'danger'
                                                                    : ''
                                                            "
                                                        />

                                                        <div class="invalid-tooltip">
                                                            <div
                                                                *ngIf="
                                                                    (form.touched ||
                                                                        form
                                                                            .get(
                                                                                'taxesByPersonByLeg'
                                                                            )
                                                                            .get(i.toString())
                                                                            .get('nbPax')
                                                                            .touched) &&
                                                                    form
                                                                        .get('taxesByPersonByLeg')
                                                                        .get(i.toString())
                                                                        .get('nbPax').errors &&
                                                                    form
                                                                        .get('taxesByPersonByLeg')
                                                                        .get(i.toString())
                                                                        .get('nbPax').errors[
                                                                        'required'
                                                                    ]
                                                                "
                                                            >
                                                                Ce champ est obligatoire.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div
                                            class="tab-pane"
                                            [class.active]="
                                                form.value.isTaxesByPersonType === 'airport'
                                            "
                                            id="taxesByPersonByAirport"
                                            role="tabpanel"
                                            aria-labelledby="taxesByPersonByAirport-tab"
                                        >
                                            <ul
                                                class="list-group"
                                                formArrayName="taxesByPersonByAirport"
                                            >
                                                <li
                                                    class="list-group-item"
                                                    [formGroupName]="i"
                                                    *ngFor="
                                                        let taxByPersonByLeg of form.get(
                                                            'taxesByPersonByAirport'
                                                        )['controls'];
                                                        let i = index
                                                    "
                                                >
                                                    <div
                                                        class="form-group"
                                                        *ngFor="let fieldType of ['InCurrency', '']"
                                                        [class.d-none]="
                                                            fieldType === '' &&
                                                            form.value.currency === defaultCurrency
                                                        "
                                                    >
                                                        <label
                                                            [for]="
                                                                'taxesByPersonByAirport' +
                                                                i +
                                                                fieldType
                                                            "
                                                            *ngIf="fieldType === 'InCurrency'"
                                                            [innerHtml]="
                                                                form.value.taxesByPersonByAirport[i]
                                                                    .airport
                                                            "
                                                        ></label>
                                                        <div class="input-group">
                                                            <input
                                                                class="form-control"
                                                                [id]="
                                                                    'taxesByPersonByAirport' +
                                                                    i +
                                                                    'amount' +
                                                                    fieldType
                                                                "
                                                                [formControlName]="
                                                                    'amount' + fieldType
                                                                "
                                                                type="number"
                                                                [class.is-invalid]="
                                                                    (form.touched ||
                                                                        form
                                                                            .get(
                                                                                'taxesByPersonByAirport'
                                                                            )
                                                                            .get(i.toString())
                                                                            .get(
                                                                                'amount' + fieldType
                                                                            ).touched) &&
                                                                    form
                                                                        .get(
                                                                            'taxesByPersonByAirport'
                                                                        )
                                                                        .get(i.toString())
                                                                        .get('amount' + fieldType)
                                                                        .status == 'INVALID'
                                                                        ? 'danger'
                                                                        : ''
                                                                "
                                                                (keyup)="
                                                                    updatedTaxesByPersonByAirport(
                                                                        i,
                                                                        fieldType === 'InCurrency'
                                                                    )
                                                                "
                                                                [placeholder]="
                                                                    fieldType === ''
                                                                        ? 'Montant en ' +
                                                                          getEnumCurrencySymbol(
                                                                              defaultCurrency
                                                                          )
                                                                        : ''
                                                                "
                                                            />

                                                            <div class="invalid-tooltip">
                                                                <div
                                                                    *ngIf="
                                                                        (form.touched ||
                                                                            form
                                                                                .get(
                                                                                    'taxesByPersonByAirport'
                                                                                )
                                                                                .get(i.toString())
                                                                                .get(
                                                                                    'amount' +
                                                                                        fieldType
                                                                                ).touched) &&
                                                                        form
                                                                            .get(
                                                                                'taxesByPersonByAirport'
                                                                            )
                                                                            .get(i.toString())
                                                                            .get(
                                                                                'amount' + fieldType
                                                                            ).errors &&
                                                                        form
                                                                            .get(
                                                                                'taxesByPersonByAirport'
                                                                            )
                                                                            .get(i.toString())
                                                                            .get(
                                                                                'amount' + fieldType
                                                                            ).errors['required']
                                                                    "
                                                                >
                                                                    Ce champ est obligatoire.
                                                                </div>
                                                            </div>

                                                            <div class="input-group-append">
                                                                <div
                                                                    class="input-group-text"
                                                                    [innerHtml]="
                                                                        getEnumCurrencySymbol(
                                                                            fieldType ===
                                                                                'InCurrency'
                                                                                ? form.value
                                                                                      .currency
                                                                                : defaultCurrency
                                                                        )
                                                                    "
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="nbPax">Nb pax</label>
                                                        <input
                                                            class="form-control"
                                                            id="nbPax"
                                                            formControlName="nbPax"
                                                            min="0"
                                                            type="number"
                                                            required
                                                            (keyup)="
                                                                updatedTaxesByPersonByAirport(i)
                                                            "
                                                            [class.is-invalid]="
                                                                (form.touched ||
                                                                    form
                                                                        .get(
                                                                            'taxesByPersonByAirport'
                                                                        )
                                                                        .get(i.toString())
                                                                        .get('nbPax').touched) &&
                                                                form
                                                                    .get('taxesByPersonByAirport')
                                                                    .get(i.toString())
                                                                    .get('nbPax').status ==
                                                                    'INVALID'
                                                                    ? 'danger'
                                                                    : ''
                                                            "
                                                        />

                                                        <div class="invalid-tooltip">
                                                            <div
                                                                *ngIf="
                                                                    (form.touched ||
                                                                        form
                                                                            .get(
                                                                                'taxesByPersonByAirport'
                                                                            )
                                                                            .get(i.toString())
                                                                            .get('nbPax')
                                                                            .touched) &&
                                                                    form
                                                                        .get(
                                                                            'taxesByPersonByAirport'
                                                                        )
                                                                        .get(i.toString())
                                                                        .get('nbPax').errors &&
                                                                    form
                                                                        .get(
                                                                            'taxesByPersonByAirport'
                                                                        )
                                                                        .get(i.toString())
                                                                        .get('nbPax').errors[
                                                                        'required'
                                                                    ]
                                                                "
                                                            >
                                                                Ce champ est obligatoire.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </ng-container>

                                <div
                                    class="form-group"
                                    *ngFor="let fieldType of ['InCurrency', '']"
                                    [class.d-none]="
                                        fieldType === '' && form.value.currency === defaultCurrency
                                    "
                                >
                                    <label
                                        [for]="'taxesTotalPax' + fieldType"
                                        *ngIf="fieldType === 'InCurrency'"
                                        >Taxes total passagers</label
                                    >
                                    <div class="input-group">
                                        <input
                                            class="form-control"
                                            [id]="'taxesTotalPax' + fieldType"
                                            [formControlName]="'taxesTotalPax' + fieldType"
                                            type="number"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('taxesTotalPax' + fieldType)
                                                        .touched) &&
                                                form.get('taxesTotalPax' + fieldType).status ==
                                                    'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                            (keyup)="
                                                updatedValue(
                                                    'taxesTotalPax',
                                                    fieldType === 'InCurrency'
                                                )
                                            "
                                            [placeholder]="
                                                'Montant en ' +
                                                getEnumCurrencySymbol(defaultCurrency)
                                            "
                                            readonly
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('taxesTotalPax' + fieldType)
                                                            .touched) &&
                                                    form.get('taxesTotalPax' + fieldType).errors &&
                                                    form.get('taxesTotalPax' + fieldType).errors[
                                                        'isZeroOrBelow'
                                                    ]
                                                "
                                            >
                                                Ce champ ne peut être inférieur à zéro.
                                            </div>
                                        </div>

                                        <div class="input-group-append">
                                            <div
                                                class="input-group-text"
                                                [innerHtml]="
                                                    getEnumCurrencySymbol(
                                                        fieldType === 'InCurrency'
                                                            ? form.value.currency
                                                            : defaultCurrency
                                                    )
                                                "
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12">
                                <fieldset
                                    [class.has-different-currency]="
                                        form.value.currency !== defaultCurrency
                                    "
                                >
                                    <legend>Prix de vente TC et CC</legend>
                                    <div
                                        class="form-group"
                                        *ngFor="let fieldType of ['InCurrency', '']"
                                        [class.d-none]="
                                            fieldType === '' &&
                                            form.value.currency === defaultCurrency
                                        "
                                    >
                                        <div class="input-group">
                                            <input
                                                class="form-control"
                                                [id]="'sellingPrice' + fieldType"
                                                [formControlName]="'sellingPrice' + fieldType"
                                                type="number"
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        form.get('sellingPrice' + fieldType)
                                                            .touched) &&
                                                    form.get('sellingPrice' + fieldType).status ==
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                                (keyup)="
                                                    updatedValue(
                                                        'sellingPrice',
                                                        fieldType === 'InCurrency'
                                                    )
                                                "
                                                [placeholder]="
                                                    fieldType === ''
                                                        ? 'Montant en ' +
                                                          getEnumCurrencySymbol(defaultCurrency)
                                                        : 'Laissez vide si inconnu'
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            form.get('sellingPrice' + fieldType)
                                                                .touched) &&
                                                        form.get('sellingPrice' + fieldType)
                                                            .errors &&
                                                        form.get('sellingPrice' + fieldType).errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>

                                            <div class="input-group-append">
                                                <div
                                                    class="input-group-text"
                                                    [innerHtml]="
                                                        getEnumCurrencySymbol(
                                                            fieldType === 'InCurrency'
                                                                ? form.value.currency
                                                                : defaultCurrency
                                                        )
                                                    "
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="notes">Notes</label>
                            <textarea
                                formControlName="notes"
                                class="form-control"
                                id="notes"
                                rows="5"
                                [class.is-invalid]="
                                    (form.touched || form.get('notes').touched) &&
                                    form.get('notes').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            ></textarea>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || form.get('notes').touched) &&
                                        form.get('notes').errors &&
                                        form.get('notes').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Annuler
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            (click)="saveCotation()"
                            [disabled]="form.disabled || !form.valid"
                        >
                            Sauvegarder
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" #modalConfirmed>
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Dossier confirmé</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <p class="alert alert-information text-center mb-2">
                        Sélectionnez la cotation confirmée ci-dessous.
                    </p>

                    <div class="table-responsive" *ngIf="lines.length">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        class="sortable text-center"
                                        *ngFor="let field of fields"
                                        [class.is-sorted]="orderBy.field == field.code"
                                        [class.asc]="
                                            orderBy.field == field.code &&
                                            orderBy.direction == 'asc'
                                        "
                                        [class.desc]="
                                            orderBy.field == field.code &&
                                            orderBy.direction == 'desc'
                                        "
                                        (click)="sortField(field.code)"
                                        [innerHtml]="field.title"
                                    ></th>
                                    <th class="text-center">Confirmé ?</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let line of lines"
                                    [class.table-success]="
                                        line.status === EnumEnquiryCotationStatus.confirmed
                                    "
                                >
                                    <td>
                                        <span
                                            class="badge"
                                            [class.bg-primary]="
                                                line.status ===
                                                EnumEnquiryCotationStatus.waitingForAnswer
                                            "
                                            [class.bg-success]="
                                                ['answered', 'confirmed'].indexOf(line.status) !==
                                                -1
                                            "
                                            [class.bg-danger]="
                                                line.status === EnumEnquiryCotationStatus.rejected
                                            "
                                            [class.bg-warning]="
                                                line.status === EnumEnquiryCotationStatus.toContact
                                            "
                                            [innerHtml]="
                                                getEnumEnquiryCotationStatusLabel(line.status)
                                            "
                                        ></span>
                                    </td>
                                    <td [innerHtml]="line.airlineTitle"></td>
                                    <td [innerHtml]="line.airlineCountryTitle"></td>
                                    <td>
                                        <span [innerHtml]="line.legsTitle"></span>

                                        <div
                                            *ngIf="
                                                line.legsId &&
                                                legsList[line.itineraryId] &&
                                                line.legsId.length &&
                                                line.legsId.length <
                                                    legsList[line.itineraryId].length
                                            "
                                        >
                                            <img
                                                [src]="
                                                    rootUrl + 'assets/img/icon-warning-orange.svg'
                                                "
                                                width="24"
                                                alt="Attention"
                                                rel="tooltip"
                                                [title]="
                                                    'Tous les legs ne sont pas présent sur cette cotation (' +
                                                    line.legsId.length +
                                                    '/' +
                                                    legsList[line.itineraryId].length +
                                                    ')'
                                                "
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        {{ line.aircraftTitle.toUpperCase() }}

                                        <div *ngIf="line.aircraftErrorMessages.length">
                                            <img
                                                [src]="
                                                    rootUrl + 'assets/img/icon-warning-orange.svg'
                                                "
                                                width="24"
                                                alt="Attention"
                                                rel="tooltip"
                                                [title]="line.aircraftErrorMessages.join('<hr>')"
                                            />
                                        </div>
                                    </td>
                                    <td
                                        class="text-center"
                                        [innerHtml]="
                                            line.ageAverage ? line.ageAverage + ' ans' : 'Inconnu'
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerHtml]="line.capacityAircraft"
                                        *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerHtml]="line.weightAircraft + ' kg'"
                                        *ngIf="enquiry.type === EnumEnquiryType.cargo"
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerHtml]="line.volumeAircraft + ' m<sup>3</sup>'"
                                        *ngIf="enquiry.type === EnumEnquiryType.cargo"
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerHtml]="line.nbPax"
                                        *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                                    ></td>
                                    <td class="text-end">
                                        <div>
                                            {{
                                                line.buyingPriceInCurrency !== null
                                                    ? formatPrice(
                                                          line.buyingPriceInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.buyingPrice !== null
                                                    ? "= " +
                                                      formatPrice(line.buyingPrice, defaultCurrency)
                                                    : ""
                                            }}
                                        </div>

                                        <div *ngIf="line.isNationalFlight && line.buyingPrice">
                                            <img
                                                [src]="
                                                    rootUrl + 'assets/img/icon-warning-orange.svg'
                                                "
                                                width="24"
                                                alt="Attention"
                                                rel="tooltip"
                                                data-bs-title="Attention: les prix sont avec TVA car c'est un vol national"
                                            />
                                        </div>
                                    </td>
                                    <td
                                        class="text-end"
                                        *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                                    >
                                        <div>
                                            {{
                                                line.taxesTotalPaxInCurrency !== null
                                                    ? formatPrice(
                                                          line.taxesTotalPaxInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.taxesTotalPax !== null
                                                    ? "= " +
                                                      formatPrice(
                                                          line.taxesTotalPax,
                                                          defaultCurrency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td
                                        class="text-end"
                                        *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                                    >
                                        <div>
                                            {{
                                                line.cateringInCurrency !== null
                                                    ? formatPrice(
                                                          line.cateringInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.catering !== null
                                                    ? "= " +
                                                      formatPrice(line.catering, defaultCurrency)
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td class="text-end">
                                        <div>
                                            {{
                                                line.priceNetTTCInCurrency !== null
                                                    ? formatPrice(
                                                          line.priceNetTTCInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.priceNetTTC !== null
                                                    ? "= " +
                                                      formatPrice(line.priceNetTTC, defaultCurrency)
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td
                                        class="text-end"
                                        *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                                    >
                                        <div>
                                            {{
                                                line.pricePPInCurrency !== null
                                                    ? formatPrice(
                                                          line.pricePPInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.pricePP !== null
                                                    ? "= " +
                                                      formatPrice(line.pricePP, defaultCurrency)
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td class="text-end">
                                        <div>
                                            {{
                                                line.sellingPriceInCurrency !== null
                                                    ? formatPrice(
                                                          line.sellingPriceInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.sellingPrice !== null
                                                    ? "= " +
                                                      formatPrice(
                                                          line.sellingPrice,
                                                          defaultCurrency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td class="text-end">
                                        <div>
                                            {{
                                                line.marginInCurrency !== null
                                                    ? formatPrice(
                                                          line.marginInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.margin !== null
                                                    ? "= " +
                                                      formatPrice(line.margin, defaultCurrency)
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td class="text-end">
                                        <div>
                                            {{
                                                line.marginPercentInCurrency !== null
                                                    ? line.marginPercentInCurrency
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.marginPercent !== null
                                                    ? "= " + line.marginPercent + "%"
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td
                                        class="text-end"
                                        *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                                    >
                                        <div>
                                            {{
                                                line.sellingPricePPInCurrency !== null
                                                    ? formatPrice(
                                                          line.sellingPricePPInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.sellingPricePP !== null
                                                    ? "= " +
                                                      formatPrice(
                                                          line.sellingPricePP,
                                                          defaultCurrency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td [innerHtml]="line.notes"></td>
                                    <td>
                                        <div class="d-flex justify-content-center" *ngIf="isLogged">
                                            <span
                                                *ngIf="!line.cotationId"
                                                rel="tooltip"
                                                data-bs-title="Cette cotation n'a pas été remplie"
                                                data-bs-placement="left"
                                                ><img
                                                    [src]="
                                                        rootUrl + 'assets/img/icon-check-dark.svg'
                                                    "
                                                    width="16"
                                                    alt="Sélectionner"
                                            /></span>

                                            <span
                                                *ngIf="line.cotationId && !line.sellingPrice"
                                                rel="tooltip"
                                                data-bs-title="Cette cotation n'a pas de prix de vente"
                                                data-bs-placement="left"
                                                ><img
                                                    [src]="
                                                        rootUrl + 'assets/img/icon-check-dark.svg'
                                                    "
                                                    width="16"
                                                    alt="Sélectionner"
                                            /></span>

                                            <div
                                                class="form-check"
                                                *ngIf="line.cotationId && line.sellingPrice"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    [checked]="
                                                        line.status ===
                                                            EnumEnquiryCotationStatus.confirmed &&
                                                        selectedConfirmedCotationsId.includes(
                                                            line.cotationId
                                                        )
                                                    "
                                                    (click)="
                                                        toggleConfirmedCotation(line.cotationId)
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th [attr.colspan]="fields.length"></th>
                                    <th class="text-center">
                                        <button
                                            type="button"
                                            class="btn btn-success"
                                            (click)="saveConfirmedCotations()"
                                            [disabled]="!selectedConfirmedCotationsId.length"
                                        >
                                            <img
                                                [src]="rootUrl + 'assets/img/icon-check-white.svg'"
                                                width="16"
                                                alt="Sauvegarder"
                                            />
                                            Sauvegarder
                                        </button>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade"
        id="enquiry-detail"
        tabindex="-1"
        aria-labelledby="enquiryDetailLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="enquiryDetailLabel">Détails de la demande</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body" *ngIf="enquiry">
                    <app-enquiry-card [enquiry]="enquiry"></app-enquiry-card>
                </div>
            </div>
        </div>
    </div>
</div>
