import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { getArrivalPassengerTotal } from 'src/app/interfaces/enquiry-trip.interface';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { IPipedriveOrganization, IPipedrivePerson } from 'src/app/interfaces/pipedrive.interface';
import { AclService } from 'src/app/services/acl.service';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { PipedriveService } from 'src/app/services/pipedrive.service';
import { RemoteService } from 'src/app/services/remote.service';

@Component({
  selector: 'app-enquiry-card',
  templateUrl: './enquiry-card.component.html',
  styleUrls: ['./enquiry-card.component.scss']
})
export class EnquiryCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input('enquiry') enquiry: IEnquiry;

  faPlane = faPlane;

  airportsObj: { [key: string]: IAirport | null } = {};
  client: IPipedriveOrganization | null = null;
  contact: IPipedrivePerson | null = null;
  isLogged: boolean = false;
  creatingPipedriveDeal: boolean = false;

  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;
  getArrivalPassengerTotal = getArrivalPassengerTotal;

  EnumEnquiryType = EnumEnquiryType;
  EnumAcl = EnumAcl;

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private pipedriveService: PipedriveService,
    private enquiryService: EnquiryService,
    private aclService: AclService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.remoteService.isLoggedObservable.subscribe(
        (isLogged: boolean) => (this.isLogged = isLogged)
      )
    );

    this.loadAirportsOfEnquiry();
    this.loadClient();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadAirportsOfEnquiry();
    this.loadClient();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async loadAirportsOfEnquiry(): Promise<void> {
    const airportsToLoad: string[] = [];

    if (this.enquiry?.itineraries?.length) {
      for (const itinerary of this.enquiry.itineraries) {
        for (const trip of itinerary.trips) {
          for (const field of [
            'airportDepart',
            'airportDestination',
            'commercialStopOverAirport',
            'fuelStopOverAirport'
          ]) {
            if (
              trip[field] &&
              typeof this.airportsObj[trip[field]] === 'undefined' &&
              !airportsToLoad.includes(trip[field])
            ) {
              airportsToLoad.push(trip[field]);
            }
          }
        }
      }
    }

    if (airportsToLoad.length) {
      await this.loadAirports(airportsToLoad);
    }
  }

  async loadAirports(airportsId: string[]): Promise<void> {
    if (airportsId.length) {
      const docs: object[] = await this.remoteService.getDocumentsFromDocId('airports', airportsId);

      for (const doc of docs) {
        const airport = doc as IAirport;

        this.airportsObj[airport.id] = airport;
      }
    }
  }

  async loadClient(): Promise<void> {
    if (this.enquiry?.clientId) {
      this.client = await this.pipedriveService.getOrganization(
        typeof this.enquiry.clientId === 'number'
          ? this.enquiry.clientId.toString()
          : this.enquiry.clientId
      );
    }

    if (this.enquiry?.contactId) {
      this.contact = await this.pipedriveService.getPerson(this.enquiry.contactId);
    }
  }

  async createDealInPipedrive(): Promise<void> {
    if (this.enquiry) {
      this.creatingPipedriveDeal = true;

      const pipedriveDealId: number = await this.pipedriveService.createDealFromEnquiry(
        this.enquiry
      );

      await this.enquiryService.update({
        id: this.enquiry.id,
        pipedriveDealId: pipedriveDealId
      } as IEnquiry);

      this.creatingPipedriveDeal = false;
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async delete(): Promise<void> {
    if (this.enquiry && this.hasAclAccess(EnumAcl.enquiriesDelete)) {
      const result: boolean = confirm(
        'La suppression de cette demande sera permanente. Êtes-vous sûr de vouloir continuer ?'
      );

      if (result) {
        try {
          await this.enquiryService.delete(this.enquiry.id);

          alert('La suppression a été effectuée avec succès.');

          this.router.navigate(['/admin']);
        } catch (err) {
          console.log(err);
          alert(err.message);
        }
      }
    }
  }
}
