import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { IFinancialReportStaff } from 'src/app/interfaces/financial-report-staff.interface';

@Injectable({
  providedIn: 'root'
})
export class FinancialReportStaffsFirestoreService extends FirestoreService<IFinancialReportStaff> {
  protected basePath = 'financialReportsStaffs';
}
