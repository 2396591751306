import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';

@Component({
  selector: 'app-list-clients',
  templateUrl: './list-clients.component.html',
  styleUrls: ['./list-clients.component.scss']
})
export class ListClientsComponent implements OnInit {
  currentPagination: string = 'pipedriveOrganizations-list';

  constructor(
    public paginationService: PaginationService,
    private router: Router,
    private aclService: AclService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.clientsList);

    this.fetchData();
  }

  async fetchData(): Promise<void> {
    await this.paginationService.fetchData(this.currentPagination);
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string;
    }>;
  }): void {
    for (let field of $event.fields) {
      if (field.name === 'clientId') {
        this.router.navigate(['/admin/clients/view/' + field.value]);
      }
    }
  }
}
