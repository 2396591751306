import { Injectable } from '@angular/core';
import { IFlightChecklistTemplate } from 'src/app/interfaces/flight-checklist-template.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class FlightChecklistTemplateFirestoreService extends FirestoreService<IFlightChecklistTemplate> {
  protected basePath = 'flightChecklistTemplates';
}
