import { Component, OnDestroy, OnInit } from '@angular/core';
import { faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons';
import { addMonths, addYears, endOfYear, format, getYear, isAfter, startOfMonth } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { getDefaultCurrency } from 'src/app/enums/currency.enum';
import { IEmployeeBonusRule } from 'src/app/interfaces/employee-bonus-rule.interface';
import { IEnquiryPaymentSummary } from 'src/app/interfaces/enquiry-payment-summary.interface';
import { formatPrice } from 'src/app/misc.utils';
import { AclService } from 'src/app/services/acl.service';
import { EmployeeBonusRulesService } from 'src/app/services/employee-bonus-rules/employee-bonus-rules.service';
import { EnquiryPaymentSummaryService } from 'src/app/services/enquiry-payment-summaries/enquiry-payment-summaries.service';

interface IBonusForUser {
  ruleId: string;
  userId: string;
  userFullName?: string;
  userInitials?: string;
  enquiriesIds: string[];
}

@Component({
  selector: 'app-employee-bonus-dashboard',
  templateUrl: './employee-bonus-dashboard.component.html',
  styleUrls: ['./employee-bonus-dashboard.component.scss']
})
export class EmployeeBonusDashboardComponent implements OnInit, OnDestroy {
  faMoneyBillTrendUp = faMoneyBillTrendUp;

  formatPrice = formatPrice;
  getDefaultCurrency = getDefaultCurrency;

  EnumAcl = EnumAcl;

  filtersByPeriod: {
    title: string;
    value: string;
  }[] = [];
  filterByPeriod: string = format(new Date(), 'yyyy-MM') + '-01';

  loadingRules: boolean = false;
  loadingEnquiryPaymentSummaries: boolean = false;

  rules: IEmployeeBonusRule[] = [];
  enquiryPaymentSummaries: IEnquiryPaymentSummary[] = [];
  bonusByRule: { [ruleId: string]: IBonusForUser[] } = {};

  subscriptions = new Subscription();

  constructor(
    private aclService: AclService,
    private employeeBonusRulesService: EmployeeBonusRulesService,
    private enquiryPaymentSummaryService: EnquiryPaymentSummaryService
  ) {
    this.generateFilterPeriodOptions();
  }

  ngOnInit(): void {
    this.refreshData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  generateFilterPeriodOptions(): void {
    let currentYear: number = getYear(new Date());

    const maxDec: number = currentYear - 2021;
    const beginningOfMonth: Date = startOfMonth(new Date());
    const startDate: Date = endOfYear(addYears(beginningOfMonth, -maxDec - 1));

    for (var m = beginningOfMonth; isAfter(m, startDate); m = addMonths(m, -1)) {
      let title: string = format(m, 'MMMM yy', {
        locale: fr
      });
      title = title.charAt(0).toUpperCase() + title.slice(1);
      this.filtersByPeriod.push({
        title: title,
        value: format(m, 'yyyy-MM-01')
      });
    }
  }

  refreshData(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }

    this.subscriptions = new Subscription();

    this.loadAllBonusRules();
    this.loadEnquiryPaymentSummaries();
  }

  loadAllBonusRules(): void {
    this.loadingRules = true;

    this.subscriptions.add(
      this.employeeBonusRulesService
        .getAllInMonth(new Date(this.filterByPeriod + ' 00:00:00'))
        .subscribe((rules: IEmployeeBonusRule[]) => {
          this.rules = rules;

          this.rules.sort((a, b) => (a.amountBonus < b.amountBonus ? -1 : 1));

          this.refreshBonusByRules();

          this.loadingRules = false;
        })
    );
  }

  loadEnquiryPaymentSummaries(): void {
    this.loadingEnquiryPaymentSummaries = true;

    const splittedFilterByPeriod: string[] = this.filterByPeriod.split('-');

    let promise;

    switch (splittedFilterByPeriod[0]) {
      case 'quarter':
        promise = () =>
          this.enquiryPaymentSummaryService.getAllOfQuarter(
            parseInt(splittedFilterByPeriod[1]) - 2000,
            parseInt(splittedFilterByPeriod[2])
          );

        break;
      case 'year':
        promise = () =>
          this.enquiryPaymentSummaryService.getAllOfYear(
            parseInt(splittedFilterByPeriod[1]) - 2000
          );
        break;
      default:
        promise = () =>
          this.enquiryPaymentSummaryService.getAllOfMonth(
            parseInt(splittedFilterByPeriod[0]) - 2000,
            parseInt(splittedFilterByPeriod[1])
          );
        break;
    }

    if (promise) {
      this.subscriptions.add(
        promise().subscribe((enquiryPaymentSummaries: IEnquiryPaymentSummary[]) => {
          this.enquiryPaymentSummaries = enquiryPaymentSummaries;

          this.refreshBonusByRules();

          this.loadingEnquiryPaymentSummaries = false;
        })
      );
    }
  }

  refreshBonusByRules(): void {
    this.bonusByRule = {};

    const enquiriesIdAlreadyAdded: string[] = [];
    const enquiryPaymentSummariesByUser: { [userId: string]: IEnquiryPaymentSummary[] } = {};

    for (const enquiryPaymentSummary of this.enquiryPaymentSummaries) {
      if (!enquiriesIdAlreadyAdded.includes(enquiryPaymentSummary.enquiryId)) {
        if (
          typeof enquiryPaymentSummariesByUser[enquiryPaymentSummary.enquiryProcessedBy] ===
          'undefined'
        ) {
          enquiryPaymentSummariesByUser[enquiryPaymentSummary.enquiryProcessedBy] = [];
        }

        enquiryPaymentSummariesByUser[enquiryPaymentSummary.enquiryProcessedBy].push(
          enquiryPaymentSummary
        );

        enquiriesIdAlreadyAdded.push(enquiryPaymentSummary.enquiryId);
      }
    }

    for (const rule of this.rules) {
      this.bonusByRule[rule.id] = [];

      for (const userId in enquiryPaymentSummariesByUser) {
        const userBonusForRule: IBonusForUser = {
          ruleId: rule.id,
          userId,
          enquiriesIds: []
        };

        for (const enquiryPaymentSummary of enquiryPaymentSummariesByUser[userId]) {
          userBonusForRule.userFullName = enquiryPaymentSummary.userFullName;
          userBonusForRule.userInitials = enquiryPaymentSummary.userInitials;

          if (enquiryPaymentSummary.enquiryMargin >= rule.minimumMarginHT) {
            userBonusForRule.enquiriesIds.push(enquiryPaymentSummary.enquiryId);
          }
        }

        this.bonusByRule[rule.id].push(userBonusForRule);
      }
    }
  }

  getPercentage(value: number, total: number): number {
    return total !== 0 ? (value * 100) / total : 0;
  }
}
