import { Component, OnInit } from '@angular/core';
import { AclService } from '../../../services/acl.service';

import moment from 'moment';

import * as XLSX from 'xlsx';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { RemoteService } from 'src/app/services/remote.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlgoliaService, IAlgoliaFilter } from 'src/app/services/algolia.service';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import {
  EnumCurrency,
  getEnumCurrencyLabel,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  EnumSuggestionInvoiceReason,
  ISuggestedInvoice,
  getSuggestionInvoiceReasonLabel
} from 'src/app/enums/suggested-invoice.enum';
import { NgxCsvParser } from 'ngx-csv-parser';
import { IInvoice } from 'src/app/interfaces/invoice.interface';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';

interface Operation {
  dateOperation: string;
  dateEffective: string;
  title: string;
  debit: number;
  credit: number;
}

interface DataImport {
  ibanAccount: string;
  currency: string;
  dateStart: string;
  dateEnd: string;
  operations: Operation[];
}

@Component({
  selector: 'app-encaissement-import',
  templateUrl: './encaissement-import.component.html',
  styleUrls: ['./encaissement-import.component.scss']
})
export class EncaissementImportComponent implements OnInit {
  EnumCurrency = EnumCurrency;

  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getEnumCurrencyLabel = getEnumCurrencyLabel;
  getSuggestionInvoiceReasonLabel = getSuggestionInvoiceReasonLabel;

  fileContentLoaded = false;
  currentFile: File;
  dataValid = false;
  data: DataImport;

  form: FormGroup;
  sending: boolean = false;

  bankAccountsList: IBankAccount[] = [];
  encaissementTypesList: IEncaissementType[] = [];

  displayEncaissementForm = false;

  suggestionInvoices: ISuggestedInvoice[][] = [];

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private algoliaService: AlgoliaService,
    private ngxCsvParser: NgxCsvParser
  ) {
    this.form = this.formBuilder.group({
      encaissements: new FormArray([])
    });
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.encaissementsImport);

    this.loadBankAccounts();
    this.loadEncaissementTypes();
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.currentFile = fileInput.target.files[0];

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileContentLoaded = false;

        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        this.data = this.excelContentToJson(XLSX.utils.sheet_to_csv(ws));

        this.fileContentLoaded = true;
      };

      reader.readAsBinaryString(this.currentFile);
    }
  }

  addEncaissementFormGroup(): void {
    const encaissementsFormArray = this.form.get('encaissements') as FormArray;

    encaissementsFormArray.push(
      new FormGroup({
        id: new FormControl('', [Validators.required]),
        libelle: new FormControl('', [Validators.required]),
        encaissementTypeId: new FormControl('', [Validators.required]),
        isCredit: new FormControl(false),
        isDebit: new FormControl(false),
        amount: new FormControl(0, [Validators.required]),
        currency: new FormControl('EUR', [Validators.required]),
        bankAccountId: new FormControl('', [Validators.required]),
        invoiceId: new FormControl(''),
        date: new FormControl('', [Validators.required]),
        isReconciled: new FormControl(true, [Validators.required]),
        comment: new FormControl('')
      })
    );

    this.form.updateValueAndValidity();
  }

  async submitForm(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.status == 'VALID') {
      let data = Object.assign({}, this.form.value);

      data.isDebit = data.amount >= 0;
      data.isCredit = data.amount < 0;

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      try {
        for (const encaissement of this.form.value.encaissements) {
          await this.remoteService.setDocumentToCollection(
            'encaissements',
            encaissement.id,
            encaissement
          );
        }

        this.sending = false;
        this.redirectAfterSaving();
      } catch (err) {
        console.log(err);
        this.sending = false;
        this.form.enable();

        alert(err.message);
      }

      // if (this.encaissementId) {
      //   this.remoteService
      //     .updateDocumentToCollection('encaissements', this.encaissementId, data)
      //     .then(() => {
      //       this.sending = false;
      //       this.redirectAfterSaving();
      //     })
      //     .catch(err => {
      //       this.sending = false;
      //       this.form.enable();

      //       alert(err.message);
      //     });
      // } else {
      //   this.remoteService
      //     .addDocumentToCollection('encaissements', data)
      //     .then((docId: string) => {
      //       this.encaissementId = docId;

      //       this.sending = false;
      //       this.redirectAfterSaving();
      //     })
      //     .catch(err => {
      //       this.sending = false;
      //       this.form.enable();

      //       alert(err.message);
      //     });
      // }
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/encaissements']);
  }

  async loadBankAccounts(): Promise<void> {
    const docs = await this.remoteService.getAllDocuments('bankAccounts', {
      field: 'name',
      direction: 'asc'
    });

    this.bankAccountsList = [];
    for (const doc of docs) {
      this.bankAccountsList.push(doc as IBankAccount);
    }
  }

  async loadEncaissementTypes(): Promise<void> {
    const docs = await this.remoteService.getAllDocuments('encaissementTypes', {
      field: 'name',
      direction: 'asc'
    });

    this.encaissementTypesList = [];
    for (const doc of docs) {
      this.encaissementTypesList.push(doc as IEncaissementType);
    }
  }

  async setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string;
    }>;
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      formControl.setValue(field.value);

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  generateEncaissementsFromImportedData(): void {
    const encaissementsFormArray = this.form.get('encaissements') as FormArray;

    for (const operation of this.data.operations) {
      this.addEncaissementFormGroup();

      const currentEncaissementFormGroup: FormGroup = encaissementsFormArray.at(
        encaissementsFormArray.length - 1
      ) as FormGroup;

      currentEncaissementFormGroup.get('libelle').setValue(operation.title);
      currentEncaissementFormGroup.get('currency').setValue(this.data.currency);
      currentEncaissementFormGroup.get('isReconciled').setValue(true);
      currentEncaissementFormGroup.get('date').setValue(operation.dateEffective);

      if (operation.credit > 0) {
        currentEncaissementFormGroup.get('isCredit').setValue(true);
        currentEncaissementFormGroup.get('amount').setValue(operation.credit);
      } else if (operation.debit < 0) {
        currentEncaissementFormGroup.get('isDebit').setValue(true);
        currentEncaissementFormGroup.get('amount').setValue(operation.debit * -1);
      }

      const operationTypesToMatch: {
        sourceToMatch: string;
        encaissementTypeId: string;
      }[] = [
        {
          sourceToMatch: 'VIR',
          encaissementTypeId: 'Cm9kB9ZNaZbFnXo1Bx3Y'
        },
        {
          sourceToMatch: 'CHEQUE',
          encaissementTypeId: '3sMVVghuAnSNpM36QfCf'
        },
        {
          sourceToMatch: 'PRLV',
          encaissementTypeId: 'AZHyJ4DIvdcfwcXfLwZY'
        }
      ];

      for (const operationTypeToMatch of operationTypesToMatch) {
        if (operation.title.match(operationTypeToMatch.sourceToMatch)) {
          currentEncaissementFormGroup
            .get('encaissementTypeId')
            .setValue(operationTypeToMatch.encaissementTypeId);
        }
      }

      for (const bankAccount of this.bankAccountsList) {
        if (bankAccount.iban.split(' ').join('') === this.data.ibanAccount) {
          currentEncaissementFormGroup.get('bankAccountId').setValue(bankAccount.id);
          break;
        }
      }

      currentEncaissementFormGroup
        .get('id')
        .setValue(
          currentEncaissementFormGroup.value.bankAccountId +
            '_' +
            currentEncaissementFormGroup.value.encaissementTypeId +
            '_' +
            currentEncaissementFormGroup.value.date +
            '_' +
            currentEncaissementFormGroup.value.currency +
            '_' +
            currentEncaissementFormGroup.value.amount
        );

      this.suggestionInvoices.push([]);
    }

    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();

    for (let i = 0; i < (this.form.get('encaissements') as FormArray).length; i++) {
      this.getSuggestionInvoices(i);
    }

    this.displayEncaissementForm = true;
  }

  private excelContentToJson(data: string): DataImport {
    const dataParsed: any[][] = this.ngxCsvParser.csvStringToArray(data, ',');

    const result: DataImport = {
      ibanAccount: null,
      currency: null,
      dateStart: null,
      dateEnd: null,
      operations: []
    };

    for (let i = 0; i < dataParsed.length; i++) {
      switch (i) {
        case 0:
          result.ibanAccount = dataParsed[i][1].replace('n° ', '');
          break;
        case 1:
          result.currency = dataParsed[i][1];
          break;
        case 3:
          const dates: string[] = dataParsed[i][1].split(' au  ');

          result.dateStart = moment(dates[0], 'DD/MM/YYYY').format('YYYY-MM-DD');
          result.dateEnd = moment(dates[1], 'DD/MM/YYYY').format('YYYY-MM-DD');
          break;
        default:
          if (i >= 5 && dataParsed[i][0]) {
            result.operations.push({
              dateOperation: moment(dataParsed[i][0], 'D/M/YY').format('YYYY-MM-DD'),
              dateEffective: moment(dataParsed[i][1], 'D/M/YY').format('YYYY-MM-DD'),
              title: dataParsed[i][2],
              debit: dataParsed[i][3] !== '' ? parseFloat(dataParsed[i][3]) : 0,
              credit: dataParsed[i][4] !== '' ? parseFloat(dataParsed[i][4]) : 0
            });
          }
          break;
      }
    }

    return result;
  }

  selectSuggestedInvoice(i: number, suggestionInvoice: ISuggestedInvoice): void {
    const encaissementFormGroup = (this.form.get('encaissements') as FormArray).at(i);

    encaissementFormGroup.get('invoiceId').setValue(suggestionInvoice.invoice.id);
    encaissementFormGroup.get('invoiceId').updateValueAndValidity();

    this.suggestionInvoices[i] = [];
  }

  async getSuggestionInvoices(i: number): Promise<void> {
    this.suggestionInvoices[i] = [];

    if (!this.form.value.encaissements[i].invoiceId) {
      const invoiceRefs: string[] = this.extractInvoiceRefFromText(
        this.form.value.encaissements[i].libelle
      );

      if (invoiceRefs) {
        for (const invoiceRef of invoiceRefs) {
          const invoicesByRef: IInvoice[] = await this.searchInvoiceInAlgoliaByRef(invoiceRef);

          this.appendSuggestedInvoicesToList(
            i,
            invoicesByRef,
            EnumSuggestionInvoiceReason.matchingInvoiceRef
          );
        }
      }

      const invoicesByAmount: IInvoice[] = await this.searchInvoiceSameAmount(
        this.form.value.encaissements[i].amount,
        this.form.value.encaissements[i].currency
      );

      this.appendSuggestedInvoicesToList(
        i,
        invoicesByAmount,
        EnumSuggestionInvoiceReason.sameAmountTtcTotal
      );

      const enquiryRefs: string[] = this.extractEnquiryRefFromText(
        this.form.value.encaissements[i].libelle
      );

      if (enquiryRefs) {
        for (const enquiryRef of enquiryRefs) {
          const enquiries: IEnquiry[] = await this.searchEnquiryByRef(enquiryRef);

          if (enquiries.length) {
            for (const enquiry of enquiries) {
              const invoicesByEnquiry: IInvoice[] = await this.searchInvoiceByEnquiryId(enquiry.id);

              this.appendSuggestedInvoicesToList(
                i,
                invoicesByEnquiry,
                EnumSuggestionInvoiceReason.enquiryMatch
              );
            }
          }
        }
      }
    }

    this.sortSuggestionInvoicesByTotalReasons(i);
  }

  appendSuggestedInvoicesToList(
    encaissementIndex: number,
    invoices: IInvoice[],
    reason: EnumSuggestionInvoiceReason
  ): void {
    for (const invoice of invoices) {
      let existingIndex = -1;
      for (let i = 0; i < this.suggestionInvoices[encaissementIndex].length; i++) {
        if (this.suggestionInvoices[encaissementIndex][i].invoice.id === invoice.id) {
          existingIndex = i;
        }
      }

      if (existingIndex === -1) {
        this.suggestionInvoices[encaissementIndex].push({
          reasons: [reason],
          invoice: invoice
        });
      } else {
        if (
          this.suggestionInvoices[encaissementIndex][existingIndex].reasons.indexOf(reason) === -1
        ) {
          this.suggestionInvoices[encaissementIndex][existingIndex].reasons.push(reason);
        }
      }
    }
  }

  sortSuggestionInvoicesByTotalReasons(i: number): void {
    this.suggestionInvoices[i] = this.suggestionInvoices[i].sort((a, b) => {
      if (
        a.reasons.length > b.reasons.length ||
        (a.reasons.length === b.reasons.length &&
          a.reasons.indexOf(EnumSuggestionInvoiceReason.matchingInvoiceRef) !== -1)
      ) {
        return -1;
      } else if (a.reasons.length < b.reasons.length) {
        return 1;
      }
    });
  }

  extractInvoiceRefFromText(text: string): string[] {
    let refs: string[] = text.match(new RegExp(/([FA]-\d{6}-\d+)/g));

    if (!refs) {
      refs = text.match(new RegExp(/([PRO]+-[A-Z]+\d+.\d+.\d+)/g));
    }

    if (!refs) {
      refs = text.match(new RegExp(/([PRO]+ [A-Z]+\d+.\d+.\d+)/g));

      if (refs) {
        for (let i in refs) {
          refs[i].replaceAll('PRO ', 'PRO-');
        }
      }
    }

    return refs || [];
  }

  extractEnquiryRefFromText(text: string): string[] {
    return text.match(new RegExp(/([A-Z]{3}\d{2}\.\d+)/g));
  }

  async searchInvoiceInAlgoliaByRef(invoiceRef: string): Promise<IInvoice[]> {
    if (invoiceRef.length) {
      try {
        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          invoiceRef,
          ['ref'],
          []
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchEnquiryByRef(enquiryRef: string): Promise<IEnquiry[]> {
    if (enquiryRef.length) {
      try {
        const enquiries: IEnquiry[] = await this.remoteService.searchEnquiryByRef(enquiryRef);

        return enquiries;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchInvoiceInAlgoliaByEnquiryRef(enquiryRef: string): Promise<IInvoice[]> {
    if (enquiryRef.length) {
      try {
        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          enquiryRef,
          ['ref'],
          []
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchInvoiceByEnquiryId(enquiryId: string): Promise<IInvoice[]> {
    if (enquiryId !== null) {
      try {
        const filters: IAlgoliaFilter[] = [];

        filters.push({
          field: 'enquiryId',
          type: 'string',
          operator: '=',
          value: enquiryId
        });

        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          '',
          ['ref', 'enquiryId'],
          filters
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchInvoiceSameAmount(amount: number, currency: string): Promise<IInvoice[]> {
    if (amount !== null && currency !== null) {
      try {
        const filters: IAlgoliaFilter[] = [];

        filters.push({
          field: 'amountTtcTotal',
          type: 'number',
          operator: '=',
          value: amount
        });
        filters.push({
          field: 'currency',
          type: 'string',
          operator: '=',
          value: currency
        });

        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          '',
          ['ref', 'amountTtcTotal', 'currency'],
          filters
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  formatPrice(value: number, currency: string = 'EUR'): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }
}
