import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';

import {
  EnumCrawlerUrlStatus,
  getEnumCrawlerUrlStatusColor,
  getEnumCrawlerUrlStatusLabel
} from 'src/app/enums/crawler-url-status.enum';
import {
  EnumCrawlerUrlSource,
  getEnumCrawlerUrlSourceLabel
} from 'src/app/enums/crawler-url-source.enum';
import { getCrawlerUrlDuration, ICrawlerUrl } from 'src/app/interfaces/crawler-url.interface';
import { faBan, faEye, faSearch, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CrawlerUrlService } from 'src/app/services/crawler-url/crawler-url.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';

@Component({
  selector: 'app-list-crawler-urls',
  templateUrl: './list-crawler-urls.component.html',
  styleUrls: ['./list-crawler-urls.component.scss']
})
export class ListCrawlerUrlsComponent implements OnInit {
  rootUrl: string = './';
  currentPagination: string = 'crawler-urls-list';
  countriesList: Array<{
    title: string;
    value: string;
  }> = [];
  bankAccounts: IBankAccount[] = [];

  status: EnumCrawlerUrlStatus | '' = '';
  source: EnumCrawlerUrlSource | '' = '';

  getEnumCrawlerUrlStatusLabel = getEnumCrawlerUrlStatusLabel;
  getEnumCrawlerUrlStatusColor = getEnumCrawlerUrlStatusColor;
  getEnumCrawlerUrlSourceLabel = getEnumCrawlerUrlSourceLabel;
  getCrawlerUrlDuration = getCrawlerUrlDuration;

  EnumCrawlerUrlStatus = EnumCrawlerUrlStatus;
  EnumAcl = EnumAcl;

  faTrash = faTrash;
  faSync = faSync;
  faSearch = faSearch;
  faBan = faBan;
  faEye = faEye;

  loading = false;

  searchUrl = '';
  searchingByUrl = false;

  currentCrawlerUrl: ICrawlerUrl | null = null;
  currentCrawlerDataParsed: string | null = null;

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private crawlerUrlService: CrawlerUrlService
  ) {
    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.bankAccountsList);

    this.paginationService.pagination[this.currentPagination].currentPage = 0;

    this.fetchData();
  }

  getCrawlerUrlStatuses(): EnumCrawlerUrlStatus[] {
    return Object.values(EnumCrawlerUrlStatus);
  }

  getEnumCrawlerUrlSources(): EnumCrawlerUrlSource[] {
    return Object.values(EnumCrawlerUrlSource);
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    if (this.searchingByUrl) {
      this.paginationService.pagination[this.currentPagination].conditions.push({
        field: 'url',
        operator: '==',
        value: decodeURI(this.searchUrl)
      });
    } else if (this.status.length) {
      this.paginationService.pagination[this.currentPagination].conditions.push({
        field: 'status',
        operator: '==',
        value: this.status
      });
    } else if (this.source.length) {
      this.paginationService.pagination[this.currentPagination].conditions.push({
        field: 'source',
        operator: '==',
        value: this.source
      });
    }

    await this.paginationService.fetchData(this.currentPagination);
  }

  statusChanged(): void {
    this.fetchData();
  }

  sourceChanged(): void {
    this.fetchData();
  }

  async deleteOne(id: string): Promise<void> {
    this.loading = true;

    await this.crawlerUrlService.delete(id);

    await this.fetchData();

    this.loading = false;
  }

  async retryOne(id: string): Promise<void> {
    this.loading = true;

    await this.crawlerUrlService.update({
      id,
      status: EnumCrawlerUrlStatus.notStarted,
      nextDateToUpdate: new Date('2022') // Force to refresh first
    } as ICrawlerUrl);

    await this.fetchData();

    this.loading = false;
  }

  async deleteAllByStatus(status: EnumCrawlerUrlStatus): Promise<void> {
    if (confirm('La suppression  sera permanente. Êtes-vous sûr de vouloir continuer ?')) {
      this.loading = true;

      await this.crawlerUrlService.deleteByStatus(status);

      await this.fetchData();

      this.loading = false;
    }
  }

  async deleteAllBySource(source: EnumCrawlerUrlSource): Promise<void> {
    if (confirm('La suppression  sera permanente. Êtes-vous sûr de vouloir continuer ?')) {
      this.loading = true;

      await this.crawlerUrlService.deleteBySource(source);

      await this.fetchData();

      this.loading = false;
    }
  }

  async searchByUrl(): Promise<void> {
    this.searchingByUrl = true;

    await this.fetchData();
  }

  async cancelSearchByUrl(): Promise<void> {
    this.searchingByUrl = false;

    this.searchUrl = '';

    await this.fetchData();
  }

  view(crawlerUrl: ICrawlerUrl): void {
    if (crawlerUrl.dataFetched) {
      this.currentCrawlerDataParsed = JSON.parse(crawlerUrl.dataFetched as unknown as string);
    }

    this.currentCrawlerUrl = crawlerUrl;
  }
}
