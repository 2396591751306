import { Injectable } from '@angular/core';
import { IEnquiryCotation } from 'src/app/interfaces/enquiry-cotation.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class EnquiryCotationFirestoreService extends FirestoreService<IEnquiryCotation> {
  protected basePath = 'enquiryCotations';
}
