import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-authorised',
  templateUrl: './not-authorised.component.html',
  styleUrls: ['./not-authorised.component.scss']
})
export class NotAuthorisedComponent implements OnInit {

  rootUrl: string = './'

  constructor() {
    this.rootUrl = window['rootUrl']
  }

  ngOnInit(): void {
  }

}
