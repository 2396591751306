<breadcrumbs
    [items]="[
        {
            title: 'Liste des clients',
            path: '/admin/clients/list'
        },
        {
            title: client ? client.name : '',
            path: '/admin/clients/view/' + clientId
        },
        {
            title: 'Statistiques',
            path: '/admin/statistics/client/' + clientId
        }
    ]"
></breadcrumbs>

<div class="row mb-4">
    <div class="col-md-4">
        <a [routerLink]="'/admin/clients/view/' + clientId" class="btn btn-primary">Retour fiche</a>
    </div>
    <div class="offset-md-4 col-md-4">
        <div class="row">
            <div class="col-sm">
                <div class="form-group">
                    <label for="filterByPeriod">Filtrer par période</label>
                    <select
                        class="form-select"
                        id="filterByPeriod"
                        [(ngModel)]="filterByPeriod"
                        (change)="updateFilterPeriod()"
                    >
                        <option value=""></option>
                        <option
                            [value]="filter.value"
                            *ngFor="let filter of filtersByPeriod"
                            [innerHtml]="filter.title"
                        ></option>
                        <option value="custom">Personalisée</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="offset-md-4 col-md-8">
        <div class="row" *ngIf="filterByPeriod == 'custom'">
            <div class="col-sm">
                <div class="form-group">
                    <label for="start">Début période</label>
                    <input
                        type="date"
                        class="form-control"
                        name="start"
                        [(ngModel)]="start"
                        [max]="end ? end : today"
                        placeholder="YYYY-MM-DD"
                        (change)="customDatesChanged()"
                    />
                </div>
            </div>
            <div class="col-sm">
                <div class="form-group">
                    <label for="end">Fin période</label>
                    <input
                        type="date"
                        class="form-control"
                        name="end"
                        [(ngModel)]="end"
                        [min]="start"
                        [max]="today"
                        placeholder="YYYY-MM-DD"
                        (change)="customDatesChanged()"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-6 col-xl-4">
        <div class="row">
            <div class="col-sm-6 col-md-12">
                <div class="card card-statistics mb-4">
                    <div class="card-header">Nombre de demandes par status</div>
                    <table class="table table-sm">
                        <tbody>
                            <tr *ngFor="let count of stats.counts">
                                <td class="text-start" [innerHtml]="count.title"></td>
                                <td class="text-end" [class.disabled]="loading">
                                    <span
                                        [class]="'badge ' + count.badgeCls"
                                        [innerHtml]="count.value"
                                    ></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-sm-6 col-md-12">
                <div class="card card-statistics mb-4">
                    <div class="card-header">Nombre de demandes par type</div>
                    <table class="table table-sm">
                        <tbody>
                            <tr
                                *ngFor="let count of stats.countTypes"
                                [class]="'enquiry-' + count.type"
                            >
                                <td class="text-start" [innerHtml]="count.title"></td>
                                <td class="text-end" [class.disabled]="loading">
                                    <strong [innerHtml]="count.value"></strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-sm-6 col-md-12">
                <div class="card card-statistics mb-4">
                    <div class="card-header">Raisons d'annulation des demandes</div>
                    <table class="table table-sm">
                        <tbody>
                            <tr *ngFor="let count of stats.countCancelledReasons">
                                <td class="text-start" [innerHtml]="count.title"></td>
                                <td class="text-end" [class.disabled]="loading">
                                    <span class="badge bg-danger" [innerHtml]="count.value"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6 col-xl-8">
        <div class="row">
            <div class="col-sm-6 col-md-12 col-xl-6">
                <div class="card card-statistics mb-4">
                    <div class="card-header">Nombre total de demandes</div>
                    <div
                        class="card-body big-number"
                        [innerHtml]="stats.countTotal"
                        [class.disabled]="loading"
                    ></div>
                </div>
            </div>

            <div class="col-sm-6 col-md-12 col-xl-6">
                <div class="card card-statistics mb-4">
                    <div class="card-header">Chiffre d'affaires</div>
                    <div
                        class="card-body big-number"
                        [innerHtml]="formatPrice(stats.ca)"
                        [class.disabled]="loading"
                    ></div>
                </div>
            </div>

            <div class="col-sm-6 col-md-12 col-xl-6">
                <div class="card card-statistics mb-4">
                    <div class="card-header">Marge</div>
                    <div class="card-body big-number" [class.disabled]="loading">
                        {{ formatPrice(stats.margin) }} <br />{{ stats.marginPercent }}%
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-12 col-xl-6">
                <div class="card card-statistics mb-4">
                    <div class="card-header">Ratio demande/confirmation</div>
                    <div class="card-body big-number" [class.disabled]="loading">
                        {{ stats.ratioEnquiryConfirmed }}%
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
