import { Injectable } from '@angular/core';
import { IQuotation } from 'src/app/interfaces/quotation.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class QuotationFirestoreService extends FirestoreService<IQuotation> {
  protected basePath = 'quotations';
}
