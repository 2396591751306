export enum EnumEnquiryCommissionStatus {
  success = 'success',
  missingDocuments = 'missingDocuments',
  other = 'other'
}

export const getEnumEnquiryCommissionStatusLabel = (
  status: EnumEnquiryCommissionStatus
): string => {
  switch (status) {
    default:
      return status;
    case EnumEnquiryCommissionStatus.success:
      return 'Tout est bon';
    case EnumEnquiryCommissionStatus.missingDocuments:
      return 'Documents manquants';
    case EnumEnquiryCommissionStatus.other:
      return 'Autre';
  }
};
