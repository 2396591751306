import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { FinancialReportDepartmentsFirestoreService } from './financial-report-departments-firestore.service';
import { EnumCurrency } from 'src/app/enums/currency.enum';
import { IFinancialReportDepartment } from 'src/app/interfaces/financial-report-department.interface';

@Injectable({
  providedIn: 'root'
})
export class FinancialReportDepartmentsService {
  constructor(
    private financialReportDepartmentsFirestoreService: FinancialReportDepartmentsFirestoreService
  ) {}

  create(stats: IFinancialReportDepartment): Promise<string> {
    return this.financialReportDepartmentsFirestoreService.create(stats);
  }

  update(stats: IFinancialReportDepartment): Promise<string> {
    return this.financialReportDepartmentsFirestoreService.update(stats);
  }

  delete(id: string): Promise<void> {
    return this.financialReportDepartmentsFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IFinancialReportDepartment> {
    return this.financialReportDepartmentsFirestoreService.docValue$(id);
  }

  getAll(): Observable<IFinancialReportDepartment[]> {
    return this.financialReportDepartmentsFirestoreService.collectionValues$();
  }

  getAllForCurrencyForYear(
    currency: EnumCurrency,
    year: number
  ): Observable<IFinancialReportDepartment[]> {
    return this.financialReportDepartmentsFirestoreService.collectionValues$(ref =>
      ref.where('currency', '==', currency).where('year', '==', year)
    );
  }

  getAllForCurrencyOnlyYears(currency: EnumCurrency): Observable<IFinancialReportDepartment[]> {
    return this.financialReportDepartmentsFirestoreService.collectionValues$(ref =>
      ref.where('currency', '==', currency).where('quarter', '==', null).where('month', '==', null)
    );
  }

  getAllForCurrencyForYearOnly(
    currency: EnumCurrency,
    year: number
  ): Observable<IFinancialReportDepartment[]> {
    return this.financialReportDepartmentsFirestoreService.collectionValues$(ref =>
      ref.where('currency', '==', currency).where('year', '==', year).where('quarter', '==', null)
    );
  }

  getAllForYear(year: number): Observable<IFinancialReportDepartment[]> {
    return this.financialReportDepartmentsFirestoreService.collectionValues$(ref =>
      ref.where('year', '==', year)
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.financialReportDepartmentsFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IFinancialReportDepartment[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.financialReportDepartmentsFirestoreService.paginate(
      conditions,
      orderBy,
      20,
      paginationName
    );
  }
}
