<breadcrumbs [items]="breadcrumbsItems"></breadcrumbs>

<div class="row mb-4" *ngIf="!loading && enquiry">
    <div class="col-lg-3 order-lg-last">
        <div class="card text-center" *ngIf="enquiry">
            <div class="card-body">
                <h4 *ngIf="enquiry.refContractTitle">
                    <strong
                        [innerHtml]="
                            'N° contrat <br>' + getDisplayedEnquiryRefTitle(enquiry, 'refContract')
                        "
                    ></strong>
                </h4>
                <h4
                    [innerHtml]="
                        'N° demande <br>' + getDisplayedEnquiryRefTitle(enquiry, 'refEnquiry')
                    "
                    *ngIf="enquiry.refEnquiryTitle"
                ></h4>
            </div>
        </div>

        <h4>Détails vol</h4>
        <div class="card">
            <div class="card-body">
                <app-enquiry-card [enquiry]="enquiry"></app-enquiry-card>
            </div>
        </div>
    </div>
    <div class="col-lg" *ngIf="!form.value.length">
        <div class="alert alert-info text-center">
            Il n'y a aucune nouvelle compagnie aérienne à
            <span *ngIf="emailType === EnumEmailType.followUpAirlineForCotation">relancer</span>
            <span *ngIf="emailType === EnumEmailType.contactAirlineForCotation">contacter</span>
            <span *ngIf="emailType === EnumEmailType.cancelAirlineForCotation">annuler</span>.<br />
            <br />
            <a [routerLink]="'/admin/enquiry/view/' + enquiryId" class="btn btn-primary mb-1"
                >Aller au suivi des cotations</a
            >
        </div>
    </div>
    <div class="col-lg" *ngIf="form.value.length">
        <form [formGroup]="form" class="main-form">
            <div class="accordion" id="accordion-airlines">
                <div
                    class="accordion-item"
                    *ngFor="let airlineFormGroup of form.controls; let i = index"
                    [formGroupName]="i"
                    [class.accordion-item-success]="
                        airlineFormGroup.value.status === EnumEmailToSend.toSend
                    "
                    [class.accordion-item-warning]="
                        airlineFormGroup.value.status === EnumEmailToSend.toIgnore
                    "
                >
                    <h2
                        class="accordion-header"
                        [id]="'heading-' + airlineFormGroup.value.airlineId"
                        *ngIf="form.controls.length > 1"
                    >
                        <button
                            class="accordion-button"
                            [class.collapsed]="i !== currentlyOpenedAccordionIndex"
                            type="button"
                            data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapse-' + airlineFormGroup.value.airlineId"
                            [attr.aria-expanded]="i === currentlyOpenedAccordionIndex"
                            [attr.aria-controls]="'collapse-' + airlineFormGroup.value.airlineId"
                        >
                            {{ i + 1 }} / {{ form.value.length }} -
                            {{ airlinesObj[airlineFormGroup.value.airlineId]?.title }}
                        </button>
                    </h2>
                    <div
                        [id]="'collapse-' + airlineFormGroup.value.airlineId"
                        class="accordion-collapse collapse"
                        [class.show]="i === currentlyOpenedAccordionIndex"
                        [attr.aria-labelledby]="'heading-' + airlineFormGroup.value.airlineId"
                        data-bs-parent="#accordion-airlines"
                    >
                        <div class="accordion-body">
                            <div class="row mb-4">
                                <div class="col-md-5">
                                    <a
                                        [routerLink]="
                                            '/admin/airlines/view/' +
                                            airlineFormGroup.value.airlineId
                                        "
                                        >Fiche compagnie aérienne</a
                                    >
                                </div>
                                <div class="col-md-7">
                                    <div class="form-group">
                                        <div
                                            class="alert alert-danger text-center"
                                            *ngIf="
                                                !airlinesContactsObj[
                                                    airlineFormGroup.value.airlineId
                                                ]?.length
                                            "
                                        >
                                            La compagnie aérienne n'a aucun contact avec un email,
                                            veuillez en ajouter un pour pouvoir la contacter.<br />
                                            <br />
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                (click)="
                                                    addAirlineContacts(
                                                        airlineFormGroup.value.airlineId
                                                    )
                                                "
                                            >
                                                Ajouter une adresse email
                                            </button>
                                        </div>
                                        <div
                                            class="form-check"
                                            *ngFor="
                                                let contact of airlinesContactsObj[
                                                    airlineFormGroup.value.airlineId
                                                ];
                                                let j = index
                                            "
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                [value]="contact.email"
                                                [id]="
                                                    'contact-' +
                                                    airlineFormGroup.value.airlineId +
                                                    '-' +
                                                    j
                                                "
                                                (change)="
                                                    updateEmailCheckbox(
                                                        airlineFormGroup,
                                                        contact.email
                                                    )
                                                "
                                                [checked]="
                                                    airlineFormGroup.value.emails.includes(
                                                        contact.email
                                                    )
                                                "
                                            />
                                            <label
                                                class="form-check-label"
                                                [for]="
                                                    'contact-' +
                                                    airlineFormGroup.value.airlineId +
                                                    '-' +
                                                    j
                                                "
                                                [innerText]="
                                                    getContactTitle(contact) !== contact.email
                                                        ? contact.email +
                                                          ' (' +
                                                          getContactTitle(contact) +
                                                          ')'
                                                        : contact.email
                                                "
                                            >
                                            </label>
                                        </div>

                                        <div class="invalid-feedback">
                                            <div
                                                *ngIf="
                                                    airlineFormGroup.touched &&
                                                    airlineFormGroup.controls['emails'].errors &&
                                                    airlineFormGroup.controls['emails'].errors[
                                                        'required'
                                                    ]
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group mb-0">
                                        <label for="cc">Cc (email en copie)</label>
                                        <input
                                            class="form-control"
                                            id="cc"
                                            formControlName="cc"
                                            type="text"
                                            [readonly]="
                                                !airlinesContactsObj[
                                                    airlineFormGroup.value.airlineId
                                                ]?.length
                                            "
                                        />
                                    </div>
                                    <p class="text-muted">Séparer les emails par un ;</p>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group mb-0">
                                        <label for="cci">Cci (email en copie caché)</label>
                                        <input
                                            class="form-control"
                                            id="cci"
                                            formControlName="cci"
                                            type="text"
                                            [readonly]="
                                                !airlinesContactsObj[
                                                    airlineFormGroup.value.airlineId
                                                ]?.length
                                            "
                                        />
                                    </div>
                                    <p class="text-muted">Séparer les emails par un ;</p>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="language">Langue</label>
                                <select
                                    class="form-select"
                                    id="language"
                                    formControlName="language"
                                    required
                                    [class.is-invalid]="
                                        (airlineFormGroup.touched ||
                                            airlineFormGroup.controls['language'].touched) &&
                                        airlineFormGroup.controls['language'].status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="updatedLanguage(airlineFormGroup)"
                                >
                                    <option
                                        [value]="language"
                                        *ngFor="let language of getLanguages()"
                                        [innerHtml]="getLanguageLabel(language)"
                                    ></option>
                                </select>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (airlineFormGroup.touched ||
                                                airlineFormGroup.controls['language'].touched) &&
                                            airlineFormGroup.controls['language'].errors &&
                                            airlineFormGroup.controls['language'].errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="subject">Sujet</label>
                                <input
                                    class="form-control"
                                    id="subject"
                                    formControlName="subject"
                                    type="text"
                                    required
                                    [class.is-invalid]="
                                        (airlineFormGroup.touched ||
                                            airlineFormGroup.controls['subject'].touched) &&
                                        airlineFormGroup.controls['subject'].status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    [readonly]="
                                        !airlinesContactsObj[airlineFormGroup.value.airlineId]
                                            ?.length
                                    "
                                    maxlength="255"
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (airlineFormGroup.touched ||
                                                airlineFormGroup.controls['subject'].touched) &&
                                            airlineFormGroup.controls['subject'].errors &&
                                            airlineFormGroup.controls['subject'].errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                    <div
                                        *ngIf="
                                            (airlineFormGroup.touched ||
                                                airlineFormGroup.controls['subject'].touched) &&
                                            airlineFormGroup.controls['subject'].errors &&
                                            airlineFormGroup.controls['subject'].errors['maxlength']
                                        "
                                    >
                                        Ce champ doit être maximum 255 caractères.
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="message">Contenu</label>
                                <quill-editor
                                    formControlName="messageHtml"
                                    [readOnly]="
                                        !airlinesContactsObj[airlineFormGroup.value.airlineId]
                                            ?.length
                                    "
                                ></quill-editor>
                            </div>

                            <div class="row">
                                <div class="col-md">
                                    <button
                                        type="button"
                                        class="btn btn-warning btn-block mb-2"
                                        (click)="ignoreAndGoNext(i)"
                                        *ngIf="form.controls.length > 1"
                                    >
                                        <fa-icon [icon]="faBan"></fa-icon>
                                        <span class="ms-2">Ignorer et passer au suivant</span>
                                    </button>

                                    <button
                                        type="button"
                                        class="btn btn-warning btn-block mb-2"
                                        [routerLink]="'/admin/enquiry/view/' + enquiryId"
                                        *ngIf="form.controls.length <= 1"
                                    >
                                        <fa-icon [icon]="faBan"></fa-icon>
                                        <span class="ms-2"
                                            >Ignorer et retourner au suivi des cotations</span
                                        >
                                    </button>
                                </div>
                                <div class="col-md text-end">
                                    <button
                                        type="button"
                                        class="btn btn-success btn-block mb-2"
                                        (click)="addAndGoNext(i)"
                                        [disabled]="
                                            airlineFormGroup.invalid ||
                                            airlineFormGroup.disabled ||
                                            !airlinesContactsObj[airlineFormGroup.value.airlineId]
                                                ?.length
                                        "
                                        *ngIf="form.controls.length > 1"
                                    >
                                        <fa-icon [icon]="faPlus"></fa-icon> Ajouter à la liste
                                        d'envoi
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-success btn-block mb-2"
                                        (click)="addAndSend(i)"
                                        [disabled]="
                                            airlineFormGroup.invalid ||
                                            airlineFormGroup.disabled ||
                                            !airlinesContactsObj[airlineFormGroup.value.airlineId]
                                                ?.length
                                        "
                                        *ngIf="form.controls.length <= 1"
                                    >
                                        <fa-icon [icon]="faPaperPlane"></fa-icon> Envoyer l'email
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="form.controls.length > 1">
                <div class="alert alert-info text-center mt-4" *ngIf="getAllEmailAdded() <= 0">
                    Aucune compagnie aérienne n'a été ajouté à
                    <span *ngIf="emailType === EnumEmailType.followUpAirlineForCotation"
                        >relancer</span
                    >
                    <span *ngIf="emailType === EnumEmailType.contactAirlineForCotation"
                        >contacter</span
                    >
                    <span *ngIf="emailType === EnumEmailType.cancelAirlineForCotation"
                        >annuler</span
                    >
                    .<br />
                    <br />
                    <a
                        [routerLink]="'/admin/enquiry/view/' + enquiryId"
                        class="btn btn-primary mb-1"
                        >Retour au suivi des cotations</a
                    >
                </div>

                <div class="alert alert-primary text-center mt-4" *ngIf="getAllEmailAdded() > 0">
                    <p>
                        <strong>Attention, les emails n'ont pas encore été envoyés.</strong
                        ><br /><br />
                        {{ getAllEmailAdded() }} compagnies aériennes à
                        <span *ngIf="emailType === EnumEmailType.followUpAirlineForCotation"
                            >relancer</span
                        >
                        <span *ngIf="emailType === EnumEmailType.contactAirlineForCotation"
                            >contacter</span
                        >
                        <span *ngIf="emailType === EnumEmailType.cancelAirlineForCotation"
                            >annuler</span
                        >.
                    </p>
                    <button
                        type="button"
                        class="btn btn-primary"
                        (click)="sendAllEmails()"
                        [disabled]="sending"
                    >
                        <fa-icon [icon]="faPaperPlane"></fa-icon>
                        {{ sending ? "Envoie en cours ..." : "Envoyer les emails" }}
                    </button>
                </div>
            </ng-container>
        </form>
    </div>
</div>
