<breadcrumbs
    [items]="[
        {
            title: enquiry ? getEnquiryBreadcrumbTitle(enquiry) : '',
            path: '/admin/enquiry/view/' + enquiryId
        },
        {
            title: 'Sélection compagnies aériennes',
            path: '/admin/enquiry/select-airlines/' + enquiryId
        }
    ]"
></breadcrumbs>

<div class="row mb-4" *ngIf="loading">
    <div class="col-lg-3 order-lg-last">
        <div class="skeleton-text"></div>

        <h4>Détails vol</h4>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm col-lg-12">
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                    </div>

                    <div class="col-sm col-lg-12">
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                    </div>

                    <div class="col-md col-lg-12">
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg">
        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            *ngIf="
                                aircraftsCompiledSortedAndGroupedFiltered &&
                                aircraftsCompiledSortedAndGroupedFiltered.length
                            "
                        >
                            <input
                                type="checkbox"
                                [checked]="
                                    aircraftsCompiledSortedAndGroupedFiltered &&
                                    selectedAircraftsCompiled.length ==
                                        aircraftsCompiledSortedAndGroupedFiltered.length
                                "
                                *ngIf="
                                    aircraftsCompiledSortedAndGroupedFiltered.length ===
                                    aircraftsCompiledSortedAndGrouped.length
                                "
                                (click)="selectAll()"
                            />
                        </th>
                        <th
                            scope="col"
                            class="sortable"
                            *ngFor="let field of fields"
                            [class.is-sorted]="orderBy.field == field.code"
                            [class.asc]="orderBy.field == field.code && orderBy.direction == 'asc'"
                            [class.desc]="
                                orderBy.field == field.code && orderBy.direction == 'desc'
                            "
                            (click)="sortField(field.code)"
                            [innerHtml]="field.title"
                        ></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let number of [].constructor(10)">
                        <td
                            class="has-check"
                            *ngIf="
                                aircraftsCompiledSortedAndGroupedFiltered &&
                                aircraftsCompiledSortedAndGroupedFiltered.length
                            "
                        >
                            <div class="skeleton-text"></div>
                        </td>
                        <td *ngFor="let field of fields"><div class="skeleton-text"></div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="row mb-4" *ngIf="!loading && enquiry">
    <div class="col-lg-3 order-lg-last">
        <div class="card text-center" *ngIf="enquiry">
            <div class="card-body">
                <h4 *ngIf="enquiry.refContractTitle">
                    <strong
                        [innerHtml]="
                            'N° contrat <br>' + getDisplayedEnquiryRefTitle(enquiry, 'refContract')
                        "
                    ></strong>
                </h4>
                <h4
                    [innerHtml]="
                        'N° demande <br>' + getDisplayedEnquiryRefTitle(enquiry, 'refEnquiry')
                    "
                    *ngIf="enquiry.refEnquiryTitle"
                ></h4>
            </div>
        </div>

        <h4>Détails vol</h4>
        <div class="card">
            <div class="card-body">
                <app-enquiry-card [enquiry]="enquiry"></app-enquiry-card>
            </div>
        </div>
    </div>
    <div class="col-lg">
        <div class="alert alert-info">
            Liste des compagnies aériennes selon les filtres suivants :

            <div class="row mt-4">
                <div
                    class="col-sm-4"
                    *ngIf="enquiry.geographicalAreasCode && enquiry.geographicalAreasCode.length"
                >
                    <strong>Zone(s) géographique(s)</strong><br />
                    <span
                        [innerHtml]="getEnquiryGeographicalAreasTitles(enquiry).join(', ')"
                    ></span>
                </div>
                <div
                    class="col-sm-2"
                    *ngIf="
                        enquiry.type !== EnumEnquiryType.cargo &&
                        enquiry.targets &&
                        enquiry.targets.length
                    "
                >
                    <div *ngIf="enquiry.type === EnumEnquiryType.helico">
                        <strong>Cible</strong><br />
                        <span>Hélicoptère</span>
                    </div>
                    <div
                        *ngIf="
                            [EnumEnquiryType.cargo, EnumEnquiryType.helico].indexOf(
                                enquiry.type
                            ) === -1
                        "
                    >
                        <strong
                            [innerHtml]="enquiry.targets.length > 1 ? 'Cibles' : 'Cible'"
                        ></strong
                        ><br />
                        <span [innerHtml]="getEnquiryTargetsTitles(enquiry).join(', ')"></span>
                    </div>
                </div>
                <div class="col-sm-2" *ngIf="enquiry.aircraftModelId">
                    <strong>Modèle</strong><br />
                    <span [innerHtml]="aircraftModel ? aircraftModel.title : ''"></span>
                </div>
                <div class="col-sm-6" *ngIf="enquiry.type !== EnumEnquiryType.cargo">
                    <div class="row">
                        <div *ngIf="enquiry.capacityMin" class="col-sm-6">
                            <strong>Capacité Min</strong><br />
                            <span [innerHtml]="enquiry.capacityMin"></span>
                        </div>
                        <div *ngIf="enquiry.capacityMax" class="col-sm-6">
                            <strong>Capacité Max</strong><br />
                            <span [innerHtml]="enquiry.capacityMax"></span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-8" *ngIf="enquiry.type === EnumEnquiryType.cargo">
                    <div class="row">
                        <div *ngIf="enquiry.weightMin" class="col-sm-3">
                            <strong>Poids Min</strong><br />
                            <span [innerHtml]="enquiry.weightMin + ' kg'"></span>
                        </div>
                        <div *ngIf="enquiry.weightMax" class="col-sm-3">
                            <strong>Poids Max</strong><br />
                            <span [innerHtml]="enquiry.weightMax + ' kg'"></span>
                        </div>
                        <div *ngIf="enquiry.volumeMin" class="col-sm-3">
                            <strong>Volume Min</strong><br />
                            <span [innerHtml]="enquiry.volumeMin + ' m<sup>3</sup>'"></span>
                        </div>
                        <div *ngIf="enquiry.volumeMax" class="col-sm-3">
                            <strong>Volume Max</strong><br />
                            <span [innerHtml]="enquiry.volumeMax + ' m<sup>3</sup>'"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div
                class="col-lg-7"
                [class.col-lg-12]="aircraftsCompiledSortedAndGroupedFiltered.length"
            >
                <div class="form-group">
                    <div class="input-group">
                        <span class="input-group-text">
                            <fa-icon [icon]="faSearch"></fa-icon>
                        </span>
                        <input
                            type="search"
                            class="form-control"
                            placeholder="Recherchez une compagnie aérienne ou un appareil"
                            (keyup)="filterData($event)"
                            (search)="filterData()"
                            debounce="500"
                            *ngIf="prefillListWithoutSearch"
                        />
                        <app-airline-autocomplete
                            (setValueToFormControl)="setValueToFormControl($event)"
                            inputName="airline"
                            [defaultValue]="selectedAirline"
                            [disabled]="sending"
                            placeholder="Recherchez une compagnie aérienne"
                            *ngIf="!prefillListWithoutSearch"
                        ></app-airline-autocomplete>
                    </div>
                </div>
            </div>
            <div
                class="col-lg-5 text-end"
                *ngIf="!aircraftsCompiledSortedAndGroupedFiltered.length"
            >
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="submitForm()"
                    [disabled]="sending"
                >
                    Continuer vers le suivi de cotation
                </button>
            </div>
        </div>

        <h5
            *ngIf="
                aircraftsCompiledSortedAndGroupedFiltered &&
                aircraftsCompiledSortedAndGroupedFiltered.length
            "
            class="text-center mt-4"
        >
            Choisisez un ou plusieurs appareils ci-dessous
        </h5>
        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead *ngIf="aircraftsCompiledSortedAndGroupedFiltered?.length">
                    <tr>
                        <th
                            scope="col"
                            *ngIf="
                                aircraftsCompiledSortedAndGroupedFiltered &&
                                aircraftsCompiledSortedAndGroupedFiltered.length
                            "
                        >
                            <input
                                type="checkbox"
                                [checked]="
                                    aircraftsCompiledSortedAndGroupedFiltered &&
                                    selectedAircraftsCompiled.length ==
                                        aircraftsCompiledSortedAndGroupedFiltered.length
                                "
                                *ngIf="
                                    aircraftsCompiledSortedAndGroupedFiltered.length ===
                                    aircraftsCompiledSortedAndGrouped.length
                                "
                                (click)="selectAll()"
                            />
                        </th>
                        <th
                            scope="col"
                            class="sortable"
                            *ngFor="let field of fields"
                            [class.is-sorted]="orderBy.field == field.code"
                            [class.asc]="orderBy.field == field.code && orderBy.direction == 'asc'"
                            [class.desc]="
                                orderBy.field == field.code && orderBy.direction == 'desc'
                            "
                            (click)="sortField(field.code)"
                            [class.d-none]="
                                field.code === 'airlineContinentTitle' &&
                                enquiry.geographicalAreasCode.length <= 1
                            "
                            [innerHtml]="field.title"
                        ></th>
                    </tr>
                </thead>
                <tbody *ngIf="loading">
                    <tr *ngFor="let number of [].constructor(10)">
                        <td
                            class="has-check"
                            *ngIf="
                                aircraftsCompiledSortedAndGroupedFiltered &&
                                aircraftsCompiledSortedAndGroupedFiltered.length
                            "
                        >
                            <div class="skeleton-text"></div>
                        </td>
                        <td *ngFor="let field of fields"><div class="skeleton-text"></div></td>
                    </tr>
                </tbody>
                <tbody *ngIf="!loading">
                    <tr
                        *ngIf="
                            !aircraftsCompiledSortedAndGroupedFiltered ||
                            !aircraftsCompiledSortedAndGroupedFiltered.length
                        "
                        class="table-warning text-center"
                    >
                        <td [attr.colspan]="fields.length + 1">Aucun appareil n'a été trouvé.</td>
                    </tr>
                    <tr
                        *ngFor="
                            let aircraftCompiledSortedAndGrouped of aircraftsCompiledSortedAndGroupedFiltered;
                            let i = index
                        "
                        [class.clickable]="
                            aircraftsCompiledAlreadyContactedId.indexOf(
                                aircraftCompiledSortedAndGrouped.aircraftCompiled.id
                            ) === -1
                        "
                        (click)="clickOnTr(i)"
                        [class.disabled]="
                            aircraftsCompiledAlreadyContactedId.indexOf(
                                aircraftCompiledSortedAndGrouped.aircraftCompiled.id
                            ) !== -1
                        "
                        [class.table-primary]="
                            aircraftCompiledSortedAndGrouped.aircraftCompiled.airlineMustBeContacted
                        "
                        [class.table-warning]="
                            aircraftCompiledSortedAndGrouped.aircraftCompiled
                                .isAirlineNationalCompany
                        "
                        [class.table-danger]="
                            aircraftCompiledSortedAndGrouped.aircraftCompiled.isBlacklisted
                        "
                        [class.d-none]="aircraftCompiledSortedAndGrouped.hide"
                        [class.is-last-child]="aircraftCompiledSortedAndGrouped.last"
                    >
                        <td
                            class="is-parent-arrow"
                            *ngIf="aircraftCompiledSortedAndGrouped.isParent"
                        >
                            <span
                                [innerHtml]="aircraftCompiledSortedAndGrouped.opened ? '▾' : '▸'"
                            ></span>
                            <span
                                class="count"
                                [innerHtml]="aircraftCompiledSortedAndGrouped.countChildren"
                            ></span>
                        </td>
                        <td class="has-check" *ngIf="!aircraftCompiledSortedAndGrouped.isParent">
                            <input
                                type="checkbox"
                                [checked]="
                                    isAircraftCompiledSelected(
                                        aircraftCompiledSortedAndGrouped.aircraftCompiled.id
                                    ) ||
                                    aircraftsCompiledAlreadyContactedId.indexOf(
                                        aircraftCompiledSortedAndGrouped.aircraftCompiled.id
                                    ) !== -1
                                "
                                *ngIf="
                                    aircraftsCompiledAlreadyContactedId.indexOf(
                                        aircraftCompiledSortedAndGrouped.aircraftCompiled.id
                                    ) === -1
                                "
                            />
                            <img
                                [src]="rootUrl + 'assets/img/icon-check-dark.svg'"
                                alt="Ajouté"
                                height="15"
                                *ngIf="
                                    aircraftsCompiledAlreadyContactedId.indexOf(
                                        aircraftCompiledSortedAndGrouped.aircraftCompiled.id
                                    ) !== -1
                                "
                            />
                        </td>
                        <td>
                            <a
                                [routerLink]="
                                    '/admin/airlines/view/' +
                                    aircraftCompiledSortedAndGrouped.aircraftCompiled.airlineId
                                "
                                [innerHtml]="
                                    aircraftCompiledSortedAndGrouped.aircraftCompiled.airlineTitle
                                "
                                target="_blank"
                                >{{
                                    aircraftCompiledSortedAndGrouped.aircraftCompiled.airlineTitle
                                }}
                            </a>

                            <img
                                [src]="rootUrl + 'assets/img/icon-warning-orange.svg'"
                                *ngIf="
                                    aircraftCompiledSortedAndGrouped.aircraftCompiled.isBlacklisted
                                "
                                width="24"
                                alt="Attention"
                                rel="tooltip"
                                [title]="
                                    'Cette compagnie aérienne est black listée pour la raison suivante : ' +
                                    aircraftCompiledSortedAndGrouped.aircraftCompiled
                                        .reasonBlackListed
                                "
                            />
                        </td>
                        <td
                            [innerHtml]="
                                aircraftCompiledSortedAndGrouped.aircraftCompiled
                                    .airlineCountryTitle
                            "
                        ></td>
                        <td>
                            <a
                                [routerLink]="
                                    '/admin/aircraft-models/view/' +
                                    aircraftCompiledSortedAndGrouped.aircraftCompiled
                                        .aircraftModelId
                                "
                                [innerHtml]="
                                    aircraftCompiledSortedAndGrouped.aircraftCompiled.modelTitle.toUpperCase()
                                "
                            ></a>
                        </td>
                        <td
                            *ngIf="!aircraftCompiledSortedAndGrouped.isParent"
                            [innerHtml]="aircraftCompiledSortedAndGrouped.aircraftCompiled.count"
                        ></td>
                        <td
                            [innerHtml]="
                                aircraftCompiledSortedAndGrouped.aircraftCompiled
                                    ? aircraftCompiledSortedAndGrouped.aircraftCompiled.seatTotal
                                    : ''
                            "
                            *ngIf="
                                !aircraftCompiledSortedAndGrouped.isParent &&
                                enquiry.type !== EnumEnquiryType.cargo
                            "
                        ></td>
                        <td
                            [innerHtml]="
                                aircraftCompiledSortedAndGrouped.aircraftCompiled
                                    ? aircraftCompiledSortedAndGrouped.aircraftCompiled.classesTitle
                                    : ''
                            "
                            *ngIf="
                                !aircraftCompiledSortedAndGrouped.isParent &&
                                enquiry.type !== EnumEnquiryType.cargo
                            "
                        ></td>
                        <td
                            [innerHtml]="
                                aircraftCompiledSortedAndGrouped.aircraftCompiled
                                    ? aircraftCompiledSortedAndGrouped.aircraftCompiled.targetTitle
                                    : ''
                            "
                            *ngIf="
                                !aircraftCompiledSortedAndGrouped.isParent &&
                                [EnumEnquiryType.cargo, EnumEnquiryType.helico].indexOf(
                                    enquiry.type
                                ) === -1
                            "
                        ></td>
                        <td
                            [innerHtml]="
                                aircraftCompiledSortedAndGrouped.aircraftCompiled
                                    ? aircraftCompiledSortedAndGrouped.aircraftCompiled.weight
                                    : ''
                            "
                            *ngIf="
                                !aircraftCompiledSortedAndGrouped.isParent &&
                                enquiry.type === EnumEnquiryType.cargo
                            "
                        ></td>
                        <td
                            [innerHtml]="
                                aircraftCompiledSortedAndGrouped.aircraftCompiled
                                    ? aircraftCompiledSortedAndGrouped.aircraftCompiled.volume
                                    : ''
                            "
                            *ngIf="
                                !aircraftCompiledSortedAndGrouped.isParent &&
                                enquiry.type === EnumEnquiryType.cargo
                            "
                        ></td>
                        <td
                            [innerHtml]="
                                aircraftCompiledSortedAndGrouped.aircraftCompiled
                                    ? aircraftCompiledSortedAndGrouped.aircraftCompiled.ageAverage
                                    : ''
                            "
                            *ngIf="
                                !aircraftCompiledSortedAndGrouped.isParent &&
                                enquiry.type !== EnumEnquiryType.helico
                            "
                        ></td>

                        <td
                            colspan="5"
                            *ngIf="
                                aircraftCompiledSortedAndGrouped.isParent &&
                                enquiry.type === EnumEnquiryType.cargo
                            "
                        ></td>
                        <td
                            colspan="4"
                            *ngIf="
                                aircraftCompiledSortedAndGrouped.isParent &&
                                enquiry.type === EnumEnquiryType.helico
                            "
                        ></td>
                        <td
                            colspan="5"
                            *ngIf="
                                aircraftCompiledSortedAndGrouped.isParent &&
                                [EnumEnquiryType.cargo, EnumEnquiryType.helico].indexOf(
                                    enquiry.type
                                ) === -1
                            "
                        ></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div
            class="row"
            id="footer-actions"
            *ngIf="aircraftsCompiledSortedAndGroupedFiltered.length"
        >
            <div class="col-lg-9">
                <div class="btn-toolbar mb-4 justify-content-between" role="toolbar">
                    <a
                        [routerLink]="'/admin/enquiry/edit/' + enquiryId"
                        class="btn btn-primary"
                        [class.disabled]="sending"
                        >Retour édition</a
                    >
                    <button
                        type="button"
                        class="btn btn-primary"
                        (click)="submitForm()"
                        [disabled]="sending"
                    >
                        Continuer vers le suivi de cotation
                    </button>
                    <a
                        [routerLink]="'/admin/enquiry/view/' + enquiryId"
                        class="btn btn-primary"
                        [class.disabled]="sending"
                        *ngIf="
                            aircraftsCompiledSortedAndGroupedFiltered &&
                            aircraftsCompiledAlreadyContactedId.length ==
                                aircraftsCompiledSortedAndGroupedFiltered.length
                        "
                        >Retour au suivi</a
                    >
                </div>
            </div>
        </div>
    </div>
</div>
