import { IStoreData } from './store-data.interface';

export interface IEnquiryTrip extends IStoreData {
  date: string;
  time: string;
  aircraftModelId?: string;
  arrivalTime: string;
  airportDepart: string;
  airportDepartTax: number;
  airportDestination: string;
  airportDestinationTax: number;
  airportDepartTimezoneId: string;
  airportDepartTimezoneRawOffset: number;
  airportDepartTimezoneGmt: string;
  airportDestinationTimezoneId: string;
  airportDestinationTimezoneRawOffset: number;
  airportDestinationTimezoneGmt: string;
  passengersTotal: number;
  passengersBusiness: number;
  weight: number;
  volume: number;
  distanceInKm: number;
  speedInKts: number;
  title: string;
  natureFret: string;
  dimensions: string;
  hasFuelStopOver: boolean;
  fuelStopOverAirport: string;
  hasCommercialStopOver: boolean;
  commercialStopOverAirport: string;
  passengersCommercialStop: number;
  passengersCommercialStopEmbark: number;
  passengersCommercialStopDisembark: number;
}

export const getArrivalPassengerTotal = (trip: IEnquiryTrip): number => {
  let nbPax: number = 0;

  if (trip.passengersTotal) {
    nbPax += trip.passengersTotal;
  }

  if (trip.hasCommercialStopOver && trip.passengersCommercialStop !== 0) {
    nbPax += trip.passengersCommercialStop;
  }

  return nbPax;
};
