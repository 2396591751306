import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';

@Injectable({
  providedIn: 'root'
})
export class EnquiryFlightFirestoreService extends FirestoreService<IEnquiryFlight> {
  protected basePath = 'enquiryFlights';
}
