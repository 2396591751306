import { Component, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnChanges {

  @Input('items') items: Array<{
    title: string,
    path: string,
    children: Array<{
      title: string,
      path: string,  
    }>
  }> = []

  constructor() {
  }

  ngOnChanges() {
  }

}
