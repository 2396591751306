import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { CountryFirestoreService } from './countries-firestore.service';
import { ICountry } from 'src/app/interfaces/country.interface';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(private countryFirestoreService: CountryFirestoreService) {}

  create(country: ICountry): Promise<string> {
    return this.countryFirestoreService.create(country);
  }

  createWithId(id: string, country: ICountry): Promise<string> {
    return this.countryFirestoreService.createWithId(id, country);
  }

  update(country: ICountry): Promise<string> {
    return this.countryFirestoreService.update(country);
  }

  delete(id: string): Promise<void> {
    return this.countryFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<ICountry> {
    return this.countryFirestoreService.docValue$(id);
  }

  getAll(): Observable<ICountry[]> {
    return this.countryFirestoreService.collectionValues$();
  }

  getAllActive(): Observable<ICountry[]> {
    return this.countryFirestoreService.collectionValues$(ref => ref.where('isActive', '==', true));
  }

  resetPagination(paginationName: string = 'list'): void {
    this.countryFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<ICountry[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.countryFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }
}
