import { Injectable } from '@angular/core';
import { ICrawlerUrl } from 'src/app/interfaces/crawler-url.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class CrawlerUrlFirestoreService extends FirestoreService<ICrawlerUrl> {
  protected basePath = 'crawlerUrls';
}
