import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { IEmployeeBonusRule } from 'src/app/interfaces/employee-bonus-rule.interface';

@Injectable({
  providedIn: 'root'
})
export class EmployeeBonusRulesFirestoreService extends FirestoreService<IEmployeeBonusRule> {
  protected basePath = 'employeeBonusRules';
}
