<nav class="navbar navbar-expand-lg navbar-light" *ngIf="!hideMenu">
    <div class="container-fluid">
        <a class="navbar-brand" routerLink="/admin/dashboard">
            <img [src]="rootUrl + 'assets/img/logo-menu.png'" alt="Artheau Aviation" />
        </a>
        <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            #menuCloseBtn
        >
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ml-auto">
                <li
                    class="nav-item"
                    *ngFor="let menuItem of menuItems"
                    [class.dropdown]="menuItem.children.length"
                >
                    <a
                        class="nav-link"
                        [class.dropdown-toggle]="menuItem.children.length"
                        (click)="openMenuItem(menuItem)"
                        [class.active]="menuItem.url == currentUrl"
                        [innerHtml]="menuItem.title"
                        [attr.data-bs-toggle]="menuItem.children.length ? 'dropdown' : ''"
                    ></a>
                    <div
                        class="dropdown-menu dropdown-menu-end"
                        aria-labelledby="navbarDropdown"
                        *ngIf="menuItem.children.length"
                    >
                        <a
                            class="dropdown-item"
                            (click)="openMenuItem(childMenuItem)"
                            *ngFor="let childMenuItem of menuItem.children"
                            [innerHtml]="childMenuItem.title"
                        ></a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>

<div class="container-fluid" id="main-container">
    <div class="alert alert-info text-center" *ngIf="isStaging">
        Vous êtes actuellement sur l'environnement de préproduction. <br />
        Les données de production sont récupérées une fois par jour.
    </div>
    <router-outlet></router-outlet>
</div>

<div class="text-center" id="main-loader" [class.d-none]="hideLoading">
    <div id="main-loader-content">
        <div class="spinner-border" role="status"></div>
        Chargement ...
    </div>
</div>

<app-loader></app-loader>
