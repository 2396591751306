import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { getEmployeeBonusRuleDepartmentList } from 'src/app/interfaces/employee-bonus-rule.interface';

import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-list-employee-bonus-rules',
  templateUrl: './list-employee-bonus-rules.component.html',
  styleUrls: ['./list-employee-bonus-rules.component.scss']
})
export class ListEmployeeBonusRulesComponent implements OnInit {
  faSearch = faSearch;

  EnumAcl = EnumAcl;

  getEmployeeBonusRuleDepartmentList = getEmployeeBonusRuleDepartmentList;

  rootUrl: string = './';
  currentPagination: string = 'employee-bonus-rules-list';

  constructor(public paginationService: PaginationService, private aclService: AclService) {}

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.employeeBonusRulesList);

    this.paginationService.pagination[this.currentPagination].currentPage = 0;

    this.fetchData();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    await this.paginationService.fetchData(this.currentPagination);
  }
}
