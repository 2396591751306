import {
  addMonths,
  addYears,
  endOfYear,
  format,
  getQuarter,
  getYear,
  isAfter,
  startOfMonth
} from 'date-fns';
import { IStoreData } from './store-data.interface';
import { fr } from 'date-fns/locale';

export interface IExportRequest extends IStoreData {
  type: EnumExportRequestType;
  status: EnumExportRequestStatus;
  filePath?: string;
  filterType: EnumExportRequestFilterType;
  timePeriod: string | null;
  enquiryId: string | null;
  enquiryTitle: string | null;
  clientId: string | null;
  clientName: string | null;
}

export enum EnumExportRequestFilterType {
  timePeriod = 'timePeriod',
  enquiry = 'enquiry',
  client = 'client'
}

export const getExportRequestFilerTypeLabel = (type: EnumExportRequestFilterType): string => {
  switch (type) {
    default:
      return type;
    case EnumExportRequestFilterType.timePeriod:
      return 'Période de temps';
    case EnumExportRequestFilterType.enquiry:
      return 'Dossier';
    case EnumExportRequestFilterType.client:
      return 'Client';
  }
};

export enum EnumExportRequestType {
  invoices = 'invoices'
}

export const getExportRequestLabel = (exportRequest: IExportRequest | null): string => {
  if (exportRequest) {
    let title: string = getExportRequestTypeLabel(exportRequest.type);

    switch (exportRequest.filterType) {
      case EnumExportRequestFilterType.timePeriod:
        title += ' pour ' + getExportRequesTimePeriodLabel(exportRequest.timePeriod);
        break;
      case EnumExportRequestFilterType.enquiry:
        title += ' pour dossier ' + exportRequest.enquiryTitle;
        break;
      case EnumExportRequestFilterType.client:
        title += ' pour client ' + exportRequest.clientName;
        break;
    }

    return title;
  }

  return '';
};

export const getExportRequestTypeLabel = (type: EnumExportRequestType): string => {
  switch (type) {
    default:
      return type;
    case EnumExportRequestType.invoices:
      return 'Factures PDF';
  }
};

export enum EnumExportRequestStatus {
  requested = 'requested',
  ongoing = 'ongoing',
  completed = 'completed'
}

export const getExportRequestStatusLabel = (status: EnumExportRequestStatus): string => {
  switch (status) {
    default:
      return status;
    case EnumExportRequestStatus.requested:
      return 'Demandé';
    case EnumExportRequestStatus.ongoing:
      return 'En cours';
    case EnumExportRequestStatus.completed:
      return 'Complété';
  }
};

export const getExportRequesTimePeriodLabel = (timePeriod: string): string => {
  const filtersByPeriod: {
    title: string;
    value: string;
  }[] = [];

  let currentYear: number = getYear(new Date());

  const maxDec: number = currentYear - 2021;
  const beginningOfMonth: Date = startOfMonth(new Date());
  const startDate: Date = endOfYear(addYears(beginningOfMonth, -maxDec - 1));

  for (var m = beginningOfMonth; isAfter(m, startDate); m = addMonths(m, -1)) {
    let title: string = format(m, 'MMMM yy', {
      locale: fr
    });
    title = title.charAt(0).toUpperCase() + title.slice(1);
    filtersByPeriod.push({
      title: title,
      value: format(m, 'yyyy-MM')
    });
  }
  for (let dec = 0; dec <= maxDec; dec++) {
    const lastQuarter: number = dec === 0 ? getQuarter(new Date()) : 4;
    const year: number = currentYear - dec;
    for (let i = lastQuarter; i >= 1; i--) {
      filtersByPeriod.push({
        title: 'Q' + i + ' ' + year,
        value: 'quarter-' + year + '-' + i
      });
    }
  }
  for (let year = currentYear; year >= 2021; year--) {
    filtersByPeriod.push({
      title: 'Année ' + year,
      value: 'year-' + year
    });
  }

  for (const filterByPeriod of filtersByPeriod) {
    if (filterByPeriod.value === timePeriod) {
      return filterByPeriod.title;
    }
  }

  return timePeriod;
};
