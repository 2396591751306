import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { IUser } from 'src/app/interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class UsersFirestoreService extends FirestoreService<IUser> {
  protected basePath = 'users';
}
