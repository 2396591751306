<div *ngIf="value" class="form-group">
    <div class="form-control fake-input" [class.disabled]="disabled">
        <span class="fake-input-text">
            <ng-container *ngIf="contractRefOnly">
                <a
                    [routerLink]="'/admin/enquiry/view/' + value"
                    target="_blank"
                    *ngIf="selectedEnquiry"
                >
                    {{
                        selectedEnquiry.refContractTitle
                            ? getDisplayedEnquiryRefTitle(selectedEnquiry, "refContract")
                            : "Aucun numéro de contrat"
                    }}
                </a>
            </ng-container>
            <ng-container *ngIf="!contractRefOnly">
                <a
                    [routerLink]="'/admin/enquiry/view/' + value"
                    target="_blank"
                    *ngIf="selectedEnquiry"
                >
                    {{ getDisplayedEnquiryRefTitle(selectedEnquiry, "refEnquiry")
                    }}<span *ngIf="selectedEnquiry.refContractTitle">
                        /
                        <strong
                            [innerText]="
                                getDisplayedEnquiryRefTitle(selectedEnquiry, 'refContract')
                            "
                        ></strong>
                    </span>
                </a>
            </ng-container>
        </span>
        <img
            [src]="rootUrl + 'assets/img/icon-close-dark.svg'"
            class="clear-btn"
            (click)="clearValue()"
            *ngIf="!disabled"
        />
    </div>
</div>
<div [style.display]="!value ? 'block' : 'none'" class="form-group">
    <input
        type="text"
        autocomplete="off"
        [name]="inputName"
        class="form-control"
        [class.list-displayed]="optionsSuggested.length"
        list="browsers"
        [(ngModel)]="query"
        (keyup)="updateAutocomplete()"
        debounce="500"
        placeholder="Recherche une demande"
        #searchInput
        (focus)="updateAutocomplete()"
        [class.is-invalid]="isInvalid"
        [disabled]="disabled"
    />

    <div class="invalid-tooltip" *ngIf="isInvalid">
        <div *ngIf="isInvalid">Ce champ est obligatoire.</div>
    </div>

    <div *ngIf="optionsSuggested.length && !disabled" class="list-group">
        <button
            type="button"
            class="list-group-item list-group-item-action"
            *ngFor="let option of optionsSuggested"
            (click)="updateFormValue(option)"
        >
            <ng-container *ngIf="contractRefOnly">
                {{ getDisplayedEnquiryRefTitle(option, "refContract") }}
            </ng-container>
            <ng-container *ngIf="!contractRefOnly">
                {{ getDisplayedEnquiryRefTitle(option, "refEnquiry")
                }}<span *ngIf="option.refContractTitle">
                    /
                    <strong
                        [innerText]="getDisplayedEnquiryRefTitle(option, 'refContract')"
                    ></strong>
                </span>
            </ng-container>
        </button>
    </div>
</div>
