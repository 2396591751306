export enum EnumInvoiceStatus {
  generated = 'generated',
  sent = 'sent',
  accepted = 'accepted',
  rejected = 'rejected',
  cancelled = 'cancelled',
  requested = 'requested'
}

export const getEnumInvoiceStatusLabel = (status: EnumInvoiceStatus): string => {
  switch (status) {
    default:
      return status;
    case EnumInvoiceStatus.generated:
      return 'generated';
    case EnumInvoiceStatus.sent:
      return 'Envoyé';
    case EnumInvoiceStatus.accepted:
      return 'Accepté';
    case EnumInvoiceStatus.rejected:
      return 'Refusé';
    case EnumInvoiceStatus.cancelled:
      return 'Annulée';
    case EnumInvoiceStatus.requested:
      return 'Facture proforma demandée';
  }
};
