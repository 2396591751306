import { Injectable } from '@angular/core';
import { IFlightBrief } from 'src/app/interfaces/flight-brief.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class FlightBriefFirestoreService extends FirestoreService<IFlightBrief> {
  protected basePath = 'flightBriefs';
}
