import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { IFlightChecklistTemplate } from 'src/app/interfaces/flight-checklist-template.interface';
import { getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';

@Component({
  selector: 'app-list-flight-checklist-templates',
  templateUrl: './list-flight-checklist-templates.component.html',
  styleUrls: ['./list-flight-checklist-templates.component.scss']
})
export class ListFlightChecklistTemplatesComponent implements OnInit {
  EnumAcl = EnumAcl;

  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;

  rootUrl: string = './';
  currentPagination: string = 'flight-checklist-templates-list';
  countriesList: Array<{
    title: string;
    value: string;
  }> = [];
  flightChecklistTemplates: IFlightChecklistTemplate[] = [];

  constructor(public paginationService: PaginationService, private aclService: AclService) {
    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.flightChecklistTemplatesList);

    this.paginationService.pagination[this.currentPagination].currentPage = 0;

    this.fetchData();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    await this.paginationService.fetchData(this.currentPagination);
  }
}
