export enum EnumEnquiryStatus {
  draft = 'draft',
  cancelled = 'cancelled',
  confirmed = 'confirmed'
}

export const getEnumEnquiryStatusLabel = (status: EnumEnquiryStatus): string => {
  switch (status) {
    default:
      return status;
    case EnumEnquiryStatus.draft:
      return 'En cours';
    case EnumEnquiryStatus.cancelled:
      return 'Annulée';
    case EnumEnquiryStatus.confirmed:
      return 'Confirmée';
  }
};
