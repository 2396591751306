import { formatDuration, intervalToDuration } from 'date-fns';
import { fr } from 'date-fns/locale';
import { EnumCrawlerUrlSource } from '../enums/crawler-url-source.enum';
import { EnumCrawlerUrlStatus } from '../enums/crawler-url-status.enum';
import { IStoreData } from './store-data.interface';
import { IAirline } from './airline.interface';
import { IAircraft } from './aircraft.interface';
import { IAirport } from './airport.interface';

export interface ICrawlerUrl extends IStoreData {
  url: string;
  delayValidInSeconds: number;
  source: EnumCrawlerUrlSource;
  nextDateToUpdate: Date;
  status: EnumCrawlerUrlStatus;
  dateStart: Date;
  dateEnd: Date;
  error: any;
  dataFetched: ICrawlerExtractedData | null;
}

export const getCrawlerUrlDuration = (crawlerUrl: ICrawlerUrl): string => {
  const dateEnd: Date = crawlerUrl.dateEnd ?? crawlerUrl.modified;

  return crawlerUrl.dateStart && dateEnd
    ? formatDuration(
        intervalToDuration({
          start: new Date(crawlerUrl.dateStart),
          end: dateEnd
        }),
        {
          locale: fr
        }
      )
    : null;
};

export interface ICrawlerExtractedData {
  type: EnumCrawlerExtractedDataType;
  urls?: string[];
  urlsCount?: number;
  airport?: IAirport | null;
  airline?: IAirline | null;
  aircraft?: IAircraft | null;
}

export enum EnumCrawlerExtractedDataType {
  acukwikAirportUrls = 'acukwikAirportUrls',
  acukwikAirportData = 'acukwikAirportData',
  airfleetsAirlineByAlphabetic = 'airfleetsAirlineByAlphabetic',
  airfleetsAirlineUrls = 'airfleetsAirlineUrls',
  airfleetsAirlineWithFlotte = 'airfleetsAirlineWithFlotte',
  airfleetsListAircraftUrls = 'airfleetsListAircraftUrls',
  airfleetsAircraft = 'airfleetsAircraft'
}
