import { IStoreData } from './store-data.interface';

export interface IUser extends IStoreData {
  userAuthUID: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  emailSignature: string;
  emailSignatureHtml: string;
  emailInCc: boolean;
  userGroupId: string;
  pipedriveId: number;
  goals: object;
  primaryColor: string;
  initials: string;
  displayInStatistics: boolean;
  isDisabled?: boolean;
  disableDate?: Date | null;
}

export const getUserFullname = (user: IUser | null): string => {
  return user ? user.firstname + ' ' + user.lastname : '';
};
