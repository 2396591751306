import { add, format } from 'date-fns';
import { EnumLanguage } from '../enums/language.enum';
import { IStoreData } from './store-data.interface';
import { EnumEnquiryType } from '../enums/enquiry-type.enum';

export enum EnumFlightBriefPassengerCivility {
  mr = 'mr',
  mme = 'mme'
}

export const getFlightBriefPassengerCivilityLabel = (
  civility: EnumFlightBriefPassengerCivility,
  language: EnumLanguage = EnumLanguage.en
): string => {
  if (language === EnumLanguage.fr) {
    switch (civility) {
      default:
        return civility;
      case EnumFlightBriefPassengerCivility.mr:
        return 'M.';
      case EnumFlightBriefPassengerCivility.mme:
        return 'Mme';
    }
  } else {
    switch (civility) {
      default:
        return civility;
      case EnumFlightBriefPassengerCivility.mr:
        return 'Mr.';
      case EnumFlightBriefPassengerCivility.mme:
        return 'Mrs';
    }
  }
};

export interface IFlightBriefTrip {
  id: string;
  date: string;
  flightNumber: string;
  departureTime: string;
  arrivalTime: string;
  flyTimeInHours: string;
  boardingMinutesBeforeDeparture: number;
  deliveryCargoHoursBeforeDeparture: number;
  airportDepartId: string;
  airportArrivalId: string;

  passengers: IFlightBriefPassengerInfo[];
  flightBriefCateringId: string | null;
  catering: string;
  luggages: string;
  luggageQty: number;
  luggagesWeight: number;
  luggageIsCustom: boolean;
  googleMapStaticUrl: string;
  isFerryFlight: boolean;
}

export interface IFlightBriefPassengerInfo {
  id: string;
  civility: EnumFlightBriefPassengerCivility;
  firstname: string;
  lastname: string;
}

export interface IFlightBriefAirportsAddressDetails {
  airportId: string;
  text: string;
  fboId: string;
}

export interface IFlightBrief extends IStoreData {
  title: string;
  enquiryId: string;
  versionNumber: number;
  language: EnumLanguage;
  isSent: boolean;
  sentDate: string;
  sentBy: string | null;
  sentByFirstname?: string;
  sentByLastname?: string;
  sentByInitials?: string;
  sentByFullname?: string;

  createdByFirstname?: string;
  createdByLastname?: string;
  createdByInitials?: string;
  createdByFullname?: string;

  trips: IFlightBriefTrip[];

  airlineId: string;
  airlineTitle: string;
  aircraftId: string;
  aircraftModelId: string;
  aircraftModelTitle: string;
  aircraftRegistration: string;
  aircraftHasToilet: boolean;
  smokingIsAllowed: boolean;
  displayAirlineOnFlightBrief: boolean;

  pilot: string;
  coPilot: string;
  cabinCrew: string;

  typeOfCargo: string;
  weightInKg: number;
  volumeInM3: number;
  nbPackages: number;
  awbNumber: string;
  customDeclaration: string;
  dgd: boolean;

  googleMapStaticUrl: string;
  imageOutsideUrl: string;
  imageOutsideZoomLevel: number;
  imageInsideUrl: string;
  imageInsideZoomLevel: number;

  comments: string;
  internalNote: string;

  airportsAddressDetails: IFlightBriefAirportsAddressDetails[];
}

export const getTimeBoardingBeforeDeparture = (
  trip: IFlightBriefTrip,
  enquiryType: EnumEnquiryType
): string => {
  const departureDatetime: Date = new Date(trip.date + ' ' + trip.departureTime);
  const maxBoardingDatetime: Date = add(
    departureDatetime,
    enquiryType === EnumEnquiryType.cargo
      ? {
          hours: trip.deliveryCargoHoursBeforeDeparture * -1
        }
      : {
          minutes: trip.boardingMinutesBeforeDeparture * -1
        }
  );

  return format(maxBoardingDatetime, 'HH:mm');
};

export const getPassengerLabel = (
  passenger: IFlightBriefPassengerInfo,
  language: EnumLanguage = EnumLanguage.en
): string => {
  return (
    getFlightBriefPassengerCivilityLabel(passenger.civility, language) +
    ' ' +
    passenger.lastname +
    ' ' +
    passenger.firstname
  );
};
