import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { RemoteService } from '../../services/remote.service';
import { IUser } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  user: IUser;
  loading: boolean = true;
  signingOut: boolean = false;

  constructor(private remoteService: RemoteService, private zone: NgZone, private router: Router) {
    this.remoteService.userObservable.subscribe((user: IUser) => {
      if (user) {
        this.user = user;

        this.loading = false;
      }
    });
  }

  ngOnInit(): void {}

  signOut(): void {
    this.signingOut = true;
    this.remoteService
      .signOut()
      .then(result => {
        setTimeout(() => {
          this.zone.run(() => {
            this.signingOut = false;
            this.router.navigate(['login']);
          });
        }, 1000);
      })
      .catch(err => {
        this.signingOut = false;
        console.log(err);
        alert('Error to sign out.');
      });
  }
}
