import { IStoreData } from './store-data.interface';

export interface IContact extends IStoreData {
  lastname: string;
  firstname: string;
  fonction: string;
  phone: string;
  mobile: string;
  fax: string;
  email: string;
}

export const getContactTitle = (contact: IContact): string => {
  const titleArray: Array<string> = [];

  const fullName: Array<string> = [];

  if (contact.firstname && contact.firstname !== '') {
    fullName.push(contact.firstname);
  }
  if (contact.lastname && contact.lastname !== '') {
    fullName.push(contact.lastname);
  }

  if (fullName.length) {
    titleArray.push(fullName.join(' '));
  }

  if (contact.fonction && contact.fonction !== '') {
    titleArray.push(contact.fonction);
  }

  if (!titleArray.length) {
    // No name or function, let's try with email or phone
    if (contact.email && contact.email !== '') {
      titleArray.push(contact.email);
    }

    if (contact.phone && contact.phone !== '') {
      titleArray.push(contact.phone);
    }

    if (contact.mobile && contact.mobile !== '') {
      titleArray.push(contact.mobile);
    }

    if (contact.fax && contact.fax !== '') {
      titleArray.push(contact.fax);
    }
  }

  return titleArray.length ? titleArray.join(' - ') : 'Contact sans nom';
};
