<breadcrumbs
    [items]="
        clientId
            ? [
                  {
                      title: 'Liste des clients',
                      path: '/admin/clients/list'
                  },
                  {
                      title: client ? client.name : '',
                      path: '/admin/clients/view/' + clientId
                  },
                  {
                      title: 'Ajout d\'une nouvelle demande',
                      path: '/admin/enquiry/add'
                  }
              ]
            : enquiryId
            ? [
                  {
                      title: enquiry ? getEnquiryBreadcrumbTitle(enquiry) : '',
                      path: '/admin/enquiry/view/' + enquiryId
                  },
                  {
                      title: 'Édition d\'une demande',
                      path: '/admin/enquiry/edit/' + enquiryId
                  }
              ]
            : [
                  {
                      title: 'Ajout d\'une nouvelle demande',
                      path: '/admin/enquiry/add'
                  }
              ]
    "
></breadcrumbs>

<form (submit)="submitForm()" [formGroup]="form" class="main-form">
    <div class="row">
        <div class="col-lg-3">
            <div class="card mb-4 text-center" *ngIf="enquiry">
                <div class="card-body" *ngIf="!editRefMode">
                    <h4 *ngIf="enquiry.refContractTitle">
                        <strong
                            [innerHtml]="'N° contrat <br>' + getRefTitleFromForm('refContract')"
                        ></strong>
                    </h4>
                    <h4
                        [innerHtml]="'N° demande <br>' + getRefTitleFromForm('refEnquiry')"
                        *ngIf="enquiry.refEnquiryTitle"
                    ></h4>

                    <button type="button" (click)="toggleRefMode()" class="btn btn-primary btn-sm">
                        Modifier
                    </button>
                </div>
                <div class="card-body" *ngIf="editRefMode" id="enquiry-ref-edit">
                    <fieldset *ngFor="let refField of refFields" class="mb-2">
                        <h4 *ngIf="refField === 'refEnquiry'">N° demande</h4>
                        <h4 *ngIf="refField === 'refContract'"><strong>N° contrat</strong></h4>

                        <div class="form-inline">
                            {{ form.get(refField + "Prefix").value }}

                            <select
                                class="form-control ms-2"
                                id="ref-enquiry-year"
                                [formControlName]="refField + 'Year'"
                                required
                                [class.is-invalid]="
                                    (form.touched || form.get(refField + 'Year').touched) &&
                                    form.get(refField + 'Year').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            >
                                <option
                                    [value]="year"
                                    *ngFor="let year of listYears"
                                    [innerText]="year"
                                ></option>
                            </select>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || form.get(refField + 'Year').touched) &&
                                        form.get(refField + 'Year').errors &&
                                        form.get(refField + 'Year').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>

                            .

                            <select
                                class="form-control"
                                id="ref-enquiry-month"
                                [formControlName]="refField + 'Month'"
                                required
                                [class.is-invalid]="
                                    (form.touched || form.get(refField + 'Month').touched) &&
                                    form.get(refField + 'Month').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            >
                                <option
                                    [value]="month"
                                    *ngFor="let month of listMonths"
                                    [innerText]="addZeroToDigit(month)"
                                ></option>
                            </select>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || form.get(refField + 'Month').touched) &&
                                        form.get(refField + 'Month').errors &&
                                        form.get(refField + 'Month').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>

                            <input
                                class="form-control"
                                id="ref-enquiry-number"
                                [formControlName]="refField + 'Number'"
                                type="number"
                                min="1"
                                [class.is-invalid]="
                                    (form.touched || form.get(refField + 'Number').touched) &&
                                    form.get(refField + 'Number').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || form.get(refField + 'Number').touched) &&
                                        form.get(refField + 'Number').errors &&
                                        form.get(refField + 'Number').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <button
                        type="button"
                        (click)="toggleRefMode()"
                        class="btn btn-outline-primary btn-sm"
                    >
                        Annuler
                    </button>
                </div>
            </div>

            <div class="card mb-4">
                <div class="card-body">
                    <div class="form-group">
                        <label for="clientId">Entreprise</label>
                        <pipedrive-search-organization-input
                            (setValueToFormControl)="setValueToFormControl($event)"
                            inputName="clientId"
                            [defaultValue]="form.value['clientId']"
                            [disabled]="form.disabled || sending"
                            [isInvalid]="
                                (form.touched || form.controls['clientId'].touched) &&
                                form.controls['clientId'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></pipedrive-search-organization-input>
                    </div>
                    <div class="text-center mb-4">
                        <a
                            class="btn btn-primary btn-sm"
                            [routerLink]="'/admin/clients/view/' + form.value.clientId"
                            target="_blank"
                            *ngIf="form.value.clientId"
                            [class.disabled]="form.disabled || sending"
                            >Ouvrir dans Pipedrive</a
                        >
                    </div>
                    <div class="form-group" *ngIf="form.value.clientId">
                        <label for="contactId">Contact</label>
                        <pipedrive-search-person-input
                            (setValueToFormControl)="setValueToFormControl($event)"
                            inputName="contactId"
                            [organizationId]="form.value.clientId"
                            [defaultValue]="form.value['contactId']"
                            [disabled]="form.disabled || sending"
                            [isInvalid]="
                                (form.touched || form.controls['contactId'].touched) &&
                                form.controls['contactId'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></pipedrive-search-person-input>
                    </div>
                    <div class="text-center mb-4">
                        <a
                            class="btn btn-primary btn-sm"
                            [href]="
                                'https://artheauaviation2.pipedrive.com/person/' +
                                form.value.contactId
                            "
                            target="_blank"
                            *ngIf="form.value.contactId"
                            [class.disabled]="form.disabled || sending"
                            >Ouvrir dans Pipedrive</a
                        ><br />
                    </div>

                    <div class="form-group mt-4">
                        <label for="comments"
                            >Commentaire sur la demande, besoins particuliers, attentes du client
                            ...</label
                        >
                        <textarea
                            formControlName="comments"
                            class="form-control"
                            id="comments"
                            rows="5"
                            [class.is-invalid]="
                                (form.touched || form.controls['comments'].touched) &&
                                form.controls['comments'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></textarea>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['comments'].touched) &&
                                    form.controls['comments'].errors &&
                                    form.controls['comments'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group mt-4">
                        <label for="noteForAirlines"
                            >Note à destination des compagnies aériennes</label
                        >
                        <textarea
                            formControlName="noteForAirlines"
                            class="form-control"
                            id="noteForAirlines"
                            rows="5"
                            [class.is-invalid]="
                                (form.touched || form.controls['noteForAirlines'].touched) &&
                                form.controls['noteForAirlines'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></textarea>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['noteForAirlines'].touched) &&
                                    form.controls['noteForAirlines'].errors &&
                                    form.controls['noteForAirlines'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <label for="language"
                        >Document attachés aux emails pour les compagnies aériennes</label
                    >
                    <ul class="list-group" formArrayName="emailAttachments">
                        <li
                            class="list-group-item"
                            *ngFor="
                                let emailAttachment of form.value.emailAttachments;
                                let i = index
                            "
                            [formGroupName]="i"
                        >
                            <button
                                type="button"
                                class="btn btn-danger btn-sm float-end"
                                (click)="deleteEmailAttachment(emailAttachment.id)"
                            >
                                X
                            </button>
                            <a
                                target="_blank"
                                [href]="emailAttachment.url"
                                [innerText]="
                                    emailAttachment.name +
                                    ' (' +
                                    formatBytes(emailAttachment.size) +
                                    ')'
                                "
                            ></a>
                            <label for="language">Pour quelle langue ?</label>
                            <select
                                class="form-control"
                                id="language"
                                formControlName="language"
                                [class.is-invalid]="
                                    (form.touched ||
                                        form
                                            .get('emailAttachments')
                                            .get(i.toString())
                                            .get('language').touched) &&
                                    form.get('emailAttachments').get(i.toString()).get('language')
                                        .status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            >
                                <option value="">Toutes</option>
                                <option
                                    [value]="language"
                                    *ngFor="let language of getLanguages()"
                                    [innerHtml]="getLanguageLabel(language) + ' uniquement'"
                                ></option>
                            </select>
                        </li>
                    </ul>

                    <button
                        type="button"
                        class="btn btn-primary btn-block"
                        (click)="openModalUpload()"
                    >
                        + Ajouter un document
                    </button>

                    <div class="form-check mt-2 mb-2" *ngIf="!enquiryId">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="will-create-pipedrive-deal"
                            [(ngModel)]="willCreatePipedriveDeal"
                            [ngModelOptions]="{ standalone: true }"
                        />
                        <label class="form-check-label" for="will-create-pipedrive-deal"
                            >Créer une offre dans Pipedrive automatiquement</label
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg" *ngIf="form.value.clientId && form.value.contactId">
            <div class="row">
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label for="type">Nature de la demande</label>
                        <select
                            class="form-select"
                            id="type"
                            formControlName="type"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['type'].touched) &&
                                form.controls['type'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (change)="setTypeFields()"
                        >
                            <option
                                [value]="enquiryType"
                                *ngFor="let enquiryType of getEnquiryTypes()"
                                [innerHtml]="getEnumEnquiryTypeLabel(enquiryType)"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['type'].touched) &&
                                    form.controls['type'].errors &&
                                    form.controls['type'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label for="receivedBy">Reçu par</label>
                        <select
                            class="form-select"
                            id="receivedBy"
                            formControlName="receivedBy"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['receivedBy'].touched) &&
                                form.controls['receivedBy'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="user.value"
                                *ngFor="let user of usersList"
                                [innerHtml]="user.title"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['receivedBy'].touched) &&
                                    form.controls['receivedBy'].errors &&
                                    form.controls['receivedBy'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="form-group">
                        <label for="processedBy">Traité par</label>
                        <select
                            class="form-select"
                            id="processedBy"
                            formControlName="processedBy"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['processedBy'].touched) &&
                                form.controls['processedBy'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="user.value"
                                *ngFor="let user of usersList"
                                [innerHtml]="user.title"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['processedBy'].touched) &&
                                    form.controls['processedBy'].errors &&
                                    form.controls['processedBy'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="form && form.value.type">
                <div formArrayName="itineraries" class="mb-4">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li
                            class="nav-item"
                            role="presentation"
                            *ngFor="
                                let option of form.get('itineraries')['controls'];
                                let i = index
                            "
                        >
                            <button
                                type="button"
                                class="nav-link"
                                [class.active]="i == currentItineraryIndex ? 'active' : ''"
                                id="option-{{ i }}"
                                data-bs-toggle="tab"
                                role="tab"
                                [attr.aria-controls]="'option-' + i"
                                [attr.aria-selected]="i == currentItineraryIndex ? 'true' : ''"
                                (click)="selectItinerary(i)"
                                [formGroupName]="i"
                            >
                                <input
                                    type="text"
                                    formControlName="title"
                                    class="form-control"
                                    required
                                    [class.is-invalid]="
                                        (form.touched ||
                                            getItirineraryFormGroup(i).get('title').touched) &&
                                        getItirineraryFormGroup(i).get('title').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <span
                                    (click)="deleteItinerary(i)"
                                    class="btn-delete"
                                    *ngIf="
                                        i == currentItineraryIndex &&
                                        form.get('itineraries')['controls'].length > 1
                                    "
                                    ><img
                                        [src]="rootUrl + 'assets/img/icon-close-circled.svg'"
                                        alt="Close"
                                        height="20"
                                /></span>
                            </button>
                        </li>
                        <li class="nav-item add-itinerary" role="presentation">
                            <button
                                type="button"
                                class="nav-link"
                                (click)="addItineraryAndSelect()"
                            >
                                +
                            </button>
                        </li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div
                            class="tab-pane fade"
                            [class.show]="i == currentItineraryIndex ? 'active' : ''"
                            [class.active]="i == currentItineraryIndex ? 'active' : ''"
                            [id]="'option-' + i"
                            role="tabpanel"
                            [attr.aria-labelledby]="'option-' + i"
                            *ngFor="
                                let option of form.get('itineraries')['controls'];
                                let i = index
                            "
                            [formGroupName]="i"
                        >
                            <div
                                class="btn-group btn-group-toggle mt-4 mb-4"
                                role="group"
                                data-bs-toggle="buttons"
                            >
                                <ng-container *ngFor="let itineraryType of getItineraryTypes()">
                                    <input
                                        type="radio"
                                        name="itineraries"
                                        id="one-way"
                                        class="btn-check"
                                        [value]="itineraryType"
                                        [checked]="
                                            form && form.value.itineraries[i].type == itineraryType
                                        "
                                    />

                                    <label
                                        class="btn btn-primary"
                                        [class.active]="
                                            form && form.value.itineraries[i].type == itineraryType
                                        "
                                        (click)="updateCurrentType(i, itineraryType)"
                                    >
                                        <img
                                            [src]="
                                                rootUrl + getEnumItineraryTypeIconUrl(itineraryType)
                                            "
                                            height="16"
                                        />
                                        {{ getEnumItineraryTypeLabel(itineraryType) }}
                                    </label>
                                </ng-container>
                            </div>

                            <div formArrayName="trips">
                                <div
                                    class="row justify-content-center trip"
                                    [formGroupName]="j"
                                    *ngFor="
                                        let trip of getItirineraryFormGroup(i).get('trips')[
                                            'controls'
                                        ];
                                        let j = index
                                    "
                                >
                                    <div class="col-md-6 col-xl">
                                        <div class="form-group">
                                            <label
                                                for="date"
                                                *ngIf="
                                                    form.value.itineraries[i].type ==
                                                    EnumItineraryType.oneWay
                                                "
                                                >Date</label
                                            >
                                            <label
                                                for="date"
                                                *ngIf="
                                                    form.value.itineraries[i].type ===
                                                        EnumItineraryType.roundTrip && j === 0
                                                "
                                                >Date de départ</label
                                            >
                                            <label
                                                for="date"
                                                *ngIf="
                                                    form.value.itineraries[i].type ===
                                                        EnumItineraryType.roundTrip && j === 1
                                                "
                                                >Date de retour</label
                                            >
                                            <label
                                                for="date"
                                                *ngIf="
                                                    form.value.itineraries[i].type ===
                                                    EnumItineraryType.multipleDestinations
                                                "
                                                >Date du vol {{ j + 1 }}</label
                                            >

                                            <input
                                                class="form-control"
                                                id="date"
                                                formControlName="date"
                                                type="date"
                                                required
                                                [max]="
                                                    form.value.itineraries[i].trips[j + 1] &&
                                                    form.value.itineraries[i].trips[j + 1].date
                                                        ? form.value.itineraries[i].trips[j + 1]
                                                              .date
                                                        : ''
                                                "
                                                [min]="
                                                    j > 0 &&
                                                    form.value.itineraries[i].trips[j - 1].date
                                                        ? form.value.itineraries[i].trips[j - 1]
                                                              .date
                                                        : '2000-01-01'
                                                "
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get('date')
                                                            .touched) &&
                                                    getTripFormGroup(i, j).get('date').status ==
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripFormGroup(i, j).get('date')
                                                                .touched) &&
                                                        getTripFormGroup(i, j).get('date').errors &&
                                                        getTripFormGroup(i, j).get('date').errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripFormGroup(i, j).get('date')
                                                                .touched) &&
                                                        getTripFormGroup(i, j).get('date').errors &&
                                                        getTripFormGroup(i, j).get('date').errors[
                                                            'dateBelowMinimum'
                                                        ]
                                                    "
                                                >
                                                    La date doit être supérieure à
                                                    {{
                                                        getTripFormGroup(i, j).get("date").errors[
                                                            "dateMinimum"
                                                        ] | date : "dd/MM/yyyy"
                                                    }}.
                                                </div>
                                            </div>

                                            <div
                                                *ngIf="
                                                    form.value.itineraries[i].type ==
                                                        EnumItineraryType.roundTrip &&
                                                    j === 1 &&
                                                    form.value.itineraries[i].trips[j].date &&
                                                    form.value.itineraries[i].trips[j - 1].date ===
                                                        form.value.itineraries[i].trips[j].date
                                                "
                                                class="text-warning"
                                            >
                                                <img
                                                    [src]="
                                                        rootUrl +
                                                        'assets/img/icon-warning-orange.svg'
                                                    "
                                                    width="24"
                                                    alt="Attention"
                                                />
                                                Aller/Retour ont lieu le même jour
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-md-6 col-xl"
                                        *ngIf="form.value.type !== EnumEnquiryType.cargo"
                                    >
                                        <div class="form-group">
                                            <label
                                                for="end"
                                                *ngIf="
                                                    form.value.itineraries[i].type ==
                                                    EnumItineraryType.oneWay
                                                "
                                                >Heure (LT)</label
                                            >
                                            <label
                                                for="end"
                                                *ngIf="
                                                    form &&
                                                    form.value.itineraries[i].type ==
                                                        EnumItineraryType.roundTrip &&
                                                    j == 0
                                                "
                                                >Heure de départ (LT)</label
                                            >
                                            <label
                                                for="end"
                                                *ngIf="
                                                    form.value.itineraries[i].type ==
                                                        EnumItineraryType.roundTrip && j == 1
                                                "
                                                >Heure de retour (LT)</label
                                            >
                                            <label
                                                for="end"
                                                *ngIf="
                                                    form.value.itineraries[i].type ==
                                                    EnumItineraryType.multipleDestinations
                                                "
                                                >Heure (LT)</label
                                            >

                                            <input
                                                class="form-control"
                                                id="time"
                                                formControlName="time"
                                                type="time"
                                                required
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get('time')
                                                            .touched) &&
                                                    getTripFormGroup(i, j).get('time').status ==
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripFormGroup(i, j).get('time')
                                                                .touched) &&
                                                        getTripFormGroup(i, j).get('time').errors &&
                                                        getTripFormGroup(i, j).get('time').errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-xl">
                                        <div class="form-group">
                                            <label
                                                for="airportDepart"
                                                *ngIf="
                                                    !(
                                                        form &&
                                                        form.value.itineraries[i].type ==
                                                            EnumItineraryType.roundTrip &&
                                                        j > 0
                                                    )
                                                "
                                                >Départ</label
                                            >
                                            <label
                                                for="airportDepart"
                                                *ngIf="
                                                    form &&
                                                    form.value.itineraries[i].type ==
                                                        EnumItineraryType.roundTrip &&
                                                    j > 0
                                                "
                                                >Arrivée</label
                                            >
                                            <app-airport-picker
                                                [inputName]="
                                                    'itineraries.' +
                                                    i +
                                                    '.trips.' +
                                                    j +
                                                    '.airportDepart'
                                                "
                                                [defaultValue]="
                                                    form &&
                                                    form.value.itineraries[i].trips[j][
                                                        'airportDepart'
                                                    ]
                                                "
                                                [disabled]="form.disabled || sending"
                                                [isInvalid]="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get('airportDepart')
                                                            .touched) &&
                                                    getTripFormGroup(i, j).get('airportDepart')
                                                        .status == 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                                (setValueToFormControl)="
                                                    setValueToFormControl($event)
                                                "
                                            >
                                            </app-airport-picker>

                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get('airportDepart')
                                                            .touched) &&
                                                    getTripFormGroup(i, j).get('airportDepart')
                                                        .errors &&
                                                    getTripFormGroup(i, j).get('airportDepart')
                                                        .errors['required']
                                                "
                                                class="text-danger"
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                        <div class="form-check mb-2">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                [id]="i + '-' + j + 'has-fuel-stop-over'"
                                                formControlName="hasFuelStopOver"
                                                (change)="updatedFuelStopOver(i, j)"
                                            />
                                            <label
                                                class="form-check-label"
                                                [for]="i + '-' + j + 'has-fuel-stop-over'"
                                                >A un arrêt carburant</label
                                            >
                                        </div>
                                        <div
                                            class="form-group has-borders"
                                            *ngIf="
                                                form.value.itineraries[i].trips[j].hasFuelStopOver
                                            "
                                        >
                                            <label for="commercialStopOverAirport"
                                                >Aéroport arrêt carburant</label
                                            >
                                            <app-airport-picker
                                                [inputName]="
                                                    'itineraries.' +
                                                    i +
                                                    '.trips.' +
                                                    j +
                                                    '.fuelStopOverAirport'
                                                "
                                                [defaultValue]="
                                                    form &&
                                                    form.value.itineraries[i].trips[j][
                                                        'fuelStopOverAirport'
                                                    ]
                                                "
                                                [disabled]="form.disabled || sending"
                                                [isInvalid]="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get(
                                                            'fuelStopOverAirport'
                                                        ).touched) &&
                                                    getTripFormGroup(i, j).get(
                                                        'fuelStopOverAirport'
                                                    ).status == 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                                (setValueToFormControl)="
                                                    setValueToFormControl($event)
                                                "
                                            >
                                            </app-airport-picker>
                                            <p class="text-muted">Laissez vide si non précisé</p>

                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get(
                                                            'fuelStopOverAirport'
                                                        ).touched) &&
                                                    getTripFormGroup(i, j).get(
                                                        'fuelStopOverAirport'
                                                    ).errors &&
                                                    getTripFormGroup(i, j).get(
                                                        'fuelStopOverAirport'
                                                    ).errors['required']
                                                "
                                                class="text-danger"
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                        <div class="form-check mb-2">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                [id]="i + '-' + j + 'has-commercial-stop-over'"
                                                formControlName="hasCommercialStopOver"
                                                (change)="updatedCommercialStopOver(i, j)"
                                            />
                                            <label
                                                class="form-check-label"
                                                [for]="i + '-' + j + 'has-commercial-stop-over'"
                                                >A une escale commerciale</label
                                            >
                                        </div>
                                        <div
                                            class="form-group has-borders"
                                            *ngIf="
                                                form.value.itineraries[i].trips[j]
                                                    .hasCommercialStopOver
                                            "
                                        >
                                            <label for="commercialStopOverAirport"
                                                >Aéroport escale commerciale</label
                                            >
                                            <app-airport-picker
                                                [inputName]="
                                                    'itineraries.' +
                                                    i +
                                                    '.trips.' +
                                                    j +
                                                    '.commercialStopOverAirport'
                                                "
                                                [defaultValue]="
                                                    form &&
                                                    form.value.itineraries[i].trips[j][
                                                        'commercialStopOverAirport'
                                                    ]
                                                "
                                                [disabled]="form.disabled || sending"
                                                [isInvalid]="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get(
                                                            'commercialStopOverAirport'
                                                        ).touched) &&
                                                    getTripFormGroup(i, j).get(
                                                        'commercialStopOverAirport'
                                                    ).status == 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                                (setValueToFormControl)="
                                                    setValueToFormControl($event)
                                                "
                                            >
                                            </app-airport-picker>
                                            <p class="text-muted">Laissez vide si non précisé</p>

                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get(
                                                            'commercialStopOverAirport'
                                                        ).touched) &&
                                                    getTripFormGroup(i, j).get(
                                                        'commercialStopOverAirport'
                                                    ).errors &&
                                                    getTripFormGroup(i, j).get(
                                                        'commercialStopOverAirport'
                                                    ).errors['required']
                                                "
                                                class="text-danger"
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                        <div
                                            class="form-group d-none"
                                            *ngIf="form.value.type !== EnumEnquiryType.sport"
                                        >
                                            <label for="airportDepartTax"
                                                >Taxes d'aéroport
                                                <span class="text-muted">{{
                                                    form.value.itineraries[i].trips[j].airportDepart
                                                }}</span></label
                                            >
                                            <input
                                                class="form-control"
                                                id="airportDepartTax"
                                                formControlName="airportDepartTax"
                                                type="number"
                                                min="0"
                                                required
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get(
                                                            'airportDepartTax'
                                                        ).touched) &&
                                                    getTripFormGroup(i, j).get('airportDepartTax')
                                                        .status == 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                                (keyup)="updateAirportDestination(i)"
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripFormGroup(i, j).get(
                                                                'airportDepartTax'
                                                            ).touched) &&
                                                        getTripFormGroup(i, j).get(
                                                            'airportDepartTax'
                                                        ).errors &&
                                                        getTripFormGroup(i, j).get(
                                                            'airportDepartTax'
                                                        ).errors['required']
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-md-6 col-xl"
                                        *ngIf="
                                            form &&
                                            form.value.itineraries[i].type !==
                                                EnumItineraryType.roundTrip
                                        "
                                    >
                                        <div class="form-group">
                                            <label for="airportDestination">Arrivée</label>
                                            <app-airport-picker
                                                [inputName]="
                                                    'itineraries.' +
                                                    i +
                                                    '.trips.' +
                                                    j +
                                                    '.airportDestination'
                                                "
                                                [defaultValue]="
                                                    form &&
                                                    form.value.itineraries[i].trips[j][
                                                        'airportDestination'
                                                    ]
                                                "
                                                [disabled]="form.disabled || sending"
                                                [isInvalid]="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get(
                                                            'airportDestination'
                                                        ).touched) &&
                                                    getTripFormGroup(i, j).get('airportDestination')
                                                        .status == 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                                (setValueToFormControl)="
                                                    setValueToFormControl($event)
                                                "
                                            >
                                            </app-airport-picker>

                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get(
                                                            'airportDestination'
                                                        ).touched) &&
                                                    getTripFormGroup(i, j).get('airportDestination')
                                                        .errors &&
                                                    getTripFormGroup(i, j).get('airportDestination')
                                                        .errors['required']
                                                "
                                                class="text-danger"
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                        <div
                                            class="form-group d-none"
                                            *ngIf="form.value.type !== EnumEnquiryType.sport"
                                        >
                                            <label for="airportDestinationTax"
                                                >Taxes d'aéroport
                                                <span class="text-muted">{{
                                                    form.value.itineraries[i].trips[j]
                                                        .airportDestination
                                                }}</span></label
                                            >
                                            <input
                                                class="form-control"
                                                id="airportDestinationTax"
                                                formControlName="airportDestinationTax"
                                                type="number"
                                                min="0"
                                                required
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get(
                                                            'airportDestinationTax'
                                                        ).touched) &&
                                                    getTripFormGroup(i, j).get(
                                                        'airportDestinationTax'
                                                    ).status == 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                                (keyup)="updateAirportDestination(i)"
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripFormGroup(i, j).get(
                                                                'airportDestinationTax'
                                                            ).touched) &&
                                                        getTripFormGroup(i, j).get(
                                                            'airportDestinationTax'
                                                        ).errors &&
                                                        getTripFormGroup(i, j).get(
                                                            'airportDestinationTax'
                                                        ).errors['required']
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-md-5 col-xl"
                                        *ngIf="form.value.type !== EnumEnquiryType.cargo"
                                    >
                                        <div class="form-group">
                                            <label for="passengersTotal">Passagers</label>
                                            <input
                                                class="form-control"
                                                id="passengersTotal"
                                                formControlName="passengersTotal"
                                                type="number"
                                                min="1"
                                                required
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get(
                                                            'passengersTotal'
                                                        ).touched) &&
                                                    getTripFormGroup(i, j).get('passengersTotal')
                                                        .status == 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripFormGroup(i, j).get(
                                                                'passengersTotal'
                                                            ).touched) &&
                                                        getTripFormGroup(i, j).get(
                                                            'passengersTotal'
                                                        ).errors &&
                                                        getTripFormGroup(i, j).get(
                                                            'passengersTotal'
                                                        ).errors['required']
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="form-group"
                                            *ngIf="hasBusinessPassengerField(form.value.type)"
                                        >
                                            <label for="passengersBusiness">dont business</label>
                                            <input
                                                class="form-control"
                                                id="passengersBusiness"
                                                formControlName="passengersBusiness"
                                                required
                                                type="number"
                                                min="0"
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get(
                                                            'passengersBusiness'
                                                        ).touched) &&
                                                    getTripFormGroup(i, j).get('passengersBusiness')
                                                        .status == 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripFormGroup(i, j).get(
                                                                'passengersBusiness'
                                                            ).touched) &&
                                                        getTripFormGroup(i, j).get(
                                                            'passengersBusiness'
                                                        ).errors &&
                                                        getTripFormGroup(i, j).get(
                                                            'passengersBusiness'
                                                        ).errors['required']
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="form-group"
                                            *ngIf="
                                                form.value.itineraries[i].trips[j]
                                                    .hasCommercialStopOver
                                            "
                                        >
                                            <fieldset>
                                                <legend>
                                                    Passagers EC
                                                    <span
                                                        class="float-end"
                                                        [innerText]="
                                                            form.value.itineraries[i].trips[j]
                                                                .passengersCommercialStop
                                                        "
                                                    ></span>
                                                </legend>

                                                <div class="form-group">
                                                    <label for="passengersCommercialStopEmbark"
                                                        >Nombre qui embarquent</label
                                                    >
                                                    <input
                                                        (keyup)="passengersCommercialStop(i, j)"
                                                        class="form-control"
                                                        id="passengersCommercialStopEmbark"
                                                        formControlName="passengersCommercialStopEmbark"
                                                        type="number"
                                                        [class.is-invalid]="
                                                            (form.touched ||
                                                                getTripFormGroup(i, j).get(
                                                                    'passengersCommercialStopEmbark'
                                                                ).touched) &&
                                                            getTripFormGroup(i, j).get(
                                                                'passengersCommercialStopEmbark'
                                                            ).status == 'INVALID'
                                                                ? 'danger'
                                                                : ''
                                                        "
                                                    />
                                                </div>

                                                <div class="form-group">
                                                    <label for="passengersCommercialStopDisembark"
                                                        >Nombre qui débarquent</label
                                                    >
                                                    <input
                                                        (keyup)="passengersCommercialStop(i, j)"
                                                        class="form-control"
                                                        id="passengersCommercialStopDisembark"
                                                        formControlName="passengersCommercialStopDisembark"
                                                        type="number"
                                                        [class.is-invalid]="
                                                            (form.touched ||
                                                                getTripFormGroup(i, j).get(
                                                                    'passengersCommercialStopDisembark'
                                                                ).touched) &&
                                                            getTripFormGroup(i, j).get(
                                                                'passengersCommercialStopDisembark'
                                                            ).status == 'INVALID'
                                                                ? 'danger'
                                                                : ''
                                                        "
                                                    />
                                                </div>
                                            </fieldset>

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripFormGroup(i, j).get(
                                                                'passengersCommercialStop'
                                                            ).touched) &&
                                                        getTripFormGroup(i, j).get(
                                                            'passengersCommercialStop'
                                                        ).errors &&
                                                        getTripFormGroup(i, j).get(
                                                            'passengersCommercialStop'
                                                        ).errors['required']
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-md-5 col-xl"
                                        *ngIf="form.value.type === EnumEnquiryType.cargo"
                                    >
                                        <div class="form-group">
                                            <label for="weight">Poids (kg)</label>
                                            <input
                                                class="form-control"
                                                id="weight"
                                                formControlName="weight"
                                                type="number"
                                                min="1"
                                                required
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get('weight')
                                                            .touched) &&
                                                    getTripFormGroup(i, j).get('weight').status ==
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripFormGroup(i, j).get('weight')
                                                                .touched) &&
                                                        getTripFormGroup(i, j).get('weight')
                                                            .errors &&
                                                        getTripFormGroup(i, j).get('weight').errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-md-5 col-xl"
                                        *ngIf="form.value.type === EnumEnquiryType.cargo"
                                    >
                                        <div class="form-group">
                                            <label for="volume">Volume (m<sup>3</sup>)</label>
                                            <input
                                                class="form-control"
                                                id="volume"
                                                formControlName="volume"
                                                type="number"
                                                min="1"
                                                required
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get('volume')
                                                            .touched) &&
                                                    getTripFormGroup(i, j).get('volume').status ==
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripFormGroup(i, j).get('volume')
                                                                .touched) &&
                                                        getTripFormGroup(i, j).get('volume')
                                                            .errors &&
                                                        getTripFormGroup(i, j).get('volume').errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-md-5 col-xl"
                                        *ngIf="form.value.type === EnumEnquiryType.cargo"
                                    >
                                        <div class="form-group">
                                            <label for="natureFret">Nature du fret</label>
                                            <input
                                                class="form-control"
                                                id="natureFret"
                                                formControlName="natureFret"
                                                type="text"
                                                required
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get('natureFret')
                                                            .touched) &&
                                                    getTripFormGroup(i, j).get('natureFret')
                                                        .status == 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripFormGroup(i, j).get('natureFret')
                                                                .touched) &&
                                                        getTripFormGroup(i, j).get('natureFret')
                                                            .errors &&
                                                        getTripFormGroup(i, j).get('natureFret')
                                                            .errors['required']
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-md-5 col-xl"
                                        *ngIf="form.value.type === EnumEnquiryType.cargo"
                                    >
                                        <div class="form-group">
                                            <label for="dimensions">Dimensions</label>
                                            <input
                                                class="form-control"
                                                id="dimensions"
                                                formControlName="dimensions"
                                                type="text"
                                                required
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        getTripFormGroup(i, j).get('dimensions')
                                                            .touched) &&
                                                    getTripFormGroup(i, j).get('dimensions')
                                                        .status == 'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getTripFormGroup(i, j).get('dimensions')
                                                                .touched) &&
                                                        getTripFormGroup(i, j).get('dimensions')
                                                            .errors &&
                                                        getTripFormGroup(i, j).get('dimensions')
                                                            .errors['required']
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-md-1 col-xl-1 d-flex align-items-end"
                                        *ngIf="
                                            form &&
                                            form.value.itineraries[i].type ==
                                                EnumItineraryType.multipleDestinations
                                        "
                                    >
                                        <button
                                            type="button"
                                            (click)="deleteTrip(i, j)"
                                            class="btn btn-danger float-end"
                                            [disabled]="form.disabled || sending"
                                            *ngIf="
                                                form &&
                                                form.value.itineraries[i].type ==
                                                    EnumItineraryType.multipleDestinations &&
                                                j > 1
                                            "
                                        >
                                            X
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="text-center"
                                *ngIf="
                                    form &&
                                    form.value.itineraries[i].type ==
                                        EnumItineraryType.multipleDestinations
                                "
                            >
                                <button type="button" class="btn btn-primary" (click)="addTrip(i)">
                                    + nouvelle destination
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <h4 class="text-center mb-4">Pré-Sélection compagnies</h4>
                <div class="row">
                    <div
                        class="col-md-6 col-lg-3"
                        *ngIf="doesEnquiryPrefillAirlinesSelection(form.value.type)"
                    >
                        <div class="form-group">
                            <label for="geographicalAreasCode">Zone géographique</label>
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    id="worldwide"
                                    class="form-check-input"
                                    [checked]="filterWorldwide"
                                    (click)="toggleWorldwide()"
                                />
                                <label for="worldwide">Monde entier</label>
                            </div>
                            <div
                                class="form-check"
                                *ngFor="let geographicalArea of getContinentCodes()"
                            >
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    [attr.id]="geographicalArea"
                                    [checked]="
                                        form.value.geographicalAreasCode.indexOf(
                                            geographicalArea
                                        ) !== -1
                                    "
                                    (click)="
                                        toggleCheckboxField(
                                            'geographicalAreasCode',
                                            geographicalArea
                                        )
                                    "
                                />
                                <label
                                    class="form-check-label"
                                    [attr.for]="geographicalArea"
                                    [innerHtml]="getContinentLabel(geographicalArea)"
                                ></label>
                            </div>

                            <div
                                *ngIf="
                                    form.controls['geographicalAreasCode'].errors &&
                                    form.controls['geographicalAreasCode'].errors['required']
                                "
                                class="text-danger"
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-9">
                        <div class="row justify-content-center">
                            <div
                                class="col-md-6 col-lg-3"
                                *ngIf="form.value.type !== EnumEnquiryType.cargo"
                            >
                                <div class="form-group">
                                    <label for="capacityMin">Capacité Min</label>
                                    <input
                                        class="form-control"
                                        id="capacityMin"
                                        formControlName="capacityMin"
                                        type="number"
                                        min="0"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form.controls['capacityMin'].touched) &&
                                            form.controls['capacityMin'].status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.controls['capacityMin'].touched) &&
                                                form.controls['capacityMin'].errors &&
                                                form.controls['capacityMin'].errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-md-6 col-lg-3"
                                *ngIf="form.value.type !== EnumEnquiryType.cargo"
                            >
                                <div class="form-group">
                                    <label for="capacityMax">Capacité Max</label>
                                    <input
                                        class="form-control"
                                        id="capacityMax"
                                        formControlName="capacityMax"
                                        type="number"
                                        min="0"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form.controls['capacityMax'].touched) &&
                                            form.controls['capacityMax'].status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.controls['capacityMax'].touched) &&
                                                form.controls['capacityMax'].errors &&
                                                form.controls['capacityMax'].errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-md-6 col-lg-3"
                                *ngIf="form.value.type === EnumEnquiryType.cargo"
                            >
                                <div class="form-group">
                                    <label for="weightMin">Poids Min</label>
                                    <input
                                        class="form-control"
                                        id="weightMin"
                                        formControlName="weightMin"
                                        type="number"
                                        min="0"
                                        [class.is-invalid]="
                                            (form.touched || form.controls['weightMin'].touched) &&
                                            form.controls['weightMin'].status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.controls['weightMin'].touched) &&
                                                form.controls['weightMin'].errors &&
                                                form.controls['weightMin'].errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-md-6 col-lg-3"
                                *ngIf="form.value.type === EnumEnquiryType.cargo"
                            >
                                <div class="form-group">
                                    <label for="weightMax">Poids Max</label>
                                    <input
                                        class="form-control"
                                        id="weightMax"
                                        formControlName="weightMax"
                                        type="number"
                                        min="0"
                                        [class.is-invalid]="
                                            (form.touched || form.controls['weightMax'].touched) &&
                                            form.controls['weightMax'].status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.controls['weightMax'].touched) &&
                                                form.controls['weightMax'].errors &&
                                                form.controls['weightMax'].errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-md-6 col-lg-3"
                                *ngIf="form.value.type === EnumEnquiryType.cargo"
                            >
                                <div class="form-group">
                                    <label for="volumeMin">Volume Min</label>
                                    <input
                                        class="form-control"
                                        id="volumeMin"
                                        formControlName="volumeMin"
                                        type="number"
                                        min="0"
                                        [class.is-invalid]="
                                            (form.touched || form.controls['volumeMin'].touched) &&
                                            form.controls['volumeMin'].status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.controls['volumeMin'].touched) &&
                                                form.controls['volumeMin'].errors &&
                                                form.controls['volumeMin'].errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-md-6 col-lg-3"
                                *ngIf="form.value.type === EnumEnquiryType.cargo"
                            >
                                <div class="form-group">
                                    <label for="volumeMax">Volume Max</label>
                                    <input
                                        class="form-control"
                                        id="volumeMax"
                                        formControlName="volumeMax"
                                        type="number"
                                        min="0"
                                        [class.is-invalid]="
                                            (form.touched || form.controls['volumeMax'].touched) &&
                                            form.controls['volumeMax'].status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.controls['volumeMax'].touched) &&
                                                form.controls['volumeMax'].errors &&
                                                form.controls['volumeMax'].errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-md-6 col-lg-3"
                                *ngIf="
                                    form.value.type !== EnumEnquiryType.helico &&
                                    doesEnquiryPrefillAirlinesSelection(form.value.type)
                                "
                            >
                                <div class="form-group">
                                    <label for="filterBy">Filtré par</label>
                                    <select
                                        class="form-select"
                                        id="filterBy"
                                        formControlName="filterBy"
                                        required
                                        [class.is-invalid]="
                                            (form.touched || form.controls['filterBy'].touched) &&
                                            form.controls['filterBy'].status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                        (change)="updateFilterBy()"
                                    >
                                        <option
                                            [value]="filterBy"
                                            *ngFor="let filterBy of getEnquiryPrefilterAirlines()"
                                            [innerHtml]="
                                                getEnumEnquiryPrefilterAirlineLabel(filterBy)
                                            "
                                        ></option>
                                    </select>

                                    <div
                                        *ngIf="
                                            form.controls['filterBy'].errors &&
                                            form.controls['filterBy'].errors['required']
                                        "
                                        class="text-danger"
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="form.value.filterBy === 'target'">
                                    <label for="target">Cible</label>
                                    <div
                                        class="form-check"
                                        *ngFor="let target of getEnquiryTargets()"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            [attr.id]="target"
                                            [checked]="form.value.targets.includes(target)"
                                            (click)="toggleCheckboxField('targets', target)"
                                        />
                                        <label
                                            class="form-check-label"
                                            [attr.for]="target"
                                            [innerText]="getEnquiryTargetLabel(target)"
                                        ></label>
                                    </div>

                                    <div
                                        *ngIf="
                                            form.controls['targets'].errors &&
                                            form.controls['targets'].errors['required']
                                        "
                                        class="text-danger"
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>

                                <div
                                    class="form-group"
                                    *ngIf="form.value.filterBy === 'aircraftModel'"
                                >
                                    <label for="aircraftModelId">Modèle</label>
                                    <select
                                        class="form-select"
                                        id="aircraftModelId"
                                        formControlName="aircraftModelId"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                form.controls['aircraftModelId'].touched) &&
                                            form.controls['aircraftModelId'].status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    >
                                        <option
                                            [value]="aircraftModel.id"
                                            *ngFor="let aircraftModel of aircraftModels"
                                            [innerHtml]="aircraftModel.title"
                                        ></option>
                                    </select>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    form.controls['aircraftModelId'].touched) &&
                                                form.controls['aircraftModelId'].errors &&
                                                form.controls['aircraftModelId'].errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-end mt-4 mb-4">
                    <button
                        type="submit"
                        class="btn btn-primary btn-lg"
                        [innerHtml]="sending ? 'Sauvegarde ...' : 'Suivant'"
                        [disabled]="form.disabled || !form.valid || sending"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="modal" tabindex="-1" id="modal-fly-information" #modalFlyInformation>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Explication du calcul du temps de vol</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body" [innerHtml]="flyInformationContent"></div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalUpload>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Envoie d'un document au serveur</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <input
                        type="file"
                        #inputFile
                        class="form-control"
                        (change)="changeInputFile($event)"
                    />
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="upload()"
                    [disabled]="form.disabled"
                >
                    Envoyer
                </button>
            </div>
        </div>
    </div>
</div>
