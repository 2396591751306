<div class="row justify-content-center">
    <div class="col-md-6 col-lg-4 col-xl-2">
        <div class="form-group">
            <label for="userId">Accéder une demande</label>
            <app-enquiry-ref-picker
                inputName="enquiryId"
                (setValueToFormControl)="setValueToFormControl($event)"
            ></app-enquiry-ref-picker>
        </div>
    </div>
    <div
        class="col-md-6 col-lg-4 col-xl-3"
        *ngIf="hasAclAccess(EnumAcl.enquiriesFilterByDepartment)"
    >
        <div class="form-check mt-md-3">
            <input
                type="radio"
                name="person-or-department"
                id="person-or-department-person"
                value="person"
                [(ngModel)]="filterByPersonOrDepartment"
                class="form-check-input"
                (change)="updatedFilterByPersonOrDepartment()"
            />
            <label class="form-check-label" for="person-or-department-person">
                Filtrer par personne
            </label>
        </div>
        <div class="form-check">
            <input
                type="radio"
                name="person-or-department"
                id="person-or-department-department"
                value="department"
                [(ngModel)]="filterByPersonOrDepartment"
                class="form-check-input"
                (change)="updatedFilterByPersonOrDepartment()"
            />
            <label class="form-check-label" for="person-or-department-department">
                Filtrer par département
            </label>
        </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3">
        <label class="d-none d-md-block">&nbsp;</label>
        <div class="form-group" *ngIf="filterByPersonOrDepartment === 'person'">
            <select
                class="form-select"
                id="userId"
                [(ngModel)]="userId"
                required
                [disabled]="!usersList || !usersList.length"
                (change)="userChanged()"
            >
                <option value="all">Tout le monde</option>
                <option
                    [value]="user.value"
                    *ngFor="let user of usersList"
                    [innerHtml]="user.value == currentUser.id ? user.title + ' (moi)' : user.title"
                ></option>
            </select>
        </div>
        <div class="form-group" *ngIf="filterByPersonOrDepartment === 'department'">
            <select
                class="form-select"
                id="enquiryType"
                [(ngModel)]="enquiryType"
                required
                (change)="enquiryTypeChanged()"
            >
                <option value="all">Tous les département</option>
                <option
                    [value]="enquiryType"
                    *ngFor="let enquiryType of getEnquiryTypes()"
                    [innerText]="getEnumEnquiryTypeLabel(enquiryType)"
                ></option>
            </select>
        </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-2">
        <div class="form-group">
            <label for="status">Filtrées par statut</label>
            <select
                class="form-select"
                id="status"
                [(ngModel)]="status"
                required
                (change)="statusChanged()"
            >
                <option value="all">Tous</option>
                <option
                    [value]="status"
                    *ngFor="let status of getEnquiryStatuses()"
                    [innerText]="getEnumEnquiryStatusLabel(status)"
                ></option>
            </select>
        </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3" *ngIf="status === EnumEnquiryStatus.cancelled">
        <div class="form-group">
            <label for="cancelledReason">Filtrées par raison d'annulation</label>
            <select
                class="form-select"
                id="cancelledReason"
                [(ngModel)]="cancelledReason"
                required
                (change)="cancelledReasonChanged()"
            >
                <option value="all">Tous</option>
                <option
                    [value]="cancelledReason"
                    *ngFor="let cancelledReason of getEnquiryCancelledReason()"
                    [innerText]="getEnumEnquiryCancelledReasonLabel(cancelledReason)"
                ></option>
            </select>
        </div>
    </div>
    <div class="col-md-6 col-lg-4 col-xl-2">
        <div class="form-group">
            <label for="filterByPeriod">Filtrer par période</label>
            <select
                class="form-select"
                id="filterByPeriod"
                [(ngModel)]="filterByPeriod"
                (change)="updateFilterPeriod()"
            >
                <option [ngValue]="null">Tous</option>
                <option
                    [value]="filter.value"
                    *ngFor="let filter of filtersByPeriod"
                    [innerHtml]="filter.title"
                ></option>
            </select>
        </div>
    </div>
    <div class="offset-md-4 col-md-8">
        <div class="row" *ngIf="filterByPeriod == 'custom'">
            <div class="col-sm">
                <div class="form-group">
                    <label for="start">Début période</label>
                    <input
                        type="date"
                        class="form-control"
                        name="start"
                        [(ngModel)]="start"
                        [max]="end ? end : today"
                        placeholder="YYYY-MM-DD"
                        (change)="customDatesChanged()"
                    />
                </div>
            </div>
            <div class="col-sm">
                <div class="form-group">
                    <label for="end">Fin période</label>
                    <input
                        type="date"
                        class="form-control"
                        name="end"
                        [(ngModel)]="end"
                        [min]="start"
                        [max]="today"
                        placeholder="YYYY-MM-DD"
                        (change)="customDatesChanged()"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<enquiries-list
    [loading]="paginationService.loading"
    [enquiries]="paginationService.pagination[currentPagination].data"
    [usersObj]="usersObj"
    [status]="status"
    [enquiryType]="enquiryType"
></enquiries-list>

<nav aria-label="Liste navigation" class="mt-4 mb-4" *ngIf="!paginationService.loading">
    <ul class="pagination justify-content-center">
        <li
            class="page-item"
            [class.disabled]="paginationService.pagination[currentPagination].currentPage === 0"
        >
            <button type="button" class="page-link" (click)="loadPrevPage(currentPagination)">
                Précédent
            </button>
        </li>
        <li class="page-item disabled">
            <span
                class="page-link"
                [innerHtml]="paginationService.pagination[currentPagination].currentPage + 1"
            ></span>
        </li>
        <li
            class="page-item"
            [class.disabled]="
                paginationService.pagination[currentPagination].data &&
                (!paginationService.pagination[currentPagination].data.length ||
                    paginationService.pagination[currentPagination].data.length <
                        paginationService.pagination[currentPagination].limit)
            "
        >
            <button type="button" class="page-link" (click)="loadNextPage(currentPagination)">
                Suivant
            </button>
        </li>
    </ul>
</nav>
