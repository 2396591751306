import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import firebase from 'firebase/compat/app';
import { FlightBriefFirestoreService } from './flight-briefs-firestore.service';
import { IFlightBrief } from 'src/app/interfaces/flight-brief.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FlightBriefService {
  constructor(private flightBriefFirestoreService: FlightBriefFirestoreService) {}

  create(flightBrief: IFlightBrief): Promise<string> {
    return this.flightBriefFirestoreService.create(flightBrief);
  }

  createWithId(id: string, flightBrief: IFlightBrief): Promise<string> {
    return this.flightBriefFirestoreService.createWithId(id, flightBrief);
  }

  update(flightBrief: IFlightBrief): Promise<string> {
    return this.flightBriefFirestoreService.update(flightBrief);
  }

  delete(id: string): Promise<void> {
    return this.flightBriefFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IFlightBrief> {
    return this.flightBriefFirestoreService.docValue$(id);
  }

  getAll(): Observable<IFlightBrief[]> {
    return this.flightBriefFirestoreService.collectionValues$();
  }

  getAllForEnquiry(enquiryId: string): Observable<IFlightBrief[]> {
    return this.flightBriefFirestoreService.collectionValues$(ref =>
      ref.where('enquiryId', '==', enquiryId)
    );
  }

  getLastVersionForEnquiry(enquiryId: string): Observable<IFlightBrief> {
    return this.flightBriefFirestoreService
      .collectionValues$(ref =>
        ref.where('enquiryId', '==', enquiryId).orderBy('versionNumber', 'desc')
      )
      .pipe(map(results => (results && results.length === 1 ? results[0] : null)));
  }

  resetPagination(paginationName: string = 'list'): void {
    this.flightBriefFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IFlightBrief[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.flightBriefFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }
}
