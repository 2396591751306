export enum EnumCurrency {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
  CHF = 'CHF'
}

export const getDefaultCurrency = (): EnumCurrency => {
  return EnumCurrency.EUR;
};

export const getEnumCurrencyLabel = (currency: EnumCurrency): string => {
  switch (currency) {
    default:
      return currency;
    case EnumCurrency.EUR:
      return 'Euro';
    case EnumCurrency.USD:
      return 'US dollar';
    case EnumCurrency.GBP:
      return 'Livre sterling';
    case EnumCurrency.CHF:
      return 'Franc suisse';
  }
};

export const getEnumCurrencySymbol = (currency: EnumCurrency): string => {
  switch (currency) {
    default:
      return currency;
    case EnumCurrency.EUR:
      return '€';
    case EnumCurrency.USD:
      return '$US';
    case EnumCurrency.GBP:
      return '£';
    case EnumCurrency.CHF:
      return 'CHF';
  }
};

export const getEnumCurrencySymbolForExcel = (currency: EnumCurrency): string => {
  switch (currency) {
    default:
      return currency;
    case EnumCurrency.EUR:
      return '€';
    case EnumCurrency.USD:
      return '$';
    case EnumCurrency.GBP:
      return '£';
    case EnumCurrency.CHF:
      return 'CHF';
  }
};
