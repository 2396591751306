import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import firebase from 'firebase/compat/app';
import { FlightChecklistFirestoreService } from './flight-checklists-firestore.service';
import { IFlightChecklist } from 'src/app/interfaces/flight-checklist.interface';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';

@Injectable({
  providedIn: 'root'
})
export class FlightChecklistService {
  constructor(private flightChecklistFirestoreService: FlightChecklistFirestoreService) {}

  create(flightChecklist: IFlightChecklist): Promise<string> {
    return this.flightChecklistFirestoreService.create(flightChecklist);
  }

  createWithId(id: string, flightChecklist: IFlightChecklist): Promise<string> {
    return this.flightChecklistFirestoreService.createWithId(id, flightChecklist);
  }

  update(flightChecklist: IFlightChecklist): Promise<string> {
    return this.flightChecklistFirestoreService.update(flightChecklist);
  }

  delete(id: string): Promise<void> {
    return this.flightChecklistFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IFlightChecklist> {
    return this.flightChecklistFirestoreService.docValue$(id);
  }

  getForFlight(enquiryFlightId: string): Observable<IFlightChecklist> {
    return this.flightChecklistFirestoreService
      .collectionValues$(ref => ref.where('enquiryFlightId', '==', enquiryFlightId))
      .pipe(
        map(results => {
          if (results && results.length === 1) {
            for (const fieldData of results[0].fieldData) {
              for (let item of fieldData.history) {
                item = this.flightChecklistFirestoreService.timestampsToDate(item);
              }
            }

            return results[0];
          }

          return null;
        })
      );
  }

  getAll(): Observable<IFlightChecklist[]> {
    return this.flightChecklistFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.flightChecklistFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IFlightChecklist[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.flightChecklistFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }
}
