<breadcrumbs
    [items]="[
        {
            title: 'Liste des appareils aériens',
            path: '/admin/aircrafts/list'
        },
        {
            title: aircraft ? aircraftModel?.title + ' - ' + aircraft.serialNumber : '',
            path: '/admin/aircrafts/view/' + aircraftId
        }
    ]"
></breadcrumbs>

<div class="row">
    <div class="col-md-5">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title text-center">Informations générales</h3>
                <ul *ngIf="loading" class="list-unstyled">
                    <li *ngFor="let number of [].constructor(4)">
                        <div class="skeleton-text"></div>
                    </li>
                </ul>
                <div *ngIf="!loading && aircraft">
                    <ul class="list-unstyled">
                        <li><strong>MSN</strong> : {{ aircraft.serialNumber }}</li>
                        <li>
                            <strong>Status</strong> :
                            <span
                                class="badge"
                                [class.bg-success]="aircraft.isActive"
                                [class.bg-danger]="!aircraft.isActive"
                                [innerText]="getAircraftStatusLabel(aircraft.status)"
                            ></span>
                        </li>
                        <li>
                            <strong>Dernière Immatriculation</strong> : {{ aircraft.registration }}
                        </li>
                        <li>
                            <strong>Dernier opérateur</strong> :
                            <div class="skeleton-text" *ngIf="loadingAirline"></div>
                            <a
                                [routerLink]="'/admin/airlines/view/' + airline.id"
                                [innerText]="airline.title"
                                *ngIf="!loadingAirline"
                            ></a>
                        </li>
                        <li>
                            <strong>Modèle</strong> :
                            <div *ngIf="loadingAircraftModel" class="skeleton-text"></div>
                            <a
                                [routerLink]="
                                    '/admin/aircraft-models/view/' + aircraft.aircraftModelId
                                "
                                [innerText]="aircraftModel?.title.toUpperCase()"
                                *ngIf="!loadingAircraftModel"
                            ></a>
                        </li>
                        <li><strong>Type</strong> : {{ aircraft.type }}</li>
                        <li>
                            <strong>Date premier vol</strong> :
                            {{ aircraft.firstFlightDate ? aircraft.firstFlightDate : "Inconnu" }}
                        </li>
                        <li>
                            <strong>Immatriculation d'essai</strong> :
                            {{ aircraft.testRegistration }}
                        </li>
                        <li>
                            <strong>Âge de l'avion</strong> :
                            {{ getAircraftAge(aircraft) || "?" }} ans
                        </li>
                        <li><strong>Classes</strong> : {{ aircraft.classes.join("/") }}</li>
                        <li>
                            <strong>Config siège</strong> :
                            <ul>
                                <li
                                    *ngIf="aircraft.seatFirst"
                                    [innerText]="'Classe première : ' + aircraft.seatFirst + 'F'"
                                ></li>
                                <li
                                    *ngIf="aircraft.seatBusiness"
                                    [innerText]="'Classe affaire : ' + aircraft.seatBusiness + 'C'"
                                ></li>
                                <li
                                    *ngIf="aircraft.seatPremiumEconomy"
                                    [innerText]="
                                        'Classe économique premium : ' +
                                        aircraft.seatPremiumEconomy +
                                        'W'
                                    "
                                ></li>
                                <li
                                    *ngIf="aircraft.seatEconomy"
                                    [innerText]="
                                        'Classe économique : ' + aircraft.seatEconomy + 'Y'
                                    "
                                ></li>
                                <li [innerText]="'Total : ' + aircraft.seatTotal + ' places'"></li>
                            </ul>
                        </li>
                        <li><strong>Moteurs</strong> : {{ aircraft.engines }}</li>
                        <li>
                            <strong>Peut être utilisé pour le cargo</strong> :
                            {{ aircraft.isCargo ? "Oui" : "Non" }}
                        </li>
                        <li *ngIf="aircraft.weight">
                            <strong>Poids</strong> : {{ aircraft.weight }} kg
                        </li>
                        <li *ngIf="aircraft.volume">
                            <strong>Volume</strong> : {{ aircraft.volume }} m<sup>3</sup>
                        </li>
                        <li *ngIf="aircraft.url">
                            <strong>Airfleets</strong> :
                            <a [href]="aircraft.url" target="_blank">Lien</a>
                        </li>
                        <li *ngIf="aircraft.homebase">
                            <strong>Aéroport d'attache</strong> : {{ aircraft.homebase }}
                        </li>
                    </ul>
                    <div class="mb-4 text-center">
                        <a
                            [routerLink]="'/admin/aircrafts/edit/' + aircraft.id"
                            class="btn btn-primary"
                            *ngIf="hasAclAccess(EnumAcl.aircraftsEdit)"
                            >Modifier</a
                        >
                        &nbsp;
                        <button
                            type="button"
                            (click)="delete()"
                            class="btn btn-outline-danger"
                            *ngIf="hasAclAccess(EnumAcl.aircraftsDelete)"
                        >
                            Supprimer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-7">
        <div class="card mb-4">
            <div class="card-body">
                <h3 class="card-title text-center">Opérateurs de l'appareil</h3>
                <ul *ngIf="loading" class="list-unstyled">
                    <li *ngFor="let number of [].constructor(4)">
                        <div class="skeleton-text"></div>
                    </li>
                </ul>
                <div *ngIf="!loading && aircraft">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Immatriculation</th>
                                    <th>Date de livraison</th>
                                    <th>Opérateur</th>
                                    <th>Remarque</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let tr of aircraft.history">
                                    <td [innerText]="tr.registration"></td>
                                    <td [innerText]="tr.deliveryDate"></td>
                                    <td>
                                        <a
                                            [routerLink]="'/admin/airlines/view/' + tr.airlineId"
                                            [innerText]="tr.airlineTitle"
                                            *ngIf="tr.airlineId"
                                        ></a>
                                        <span
                                            *ngIf="!tr.airlineId"
                                            [innerText]="tr.airlineTitle"
                                        ></span>
                                    </td>
                                    <td [innerText]="tr.remark"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4">
            <div class="card-body">
                <h3 class="card-title text-center">Images</h3>

                <div class="row" *ngIf="aircraft">
                    <div
                        class="col-md-6 text-center"
                        *ngFor="let imageField of ['imageOutside', 'imageInside']"
                    >
                        <div *ngIf="imageField === 'imageOutside'">Photo extérieur</div>
                        <div *ngIf="imageField === 'imageInside'">Photo intérieur</div>

                        <div
                            class="alert alert-info text-center"
                            *ngIf="!aircraft[imageField + 'Url']"
                        >
                            Aucune image
                        </div>

                        <div class="image-container" *ngIf="aircraft[imageField + 'Url']">
                            <div
                                class="image"
                                [style.background-image]="
                                    'url(' + aircraft[imageField + 'Url'] + ')'
                                "
                                [style.webkitTransform]="
                                    'scale(' + aircraft[imageField + 'ZoomLevel'] + ')'
                                "
                                [style.transform]="
                                    'scale(' + aircraft[imageField + 'ZoomLevel'] + ')'
                                "
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
