import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';

@Component({
  selector: 'app-list-user-groups',
  templateUrl: './list-user-groups.component.html',
  styleUrls: ['./list-user-groups.component.scss']
})
export class ListUserGroupsComponent implements OnInit {
  rootUrl: string = './';
  currentPagination: string = 'user-groups-list';
  userGroups: IUserGroup[] = [];

  EnumAcl = EnumAcl;

  constructor(public paginationService: PaginationService, private aclService: AclService) {}

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.userGroupsList);

    this.paginationService.pagination[this.currentPagination].currentPage = 0;

    this.fetchData();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    await this.paginationService.fetchData(this.currentPagination);
  }
}
