import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { FinancialReportUsersFirestoreService } from './financial-report-users-firestore.service';
import { EnumCurrency } from 'src/app/enums/currency.enum';
import { IFinancialReportUser } from 'src/app/interfaces/financial-report-user.interface';

@Injectable({
  providedIn: 'root'
})
export class FinancialReportUsersService {
  constructor(private financialReportUsersFirestoreService: FinancialReportUsersFirestoreService) {}

  create(stats: IFinancialReportUser): Promise<string> {
    return this.financialReportUsersFirestoreService.create(stats);
  }

  update(stats: IFinancialReportUser): Promise<string> {
    return this.financialReportUsersFirestoreService.update(stats);
  }

  delete(id: string): Promise<void> {
    return this.financialReportUsersFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IFinancialReportUser> {
    return this.financialReportUsersFirestoreService.docValue$(id);
  }

  getAll(): Observable<IFinancialReportUser[]> {
    return this.financialReportUsersFirestoreService.collectionValues$();
  }

  getAllForCurrencyForYear(
    currency: EnumCurrency,
    year: number
  ): Observable<IFinancialReportUser[]> {
    return this.financialReportUsersFirestoreService.collectionValues$(ref =>
      ref.where('currency', '==', currency).where('year', '==', year)
    );
  }

  getAllForCurrencyOnlyYears(currency: EnumCurrency): Observable<IFinancialReportUser[]> {
    return this.financialReportUsersFirestoreService.collectionValues$(ref =>
      ref.where('currency', '==', currency).where('quarter', '==', null).where('month', '==', null)
    );
  }

  getAllForCurrencyForYearOnly(
    currency: EnumCurrency,
    year: number
  ): Observable<IFinancialReportUser[]> {
    return this.financialReportUsersFirestoreService.collectionValues$(ref =>
      ref.where('currency', '==', currency).where('year', '==', year).where('quarter', '==', null)
    );
  }

  getAllForYear(year: number): Observable<IFinancialReportUser[]> {
    return this.financialReportUsersFirestoreService.collectionValues$(ref =>
      ref.where('year', '==', year)
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.financialReportUsersFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IFinancialReportUser[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.financialReportUsersFirestoreService.paginate(
      conditions,
      orderBy,
      20,
      paginationName
    );
  }
}
