import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';

import { RemoteService } from '../services/remote.service';

@Component({
  selector: 'app-password-forgotten',
  templateUrl: './password-forgotten.component.html',
  styleUrls: ['./password-forgotten.component.scss']
})
export class PasswordForgottenComponent implements OnInit {
  form: FormGroup;
  sending: boolean = false;
  rootUrl: string = './';

  constructor(private remoteService: RemoteService) {
    this.rootUrl = window['rootUrl'];
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.status == 'VALID') {
      this.sending = true;
      this.form.disable();

      this.remoteService
        .resetPassword(this.form.value['email'])
        .then(() => {
          this.sending = false;
          this.form.enable();
          this.form.reset();

          alert('Un email vous a été envoyé, vérifiez votre boîte de réception !');
        })
        .catch(err => {
          console.log(err);
          this.sending = false;
          this.form.enable();

          alert(err.message);
        });
    }
  }
}
