<breadcrumbs
    [items]="[
        {
            title: 'Liste des compagnies aériennes',
            path: '/admin/airlines/list'
        }
    ]"
></breadcrumbs>

<h1 class="text-center">Liste des compagnies aériennes</h1>

<div class="row">
    <div class="offset-md-8 col-md-4">
        <div class="form-group mb-3" *ngIf="hasAclAccess(EnumAcl.airlinesSearch)">
            <input
                type="text"
                class="form-control"
                placeholder="Recherchez une compagnie..."
                aria-label="Recherche"
                required
                (keyup)="keyUp.next($event.srcElement.value)"
            />
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-3 mb-4">
        <a
            routerLink="/admin/airlines/add"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.airlinesAdd)"
            >+ Nouvelle compagnie</a
        >
    </div>
    <div class="col-md-3 mb-4">
        <div class="form-group mb-0" *ngIf="hasAclAccess(EnumAcl.airlinesListFilters)">
            <select
                class="form-select"
                id="countryCode"
                [(ngModel)]="countryCode"
                required
                [disabled]="!countriesList || !countriesList.length"
                (change)="resetOrderAndfetchData()"
            >
                <option [ngValue]="null">Tous les pays</option>
                <option
                    [value]="country.value"
                    *ngFor="let country of countriesList"
                    [innerHtml]="country.title"
                ></option>
            </select>
        </div>
    </div>
    <div class="col-md-3 mb-4">
        <div class="form-group mb-0" *ngIf="hasAclAccess(EnumAcl.airlinesListFilters)">
            <select
                class="form-select"
                id="isActiveFilter"
                [(ngModel)]="isActiveFilter"
                required
                [disabled]="!activesList || !activesList.length"
                (change)="resetOrderAndfetchData()"
            >
                <option [ngValue]="null">Toutes les compagnies</option>
                <option
                    [value]="activeOption.value"
                    *ngFor="let activeOption of activesList"
                    [innerHtml]="activeOption.title"
                ></option>
            </select>
        </div>
    </div>
    <div class="col-md-3 mb-4 text-end">
        <a
            routerLink="/admin/aircrafts/list"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.aircraftsList)"
            >Liste avions</a
        >
    </div>
</div>

<div class="table-responsive">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th
                    scope="col"
                    class="sortable"
                    *ngFor="let field of paginationService.pagination[currentPagination].fields"
                    [class.is-sorted]="
                        paginationService.pagination[currentPagination].orderBy.field == field.code
                    "
                    [class.d-none]="
                        (countryCode &&
                            ['continentCode', 'countryCode'].indexOf(field.code) !== -1) ||
                        ([EnumListFilter.activeOnly, EnumListFilter.inactiveOnly].includes(
                            isActiveFilter
                        ) &&
                            ['isActive'].includes(field.code)) ||
                        ([EnumListFilter.hasContactOnly, EnumListFilter.noContactOnly].includes(
                            isActiveFilter
                        ) &&
                            ['hasContact'].includes(field.code))
                    "
                    [class.asc]="
                        paginationService.pagination[currentPagination].orderBy.field ==
                            field.code &&
                        paginationService.pagination[currentPagination].orderBy.direction == 'asc'
                    "
                    [class.desc]="
                        paginationService.pagination[currentPagination].orderBy.field ==
                            field.code &&
                        paginationService.pagination[currentPagination].orderBy.direction == 'desc'
                    "
                    (click)="paginationService.sortField(currentPagination, field.code)"
                    [innerHtml]="field.title"
                ></th>
            </tr>
        </thead>
        <tbody *ngIf="paginationService.loading">
            <tr *ngFor="let number of [].constructor(10)">
                <td
                    *ngFor="let field of paginationService.pagination[currentPagination].fields"
                    [class.d-none]="
                        (countryCode &&
                            ['continentCode', 'countryCode'].indexOf(field.code) !== -1) ||
                        ([EnumListFilter.activeOnly, EnumListFilter.inactiveOnly].includes(
                            isActiveFilter
                        ) &&
                            ['isActive'].includes(field.code)) ||
                        ([EnumListFilter.hasContactOnly, EnumListFilter.noContactOnly].includes(
                            isActiveFilter
                        ) &&
                            ['hasContact'].includes(field.code))
                    "
                >
                    <div class="skeleton-text"></div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="isSearchListing">
            <tr *ngIf="!searchedAirlines.length" class="table-warning text-center">
                <td [attr.colspan]="paginationService.pagination[currentPagination].fields.length">
                    Aucune compagnie aérienne n'a été trouvée.
                </td>
            </tr>
            <tr
                *ngFor="let airline of searchedAirlines"
                [routerLink]="'/admin/airlines/view/' + airline.id"
                class="clickable"
            >
                <td [innerHtml]="airline.title"></td>
                <td
                    [innerHtml]="
                        airline.continentCode + ' - ' + getContinentLabel(airline.continentCode)
                    "
                    [class.d-none]="countryCode"
                ></td>
                <td
                    [innerHtml]="airline.countryCode + ' - ' + getCountryLabel(airline.countryCode)"
                    [class.d-none]="countryCode"
                ></td>
                <td
                    [innerHtml]="airline.isActive ? 'Oui' : 'Non'"
                    [class.d-none]="
                        [EnumListFilter.activeOnly, EnumListFilter.inactiveOnly].includes(
                            isActiveFilter
                        )
                    "
                ></td>
                <td
                    [innerHtml]="airline.hasContact ? 'Oui' : 'Non'"
                    [class.d-none]="
                        [EnumListFilter.hasContactOnly, EnumListFilter.noContactOnly].includes(
                            isActiveFilter
                        )
                    "
                ></td>
            </tr>
        </tbody>
        <tbody *ngIf="!paginationService.loading && !isSearchListing">
            <tr
                *ngIf="
                    !paginationService.pagination[currentPagination].data ||
                    !paginationService.pagination[currentPagination].data.length
                "
                class="table-warning text-center"
            >
                <td [attr.colspan]="paginationService.pagination[currentPagination].fields.length">
                    Aucune compagnie aérienne n'a été trouvée.
                </td>
            </tr>
            <tr
                *ngFor="let airline of paginationService.pagination[currentPagination].data"
                [routerLink]="'/admin/airlines/view/' + airline.id"
                class="clickable"
            >
                <td [innerHtml]="airline.title"></td>
                <td
                    [innerHtml]="
                        airline.continentCode + ' - ' + getContinentLabel(airline.continentCode)
                    "
                    [class.d-none]="countryCode"
                ></td>
                <td
                    [innerHtml]="airline.countryCode + ' - ' + getCountryLabel(airline.countryCode)"
                    [class.d-none]="countryCode"
                ></td>
                <td
                    [innerHtml]="airline.isActive ? 'Oui' : 'Non'"
                    [class.d-none]="
                        [EnumListFilter.activeOnly, EnumListFilter.inactiveOnly].includes(
                            isActiveFilter
                        )
                    "
                ></td>
                <td
                    [innerHtml]="airline.hasContact ? 'Oui' : 'Non'"
                    [class.d-none]="
                        [EnumListFilter.hasContactOnly, EnumListFilter.noContactOnly].includes(
                            isActiveFilter
                        )
                    "
                ></td>
            </tr>
        </tbody>
    </table>
</div>

<nav
    aria-label="Liste navigation"
    class="mt-4 mb-4"
    *ngIf="!paginationService.loading && !isSearchListing"
>
    <ul class="pagination justify-content-center">
        <li
            class="page-item"
            [class.disabled]="paginationService.pagination[currentPagination].currentPage === 0"
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadPreviousPage(currentPagination)"
            >
                Précédent
            </button>
        </li>
        <li class="page-item disabled">
            <span
                class="page-link"
                [innerHtml]="paginationService.pagination[currentPagination].currentPage + 1"
            ></span>
        </li>
        <li
            class="page-item"
            [class.disabled]="
                paginationService.pagination[currentPagination].data &&
                (!paginationService.pagination[currentPagination].data.length ||
                    paginationService.pagination[currentPagination].data.length <
                        paginationService.pagination[currentPagination].limit)
            "
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadNextPage(currentPagination)"
            >
                Suivant
            </button>
        </li>
    </ul>
</nav>
