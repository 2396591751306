<div class="calendar">
    <div class="calendar-header">
        <button type="button" class="btn btn-light btn-sm" (click)="prevYear()">
            <fa-icon [icon]="faAnglesLeft"></fa-icon>
        </button>
        <button type="button" class="btn btn-light btn-sm" (click)="prevMonth()">
            <fa-icon [icon]="faAngleLeft"></fa-icon>
        </button>
        <span
            class="current-month"
            [innerText]="dateStr | date : 'MMMM YYYY' : undefined : 'fr'"
        ></span>
        <button type="button" class="btn btn-light btn-sm" (click)="nextMonth()">
            <fa-icon [icon]="faAngleRight"></fa-icon>
        </button>
        <button type="button" class="btn btn-light btn-sm" (click)="nextYear()">
            <fa-icon [icon]="faAnglesRight"></fa-icon>
        </button>
    </div>
    <table class="calendar-days">
        <thead>
            <tr>
                <th>Lu</th>
                <th>Ma</th>
                <th>Me</th>
                <th>Je</th>
                <th>Ve</th>
                <th>Sa</th>
                <th>Di</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let week of daysOfMonth">
                <td *ngFor="let day of week">
                    <button
                        type="button"
                        class="btn btn-sm"
                        [class.btn-primary]="todayStr === day"
                        [class.btn-secondary]="dateStr === day"
                        [innerText]="day | date : 'd'"
                        *ngIf="day"
                        (click)="selectDate(day)"
                    ></button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
