import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { BackupsService } from 'src/app/services/backups/backups.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { format, formatInTimeZone } from 'date-fns-tz';

@Component({
  selector: 'app-list-backups',
  templateUrl: './list-backups.component.html',
  styleUrls: ['./list-backups.component.scss']
})
export class ListBackupsComponent implements OnInit {
  EnumAcl = EnumAcl;

  backupFiles: {
    firestore: {
      fileName: string;
      fileNameLocalised: string;
    }[];
    auth: {
      fileName: string;
      fileNameLocalised: string;
    }[];
  } = {
    firestore: [],
    auth: []
  };

  loading: boolean = false;
  restoring: boolean = false;

  form: FormGroup = new FormGroup({
    firestoreFile: new FormControl(null, Validators.required),
    authFile: new FormControl(null, Validators.required)
  });

  constructor(private aclService: AclService, private backupsService: BackupsService) {}

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.backupsList);

    this.loadBackupFiles();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async loadBackupFiles(): Promise<void> {
    this.loading = true;

    const backupFiles: {
      firestore: string[];
      auth: string[];
    } = await this.backupsService.getBackupFilesList();

    for (const field in backupFiles) {
      this.backupFiles[field] = [];

      for (const file of backupFiles[field]) {
        const fileName: string = file.replace('.json', '');

        const dateInUtc: Date = new Date(fileName + ' GMT');

        const dateInLocal: string = formatInTimeZone(
          dateInUtc,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          'yyyy-MM-dd HH:mm'
        );

        this.backupFiles[field].push({
          fileName: file,
          fileNameLocalised: dateInLocal
        });
      }
    }

    this.loading = false;
  }

  async restoreBackup(): Promise<void> {
    if (this.form.valid) {
      await this.aclService.checkAclAccess(EnumAcl.backupsRestore);

      if (confirm('Cette action est irreversible. Voulez-vous continuer ?')) {
        this.form.disable();
        this.restoring = true;

        try {
          await this.backupsService.applyBackUp(
            this.form.value.firestoreFile,
            this.form.value.authFile
          );

          alert('La restauration est en cours, cela peut prendre quelques minutes');
        } catch (err) {
          alert(err);
        } finally {
          this.restoring = false;
          this.form.reset();
          this.form.enable();
        }
      }
    }
  }

  addToRestore(field: string, value: string): void {
    this.form.get(field).setValue(value);
  }

  clearToRestore(field: string): void {
    this.form.get(field).setValue(null);
  }

  async forceBackup(): Promise<void> {
    if (confirm('Cette action est irreversible. Voulez-vous continuer ?')) {
      this.loading = true;

      try {
        await this.backupsService.forceBackup();

        alert('La sauvegarde est en cours et peut prendre quelques minutes.');
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    }
  }
}
