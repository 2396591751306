import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import firebase from 'firebase/compat/app';
import { CrewFirestoreService } from './crews-firestore.service';
import { ICrew } from 'src/app/interfaces/crew.interface';

@Injectable({
  providedIn: 'root'
})
export class CrewService {
  constructor(private crewFirestoreService: CrewFirestoreService) {}

  create(Crew: ICrew): Promise<string> {
    return this.crewFirestoreService.create(Crew);
  }

  createWithId(id: string, Crew: ICrew): Promise<string> {
    return this.crewFirestoreService.createWithId(id, Crew);
  }

  update(Crew: ICrew): Promise<string> {
    return this.crewFirestoreService.update(Crew);
  }

  delete(id: string): Promise<void> {
    return this.crewFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<ICrew> {
    return this.crewFirestoreService.docValue$(id);
  }

  getAll(): Observable<ICrew[]> {
    return this.crewFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.crewFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<ICrew[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.crewFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }

  getForAirline(airlineId: string): Observable<ICrew[]> {
    return this.crewFirestoreService.collectionValues$(ref =>
      ref.where('airlineId', '==', airlineId)
    );
  }
}
