import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { IFinancialGoal } from 'src/app/interfaces/financial-goal.interface';
import { FinancialGoalsFirestoreService } from './financial-goals-firestore.service';
import { EnumCurrency } from 'src/app/enums/currency.enum';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FinancialGoalsService {
  constructor(private financialGoalsFirestoreService: FinancialGoalsFirestoreService) {}

  create(financialGoal: IFinancialGoal): Promise<string> {
    return this.financialGoalsFirestoreService.create(financialGoal);
  }

  createWithId(id: string, financialGoal: IFinancialGoal): Promise<string> {
    return this.financialGoalsFirestoreService.createWithId(id, financialGoal);
  }

  update(financialGoal: IFinancialGoal): Promise<string> {
    return this.financialGoalsFirestoreService.update(financialGoal);
  }

  delete(id: string): Promise<void> {
    return this.financialGoalsFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IFinancialGoal> {
    return this.financialGoalsFirestoreService.docValue$(id);
  }

  getAll(): Observable<IFinancialGoal[]> {
    return this.financialGoalsFirestoreService.collectionValues$();
  }

  getOneForCurrencyForYear(
    currency: EnumCurrency,
    year: number
  ): Observable<IFinancialGoal | null> {
    return this.financialGoalsFirestoreService
      .collectionValues$(ref => ref.where('currency', '==', currency).where('year', '==', year))
      .pipe(
        map(results => {
          return results && results.length ? results[0] : null;
        })
      );
  }

  getAllForCurrencyForYear(currency: EnumCurrency, year: number): Observable<IFinancialGoal[]> {
    return this.financialGoalsFirestoreService.collectionValues$(ref =>
      ref.where('currency', '==', currency).where('year', '==', year)
    );
  }

  getAllForYear(year: number): Observable<IFinancialGoal[]> {
    return this.financialGoalsFirestoreService.collectionValues$(ref =>
      ref.where('year', '==', year)
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.financialGoalsFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IFinancialGoal[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.financialGoalsFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }
}
