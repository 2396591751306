import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import firebase from 'firebase/compat/app';
import { IFlightBriefCatering } from 'src/app/interfaces/flight-brief-catering.interface';
import { map } from 'rxjs/operators';
import { FlightBriefCateringFirestoreService } from './flight-brief-caterings-firestore.service';
import { EnumLanguage } from 'src/app/enums/language.enum';

@Injectable({
  providedIn: 'root'
})
export class FlightBriefCateringService {
  constructor(private flightBriefCateringFirestoreService: FlightBriefCateringFirestoreService) {}

  create(flightBriefCatering: IFlightBriefCatering): Promise<string> {
    return this.flightBriefCateringFirestoreService.create(flightBriefCatering);
  }

  createWithId(id: string, flightBriefCatering: IFlightBriefCatering): Promise<string> {
    return this.flightBriefCateringFirestoreService.createWithId(id, flightBriefCatering);
  }

  update(flightBriefCatering: IFlightBriefCatering): Promise<string> {
    return this.flightBriefCateringFirestoreService.update(flightBriefCatering);
  }

  delete(id: string): Promise<void> {
    return this.flightBriefCateringFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IFlightBriefCatering> {
    return this.flightBriefCateringFirestoreService.docValue$(id);
  }

  getAll(): Observable<IFlightBriefCatering[]> {
    return this.flightBriefCateringFirestoreService.collectionValues$();
  }

  getAllForLanguage(language: EnumLanguage): Observable<IFlightBriefCatering[]> {
    return this.flightBriefCateringFirestoreService.collectionValues$(ref =>
      ref.where('language', '==', language).orderBy('position', 'asc')
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.flightBriefCateringFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IFlightBriefCatering[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.flightBriefCateringFirestoreService.paginate(
      conditions,
      orderBy,
      20,
      paginationName
    );
  }
}
