import { EnumContinentCode } from '../enums/continent-code.enum';
import { EnumLanguage } from '../enums/language.enum';
import { IContact } from './contact.interface';
import { IStoreData } from './store-data.interface';

export interface IAirline extends IStoreData {
  airfleetTitle: string;
  title: string;
  url: string;
  countryCode: string;
  continentCode: EnumContinentCode;
  website: string;
  formattedAddress: string;
  streetNumber: string;
  route: string;
  city: string;
  postalCode: string;
  companyCreationYear: number;
  companyClosureYear: number;
  isActive: boolean;
  codes: string;
  callsign: string;
  tvaNumber: string;
  description: string;
  companyContacts: {
    title: string;
    contacts: IContact[];
  }[];
  documentsUrl: {
    title: string;
    url: string;
  }[];
  isNationalCompany: boolean;
  mustBeContacted: boolean;
  isBlacklisted: boolean;
  reasonBlackListed: string;
  history: string;
  fusionsTransfers: string[];
  previousNames: string[];
  language: EnumLanguage;
  hasContact: boolean;
}

export const getStreetNumberAndRoute = (airline: IAirline): string => {
  const routes: string[] = [];

  if (airline.streetNumber) {
    routes.push(airline.streetNumber);
  }
  if (airline.route) {
    routes.push(airline.route);
  }

  return routes.join(' ');
};
