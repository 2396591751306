<breadcrumbs
    [items]="
        crawlerUrlId
            ? [
                  {
                      title: 'Liste des logs de scripts',
                      path: '/admin/crawler-urls/list'
                  },
                  {
                      title: 'Édition d\'une url',
                      path: '/admin/crawler-urls/edit/' + crawlerUrlId
                  }
              ]
            : [
                  {
                      title: 'Liste des logs de scripts',
                      path: '/admin/crawler-urls/list'
                  },
                  {
                      title: 'Ajout d\'une url',
                      path: '/admin/crawler-urls/add'
                  }
              ]
    "
></breadcrumbs>

<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="url">Url</label>
                        <input
                            class="form-control"
                            id="url"
                            formControlName="url"
                            type="url"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['url'].touched) &&
                                form.controls['url'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['url'].touched) &&
                                    form.controls['url'].errors &&
                                    form.controls['url'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="name">Durée de validité en secondes</label>
                        <input
                            class="form-control"
                            id="delayValidInSeconds"
                            formControlName="delayValidInSeconds"
                            type="number"
                            min="0"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['delayValidInSeconds'].touched) &&
                                form.controls['delayValidInSeconds'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched ||
                                        form.controls['delayValidInSeconds'].touched) &&
                                    form.controls['delayValidInSeconds'].errors &&
                                    form.controls['delayValidInSeconds'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="source">Source</label>
                        <select
                            class="form-select"
                            id="source"
                            formControlName="source"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['source'].touched) &&
                                form.controls['source'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="source"
                                *ngFor="let source of getSources()"
                                [innerHtml]="getEnumCrawlerUrlSourceLabel(source)"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['source'].touched) &&
                                    form.controls['source'].errors &&
                                    form.controls['source'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="'/admin/crawlerUrlId/list'"
                class="btn btn-outline-primary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>
