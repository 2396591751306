import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import firebase from 'firebase/compat/app';
import { EnquiryPaymentSummaryFirestoreService } from './enquiry-payment-summaries-firestore.service';
import { IEnquiryPaymentSummary } from 'src/app/interfaces/enquiry-payment-summary.interface';
import { map } from 'rxjs/operators';
import { addZeroToDigit } from 'src/app/misc.utils';

@Injectable({
  providedIn: 'root'
})
export class EnquiryPaymentSummaryService {
  constructor(
    private enquiryPaymentSummaryFirestoreService: EnquiryPaymentSummaryFirestoreService
  ) {}

  create(enquiryPaymentSummary: IEnquiryPaymentSummary): Promise<string> {
    return this.enquiryPaymentSummaryFirestoreService.create(enquiryPaymentSummary);
  }

  createWithId(id: string, enquiryPaymentSummary: IEnquiryPaymentSummary): Promise<string> {
    return this.enquiryPaymentSummaryFirestoreService.createWithId(id, enquiryPaymentSummary);
  }

  update(enquiryPaymentSummary: IEnquiryPaymentSummary): Promise<string> {
    return this.enquiryPaymentSummaryFirestoreService.update(enquiryPaymentSummary);
  }

  delete(id: string): Promise<void> {
    return this.enquiryPaymentSummaryFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IEnquiryPaymentSummary> {
    return this.enquiryPaymentSummaryFirestoreService.docValue$(id);
  }

  getAll(): Observable<IEnquiryPaymentSummary[]> {
    return this.enquiryPaymentSummaryFirestoreService.collectionValues$();
  }

  getAllForEnquiry(enquiryId: string): Observable<IEnquiryPaymentSummary[]> {
    return this.enquiryPaymentSummaryFirestoreService.collectionValues$(ref =>
      ref.where('enquiryId', '==', enquiryId)
    );
  }

  getLast(): Observable<IEnquiryPaymentSummary | null> {
    return this.enquiryPaymentSummaryFirestoreService
      .collectionValues$(ref => ref.orderBy('periodMonth', 'desc').limit(1))
      .pipe(map(results => (results && results.length === 1 ? results[0] : null)));
  }

  getFirst(): Observable<IEnquiryPaymentSummary | null> {
    return this.enquiryPaymentSummaryFirestoreService
      .collectionValues$(ref => ref.orderBy('periodMonth', 'asc').limit(1))
      .pipe(map(results => (results && results.length === 1 ? results[0] : null)));
  }

  getAllForEnquiryRefContractTitle(
    enquiryRefContractTitle: string
  ): Observable<IEnquiryPaymentSummary[]> {
    return this.enquiryPaymentSummaryFirestoreService.collectionValues$(ref =>
      ref.where('enquiryRefContractTitle', '==', enquiryRefContractTitle)
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.enquiryPaymentSummaryFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IEnquiryPaymentSummary[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.enquiryPaymentSummaryFirestoreService.paginate(
      conditions,
      orderBy,
      20,
      paginationName
    );
  }

  getAllOfMonth(year: number, month: number): Observable<IEnquiryPaymentSummary[]> {
    return this.enquiryPaymentSummaryFirestoreService.collectionValues$(ref =>
      ref
        .where('periodMonth', '==', year + '-' + addZeroToDigit(month))
        .orderBy('enquiryRefContractTitleWithoutPrefix', 'desc')
    );
  }

  getAllOfMonthForUser(
    year: number,
    month: number,
    userId: string
  ): Observable<IEnquiryPaymentSummary[]> {
    return this.enquiryPaymentSummaryFirestoreService.collectionValues$(ref =>
      ref
        .where('periodMonth', '==', year + '-' + addZeroToDigit(month))
        .where('enquiryProcessedBy', '==', userId)
        .orderBy('enquiryRefContractTitleWithoutPrefix', 'desc')
    );
  }

  getAllOfQuarter(year: number, quarter: number): Observable<IEnquiryPaymentSummary[]> {
    return this.enquiryPaymentSummaryFirestoreService.collectionValues$(ref =>
      ref
        .where('periodQuarter', '==', year + '-q' + quarter)
        .orderBy('enquiryRefContractTitleWithoutPrefix', 'desc')
    );
  }

  getAllOfQuarterForUser(
    year: number,
    quarter: number,
    userId: string
  ): Observable<IEnquiryPaymentSummary[]> {
    return this.enquiryPaymentSummaryFirestoreService.collectionValues$(ref =>
      ref
        .where('periodQuarter', '==', year + '-q' + quarter)
        .where('enquiryProcessedBy', '==', userId)
        .orderBy('enquiryRefContractTitleWithoutPrefix', 'desc')
    );
  }

  getAllOfYear(year: number): Observable<IEnquiryPaymentSummary[]> {
    return this.enquiryPaymentSummaryFirestoreService.collectionValues$(ref =>
      ref.where('periodYear', '==', year).orderBy('enquiryRefContractTitleWithoutPrefix', 'desc')
    );
  }

  getAllOfYearForUser(year: number, userId: string): Observable<IEnquiryPaymentSummary[]> {
    return this.enquiryPaymentSummaryFirestoreService.collectionValues$(ref =>
      ref
        .where('periodYear', '==', year)
        .where('enquiryProcessedBy', '==', userId)
        .orderBy('enquiryRefContractTitleWithoutPrefix', 'desc')
    );
  }
}
