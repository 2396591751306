import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { EnquiryFirestoreService } from './enquiry-firestore.service';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EnquiryService {
  constructor(private enquiryFirestoreService: EnquiryFirestoreService) {}

  create(enquiry: IEnquiry): Promise<string> {
    return this.enquiryFirestoreService.create(enquiry);
  }

  update(enquiry: IEnquiry): Promise<string> {
    return this.enquiryFirestoreService.update(enquiry);
  }

  delete(id: string): Promise<void> {
    return this.enquiryFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IEnquiry> {
    return this.enquiryFirestoreService.docValue$(id);
  }

  getAll(): Observable<IEnquiry[]> {
    return this.enquiryFirestoreService.collectionValues$();
  }

  getFromRefContractTitle(refContractTitle: string): Observable<IEnquiry | null> {
    return this.enquiryFirestoreService
      .collectionValues$(ref =>
        ref.where('refContractTitleDisplayed', '==', refContractTitle).limit(1)
      )
      .pipe(map(results => (results && results.length === 1 ? results[0] : null)));
  }

  resetPagination(paginationName: string = 'list'): void {
    this.enquiryFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = [],
    orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    },
    itemsPerPage: number = 20
  ): Observable<IEnquiry[]> {
    return this.enquiryFirestoreService.paginate(conditions, orderBy, itemsPerPage, paginationName);
  }

  getLatestEnquiryOfMonth(isConfirmed: boolean, year: number, month: number): Observable<IEnquiry> {
    const refField: string = isConfirmed ? 'refContract' : 'refEnquiry';
    const numberField: string = refField + 'Number';
    const yearField: string = refField + 'Year';
    const monthField: string = refField + 'Month';

    return this.enquiryFirestoreService
      .collectionValues$(ref =>
        ref
          .where(yearField, '==', year)
          .where(monthField, '==', month)
          .orderBy(numberField, 'desc')
          .limit(1)
      )
      .pipe(map(results => (results && results.length === 1 ? results[0] : null)));
  }

  getAllEnquiryOfMonth(isConfirmed: boolean, year: number, month: number): Observable<IEnquiry[]> {
    const refField: string = isConfirmed ? 'refContract' : 'refEnquiry';
    const numberField: string = refField + 'Number';
    const yearField: string = refField + 'Year';
    const monthField: string = refField + 'Month';

    return this.enquiryFirestoreService.collectionValues$(ref =>
      ref.where(yearField, '==', year).where(monthField, '==', month).orderBy(numberField, 'desc')
    );
  }

  getAllEnquiryOfQuarter(
    isConfirmed: boolean,
    year: number,
    quarter: number
  ): Observable<IEnquiry[]> {
    const refField: string = isConfirmed ? 'refContract' : 'refEnquiry';
    const numberField: string = refField + 'Number';
    const yearField: string = refField + 'Year';
    const monthField: string = refField + 'Month';

    const months: number[] = [];

    for (let i = 1; i <= 3; i++) {
      months.push((quarter - 1) * 3 + i);
    }

    return this.enquiryFirestoreService.collectionValues$(ref =>
      ref.where(yearField, '==', year).where(monthField, 'in', months).orderBy(numberField, 'desc')
    );
  }

  getAllEnquiryOfYear(isConfirmed: boolean, year: number): Observable<IEnquiry[]> {
    const refField: string = isConfirmed ? 'refContract' : 'refEnquiry';
    const numberField: string = refField + 'Number';
    const yearField: string = refField + 'Year';

    return this.enquiryFirestoreService.collectionValues$(ref =>
      ref.where(yearField, '==', year).orderBy(numberField, 'desc')
    );
  }

  getFromIds(ids: string[]): Promise<IEnquiry[]> {
    return this.enquiryFirestoreService.getDocumentsFromDocId(ids);
  }
}
