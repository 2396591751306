import { Injectable } from '@angular/core';
import { IStats } from 'src/app/interfaces/stats.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class StatsFirestoreService extends FirestoreService<IStats> {
  protected basePath = 'stats';
}
