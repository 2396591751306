import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { IPdfParserSettingFirestoreService } from './pdf-parser-settings-firestore.service';
import { IPdfParserSetting } from 'src/app/interfaces/pdf-parser-setting.interface';

@Injectable({
  providedIn: 'root'
})
export class PdfParserSettingService {
  constructor(private IPdfParserSettingFirestoreService: IPdfParserSettingFirestoreService) {}

  create(IPdfParserSetting: IPdfParserSetting): Promise<string> {
    return this.IPdfParserSettingFirestoreService.create(IPdfParserSetting);
  }

  createWithId(id: string, IPdfParserSetting: IPdfParserSetting): Promise<string> {
    return this.IPdfParserSettingFirestoreService.createWithId(id, IPdfParserSetting);
  }

  update(IPdfParserSetting: IPdfParserSetting): Promise<string> {
    return this.IPdfParserSettingFirestoreService.update(IPdfParserSetting);
  }

  delete(id: string): Promise<void> {
    return this.IPdfParserSettingFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IPdfParserSetting> {
    return this.IPdfParserSettingFirestoreService.docValue$(id);
  }

  getAll(): Observable<IPdfParserSetting[]> {
    return this.IPdfParserSettingFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.IPdfParserSettingFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IPdfParserSetting[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.IPdfParserSettingFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }
}
