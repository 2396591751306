import { Injectable } from '@angular/core';
import { IFbo } from 'src/app/interfaces/fbo.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class FboFirestoreService extends FirestoreService<IFbo> {
  protected basePath = 'fbos';
}
