<breadcrumbs
    [items]="[
        { title: 'Liste des modèles d\'avions', path: '/admin/aircraft-models/list' },
        {
            title: 'Statistiques',
            path: '/admin/statistics/aircraft-models'
        }
    ]"
></breadcrumbs>

<div class="row">
    <div class="col-sm-6 col-md-4 col-lg-3">
        <div class="form-group">
            <label for="filterByPeriod">Filtrer par période</label>
            <div class="input-group">
                <select
                    class="form-select"
                    id="filterByPeriod"
                    [(ngModel)]="filterPeriod"
                    (change)="updatedFilterPeriod()"
                >
                    <option
                        [value]="filter.value"
                        *ngFor="let filter of filtersByPeriod"
                        [innerHtml]="filter.title"
                    ></option>
                </select>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-sm-6 col-lg-4 col-xl-12">
        <div class="text-center">
            <div class="btn-group mb-4" role="group" aria-label="Basic radio toggle button group">
                <ng-container *ngFor="let enquiryType of getEnquiryTypes()">
                    <input
                        type="radio"
                        class="btn-check"
                        name="tab"
                        [id]="'tab-' + enquiryType"
                        autocomplete="off"
                        [checked]="enquiryTypeSelected === enquiryType"
                        (change)="selectEnquiryTypeTab(enquiryType)"
                    />
                    <label class="btn btn-outline-primary" [attr.for]="'tab-' + enquiryType"
                        >{{
                            enquiryType === "all"
                                ? "Tous les départements"
                                : getEnumEnquiryTypeLabel(enquiryType)
                        }}
                    </label>
                </ng-container>
            </div>
        </div>

        <div class="card card-statistics mb-4">
            <table class="table table-sm mb-0">
                <thead>
                    <tr>
                        <th>Appareil</th>
                        <td class="text-center">Nombre de demandes</td>
                    </tr>
                </thead>
                <tbody *ngIf="loading">
                    <tr *ngFor="let number of [].constructor(5)">
                        <td><div class="skeleton-text mb-0"></div></td>
                        <td><div class="skeleton-text mb-0"></div></td>
                    </tr>
                </tbody>
                <ng-container *ngIf="!loading">
                    <tbody *ngIf="!stats[enquiryTypeSelected]?.data?.length">
                        <tr class="table-warning text-center">
                            <td [attr.colspan]="2">
                                Aucun demande confirmée pour ce département n'a été trouvée.
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="stats[enquiryTypeSelected]?.data?.length">
                        <tr *ngFor="let line of stats[enquiryTypeSelected].data">
                            <td>
                                <a
                                    [href]="'/admin/aircraft-models/view/' + line.aircraftModelId"
                                    [innerText]="line.modelTitle.toUpperCase()"
                                ></a>
                            </td>
                            <td class="text-center" [innerText]="line.count || 0"></td>
                        </tr>
                    </tbody>
                </ng-container>
            </table>
        </div>
    </div>
</div>
