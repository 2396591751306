import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { IFinancialReportClient } from 'src/app/interfaces/financial-report-client.interface';

@Injectable({
  providedIn: 'root'
})
export class FinancialReportClientsFirestoreService extends FirestoreService<IFinancialReportClient> {
  protected basePath = 'financialReportsByClients';
}
