import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { getYear } from 'date-fns';
import {
  EnumCurrency,
  getDefaultCurrency,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { EnumPeriod, getPeriodLabel } from '../financial-reports.component';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import {
  getMonthLabelByQuarter,
  getMonthLabel,
  formatPrice,
  roundNumber
} from 'src/app/misc.utils';
import { Subscription } from 'rxjs';
import {
  EnumFinancialReportDepartmentEnquiryType,
  IFinancialReportDepartment,
  getEnquiryTypesWithoutVirtual,
  getFinancialReportDepartmentEnquiryTypeLabel
} from 'src/app/interfaces/financial-report-department.interface';
import { FinancialReportDepartmentsService } from 'src/app/services/financial-report-departments/financial-report-departments.service';
import { UserService } from 'src/app/services/user/user.service';

enum EnumFinancialReportDepartmentVirtualField {
  marginHTPercent = 'marginHTPercent',
  nbContractsPercent = 'nbContractsPercent',
  caPercent = 'caPercent'
}

interface IFinancialReportDepartmentVirtualFields {
  marginHTPercent: number;
  nbContractsPercent: number;
  caPercent: number;
}

interface IFinancialReportDepartmentAllAndTypes {
  label: string;
  all: IFinancialReportDepartment | null;
  financialReportDepartmentsByType: IFinancialReportDepartmentByType;
  virtualFields: IFinancialReportDepartmentVirtualFieldsAllAndTypes;
}
interface IFinancialReportDepartmentByType {
  [enquiryType: string]: IFinancialReportDepartment;
}

interface IFinancialReportDepartmentVirtualFieldsAllAndTypes {
  [enquiryType: string]: IFinancialReportDepartmentVirtualFields | null;
  all: IFinancialReportDepartmentVirtualFields | null;
}

@Component({
  selector: 'app-financial-report-department',
  templateUrl: './financial-report-department.component.html',
  styleUrls: ['./financial-report-department.component.scss']
})
export class FinancialReportDepartmentComponent implements OnInit, OnDestroy, OnChanges {
  @Input('currentYear') currentYear: number = getYear(new Date());
  @Input('currentCurrency') currentCurrency: EnumCurrency | null = null;
  @Input('currentPeriod') currentPeriod: EnumPeriod = EnumPeriod.months;

  @Output() updateCurrentPeriod: EventEmitter<EnumPeriod> = new EventEmitter();

  @ViewChild('modalUsersList', { static: false }) modalUsersListElement: ElementRef;

  getPeriodLabel = getPeriodLabel;
  getMonthLabelByQuarter = getMonthLabelByQuarter;
  getMonthLabel = getMonthLabel;
  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getUserFullname = getUserFullname;
  getFinancialReportDepartmentEnquiryTypeLabel = getFinancialReportDepartmentEnquiryTypeLabel;
  formatPrice = formatPrice;
  roundNumber = roundNumber;
  getDefaultCurrency = getDefaultCurrency;

  EnumPeriod = EnumPeriod;
  EnumFinancialReportDepartmentEnquiryType = EnumFinancialReportDepartmentEnquiryType;
  EnumFinancialReportDepartmentVirtualField = EnumFinancialReportDepartmentVirtualField;

  loading: boolean = false;
  loadingYearsOnly: boolean = false;

  financialReportDepartments: IFinancialReportDepartment[] = [];
  financialReportDepartmentsOnlyYears: IFinancialReportDepartment[] = [];
  financialReportDepartmentsByPeriod: {
    [period: string]: {
      [year: string]: IFinancialReportDepartmentAllAndTypes[];
    };
  } = {};

  subscriptions = new Subscription();
  subscriptionsForYear = new Subscription();

  loadingUsers: boolean = false;
  usersObj: { [key: string]: IUser } = {};
  usersDisplayedInModalList: string[] = [];

  constructor(
    private financialReportDepartmentsService: FinancialReportDepartmentsService,
    private userService: UserService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadAllUsers();

    if (changes.currentYear || changes.currentCurrency) {
      this.loadAll();
    }
  }

  ngOnDestroy(): void {
    this.subscriptionsForYear.unsubscribe();
    this.subscriptions.unsubscribe();

    this.removeModal();
  }

  getPeriods(): EnumPeriod[] {
    return Object.values(EnumPeriod);
  }

  getEnquiryTypes(): EnumFinancialReportDepartmentEnquiryType[] {
    return Object.values(EnumFinancialReportDepartmentEnquiryType);
  }

  removeModal(): void {
    window['$'](this.modalUsersListElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  getData(): IFinancialReportDepartmentAllAndTypes[] {
    switch (this.currentPeriod) {
      case EnumPeriod.years:
        return this.financialReportDepartmentsByPeriod[this.currentPeriod].all;
      default:
        return this.financialReportDepartmentsByPeriod[this.currentPeriod][this.currentYear];
    }
  }

  changeCurrentPeriod(newPeriod: EnumPeriod): void {
    this.currentPeriod = newPeriod;

    this.updateCurrentPeriod.emit(this.currentPeriod);
  }

  openUserList(usersId: string[]): void {
    window['$'](this.modalUsersListElement.nativeElement).modal('show');

    this.usersDisplayedInModalList = usersId;
  }

  loadAllUsers(): void {
    this.loadingUsers = true;

    this.subscriptions.add(
      this.userService.getAll().subscribe((users: IUser[]) => {
        for (const user of users) {
          this.usersObj[user.id] = user;
        }

        setTimeout(() => {
          this.loadingUsers = false;
        }, 1000);
      })
    );
  }

  loadAll(): void {
    this.loading = true;

    this.resetSubscriptionForYear();

    this.resetFinancialReportDepartementsDisplayedData(this.currentYear);

    this.loadFinancialReports();
    this.loadFinancialReportDepartmentsYearsOnly();
  }

  resetSubscriptionForYear(): void {
    if (this.subscriptionsForYear) {
      this.subscriptionsForYear.unsubscribe();

      this.subscriptionsForYear = new Subscription();
    }
  }

  loadFinancialReportDepartmentsYearsOnly(): void {
    this.loadingYearsOnly = true;

    // By years
    this.subscriptionsForYear.add(
      this.financialReportDepartmentsService
        .getAllForCurrencyOnlyYears(this.currentCurrency)
        .subscribe((financialReportDepartments: IFinancialReportDepartment[]) => {
          this.financialReportDepartmentsOnlyYears = financialReportDepartments;

          this.organiseFinancialReportDepartementsYearsOnly();

          this.loadingYearsOnly = false;
        })
    );
  }

  organiseFinancialReportDepartementsYearsOnly(): void {
    this.financialReportDepartmentsByPeriod[EnumPeriod.years] = {
      all: []
    };

    const financialReportDepartmentsByYearObj: {
      [year: string]: IFinancialReportDepartmentAllAndTypes;
    } = {};

    for (const financialReportDepartment of this.financialReportDepartmentsOnlyYears) {
      if (
        typeof financialReportDepartmentsByYearObj[financialReportDepartment.year] === 'undefined'
      ) {
        const objectByEnquiryTypes: IFinancialReportDepartmentByType = {};
        const virtualFieldsByEnquiryTypes: IFinancialReportDepartmentVirtualFieldsAllAndTypes = {
          all: {} as IFinancialReportDepartmentVirtualFields
        };
        for (const enquiryType of this.getEnquiryTypes()) {
          objectByEnquiryTypes[enquiryType] = null;
          virtualFieldsByEnquiryTypes[enquiryType] = {} as IFinancialReportDepartmentVirtualFields;
        }
        for (const field in virtualFieldsByEnquiryTypes) {
          for (const virtualField of Object.values(EnumFinancialReportDepartmentVirtualField)) {
            virtualFieldsByEnquiryTypes[field][virtualField] = 0;
          }
        }

        financialReportDepartmentsByYearObj[financialReportDepartment.year] = {
          label: financialReportDepartment.year.toString(),
          all: null,
          financialReportDepartmentsByType: this.cloneVariable(objectByEnquiryTypes),
          virtualFields: this.cloneVariable(virtualFieldsByEnquiryTypes)
        };
      }

      if (financialReportDepartment.enquiryType) {
        financialReportDepartmentsByYearObj[
          financialReportDepartment.year
        ].financialReportDepartmentsByType[financialReportDepartment.enquiryType] =
          financialReportDepartment;
      } else {
        financialReportDepartmentsByYearObj[financialReportDepartment.year].all =
          financialReportDepartment;
      }
    }

    this.financialReportDepartmentsByPeriod[EnumPeriod.years].all = Object.values(
      financialReportDepartmentsByYearObj
    ).reverse();

    for (let financialReportDepartmentAllAndTypes of this.financialReportDepartmentsByPeriod[
      EnumPeriod.years
    ].all) {
      financialReportDepartmentAllAndTypes = this.setVirtualFieldsForFinancialReportDepartment(
        financialReportDepartmentAllAndTypes
      );
    }

    this.loadingYearsOnly = false;
  }

  getPercentage(value: number, total: number): number {
    if (total !== 0) {
      return roundNumber((value * 100) / total);
    }

    return 0;
  }

  loadFinancialReports(): void {
    this.subscriptionsForYear.add(
      this.financialReportDepartmentsService
        .getAllForCurrencyForYear(this.currentCurrency, this.currentYear)
        .subscribe((financialReportDepartments: IFinancialReportDepartment[]) => {
          this.financialReportDepartments = financialReportDepartments;

          this.organiseFinancialReportDepartements(this.currentYear);
        })
    );
  }

  organiseFinancialReportDepartements(year: number): void {
    for (const financialReportDepartment of this.financialReportDepartments) {
      if (financialReportDepartment.month) {
        if (financialReportDepartment.enquiryType) {
          this.financialReportDepartmentsByPeriod[EnumPeriod.months][year][
            financialReportDepartment.month - 1
          ].financialReportDepartmentsByType[financialReportDepartment.enquiryType] =
            this.cloneVariable(financialReportDepartment);
        } else {
          this.financialReportDepartmentsByPeriod[EnumPeriod.months][year][
            financialReportDepartment.month - 1
          ].all = this.cloneVariable(financialReportDepartment);
        }
      } else if (financialReportDepartment.quarter) {
        if (financialReportDepartment.enquiryType) {
          this.financialReportDepartmentsByPeriod[EnumPeriod.quarters][year][
            financialReportDepartment.quarter - 1
          ].financialReportDepartmentsByType[financialReportDepartment.enquiryType] =
            this.cloneVariable(financialReportDepartment);
        } else {
          this.financialReportDepartmentsByPeriod[EnumPeriod.quarters][year][
            financialReportDepartment.quarter - 1
          ].all = this.cloneVariable(financialReportDepartment);
        }
      }
    }

    for (const period of this.getPeriods()) {
      for (const year in this.financialReportDepartmentsByPeriod[period]) {
        for (let financialReportDepartmentAllAndTypes of this.financialReportDepartmentsByPeriod[
          period
        ][year]) {
          financialReportDepartmentAllAndTypes = this.setVirtualFieldsForFinancialReportDepartment(
            financialReportDepartmentAllAndTypes
          );
        }
      }
    }

    this.loading = false;
  }

  setVirtualFieldsForFinancialReportDepartment(
    financialReportDepartmentAllAndTypes: IFinancialReportDepartmentAllAndTypes
  ): IFinancialReportDepartmentAllAndTypes {
    for (const enquiryType of this.getEnquiryTypes()) {
      for (const virtualField of Object.values(EnumFinancialReportDepartmentVirtualField)) {
        switch (virtualField) {
          default:
            const field: string = (virtualField as string).replace('Percent', '');
            if (
              financialReportDepartmentAllAndTypes.financialReportDepartmentsByType[enquiryType] !==
                null &&
              financialReportDepartmentAllAndTypes.financialReportDepartmentsByType[enquiryType][
                field
              ] &&
              financialReportDepartmentAllAndTypes.all !== null &&
              financialReportDepartmentAllAndTypes.all[field]
            ) {
              financialReportDepartmentAllAndTypes.virtualFields[enquiryType][
                virtualField as string
              ] = this.getPercentage(
                financialReportDepartmentAllAndTypes.financialReportDepartmentsByType[enquiryType][
                  field
                ],
                financialReportDepartmentAllAndTypes.all[field]
              );
            }

            break;
        }
      }
    }

    for (const virtualField of Object.values(EnumFinancialReportDepartmentVirtualField)) {
      financialReportDepartmentAllAndTypes.virtualFields.all[virtualField as string] = 0;

      for (const enquiryType of getEnquiryTypesWithoutVirtual()) {
        financialReportDepartmentAllAndTypes.virtualFields.all[virtualField as string] +=
          financialReportDepartmentAllAndTypes.virtualFields[enquiryType][virtualField];
      }
    }

    return financialReportDepartmentAllAndTypes;
  }

  resetFinancialReportDepartementsDisplayedData(year: number): void {
    for (const period of this.getPeriods()) {
      this.financialReportDepartmentsByPeriod[period] = {};

      this.financialReportDepartmentsByPeriod[period][year] = [];

      const objectByEnquiryTypes: IFinancialReportDepartmentByType = {};
      const virtualFieldsByEnquiryTypes: IFinancialReportDepartmentVirtualFieldsAllAndTypes = {
        all: {} as IFinancialReportDepartmentVirtualFields
      };
      for (const enquiryType of this.getEnquiryTypes()) {
        objectByEnquiryTypes[enquiryType] = null;
        virtualFieldsByEnquiryTypes[enquiryType] = {} as IFinancialReportDepartmentVirtualFields;
      }
      for (const field in virtualFieldsByEnquiryTypes) {
        for (const virtualField of Object.values(EnumFinancialReportDepartmentVirtualField)) {
          virtualFieldsByEnquiryTypes[field][virtualField] = 0;
        }
      }

      let countItems: number = 1;
      switch (period) {
        case EnumPeriod.months:
          countItems = 12;
          break;
        case EnumPeriod.quarters:
          countItems = 4;
          break;
      }

      for (let i = 1; i <= countItems; i++) {
        this.financialReportDepartmentsByPeriod[period][year].push({
          label:
            period === EnumPeriod.quarters
              ? this.getMonthLabelByQuarter(i) + ' ' + this.currentYear
              : getMonthLabel(i) + ' ' + this.currentYear,
          all: null,
          financialReportDepartmentsByType: this.cloneVariable(objectByEnquiryTypes),
          virtualFields: this.cloneVariable(virtualFieldsByEnquiryTypes)
        });
      }
    }
  }

  cloneVariable(variable: any): any {
    return JSON.parse(JSON.stringify(variable));
  }
}
