import { Injectable } from '@angular/core';
import { IInvoice } from 'src/app/interfaces/invoice.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceFirestoreService extends FirestoreService<IInvoice> {
  protected basePath = 'invoices';
}
