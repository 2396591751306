import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, zip } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import firebase from 'firebase/compat/app';
import { Query } from '@firebase/firestore-types';

import { IEnquiry } from '../interfaces/enquiry.interface';
import { EnumCrawlerUrlStatus } from '../enums/crawler-url-status.enum';
import { EnumInvoiceType } from '../enums/invoice-type.enum';
import { IUser } from '../interfaces/user.interface';
import { IAirline } from '../interfaces/airline.interface';
import { IAircraft } from '../interfaces/aircraft.interface';
import { IAircraftCompiled } from '../interfaces/aircraft-compiled.interface';
import { IAircraftModel } from '../interfaces/aircraft-model.interface';
import { IAirport } from '../interfaces/airport.interface';
import { IUserGroup } from '../interfaces/user-group.interface';
import { IQuotation } from '../interfaces/quotation.interface';
import { IInvoice } from '../interfaces/invoice.interface';

@Injectable({
  providedIn: 'root'
})
export class RemoteService {
  userAuth: firebase.User;
  private userAuthBehavior: BehaviorSubject<firebase.User>;
  userAuthObservable: Observable<firebase.User>;

  user: IUser;
  private userBehavior: BehaviorSubject<IUser>;
  userObservable: Observable<IUser>;

  userGroup: IUserGroup | null;
  private userGroupBehavior: BehaviorSubject<IUserGroup | null>;
  userGroupObservable: Observable<IUserGroup | null>;

  isLogged: boolean;
  private isLoggedBehavior: BehaviorSubject<boolean>;
  isLoggedObservable: Observable<boolean>;

  aircraftModels: Array<IAircraftModel>;
  private aircraftModelsBehavior: BehaviorSubject<Array<IAircraftModel>>;
  aircraftModelsObservable: Observable<Array<IAircraftModel>>;

  firebaseSubscriptions: Array<Subscription> = [];

  constructor(
    private afAuth: AngularFireAuth,
    public afFirestore: AngularFirestore,
    private afStorage: AngularFireStorage,
    private http: HttpClient
  ) {
    this.userAuthBehavior = new BehaviorSubject<firebase.User>(null);
    this.userAuthObservable = this.userAuthBehavior.asObservable();
    this.userAuthObservable.subscribe((userAuth: firebase.User) => (this.userAuth = userAuth));

    this.userBehavior = new BehaviorSubject<IUser>(null);
    this.userObservable = this.userBehavior.asObservable();
    this.userObservable.subscribe((user: IUser) => {
      this.user = user;

      if (
        this.user &&
        this.user.userGroupId &&
        (!this.userGroup || (this.userGroup && this.user.userGroupId !== this.userGroup.id))
      ) {
        this.loadUserGroup(this.user.userGroupId);
      }
    });

    this.userGroupBehavior = new BehaviorSubject<IUserGroup | null>(null);
    this.userGroupObservable = this.userGroupBehavior.asObservable();
    this.userGroupObservable.subscribe(
      (userGroup: IUserGroup | null) => (this.userGroup = userGroup)
    );

    this.isLoggedBehavior = new BehaviorSubject<boolean>(null);
    this.isLoggedObservable = this.isLoggedBehavior.asObservable();
    this.isLoggedObservable.subscribe((isLogged: boolean) => (this.isLogged = isLogged));

    this.aircraftModelsBehavior = new BehaviorSubject<Array<IAircraftModel>>(null);
    this.aircraftModelsObservable = this.aircraftModelsBehavior.asObservable();
    this.aircraftModelsObservable.subscribe(
      (aircraftModels: Array<IAircraftModel>) => (this.aircraftModels = aircraftModels)
    );

    this.afAuth.onAuthStateChanged((userAuth: firebase.User) => {
      this.setUserAuth(userAuth);
      if (userAuth && userAuth.uid) {
        this.getUserFromAuthUid(userAuth.uid)
          .then(() => {
            this.setIsLogged(true);
          })
          .catch(err => {
            this.unsubscribeFirebaseSubscriptions();
            this.setIsLogged(false);
            console.log(err);
          });
      } else {
        this.unsubscribeFirebaseSubscriptions();
        this.setIsLogged(false);
      }
    });
  }

  unsubscribeFirebaseSubscriptions(): void {
    for (const firebaseSubscription of this.firebaseSubscriptions) {
      firebaseSubscription.unsubscribe();
    }
    this.firebaseSubscriptions = [];
  }

  setUserAuth(userAuth: firebase.User): void {
    this.userAuthBehavior.next(userAuth);
  }

  setUser(user: IUser): void {
    this.userBehavior.next(user);
  }

  setUserGroup(userGroup: IUserGroup | null): void {
    this.userGroupBehavior.next(userGroup);
  }

  setIsLogged(isLogged: boolean): void {
    this.isLoggedBehavior.next(isLogged);
  }

  setAircraftModels(aircraftModels: Array<IAircraftModel>): void {
    this.aircraftModelsBehavior.next(aircraftModels);
  }

  async refreshUser(): Promise<void> {
    if (this.userAuth) {
      await this.getUserFromAuthUid(this.userAuth.uid);
    }
  }

  getUserFromAuthUid(authUID: string): Promise<IUser> {
    return new Promise((resolve, reject) => {
      this.firebaseSubscriptions.push(
        this.afFirestore
          .collection('users', ref => ref.where('userAuthUID', '==', authUID))
          .snapshotChanges()
          .subscribe(documentChanges => {
            documentChanges.forEach(documentChange => {
              let documentData: any = documentChange.payload.doc.data();
              documentData['id'] = documentChange.payload.doc.id;

              const user: IUser = documentData as IUser;

              this.setUser(this.timestampsToDate(user));

              resolve(user);
            });

            reject({
              message: "L'utilisateur n'existe pas."
            });
          })
      );
    });
  }

  loadUserGroup(userGroupId: string): void {
    this.firebaseSubscriptions.push(
      this.afFirestore
        .collection('userGroups')
        .doc(userGroupId)
        .snapshotChanges()
        .subscribe(documentChange => {
          let documentData: any = documentChange.payload.data();
          documentData['id'] = documentChange.payload.id;

          this.setUserGroup(documentData as IUserGroup);
        })
    );
  }

  resetPassword(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afAuth
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  addDocumentToCollection(collection: string, data: Object): Promise<string> {
    return new Promise((resolve, reject) => {
      data['created'] = new Date();
      data['modified'] = data['created'];
      if (this.user) {
        data['createdBy'] = this.user.id;
        data['modifiedBy'] = data['createdBy'];
      }

      this.afFirestore
        .collection(collection)
        .add(data)
        .then((docRef: firebase.firestore.DocumentReference) => {
          resolve(docRef.id);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  addDocumentToCollectionWithId(collection: string, docId: string, data: Object): Promise<string> {
    return new Promise((resolve, reject) => {
      data['created'] = new Date();
      data['modified'] = data['created'];
      if (this.user) {
        data['createdBy'] = this.user.id;
        data['modifiedBy'] = data['createdBy'];
      }

      this.afFirestore
        .collection(collection)
        .doc(docId)
        .set(data, { merge: false })
        .then(() => {
          resolve(docId);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  updateDocumentToCollection(collection: string, docId: string, data: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!data['modified']) {
        data['modified'] = new Date();
      }
      if (this.user) {
        data['modifiedBy'] = this.user.id;
      }

      if (data['id']) {
        delete data['id'];
      }

      this.afFirestore
        .collection(collection)
        .doc(docId)
        .update(data)
        .then(() => {
          resolve(docId);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  setDocumentToCollection(collection: string, docId: string, data: Object): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!data['created']) {
        data['created'] = new Date();
      }
      if (!data['modified']) {
        data['modified'] = new Date();
      }
      if (this.user) {
        data['modifiedBy'] = this.user.id;
      }

      if (data['id']) {
        delete data['id'];
      }

      this.afFirestore
        .collection(collection)
        .doc(docId)
        .set(data)
        .then(() => {
          resolve(docId);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  deleteDocumentInCollection(collection: string, docId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection(collection)
        .doc(docId)
        .delete()
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  signIn(email: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afAuth
        .signInWithEmailAndPassword(email, password)
        .then((userCredential: firebase.auth.UserCredential) => {
          this.getUser(email)
            .then((doc: firebase.firestore.DocumentData) => {
              let data = doc.data();
              data['id'] = doc.id;

              this.setUser(this.timestampsToDate(data as IUser));

              resolve();
            })
            .catch(err => {
              this.signOut();
              reject(err);
            });
        })
        .catch(err => {
          switch (err.code) {
            case 'auth/user-mismatch':
              err.message = 'Compte utilisateur introuvable.';
              break;
            case 'auth/user-not-found':
              err.message = 'Compte utilisateur introuvable.';
              break;
            case 'auth/invalid-credential':
              err.message = "L'authentification de l'utilisateur n'est plus valide.";
              break;
            case 'auth/invalid-email':
              err.message = "L'email est invalide.";
              break;
            case 'auth/wrong-password':
              err.message = 'Le mot de passe est invalide.';
              break;
            case 'auth/too-many-enquiries':
              err.message =
                'Trop de tentatives de connexion échouées. Veuillez réessayer plus tard.';
              break;
          }

          reject(err);
        });
    });
  }

  signOut(): Promise<void> {
    return this.afAuth.signOut();
  }

  getUser(email: string): Promise<firebase.firestore.DocumentData> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('users', ref => ref.where('email', '==', email))
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          if (querySnapshot.empty) {
            reject({
              message: "L'utilisateur n'existe pas pour cet email."
            });
          } else {
            querySnapshot.forEach(doc => {
              resolve(doc);
            });
          }
        });
    });
  }

  updateCurrentUser(originalEmail: string, data: Object): Promise<void> {
    return new Promise((resolve, reject) => {
      if (data['email']) {
        let credential = firebase.auth.EmailAuthProvider.credential(
          originalEmail,
          data['password']
        );

        this.userAuth
          .reauthenticateWithCredential(credential)
          .then(() => {
            this.userAuth
              .updateEmail(data['email'])
              .then(() => {
                delete data['password'];

                this.updateDocumentToCollection('users', this.user.id, data)
                  .then(() => {
                    resolve();
                  })
                  .catch(err => {
                    reject(err);
                  });
              })
              .catch(err => {
                switch (err.code) {
                  case 'auth/invalid-email':
                    err.message = "L'email est invalide.";
                    break;
                  case 'auth/email-already-in-use':
                    err.message = "L'email est déjà utilisé par un autre utilisateur.";
                    break;
                }

                reject(err);
              });
          })
          .catch(err => {
            switch (err.code) {
              case 'auth/user-mismatch':
                err.message = 'Compte utilisateur introuvable.';
                break;
              case 'auth/user-not-found':
                err.message = 'Compte utilisateur introuvable.';
                break;
              case 'auth/invalid-credential':
                err.message = "L'authentification de l'utilisateur n'est plus valide.";
                break;
              case 'auth/invalid-email':
                err.message = "L'email est invalide.";
                break;
              case 'auth/wrong-password':
                err.message = 'Le mot de passe est invalide.';
                break;
              case 'auth/too-many-enquiries':
                err.message =
                  'Trop de tentatives de connexion échouées. Veuillez réessayer plus tard.';
                break;
            }

            reject(err);
          });
      } else {
        delete data['password'];

        this.updateDocumentToCollection('users', this.user.id, data)
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      }
    });
  }

  updateCurrentUserPassword(
    originalEmail: string,
    originalPassword: string,
    newPassword: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      let credential = firebase.auth.EmailAuthProvider.credential(originalEmail, originalPassword);

      this.userAuth
        .reauthenticateWithCredential(credential)
        .then(() => {
          this.userAuth
            .updatePassword(newPassword)
            .then(() => {
              resolve();
            })
            .catch(err => {
              switch (err.code) {
                case 'auth/weak-password':
                  err.message = "Le mot de passe n'est pas suffisamment sécurisé.";
                  break;
              }

              reject(err);
            });
        })
        .catch(err => {
          switch (err.code) {
            case 'auth/user-mismatch':
              err.message = 'Compte utilisateur introuvable.';
              break;
            case 'auth/user-not-found':
              err.message = 'Compte utilisateur introuvable.';
              break;
            case 'auth/invalid-credential':
              err.message = "L'authentification de l'utilisateur n'est plus valide.";
              break;
            case 'auth/invalid-email':
              err.message = "L'email est invalide.";
              break;
            case 'auth/wrong-password':
              err.message = 'Le mot de passe est invalide.';
              break;
            case 'auth/too-many-enquiries':
              err.message =
                'Trop de tentatives de connexion échouées. Veuillez réessayer plus tard.';
              break;
          }

          reject(err);
        });
    });
  }

  getDocument(collectionName: string, docId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      if (collectionName && docId) {
        this.afFirestore
          .collection(collectionName)
          .doc(docId)
          .get()
          .subscribe((docSnapshot: firebase.firestore.DocumentSnapshot) => {
            if (docSnapshot.exists) {
              const data: any = docSnapshot.data();
              data.id = docId;

              resolve(data);
            } else {
              reject('Document ' + collectionName + '/' + docId + ' not found.');
            }
          });
      } else {
        reject('Document ' + collectionName + '/' + docId + ' not found.');
      }
    });
  }

  getDocumentsFromDocId(collectionName: string, docsId: Array<string>): Promise<Array<object>> {
    return new Promise(async (resolve, reject) => {
      // Remove duplicates for optimization
      docsId = docsId.filter((value, index, self) => self.indexOf(value) === index);
      docsId = docsId.filter((value, index, self) => typeof value !== 'undefined');

      const chunkedDocsId: Array<Array<string>> = this.chunk(docsId, 10);

      let results: Array<object> = [];
      for (const docsIdChunked of chunkedDocsId) {
        results = results.concat(
          await this.getDocumentsFromDocIdUpTo10(collectionName, docsIdChunked)
        );
      }

      resolve(results);
    });
  }

  private getDocumentsFromDocIdUpTo10(
    collectionName: string,
    docsId: Array<string>
  ): Promise<Array<object>> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection(collectionName, ref =>
          ref.where(firebase.firestore.FieldPath.documentId(), 'in', docsId)
        )
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          const results: Array<object> = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              let docData = doc.data();
              docData.id = doc.id;

              results.push(docData);
            });
          }

          resolve(results);
        });
    });
  }

  private chunk(array: Array<any>, size: number): Array<Array<any>> {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
      chunked_arr.push(array.slice(index, size + index));
      index += size;
    }

    return chunked_arr;
  }

  upload(folder = '', filename = null, file: any, encoding: string = 'raw'): object {
    // Create a reference to file
    if (!filename) {
      filename = file['name'];
    }

    let ref = this.afStorage.ref(folder + '/' + filename);

    return {
      ref: ref,
      task: encoding == 'file' ? ref.put(file) : ref.putString(file, encoding)
    };
  }

  async loadAircraftModels(): Promise<void> {
    const docs = await this.getAllDocuments('aircraftModels');

    const aircraftModels: Array<IAircraftModel> = [];
    for (const doc of docs) {
      aircraftModels.push(doc as IAircraftModel);
    }

    this.setAircraftModels(aircraftModels);
  }

  getAllDocuments(
    collectionName: string,
    orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'title',
      direction: 'asc'
    }
  ): Promise<Array<object>> {
    return new Promise((resolve, reject) => {
      const sub = this.afFirestore
        .collection(collectionName, ref => ref.orderBy(orderBy.field, orderBy.direction))
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          sub.unsubscribe();
          const result: Array<object> = [];
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
              const documentData = doc.data();

              documentData.id = doc.id;

              result.push(documentData);
            });
          }

          resolve(result);
        });
    });
  }

  getActiveAirlinesFromCountry(countryCode: string): Promise<IAirline[]> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('airlines', ref =>
          ref
            .orderBy('title', 'asc')
            .where('isActive', '==', true)
            .where('countryCode', '==', countryCode)
        )
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          const result: IAirline[] = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
              const documentData = doc.data();

              documentData.id = doc.id;

              result.push(documentData as IAirline);
            });
          }

          resolve(result);
        });
    });
  }

  getAirlineAircraftsModel(
    airlineId: string,
    isCargo: boolean = false
  ): Promise<IAircraftCompiled[]> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('aircraftsCompiled', ref =>
          ref
            .orderBy('type', 'asc')
            .where('airlineId', '==', airlineId)
            .where('isCargo', '==', isCargo)
        )
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          const result: IAircraftCompiled[] = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
              const documentData = doc.data();

              documentData.id = doc.id;

              result.push(documentData as IAircraftCompiled);
            });
          }

          resolve(result);
        });
    });
  }

  getEnquiryCotations(enquiryId: string): Promise<IQuotation[]> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('quotations', ref => ref.where('enquiryId', '==', enquiryId))
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          const result: IQuotation[] = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
              const documentData = doc.data();

              documentData.id = doc.id;

              result.push(documentData as IQuotation);
            });
          }

          resolve(result);
        });
    });
  }

  getEnquiryInvoices(enquiryId: string): Promise<IInvoice[]> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('invoices', ref => ref.where('enquiryId', '==', enquiryId))
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          const result: IInvoice[] = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
              const documentData = doc.data();

              documentData.id = doc.id;

              result.push(documentData as IInvoice);
            });
          }

          resolve(result);
        });
    });
  }

  async getLatestInvoiceOfYear(year: number): Promise<IInvoice> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('invoices', ref =>
          ref.where('refYear', '==', year).orderBy('refNumber', 'desc').limit(1)
        )
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          let result: IInvoice;

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
              const documentData = doc.data();
              documentData.id = doc.id;

              resolve(documentData as IInvoice);
            });
          }

          resolve(result);
        });
    });
  }

  async getLatestDefinitiveInvoiceOfYear(invoiceType: string, year: number): Promise<IInvoice> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('invoices', ref =>
          ref
            .where('refYear', '==', year)
            .where('invoiceType', '==', invoiceType)
            .orderBy('refNumber', 'desc')
            .limit(1)
        )
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          let result: IInvoice;

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
              const documentData = doc.data();
              documentData.id = doc.id;

              resolve(documentData as IInvoice);
            });
          }

          resolve(result);
        });
    });
  }

  async getLatestDefinitiveInvoiceOfMonth(
    invoiceType: string,
    year: number,
    month: number
  ): Promise<IInvoice> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('invoices', ref =>
          ref
            .where('refYear', '==', year)
            .where('refMonth', '==', month)
            .where('invoiceType', '==', invoiceType)
            .orderBy('refNumber', 'desc')
            .limit(1)
        )
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          let result: IInvoice;

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
              const documentData = doc.data();
              documentData.id = doc.id;

              resolve(documentData as IInvoice);
            });
          }

          resolve(result);
        });
    });
  }

  async getLatestProformaOfEnquiry(enquiryId: string): Promise<IInvoice> {
    return new Promise((resolve, reject) => {
      const numberField: string = 'refNumber';

      this.afFirestore
        .collection('invoices', ref =>
          ref
            .where('enquiryId', '==', enquiryId)
            .where('invoiceType', '==', EnumInvoiceType.proforma)
            .orderBy('versionNumber', 'desc')
            .limit(1)
        )
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          let result: IInvoice;

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
              const documentData = doc.data();
              documentData.id = doc.id;

              resolve(documentData as IInvoice);
            });
          }

          resolve(result);
        });
    });
  }

  generateRandomId(): string {
    return Math.floor(new Date().valueOf() * Math.random()).toString();
  }

  searchEnquiryByRef(enquiryRef: string): Promise<IEnquiry[]> {
    return new Promise(async (resolve, reject) => {
      const enquiryTitleRequest = this.afFirestore
        .collection('enquiries', ref => ref.where('refEnquiryTitle', '==', enquiryRef))
        .get();
      const contractTitleRequest = this.afFirestore
        .collection('enquiries', ref => ref.where('refContractTitle', '==', enquiryRef))
        .get();

      let result: Array<IEnquiry> = [];

      enquiryTitleRequest.subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
            const documentData = doc.data();
            documentData.id = doc.id;

            result.push(documentData as IEnquiry);
          });
        }

        contractTitleRequest.subscribe(
          (querySnapshotContract: firebase.firestore.QuerySnapshot) => {
            if (!querySnapshotContract.empty) {
              querySnapshotContract.forEach((doc: firebase.firestore.DocumentData) => {
                const documentData = doc.data();
                documentData.id = doc.id;

                result.push(documentData as IEnquiry);
              });
            }

            resolve(result);
          }
        );
      });
    });
  }

  getAllClientsEnquiries(
    clientId: string,
    conditions: Array<{
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }> = []
  ): Promise<Array<IEnquiry>> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('enquiries', (ref: firebase.firestore.CollectionReference) => {
          let query: Query = ref;

          query = query.where('clientId', '==', clientId);

          if (conditions.length) {
            for (let condition of conditions) {
              query = query.where(condition['field'], condition['operator'], condition['value']);
            }
          }

          return query;
        })
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          let result: Array<IEnquiry> = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
              const documentData = doc.data();
              documentData.id = doc.id;

              result.push(documentData as IEnquiry);
            });
          }

          resolve(result);
        });
    });
  }

  getAllAircraftsCompiled(
    conditions: Array<{
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }>
  ): Promise<IAircraftCompiled[]> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('aircraftsCompiled', (ref: firebase.firestore.CollectionReference) => {
          let query: Query = ref;

          if (conditions.length) {
            for (let condition of conditions) {
              query = query.where(condition['field'], condition['operator'], condition['value']);
            }
          }

          // query = query.limit(10)

          return query;
        })
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          let result: IAircraftCompiled[] = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
              const documentData = doc.data();
              documentData.id = doc.id;

              result.push(documentData as IAircraftCompiled);
            });
          }

          resolve(result);
        });
    });
  }

  getAllAircraftsOfHomebase(homebase: string): Promise<IAircraft[]> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('aircrafts', (ref: firebase.firestore.CollectionReference) => {
          let query: Query = ref.where('homebase', '==', homebase);

          // query = query.limit(10)

          return query;
        })
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          let result: IAircraft[] = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
              const documentData = doc.data();
              documentData.id = doc.id;

              result.push(documentData as IAircraft);
            });
          }

          resolve(result);
        });
    });
  }

  httpGet(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };

      this.http.get(url, httpOptions).subscribe(
        (data: any) => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  httpPost(url: string, data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };

      this.http.post(url, data, httpOptions).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  httpPut(url: string, data: object): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };

      this.http.put(url, data, httpOptions).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  httpDelete(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      };

      this.http.delete(url, httpOptions).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  jsonpRequest(url: string): object {
    return new Promise((resolve, _reject) => {
      let callbackName = 'jsonp_callback_' + Math.round(100000 * Math.random());
      window[callbackName] = (data: any) => {
        delete window[callbackName];
        document.body.removeChild(script);
        resolve(data);
      };

      let script = document.createElement('script');
      script.src = url + (url.indexOf('?') >= 0 ? '&' : '?') + 'callback=' + callbackName;
      document.body.appendChild(script);
    });
  }

  convertHtmlToPlainText(html: string): string {
    let temp = document.createElement('div');
    html = html.replace(new RegExp('</div><div', 'g'), '</div><br><div');
    temp.innerHTML = html.replace(new RegExp('<br>', 'g'), '\\n');

    return temp.textContent || temp.innerText || '';
  }

  async getTimezoneFromCoordinates(lat: number, lng: number, timestamp: number): Promise<object> {
    if (isNaN(timestamp)) {
      Promise.reject('Invalid date to fetch timezone.');
    } else {
      const url: string =
        'https://us-central1-outil-prod-aa.cloudfunctions.net/getTimezoneData?lat=' +
        lat +
        '&lng=' +
        lng +
        '&timestamp=' +
        timestamp +
        '&key=AIzaSyDyPoaFu7WvRZjqmSlQq0QdWA1FS_RLMQw';

      const result: {
        success: boolean;
        data: object;
        err: any;
      } = await this.httpGet(url);
      if (result.success) {
        return result.data;
      } else {
        Promise.reject(result.err ? result.err : null);
      }
    }
  }

  formatBytes(bytes: number, decimals: number = 2): string {
    if (bytes == 0) return '0 Bytes';
    var k = 1024,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
  }

  getUserFromUserGroup(userGroupId: string): Promise<IUser[]> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('users', ref => ref.where('userGroupId', '==', userGroupId))
        .get()
        .subscribe((querySnapshot: firebase.firestore.QuerySnapshot) => {
          const results: Array<IUser> = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              let docData = doc.data();
              docData.id = doc.id;

              results.push(docData as IUser);
            });
          }

          resolve(results);
        });
    });
  }

  getAllInvoices(): Promise<IInvoice[]> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('invoices')
        .get()
        .subscribe(async (querySnapshot: firebase.firestore.QuerySnapshot) => {
          const results: IInvoice[] = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              let docData = doc.data();
              docData.id = doc.id;

              if (typeof docData.definitiveInvoice === 'undefined') {
                results.push(docData as IInvoice);
              }
            });
          }
          console.log('invoice count', results.length);
          for (const result of results) {
            if (result.invoiceType === EnumInvoiceType.proforma) {
              result.definitiveInvoice = false;
            } else {
              result.definitiveInvoice = true;
            }

            await this.updateDocumentToCollection('invoices', result.id, {
              definitiveInvoice: result.invoiceType === EnumInvoiceType.proforma ? false : true
            });
          }
          console.log('FINI');

          resolve(results);
        });
    });
  }

  getAllAirports(): Promise<IAirport[]> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('airports', ref => ref.limit(1).where('isLieuDit', '==', false))
        .get()
        .subscribe(async (querySnapshot: firebase.firestore.QuerySnapshot) => {
          const results: Array<any> = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              let docData = doc.data();
              docData.id = doc.id;
              delete docData.created;
              delete docData.modified;
              delete docData.createdBy;
              delete docData.modifiedBy;

              results.push({
                lat: docData.latitude,
                lng: docData.longitude,
                iata: docData.iataCode
              });
            });
          }

          console.log(JSON.stringify(results));

          resolve(results);
        });
    });
  }

  getAllAirlinesNotFromAirfleets(): Promise<IAirport[]> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('airlines', ref => ref.where('url', '==', null))
        .get()
        .subscribe(async (querySnapshot: firebase.firestore.QuerySnapshot) => {
          const results: IAirport[] = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              let docData = doc.data();
              docData.id = doc.id;

              results.push(docData as IAirport);
            });
          }

          console.log(JSON.stringify(results));

          resolve(results);
        });
    });
  }

  getAllAircraftsNotFromAirfleets(): Promise<IAircraft[]> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('aircrafts', ref => ref.where('url', '==', ''))
        .get()
        .subscribe(async (querySnapshot: firebase.firestore.QuerySnapshot) => {
          const results: IAircraft[] = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              let docData = doc.data();
              docData.id = doc.id;

              results.push(docData as IAircraft);
            });
          }

          console.log(JSON.stringify(results));

          resolve(results);
        });
    });
  }

  getAllAirlinesInactives(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('crawlerUrls', ref => ref)
        .get()
        .subscribe(async (querySnapshot: firebase.firestore.QuerySnapshot) => {
          const crawlerToUpdateIds: any[] = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              let docData = doc.data();
              docData.id = doc.id;

              if (docData.url.match('flottecie')) {
                crawlerToUpdateIds.push(doc.ref);
              }
            });
          }

          const chunkedDocs: Array<Array<any>> = this.chunk(crawlerToUpdateIds, 500);

          for (const docs of chunkedDocs) {
            const batch = this.afFirestore.firestore.batch();

            for (const doc of docs) {
              batch.update(doc, {
                status: EnumCrawlerUrlStatus.notStarted,
                nextDateToUpdate: new Date('2022') // Force to refresh first
              });
            }

            await batch.commit();
          }

          console.log('finished');

          resolve();
        });
    });
  }

  refreshAllAirlines(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afFirestore
        .collection('airlines', ref =>
          ref.where('modified', '<=', new Date('2023-03-31 09:20')).limit(500)
        )
        .get()
        .subscribe(async (querySnapshot: firebase.firestore.QuerySnapshot) => {
          const results: Array<any> = [];

          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              let docData = doc.data();
              docData.id = doc.id;

              results.push(docData);
            });
          }

          for (const airline of results) {
            await this.updateDocumentToCollection('airlines', airline.id, {
              modified: new Date()
            });

            console.log('Airline ' + airline.id + ' (' + airline.title + ') updated.');
          }

          console.log('finished');

          resolve();
        });
    });
  }

  timestampsToDate(data: any): any {
    for (const field in data) {
      if (data[field] && typeof data[field].seconds !== 'undefined') {
        data[field] = (data[field] as unknown as firebase.firestore.Timestamp).toDate();
      }
    }

    return data;
  }
}
