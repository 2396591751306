export enum EnumEnquiryCancelledReason {
  cancelled = 'cancelled',
  competition = 'competition',
  directWithAirline = 'direct-with-airline',
  anotherAgency = 'another-agency',
  otherTransportationType = 'other-transportation-type'
}

export const getEnumEnquiryCancelledReasonLabel = (
  cancelledReason: EnumEnquiryCancelledReason
): string => {
  switch (cancelledReason) {
    default:
      return cancelledReason;
    case EnumEnquiryCancelledReason.cancelled:
      return 'Dossier annulé';
    case EnumEnquiryCancelledReason.competition:
      return 'Dossier broker concurent';
    case EnumEnquiryCancelledReason.directWithAirline:
      return 'Dossier en direct avec la compagnie';
    case EnumEnquiryCancelledReason.anotherAgency:
      return 'Dossier avec une autre agence';
    case EnumEnquiryCancelledReason.otherTransportationType:
      return 'Dossier avec un autre moyen de transport';
  }
};
