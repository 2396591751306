import { Injectable } from '@angular/core';
import { IEnquiryOffer } from 'src/app/interfaces/enquiry-offer.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class EnquiryOfferFirestoreService extends FirestoreService<IEnquiryOffer> {
  protected basePath = 'enquiryOffers';
}
