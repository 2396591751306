import { Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { Subscription } from 'rxjs';
import { IInvoice, getInvoiceTitle } from 'src/app/interfaces/invoice.interface';
import { InvoiceService } from 'src/app/services/invoices/invoices.service';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';
import { IEncaissement } from 'src/app/interfaces/encaissement.interface';

@Component({
  selector: 'app-encaissement-view',
  templateUrl: './encaissement-view.component.html',
  styleUrls: ['./encaissement-view.component.scss']
})
export class EncaissementViewComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  encaissementId: string;
  encaissement: IEncaissement | null = null;
  isLogged: boolean = false;

  bankAccount: IBankAccount | null = null;
  invoice: IInvoice | null = null;
  encaissementType: IEncaissementType | null = null;

  getInvoiceTitle = getInvoiceTitle;

  EnumAcl = EnumAcl;

  private subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private invoiceService: InvoiceService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(() => {
      this.encaissementId = this.activatedRoute.snapshot.paramMap.get('encaissementId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadEncaissement();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadEncaissement(): Promise<void> {
    this.loading = true;

    const doc = await this.remoteService.getDocument('encaissements', this.encaissementId);

    this.encaissement = doc as IEncaissement;

    await this.loadBankAccount();
    this.loadInvoice();
    await this.loadEncaissementType();

    this.loading = false;
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.encaissementsDelete)) {
      const result = confirm(
        "La suppression d'un compte bancaire sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        this.remoteService
          .deleteDocumentInCollection('encaissements', this.encaissementId)
          .then(() => {
            this.router.navigate(['/admin/encaissements/list']);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async loadBankAccount(): Promise<void> {
    if (this.encaissement.bankAccountId) {
      const doc = await this.remoteService.getDocument(
        'bankAccounts',
        this.encaissement.bankAccountId
      );

      this.bankAccount = doc as IBankAccount;
    }
  }

  loadInvoice(): void {
    if (this.encaissement.invoiceId && !this.invoice) {
      this.subscriptions.add(
        this.invoiceService
          .getFromId(this.encaissement.invoiceId)
          .subscribe((invoice: IInvoice) => {
            this.invoice = invoice;
          })
      );
    }
  }

  async loadEncaissementType(): Promise<void> {
    if (this.encaissement.encaissementTypeId) {
      const doc = await this.remoteService.getDocument(
        'encaissementTypes',
        this.encaissement.encaissementTypeId
      );

      this.encaissementType = doc as IEncaissementType;
    }
  }
}
