import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { RemoteService } from '../services/remote.service';
import { EnumAcl } from '../enums/acl.enum';
import { IUserGroup, getUserGroupRulesList } from '../interfaces/user-group.interface';

@Injectable({
  providedIn: 'root'
})
export class AclService {
  userGroup: IUserGroup | null = null;

  constructor(private remoteService: RemoteService, private router: Router) {
    this.remoteService.userGroupObservable.subscribe(
      (userGroup: IUserGroup | null) => (this.userGroup = userGroup)
    );
  }

  checkAclAccess(id: EnumAcl): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.userGroup) {
        if (getUserGroupRulesList(this.userGroup).indexOf(id) !== -1) {
          resolve();
        } else {
          this.router.navigate(['/not-authorised']);
          resolve();
        }
      } else {
        setTimeout(async () => {
          await this.checkAclAccess(id);
          resolve();
        }, 1000);
      }
    });
  }

  hasAclAccess(id: EnumAcl, userGroup: IUserGroup | null = null): boolean {
    if (userGroup) {
      return getUserGroupRulesList(userGroup).includes(id);
    } else if (this.userGroup) {
      return getUserGroupRulesList(this.userGroup).includes(id);
    } else {
      setTimeout(() => {
        return this.hasAclAccess(id);
      }, 1000);
    }
  }
}
