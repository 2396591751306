import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faArrowLeft,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  format,
  getDay,
  isAfter,
  startOfMonth
} from 'date-fns';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges {
  @Input() dateStr: string = format(new Date(), 'yyyy-MM-dd');
  @Output() updatedDate: EventEmitter<string> = new EventEmitter();

  todayStr: string = format(new Date(), 'yyyy-MM-dd');

  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;
  faAnglesLeft = faAnglesLeft;
  faAnglesRight = faAnglesRight;

  daysOfMonth: (string | null)[][] = [];

  ngOnInit(): void {
    this.generateDaysOfMonth();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.generateDaysOfMonth();
  }

  prevMonth(): void {
    this.dateStr = format(addMonths(new Date(this.dateStr), -1), 'yyyy-MM-dd');

    this.selectDate(this.dateStr);

    this.generateDaysOfMonth();
  }

  nextMonth(): void {
    this.dateStr = format(addMonths(new Date(this.dateStr), 1), 'yyyy-MM-dd');

    this.selectDate(this.dateStr);

    this.generateDaysOfMonth();
  }

  prevYear(): void {
    this.dateStr = format(addYears(new Date(this.dateStr), -1), 'yyyy-MM-dd');

    this.selectDate(this.dateStr);

    this.generateDaysOfMonth();
  }

  nextYear(): void {
    this.dateStr = format(addYears(new Date(this.dateStr), 1), 'yyyy-MM-dd');

    this.selectDate(this.dateStr);

    this.generateDaysOfMonth();
  }

  generateDaysOfMonth(): void {
    const dateStartOfMonth: Date = startOfMonth(new Date(this.dateStr));
    const dateEndOfMonth: Date = endOfMonth(new Date(this.dateStr));

    let date: Date = new Date(dateStartOfMonth.getTime());

    this.daysOfMonth = [];
    let currentWeek: (string | null)[] = [];
    while (!isAfter(date, dateEndOfMonth)) {
      let dayOfWeek: number = getDay(date);

      if (dayOfWeek === 0) {
        dayOfWeek = 7; // use 7 for sunday
      }

      if (!currentWeek.length && dayOfWeek > 1) {
        for (let i = 1; i < dayOfWeek; i++) {
          currentWeek.push(null);
        }
      }

      if (dayOfWeek === 1 && currentWeek.length) {
        this.daysOfMonth.push(currentWeek);
        currentWeek = [];
      }

      currentWeek.push(format(date, 'yyyy-MM-dd'));

      date = addDays(date, 1);
    }

    if (currentWeek.length) {
      if (currentWeek.length < 7) {
        for (let i = currentWeek.length; i < 7; i++) {
          currentWeek.push(null);
        }
      }

      this.daysOfMonth.push(currentWeek);
    }
  }

  selectDate(date: string): void {
    this.updatedDate.emit(date);
  }
}
