import { Injectable } from '@angular/core';
import { ICurrencyExchangeRate } from 'src/app/interfaces/currency-exchange-rate.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyExchangeRateFirestoreService extends FirestoreService<ICurrencyExchangeRate> {
  protected basePath = 'currencyExchangeRates';
}
