export enum EnumInvoiceType {
  proforma = 'proforma',
  proformaCreditNote = 'proforma-credit-note',
  creditNote = 'credit-note',
  definitive = 'definitive',
  purchase = 'purchase',
  purchaseCreditNote = 'purchaseCreditNote'
}

export const getEnumInvoiceTypeLabel = (invoiceType: EnumInvoiceType): string => {
  switch (invoiceType) {
    default:
      return invoiceType;
    case EnumInvoiceType.proforma:
      return 'Proforma';
    case EnumInvoiceType.proformaCreditNote:
      return 'Proforma avoir';
    case EnumInvoiceType.creditNote:
      return 'Avoir définitif';
    case EnumInvoiceType.definitive:
      return 'Facture définitive';
    case EnumInvoiceType.purchase:
      return "Facture d'achat";
    case EnumInvoiceType.purchaseCreditNote:
      return "Avoir reçu d'un fournisseur/prestataire";
  }
};
