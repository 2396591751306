import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  EnumCrewGender,
  EnumCrewType,
  ICrew,
  getCrewFullname,
  getCrewGenderLabel,
  getCrewTypeLabel
} from 'src/app/interfaces/crew.interface';
import { CrewService } from 'src/app/services/crews/crews.service';
import { Subscription } from 'rxjs';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-crew-edit',
  templateUrl: './crew-edit.component.html',
  styleUrls: ['./crew-edit.component.scss']
})
export class CrewEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inModal: boolean = false;
  @Input() defaultAirlineId: string | null = null;
  @Input() crewId: string | null = null;

  @Output() dismissModal: EventEmitter<string | null> = new EventEmitter();

  EnumCrewType = EnumCrewType;
  EnumCrewGender = EnumCrewGender;

  getCrewFullname = getCrewFullname;
  getCrewTypeLabel = getCrewTypeLabel;
  getCrewGenderLabel = getCrewGenderLabel;

  isLogged: boolean = false;
  form: FormGroup = this.resetForm();
  sending: boolean = false;
  crew: ICrew | null = null;
  countriesList: {
    title: string;
    value: string;
  }[] = [];
  airlinesObj: { [id: string]: IAirline | null } = {};

  subscriptions = new Subscription();

  constructor(
    private crewService: CrewService,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private airlineService: AirlineService,
    private loaderService: LoaderService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  get airlineId(): FormControl {
    return this.form.get('airlineId') as FormControl;
  }

  get type(): FormControl {
    return this.form.get('type') as FormControl;
  }

  get gender(): FormControl {
    return this.form.get('gender') as FormControl;
  }

  get firstname(): FormControl {
    return this.form.get('firstname') as FormControl;
  }

  get lastname(): FormControl {
    return this.form.get('lastname') as FormControl;
  }

  get phoneNumber(): FormControl {
    return this.form.get('phoneNumber') as FormControl;
  }

  get comment(): FormControl {
    return this.form.get('comment') as FormControl;
  }

  getCrewTypes(): EnumCrewType[] {
    return Object.values(EnumCrewType);
  }

  getCrewGenders(): EnumCrewGender[] {
    return Object.values(EnumCrewGender);
  }

  ngOnInit() {
    this.updatedDefaultAirlineId();

    this.form.disable();

    this.activatedRoute.url.subscribe(async () => {
      if (!this.crewId) {
        this.crewId = this.activatedRoute.snapshot.paramMap.get('crewId');
      }

      if (this.crewId) {
        await this.aclService.checkAclAccess(EnumAcl.crewsEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.crewsAdd);

        if (!this.defaultAirlineId) {
          this.defaultAirlineId = this.activatedRoute.snapshot.paramMap.get('airlineId');
        }

        this.updatedDefaultAirlineId();

        this.form.enable();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updatedDefaultAirlineId();

    if (this.crewId) {
      this.loadData();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updatedDefaultAirlineId(): void {
    if (this.defaultAirlineId) {
      this.airlineId.setValue(this.defaultAirlineId);
      this.airlineId.updateValueAndValidity();
      this.loadAirline(this.defaultAirlineId);
    }
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadCrew();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadCrew(): void {
    this.subscriptions.add(
      this.crewService.getFromId(this.crewId).subscribe((crew: ICrew) => {
        this.crew = crew;

        this.setCrew();
      })
    );
  }

  setCrew(): void {
    if (this.form && this.crew) {
      this.form = this.resetForm();

      for (const field in this.form.value) {
        if (typeof this.crew[field] !== 'undefined') {
          this.form.get(field).setValue(this.crew[field]);
        }
      }

      if (this.airlineId.value) {
        this.loadAirline(this.airlineId.value);
      }

      this.form.enable();
    }
  }

  async submitForm(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.status == 'VALID') {
      await this.loaderService.presentLoader('Envoi en cours...');

      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      let promise;
      if (this.crewId) {
        data.id = this.crewId;
        promise = () => this.crewService.update(data);
      } else {
        promise = () => this.crewService.create(data);
      }

      promise()
        .then(async id => {
          await this.loaderService.hideLoaderOnSuccess();

          if (!this.crewId) {
            this.crewId = id;

            if (this.inModal) {
              this.closeModal(this.crewId);
            }
          } else {
            if (this.inModal) {
              this.closeModal();
            }
          }

          this.sending = false;

          if (!this.inModal) {
            this.redirectAfterSaving();
          }
        })
        .catch(async (err: any) => {
          await this.loaderService.hideLoaderOnFailure(err.message);
          this.sending = false;
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/crews/view/' + this.crewId]);
  }

  resetForm(): FormGroup {
    return new FormGroup({
      airlineId: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
      gender: new FormControl(null, [Validators.required]),
      firstname: new FormControl(null, [Validators.required]),
      lastname: new FormControl(null, [Validators.required]),
      phoneNumber: new FormControl(null),
      comment: new FormControl(null)
    });
  }

  setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string | IAirline;
    }>;
  }): void {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      if (field.name === 'airlineId') {
        const airline: IAirline | null = field.value as IAirline | null;

        if (airline) {
          this.loadAirline(airline.id);
        }

        formControl.setValue(airline?.id || null);
      } else {
        formControl.setValue(field.value);
      }

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  loadAirline(airlineId: string): void {
    console.log(
      '🚀 ~ file: crew-edit.component.ts:286 ~ CrewEditComponent ~ loadAirline ~ airlineId:',
      airlineId
    );
    if (typeof this.airlinesObj[airlineId] === 'undefined') {
      this.airlinesObj[airlineId] = null;

      this.subscriptions.add(
        this.airlineService.getFromId(airlineId).subscribe((airline: IAirline | null) => {
          this.airlinesObj[airlineId] = airline;
        })
      );
    }
  }

  closeModal(newId: string | null = null): void {
    this.dismissModal.emit(newId);
  }
}
