import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { Inject, NgModule, Pipe, PipeTransform } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';

import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

import { AirlineInputComponent } from './components/airline-input/airline-input.component';
import { EnquiriesListComponent } from './components/enquiries-list/enquiries-list.component';
import { PipedriveSearchOrganizationInputComponent } from './components/pipedrive/search-organization-input/search-organization-input.component';
import { PipedriveSearchPersonInputComponent } from './components/pipedrive/search-person-input/search-person-input.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { Error404Component } from './error404/error404.component';
import { NotAuthorisedComponent } from './not-authorised/not-authorised.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { EnquiryViewComponent } from './admin/enquiries/enquiry-view/enquiry-view.component';
import { EnquiryEditComponent } from './admin/enquiries/enquiry-edit/enquiry-edit.component';
import { SelectAirlinesComponent } from './admin/enquiries/select-airlines/select-airlines.component';
import { SendMessageToAirlinesComponent } from './admin/enquiries/send-message-to-airlines/send-message-to-airlines.component';
import { SendEmailsComponent } from './admin/send-emails/send-emails.component';
import { ListAirlinesComponent } from './admin/airlines/list-airlines/list-airlines.component';
import { AirlineViewComponent } from './admin/airlines/airline-view/airline-view.component';
import { AirlineEditComponent } from './admin/airlines/airline-edit/airline-edit.component';
import { ListAirportsComponent } from './admin/airports/list-airports/list-airports.component';
import { AirportViewComponent } from './admin/airports/airport-view/airport-view.component';
import { AirportEditComponent } from './admin/airports/airport-edit/airport-edit.component';
import { UsersListComponent } from './admin/users/users-list/users-list.component';
import { UsersViewComponent } from './admin/users/users-view/users-view.component';
import { UsersEditComponent } from './admin/users/users-edit/users-edit.component';
import { MyAccountComponent } from './admin/my-account/my-account.component';
import { MyAccountEditComponent } from './admin/my-account/my-account-edit/my-account-edit.component';
import { EditPasswordComponent } from './admin/my-account/edit-password/edit-password.component';
import { PasswordForgottenComponent } from './password-forgotten/password-forgotten.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { ListAircraftsComponent } from './admin/aircrafts/list-aircrafts/list-aircrafts.component';
import { AircraftEditComponent } from './admin/aircrafts/aircraft-edit/aircraft-edit.component';
import { AircraftViewComponent } from './admin/aircrafts/aircraft-view/aircraft-view.component';
import { ListAircraftModelsComponent } from './admin/aircrafts/aircraft-models/list-aircraft-models/list-aircraft-models.component';
import { AircraftModelEditComponent } from './admin/aircrafts/aircraft-models/aircraft-model-edit/aircraft-model-edit.component';
import { AircraftModelViewComponent } from './admin/aircrafts/aircraft-models/aircraft-model-view/aircraft-model-view.component';
import { SimulateurListAircraftModelsComponent } from './admin/simulateur-prix/simulateur-aircraft-models/list-simulateur-aircraft-models/list-simulateur-aircraft-models.component';
import { SimulateurAircraftModelViewComponent } from './admin/simulateur-prix/simulateur-aircraft-models/simulateur-aircraft-model-view/simulateur-aircraft-model-view.component';
import { SimulateurAircraftModelEditComponent } from './admin/simulateur-prix/simulateur-aircraft-models/simulateur-aircraft-model-edit/simulateur-aircraft-model-edit.component';
import { ListEmailTemplatesComponent } from './admin/email-templates/list-email-templates/list-email-templates.component';
import { EmailTemplateViewComponent } from './admin/email-templates/email-template-view/email-template-view.component';
import { EmailTemplateEditComponent } from './admin/email-templates/email-template-edit/email-template-edit.component';
import { ListClientsComponent } from './admin/clients/list-clients/list-clients.component';
import { ClientViewComponent } from './admin/clients/client-view/client-view.component';
import { ClientEditComponent } from './admin/clients/client-edit/client-edit.component';
import { RelanceAirlineComponent } from './admin/enquiries/relance-airline/relance-airline.component';
import { ReportComponent } from './admin/report/report.component';
import { ListUserGroupsComponent } from './admin/user-groups/list-user-groups/list-user-groups.component';
import { UserGroupViewComponent } from './admin/user-groups/user-group-view/user-group-view.component';
import { UserGroupsEditComponent } from './admin/user-groups/user-groups-edit/user-groups-edit.component';
import { StatisticsClientsComponent } from './admin/statistics/statistics-clients/statistics-clients.component';
import { StatisticsUsersComponent } from './admin/statistics/statistics-users/statistics-users.component';
import { ListQuotationsComponent } from './admin/quotations/list-quotations/list-quotations.component';
import { QuotationViewComponent } from './admin/quotations/quotation-view/quotation-view.component';
import { QuotationEditComponent } from './admin/quotations/quotation-edit/quotation-edit.component';
import { ListInvoicesComponent } from './admin/invoices/list-invoices/list-invoices.component';
import { InvoiceViewComponent } from './admin/invoices/invoice-view/invoice-view.component';
import { InvoiceEditComponent } from './admin/invoices/invoice-edit/invoice-edit.component';
import { ListEnquiriesComponent } from './admin/enquiries/list-enquiries/list-enquiries.component';
import { EncaissementTypeViewComponent } from './admin/encaissement-types/encaissement-type-view/encaissement-type-view.component';
import { EncaissementTypeEditComponent } from './admin/encaissement-types/encaissement-type-edit/encaissement-type-edit.component';
import { ListEncaissementTypesComponent } from './admin/encaissement-types/list-encaissement-types/list-encaissement-types.component';
import { BankAccountViewComponent } from './admin/bank-accounts/bank-account-view/bank-account-view.component';
import { BankAccountEditComponent } from './admin/bank-accounts/bank-account-edit/bank-account-edit.component';
import { ListBankAccountsComponent } from './admin/bank-accounts/list-bank-accounts/list-bank-accounts.component';
import { EncaissementViewComponent } from './admin/encaissements/encaissement-view/encaissement-view.component';
import { EncaissementEditComponent } from './admin/encaissements/encaissement-edit/encaissement-edit.component';
import { ListEncaissementsComponent } from './admin/encaissements/list-encaissements/list-encaissements.component';
import { ListCrawlerUrlsComponent } from './admin/crawler-urls/list-crawler-urls/list-crawler-urls.component';

import { CanDeactivateGuard } from './components/can-deactivate/can-deactivate.guard';

import { QuillModule } from 'ngx-quill';
import { EnquiryRefPickerComponent } from './components/enquiry-ref-picker/enquiry-ref-picker.component';
import { InvoiceRefPickerComponent } from './components/invoice-ref-picker/invoice-ref-picker.component';
import { InvoicesListComponent } from './components/invoices-list/invoices-list.component';
import { EncaissementImportComponent } from './admin/encaissements/encaissement-import/encaissement-import.component';
import { EnquiryCardComponent } from './components/enquiry-card/enquiry-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CrawlerUrlEditComponent } from './admin/crawler-urls/crawler-url-edit/crawler-url-edit.component';
import { EnquiriesListWithFiltersComponent } from './components/enquiries-list-with-filters/enquiries-list-with-filters.component';
import { EnquiryPaymentsSummaryComponent } from './admin/finance/finance.component';
import { StatisticsAircraftModelsComponent } from './admin/statistics/statistics-aircraft-models/statistics-aircraft-models.component';
import { ListFlightBriefsComponent } from './admin/flight-briefs/list-flight-briefs/list-flight-briefs.component';
import { FlightBriefViewComponent } from './admin/flight-briefs/flight-brief-view/flight-brief-view.component';
import { FlightBriefEditComponent } from './admin/flight-briefs/flight-brief-edit/flight-brief-edit.component';
import { FlightBriefCateringViewComponent } from './admin/flight-brief-caterings/flight-brief-catering-view/flight-brief-catering-view.component';
import { FlightBriefCateringEditComponent } from './admin/flight-brief-caterings/flight-brief-catering-edit/flight-brief-catering-edit.component';
import { ListFlightBriefCateringsComponent } from './admin/flight-brief-caterings/list-flight-brief-caterings/list-flight-brief-caterings.component';
import { FinancialReportsComponent } from './components/financial-reports/financial-reports.component';
import { FinancialReportTotalComponent } from './components/financial-reports/financial-report-total/financial-report-total.component';
import { FinancialReportTeamComponent } from './components/financial-reports/financial-report-team/financial-report-team.component';
import { FinancialReportDepartmentComponent } from './components/financial-reports/financial-report-department/financial-report-department.component';
import { RouterModule } from '@angular/router';
import { FlightBriefPageComponent } from './components/flight-brief-page/flight-brief-page.component';
import { ListAircraftsNoCapacityComponent } from './admin/aircrafts/list-aircrafts-no-capacity/list-aircrafts-no-capacity.component';
import { ListBackupsComponent } from './admin/backups/list-backups/list-backups.component';
import { ListCountriesComponent } from './admin/countries/list-countries/list-countries.component';
import { CountryEditComponent } from './admin/countries/country-edit/country-edit.component';
import { CountryViewComponent } from './admin/countries/country-view/country-view.component';
import { EnquiryPaymentSummariesTableComponent } from './components/enquiry-payment-summaries-table/enquiry-payment-summaries-table.component';
import { InvoiceImportComponent } from './admin/invoices/invoice-import/invoice-import.component';
import { InvoiceAddPdfComponent } from './admin/invoices/invoice-add-pdf/invoice-add-pdf.component';
import { CrawlerAlertComponent } from './components/crawler-alert/crawler-alert.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { FinancialReportClientComponent } from './components/financial-reports/financial-report-client/financial-report-client.component';
import { FinancialReportAirlineComponent } from './components/financial-reports/financial-report-airline/financial-report-airline.component';
import { ListEmployeeBonusRulesComponent } from './admin/employee-bonus-rules/list-employee-bonus-rules/list-employee-bonus-rules.component';
import { EmployeeBonusRuleEditComponent } from './admin/employee-bonus-rules/employee-bonus-rule-edit/employee-bonus-rule-edit.component';
import { EmployeeBonusRuleViewComponent } from './admin/employee-bonus-rules/employee-bonus-rule-view/employee-bonus-rule-view.component';
import { EmployeeBonusDashboardComponent } from './components/employee-bonus-dashboard/employee-bonus-dashboard.component';
import { ListExportRequestsComponent } from './admin/export-requests/list-export-requests/list-export-requests.component';
import { ExportRequestViewComponent } from './admin/export-requests/export-request-view/export-request-view.component';
import { ExportRequestEditComponent } from './admin/export-requests/export-request-edit/export-request-edit.component';
import { EnquiryPaymentsSummaryImportComponent } from './admin/enquiry-payments-summary-import/enquiry-payments-summary-import.component';
import { FlightChecklistTemplateViewComponent } from './admin/flight-checklist-templates/flight-checklist-template-view/flight-checklist-template-view.component';
import { FlightChecklistTemplateEditComponent } from './admin/flight-checklist-templates/flight-checklist-template-edit/flight-checklist-template-edit.component';
import { ListFlightChecklistTemplatesComponent } from './admin/flight-checklist-templates/list-flight-checklist-templates/list-flight-checklist-templates.component';
import { registerLocaleData } from '@angular/common';

import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { ForecastReportsComponent } from './components/forecast-reports/forecast-reports.component';
import { FlightsCalendarModule } from './components/flights-calendar/flights-calendar.module';
import { FlightCalendarDashboardComponent } from './admin/flight-calendar-dashboard/flight-calendar-dashboard.component';
import { FboViewComponent } from './admin/fbos/fbo-view/fbo-view.component';
import { ListFbosComponent } from './admin/fbos/list-fbos/list-fbos.component';
import { ListCrewsComponent } from './admin/crews/list-crews/list-crews.component';
import { CrewViewComponent } from './admin/crews/crew-view/crew-view.component';
import { CrewEditComponent } from './admin/crews/crew-edit/crew-edit.component';
import { ListAirlineSupervisorsComponent } from './admin/airline-supervisors/list-airline-supervisors/list-airline-supervisors.component';
import { AirlineSupervisorViewComponent } from './admin/airline-supervisors/airline-supervisor-view/airline-supervisor-view.component';
import { AirlineSupervisorEditComponent } from './admin/airline-supervisors/airline-supervisor-edit/airline-supervisor-edit.component';
import { EnquiryFlightsTableModule } from './components/enquiry-flights-table/enquiry-flights-table.module';
import { EnquiryFlightsCardsModule } from './components/enquiry-flights-cards/enquiry-flights-cards.module';
import { AirportPickerModule } from './components/airport-picker/airport-picker.module';
import { FboEditModule } from './admin/fbos/fbo-edit/fbo-edit.module';
import { AutocompleteInputModule } from './components/autocomplete-input/autocomplete-input.module';
import { BreadcrumbsModule } from './components/breadcrumbs/breadcrumbs.module';
import { AirlineAutocompleteModule } from './components/airline-autocomplete/airline-autocomplete.module';
import { HandlingViewComponent } from './admin/handlings/handling-view/handling-view.component';
import { ListHandlingsComponent } from './admin/handlings/list-handlings/list-handlings.component';
import { HandlingEditModule } from './admin/handlings/handling-edit/handling-edit.module';
import { ImportAircraftsComponent } from './admin/aircrafts/import-aircrafts/import-aircrafts.component';
import { EnquiryFlightChecklistModule } from './components/enquiry-flight-checklist/enquiry-flight-checklist.module';

registerLocaleData(localeEn);
registerLocaleData(localeFr);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  declarations: [
    SafeHtmlPipe,
    AppComponent,
    LoginComponent,
    Error404Component,
    NotAuthorisedComponent,
    DashboardComponent,
    EnquiryViewComponent,
    EnquiryEditComponent,
    SelectAirlinesComponent,
    SendMessageToAirlinesComponent,
    SendEmailsComponent,
    ListAirlinesComponent,
    AirlineViewComponent,
    AirlineEditComponent,
    ListAirportsComponent,
    AirportViewComponent,
    AirportEditComponent,
    ListFbosComponent,
    FboViewComponent,
    ListHandlingsComponent,
    HandlingViewComponent,
    ListCrewsComponent,
    CrewViewComponent,
    CrewEditComponent,
    ListAirlineSupervisorsComponent,
    AirlineSupervisorViewComponent,
    AirlineSupervisorEditComponent,
    UsersListComponent,
    UsersViewComponent,
    UsersEditComponent,
    MyAccountComponent,
    MyAccountEditComponent,
    EditPasswordComponent,
    PasswordForgottenComponent,
    SetPasswordComponent,
    ListAircraftsComponent,
    ImportAircraftsComponent,
    AircraftEditComponent,
    AircraftViewComponent,
    ListAircraftModelsComponent,
    AircraftModelEditComponent,
    AircraftModelViewComponent,
    SimulateurListAircraftModelsComponent,
    SimulateurAircraftModelViewComponent,
    SimulateurAircraftModelEditComponent,
    ListEmailTemplatesComponent,
    EmailTemplateViewComponent,
    EmailTemplateEditComponent,
    ListClientsComponent,
    ClientViewComponent,
    ClientEditComponent,
    EnquiriesListComponent,
    AirlineInputComponent,
    RelanceAirlineComponent,
    ReportComponent,
    PipedriveSearchOrganizationInputComponent,
    PipedriveSearchPersonInputComponent,
    ListUserGroupsComponent,
    UserGroupViewComponent,
    UserGroupsEditComponent,
    StatisticsClientsComponent,
    StatisticsUsersComponent,
    ListQuotationsComponent,
    QuotationViewComponent,
    QuotationEditComponent,
    ListInvoicesComponent,
    InvoiceViewComponent,
    InvoiceEditComponent,
    InvoiceImportComponent,
    ListEnquiriesComponent,
    EnquiryRefPickerComponent,
    InvoiceRefPickerComponent,
    InvoicesListComponent,
    EncaissementTypeViewComponent,
    EncaissementTypeEditComponent,
    ListEncaissementTypesComponent,
    BankAccountViewComponent,
    BankAccountEditComponent,
    ListBankAccountsComponent,
    EncaissementViewComponent,
    EncaissementEditComponent,
    ListEncaissementsComponent,
    EncaissementImportComponent,
    EnquiryCardComponent,
    ListCrawlerUrlsComponent,
    CrawlerUrlEditComponent,
    EnquiriesListWithFiltersComponent,
    EnquiryPaymentsSummaryComponent,
    EnquiryPaymentsSummaryImportComponent,
    StatisticsAircraftModelsComponent,
    ListFlightBriefsComponent,
    FlightBriefViewComponent,
    FlightBriefEditComponent,
    FlightBriefCateringViewComponent,
    FlightBriefCateringEditComponent,
    ListFlightBriefCateringsComponent,
    FinancialReportsComponent,
    FinancialReportTotalComponent,
    FinancialReportTeamComponent,
    FinancialReportDepartmentComponent,
    FinancialReportClientComponent,
    FinancialReportAirlineComponent,
    FlightBriefPageComponent,
    ListAircraftsNoCapacityComponent,
    ListBackupsComponent,
    ListCountriesComponent,
    CountryEditComponent,
    CountryViewComponent,
    ListEmployeeBonusRulesComponent,
    EmployeeBonusRuleViewComponent,
    EmployeeBonusRuleEditComponent,
    EnquiryPaymentSummariesTableComponent,
    ForecastReportsComponent,
    InvoiceAddPdfComponent,
    CrawlerAlertComponent,
    LoaderComponent,
    EmployeeBonusDashboardComponent,
    ListExportRequestsComponent,
    ExportRequestViewComponent,
    ExportRequestEditComponent,
    FlightChecklistTemplateViewComponent,
    FlightChecklistTemplateEditComponent,
    ListFlightChecklistTemplatesComponent,
    FlightCalendarDashboardComponent,
    EnquiryRefPickerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote'],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ align: [] }],

          ['clean'], // remove formatting button

          ['link', 'image'] // link and image, video
        ]
      },
      placeholder: 'Ajoutez du texte ici...'
    }),
    FontAwesomeModule,
    NgxCsvParserModule,
    FlightsCalendarModule,
    EnquiryFlightsTableModule,
    AirportPickerModule,
    AutocompleteInputModule,
    BreadcrumbsModule,
    FboEditModule,
    HandlingEditModule,
    EnquiryFlightsCardsModule,
    AirlineAutocompleteModule,
    EnquiryFlightChecklistModule
  ],
  providers: [{ provide: SETTINGS, useValue: {} }, CanDeactivateGuard],
  bootstrap: [AppComponent]
})
export class AppModule {}
