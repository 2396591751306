export enum EnumLanguage {
  fr = 'fr',
  en = 'en'
}

export const getLanguageLabel = (language: EnumLanguage): string => {
  switch (language) {
    default:
      return language;
    case EnumLanguage.fr:
      return 'Français';
    case EnumLanguage.en:
      return 'Anglais';
  }
};

export enum EnumInvoiceLanguage {
  fr = 'fr',
  en = 'en',
  es = 'es',
  de = 'de'
}

export const getInvoiceLanguageLabel = (language: EnumInvoiceLanguage): string => {
  switch (language) {
    default:
      return language;
    case EnumInvoiceLanguage.fr:
      return 'Français';
    case EnumInvoiceLanguage.en:
      return 'Anglais';
    case EnumInvoiceLanguage.es:
      return 'Espagnol';
    case EnumInvoiceLanguage.de:
      return 'Allemand';
  }
};

export const getInvoiceLanguageForTranslation = (language: EnumInvoiceLanguage): EnumLanguage => {
  switch (language) {
    default:
      return EnumLanguage.en;
    case EnumInvoiceLanguage.fr:
      return EnumLanguage.fr;
  }
};
