import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { RemoteService } from '../../services/remote.service';
import { PipedriveService } from '../../services/pipedrive.service';
import { AclService } from '../../services/acl.service';

import moment from 'moment';
import {
  getDisplayedEnquiryRefTitle,
  getEnquiryBreadcrumbTitle,
  IEnquiry
} from 'src/app/interfaces/enquiry.interface';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { Subscription } from 'rxjs';
import { IEnquiryOffer } from 'src/app/interfaces/enquiry-offer.interface';
import { EnquiryOfferService } from 'src/app/services/enquiry-offer/enquiry-offer.service';
import { EnumEnquiryOfferStatus } from 'src/app/enums/enquiry-offer-status.enum';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { getUserFullname, IUser } from 'src/app/interfaces/user.interface';
import { IPipedriveOrganization } from 'src/app/interfaces/pipedrive.interface';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { IEmailTemplate } from 'src/app/interfaces/email-template.interface';
import { EnumLanguage } from 'src/app/enums/language.enum';
import { getInvoiceTitle, getTvaRateTitle, IInvoice } from 'src/app/interfaces/invoice.interface';
import { getEnumCurrencySymbol } from 'src/app/enums/currency.enum';

@Component({
  selector: 'app-send-emails',
  templateUrl: './send-emails.component.html',
  styleUrls: ['./send-emails.component.scss']
})
export class SendEmailsComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  enquiry: IEnquiry;
  enquiryOffers: IEnquiryOffer[] = [];
  invoice: IInvoice | null = null;
  isLogged: boolean = false;
  sending: boolean = false;
  currentEmailIndex: number = 0;
  form: FormGroup;
  currentUser: IUser;
  airportsObj: object = {};
  airportsToLoad: string[] = [];
  emailTemplateCode: string;
  collection: string;
  itemId: string;
  emailTemplateIds: object = {
    default: {
      fr: 'IVJDfWmjTahOP7LkmnTU',
      en: 'EzxGhVQ9YK7EuXpsKfaz'
    },
    sport: {
      fr: 'VwtifTK24tCreUJVLPYk',
      en: 'OPShH8YucpKFJdQV3uzz'
    },
    cargo: {
      fr: 'V7uywaoiO8jfkqJkaDbz',
      en: 'ZqnmeNgjk3DTJFn3xjVR'
    },
    invoiceRequest: {
      fr: '4050ysJ7quqeYdyvQrnC',
      en: '4050ysJ7quqeYdyvQrnC'
    },
    invoiceAdded: {
      fr: 'yIM4rktuqQoTzso8Dw84',
      en: 'yIM4rktuqQoTzso8Dw84'
    }
  };
  emailsToSend: {
    subject: string;
    contentHtml: string;
    oneContactOnly: boolean;
    contacts: {
      email: string;
      title: string;
    }[];
    cc: string[];
    cci: string[];
    receiver: {
      name: string;
      internalUrl: string;
    };
    foreignKey: string;
    params: object;
  }[] = [];
  client: IPipedriveOrganization;
  breadcrumbsItems: {
    title: string;
    path: string;
  }[] = [];

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private pipedriveService: PipedriveService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private aclService: AclService,
    private enquiryService: EnquiryService,
    private enquiryOfferService: EnquiryOfferService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.userObservable.subscribe((user: IUser) => {
      if (user) {
        this.currentUser = user;
      }
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      subject: ['', [Validators.required]],
      email: ['', [Validators.required]],
      cc: [''],
      cci: [''],
      contentHtml: ['', [Validators.required]],
      language: ['', [Validators.required]]
    });

    this.form.disable();

    window['enquiryId'] = null;

    this.activatedRoute.url.subscribe(async () => {
      this.emailTemplateCode = this.activatedRoute.snapshot.paramMap.get('emailTemplateCode');
      this.itemId = this.activatedRoute.snapshot.paramMap.get('itemId');

      switch (this.emailTemplateCode) {
        case 'invoice-requested':
        case 'invoice-added':
          this.collection = 'invoices';
          break;
        default:
          this.router.navigate(['/']);
          break;
      }

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      switch (this.emailTemplateCode) {
        case 'invoice-requested':
          await this.loadInvoice(this.itemId);
          await this.generateEmailsToSend('invoiceRequest');
          break;
        case 'invoice-added':
          await this.loadInvoice(this.itemId);
          await this.generateEmailsToSend('invoiceAdded');
          break;
      }

      this.loadBreadcrumbsItems();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async generateEmailsToSend(type: string): Promise<void> {
    const emailTemplate: IEmailTemplate = await this.getEmailTemplate(type);
    let contacts: {
      email: string;
      title: string;
    }[] = [];

    let language: EnumLanguage = EnumLanguage.en;

    if (this.emailTemplateCode === 'invoice-requested') {
      language = EnumLanguage.fr;
      contacts = await this.getAccountantContacts();
    } else if (this.emailTemplateCode === 'invoice-added') {
      language = EnumLanguage.fr;
      contacts = await this.getUserContacts();
    }

    this.form.get('language').setValue(language);

    if (this.emailTemplateCode === 'invoice-requested') {
      this.emailsToSend = [
        {
          subject: await this.setEmailVariable(emailTemplate.subject, language),
          contentHtml: await this.setEmailVariable(emailTemplate.messageHtml, language),
          oneContactOnly: false,
          contacts: contacts,
          cc: this.currentUser.emailInCc ? [this.currentUser.email] : [],
          cci: [],
          receiver: {
            name: 'Service comptable',
            internalUrl: null
          },
          foreignKey: null,
          params: {
            invoiceId: this.invoice.id,
            enquiryId: this.enquiry ? this.enquiry.id : null
          }
        }
      ];
    } else if (this.emailTemplateCode === 'invoice-added') {
      this.emailsToSend = [
        {
          subject: await this.setEmailVariable(emailTemplate.subject, language),
          contentHtml: await this.setEmailVariable(emailTemplate.messageHtml, language),
          oneContactOnly: false,
          contacts: contacts,
          cc: this.currentUser.emailInCc ? [this.currentUser.email] : [],
          cci: [],
          receiver: {
            name: contacts.length ? contacts[0].title : null,
            internalUrl: null
          },
          foreignKey: null,
          params: {
            invoiceId: this.invoice.id,
            enquiryId: this.enquiry ? this.enquiry.id : null
          }
        }
      ];
    } else {
      this.emailsToSend = [];
    }

    this.setDataToForm();
  }

  async getAccountantContacts(): Promise<
    {
      title: string;
      email: string;
    }[]
  > {
    return [
      {
        email: 'compta@flyaa.eu',
        title: 'Comptabilité'
      }
    ];
  }

  async getUserContacts(): Promise<
    {
      title: string;
      email: string;
    }[]
  > {
    const contacts: {
      email: string;
      title: string;
    }[] = [];

    const userId: string = this.invoice.requestedBy
      ? this.invoice.requestedBy
      : this.enquiry
      ? this.enquiry.processedBy
      : null;

    if (userId) {
      const userObj: object = await this.remoteService.getDocument('users', userId);

      const user: IUser = userObj as IUser;

      contacts.push({
        email: user.email,
        title: getUserFullname(user)
      });
    }

    return contacts;
  }

  async loadInvoice(docId: string): Promise<void> {
    this.loading = true;

    const doc = await this.remoteService.getDocument('invoices', this.itemId);

    this.invoice = this.remoteService.timestampsToDate(doc) as IInvoice;

    if (this.invoice.enquiryId) {
      await this.loadEnquiry(this.invoice.enquiryId);
    }

    await this.loadClient(this.invoice.clientId);

    this.form.enable();

    this.loading = false;
  }

  async loadEnquiry(enquiryId: string): Promise<void> {
    this.loading = true;

    if (enquiryId) {
      this.subscriptions.add(
        this.enquiryService.getFromId(enquiryId).subscribe(async (enquiry: IEnquiry) => {
          this.enquiry = enquiry;

          this.loadEnquiryOffers(enquiryId);

          await this.loadClient(
            typeof this.enquiry.clientId === 'number'
              ? this.enquiry.clientId.toString()
              : this.enquiry.clientId
          );

          await this.loadAirportsOfEnquiry(this.enquiry);
        })
      );
    }

    await this.setCurrentEmail(this.currentEmailIndex);

    this.form.enable();

    this.loading = false;
  }

  loadEnquiryOffers(enquiryId: string): void {
    this.subscriptions.add(
      this.enquiryOfferService
        .getAllForEnquiry(enquiryId)
        .subscribe(async (enquiryOffers: IEnquiryOffer[]) => {
          this.enquiryOffers = enquiryOffers;
        })
    );
  }

  getDatesForSubject(): string {
    const datesDepartForSubject: string[] = [];
    if (this.enquiry) {
      for (const itinerary of this.enquiry.itineraries) {
        for (const trip of itinerary.trips) {
          const date: string = trip.date;

          if (datesDepartForSubject.indexOf(date) === -1) {
            datesDepartForSubject.push(date);
          }
        }
      }
      datesDepartForSubject.sort();

      for (const i in datesDepartForSubject) {
        datesDepartForSubject[i] = moment(datesDepartForSubject[i])
          .format('ddd DD MMMM YYYY')
          .toUpperCase();
      }
    }

    return datesDepartForSubject.join(' - ');
  }

  getAirportsCodeForSubject(): string {
    const airportsCode: string[] = [];
    if (this.enquiry) {
      for (const itinerary of this.enquiry.itineraries) {
        for (const trip of itinerary.trips) {
          for (const field of [
            'airportDepart',
            'airportDestination',
            'commercialStopOverAirport',
            'fuelStopOverAirport'
          ]) {
            if (airportsCode[airportsCode.length - 1] !== trip[field] && trip[field]) {
              airportsCode.push(this.airportsObj[trip[field]]?.iataCode);
            }
          }
        }
      }
    }

    return airportsCode.join(' / ');
  }

  setDataToForm(): void {
    if (this.emailsToSend[this.currentEmailIndex]) {
      const contactEmails: string[] = [];
      for (const contact of this.emailsToSend[this.currentEmailIndex].contacts) {
        contactEmails.push(contact.email);
      }
      this.form.get('email').setValue(contactEmails.join(';'));
      this.form.get('cc').setValue(this.emailsToSend[this.currentEmailIndex].cc.join(';'));
      this.form.get('cci').setValue(this.emailsToSend[this.currentEmailIndex].cci.join(';'));
      this.form.get('subject').setValue(this.emailsToSend[this.currentEmailIndex].subject);
      this.form.get('contentHtml').setValue(this.emailsToSend[this.currentEmailIndex].contentHtml);
    }
  }

  async getEmailTemplate(
    type: string = 'default',
    language: EnumLanguage = EnumLanguage.en
  ): Promise<IEmailTemplate> {
    try {
      if (!this.emailTemplateIds[type]) {
        type = 'default';
      }

      const doc: object = await this.remoteService.getDocument(
        'emailTemplates',
        this.emailTemplateIds[type][language]
      );

      if (doc) {
        return doc as IEmailTemplate;
      }
    } catch (err) {
      alert(err.message);
    }
  }

  async setEmailVariable(text: string, language: EnumLanguage = EnumLanguage.en): Promise<string> {
    let userRequest: string = '';

    if (this.invoice) {
      let userId: string = null;

      if (this.invoice?.requestedBy) {
        userId = this.invoice.requestedBy;
      } else if (this.enquiry?.processedBy) {
        userId = this.enquiry.processedBy;
      } else if (this.invoice?.createdBy) {
        userId = this.invoice.createdBy;
      }

      if (userId) {
        const user: IUser = await this.loadUser(userId);

        userRequest = getUserFullname(user);
      }
    }

    let invoiceCotationCacheDetail = this.getInvoiceCotationCacheDetail();

    const variables: object = {
      'enquiry.refAndContractRef': this.enquiry
        ? getDisplayedEnquiryRefTitle(this.enquiry, 'refContract')
          ? getDisplayedEnquiryRefTitle(this.enquiry, 'refContract') +
            ' / ' +
            getDisplayedEnquiryRefTitle(this.enquiry, 'refEnquiry')
          : getDisplayedEnquiryRefTitle(this.enquiry, 'refEnquiry')
        : null,
      'enquiry.ref': this.enquiry ? getDisplayedEnquiryRefTitle(this.enquiry, 'refEnquiry') : null,
      'enquiry.contractRef':
        this.enquiry && this.enquiry.refContractTitle
          ? getDisplayedEnquiryRefTitle(this.enquiry, 'refContract')
          : '<em>Aucun</em>',
      'enquiry.routing': this.getAirportsCodeForSubject(),
      'enquiry.dates': this.getDatesForSubject(),
      // 'airline.name': this.airlines[this.currentAirlineIndex].title,
      'invoice.ref': this.invoice.ref,
      'invoice.dates': invoiceCotationCacheDetail.dates,
      'invoice.routing': invoiceCotationCacheDetail.routing,
      'invoice.airlines': invoiceCotationCacheDetail.airlines,
      'invoice.aircrafts': invoiceCotationCacheDetail.aircrafts,
      'enquiry.details': this.setEnquiryDetailsVariable(language),
      'invoice.products': this.setInvoiceProductsVariable(language),
      'client.name': this.client.name,
      'invoice.userRequest': userRequest,
      'invoice.url': this.invoice
        ? window.location.origin + '/admin/invoices/view/' + this.invoice.id
        : '',
      'user.emailSignature': this.currentUser.emailSignatureHtml,
      'invoice.dueDate': moment(this.invoice.dueDate).format('ddd DD MMMM YYYY'),
      'invoice.buyingPriceSupplierPriceInfo': this.invoice.buyingPriceSupplierPriceInfo.replace(
        '\n',
        '<br>'
      ),
      'invoice.buyingPriceSupplierPaymentsInfo':
        this.invoice.buyingPriceSupplierPaymentsInfo.replace('\n', '<br>')
    };

    for (const variable in variables) {
      text = text.replace(new RegExp('{{' + variable + '}}', 'g'), variables[variable]);
    }

    return text;
  }

  getInvoiceCotationCacheDetail(): {
    dates: string;
    routing: string;
    airlines: string;
    aircrafts: string;
  } {
    moment.locale('fr');

    let result: {
      dates: string;
      routing: string;
      airlines: string;
      aircrafts: string;
    } = {
      dates: '',
      routing: '',
      airlines: '',
      aircrafts: ''
    };

    if (this.invoice) {
      const dates: string[] = [];
      const routing: string[] = [];
      const airlineTitles: string[] = [];
      const aircraftModels: string[] = [];

      for (const cotationId of this.invoice.cotationsId) {
        for (const cotationsCachedInfo of this.invoice.cotationsCachedInfos) {
          if (cotationsCachedInfo.cotationId === cotationId) {
            dates.push(cotationsCachedInfo.dates);
            routing.push(cotationsCachedInfo.routing);
            airlineTitles.push(cotationsCachedInfo.airlineTitle);
            aircraftModels.push(cotationsCachedInfo.aircraftModel);
          }
        }
      }

      result.dates = dates.join(' + ');
      result.routing = routing.join(' + ');
      result.airlines = airlineTitles.join(' + ');
      result.aircrafts = aircraftModels.join(' + ');
    }

    return result;
  }

  capitalizeFirstLetter(val: string): string {
    return val.charAt(0).toUpperCase() + val.slice(1);
  }

  setInvoiceProductsVariable(language: EnumLanguage): string {
    let content: string = '';

    for (const product of this.invoice.products) {
      content += 'Libellé : ' + product.descriptionTitle + '<br>';
      content +=
        'Montant HT : ' + product.amountHt + getEnumCurrencySymbol(product.currency) + '<br>';
      content += 'TVA : ' + getTvaRateTitle(product.tvaRate) + '<br>';
      content +=
        'Montant net TTC : ' + product.amountTtc + getEnumCurrencySymbol(product.currency) + '<br>';
      content += '------------------------------------<br>';
    }

    content +=
      'Montant total : ' +
      this.invoice.amountTtcTotal +
      getEnumCurrencySymbol(this.invoice.currency) +
      '<br>';

    return content;
  }

  setEnquiryDetailsVariable(language: EnumLanguage): string {
    let content: string = '';

    switch (language) {
      case EnumLanguage.fr:
        if (this.enquiry) {
          for (const itineraryIndex in this.enquiry.itineraries) {
            if (this.enquiry.itineraries.length > 1) {
              content += '<strong>OPTION ' + (parseInt(itineraryIndex) + 1) + '</strong><br>';
            }

            for (let i = 0; i < this.enquiry.itineraries[itineraryIndex].trips.length; i++) {
              const trip = this.enquiry.itineraries[itineraryIndex].trips[i];
              const isLastTrip: boolean =
                i === this.enquiry.itineraries[itineraryIndex].trips.length - 1;

              content += moment(trip.date).format('ddd DD MMMM YYYY').toUpperCase();
              content += '&nbsp;&nbsp;&nbsp;&nbsp;';
              content += '&nbsp;&nbsp;&nbsp;&nbsp;';
              content += '&nbsp;&nbsp;&nbsp;&nbsp;';

              content += this.airportsObj[trip.airportDepart]?.iataCode;

              if (trip.hasCommercialStopOver) {
                content +=
                  ' / STOP ' + this.airportsObj[trip.commercialStopOverAirport]?.iataCode + ' - ';
              }

              content += ' / ';

              content += this.airportsObj[trip.airportDestination]?.iataCode;

              content += '&nbsp;&nbsp;&nbsp;&nbsp;';
              if (trip.time) {
                content += 'DEP ' + trip.time + ' local';
                content += '&nbsp;&nbsp;&nbsp;&nbsp;';
              }

              if (trip.passengersTotal) {
                content += trip.passengersTotal + 'Y';

                if (trip.hasCommercialStopOver) {
                  content += ' / STOP ' + Math.abs(trip.passengersCommercialStop) + 'Y - ';
                  content += trip.passengersTotal + trip.passengersCommercialStop;
                  content += 'Y';
                }

                if (trip.passengersBusiness) {
                  content += '&nbsp;&nbsp;&nbsp;&nbsp; inclus ' + trip.passengersBusiness + 'C';
                }
              }

              if (this.enquiry.type === EnumEnquiryType.cargo) {
                content += '<br>';
                content += 'Cargo : ';
                content += trip.natureFret ? trip.natureFret : 'Inconnu';
                content += '<br>';

                if (trip.dimensions) {
                  content += 'Dimensions : ' + trip.dimensions;
                  content += '<br>';
                }
                if (trip.weight) {
                  content += 'Poids : ' + trip.weight + ' kg';
                  content += '<br>';
                }
                if (trip.volume) {
                  content += 'Volume : ' + trip.volume + ' m3';
                }
                content += '<br>';
              }

              content += '<br>';
            }

            if (parseInt(itineraryIndex) < this.enquiry.itineraries.length - 1) {
              content += '<br>';
              content += 'OU' + '<br>';
              content += '<br>';
            }
          }

          if (this.enquiry.noteForAirlines && this.enquiry.noteForAirlines !== '') {
            content += '<br>';
            content += this.enquiry.noteForAirlines.replace(new RegExp('\r?\n', 'g'), '<br>');
            content += '<br>';
          }

          if (this.enquiry.emailAttachments.length) {
            content += '<br><strong>Pièces-jointes :</strong><br>';
            content += this.getEnquiryEmailAttachements(language);
          }
        }
        break;
      case EnumLanguage.en:
      default:
        if (this.enquiry) {
          for (const itineraryIndex in this.enquiry.itineraries) {
            if (this.enquiry.itineraries.length > 1) {
              content += '<strong>OPTION ' + (parseInt(itineraryIndex) + 1) + '</strong><br>';
            }

            for (let i = 0; i < this.enquiry.itineraries[itineraryIndex].trips.length; i++) {
              const trip = this.enquiry.itineraries[itineraryIndex].trips[i];
              const isLastTrip: boolean =
                i === this.enquiry.itineraries[itineraryIndex].trips.length - 1;

              content += moment(trip.date).format('ddd DD MMMM YYYY').toUpperCase();
              content += '&nbsp;&nbsp;&nbsp;&nbsp;';
              content += '&nbsp;&nbsp;&nbsp;&nbsp;';
              content += '&nbsp;&nbsp;&nbsp;&nbsp;';

              content += this.airportsObj[trip.airportDepart].iataCode;

              if (trip.hasCommercialStopOver) {
                content +=
                  ' / STOP ' + this.airportsObj[trip.commercialStopOverAirport].iataCode + ' - ';
              }

              content += ' / ';

              content += this.airportsObj[trip.airportDestination].iataCode;

              content += '&nbsp;&nbsp;&nbsp;&nbsp;';
              if (trip.time) {
                content += 'DEP ' + trip.time + ' local';
                content += '&nbsp;&nbsp;&nbsp;&nbsp;';
              }

              if (trip.passengersTotal) {
                content += trip.passengersTotal + 'Y';

                if (trip.hasCommercialStopOver) {
                  content += ' / STOP ' + Math.abs(trip.passengersCommercialStop) + 'Y - ';
                  content += trip.passengersTotal + trip.passengersCommercialStop;
                  content += 'Y';
                }

                if (trip.passengersBusiness) {
                  content += '&nbsp;&nbsp;&nbsp;&nbsp; including ' + trip.passengersBusiness + 'C';
                }
              }

              if (this.enquiry.type === EnumEnquiryType.cargo) {
                content += '<br>';
                content += 'Cargo : ';
                content += trip.natureFret ? trip.natureFret : 'Unknown';
                content += '<br>';

                if (trip.dimensions) {
                  content += 'Dimensions : ' + trip.dimensions;
                  content += '<br>';
                }
                if (trip.weight) {
                  content += 'Weight : ' + trip.weight + ' kg';
                  content += '<br>';
                }
                if (trip.volume) {
                  content += 'Volume : ' + trip.volume + ' m3';
                }
                content += '<br>';
              }

              content += '<br>';
            }

            if (parseInt(itineraryIndex) < this.enquiry.itineraries.length - 1) {
              content += '<br>';
              content += 'OR' + '<br>';
              content += '<br>';
            }
          }

          if (this.enquiry.noteForAirlines && this.enquiry.noteForAirlines !== '') {
            content += '<br>';
            content += this.enquiry.noteForAirlines.replace(new RegExp('\r?\n', 'g'), '<br>');
            content += '<br>';
          }

          if (this.enquiry.emailAttachments.length) {
            content += '<br><strong>Attachments :</strong><br>';
            content += this.getEnquiryEmailAttachements(language);
          }
        }

        break;
    }

    return content;
  }

  getEnquiryEmailAttachements(language: EnumLanguage = EnumLanguage.en): string {
    let html = '';

    if (this.enquiry) {
      if (this.enquiry.emailAttachments) {
        const links: string[] = [];
        for (const emailAttachments of this.enquiry.emailAttachments) {
          links.push("<a href='" + emailAttachments.url + "'>" + emailAttachments.name + '</a>');
        }

        html += links.join('<br>');
      }
    }

    return html;
  }

  async setCurrentEmail(index: number): Promise<void> {
    this.currentEmailIndex = index;

    if (this.emailsToSend[this.currentEmailIndex]) {
      await this.resetForm();

      // this.currentAirlineContacts = [];

      // for (const companyContact of this.airlines[this.currentEmailIndex].companyContacts) {
      //   for (const contact of companyContact.contacts) {
      //     if (contact.email) {
      //       if (!this.form.value.email) {
      //         this.form.get('email').setValue(contact.email);
      //       }

      //       this.currentAirlineContacts.push(contact);
      //     }
      //   }
      // }
    }
  }

  async resetForm(): Promise<void> {
    this.form.reset();

    if (this.emailsToSend.length) {
      // this.form.get('enquiryId').setValue(this.enquiry.id);
      // this.form.get('airlineId').setValue(this.airlines[this.currentAirlineIndex].id);

      await this.setDataToForm();
    }
  }

  async sendMessage(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.status == 'VALID' && this.form.value.email) {
      await this.sendEmail();
    } else if (!this.form.value.email) {
      alert(
        "Impossible d'envoyer un message car aucun email n'est fournis, veuillez en ajouter un pour pouvoir continuer."
      );
    }
  }

  ignoreAndGoNext(): void {
    if (this.currentEmailIndex < this.emailsToSend.length - 1) {
      this.setCurrentEmail(this.currentEmailIndex + 1);
      this.form.enable();

      window.scrollTo(0, 0);
    } else {
      alert('Toutes les emails ont été traitées.');

      if (['invoice-requested', 'invoice-added'].indexOf(this.emailTemplateCode) !== -1) {
        this.router.navigate(['/admin/invoices/view/' + this.invoice.id]);
      } else if (this.enquiry) {
        this.router.navigate(['/admin/enquiry/view/' + this.enquiry.id]);
      } else {
        this.router.navigate(['/admin/invoices']);
      }
    }
  }

  async sendEmail(): Promise<void> {
    this.sending = true;

    this.form.disable();

    const emailsTo: string[] = this.form.value.email.split(';');

    const to: {
      email: string;
      name: string;
    }[] = [];

    for (const email of emailsTo) {
      to.push({
        email: email,
        name: null
      });
    }

    const data: {
      subject: string;
      content: string;
      contentHtml: string;
      replyTo: {
        email: string;
        name: string;
      };
      to: {
        email: string;
        name: string;
      }[];
      cc: string[];
      cci: string[];
      params: {
        enquiryId: string;
        airlineId: string;
        contactedBy: string;
        language: EnumLanguage;
      };
      sent: boolean;
    } = {
      subject: this.form.value.subject,
      content: this.remoteService.convertHtmlToPlainText(this.form.value.contentHtml),
      contentHtml: this.form.value.contentHtml,
      replyTo: {
        email: this.currentUser.email,
        name: this.currentUser.firstname + ' ' + this.currentUser.lastname
      },
      to: to,
      cc: this.form.value.cc.split(';'),
      cci: this.form.value.cci.split(';'),
      params: {
        enquiryId: this.enquiry ? this.enquiry.id : null,
        airlineId: null,
        contactedBy: this.currentUser.id,
        language: this.form.value.language
      },
      sent: false
    };

    try {
      const docId: string = await this.remoteService.addDocumentToCollection('emailPool', data);

      if (docId) {
        if (!this.emailTemplateCode.match('invoice')) {
          if (this.enquiry) {
            for (const offer of this.enquiryOffers) {
              if (offer.aircraftCompiled.airlineId === data.params.airlineId) {
                await this.enquiryOfferService.update({
                  id: offer.id,
                  status: EnumEnquiryOfferStatus.waitingForAnswer
                } as IEnquiryOffer);
              }
            }
          }
        }

        this.sending = false;

        this.ignoreAndGoNext();
      }
    } catch (err) {
      console.log(err);
      alert(err.message);

      this.sending = false;
    }
  }

  reviver(key: string, value: string): any {
    if (
      ['selectedDatetime', 'contactDatetime', 'created', 'modified'].indexOf(key) !== -1 &&
      !isNaN(Date.parse(value))
    ) {
      return new Date(value);
    }

    return value;
  }

  formatPrice(value: number): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }

  addAirlineContacts(airlineId: string): void {
    if (this.enquiry) {
      window['enquiryId'] = this.enquiry.id;
    }

    this.router.navigate(['/admin/airlines/edit/' + airlineId]);
  }

  async loadAirportsOfEnquiry(enquiry: IEnquiry): Promise<void> {
    for (const itinerary of enquiry.itineraries) {
      for (const trip of itinerary.trips) {
        for (const field of [
          'airportDepart',
          'airportDestination',
          'commercialStopOverAirport',
          'fuelStopOverAirport'
        ]) {
          if (trip[field]) {
            this.addAirportToLoad(trip[field]);
          }
        }
      }
    }

    await this.loadAirports();
  }

  addAirportToLoad(airportId: string) {
    if (
      this.airportsToLoad.indexOf(airportId) === -1 &&
      typeof this.airportsObj[airportId] === 'undefined'
    ) {
      this.airportsToLoad.push(airportId);
    }
  }

  async loadAirports(): Promise<void> {
    if (this.airportsToLoad.length) {
      const docs: object[] = await this.remoteService.getDocumentsFromDocId(
        'airports',
        this.airportsToLoad
      );

      for (const doc of docs) {
        const airport = doc as IAirport;

        this.airportsObj[airport.id] = airport;
      }

      this.airportsToLoad = [];
    }
  }

  async loadClient(clientId: string): Promise<void> {
    if (clientId) {
      this.client = await this.pipedriveService.getOrganization(clientId);
    }
  }

  async loadUser(userId: string): Promise<IUser> {
    if (userId) {
      const docUser = await this.remoteService.getDocument('users', userId);

      if (docUser) {
        return docUser as IUser;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  loadBreadcrumbsItems(): void {
    this.breadcrumbsItems = [];

    switch (this.emailTemplateCode) {
      case 'invoice-requested':
      case 'invoice-added':
        if (this.enquiry) {
          this.breadcrumbsItems.push({
            title: getEnquiryBreadcrumbTitle(this.enquiry),
            path: '/admin/enquiry/view/' + this.enquiry.id
          });
          this.breadcrumbsItems.push({
            title: 'Liste des factures',
            path: '/admin/invoices/list/enquiry/' + this.enquiry.id
          });
        }

        if (this.invoice) {
          this.breadcrumbsItems.push({
            title: getInvoiceTitle(this.invoice),
            path: '/admin/invoices/view/' + this.invoice.id
          });

          this.breadcrumbsItems.push({
            title: "Édition d'une facture",
            path: '/admin/invoices/edit/' + this.invoice.id
          });
        } else {
          this.breadcrumbsItems.push({
            title: "Ajout d'une facture",
            path: '/admin/invoices/add'
          });
        }

        this.breadcrumbsItems.push({
          title:
            this.emailTemplateCode === 'invoice-requested'
              ? "Envoi d'un email au comptable"
              : "Envoi d'un email à l'agent",
          path: '/admin/enquiry/send-emails/' + this.emailTemplateCode + '/' + this.invoice.id
        });

        break;
      default:
        if (this.enquiry) {
          this.breadcrumbsItems.push({
            title: this.enquiry ? getEnquiryBreadcrumbTitle(this.enquiry) : '',
            path: '/admin/enquiry/view/' + this.enquiry.id
          });

          this.breadcrumbsItems.push({
            title: 'Envoi des messages aux compagnies aériennes',
            path: '/admin/enquiry/send-emails/' + this.enquiry.id
          });
        }

        break;
    }
  }
}
