import { Component } from '@angular/core';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';
import { IUser } from 'src/app/interfaces/user.interface';
import { AclService } from 'src/app/services/acl.service';
import { RemoteService } from 'src/app/services/remote.service';

enum EnumTab {
  enquiryPayments = 'enquiryPayments',
  myEnquiryPayments = 'myEnquiryPayments',
  forecastReports = 'forecastReports',
  financialReports = 'financialReports',
  employeeBonus = 'employeeBonus',
  exportRequestDashboard = 'exportRequestDashboard'
}

const getTabLabel = (tab: EnumTab): string => {
  switch (tab) {
    case EnumTab.enquiryPayments:
      return 'Paiements des dossiers';
    case EnumTab.myEnquiryPayments:
      return 'Paiements de mes dossiers';
    case EnumTab.forecastReports:
      return 'Rapport prévisionnel';
    case EnumTab.financialReports:
      return 'Rapport réel';
    case EnumTab.employeeBonus:
      return 'Bonus des commerciaux';
    case EnumTab.exportRequestDashboard:
      return 'Export des factures PDF';
  }
};

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})
export class EnquiryPaymentsSummaryComponent {
  isLogged: boolean = false;
  currentUser: IUser | null = null;

  getTabLabel = getTabLabel;
  EnumTab = EnumTab;

  tabs: EnumTab[] = [];
  currentTab: EnumTab | null = null;

  constructor(private remoteService: RemoteService, private aclService: AclService) {
    this.remoteService.isLoggedObservable.subscribe((isLogged: boolean) => {
      this.isLogged = isLogged;

      if (this.isLogged) {
        this.refreshTabs();
      }
    });

    this.remoteService.userObservable.subscribe((user: IUser) => {
      this.currentUser = user;

      if (this.currentUser) {
        this.refreshTabs();
      }
    });

    this.remoteService.userGroupObservable.subscribe((userGroup: IUserGroup | null) => {
      this.refreshTabs();
    });
  }

  refreshTabs(): void {
    this.tabs = [];

    if (this.hasAclAccess(EnumAcl.enquiryPaymentsSummaryDisplay)) {
      this.tabs.push(EnumTab.enquiryPayments);
    }

    if (this.hasAclAccess(EnumAcl.enquiryPaymentsSummaryOwnedEnquiries)) {
      this.tabs.push(EnumTab.myEnquiryPayments);
    }

    if (this.aclService.hasAclAccess(EnumAcl.reportsDisplay)) {
      this.tabs.push(EnumTab.forecastReports);
    }

    if (this.hasAclAccess(EnumAcl.enquiryPaymentsSummaryFinancialReports)) {
      this.tabs.push(EnumTab.financialReports);
    }

    if (this.hasAclAccess(EnumAcl.employeeBonusRulesList)) {
      this.tabs.push(EnumTab.employeeBonus);
    }

    if (this.hasAclAccess(EnumAcl.exportRequestsList)) {
      this.tabs.push(EnumTab.exportRequestDashboard);
    }

    if (!this.currentTab && this.tabs.length) {
      this.currentTab = this.tabs[0];
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  changeCurrentTab(tab: EnumTab): void {
    this.currentTab = tab;
  }
}
