import { Component, OnInit, OnDestroy, ElementRef, ViewChild, NgZone } from '@angular/core';

import { RemoteService } from '../../../services/remote.service';

import { Subscription, finalize } from 'rxjs';

import * as PDFObject from 'pdfobject';
import { IUser } from 'src/app/interfaces/user.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { IEnquiry, getEnquiryBreadcrumbTitle } from 'src/app/interfaces/enquiry.interface';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { EnumInvoiceType, getEnumInvoiceTypeLabel } from 'src/app/enums/invoice-type.enum';
import { InvoiceService } from 'src/app/services/invoices/invoices.service';
import {
  IPdfExtractData,
  IPdfExtractDataCell,
  IPdfTableProductsExtracted
} from 'src/app/interfaces/pdf-parsed-data.interface';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { EnumInvoiceLanguage, getInvoiceLanguageLabel } from 'src/app/enums/language.enum';
import {
  EnumCurrency,
  getEnumCurrencyLabel,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';

import countries from '../../../countries_fr.json';
import { EnumTvaRate, getEnumTvaRateLabel } from 'src/app/enums/tva-rates.enum';
import {
  EnumPdfParserRuleType,
  EnumPdfParserValueSearchedPosition,
  EnumProductsColumnsConfigType,
  IPDfParserSettingsProductColumnRule,
  IPDfParserSettingsRule,
  IPdfParserSetting,
  getPdfParserRuleByTypeLabel,
  getPdfParserValueSearchedPositionLabel,
  getProductsColumnsConfigTypeLabel
} from 'src/app/interfaces/pdf-parser-setting.interface';
import { PdfParserSettingService } from 'src/app/services/pdf-parser-settings/pdf-parser-settings.service';
import {
  IInvoice,
  IInvoiceProduct,
  invoiceGetProductsLabel
} from 'src/app/interfaces/invoice.interface';
import { EnumInvoiceStatus } from 'src/app/enums/invoice-status.enum';
import { EnumInvoiceProductDescription } from 'src/app/enums/invoice-product-description.enum';
import { addZeroToDigit, escapeRegExp, formatDateFromStr, monthsLabel } from 'src/app/misc.utils';
import { IClientBillingInfos } from 'src/app/interfaces/client-billing-infos.interface';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';
import { addDays } from 'date-fns';

@Component({
  selector: 'app-invoice-add-pdf',
  templateUrl: './invoice-add-pdf.component.html',
  styleUrls: ['./invoice-add-pdf.component.scss']
})
export class InvoiceAddPdfComponent implements OnInit, OnDestroy {
  @ViewChild('inputFile', { static: false }) inputFileElement: ElementRef;
  @ViewChild('pdfViewerEmbed', { static: false }) pdfViewerEmbedElement: ElementRef;

  faCircleQuestion = faCircleQuestion;
  faCheckCircle = faCheckCircle;
  faCircleXmark = faCircleXmark;

  isLogged: boolean = false;
  userGroup: IUserGroup | null = null;
  currentUser: IUser | null = null;
  breadcrumbItems: {
    title: string;
    path: string;
  }[] = [];
  enquiryId: string | null = null;
  enquiry: IEnquiry | null = null;
  invoiceType: EnumInvoiceType | null = null;

  subscriptions = new Subscription();

  uploading: boolean = false;
  uploadingProgress: number;
  currentFile: File;

  document: {
    name: string;
    size: number;
    type: string;
    url: string;
  } | null;

  EnumInvoiceType = EnumInvoiceType;
  EnumPdfParserRuleType = EnumPdfParserRuleType;
  EnumInvoiceLanguage = EnumInvoiceLanguage;

  getPdfParserRuleByTypeLabel = getPdfParserRuleByTypeLabel;
  getProductsColumnsConfigTypeLabel = getProductsColumnsConfigTypeLabel;
  getInvoiceLanguageLabel = getInvoiceLanguageLabel;
  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getEnumCurrencyLabel = getEnumCurrencyLabel;
  getEnumTvaRateLabel = getEnumTvaRateLabel;
  getPdfParserValueSearchedPositionLabel = getPdfParserValueSearchedPositionLabel;

  loadingPdfParser: boolean = false;
  pdfExtractData: IPdfExtractData | null = null;
  pdfExtractDataTables: {
    title: string;
    data: IPdfTableProductsExtracted;
  }[] = [];

  form: FormGroup = new FormGroup({});

  selectorFields: {
    title: string;
    value: string;
    hideIsValueInPdf?: boolean;
  }[] = [
    {
      title: 'Référence de la facture',
      value: 'ref'
    },
    {
      title: 'Date facture',
      value: 'issueDate'
    },
    {
      title: 'Date échéance',
      value: 'dueDate'
    },
    {
      title: "Nom de l'entreprise",
      value: 'clientName'
    },
    {
      title: 'Rue',
      value: 'street'
    },
    {
      title: 'Code postal',
      value: 'postalCode'
    },
    {
      title: 'Ville',
      value: 'city'
    },
    {
      title: 'Pays',
      value: 'countryCode'
    },
    {
      title: 'Siret',
      value: 'siret'
    },
    {
      title: 'TVA Intracommunautaire',
      value: 'tvaNumber'
    },
    {
      title: 'Tableau des produits / services',
      value: 'productsColumnsConfig',
      hideIsValueInPdf: true
    }
  ];

  countriesList: {
    title: string;
    value: string;
  }[] = [];
  countries = countries;

  temporaryProductsParsed: IPdfTableProductsExtracted = {
    headers: [],
    rows: []
  };

  processFromReader: boolean = false;
  pdfParserSettingId: string | null = null;
  pdfParserSettings: IPdfParserSetting[] = [];

  savingInvoice: boolean = false;

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private enquiryService: EnquiryService,
    private zone: NgZone,
    private invoiceService: InvoiceService,
    private pdfParserSettingService: PdfParserSettingService,
    private router: Router
  ) {
    for (const countryCode in countries) {
      this.countriesList.push({
        title: countries[countryCode],
        value: countryCode
      });
    }

    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.userObservable.subscribe((user: IUser) => (this.currentUser = user));
    this.remoteService.userGroupObservable.subscribe((userGroup: IUserGroup | null) => {
      this.userGroup = userGroup;
    });
  }

  ngOnInit() {
    this.activatedRoute.url.subscribe(async () => {
      this.enquiryId = this.activatedRoute.snapshot.paramMap.get('enquiryId');

      this.loadEnquiry();

      if (window.location.href.match('add-purchase-invoice')) {
        this.invoiceType = EnumInvoiceType.purchase;
      } else if (window.location.href.match('add-purchase-credit-note')) {
        this.invoiceType = EnumInvoiceType.purchaseCreditNote;
      }
    });

    // TO REMOVE - START
    // this.document = {
    //   name: 'pdf',
    //   size: 1,
    //   type: 'pdf',
    //   url: 'https://firebasestorage.googleapis.com/v0/b/artheau-aviation-preprod.appspot.com/o/invoices%2Fdocument%2FAMAZON%20-%2004.01.2023%20-%2041%2C99EUR.pdf?alt=media&token=f12aa517-1dbd-497b-96cf-fed8c7b974a6'
    // };

    // setTimeout(() => {
    //   this.afterPdfUpload();
    // }, 1000);
    // TO REMOVE - END

    this.resetForm();

    this.loadBreadcrumbItems();
    this.loadAllPdfParserSettings();
  }

  loadAllPdfParserSettings(): void {
    this.subscriptions.add(
      this.pdfParserSettingService.getAll().subscribe((pdfParserSettings: IPdfParserSetting[]) => {
        this.pdfParserSettings = pdfParserSettings;

        this.pdfParserSettings.sort((a, b) => (a.name < b.name ? -1 : 1));
      })
    );
  }

  ngOnDestroy(): void {
    window['$']('.tooltip').remove();

    this.subscriptions.unsubscribe();
  }

  getSelector(field: string): FormGroup | null {
    return this.form.get(field) as FormGroup | null;
  }

  getSelectorField(selector: string, field: string): FormControl | null {
    const selectorField: FormGroup | null = this.getSelector(selector);

    if (selectorField) {
      return this.getSelector(selector).get(field) as FormControl;
    }

    return null;
  }

  getSelectorRules(selector: string): FormArray {
    return this.getSelector(selector).get('rules') as FormArray;
  }

  getSelectorRule(selector: string, i: number): FormGroup {
    return this.getSelectorRules(selector).at(i) as FormGroup;
  }

  getSelectorRuleField(selector: string, i: number, field: string): FormControl {
    return this.getSelectorRule(selector, i).get(field) as FormControl;
  }

  getProductsColumnsConfigRulesForRows(): FormArray {
    return this.getSelector('productsColumnsConfig').get('rulesForRows') as FormArray;
  }

  getProductsColumnsConfigRulesForRowsRule(i: number): FormGroup {
    return this.getProductsColumnsConfigRulesForRows().at(i) as FormGroup;
  }

  getProductsColumnsConfigRulesForRowsRuleField(i: number, field: string): FormControl {
    return this.getProductsColumnsConfigRulesForRowsRule(i).get(field) as FormControl;
  }

  getRuleTypes(): EnumPdfParserRuleType[] {
    return Object.values(EnumPdfParserRuleType);
  }

  getRuleForRowsTypes(): EnumProductsColumnsConfigType[] {
    return Object.values(EnumProductsColumnsConfigType);
  }

  getLanguages(): EnumInvoiceLanguage[] {
    return Object.values(EnumInvoiceLanguage);
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  getPdfParserValueSearchedPositions(): EnumPdfParserValueSearchedPosition[] {
    return Object.values(EnumPdfParserValueSearchedPosition);
  }

  getTvaRates(): EnumTvaRate[] {
    const tvaRates: EnumTvaRate[] = [];

    for (const tvaRate of Object.values(EnumTvaRate)) {
      if (tvaRate !== EnumTvaRate.other) {
        tvaRates.push(tvaRate);
      }
    }

    return tvaRates;
  }

  updateTooltip(): void {
    window['$']('[rel="tooltip"]').tooltip({
      html: true,
      boundary: 'window'
    });
  }

  loadBreadcrumbItems(): void {
    this.breadcrumbItems = [];

    if (this.enquiry) {
      this.breadcrumbItems.push({
        title: getEnquiryBreadcrumbTitle(this.enquiry),
        path: '/admin/enquiry/view/' + this.enquiry.id
      });
      this.breadcrumbItems.push({
        title: 'Liste des factures',
        path: '/admin/invoices/list/enquiry/' + this.enquiry.id
      });
    } else {
      this.breadcrumbItems.push({
        title: 'Liste des factures',
        path: '/admin/invoices/list'
      });
    }

    switch (this.invoiceType) {
      case EnumInvoiceType.purchaseCreditNote:
      case EnumInvoiceType.purchase:
        this.breadcrumbItems.push({
          title: "Ajout d'une " + getEnumInvoiceTypeLabel(this.invoiceType).toLocaleLowerCase(),
          path: '/admin/invoices/add-pdf'
        });
        break;
      default:
        this.breadcrumbItems.push({
          title: "Ajout d'une facture",
          path: '/admin/invoices/add-pdf'
        });

        break;
    }
  }

  loadEnquiry(): void {
    if (this.enquiryId) {
      this.subscriptions.add(
        this.enquiryService.getFromId(this.enquiryId).subscribe((enquiry: IEnquiry) => {
          this.enquiry = enquiry;

          if (this.enquiry) {
            this.loadBreadcrumbItems();
          }
        })
      );
    }
  }

  initPDFViewer(): void {
    if (this.document.url && this.pdfViewerEmbedElement) {
      PDFObject.embed(this.document.url, this.pdfViewerEmbedElement.nativeElement);
    }
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.currentFile = fileInput.target.files[0];

      if (this.currentFile) {
        this.upload();
      }
    }
  }

  upload(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.uploading = true;
      this.uploadingProgress = 0;

      let result = this.remoteService.upload('invoices/document', null, this.currentFile, 'file');

      result['task'].percentageChanges().subscribe((progress: number) => {
        this.zone.run(() => {
          this.uploadingProgress = Math.round(progress);
        });
      });

      result['task']
        .snapshotChanges()
        .pipe(
          finalize(() => {
            result['ref'].getDownloadURL().subscribe((downloadUrl: string) => {
              this.document = {
                name: this.currentFile['name'],
                type: this.currentFile['type'],
                size: this.currentFile['size'],
                url: downloadUrl
              };

              this.inputFileElement.nativeElement.value = '';

              this.resetForm();

              setTimeout(() => {
                this.afterPdfUpload();
              }, 1000);

              this.uploading = false;
            });
          })
        )
        .subscribe();
    });
  }

  removeDocument(): void {
    const result = confirm(
      'La suppression du document sera permanente. Êtes-vous sûr de vouloir continuer?'
    );

    if (result) {
      this.document = null;
    }
  }

  async afterPdfUpload(): Promise<void> {
    this.initPDFViewer();

    if (this.document.url) {
      this.loadingPdfParser = true;

      this.pdfExtractData = await this.invoiceService.parsePdfURL(this.document.url);

      this.pdfParserSettingId = null;
      this.resetForm();

      this.updatedTemporaryProductsParsed();

      this.getAllTableHeaders();
      console.log(
        '🚀 ~ InvoiceAddPdfComponent ~ afterPdfUpload ~ this.pdfExtractData:',
        this.document.url,
        this.pdfExtractData
      );

      this.loadingPdfParser = false;

      this.getAllPdfParserSettings();

      setTimeout(() => {
        this.updateTooltip();
      }, 1000);
    }
  }

  resetForm(): void {
    this.form = new FormGroup(
      {
        name: new FormControl(null, Validators.required),
        language: new FormControl(EnumInvoiceLanguage.fr, Validators.required),
        currency: new FormControl(EnumCurrency.EUR, [Validators.required]),
        countryCode: new FormGroup({
          result: new FormControl('FR', [Validators.required])
        }),
        identifierText: new FormGroup({
          matchingText: new FormControl(null, Validators.required),
          nbOfHits: new FormControl(0)
        }),
        ref: new FormGroup({
          isValueInPdf: new FormControl(true),
          matchingText: new FormControl(null, Validators.required),
          valueSearchedPosition: new FormControl(EnumPdfParserValueSearchedPosition.current),
          nbOfHits: new FormControl(0),
          rules: new FormArray([]),
          result: new FormControl(null, Validators.required)
        }),
        dueDate: new FormGroup({
          isValueInPdf: new FormControl(true),
          isValueInDays: new FormControl(false),
          matchingText: new FormControl(null, Validators.required),
          valueSearchedPosition: new FormControl(EnumPdfParserValueSearchedPosition.current),
          nbOfHits: new FormControl(0),
          rules: new FormArray([]),
          result: new FormControl(null, Validators.required)
        }),
        issueDate: new FormGroup({
          isValueInPdf: new FormControl(true),
          matchingText: new FormControl(null, Validators.required),
          valueSearchedPosition: new FormControl(EnumPdfParserValueSearchedPosition.current),
          nbOfHits: new FormControl(0),
          rules: new FormArray([]),
          result: new FormControl(null, Validators.required)
        }),
        clientName: new FormGroup({
          isValueInPdf: new FormControl(false),
          matchingText: new FormControl(null),
          valueSearchedPosition: new FormControl(EnumPdfParserValueSearchedPosition.current),
          nbOfHits: new FormControl(0),
          rules: new FormArray([]),
          result: new FormControl(null, Validators.required)
        }),
        street: new FormGroup({
          isValueInPdf: new FormControl(false),
          matchingText: new FormControl(null),
          valueSearchedPosition: new FormControl(EnumPdfParserValueSearchedPosition.current),
          nbOfHits: new FormControl(0),
          rules: new FormArray([]),
          result: new FormControl(null, Validators.required)
        }),
        city: new FormGroup({
          isValueInPdf: new FormControl(false),
          matchingText: new FormControl(null),
          valueSearchedPosition: new FormControl(EnumPdfParserValueSearchedPosition.current),
          nbOfHits: new FormControl(0),
          rules: new FormArray([]),
          result: new FormControl(null, Validators.required)
        }),
        postalCode: new FormGroup({
          isValueInPdf: new FormControl(false),
          matchingText: new FormControl(null),
          valueSearchedPosition: new FormControl(EnumPdfParserValueSearchedPosition.current),
          nbOfHits: new FormControl(0),
          rules: new FormArray([]),
          result: new FormControl(null, Validators.required)
        }),
        siret: new FormGroup({
          isValueInPdf: new FormControl(false),
          matchingText: new FormControl(null),
          valueSearchedPosition: new FormControl(EnumPdfParserValueSearchedPosition.current),
          nbOfHits: new FormControl(0),
          rules: new FormArray([]),
          result: new FormControl(null)
        }),
        tvaNumber: new FormGroup({
          isValueInPdf: new FormControl(false),
          matchingText: new FormControl(null),
          valueSearchedPosition: new FormControl(EnumPdfParserValueSearchedPosition.current),
          nbOfHits: new FormControl(0),
          rules: new FormArray([]),
          result: new FormControl(null)
        }),
        productsColumnsConfig: new FormGroup({
          tableIndex: new FormControl(null),
          descriptionTitle: new FormControl(null),
          amountHt: new FormControl(null),
          isTvaValueInPdf: new FormControl(false),
          tvaRate: new FormControl(null),
          tvaRateSelected: new FormControl(EnumTvaRate.twenty),
          rulesForRows: new FormArray([])
        })
      },
      { validators: this.checkFormValidWithParsedData.bind(this) }
    );
  }

  checkFormValidWithParsedData(formGroup: FormGroup): void {
    for (const selector of ['identifierText', 'ref']) {
      if (this.getSelectorField(selector, 'matchingText')) {
        if (formGroup.value[selector]?.isValueInPdf && formGroup.value[selector]?.matchingText) {
          if (!formGroup.value[selector].nbOfHits) {
            this.getSelectorField(selector, 'matchingText').setErrors({
              notMatched: true
            });
          } else {
            this.getSelectorField(selector, 'matchingText').setErrors(null);
          }
        } else {
          this.getSelectorField(selector, 'matchingText').setErrors(null);
        }
      }
    }
  }

  updatedMatchingText(selector: string): void {
    if (this.form.value[selector].matchingText?.length) {
      const cells: IPdfExtractDataCell[] = this.searchInPdfExtractCellForMatchingText(
        this.form.value[selector].matchingText,
        this.form.value[selector].valueSearchedPosition
      );

      this.form.get(selector).get('nbOfHits').setValue(cells.length);

      if (selector === 'identifierText') {
        this.form.get('name').setValue(this.form.value[selector].matchingText);
      }

      if (this.form.get(selector).get('result')) {
        if (cells.length) {
          this.form
            .get(selector)
            .get('result')
            .setValue(this.getResultFromSelector(selector, cells[0].text));
        } else {
          this.form.get(selector).get('result').setValue(null);
        }
      }
    } else {
      this.form.get(selector).get('nbOfHits').setValue(0);
      this.form.get(selector).get('result').setValue(null);
    }
  }

  getResultFromSelector(selector: string, value: string): string {
    const rules = this.getSelectorRules(selector).value;

    for (const rule of rules) {
      switch (rule.ruleType) {
        case EnumPdfParserRuleType.remove:
          value = value.replaceAll(rule.matchingText, '');
          break;
        case EnumPdfParserRuleType.replaceByText:
          value = value.replaceAll(rule.matchingText, rule.replaceValue);
          break;
        case EnumPdfParserRuleType.removeAllTextBefore:
          value = value.split(rule.matchingText).pop();
          break;
        case EnumPdfParserRuleType.removeAllTextAfter:
          value = value.split(rule.matchingText, 1)[0];
          break;
      }
    }

    return value.trim();
  }

  searchInPdfExtractCellForMatchingText(
    text: string,
    position: EnumPdfParserValueSearchedPosition = EnumPdfParserValueSearchedPosition.current
  ): IPdfExtractDataCell[] {
    const cells: IPdfExtractDataCell[] = [];

    if (this.pdfExtractData?.tables?.length) {
      for (const table of this.pdfExtractData.tables) {
        if (table.headers.cells.length && table.rows.length) {
          for (let i = 0; i < table.headers.cells.length; i++) {
            if (table.headers.cells[i].text === text && table.rows[0].cells[i]) {
              // We get the first row value for same column
              cells.push(Object.assign({}, table.headers.cells[i]));
              cells[cells.length - 1].text += ': ' + table.rows[0].cells[i].text;
              break;
            }
          }
        }
      }
    }

    if (!cells.length && this.pdfExtractData?.cells?.length) {
      // Not found in table, we search in cells
      for (let i = 0; i < this.pdfExtractData?.cells.length; i++) {
        if (this.pdfExtractData?.cells[i].text.match(escapeRegExp(text))) {
          let cellToAdd: IPdfExtractDataCell | null = null;

          switch (position) {
            case EnumPdfParserValueSearchedPosition.before:
              if (
                this.pdfExtractData?.cells[i - 1] &&
                this.pdfExtractData?.cells[i].y === this.pdfExtractData?.cells[i - 1].y
              ) {
                cellToAdd = Object.assign({}, this.pdfExtractData?.cells[i - 1]);
              }
              break;
            case EnumPdfParserValueSearchedPosition.after:
              if (
                this.pdfExtractData?.cells[i + 1] &&
                this.pdfExtractData?.cells[i].y === this.pdfExtractData?.cells[i + 1].y
              ) {
                cellToAdd = Object.assign({}, this.pdfExtractData?.cells[i + 1]);
              }
              break;
            default:
              cellToAdd = Object.assign({}, this.pdfExtractData?.cells[i]);
              break;
          }

          if (cellToAdd) {
            cells.push(cellToAdd);
          }
        }
      }
    }

    return cells;
  }

  addRuleToSelector(selector: string): void {
    this.getSelectorRules(selector).push(
      new FormGroup({
        matchingText: new FormControl(null, Validators.required),
        ruleType: new FormControl(EnumPdfParserRuleType.remove, Validators.required),
        replaceValue: new FormControl('')
      })
    );
  }

  addRuleToSelectorAndSet(selector: string, rule: IPDfParserSettingsRule): void {
    this.addRuleToSelector(selector);

    this.getSelectorRules(selector)
      .at(this.getSelectorRules(selector).length - 1)
      .setValue(rule);
  }

  deleteRule(selector: string, i: number): void {
    this.getSelectorRules(selector).removeAt(i);

    this.updatedMatchingText(selector);
  }

  addRuleToProductsColumnsConfig(): void {
    this.getProductsColumnsConfigRulesForRows().push(
      new FormGroup({
        matchingText: new FormControl(null, Validators.required),
        ruleType: new FormControl(EnumProductsColumnsConfigType.ignore, Validators.required)
      })
    );
  }

  addRuleToProductsColumnsConfigAndSet(rule: IPDfParserSettingsProductColumnRule): void {
    this.addRuleToProductsColumnsConfig();

    this.getProductsColumnsConfigRulesForRows()
      .at(this.getProductsColumnsConfigRulesForRows().length - 1)
      .setValue(rule);
  }

  deleteRuleRowsForToProductsColumnsConfig(i: number): void {
    this.getProductsColumnsConfigRulesForRows().removeAt(i);

    this.updatedTemporaryProductsParsed();
  }

  updatedRuleType(selector: string, i: number): void {
    const rule = this.getSelectorRule(selector, i).value;
    const replaceValueFormControl: FormControl = this.getSelectorRuleField(
      selector,
      i,
      'replaceValue'
    );

    switch (rule.ruleType) {
      case EnumPdfParserRuleType.remove:
        replaceValueFormControl.clearValidators();
        break;
      case EnumPdfParserRuleType.replaceByText:
        replaceValueFormControl.setValidators(Validators.required);
        break;
    }
    replaceValueFormControl.updateValueAndValidity();

    this.updatedMatchingText(selector);
  }

  updatedRuleForRowsType(i: number): void {
    this.updatedTemporaryProductsParsed();
  }

  updatedIsValueInPdf(selector: string): void {
    if (this.getSelectorField(selector, 'isValueInPdf')) {
      if (this.getSelectorField(selector, 'isValueInPdf').value) {
        this.getSelectorField(selector, 'matchingText').setValidators(Validators.required);
        this.getSelectorField(selector, 'result').setValue(null);
      } else {
        this.getSelectorField(selector, 'matchingText').clearValidators();
      }

      this.getSelectorField(selector, 'matchingText').updateValueAndValidity();
    }
  }

  getAllTableHeaders(): void {
    this.pdfExtractDataTables = [];

    if (this.pdfExtractData?.tables.length) {
      for (const table of this.pdfExtractData?.tables) {
        const tableToAdd: {
          title: string;
          data: IPdfTableProductsExtracted;
        } = {
          title: '',
          data: {
            headers: [],
            rows: []
          }
        };

        let tableTitle: string[] = [];
        for (const header of table.headers.cells) {
          tableTitle.push(header.text);

          tableToAdd.data.headers.push(header.text);
        }

        for (const row of table.rows) {
          const rowToAdd: string[] = [];
          for (const cell of row.cells) {
            rowToAdd.push(cell.text);
          }

          tableToAdd.data.rows.push(rowToAdd);
        }

        tableToAdd.title = tableTitle.join(' / ');

        this.pdfExtractDataTables.push(tableToAdd);
      }
    }
  }

  updatedTemporaryProductsParsed(): void {
    this.temporaryProductsParsed = {
      headers: [],
      rows: []
    };

    if (
      this.getSelectorField('productsColumnsConfig', 'tableIndex').value &&
      this.pdfExtractDataTables[this.getSelectorField('productsColumnsConfig', 'tableIndex').value]
    ) {
      this.temporaryProductsParsed.headers = [
        ...this.pdfExtractDataTables[
          this.getSelectorField('productsColumnsConfig', 'tableIndex').value
        ].data.headers
      ];

      const columnsIndex: {
        descriptionTitle: number | null;
        amountHt: number | null;
        isTvaValueInPdf: boolean;
        tvaRate: number | null;
        tvaRateSelected: EnumTvaRate | null;
      } = {
        descriptionTitle: null,
        amountHt: null,
        isTvaValueInPdf:
          this.getSelectorField('productsColumnsConfig', 'isTvaValueInPdf').value ?? false,
        tvaRate: null,
        tvaRateSelected:
          this.getSelectorField('productsColumnsConfig', 'tvaRateSelected').value ?? null
      };

      for (const columnField of ['descriptionTitle', 'amountHt', 'tvaRate']) {
        if (
          this.getSelectorField('productsColumnsConfig', columnField).value !== null &&
          this.temporaryProductsParsed.headers.indexOf(
            this.getSelectorField('productsColumnsConfig', columnField).value
          ) !== -1
        ) {
          columnsIndex[columnField] = this.temporaryProductsParsed.headers.indexOf(
            this.getSelectorField('productsColumnsConfig', columnField).value
          );
        }
      }

      for (const row of this.pdfExtractDataTables[
        this.getSelectorField('productsColumnsConfig', 'tableIndex').value
      ].data.rows) {
        let addRow: boolean = true;

        for (const cell of row) {
          for (const rule of this.getProductsColumnsConfigRulesForRows().value) {
            switch (rule.ruleType) {
              case EnumProductsColumnsConfigType.ignore:
                if (cell.match(rule.matchingText)) {
                  addRow = false;
                }
                break;
            }
          }
        }

        if (addRow) {
          const rowToAdd: string[] = ['', '', ''];

          if (columnsIndex.descriptionTitle !== null && row[columnsIndex.descriptionTitle]) {
            rowToAdd[0] = row[columnsIndex.descriptionTitle];
          }

          if (columnsIndex.amountHt !== null && row[columnsIndex.amountHt]) {
            rowToAdd[1] = row[columnsIndex.amountHt].replace(',', '.');
          }

          if (columnsIndex.isTvaValueInPdf) {
            if (row[columnsIndex.tvaRate] && row[columnsIndex.tvaRate]) {
              rowToAdd[2] = row[columnsIndex.tvaRate].replace(',', '.').replace('%', '');
            }
          } else {
            rowToAdd[2] = columnsIndex.tvaRateSelected;
          }

          this.temporaryProductsParsed.rows.push(rowToAdd);
        }
      }
    }
  }

  async saveReaderSettings(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.status == 'VALID') {
      let data: IPdfParserSetting = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.processFromReader = true;

      this.form.disable();

      let promise;
      if (this.pdfParserSettingId) {
        data.id = this.pdfParserSettingId;
        promise = () => this.pdfParserSettingService.update(data);
      } else {
        promise = () => this.pdfParserSettingService.create(data);
      }

      promise()
        .then(async id => {
          this.pdfParserSettingId = id;

          this.form.enable();
          this.processFromReader = false;

          this.addingInvoice();
        })
        .catch(err => {
          console.log(err);
          this.processFromReader = false;

          alert(err.message);
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  async addingInvoice(): Promise<void> {
    this.savingInvoice = true;

    const products: IInvoiceProduct[] = [];

    for (const row of this.temporaryProductsParsed.rows) {
      const product: IInvoiceProduct = {
        id: this.remoteService.generateRandomId(),
        descriptionType: EnumInvoiceProductDescription.other,
        descriptionPercent: null,
        descriptionTitle: row[0],
        amountHt: parseFloat(row[1]),
        amountHtPositive: 0,
        tvaRateSelected: EnumTvaRate.other,
        tvaRate: parseFloat(row[2]),
        amountTva: 0,
        amountTvaPositive: 0,
        amountTtc: 0,
        amountTtcPositive: 0,
        currency: this.form.value.currency
      };

      switch (product.tvaRate) {
        case 0:
          product.tvaRateSelected = EnumTvaRate.zero;
          break;
        case 5.5:
          product.tvaRateSelected = EnumTvaRate.five;
          break;
        case 10:
          product.tvaRateSelected = EnumTvaRate.ten;
          break;
        case 20:
          product.tvaRateSelected = EnumTvaRate.twenty;
          break;
      }

      product.amountTva = Math.round((product.amountHt / 100) * product.tvaRate * 100) / 100;
      product.amountTtc = Math.round((product.amountHt + product.amountTva) * 100) / 100;

      for (const field of ['amountHt', 'amountTva', 'amountTtc']) {
        if (product[field] < 0) {
          product[field + 'Positive'] = product[field] * -1;
        } else {
          product[field + 'Positive'] = product[field];
        }
      }

      products.push(product);
    }

    const invoice: IInvoice = {
      language: this.form.value.language,
      invoiceType: this.invoiceType,
      ref: this.form.value.ref.result,
      refYear: null,
      refMonth: null,
      refNumber: null,
      enquiryId: this.enquiryId,
      clientId: null,
      status: EnumInvoiceStatus.accepted,
      products: products,
      dueDate: null,
      buyingPriceSupplierPriceInfo: null,
      buyingPriceSupplierPaymentsInfo: null,
      currency: this.form.value.currency,
      bankAccount: null,
      bankAccountData: null,
      cotationsId: [],
      displayCotationAirlineAndAircraft: false,
      cotationsCachedInfos: [],
      clientBillingInfosId: null,
      clientBillingInfos: {
        clientName: this.form.value.clientName.result,
        street: this.form.value.street.result,
        city: this.form.value.city.result,
        postalCode: this.form.value.postalCode.result,
        countryCode: this.form.value.countryCode.result,
        siret: this.form.value.siret.result,
        tvaNumber: this.form.value.tvaNumber.result
      } as IClientBillingInfos,
      internalNote: null,
      referalInvoiceId: null,
      followingInvoiceId: null,
      sentToClient: false,
      invoiceObject: null,
      definitiveInvoice: true,
      amountHtTotal: 0,
      amountTvaTotal: 0,
      amountTtcTotal: 0,
      amountSoldePaid: 0,
      amountSoldeLeft: 0,
      isSoldePaid: false,
      issueDate: formatDateFromStr(this.form.value.issueDate.result, this.form.value.language),
      document: this.document
    } as IInvoice;

    if (
      invoice.issueDate &&
      this.form.value.dueDate.result &&
      this.form.value.dueDate.isValueInDays
    ) {
      invoice.dueDate = addDays(invoice.issueDate, parseInt(this.form.value.dueDate.result));
    } else if (invoice.issueDate && !this.form.value.dueDate.result) {
      invoice.dueDate = addDays(invoice.issueDate, 30);
    } else {
      invoice.dueDate = formatDateFromStr(this.form.value.dueDate.result, this.form.value.language);
    }

    for (const product of products) {
      for (const field of ['amountHt', 'amountTva', 'amountTtc']) {
        invoice[field + 'Total'] += product[field];

        if (product[field] < 0) {
          product[field + 'Positive'] = product[field] * -1;
        } else {
          product[field + 'Positive'] = product[field];
        }
      }
    }

    invoice.amountSoldeLeft = invoice.amountTtcTotal;

    invoice.invoiceObject = invoiceGetProductsLabel(invoice).join(' / ');

    this.invoiceService
      .create(invoice)
      .then(async id => {
        this.router.navigate(['/admin/invoices/edit/' + id]);

        this.form.enable();
        this.processFromReader = false;
      })
      .catch(err => {
        console.log(err);
        this.processFromReader = false;

        alert(err.message);
      })
      .finally(() => {
        this.form.enable();
      });
  }

  getAllPdfParserSettings(): void {
    this.loadingPdfParser = true;

    if (!this.pdfParserSettingId) {
      for (const pdfParserSetting of this.pdfParserSettings) {
        if (pdfParserSetting.identifierText.matchingText) {
          const cells: IPdfExtractDataCell[] = this.searchInPdfExtractCellForMatchingText(
            pdfParserSetting.identifierText.matchingText
          );

          if (cells.length) {
            this.pdfParserSettingId = pdfParserSetting.id;

            this.updateFormFromSavedSettings(pdfParserSetting);
            break;
          }
        }
      }
    }

    this.loadingPdfParser = false;
  }

  updatePdfParserSettingId(): void {
    for (const pdfParserSetting of this.pdfParserSettings) {
      if (this.pdfParserSettingId === pdfParserSetting.id) {
        this.updateFormFromSavedSettings(pdfParserSetting);

        break;
      }
    }
  }

  updateFormFromSavedSettings(pdfParserSetting: IPdfParserSetting): void {
    this.form.reset();

    for (const field in pdfParserSetting) {
      if (this.form.get(field)) {
        this.form.get(field).patchValue(pdfParserSetting[field]);

        if (
          typeof this.form.get(field).value.valueSearchedPosition !== 'undefined' &&
          !this.form.get(field).value.valueSearchedPosition
        ) {
          this.form
            .get(field)
            .get('valueSearchedPosition')
            .setValue(EnumPdfParserValueSearchedPosition.current);
        }

        this.updatedIsValueInPdf(field);

        if (this.form.get(field).get('rules')) {
          if (pdfParserSetting[field].rules?.length) {
            for (const rule of pdfParserSetting[field].rules) {
              this.addRuleToSelectorAndSet(field, rule);
            }
          }
        }

        if (this.form.get(field).get('rulesForRows')) {
          if (pdfParserSetting[field].rulesForRows?.length) {
            for (const rule of pdfParserSetting[field].rulesForRows) {
              this.addRuleToProductsColumnsConfigAndSet(rule);
            }
          }
        }
      }
    }

    for (const selector of this.selectorFields) {
      switch (selector.value) {
        case 'productsColumnsConfig':
          this.updatedTemporaryProductsParsed();
          break;
        default:
          if (this.getSelectorField(selector.value, 'isValueInPdf')?.value) {
            this.updatedMatchingText(selector.value);
          }
          break;
      }
    }
  }

  updatedIsValueInDays(field: string): void {
    this.form
      .get(field)
      .get('result')
      .setValue(this.form.get(field).get('isValueInDays').value ? 30 : '');
    this.form.get(field).get('result').updateValueAndValidity();
  }
}
