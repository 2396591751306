import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { ICountry } from 'src/app/interfaces/country.interface';

import countriesFr from '../../../countries_fr.json';
import { Router } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-list-countries',
  templateUrl: './list-countries.component.html',
  styleUrls: ['./list-countries.component.scss']
})
export class ListCountriesComponent implements OnInit {
  faSearch = faSearch;

  EnumAcl = EnumAcl;

  rootUrl: string = './';
  currentPagination: string = 'countries-list';
  countries: ICountry[] = [];
  countriesFr = countriesFr;
  countriesList: {
    code: string;
    name: string;
  }[] = [];
  countrySelectedId: string | null = null;

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private router: Router
  ) {
    for (const countryCode in countriesFr) {
      this.countriesList.push({
        code: countryCode,
        name: countriesFr[countryCode]
      });
    }
    this.countriesList.sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.countriesList);

    this.paginationService.pagination[this.currentPagination].currentPage = 0;

    this.fetchData();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    await this.paginationService.fetchData(this.currentPagination);
  }

  goToSelectedCountry(): void {
    if (this.countrySelectedId) {
      this.router.navigateByUrl('/admin/countries/view/' + this.countrySelectedId);
    }
  }
}
