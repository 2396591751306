import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';

@Injectable({
  providedIn: 'root'
})
export class EncaissementTypeFirestoreService extends FirestoreService<IEncaissementType> {
  protected basePath = 'encaissementTypes';
}
