export enum EnumEnquiryTarget {
  jet = 'jet',
  tp = 'tp',
  helico = 'helico'
}

export const getEnquiryTargetLabel = (target: EnumEnquiryTarget): string => {
  switch (target) {
    default:
      return target;
    case EnumEnquiryTarget.jet:
      return 'JET';
    case EnumEnquiryTarget.tp:
      return 'TP';
    case EnumEnquiryTarget.helico:
      return 'Hélico';
  }
};
