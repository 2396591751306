<breadcrumbs
    [items]="
        flightBriefCateringId
            ? [
                  {
                      title: 'Liste des caterings de flight briefs',
                      path: '/admin/flight-brief-caterings/list'
                  },
                  {
                      title: flightBriefCatering ? flightBriefCatering.text : '',
                      path: '/admin/flight-brief-caterings/view/' + flightBriefCateringId
                  },
                  {
                      title: 'Édition d\'un catering de flight briefs',
                      path: '/admin/flight-brief-caterings/edit/' + flightBriefCateringId
                  }
              ]
            : [
                  {
                      title: 'Liste des caterings de flight briefs',
                      path: '/admin/flight-brief-caterings/list'
                  },
                  {
                      title: 'Ajout d\'un catering de flight briefs',
                      path: '/admin/flight-brief-caterings/add'
                  }
              ]
    "
></breadcrumbs>

<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="name">Langue</label>
                        <select
                            class="form-select"
                            id="language"
                            formControlName="language"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['language'].touched) &&
                                form.controls['language'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="language"
                                *ngFor="let language of getLanguages()"
                                [innerHtml]="getLanguageLabel(language)"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['language'].touched) &&
                                    form.controls['language'].errors &&
                                    form.controls['language'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="text">Texte</label>
                        <textarea
                            class="form-control"
                            id="text"
                            formControlName="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['text'].touched) &&
                                form.controls['text'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            rows="2"
                        ></textarea>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['text'].touched) &&
                                    form.controls['text'].errors &&
                                    form.controls['text'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="position">Position</label>
                        <input
                            class="form-control"
                            id="position"
                            formControlName="position"
                            type="number"
                            min="0"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['position'].touched) &&
                                form.controls['position'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['position'].touched) &&
                                    form.controls['position'].errors &&
                                    form.controls['position'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="
                    flightBriefCateringId
                        ? '/admin/flight-brief-caterings/view/' + flightBriefCateringId
                        : '/admin/flight-brief-caterings/list'
                "
                class="btn btn-outline-primary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>
