<breadcrumbs
    [items]="[
        {
            title: 'Liste des clients',
            path: '/admin/clients/list'
        },
        {
            title: client ? client.name : '',
            path: '/admin/clients/view/' + clientId
        }
    ]"
></breadcrumbs>

<div class="row">
    <div class="col-lg-4 order-lg-last">
        <div class="row">
            <div class="col-md-6 col-lg-12">
                <div class="card mb-4">
                    <div class="card-body" *ngIf="loading">
                        <h3><div class="skeleton-text"></div></h3>
                    </div>
                    <div class="card-body text-center" *ngIf="!loading && client">
                        <h3 [innerHtml]="client.name"></h3>

                        <dl>
                            <dt>Adresse</dt>
                            <dd [innerHtml]="client.address"></dd>
                            <dt>Dernière mise à jour</dt>
                            <dd [innerHtml]="client.update_time | date : 'dd/MM/yyyy HH:mm'"></dd>
                        </dl>

                        <a
                            class="btn btn-primary btn-sm"
                            [routerLink]="'/admin/statistics/client/' + clientId"
                            *ngIf="hasAclAccess(EnumAcl.clientsStatistics)"
                            >Statistiques</a
                        ><br />
                        <br />
                        <a
                            class="btn btn-primary btn-sm"
                            [href]="
                                'https://artheauaviation2.pipedrive.com/organization/' + clientId
                            "
                            target="_blank"
                            >Ouvrir dans Pipedrive</a
                        >

                        <div class="text-center mt-4">
                            <button
                                type="button"
                                (click)="importFromPipedrive()"
                                class="btn btn-primary mb-4"
                                [disabled]="refreshingFromPipedrive"
                                [innerText]="
                                    refreshingFromPipedrive
                                        ? 'Rafraîchissement en cours...'
                                        : 'Rafraichir depuis Pipedrive'
                                "
                            ></button>
                            <br />
                            <button
                                type="button"
                                (click)="delete()"
                                class="btn btn-primary"
                                *ngIf="hasAclAccess(EnumAcl.clientsDelete)"
                                [disabled]="deleting"
                                [innerText]="deleting ? 'Suppression en cours...' : 'Supprimer'"
                            ></button>
                        </div>
                    </div>
                </div>

                <div class="card mb-4">
                    <div class="card-header">Addresse de facturation</div>
                    <div class="card-body" *ngIf="loadingClientBillingInfos">
                        <div class="skeleton-text" *ngFor="let number of [].constructor(14)"></div>
                    </div>
                    <div class="card-body" *ngIf="!loadingClientBillingInfos">
                        <div
                            class="alert alert-warning text-center mb-0"
                            *ngIf="!clientBillingInfos"
                        >
                            Aucun adresse de facturation pour ce client.
                        </div>
                        <dl *ngIf="clientBillingInfos" class="mb-0">
                            <dt>Nom du client sur la facture</dt>
                            <dd
                                [class.text-muted]="!clientBillingInfos.clientName"
                                [innerText]="clientBillingInfos.clientName || 'Vide'"
                            ></dd>
                            <dt>Rue</dt>
                            <dd
                                [class.text-muted]="!clientBillingInfos.street"
                                [innerText]="clientBillingInfos.street || 'Vide'"
                            ></dd>
                            <dt>Code postal</dt>
                            <dd
                                [class.text-muted]="!clientBillingInfos.postalCode"
                                [innerText]="clientBillingInfos.postalCode || 'Vide'"
                            ></dd>
                            <dt>Ville</dt>
                            <dd
                                [class.text-muted]="!clientBillingInfos.city"
                                [innerText]="clientBillingInfos.city || 'Vide'"
                            ></dd>
                            <dt>Pays</dt>
                            <dd
                                [class.text-muted]="
                                    !clientBillingInfos.countryCode ||
                                    !countries[clientBillingInfos.countryCode]
                                "
                                [innerText]="countries[clientBillingInfos.countryCode] || 'Vide'"
                            ></dd>
                            <dt>TVA Intracommunautaire</dt>
                            <dd
                                [class.text-muted]="!clientBillingInfos.tvaNumber"
                                [innerText]="clientBillingInfos.tvaNumber || 'Vide'"
                            ></dd>
                            <dt>Siret</dt>
                            <dd
                                class="mb-0"
                                [class.text-muted]="!clientBillingInfos.siret"
                                [innerText]="clientBillingInfos.siret || 'Vide'"
                            ></dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-8">
        <enquiries-list
            [loading]="paginationService.loading"
            [enquiries]="paginationService.pagination[currentPagination].data"
            [usersObj]="usersObj"
        ></enquiries-list>

        <div class="text-end mt-4 mb-4">
            <a
                [routerLink]="'/admin/enquiry/add-to-client/' + clientId"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.enquiriesAdd)"
                >+ Nouvelle demande</a
            >
        </div>

        <!--     <div class="row" *ngIf="!loading && client && client.documentsUrl.length">
      <div class="col-md-6">
        <strong>Documentation</strong><br>
        <ul class="list-unstyled">
          <li *ngFor="let documentUrl of client.documentsUrl">
            <a [href]="documentUrl.url" target="_blank" [innerHtml]="documentUrl.title"></a>
          </li>
        </ul>
      </div>
    </div> -->
    </div>
</div>
