import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  faCancel,
  faCheck,
  faClock,
  faClockRotateLeft,
  faEdit,
  faEye,
  faHome,
  faPlaneArrival,
  faPlaneDeparture
} from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import {
  EnumFlightChecklistTemplateFieldOptionStatus,
  EnumFlightChecklistTemplateFieldType,
  IFlightChecklistTemplate,
  IFlightChecklistTemplateField,
  IFlightChecklistTemplateFieldOption
} from 'src/app/interfaces/flight-checklist-template.interface';
import {
  IFlightChecklist,
  IFlightChecklistFieldDataValue,
  getFinishedCheckOnTotalForFlight,
  initFlightChecklistFromTemplate
} from 'src/app/interfaces/flight-checklist.interface';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import { AclService } from 'src/app/services/acl.service';
import { EnquiryFlightService } from 'src/app/services/enquiry-flights/enquiry-flights.service';
import { FlightChecklistTemplateService } from 'src/app/services/flight-checklist-templates/flight-checklist-templates.service';
import { FlightChecklistService } from 'src/app/services/flight-checklists/flight-checklists.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RemoteService } from 'src/app/services/remote.service';

@Component({
  selector: 'app-enquiry-flights-cards',
  templateUrl: './enquiry-flights-cards.component.html',
  styleUrls: ['./enquiry-flights-cards.component.scss']
})
export class EnquiryFlightsCardsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() loading: boolean = false;
  @Input() enquiryFlights: IEnquiryFlight[] = [];
  @Input() hideEnquiryColumns: boolean = false;

  @ViewChild('modalComment', { static: false }) modalCommentElement: ElementRef;
  @ViewChild('modalChecklist', { static: false }) modalChecklistElement: ElementRef;

  EnumAcl = EnumAcl;
  EnumFlightChecklistTemplateFieldType = EnumFlightChecklistTemplateFieldType;
  EnumFlightChecklistTemplateFieldOptionStatus = EnumFlightChecklistTemplateFieldOptionStatus;
  EnumEnquiryType = EnumEnquiryType;

  getFinishedCheckOnTotalForFlight = getFinishedCheckOnTotalForFlight;

  faHome = faHome;
  faEdit = faEdit;
  faEye = faEye;
  faCancel = faCancel;
  faCheck = faCheck;
  faClockRotateLeft = faClockRotateLeft;

  faPlaneDeparture = faPlaneDeparture;
  faPlaneArrival = faPlaneArrival;
  faClock = faClock;

  calendarDates: string[] = [];

  nbDaysDisplayed: number = 5;

  selectedFlight: IEnquiryFlight | null = null;
  formComment: FormGroup = new FormGroup({
    comment: new FormControl('')
  });

  flightChecklistTemplateByEnquiryType: { [enquiryType: string]: IFlightChecklistTemplate | null } =
    {};
  flightChecklistByEnquiryFlight: { [enquiryFlightId: string]: IFlightChecklist | null } = {};

  flightChecklistTemplate: IFlightChecklistTemplate | null = null;
  flightChecklistTemplateFieldsById: { [fieldId: string]: IFlightChecklistTemplateField } = {};
  loadingFlightChecklistTemplate: boolean = false;

  flightChecklist: IFlightChecklist | null = null;
  loadingFlightChecklist: boolean = false;

  currentUser: IUser | null = null;

  flightChecklistFieldValuesById: {
    [fieldId: string]: {
      field: IFlightChecklistTemplateField;
      value: IFlightChecklistFieldDataValue | null;
      selectedOption: IFlightChecklistTemplateFieldOption | null;
      history: IFlightChecklistFieldDataValue[];
    };
  } = {};

  editingChecklistFieldId: string | null = null;

  showHistoryFieldId: string | null = null;

  private subscriptions = new Subscription();
  private subscriptionFlightChecklist = new Subscription();

  constructor(
    private aclService: AclService,
    private enquiryFlightService: EnquiryFlightService,
    private loaderService: LoaderService,
    private flightChecklistTemplateService: FlightChecklistTemplateService,
    private flightChecklistService: FlightChecklistService,
    private remoteService: RemoteService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.remoteService.userObservable.subscribe((user: IUser) => {
        this.currentUser = user;
      })
    );

    this.refreshChecklistAndTemplateFromEnquiryFlights();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshChecklistAndTemplateFromEnquiryFlights();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionFlightChecklist.unsubscribe();

    window['$']('.tooltip').remove();

    this.removeModal();
  }

  removeModal(): void {
    window['$'](this.modalCommentElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  selectFlight(flight: IEnquiryFlight | null = null): void {
    this.selectedFlight = flight;
  }

  editComment(flight: IEnquiryFlight): void {
    this.selectFlight(flight);

    this.formComment.reset();
    this.formComment.get('comment').setValue(flight.comment || null);
    this.formComment.get('comment').updateValueAndValidity();

    window['$'](this.modalCommentElement.nativeElement).modal('show');
  }

  viewFlightChecklist(flight: IEnquiryFlight): void {
    this.selectFlight(flight);

    this.loadFlightChecklistAndTemplate();

    window['$'](this.modalChecklistElement.nativeElement).modal('show');

    this.updateTooltip();
  }

  async submitFormComment(): Promise<void> {
    this.formComment.markAsTouched();

    if (this.formComment.status == 'VALID' && this.selectedFlight) {
      this.formComment.disable();

      this.loaderService.presentLoader();

      try {
        await this.enquiryFlightService.update({
          id: this.selectedFlight.id,
          comment: this.formComment.value.comment
        } as IEnquiryFlight);

        this.formComment.reset();
        this.formComment.enable();

        window['$'](this.modalCommentElement.nativeElement).modal('hide');

        this.loaderService.hideLoaderOnSuccess();
      } catch (err: any) {
        this.loaderService.hideLoaderOnFailure(err.message);
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadFlightChecklistAndTemplate(): void {
    if (this.selectedFlight) {
      if (this.subscriptionFlightChecklist) {
        this.subscriptionFlightChecklist.unsubscribe();
      }

      this.subscriptionFlightChecklist = new Subscription();

      this.loadFlightChecklistTemplate();
      this.loadFlightChecklist();
    }
  }

  private loadFlightChecklistTemplate(): void {
    if (this.selectedFlight) {
      this.loadingFlightChecklistTemplate = true;

      this.subscriptionFlightChecklist.add(
        this.flightChecklistTemplateService
          .getOneForEnquiryType(this.selectedFlight.enquiryType)
          .subscribe((flightChecklistTemplate: IFlightChecklistTemplate) => {
            this.flightChecklistTemplate = flightChecklistTemplate;

            if (this.flightChecklistTemplate) {
              this.flightChecklistTemplateByEnquiryType[this.flightChecklistTemplate.enquiryType] =
                this.flightChecklistTemplate;

              for (const module of this.flightChecklistTemplate.modules) {
                for (const field of module.fields) {
                  this.flightChecklistTemplateFieldsById[field.id] = field;
                }
              }
            }

            this.refreshFlightChecklist();

            this.loadingFlightChecklistTemplate = false;
          })
      );
    }
  }

  private loadFlightChecklist(): void {
    if (this.selectedFlight) {
      this.loadingFlightChecklist = true;

      this.subscriptionFlightChecklist.add(
        this.flightChecklistService
          .getForFlight(this.selectedFlight.id)
          .subscribe((flightChecklist: IFlightChecklist) => {
            this.flightChecklist = flightChecklist;

            if (this.flightChecklist) {
              this.flightChecklistByEnquiryFlight[this.selectedFlight.id] = this.flightChecklist;
            }

            this.refreshFlightChecklist();

            this.loadingFlightChecklist = false;
          })
      );
    }
  }

  refreshFlightChecklist(): void {
    if (this.flightChecklistTemplate && !this.flightChecklist?.id) {
      this.flightChecklist = initFlightChecklistFromTemplate(
        this.selectedFlight,
        this.flightChecklistTemplate
      );
    }

    this.flightChecklistFieldValuesById = {};

    if (this.flightChecklist) {
      if (this.flightChecklistTemplate) {
        for (const module of this.flightChecklistTemplate.modules) {
          for (const field of module.fields) {
            this.flightChecklistFieldValuesById[field.id] = {
              field,
              value: null,
              selectedOption: null,
              history: []
            };
          }
        }
      }

      for (const fieldData of this.flightChecklist.fieldData) {
        if (this.flightChecklistTemplateFieldsById[fieldData.fieldId]) {
          let selectedOption: IFlightChecklistTemplateFieldOption | null = null;
          let value: string | null = null;

          if (fieldData.history.length) {
            value = fieldData.history[fieldData.history.length - 1].value;
          } else {
            value = fieldData.defaultOptionId;
          }

          if (
            value &&
            this.flightChecklistTemplateFieldsById[fieldData.fieldId].type ===
              EnumFlightChecklistTemplateFieldType.select
          ) {
            for (const option of this.flightChecklistTemplateFieldsById[fieldData.fieldId]
              .options) {
              if (option.id === value) {
                selectedOption = option;
                break;
              }
            }
          }

          if (fieldData.history.length) {
            this.flightChecklistFieldValuesById[fieldData.fieldId] = {
              field: this.flightChecklistTemplateFieldsById[fieldData.fieldId],
              value: fieldData.history[fieldData.history.length - 1],
              selectedOption,
              history: fieldData.history
            };
          } else {
            this.flightChecklistFieldValuesById[fieldData.fieldId] = {
              field: this.flightChecklistTemplateFieldsById[fieldData.fieldId],
              value: {
                value: fieldData.defaultOptionId
              },
              selectedOption,
              history: []
            };
          }
        }
      }
    }

    this.updateTooltip();
  }

  editChecklistField(fieldId: string): void {
    this.editingChecklistFieldId = fieldId;

    setTimeout(() => {
      this.updateTooltip();
    }, 1000);
  }

  clearEditChecklistField(): void {
    this.editingChecklistFieldId = null;

    this.refreshFlightChecklist();
  }

  async saveEditChecklistField(): Promise<void> {
    this.updateTooltip();

    await this.loaderService.presentLoader('Sauvegarde en cours ...');

    try {
      for (const fieldData of this.flightChecklist.fieldData) {
        if (fieldData.fieldId === this.editingChecklistFieldId) {
          fieldData.history.push({
            value: this.flightChecklistFieldValuesById[fieldData.fieldId].value?.value || null,
            userId: this.currentUser?.id,
            userInitials: this.currentUser?.initials,
            userFullName: getUserFullname(this.currentUser),
            date: new Date()
          });
        }
      }

      let promise;
      if (this.flightChecklist.id) {
        promise = () => this.flightChecklistService.update(this.flightChecklist);
      } else {
        promise = () => this.flightChecklistService.create(this.flightChecklist);
      }

      await promise();

      this.clearEditChecklistField();

      await this.loaderService.hideLoaderOnSuccess();
    } catch (err) {
      await this.loaderService.hideLoaderOnFailure(err.message);
    } finally {
      this.updateTooltip();
    }
  }

  updateTooltip(): void {
    window['$']('.tooltip').remove();

    window['$']('[rel="tooltip"]').tooltip({
      html: true,
      boundary: 'window'
    });
  }

  showFieldHistory(fieldId: string): void {
    if (this.showHistoryFieldId === fieldId) {
      this.showHistoryFieldId = null;
    } else {
      this.showHistoryFieldId = fieldId;
    }

    this.updateTooltip();
  }

  getFieldValueFromOption(fieldId: string, value: string): string | null {
    if (
      this.flightChecklistTemplateFieldsById[fieldId] &&
      this.flightChecklistTemplateFieldsById[fieldId].type ===
        EnumFlightChecklistTemplateFieldType.select
    ) {
      for (const option of this.flightChecklistTemplateFieldsById[fieldId].options) {
        if (option.id === value) {
          return option.text;
        }
      }
    }

    return value;
  }

  getFieldStatusFromOption(
    fieldId: string,
    value: string
  ): EnumFlightChecklistTemplateFieldOptionStatus | null {
    if (
      this.flightChecklistTemplateFieldsById[fieldId] &&
      this.flightChecklistTemplateFieldsById[fieldId].type ===
        EnumFlightChecklistTemplateFieldType.select
    ) {
      for (const option of this.flightChecklistTemplateFieldsById[fieldId].options) {
        if (option.id === value) {
          return option.status;
        }
      }
    }

    return null;
  }

  refreshChecklistAndTemplateFromEnquiryFlights(): void {
    if (this.enquiryFlights) {
      for (const enquiryFlight of this.enquiryFlights) {
        this.loadChecklistTemplate(enquiryFlight.enquiryType);
        this.loadChecklist(enquiryFlight.id);
      }
    }
  }

  loadChecklistTemplate(enquiryType: EnumEnquiryType): void {
    if (typeof this.flightChecklistTemplateByEnquiryType[enquiryType] === 'undefined') {
      this.flightChecklistTemplateByEnquiryType[enquiryType] = null;

      this.subscriptions.add(
        this.flightChecklistTemplateService
          .getOneForEnquiryType(enquiryType)
          .subscribe((flightChecklistTemplate: IFlightChecklistTemplate) => {
            if (flightChecklistTemplate) {
              this.flightChecklistTemplateByEnquiryType[enquiryType] = flightChecklistTemplate;
            }
          })
      );
    }
  }

  loadChecklist(enquiryFlightId: string): void {
    if (typeof this.flightChecklistByEnquiryFlight[enquiryFlightId] === 'undefined') {
      this.flightChecklistByEnquiryFlight[enquiryFlightId] = null;

      this.subscriptions.add(
        this.flightChecklistService
          .getForFlight(enquiryFlightId)
          .subscribe((flightChecklist: IFlightChecklist) => {
            if (flightChecklist) {
              this.flightChecklistByEnquiryFlight[enquiryFlightId] = flightChecklist;
            }
          })
      );
    }
  }
}
