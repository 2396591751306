<breadcrumbs [items]="breadcrumbItems"></breadcrumbs>

<form (submit)="submitForm()" [formGroup]="form" class="main-form">
    <div class="row">
        <div class="col-md-3">
            <div class="form-group">
                <label>Dossier</label>
                <app-enquiry-ref-picker
                    [contractRefOnly]="true"
                    [defaultValue]="form.value.enquiryId"
                    inputName="enquiryId"
                    (setValueToFormControl)="setValueToFormControl($event)"
                    [disabled]="flightBrief?.id"
                ></app-enquiry-ref-picker>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="language">Langue</label>
                <select
                    class="form-select"
                    id="language"
                    formControlName="language"
                    [class.is-invalid]="
                        (form.touched || form.get('language').touched) &&
                        form.get('language').status == 'INVALID'
                            ? 'danger'
                            : ''
                    "
                    (change)="changedLanguage()"
                >
                    <option
                        [value]="language"
                        *ngFor="let language of getLanguages()"
                        [innerHtml]="getLanguageLabel(language)"
                    ></option>
                </select>

                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || form.get('language').touched) &&
                            form.get('language').errors &&
                            form.get('language').errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="versionNumber">Version</label>
                <input
                    class="form-control"
                    id="versionNumber"
                    formControlName="versionNumber"
                    type="number"
                    min="0"
                    [class.is-invalid]="
                        (form.touched || form.get('versionNumber').touched) &&
                        form.get('versionNumber').status == 'INVALID'
                            ? 'danger'
                            : ''
                    "
                />

                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || form.get('versionNumber').touched) &&
                            form.get('versionNumber').errors &&
                            form.get('versionNumber').errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-4">
        <div class="card-header text-center">Compagnie et appareil</div>
        <div class="card-body">
            <div class="row justify-content-center">
                <div class="col-md-4">
                    <div class="form-group mb-0">
                        <label for="airlineTitle">Compagnie aérienne</label>
                        <app-airline-autocomplete
                            (setValueToFormControl)="setValueToFormControl($event)"
                            inputName="airline"
                            [disabled]="sending"
                            placeholder="Recherchez"
                            [defaultValue]="airline"
                            [noMargin]="true"
                        ></app-airline-autocomplete>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.get('airlineTitle').touched) &&
                                    form.get('airlineTitle').errors &&
                                    form.get('airlineTitle').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-check mb-2" *ngIf="form.value.airlineId">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="displayAirlineOnFlightBrief"
                            formControlName="displayAirlineOnFlightBrief"
                        />
                        <label class="form-check-label" for="displayAirlineOnFlightBrief"
                            >Afficher la compagnie sur le flight brief</label
                        >
                    </div>
                </div>
                <div class="col-md-4" *ngIf="form.value.airlineId">
                    <div class="form-group">
                        <label for="aircraftModelId">Appareil</label>

                        <div class="skeleton-text" *ngIf="loadingAircraftsCompiled"></div>
                        <div
                            *ngIf="!loadingAircraftsCompiled && !aircraftModels.length"
                            class="alert alert-warning text-center"
                        >
                            Aucun appareil trouvé
                        </div>
                        <select
                            class="form-select"
                            id="aircraftModelId"
                            formControlName="aircraftModelId"
                            [class.is-invalid]="
                                (form.touched || form.get('aircraftModelId').touched) &&
                                form.get('aircraftModelId').status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (change)="updatedAircraftModelId()"
                            *ngIf="!loadingAircraftsCompiled && aircraftModels.length"
                        >
                            <option [ngValue]="null">Choisissez</option>
                            <option
                                *ngFor="let aircraftModel of aircraftModels"
                                [value]="aircraftModel.id"
                                [innerText]="aircraftModel.title"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.get('aircraftModelId').touched) &&
                                    form.get('aircraftModelId').errors &&
                                    form.get('aircraftModelId').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="form.value.aircraftModelId">
                    <div class="form-group">
                        <label for="aircraftId">Immatriculation</label>

                        <div class="skeleton-text" *ngIf="loadingAircrafts"></div>
                        <div
                            *ngIf="!loadingAircrafts && !aircrafts.length"
                            class="alert alert-warning text-center"
                        >
                            Aucun appareil trouvé
                        </div>
                        <select
                            class="form-select"
                            id="aircraftId"
                            formControlName="aircraftId"
                            [class.is-invalid]="
                                (form.touched || form.get('aircraftId').touched) &&
                                form.get('aircraftId').status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (change)="updatedAircraftId()"
                            *ngIf="!loadingAircrafts && aircrafts.length"
                        >
                            <option [ngValue]="null">Choisissez</option>
                            <option
                                *ngFor="let aircraft of aircrafts"
                                [value]="aircraft.id"
                                [innerText]="
                                    aircraft.registration !== ''
                                        ? aircraft.registration
                                        : 'Immatriculation inconnu (id : ' + aircraft.id + ')'
                                "
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.get('aircraftId').touched) &&
                                    form.get('aircraftId').errors &&
                                    form.get('aircraftId').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="text-center mb-2">
                        <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-aircraft-add"
                            class="btn btn-primary btn-sm"
                            *ngIf="hasAclAccess(EnumAcl.aircraftsAdd)"
                            [disabled]="loadingAircrafts"
                        >
                            + Nouvel appareil
                        </button>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    *ngIf="enquiry?.type !== EnumEnquiryType.cargo && form.value.aircraftId"
                >
                    <div class="form-group">
                        <label for="aircraftHasToilet">WC</label>
                        <select
                            class="form-select"
                            id="aircraftHasToilet"
                            formControlName="aircraftHasToilet"
                            [class.is-invalid]="
                                (form.touched || form.get('aircraftHasToilet').touched) &&
                                form.get('aircraftHasToilet').status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option [ngValue]="true">Oui</option>
                            <option [ngValue]="false">Non</option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.get('aircraftHasToilet').touched) &&
                                    form.get('aircraftHasToilet').errors &&
                                    form.get('aircraftHasToilet').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    *ngIf="enquiry?.type !== EnumEnquiryType.cargo && form.value.aircraftId"
                >
                    <div class="form-group">
                        <label for="smokingIsAllowed">Vol fumeur</label>
                        <select
                            class="form-select"
                            id="smokingIsAllowed"
                            formControlName="smokingIsAllowed"
                            [class.is-invalid]="
                                (form.touched || form.get('smokingIsAllowed').touched) &&
                                form.get('smokingIsAllowed').status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option [ngValue]="true">Oui</option>
                            <option [ngValue]="false">Non</option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.get('smokingIsAllowed').touched) &&
                                    form.get('smokingIsAllowed').errors &&
                                    form.get('smokingIsAllowed').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-4">
        <div class="card-header">
            <div
                class="card-title mb-0 text-center"
                [innerText]="trips.length > 1 ? 'Itinéraires' : 'Itinéraire'"
            ></div>
        </div>
        <ul class="list-group list-group-flush" formArrayName="trips">
            <li
                class="list-group-item"
                *ngFor="let trip of trips.controls; let i = index"
                [formGroupName]="i"
            >
                <div
                    class="d-flex justify-content-center"
                    *ngIf="enquiry?.type === EnumEnquiryType.cargo"
                >
                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            formControlName="isFerryFlight"
                            [id]="'trip-' + i + '-isFerryFlight'"
                            (change)="isFerryFlightUpdated(i)"
                        />
                        <label class="form-check-label" [attr.for]="'trip-' + i + '-isFerryFlight'"
                            >Est un vol de positionnement ?</label
                        >
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label [for]="'trip-' + i + '-date'">Date </label>
                            <span
                                class="float-end text-muted"
                                [innerText]="getDayOfWeek(getTripField(i, 'date').value)"
                            ></span>
                            <input
                                [id]="'trip-' + i + '-date'"
                                type="date"
                                class="form-control"
                                formControlName="date"
                                [class.is-invalid]="
                                    (form.touched || getTripField(i, 'date').touched) &&
                                    getTripField(i, 'date').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                min="2000-01-01"
                            />
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || getTripField(i, 'date').touched) &&
                                        getTripField(i, 'date').errors &&
                                        getTripField(i, 'date').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                                <div
                                    *ngIf="
                                        (form.touched || getTripField(i, 'date').touched) &&
                                        getTripField(i, 'date').errors &&
                                        getTripField(i, 'date').errors['dateBelowMinimum']
                                    "
                                >
                                    La date doit être supérieure à
                                    {{
                                        getTripField(i, "date").errors &&
                                            getTripField(i, "date").errors["dateMinimum"]
                                            | date : "dd/MM/yyyy"
                                    }}.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Aéroport Départ</label>
                            <app-airport-picker
                                [inputName]="'trips.' + i + '.airportDepartId'"
                                [defaultValue]="form && getTripField(i, 'airportDepartId').value"
                                [disabled]="form.disabled || sending"
                                [isInvalid]="
                                    (form.touched || getTripField(i, 'airportDepartId').touched) &&
                                    getTripField(i, 'airportDepartId').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                (setValueToFormControl)="setValueToFormControl($event)"
                                [noMargin]="true"
                            >
                            </app-airport-picker>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            getTripField(i, 'airportDepartId').touched) &&
                                        getTripField(i, 'airportDepartId').errors &&
                                        getTripField(i, 'airportDepartId').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>Aéroport Arrivé</label>
                            <app-airport-picker
                                [inputName]="'trips.' + i + '.airportArrivalId'"
                                [defaultValue]="form && getTripField(i, 'airportArrivalId').value"
                                [disabled]="form.disabled || sending"
                                [isInvalid]="
                                    (form.touched || getTripField(i, 'airportArrivalId').touched) &&
                                    getTripField(i, 'airportArrivalId').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                (setValueToFormControl)="setValueToFormControl($event)"
                                [noMargin]="true"
                            >
                            </app-airport-picker>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            getTripField(i, 'airportArrivalId').touched) &&
                                        getTripField(i, 'airportArrivalId').errors &&
                                        getTripField(i, 'airportArrivalId').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label [for]="'trip-' + i + '-flightNumber'">Numéro de vol</label>
                            <input
                                [id]="'trip-' + i + '-flightNumber'"
                                type="text"
                                class="form-control"
                                formControlName="flightNumber"
                                [class.is-invalid]="
                                    (form.touched || getTripField(i, 'flightNumber').touched) &&
                                    getTripField(i, 'flightNumber').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || getTripField(i, 'flightNumber').touched) &&
                                        getTripField(i, 'flightNumber').errors &&
                                        getTripField(i, 'flightNumber').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div
                            class="form-group"
                            [class.mb-0]="enquiry?.type !== EnumEnquiryType.cargo"
                        >
                            <label
                                [for]="'trip-' + i + '-departureTime'"
                                [innerText]="
                                    enquiry?.type === EnumEnquiryType.cargo
                                        ? 'Heure Départ'
                                        : 'Heure Départ locale'
                                "
                            >
                            </label>
                            <div class="input-group">
                                <input
                                    [id]="'trip-' + i + '-departureTime'"
                                    type="time"
                                    class="form-control"
                                    formControlName="departureTime"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            getTripField(i, 'departureTime').touched) &&
                                        getTripField(i, 'departureTime').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (keyup)="setEstimatedArrivalTimeIfNotProvidedForTrip(i, true)"
                                    (change)="setEstimatedArrivalTimeIfNotProvidedForTrip(i, true)"
                                />
                                <span
                                    class="input-group-text"
                                    *ngIf="enquiry?.type === EnumEnquiryType.cargo"
                                    >GMT/UTC</span
                                >
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getTripField(i, 'departureTime').touched) &&
                                            getTripField(i, 'departureTime').errors &&
                                            getTripField(i, 'departureTime').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="
                                enquiry?.type !== EnumEnquiryType.cargo &&
                                getTrip(i).value.airportDepartId &&
                                airportsObj[getTrip(i).value.airportDepartId] &&
                                getTrip(i).value.departureTime
                            "
                            class="time-utc"
                        >
                            {{
                                getAirportUTCTimeString(
                                    airportsObj[getTrip(i).value.airportDepartId],
                                    getTrip(i).value.date,
                                    getTrip(i).value.departureTime
                                )
                            }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div
                            class="form-group"
                            [class.mb-0]="enquiry?.type !== EnumEnquiryType.cargo"
                        >
                            <label
                                [for]="'trip-' + i + '-arrivalTime'"
                                [innerText]="
                                    enquiry?.type === EnumEnquiryType.cargo
                                        ? 'Heure Arrivée'
                                        : 'Heure Arrivée locale'
                                "
                            >
                            </label>
                            <div class="input-group">
                                <input
                                    [id]="'trip-' + i + '-arrivalTime'"
                                    type="time"
                                    class="form-control"
                                    formControlName="arrivalTime"
                                    [class.is-invalid]="
                                        (form.touched || getTripField(i, 'arrivalTime').touched) &&
                                        getTripField(i, 'arrivalTime').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (keyup)="tripTimesUpdated(i)"
                                    (change)="tripTimesUpdated(i)"
                                />
                                <span
                                    class="input-group-text"
                                    *ngIf="enquiry?.type === EnumEnquiryType.cargo"
                                    >GMT/UTC</span
                                >
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getTripField(i, 'arrivalTime').touched) &&
                                            getTripField(i, 'arrivalTime').errors &&
                                            getTripField(i, 'arrivalTime').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="
                                enquiry?.type !== EnumEnquiryType.cargo &&
                                getTrip(i).value.airportArrivalId &&
                                airportsObj[getTrip(i).value.airportArrivalId] &&
                                getTrip(i).value.arrivalTime
                            "
                            class="time-utc"
                        >
                            {{
                                getAirportUTCTimeString(
                                    airportsObj[getTrip(i).value.airportArrivalId],
                                    getTrip(i).value.date,
                                    getTrip(i).value.arrivalTime
                                )
                            }}
                        </div>

                        <div class="arrival-estimated" *ngIf="getEstimatedArrivalTimeForTrip(i)">
                            {{
                                enquiry?.type === EnumEnquiryType.cargo
                                    ? "Heure UTC d'arrivée estimée pour"
                                    : "Heure locale d'arrivée estimée pour"
                            }}
                            {{ form.value.aircraftModelTitle }} :
                            {{ getEstimatedArrivalTimeForTrip(i) }}
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label [for]="'trip-' + i + '-flyTimeInHours'">Temps de vol</label>
                            <input
                                [id]="'trip-' + i + '-flyTimeInHours'"
                                type="time"
                                class="form-control"
                                formControlName="flyTimeInHours"
                                [class.is-invalid]="
                                    (form.touched || getTripField(i, 'flyTimeInHours').touched) &&
                                    getTripField(i, 'flyTimeInHours').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            getTripField(i, 'flyTimeInHours').touched) &&
                                        getTripField(i, 'flyTimeInHours').errors &&
                                        getTripField(i, 'flyTimeInHours').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group" *ngIf="enquiry?.type !== EnumEnquiryType.cargo">
                            <label [for]="'trip-' + i + '-boardingMinutesBeforeDeparture'"
                                >Limite convocation passager</label
                            >
                            <div class="input-group">
                                <input
                                    [id]="'trip-' + i + '-boardingMinutesBeforeDeparture'"
                                    type="number"
                                    min="0"
                                    class="form-control"
                                    formControlName="boardingMinutesBeforeDeparture"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            getTripField(i, 'boardingMinutesBeforeDeparture')
                                                .touched) &&
                                        getTripField(i, 'boardingMinutesBeforeDeparture').status ==
                                            'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="tripTimesUpdated(i)"
                                />
                                <span class="input-group-text">mins</span>
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getTripField(i, 'boardingMinutesBeforeDeparture')
                                                    .touched) &&
                                            getTripField(i, 'boardingMinutesBeforeDeparture')
                                                .errors &&
                                            getTripField(i, 'boardingMinutesBeforeDeparture')
                                                .errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="form-group"
                            *ngIf="
                                enquiry?.type === EnumEnquiryType.cargo &&
                                !getTripField(i, 'isFerryFlight').value
                            "
                        >
                            <label [for]="'trip-' + i + '-deliveryCargoHoursBeforeDeparture'"
                                >Limite livraison cargo</label
                            >
                            <div class="input-group">
                                <input
                                    [id]="'trip-' + i + '-deliveryCargoHoursBeforeDeparture'"
                                    type="number"
                                    min="0"
                                    class="form-control"
                                    formControlName="deliveryCargoHoursBeforeDeparture"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            getTripField(i, 'deliveryCargoHoursBeforeDeparture')
                                                .touched) &&
                                        getTripField(i, 'deliveryCargoHoursBeforeDeparture')
                                            .status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="tripTimesUpdated(i)"
                                />
                                <span class="input-group-text">heures</span>
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getTripField(i, 'deliveryCargoHoursBeforeDeparture')
                                                    .touched) &&
                                            getTripField(i, 'deliveryCargoHoursBeforeDeparture')
                                                .errors &&
                                            getTripField(i, 'deliveryCargoHoursBeforeDeparture')
                                                .errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-center">
                    <button
                        type="button"
                        (click)="removeTrip(i)"
                        class="btn btn-danger btn-sm"
                        *ngIf="trips.length > 1"
                    >
                        Supprimer itinéraire
                    </button>
                </div>
            </li>
        </ul>
        <div class="card-footer text-center">
            <button type="button" class="btn btn-outline-primary" (click)="addTrip()">
                + Ajouter
            </button>
        </div>
    </div>

    <div class="card mb-4" *ngIf="enquiry?.type === EnumEnquiryType.cargo">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="header-cells mb-2">Informations du cargo</div>

                    <div class="form-group">
                        <label for="typeOfCargo">Type of cargo</label>
                        <input
                            id="typeOfCargo"
                            type="text"
                            class="form-control"
                            formControlName="typeOfCargo"
                            [class.is-invalid]="
                                (form.touched || typeOfCargo.touched) &&
                                typeOfCargo.status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />
                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || typeOfCargo.touched) &&
                                    typeOfCargo.errors &&
                                    typeOfCargo.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="weightInKg">Poids</label>
                        <div class="input-group">
                            <input
                                id="weightInKg"
                                type="number"
                                min="0"
                                class="form-control"
                                formControlName="weightInKg"
                                [class.is-invalid]="
                                    (form.touched || weightInKg.touched) &&
                                    weightInKg.status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <span class="input-group-text">Kgs</span>
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || weightInKg.touched) &&
                                        weightInKg.errors &&
                                        weightInKg.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="volumeInM3">Volume</label>
                        <div class="input-group">
                            <input
                                id="volumeInM3"
                                type="number"
                                min="0"
                                class="form-control"
                                formControlName="volumeInM3"
                                [class.is-invalid]="
                                    (form.touched || volumeInM3.touched) &&
                                    volumeInM3.status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <span class="input-group-text">m<sup>3</sup></span>
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || volumeInM3.touched) &&
                                        volumeInM3.errors &&
                                        volumeInM3.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="nbPackages">Nombre de paquets</label>
                        <div class="input-group">
                            <input
                                id="nbPackages"
                                type="number"
                                min="0"
                                class="form-control"
                                formControlName="nbPackages"
                                [class.is-invalid]="
                                    (form.touched || nbPackages.touched) &&
                                    nbPackages.status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <span class="input-group-text">pièces</span>
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || nbPackages.touched) &&
                                        nbPackages.errors &&
                                        nbPackages.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="awbNumber">Numéro(s) AWB</label>
                        <input
                            id="awbNumber"
                            type="text"
                            class="form-control"
                            formControlName="awbNumber"
                            [class.is-invalid]="
                                (form.touched || awbNumber.touched) && awbNumber.status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />
                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || awbNumber.touched) &&
                                    awbNumber.errors &&
                                    awbNumber.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="customDeclaration">Déclaration douane</label>
                        <input
                            id="customDeclaration"
                            type="text"
                            class="form-control"
                            formControlName="customDeclaration"
                            [class.is-invalid]="
                                (form.touched || customDeclaration.touched) &&
                                customDeclaration.status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />
                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || customDeclaration.touched) &&
                                    customDeclaration.errors &&
                                    customDeclaration.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            formControlName="dgd"
                            id="dgd"
                        />
                        <label class="form-check-label" for="dgd">DGD</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div formArrayName="trips" *ngIf="enquiry?.type !== EnumEnquiryType.cargo">
        <fieldset *ngFor="let trip of trips.controls; let i = index" [formGroupName]="i">
            <legend class="text-center">
                {{ airportsObj[trip.value.airportDepartId]?.title }},
                {{ airportsObj[trip.value.airportDepartId]?.iataCode }}
                <fa-icon [icon]="faPlane"></fa-icon>
                {{ airportsObj[trip.value.airportArrivalId]?.title }},
                {{ airportsObj[trip.value.airportArrivalId]?.iataCode }}
            </legend>
            <div class="row">
                <div class="col-md-8">
                    <div class="card mb-4">
                        <div class="card-header">
                            <div
                                class="card-title mb-0 text-center"
                                [innerText]="getPassengers(i).length > 1 ? 'Passagers' : 'Passager'"
                            ></div>
                        </div>
                        <ul class="list-group list-group-flush" formArrayName="passengers">
                            <li
                                class="list-group-item"
                                *ngFor="let passenger of getPassengers(i).controls; let j = index"
                                [formGroupName]="j"
                            >
                                <div class="row justify-content-center">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label [for]="'passenger-' + i + '-' + j + '-civility'"
                                                >Civilité</label
                                            >
                                            <select
                                                class="form-select"
                                                [id]="'passenger-' + i + '-' + j + '-civility'"
                                                formControlName="civility"
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        getPassenger(i, j).get('civility')
                                                            .touched) &&
                                                    getPassenger(i, j).get('civility').status ==
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            >
                                                <option
                                                    [value]="civility"
                                                    *ngFor="let civility of getCivilities()"
                                                    [innerHtml]="
                                                        getFlightBriefPassengerCivilityLabel(
                                                            civility,
                                                            form.value.language
                                                        )
                                                    "
                                                ></option>
                                            </select>

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getPassenger(i, j).get('civility')
                                                                .touched) &&
                                                        getPassenger(i, j).get('civility').errors &&
                                                        getPassenger(i, j).get('civility').errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label [for]="'passenger-' + i + '-' + j + '-firstname'"
                                                >Prénom</label
                                            >
                                            <input
                                                [id]="'passenger-' + i + '-' + j + '-firstname'"
                                                type="text"
                                                class="form-control"
                                                formControlName="firstname"
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        getPassenger(i, j).get('firstname')
                                                            .touched) &&
                                                    getPassenger(i, j).get('firstname').status ==
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />
                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getPassenger(i, j).get('firstname')
                                                                .touched) &&
                                                        getPassenger(i, j).get('firstname')
                                                            .errors &&
                                                        getPassenger(i, j).get('firstname').errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label [for]="'passenger-' + i + '-' + j + '-lastname'"
                                                >Nom</label
                                            >
                                            <input
                                                [id]="'passenger-' + i + '-' + j + '-lastname'"
                                                type="text"
                                                class="form-control"
                                                formControlName="lastname"
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        getPassenger(i, j).get('lastname')
                                                            .touched) &&
                                                    getPassenger(i, j).get('lastname').status ==
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />
                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            getPassenger(i, j).get('lastname')
                                                                .touched) &&
                                                        getPassenger(i, j).get('lastname').errors &&
                                                        getPassenger(i, j).get('lastname').errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-md-2 d-flex align-self-center justify-content-end"
                                    >
                                        <button
                                            type="button"
                                            (click)="removePassenger(i, j)"
                                            class="btn btn-danger"
                                        >
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="card-footer text-center">
                            <button
                                type="button"
                                class="btn btn-outline-primary"
                                (click)="addPassenger(i)"
                            >
                                + Ajouter
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card mb-4">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="flightBriefCateringId">Catering</label>
                                <select
                                    class="form-select"
                                    id="flightBriefCateringId"
                                    formControlName="flightBriefCateringId"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            getTripField(i, 'flightBriefCateringId').touched) &&
                                        getTripField(i, 'flightBriefCateringId').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="updatedFlightBriefCateringId(i)"
                                >
                                    <option [ngValue]="null">Choisissez</option>
                                    <option
                                        [value]="flightBriefCatering.text"
                                        *ngFor="let flightBriefCatering of flightBriefCaterings"
                                        [innerHtml]="flightBriefCatering.text"
                                    ></option>
                                    <option value="custom">Catering personnalisé</option>
                                </select>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getTripField(i, 'flightBriefCateringId').touched) &&
                                            getTripField(i, 'flightBriefCateringId').errors &&
                                            getTripField(i, 'flightBriefCateringId').errors[
                                                'required'
                                            ]
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>

                                <textarea
                                    class="form-control"
                                    rows="2"
                                    id="catering"
                                    formControlName="catering"
                                    [class.is-invalid]="
                                        (form.touched || getTripField(i, 'catering').touched) &&
                                        getTripField(i, 'catering').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    placeholder="Saisissez le catering"
                                    *ngIf="getTrip(i).value.flightBriefCateringId === 'custom'"
                                ></textarea>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || getTripField(i, 'catering').touched) &&
                                            getTripField(i, 'catering').errors &&
                                            getTripField(i, 'catering').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <label for="luggages">Bagages par passager</label>

                                <div class="form-check form-switch">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        formControlName="luggageIsCustom"
                                        (change)="updateLuggageIsCustom(i)"
                                        [id]="'trip-' + i + '-luggageIsCustom'"
                                    />
                                    <label
                                        class="form-check-label"
                                        [for]="'trip-' + i + '-luggageIsCustom'"
                                        >Personnalisé</label
                                    >
                                </div>

                                <ul class="list-group" *ngIf="!getTrip(i)?.value.luggageIsCustom">
                                    <li class="list-group-item">
                                        <label>Nombre de bagage</label>
                                        <div class="selectors-increment-number">
                                            <button
                                                type="button"
                                                class="btn btn-primary btn-sm"
                                                [disabled]="getTrip(i)?.value.luggageQty <= 0"
                                                (click)="updateLuggageQty(i, -1)"
                                            >
                                                -
                                            </button>
                                            <div
                                                class="selector-number-value"
                                                [innerText]="getTrip(i)?.value.luggageQty"
                                            ></div>
                                            <button
                                                type="button"
                                                class="btn btn-primary btn-sm"
                                                (click)="updateLuggageQty(i, 1)"
                                            >
                                                +
                                            </button>
                                        </div>
                                    </li>

                                    <li class="list-group-item">
                                        <label>Poids par bagage</label>

                                        <div class="selectors-increment-number">
                                            <button
                                                type="button"
                                                class="btn btn-primary btn-sm"
                                                [disabled]="getTrip(i)?.value.luggagesWeight <= 0"
                                                (click)="updateLuggageWeight(i, -1)"
                                            >
                                                -
                                            </button>
                                            <div
                                                class="selector-number-value"
                                                [innerText]="
                                                    getTrip(i)?.value.luggagesWeight > 1
                                                        ? getTrip(i)?.value.luggagesWeight + ' kgs'
                                                        : getTrip(i)?.value.luggagesWeight + ' kg'
                                                "
                                            ></div>
                                            <button
                                                type="button"
                                                class="btn btn-primary btn-sm"
                                                (click)="updateLuggageWeight(i, 1)"
                                            >
                                                +
                                            </button>
                                        </div>
                                    </li>
                                </ul>

                                <ng-container *ngIf="getTrip(i)?.value.luggageIsCustom">
                                    <textarea
                                        class="form-control"
                                        rows="1"
                                        id="luggages"
                                        formControlName="luggages"
                                        [class.is-invalid]="
                                            (form.touched || getTripField(i, 'luggages').touched) &&
                                            getTripField(i, 'luggages').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    ></textarea>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    getTripField(i, 'luggages').touched) &&
                                                getTripField(i, 'luggages').errors &&
                                                getTripField(i, 'luggages').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="card mb-4">
                <div class="card-header text-center">FBOs</div>
                <div class="card-body" formArrayName="airportsAddressDetails">
                    <div
                        class="form-group"
                        *ngFor="
                            let airportAddressDetails of airportsAddressDetails.controls;
                            let i = index
                        "
                        [formGroupName]="i"
                    >
                        <div class="text-center">
                            <label
                                [innerText]="
                                    getAirportCity(
                                        airportsObj[airportAddressDetails.value.airportId]
                                    )
                                "
                            >
                            </label>
                        </div>
                        <ul
                            class="list-group mb-2"
                            *ngIf="!fbosByAirportsObj[airportAddressDetails.value.airportId]"
                        >
                            <li class="list-group-item" *ngFor="let number of [].constructor(4)">
                                <div class="skeleton-text mb-0"></div>
                            </li>
                        </ul>

                        <ul
                            class="list-group mb-2"
                            *ngIf="fbosByAirportsObj[airportAddressDetails.value.airportId]"
                        >
                            <li
                                class="list-group-item d-flex justify-content-space-between align-items-center gap-2"
                                [class.list-group-item-primary]="
                                    airportAddressDetails.value.fboId === fbo.id
                                "
                                *ngFor="
                                    let fbo of fbosByAirportsObj[
                                        airportAddressDetails.value.airportId
                                    ]
                                "
                            >
                                <button
                                    type="button"
                                    class="btn btn-icon"
                                    (click)="toggleFbo(i, fbo.id)"
                                >
                                    <fa-icon
                                        [icon]="
                                            airportAddressDetails.value.fboId === fbo.id
                                                ? faCircleCheck
                                                : faCircle
                                        "
                                        [class.text-primary]="
                                            airportAddressDetails.value.fboId === fbo.id
                                        "
                                    ></fa-icon>
                                </button>
                                <label
                                    [for]="'fbo-' + fbo.id"
                                    class="w-100"
                                    [innerHTML]="getFboAddressDetails(fbo, form.value.language)"
                                >
                                </label>
                                <button
                                    type="button"
                                    class="btn btn-icon"
                                    (click)="editFbo(i, fbo)"
                                    *ngIf="hasAclAccess(EnumAcl.fbosEdit)"
                                >
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                </button>
                            </li>
                            <li
                                class="list-group-item text-center"
                                *ngIf="
                                    hasAclAccess(EnumAcl.fbosAdd) &&
                                    airportAddressDetails.value.airportId
                                "
                            >
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    (click)="addFbo(i)"
                                >
                                    + Nouveau FBO
                                </button>
                            </li>
                        </ul>

                        <div
                            *ngIf="
                                getAirportAddressDetailsField(i, 'fboId').errors &&
                                getAirportAddressDetailsField(i, 'fboId').errors['required']
                            "
                            class="text-center text-danger"
                        >
                            La sélection du FBO est obligatoire.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="header-cells">Route</div>
                    <img
                        [src]="form.value.googleMapStaticUrl"
                        *ngIf="form.value.googleMapStaticUrl"
                        class="google-map-img"
                    />
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-4" *ngIf="enquiry?.type !== EnumEnquiryType.cargo">
        <div class="card-header text-center">Équipage</div>
        <div class="card-body">
            <div class="row justify-content-center">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="pilot">Pilote</label>
                        <textarea
                            class="form-control"
                            id="pilot"
                            formControlName="pilot"
                            [class.is-invalid]="
                                (form.touched || form.get('pilot').touched) &&
                                form.get('pilot').status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            rows="2"
                        ></textarea>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.get('pilot').touched) &&
                                    form.get('pilot').errors &&
                                    form.get('pilot').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="coPilot">Co-pilote</label>
                        <textarea
                            class="form-control"
                            id="coPilot"
                            formControlName="coPilot"
                            [class.is-invalid]="
                                (form.touched || form.get('coPilot').touched) &&
                                form.get('coPilot').status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            rows="2"
                        ></textarea>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.get('coPilot').touched) &&
                                    form.get('coPilot').errors &&
                                    form.get('coPilot').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="cabinCrew">Personnel de bord</label>
                        <textarea
                            class="form-control"
                            id="cabinCrew"
                            formControlName="cabinCrew"
                            [class.is-invalid]="
                                (form.touched || form.get('cabinCrew').touched) &&
                                form.get('cabinCrew').status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            rows="2"
                        ></textarea>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.get('cabinCrew').touched) &&
                                    form.get('cabinCrew').errors &&
                                    form.get('cabinCrew').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-4" *ngIf="form.value.aircraftId">
        <div class="col-md-6" *ngFor="let imageField of ['imageOutside', 'imageInside']">
            <div *ngIf="imageField === 'imageOutside'" class="text-center">Photo extérieur</div>
            <div *ngIf="imageField === 'imageInside'" class="text-center">Photo intérieur</div>
            <div class="image-upload-container">
                <div
                    class="image-upload-content"
                    [class.no-image]="!form.value[imageField + 'Url']"
                    (click)="triggerInputFile(imageField + 'Url')"
                >
                    <div class="image-container" *ngIf="form.value[imageField + 'Url']">
                        <div
                            class="image"
                            [id]="imageField"
                            [style.background-image]="'url(' + form.value[imageField + 'Url'] + ')'"
                            [style.webkitTransform]="
                                'scale(' + form.value[imageField + 'ZoomLevel'] + ')'
                            "
                            [style.transform]="
                                'scale(' + form.value[imageField + 'ZoomLevel'] + ')'
                            "
                        ></div>
                    </div>
                    <div class="image-upload-legend">
                        <span *ngIf="!form.disabled">
                            {{
                                form.value[imageField + "Url"]
                                    ? "Éditer l'image"
                                    : "Ajouter une image"
                            }}
                        </span>
                        <span *ngIf="form.disabled">...</span>
                    </div>
                    <input
                        type="file"
                        [id]="imageField + 'Url' + 'Input'"
                        class="form-control"
                        (change)="changeInputFile(imageField + 'Url', $event)"
                        accept="image/*"
                    />
                </div>
                <div class="form-group form-group-range" *ngIf="form.value[imageField + 'Url']">
                    <label>Zoom</label>
                    <input
                        type="range"
                        class="form-control-range"
                        [formControlName]="imageField + 'ZoomLevel'"
                        min="0.5"
                        max="4"
                        value="1"
                        step="0.1"
                    />
                </div>
                <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    (click)="deleteImage(imageField + 'Url')"
                    [disabled]="form.disabled"
                    *ngIf="form.value[imageField + 'Url']"
                >
                    Supprimer
                </button>
            </div>
        </div>
    </div>

    <div class="form-group">
        <label for="comments">Commentaires (sera affichés sur le flight brief)</label>
        <textarea
            formControlName="comments"
            class="form-control"
            rows="5"
            [class.is-invalid]="
                (form.touched || form.get('comments').touched) &&
                form.get('comments').status == 'INVALID'
                    ? 'danger'
                    : ''
            "
        ></textarea>

        <div class="invalid-tooltip">
            <div
                *ngIf="
                    (form.touched || form.get('comments').touched) &&
                    form.get('comments').errors &&
                    form.get('comments').errors['required']
                "
            >
                Ce champ est obligatoire.
            </div>
        </div>
    </div>

    <div class="form-group">
        <label for="internalNote">Notes internes (ne sera pas affichés sur le flight brief)</label>
        <textarea
            formControlName="internalNote"
            class="form-control"
            rows="5"
            [class.is-invalid]="
                (form.touched || form.get('internalNote').touched) &&
                form.get('internalNote').status == 'INVALID'
                    ? 'danger'
                    : ''
            "
        ></textarea>

        <div class="invalid-tooltip">
            <div
                *ngIf="
                    (form.touched || form.get('internalNote').touched) &&
                    form.get('internalNote').errors &&
                    form.get('internalNote').errors['required']
                "
            >
                Ce champ est obligatoire.
            </div>
        </div>
    </div>

    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="
                    flightBriefId
                        ? '/admin/flight-briefs/view/' + flightBriefId
                        : enquiryId
                        ? '/admin/flight-briefs/list/enquiry/' + enquiryId
                        : '/admin/flight-briefs/list'
                "
                class="btn btn-outline-primary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde en cours ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>

<form (submit)="saveAircraft()" [formGroup]="formAircraft" (keydown.enter)="saveAircraft()">
    <div class="modal" tabindex="-1" role="dialog" id="modal-aircraft-add" #modalAircraftAdd>
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Ajout d'un nouveau appareil</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="serialNumber">MSN (Numéro de série)</label>
                        <input
                            class="form-control"
                            id="serialNumber"
                            formControlName="serialNumber"
                            type="text"
                            [class.is-invalid]="
                                (formAircraft.touched ||
                                    formAircraft.controls['serialNumber'].touched) &&
                                formAircraft.controls['serialNumber'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formAircraft.touched ||
                                        formAircraft.controls['serialNumber'].touched) &&
                                    formAircraft.controls['serialNumber'].errors &&
                                    formAircraft.controls['serialNumber'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="status">Statut</label>
                        <select
                            class="form-select"
                            id="status"
                            formControlName="status"
                            required
                            [class.is-invalid]="
                                (formAircraft.touched || formAircraft.controls['status'].touched) &&
                                formAircraft.controls['status'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="status"
                                *ngFor="let status of getAircraftStatuses()"
                                [innerText]="getAircraftStatusLabel(status)"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formAircraft.touched ||
                                        formAircraft.controls['status'].touched) &&
                                    formAircraft.controls['status'].errors &&
                                    formAircraft.controls['status'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="registration">Immatriculation</label>
                        <input
                            class="form-control"
                            id="registration"
                            formControlName="registration"
                            type="text"
                            [class.is-invalid]="
                                (formAircraft.touched ||
                                    formAircraft.controls['registration'].touched) &&
                                formAircraft.controls['registration'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formAircraft.touched ||
                                        formAircraft.controls['registration'].touched) &&
                                    formAircraft.controls['registration'].errors &&
                                    formAircraft.controls['registration'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Annuler
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        (click)="saveAircraft()"
                        [disabled]="formAircraft.disabled || !formAircraft.valid || savingAircraft"
                        [innerText]="savingAircraft ? 'Sauvegarde ...' : 'Sauvegarder'"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="modal" tabindex="-1" role="dialog" #modalFbo>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    [innerText]="editingFbo ? 'Édition d\'un FBO' : 'Ajout d\'un FBO'"
                ></h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-fbo-edit
                [inModal]="true"
                [defaultAirportId]="
                    getAirportAddressDetailsField(airportAddressDetailsCurrentIndex, 'airportId')
                        .value
                "
                [fboId]="editingFbo?.id || null"
                *ngIf="airportAddressDetailsCurrentIndex !== null"
                (dismissModal)="dismissFboModal($event)"
            ></app-fbo-edit>
        </div>
    </div>
</div>
