import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { HttpsCallableOptions } from '@firebase/functions-types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {
  constructor(private functions: AngularFireFunctions) {}

  callFunction(fn: string, data?: any, options?: HttpsCallableOptions): Observable<any> {
    const callable = this.functions.httpsCallable(fn, options);
    return callable(data);
  }

  callFunctionAsPromise(fn: string, data?: any, options?: HttpsCallableOptions): Promise<any> {
    return this.callFunction(fn, data, options).toPromise();
  }
}
