import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { IFinancialGoal } from 'src/app/interfaces/financial-goal.interface';

@Injectable({
  providedIn: 'root'
})
export class FinancialGoalsFirestoreService extends FirestoreService<IFinancialGoal> {
  protected basePath = 'financialGoals';
}
