import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  AbstractControl
} from '@angular/forms';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { PipedriveService } from '../../../services/pipedrive.service';
import { AclService } from '../../../services/acl.service';

import moment from 'moment';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import {
  getDisplayedEnquiryRefTitle,
  getEnquiryBreadcrumbTitle,
  getEnquiryRefTitle,
  getEnquiryRefTitleWithoutPrefix,
  IEnquiry
} from 'src/app/interfaces/enquiry.interface';
import { Subscription } from 'rxjs';
import { IEnquiryOffer } from 'src/app/interfaces/enquiry-offer.interface';
import { EnumEnquiryOfferStatus } from 'src/app/enums/enquiry-offer-status.enum';
import {
  EnumEnquiryType,
  getEnumEnquiryPrefix,
  getEnumEnquiryTypeLabel
} from 'src/app/enums/enquiry-type.enum';
import { EnumEnquiryStatus, getEnumEnquiryStatusLabel } from 'src/app/enums/enquiry-status.enum';
import { EnquiryOfferService } from 'src/app/services/enquiry-offer/enquiry-offer.service';
import { EnquiryCotationService } from 'src/app/services/enquiry-cotation/enquiry-cotation.service';
import { IEnquiryCotation } from 'src/app/interfaces/enquiry-cotation.interface';
import {
  EnumEnquiryCotationStatus,
  getEnumEnquiryCotationStatusLabel
} from 'src/app/enums/enquiry-cotation-status.enum';
import {
  EnumCurrency,
  getDefaultCurrency,
  getEnumCurrencyLabel,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import {
  EnumEnquiryCancelledReason,
  getEnumEnquiryCancelledReasonLabel
} from 'src/app/enums/enquiry-cancelled-reason.enum';
import { format, getMonth, getYear } from 'date-fns';
import {
  getInvoiceTitle,
  IInvoice,
  invoiceDiffDueDate,
  invoiceGetAmountSoldeLeft,
  invoiceGetAmountSoldePaid
} from 'src/app/interfaces/invoice.interface';
import { InvoiceService } from 'src/app/services/invoices/invoices.service';
import { getQuotationStatusLabel, IQuotation } from 'src/app/interfaces/quotation.interface';
import { QuotationService } from 'src/app/services/quotations/quotations.service';
import { getLanguageLabel } from 'src/app/enums/language.enum';
import { EnumInvoiceType, getEnumInvoiceTypeLabel } from 'src/app/enums/invoice-type.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUser } from 'src/app/interfaces/user.interface';
import { IFlightBrief } from 'src/app/interfaces/flight-brief.interface';
import { FlightBriefService } from 'src/app/services/flight-briefs/flight-briefs.service';
import { addZeroToDigit } from 'src/app/misc.utils';
import { CurrencyExchangeRateService } from 'src/app/services/currency-exchange-rates/currency-exchange-rates.service';
import { ICurrencyExchangeRate } from 'src/app/interfaces/currency-exchange-rate.interface';
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { IPipedriveOrganization, IPipedrivePerson } from 'src/app/interfaces/pipedrive.interface';
import { getCountryLabel } from 'src/app/enums/continent-code.enum';
import { IAircraftCompiled } from 'src/app/interfaces/aircraft-compiled.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import { EnquiryFlightService } from 'src/app/services/enquiry-flights/enquiry-flights.service';

interface CotationLine {
  enquiryId: string;
  offerId: string;
  cotationId: string;
  status: EnumEnquiryCotationStatus | EnumEnquiryOfferStatus;
  statusTitle: string;
  followedUp: boolean;
  airlineId: string;
  airlineTitle: string;
  airlineCountryCode: string;
  airlineCountryTitle: string;
  itineraryId: string;
  itineraryTitle: string;
  legsId: string[];
  isNationalFlight: boolean;
  legsAirportsCode: string[];
  legsTitle: string;
  aircraftCompiledId: string;
  aircraftCompiledModel: string;
  aircraftTitle: string;
  ageAverage: number;
  aircraftErrorMessages: string[];
  capacityAircraft: number;
  weightAircraft: number;
  volumeAircraft: number;
  nbPax: number;
  currency: string;
  buyingPrice: number;
  buyingPriceInCurrency: number;
  taxesTotalPax: number;
  taxesTotalPaxInCurrency: number;
  catering: number;
  cateringInCurrency: number;
  priceNetTTC: number;
  priceNetTTCInCurrency: number;
  pricePP: number;
  pricePPInCurrency: number;
  sellingPrice: number;
  sellingPriceInCurrency: number;
  margin: number;
  marginInCurrency: number;
  marginPercent: number;
  marginPercentInCurrency: number;
  marginOnConfirmed: number | null;
  sellingPricePP: number;
  sellingPricePPInCurrency: number;
  notes: string;
}

@Component({
  selector: 'app-enquiry-view',
  templateUrl: './enquiry-view.component.html',
  styleUrls: ['./enquiry-view.component.scss']
})
export class EnquiryViewComponent implements OnInit, OnDestroy {
  @ViewChild('modalCancelled', { static: false }) modalCancelledElement: ElementRef;
  @ViewChild('modalConfirmed', { static: false }) modalConfirmedElement: ElementRef;
  @ViewChild('modalCotationEdit', { static: false }) modalCotationEditElement: ElementRef;

  rootUrl: string = '../';

  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;
  getEnumEnquiryStatusLabel = getEnumEnquiryStatusLabel;
  getEnquiryBreadcrumbTitle = getEnquiryBreadcrumbTitle;
  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getEnumCurrencyLabel = getEnumCurrencyLabel;
  getEnumEnquiryCotationStatusLabel = getEnumEnquiryCotationStatusLabel;
  getEnumEnquiryCancelledReasonLabel = getEnumEnquiryCancelledReasonLabel;
  getDisplayedEnquiryRefTitle = getDisplayedEnquiryRefTitle;
  getEnumInvoiceTypeLabel = getEnumInvoiceTypeLabel;

  EnumEnquiryType = EnumEnquiryType;
  EnumEnquiryStatus = EnumEnquiryStatus;
  EnumEnquiryCotationStatus = EnumEnquiryCotationStatus;
  EnumEnquiryCancelledReason = EnumEnquiryCancelledReason;

  statusLabelObj: {
    [key: string]: {
      title: string;
      position: number;
    };
  } = {
    'waiting-for-answer': {
      title: 'En attente',
      position: 3
    },
    answered: {
      title: 'Répondu',
      position: 1
    },
    rejected: {
      title: 'Rejetée',
      position: 4
    },
    'to-contact': {
      title: 'Compagnie à contacter',
      position: 2
    },
    confirmed: {
      title: 'Confirmée',
      position: 0
    },
    cancelled: {
      title: 'Annulée',
      position: 4
    }
  };
  statusLabelList: {
    title: string;
    value: string;
  }[] = [];

  EnumAcl = EnumAcl;

  defaultCurrency = EnumCurrency.EUR;

  loading: boolean = true;
  creatingPipedriveDeal: boolean = false;
  enquiryId: string;
  enquiry: IEnquiry;
  enquiryOffers: IEnquiryOffer[] = [];
  enquiryCotations: IEnquiryCotation[] = [];
  enquiryCotationsByOffers: {
    [key: string]: {
      offer: IEnquiryOffer;
      cotations: IEnquiryCotation[];
    };
  } = {};
  client: IPipedriveOrganization | null = null;
  contact: IPipedrivePerson | null = null;
  isLogged: boolean = false;
  aircraftsCompiledObj: { [key: string]: IAircraftCompiled | null } = {};
  airlinesObj: { [key: string]: IAirline | null } = {};
  form: FormGroup;
  formStatusCancelled: FormGroup;
  saving: boolean = false;
  itinerariesList: {
    title: string;
    value: string;
  }[] = [];
  itinerariesTitleObj: { [key: string]: string } = {};
  legsList: object = {};
  legsObj: object = {};
  cotationsList: {
    title: string;
    cotationId: string;
  }[] = [];
  aircraftModelsObj: { [key: string]: IAircraftModel | null } = {};
  fields: {
    title: string;
    code: string;
  }[] = [];
  orderBy: {
    field: string;
    direction: 'asc' | 'desc';
  } = {
    field: 'status',
    direction: 'asc'
  };
  lines: CotationLine[] = [];
  user: IUser;
  editingOffer: IEnquiryOffer;
  editingCotation: IEnquiryCotation;
  airportsObj: { [key: string]: IAirport | null } = {};
  airportsToLoad: string[] = [];
  airlinesAircrafts: object = {};
  selectedConfirmedCotationsId: string[] = [];

  lastEnquiry: IEnquiry;

  quotations: IQuotation[] = [];
  invoices: IInvoice[] = [];
  creditNotes: IInvoice[] = [];
  proformas: IInvoice[] = [];
  flightBriefs: IFlightBrief[] = [];

  bankAccountsObj: { [bankAccountId: string]: IBankAccount } = {};

  invoiceGetAmountSoldePaid = invoiceGetAmountSoldePaid;
  invoiceGetAmountSoldeLeft = invoiceGetAmountSoldeLeft;
  invoiceDiffDueDate = invoiceDiffDueDate;
  getInvoiceTitle = getInvoiceTitle;

  getQuotationStatusLabel = getQuotationStatusLabel;
  getLanguageLabel = getLanguageLabel;
  getDefaultCurrency = getDefaultCurrency;
  getCountryLabel = getCountryLabel;

  todayCurrencyExchangeRate: ICurrencyExchangeRate | null = null;
  faArrowRightArrowLeft = faArrowRightArrowLeft;

  enquiryFlights: IEnquiryFlight[] = [];
  loadingEnquiryFlights: boolean = false;

  subscriptions = new Subscription();
  private subscriptionsFlights = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private pipedriveService: PipedriveService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private formBuilder: FormBuilder,
    private aclService: AclService,
    private enquiryService: EnquiryService,
    private enquiryOfferService: EnquiryOfferService,
    private enquiryCotationService: EnquiryCotationService,
    private invoiceService: InvoiceService,
    private quotationService: QuotationService,
    private flightBriefService: FlightBriefService,
    private currencyExchangeRateService: CurrencyExchangeRateService,
    private loaderService: LoaderService,
    private enquiryFlightService: EnquiryFlightService
  ) {
    this.rootUrl = window['rootUrl'];

    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.userObservable.subscribe((user: IUser) => (this.user = user));

    this.remoteService.aircraftModelsObservable.subscribe((aircraftModels: IAircraftModel[]) => {
      if (aircraftModels) {
        for (const aircraftModel of aircraftModels) {
          this.aircraftModelsObj[aircraftModel.id] = aircraftModel;
        }
      }
    });

    for (const statusValue in this.statusLabelObj) {
      if (
        ![EnumEnquiryCotationStatus.confirmed, EnumEnquiryCotationStatus.cancelled].includes(
          statusValue as EnumEnquiryCotationStatus
        )
      ) {
        // We don't want confirmed in the dropdown
        this.statusLabelList.push({
          title: this.statusLabelObj[statusValue]?.title,
          value: statusValue
        });
      }
    }
  }

  ngOnInit(): void {
    this.formStatusCancelled = this.formBuilder.group({
      reason: ['', [Validators.required]],
      comment: ['']
    });

    this.activatedRoute.url.subscribe(() => {
      this.enquiryId = this.activatedRoute.snapshot.paramMap.get('enquiryId');

      this.loadData();
    });
  }

  getEnquiryStatus(): EnumEnquiryStatus[] {
    return Object.values(EnumEnquiryStatus);
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  getCancelledReasons(): EnumEnquiryCancelledReason[] {
    return Object.values(EnumEnquiryCancelledReason);
  }

  updateFloatingScroll(): void {
    window['$']('.table-responsive').floatingScroll('init', {
      orientation: 'horizontal'
    });
  }

  openDropdownCotationStatus(): void {
    // Fix bug on safari where dropdown not displaying if not scrolling
    const currentTopPosition: number =
      document.documentElement.scrollTop || document.body.scrollTop;
    window.scrollTo(0, currentTopPosition - 1);
    setTimeout(() => {
      window.scrollTo(0, currentTopPosition + 1);
    }, 300);
  }

  updateTooltip(): void {
    window['$']('[rel="tooltip"]').tooltip({
      html: true,
      boundary: 'window'
    });
  }

  ngOnDestroy(): void {
    window['$']('.tooltip').remove();
    window['$']('.table-responsive').floatingScroll('destroy');

    this.removeModal();

    this.subscriptions.unsubscribe();
    this.subscriptionsFlights.unsubscribe();
  }

  removeModal(): void {
    if (this.modalCotationEditElement) {
      window['$'](this.modalCotationEditElement.nativeElement).modal('hide');
    }
    if (this.modalConfirmedElement) {
      window['$'](this.modalConfirmedElement.nativeElement).modal('hide');
    }
    if (this.modalCancelledElement) {
      window['$'](this.modalCancelledElement.nativeElement).modal('hide');
    }
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  async loadData(): Promise<void> {
    if (this.isLogged || (!this.isLogged && window.location.href.match('public') !== null)) {
      await this.remoteService.loadAircraftModels();
      this.loadEnquiry();
      this.loadEnquiryFlights();
      this.loadEnquiryInvoices();
      this.loadEnquiryQuotations();
      this.loadBankAccounts();
      this.loadFlightBriefs();
      this.loadTodayCurrencyExchangeRate();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  copyPublicUrl(): void {
    const url: string = window.location.origin + '/public/enquiry/' + this.enquiryId;

    const copyTest = document.queryCommandSupported('copy');

    if (copyTest === true) {
      let copyTextArea = document.createElement('textarea');
      copyTextArea.value = url;
      document.body.appendChild(copyTextArea);
      copyTextArea.select();

      try {
        if (document.execCommand('copy')) {
          alert("L'url a été copiée");
        } else {
          console.log('Oops, impossible de copier');
        }
      } catch (err) {
        console.log('Oops, impossible de copier');
      }

      document.body.removeChild(copyTextArea);
    } else {
      // Fallback if browser doesn't support .execCommand('copy')
      window.prompt('Copiez dans le presse-papier: Ctrl+C ou Command+C, Entrer', url);
    }
  }

  loadLastContractedEnquiryOfMonth(): void {
    const currentMonth: number = getMonth(new Date()) + 1;
    const currentYear: number = parseInt(format(new Date(), 'yy'));

    if (this.enquiry) {
      this.subscriptions.add(
        this.enquiryService
          .getLatestEnquiryOfMonth(true, currentYear, currentMonth)
          .subscribe((lastEnquiry: IEnquiry) => {
            this.lastEnquiry = lastEnquiry;
          })
      );
    }
  }

  loadEnquiry(): void {
    this.loading = true;

    this.subscriptions.add(
      this.enquiryService.getFromId(this.enquiryId).subscribe(async (enquiry: IEnquiry) => {
        this.enquiry = enquiry;

        this.loadEnquiryOffers();
        this.loadEnquiryCotations();

        this.loadLastContractedEnquiryOfMonth();

        await this.loadAirportsOfEnquiry();

        const fields: {
          title: string;
          code: string;
        }[] = [
          {
            title: 'Statut',
            code: 'statusTitle'
          },
          {
            title: 'Compagnie',
            code: 'airlineTitle'
          },
          {
            title: 'Nationalité',
            code: 'airlineCountryTitle'
          },
          {
            title: 'Legs',
            code: 'legsTitle'
          },
          {
            title: 'Appareil',
            code: 'aircraftTitle'
          },
          {
            title: 'Âge moyen',
            code: 'ageAverage'
          },
          {
            title: 'Capacité',
            code: 'capacityAircraft'
          },
          {
            title: 'Poids',
            code: 'weightAircraft'
          },
          {
            title: 'Volume',
            code: 'volumeAircraft'
          },
          {
            title: 'Nb pax',
            code: 'nbPax'
          },
          {
            title: "Prix d'achat",
            code: 'buyingPrice'
          },
          {
            title: 'Taxes',
            code: 'taxes'
          },
          {
            title: 'Catering',
            code: 'catering'
          },
          {
            title: "Prix d'achat TC et CC",
            code: 'priceNetTTC'
          },
          {
            title: 'Prix pp',
            code: 'pricePP'
          },
          {
            title: 'Prix de vente TC et CC',
            code: 'sellingPrice'
          },
          {
            title: 'Marge',
            code: 'margin'
          },
          {
            title: 'Marge %',
            code: 'marginPercent'
          },
          {
            title: 'Prix vente pp',
            code: 'sellingPricePP'
          },
          {
            title: 'Notes',
            code: 'notes'
          }
        ];

        this.fields = [];
        if (this.enquiry?.type === EnumEnquiryType.cargo) {
          for (const field of fields) {
            if (
              [
                'capacityAircraft',
                'nbPax',
                'taxes',
                'catering',
                'pricePP',
                'sellingPricePP'
              ].indexOf(field.code) === -1
            ) {
              this.fields.push(field);
            }
          }
        } else {
          for (const field of fields) {
            if (['weightAircraft', 'volumeAircraft'].indexOf(field.code) === -1) {
              this.fields.push(field);
            }
          }
        }

        this.legsList = {};
        this.itinerariesTitleObj = {};
        this.itinerariesList = [];

        if (this.enquiry?.itineraries) {
          for (const itineraryIndex in this.enquiry.itineraries) {
            this.itinerariesList.push({
              title: this.enquiry.itineraries[itineraryIndex]?.title,
              value: this.enquiry.itineraries[itineraryIndex].id
            });
            this.itinerariesTitleObj[this.enquiry.itineraries[itineraryIndex].id] =
              this.enquiry.itineraries[itineraryIndex]?.title;

            for (const trip of this.enquiry.itineraries[itineraryIndex].trips) {
              const title: string[] = [];
              const airports: string[] = [];

              title.push(
                this.airportsObj[trip.airportDepart]?.iataCode
                  ? this.airportsObj[trip.airportDepart]?.iataCode
                  : this.airportsObj[trip.airportDepart]?.title
              );
              airports.push(this.airportsObj[trip.airportDepart]?.iataCode);

              if (trip.hasCommercialStopOver) {
                title.push(
                  'STOP ' +
                    (this.airportsObj[trip.commercialStopOverAirport]?.iataCode
                      ? this.airportsObj[trip.commercialStopOverAirport]?.iataCode
                      : this.airportsObj[trip.commercialStopOverAirport]?.title)
                );
                airports.push(this.airportsObj[trip.commercialStopOverAirport]?.iataCode);
              }

              if (trip.hasFuelStopOver) {
                title.push(
                  'FUEL ' +
                    (this.airportsObj[trip.fuelStopOverAirport]?.iataCode
                      ? this.airportsObj[trip.fuelStopOverAirport]?.iataCode
                      : this.airportsObj[trip.fuelStopOverAirport]?.title)
                );
                airports.push(this.airportsObj[trip.fuelStopOverAirport]?.iataCode);
              }

              title.push(
                this.airportsObj[trip.airportDestination]?.iataCode
                  ? this.airportsObj[trip.airportDestination]?.iataCode
                  : this.airportsObj[trip.airportDestination]?.title
              );
              airports.push(this.airportsObj[trip.airportDestination]?.iataCode);

              if (!this.legsList[this.enquiry.itineraries[itineraryIndex].id]) {
                this.legsList[this.enquiry.itineraries[itineraryIndex].id] = [];
              }

              const titleStr: string = title.join(' -> ');

              this.legsList[this.enquiry.itineraries[itineraryIndex].id].push({
                title: titleStr,
                value: trip.id,
                airports: [...new Set(airports)]
              });

              this.legsObj[trip.id] = {
                airportDepart: this.airportsObj[trip.airportDepart]?.title,
                airportDepartCode: trip.airportDepart,
                airportDestination: this.airportsObj[trip.airportDestination]?.title,
                airportDestinationCode: trip.airportDestination,
                commercialStopOverAirport:
                  trip.commercialStopOverAirport && this.airportsObj[trip.commercialStopOverAirport]
                    ? this.airportsObj[trip.commercialStopOverAirport]?.title
                    : '',
                commercialStopOverAirportCode: trip.commercialStopOverAirport,
                fuelStopOverAirport:
                  trip.fuelStopOverAirport && this.airportsObj[trip.fuelStopOverAirport]
                    ? this.airportsObj[trip.fuelStopOverAirport]?.title
                    : '',
                fuelStopOverAirportCode: trip.fuelStopOverAirport,
                title: titleStr,
                value: trip.id,
                nbPax: trip.passengersTotal
              };
            }
          }
        }

        this.updateLines();

        await this.loadClient();
        await this.fetchAircraftsAndFlightCompanies();

        // We add confirmed cotation variable
        if (this.enquiry?.cotationConfirmedIds) {
          for (const cotationConfirmedId of this.enquiry.cotationConfirmedIds) {
            this.toggleConfirmedCotation(cotationConfirmedId);
          }
        }

        this.loading = false;
      })
    );
  }

  loadEnquiryOffers(): void {
    this.subscriptions.add(
      this.enquiryOfferService
        .getAllForEnquiry(this.enquiryId)
        .subscribe(async (enquiryOffers: IEnquiryOffer[]) => {
          this.enquiryOffers = enquiryOffers;

          this.refreshEnquiryCotationsByOffers();
        })
    );
  }

  loadEnquiryCotations(): void {
    this.subscriptions.add(
      this.enquiryCotationService
        .getAllForEnquiry(this.enquiryId)
        .subscribe(async (enquiryCotations: IEnquiryCotation[]) => {
          this.enquiryCotations = enquiryCotations;

          this.refreshEnquiryCotationsByOffers();
        })
    );
  }

  loadEnquiryInvoices(): void {
    this.invoices = [];
    this.proformas = [];
    this.creditNotes = [];

    this.subscriptions.add(
      this.invoiceService.getAllForEnquiry(this.enquiryId).subscribe((invoices: IInvoice[]) => {
        this.invoices = [];
        this.proformas = [];
        this.creditNotes = [];

        for (const invoice of invoices) {
          if (
            [EnumInvoiceType.proforma, EnumInvoiceType.proformaCreditNote].includes(
              invoice.invoiceType
            )
          ) {
            this.proformas.push(invoice);
          } else if (invoice.invoiceType === EnumInvoiceType.creditNote) {
            this.creditNotes.push(invoice);
          } else {
            this.invoices.push(invoice);
          }
        }
      })
    );
  }

  loadFlightBriefs(): void {
    this.flightBriefs = [];

    this.subscriptions.add(
      this.flightBriefService
        .getAllForEnquiry(this.enquiryId)
        .subscribe((flightBriefs: IFlightBrief[]) => {
          this.flightBriefs = flightBriefs;
        })
    );
  }

  loadEnquiryQuotations(): void {
    this.subscriptions.add(
      this.quotationService
        .getAllForEnquiry(this.enquiryId)
        .subscribe((quotations: IQuotation[]) => {
          this.quotations = quotations;
        })
    );
  }

  refreshEnquiryCotationsByOffers(): void {
    this.enquiryCotationsByOffers = {};

    for (const enquiryOffer of this.enquiryOffers) {
      this.enquiryCotationsByOffers[enquiryOffer.id] = {
        offer: enquiryOffer,
        cotations: []
      };
    }

    for (const enquiryCotation of this.enquiryCotations) {
      if (this.enquiryCotationsByOffers[enquiryCotation.offerId]) {
        this.enquiryCotationsByOffers[enquiryCotation.offerId].cotations.push(enquiryCotation);
      }
    }

    window['$']('.tooltip').remove();

    this.updateLines();
  }

  updateLines(): void {
    if (this.enquiry) {
      this.lines = [];

      for (const offerId in this.enquiryCotationsByOffers) {
        const offer: IEnquiryOffer = this.enquiryCotationsByOffers[offerId].offer;
        if (this.enquiryCotationsByOffers[offerId].cotations?.length) {
          for (const cotation of this.enquiryCotationsByOffers[offerId].cotations) {
            let aircraftCompiled: IAircraftCompiled;

            if (cotation.aircraftCompiled) {
              aircraftCompiled = cotation.aircraftCompiled;
            } else if (offer.aircraftCompiled) {
              aircraftCompiled = offer.aircraftCompiled;
            }

            const line: CotationLine = {
              enquiryId: offer.enquiryId,
              offerId: offer.id,
              cotationId: cotation.id,
              status: cotation.status ?? offer.status,
              statusTitle:
                this.statusLabelObj[cotation.status ? cotation.status : offer.status]?.title,
              followedUp: offer.followedUp,
              airlineId: aircraftCompiled?.airlineId ?? null,
              airlineTitle: aircraftCompiled?.airlineTitle ?? null,
              airlineCountryCode: aircraftCompiled?.airlineCountryCode ?? null,
              airlineCountryTitle: getCountryLabel(aircraftCompiled?.airlineCountryCode) ?? null,
              itineraryId: cotation.itineraryId,
              itineraryTitle: this.itinerariesTitleObj[cotation.itineraryId],
              legsId: cotation.legsId,
              isNationalFlight: false,
              legsAirportsCode: [],
              legsTitle: '',
              aircraftCompiledId: aircraftCompiled?.id ?? null,
              aircraftCompiledModel: aircraftCompiled?.model ?? null,
              aircraftTitle: aircraftCompiled?.type ?? null,
              ageAverage: aircraftCompiled?.ageAverage ?? null,
              aircraftErrorMessages: [],
              capacityAircraft: aircraftCompiled?.seatTotal ?? null,
              weightAircraft: aircraftCompiled?.weight ?? null,
              volumeAircraft: aircraftCompiled?.volume ?? null,
              nbPax: cotation.nbPax,
              currency: cotation.currency,
              buyingPrice: cotation.buyingPrice,
              buyingPriceInCurrency: cotation.buyingPriceInCurrency,
              taxesTotalPax: cotation.taxesTotalPax,
              taxesTotalPaxInCurrency: cotation.taxesTotalPaxInCurrency,
              catering: cotation.cateringByPerson * cotation.nbPax,
              cateringInCurrency: cotation.cateringByPersonInCurrency * cotation.nbPax,
              priceNetTTC: cotation.priceNetTTC,
              priceNetTTCInCurrency: cotation.priceNetTTCInCurrency,
              pricePP: cotation.pricePP,
              pricePPInCurrency: cotation.pricePPInCurrency,
              sellingPrice: cotation.sellingPrice,
              sellingPriceInCurrency: cotation.sellingPriceInCurrency,
              margin: cotation.margin,
              marginInCurrency: cotation.marginInCurrency,
              marginPercent: cotation.marginPercent,
              marginPercentInCurrency: cotation.marginPercentInCurrency,
              marginOnConfirmed:
                typeof cotation.marginOnConfirmed !== 'undefined'
                  ? cotation.marginOnConfirmed
                  : null,
              sellingPricePP: cotation.sellingPricePP,
              sellingPricePPInCurrency: cotation.sellingPricePPInCurrency,
              notes: cotation.notes ? cotation.notes : ''
            };

            for (const legId of line.legsId) {
              line.legsAirportsCode.push(
                this.legsObj[legId] ? this.legsObj[legId]?.title : 'Leg supprimé'
              );

              if (this.legsObj[legId]) {
                if (
                  this.airportsObj[this.legsObj[legId].airportDepart] &&
                  this.airportsObj[this.legsObj[legId].airportDestination] &&
                  this.airportsObj[this.legsObj[legId].airportDepart].countryCode ===
                    this.airportsObj[this.legsObj[legId].airportDestination].countryCode
                ) {
                  line.isNationalFlight = true;
                }
              }
            }

            if (this.enquiry.itineraries?.length > 1) {
              line.legsTitle = line.itineraryTitle + '<br>';
            }
            line.legsTitle += line.legsAirportsCode.join('<br>');
            this.lines.push(line);
          }
        } else {
          const line: CotationLine = {
            enquiryId: offer.enquiryId,
            offerId: offer.id,
            cotationId: null,
            status: offer.status,
            statusTitle: this.statusLabelObj[offer.status]?.title,
            followedUp: offer.followedUp,
            airlineId: offer.aircraftCompiled?.airlineId ?? null,
            airlineTitle: offer.aircraftCompiled?.airlineTitle ?? null,
            airlineCountryCode: offer.aircraftCompiled?.airlineCountryCode ?? null,
            airlineCountryTitle: getCountryLabel(offer.aircraftCompiled.airlineCountryCode) ?? null,
            itineraryId: null,
            itineraryTitle: null,
            legsId: [],
            isNationalFlight: false,
            legsAirportsCode: [],
            legsTitle: '',
            aircraftCompiledId: offer.aircraftCompiled?.id ?? null,
            aircraftCompiledModel: offer.aircraftCompiled?.model ?? null,
            aircraftTitle: offer.aircraftCompiled?.type ?? null,
            ageAverage: offer.aircraftCompiled?.ageAverage ?? null,
            aircraftErrorMessages: [],
            capacityAircraft: offer.aircraftCompiled?.seatTotal ?? null,
            weightAircraft: offer.aircraftCompiled?.weight ?? null,
            volumeAircraft: offer.aircraftCompiled?.volume ?? null,
            nbPax: null,
            currency: null,
            buyingPrice: null,
            buyingPriceInCurrency: null,
            taxesTotalPax: null,
            taxesTotalPaxInCurrency: null,
            catering: null,
            cateringInCurrency: null,
            priceNetTTC: null,
            priceNetTTCInCurrency: null,
            pricePP: null,
            pricePPInCurrency: null,
            sellingPrice: null,
            sellingPriceInCurrency: null,
            margin: null,
            marginInCurrency: null,
            marginPercent: null,
            marginPercentInCurrency: null,
            marginOnConfirmed: null,
            sellingPricePP: null,
            sellingPricePPInCurrency: null,
            notes: ''
          };

          this.lines.push(line);
        }
      }

      for (const line of this.lines) {
        // Check if problem with aircraft
        for (let i = 0; i < this.enquiry.itineraries?.length; i++) {
          for (const trip of this.enquiry.itineraries[i].trips) {
            if (
              line.legsId.includes(trip.id) &&
              line.aircraftCompiledModel &&
              this.aircraftModelsObj[line.aircraftCompiledModel]
            ) {
              const aircraftModel: IAircraftModel =
                this.aircraftModelsObj[line.aircraftCompiledModel];

              if (
                aircraftModel &&
                aircraftModel.rangeInKms &&
                trip.distanceInKm > aircraftModel.rangeInKms
              ) {
                let text: string =
                  "Ce modèle d'avion n'a pas l'autonomie suffisante (" +
                  aircraftModel.rangeInKms +
                  ' kms) <br>' +
                  'pour faire le trajet <em>';

                if (this.enquiry.itineraries?.length > 1) {
                  text += this.enquiry.itineraries[i]?.title + ' / ';
                }
                text +=
                  this.airportsObj[trip.airportDepart]?.title +
                  ' -> ' +
                  this.airportsObj[trip.airportDestination]?.title;

                text += '</em> (distance: ' + this.roundNumber(trip.distanceInKm) + ' kms). <br>';
                text += 'Veuillez ajouter au moins une escale.';

                line.aircraftErrorMessages.push(text);
              }
            }
          }
        }
      }

      this.sortingLines();
    }
  }

  async loadClient(): Promise<void> {
    if (this.enquiry?.clientId) {
      this.client = await this.pipedriveService.getOrganization(
        typeof this.enquiry.clientId === 'number'
          ? this.enquiry.clientId.toString()
          : this.enquiry.clientId
      );
    }

    if (this.enquiry?.contactId) {
      this.contact = await this.pipedriveService.getPerson(this.enquiry.contactId);
    }
  }

  async fetchAircraftsAndFlightCompanies(): Promise<void> {
    if (this.enquiry) {
      const aircraftsCompiledId: string[] = [];

      for (const enquiryOffer of this.enquiryOffers) {
        aircraftsCompiledId.push(enquiryOffer.aircraftCompiledId);
      }

      if (aircraftsCompiledId.length) {
        const aircraftsCompiledList: object[] = await this.remoteService.getDocumentsFromDocId(
          'aircraftsCompiled',
          aircraftsCompiledId
        );

        for (const aircraftCompiledObj of aircraftsCompiledList) {
          this.aircraftsCompiledObj[aircraftCompiledObj['id']] =
            aircraftCompiledObj as IAircraftCompiled;
        }
      }

      const airlinesId: string[] = [];
      for (const aircraftCompiledId in this.aircraftsCompiledObj) {
        airlinesId.push(this.aircraftsCompiledObj[aircraftCompiledId].airlineId);
      }

      if (airlinesId.length) {
        const airlinesList: object[] = await this.remoteService.getDocumentsFromDocId(
          'airlines',
          airlinesId
        );

        for (const airlineObj of airlinesList) {
          this.airlinesObj[airlineObj['id']] = airlineObj as IAirline;
        }
      }

      this.loading = false;
    }
  }

  formatPrice(value: number, currency: string = 'EUR'): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }

  async sortField(field: string): Promise<void> {
    if (this.orderBy.field == field) {
      if (this.orderBy.direction === 'asc') {
        this.orderBy.direction = 'desc';
      } else {
        this.orderBy.direction = 'asc';
      }
    } else {
      this.orderBy.field = field;
      this.orderBy.direction = 'asc';
    }

    this.sortingLines();

    this.loading = false;
  }

  sortingLines(): void {
    this.lines.sort((a, b) => {
      switch (this.orderBy.field) {
        case 'status':
          if (a.status && b.status) {
            if (this.statusLabelObj[a.status].position < this.statusLabelObj[b.status].position) {
              return this.orderBy.direction === 'asc' ? -1 : 1;
            }
            if (this.statusLabelObj[a.status].position > this.statusLabelObj[b.status].position) {
              return this.orderBy.direction === 'asc' ? 1 : -1;
            }
          }
          break;
        default:
          if (a[this.orderBy.field] < b[this.orderBy.field]) {
            return this.orderBy.direction === 'asc' ? -1 : 1;
          }
          if (a[this.orderBy.field] > b[this.orderBy.field]) {
            return this.orderBy.direction === 'asc' ? 1 : -1;
          }
          if (a[this.orderBy.field] === b[this.orderBy.field]) {
            return this.statusLabelObj[a.status].position < this.statusLabelObj[b.status].position
              ? -1
              : 1;
          }
          break;
      }
      return 0;
    });

    this.updateTooltip();
    this.updateFloatingScroll();

    setTimeout(() => {
      this.updateTooltip();
      this.updateFloatingScroll();
    }, 1000);

    setTimeout(() => {
      this.updateTooltip();
      this.updateFloatingScroll();
    }, 3000);

    setTimeout(() => {
      this.updateTooltip();
      this.updateFloatingScroll();
    }, 5000);

    this.loading = false;
  }

  roundNumber(value: number): number {
    return Math.round(value * 100 + Number.EPSILON) / 100;
  }

  relanceOffer(offerId: string): void {
    alert('Cette fonctionnalité est en cours de développement.');
  }

  async delete(): Promise<void> {
    if (this.hasAclAccess(EnumAcl.enquiriesDelete)) {
      const result: boolean = confirm(
        'La suppression de cette demande sera permanente. Êtes-vous sûr de vouloir continuer?'
      );

      if (result) {
        try {
          await this.enquiryService.delete(this.enquiryId);

          alert('La suppression a été effectuée avec succès.');

          this.router.navigate(['/admin']);
        } catch (err) {
          console.log(err);
          alert(err.message);
        }
      }
    }
  }

  addCotationToOffer(offerId: string): void {
    this.updateCotationForm(offerId);

    window['$'](this.modalCotationEditElement.nativeElement).modal('show');
  }

  editCotation(offerId: string, cotationId: string): void {
    this.updateCotationForm(offerId, cotationId);

    window['$'](this.modalCotationEditElement.nativeElement).modal('show');
  }

  ifIncludedNotNegative(formGroup: FormGroup): void {
    if (this.enquiry.type !== EnumEnquiryType.cargo) {
      for (const field of ['taxesTotalPax', 'cateringByPerson']) {
        const isIncluded: boolean = formGroup.value[field + 'Included'];

        if (
          !isIncluded &&
          formGroup.value[field] !== null &&
          formGroup.value[field] !== '' &&
          formGroup.value[field] < 0
        ) {
          formGroup.get(field).setErrors({
            isZeroOrBelow: true
          });
        }
      }
    }
  }

  updateCotationForm(selectedOfferId: string, cotationId: string = null): void {
    this.form = this.formBuilder.group(
      {
        enquiryId: new FormControl(this.enquiryId, [Validators.required]),
        aircraftCompiledId: new FormControl('', [Validators.required]),
        offerId: new FormControl(selectedOfferId, [Validators.required]),
        status: new FormControl(null),
        id: new FormControl(cotationId),
        nbPax: new FormControl(0, [Validators.required]),
        itineraryId: new FormControl('', [Validators.required]),
        legsId: new FormControl([], [Validators.required]),
        buyingPriceInCurrency: new FormControl('', [Validators.required]),
        buyingPrice: new FormControl('', [Validators.required]),
        sellingPriceInCurrency: new FormControl(''),
        sellingPrice: new FormControl(''),
        taxesByPersonIncluded: new FormControl(false),
        taxesByPersonInCurrency: new FormControl(0),
        taxesByPerson: new FormControl(0),
        taxesTotalPaxInCurrency: new FormControl(0),
        taxesTotalPax: new FormControl(0),
        isTaxesByPersonType: new FormControl('leg'),
        taxesByPersonByLeg: new FormArray([]),
        taxesByPersonByAirport: new FormArray([]),
        cateringByPersonIncluded: new FormControl(false),
        cateringByPersonInCurrency: new FormControl(0),
        cateringByPerson: new FormControl(0),
        notes: new FormControl(''),
        currency: new FormControl(this.defaultCurrency, [Validators.required])
      },
      { validator: this.ifIncludedNotNegative.bind(this) }
    );

    if (this.enquiry.type === EnumEnquiryType.cargo) {
      // We disable useless fields
      for (const field of [
        'nbPax',
        'cateringByPersonIncluded',
        'cateringByPerson',
        'taxesByPersonIncluded',
        'taxesTotalPax'
      ]) {
        this.form.get(field).clearValidators();
        this.form.get(field).updateValueAndValidity();
      }
    }

    this.editingOffer = null;
    this.editingCotation = null;

    for (const offerId in this.enquiryCotationsByOffers) {
      if (offerId === selectedOfferId) {
        this.editingOffer = this.enquiryCotationsByOffers[offerId].offer;

        this.loadAirlineAircrafts(this.editingOffer.aircraftCompiled.airlineId);
      }

      if (cotationId) {
        for (const cotation of this.enquiryCotationsByOffers[offerId].cotations) {
          if (cotation.id === cotationId) {
            this.editingCotation = cotation;

            break;
          }
        }
      }
    }

    let aircraftCompiled: IAircraftCompiled;

    if (this.editingCotation && this.editingCotation.aircraftCompiled) {
      aircraftCompiled = this.editingCotation.aircraftCompiled;
    } else if (this.editingOffer.aircraftCompiled) {
      aircraftCompiled = this.editingOffer.aircraftCompiled;
    }

    if (aircraftCompiled) {
      this.form.get('aircraftCompiledId').setValue(aircraftCompiled.id);
    }

    this.form.get('id').setValue(this.editingCotation?.id ?? null);

    if (this.editingCotation) {
      this.form.get('status').setValue(this.editingCotation.status);
      this.form.get('nbPax').setValue(this.editingCotation.nbPax);
      this.form.get('itineraryId').setValue(this.editingCotation.itineraryId);
      this.form.get('legsId').setValue(this.editingCotation.legsId);
      this.form.get('taxesByPersonIncluded').setValue(this.editingCotation.taxesByPersonIncluded);
      this.form
        .get('cateringByPersonIncluded')
        .setValue(this.editingCotation.cateringByPersonIncluded);
      this.form.get('notes').setValue(this.editingCotation.notes);
      this.form.get('currency').setValue(this.editingCotation.currency);
      this.form.get('isTaxesByPersonType').setValue(this.editingCotation.isTaxesByPersonType);

      this.refreshTaxesByPassengers();

      const taxesByPersonByLegByLegId: any = {};
      for (const taxesByPersonByLeg of this.editingCotation.taxesByPersonByLeg) {
        taxesByPersonByLegByLegId[taxesByPersonByLeg.legId] = taxesByPersonByLeg;
      }

      const taxesByPersonByAirportByAirport: any = {};
      if (this.editingCotation.taxesByPersonByAirport) {
        for (const taxesByPersonByAirport of this.editingCotation.taxesByPersonByAirport) {
          taxesByPersonByAirportByAirport[taxesByPersonByAirport.airport] = taxesByPersonByAirport;
        }
      }

      const taxesByPersonByLegFormArray: FormArray = this.form.get(
        'taxesByPersonByLeg'
      ) as FormArray;

      for (let i = 0; i < taxesByPersonByLegFormArray.value.length; i++) {
        if (
          taxesByPersonByLegFormArray.value[i].legId &&
          taxesByPersonByLegByLegId[taxesByPersonByLegFormArray.value[i].legId]
        ) {
          for (const field in taxesByPersonByLegByLegId[
            taxesByPersonByLegFormArray.value[i].legId
          ]) {
            if (typeof taxesByPersonByLegFormArray.value[i][field] !== 'undefined') {
              taxesByPersonByLegFormArray
                .at(i)
                .get(field)
                .setValue(
                  taxesByPersonByLegByLegId[taxesByPersonByLegFormArray.value[i].legId][field]
                );
            }
          }
        }
      }

      const taxesByPersonByAirportFormArray: FormArray = this.form.get(
        'taxesByPersonByAirport'
      ) as FormArray;

      for (let i = 0; i < taxesByPersonByAirportFormArray.value.length; i++) {
        if (
          taxesByPersonByAirportFormArray.value[i].airport &&
          taxesByPersonByAirportByAirport[taxesByPersonByAirportFormArray.value[i].airport]
        ) {
          for (const field in taxesByPersonByAirportByAirport[
            taxesByPersonByAirportFormArray.value[i].airport
          ]) {
            if (typeof taxesByPersonByAirportFormArray.value[i][field] !== 'undefined') {
              taxesByPersonByAirportFormArray
                .at(i)
                .get(field)
                .setValue(
                  taxesByPersonByAirportByAirport[taxesByPersonByAirportFormArray.value[i].airport][
                    field
                  ]
                );
            }
          }
        }
      }

      for (const fieldType of ['', 'InCurrency']) {
        this.form
          .get('buyingPrice' + fieldType)
          .setValue(this.editingCotation['buyingPrice' + fieldType]);
        this.form
          .get('sellingPrice' + fieldType)
          .setValue(this.editingCotation['sellingPrice' + fieldType]);
        this.form
          .get('taxesTotalPax' + fieldType)
          .setValue(this.editingCotation['taxesTotalPax' + fieldType]);

        this.form
          .get('cateringByPerson' + fieldType)
          .setValue(this.editingCotation['cateringByPerson' + fieldType]);
      }
    } else {
      let totalPassengers: number = 0;

      for (const legId in this.legsObj) {
        totalPassengers += this.legsObj[legId].nbPax;
      }

      this.form
        .get('nbPax')
        .setValue(
          Object.keys(this.legsObj).length
            ? Math.round(totalPassengers / Object.keys(this.legsObj).length)
            : 0
        );
    }

    if (this.enquiry.itineraries?.length === 1) {
      this.form.get('itineraryId').setValue(this.enquiry.itineraries[0].id);
      this.form.get('legsId').markAsTouched();
    }
  }

  async deleteCotation(cotationId: string): Promise<void> {
    const result: boolean = confirm(
      'La suppression de la cotation sera permanente. Êtes-vous sûr de vouloir continuer ?'
    );

    if (result) {
      try {
        await this.enquiryCotationService.delete(cotationId);
      } catch (err) {
        console.log(err);

        alert(err.message);
      }
    }
  }

  async loadAirlineAircrafts(airlineId: string): Promise<void> {
    if (!this.airlinesAircrafts[airlineId] || !this.airlinesAircrafts[airlineId].length) {
      this.airlinesAircrafts[airlineId] = await this.remoteService.getAirlineAircraftsModel(
        airlineId,
        this.enquiry.type === EnumEnquiryType.cargo
      );
    }
  }

  async saveCotation(): Promise<void> {
    if (this.form.status == 'VALID') {
      let data = Object.assign({}, this.form.value);

      const aircraftCompiledId: string = data.aircraftCompiledId;

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.saving = true;

      this.form.disable();

      data = this.setFieldDataAutomaticallyBeforeSubmit(data);

      for (const field of ['cateringByPerson', 'taxesTotalPax']) {
        if (data[field] === null || data[field] === '') {
          data[field] = 0;
        }
      }

      let cotationIsNew: boolean = true;
      for (const offerId in this.enquiryCotationsByOffers) {
        const offer: IEnquiryOffer = this.enquiryCotationsByOffers[offerId].offer;

        if (offerId === data.offerId) {
          for (const cotation of this.enquiryCotationsByOffers[offerId].cotations) {
            if (cotation.id === data.id) {
              for (const aircraftCompiled of this.airlinesAircrafts[
                offer.aircraftCompiled?.airlineId
              ]) {
                if (aircraftCompiled.id === aircraftCompiledId) {
                  data.aircraftCompiled = aircraftCompiled;
                  data.aircraftCompiledId = aircraftCompiled.id;
                  break;
                }
              }

              cotationIsNew = false;

              break;
            }
          }
          break;
        }
      }

      if (
        data.buyingPrice &&
        (!data.status ||
          [
            EnumEnquiryCotationStatus.waitingForAnswer,
            EnumEnquiryCotationStatus.toContact
          ].includes(data.status))
      ) {
        data.status = EnumEnquiryCotationStatus.answered;
      }

      if (data.aircraftCompiled) {
        data.aircraftCompiled = JSON.parse(JSON.stringify(data.aircraftCompiled), this.reviver);
      }

      let promise;
      if (data.id) {
        promise = () => this.enquiryCotationService.update(data);
      } else {
        promise = () => this.enquiryCotationService.create(data);
      }
      promise()
        .then(async id => {
          data.id = id;

          await this.enquiryOfferService.update({
            id: data.offerId,
            status: data.status
          } as IEnquiryOffer);

          this.updateLines();

          window['$'](this.modalCotationEditElement.nativeElement).modal('hide');

          this.saving = false;
          this.form.enable();
        })
        .catch(err => {
          this.saving = false;
          this.form.enable();

          alert(err.message);
        });
    }
  }

  reviver(key: string, value: string): any {
    if (
      ['selectedDatetime', 'contactDatetime', 'created', 'modified'].indexOf(key) !== -1 &&
      !isNaN(Date.parse(value))
    ) {
      return new Date(value);
    }

    return value;
  }

  async changeOfferStatus(offerId: string, newStatus: EnumEnquiryOfferStatus): Promise<void> {
    await this.enquiryOfferService.update({
      id: offerId,
      status: newStatus
    } as IEnquiryOffer);
  }

  async changeOneCotationStatus(
    cotationId: string,
    newStatus: EnumEnquiryCotationStatus
  ): Promise<void> {
    for (const enquiryCotation of this.enquiryCotations) {
      if (enquiryCotation.id === cotationId) {
        await this.changeCotationsStatus(
          [
            {
              offerId: enquiryCotation.offerId,
              cotation: enquiryCotation
            }
          ],
          newStatus
        );

        break;
      }
    }
  }

  async changeCotationsStatus(
    cotationAndOfferIds: {
      offerId: string;
      cotation: IEnquiryCotation;
    }[],
    newStatus: EnumEnquiryCotationStatus
  ): Promise<void> {
    try {
      for (const cotationAndOfferId of cotationAndOfferIds) {
        const cotationData: IEnquiryCotation = {
          id: cotationAndOfferId.cotation.id,
          status: newStatus
        } as IEnquiryCotation;

        if (newStatus === EnumEnquiryCotationStatus.confirmed) {
          cotationData.marginOnConfirmed =
            cotationAndOfferId.cotation.sellingPrice - cotationAndOfferId.cotation.priceNetTTC;
        }

        await this.enquiryCotationService.update(cotationData);

        await this.enquiryOfferService.update({
          id: cotationAndOfferId.offerId,
          status: newStatus.toString()
        } as IEnquiryOffer);
      }

      this.updateLines();
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  }

  setFieldDataAutomaticallyBeforeSubmit(data: object): object {
    for (const fieldType of ['', 'InCurrency']) {
      data['priceNetTTC' + fieldType] = null;
      data['pricePP' + fieldType] = null;
      data['sellingPricePP' + fieldType] = null;

      if (data['buyingPrice' + fieldType]) {
        data['priceNetTTC' + fieldType] = data['buyingPrice' + fieldType];
      }

      data['priceNetTTC' + fieldType] += data['taxesTotalPax' + fieldType];

      if (data['nbPax']) {
        for (const field of ['cateringByPerson']) {
          if (data[field]) {
            data['priceNetTTC' + fieldType] += data['nbPax'] * data[field + fieldType];
          }
        }

        data['pricePP' + fieldType] = data['priceNetTTC' + fieldType] / data['nbPax'];

        data['sellingPricePP' + fieldType] = data['sellingPrice' + fieldType]
          ? data['sellingPrice' + fieldType] / data['nbPax']
          : null;
      }

      if (data['sellingPrice' + fieldType]) {
        data['margin' + fieldType] =
          data['sellingPrice' + fieldType] - data['priceNetTTC' + fieldType];
        data['marginPercent' + fieldType] = this.roundNumber(
          (data['margin' + fieldType] / data['priceNetTTC' + fieldType]) * 100
        );
      } else {
        data['sellingPrice' + fieldType] = null;
        data['margin' + fieldType] = null;
        data['marginPercent' + fieldType] = null;
      }
    }

    return data;
  }

  changeStatus(newStatus: EnumEnquiryStatus): void {
    if (this.hasAclAccess(EnumAcl.enquiriesSwitchStatus)) {
      if (
        this.enquiry.status === EnumEnquiryStatus.confirmed &&
        !this.hasAclAccess(EnumAcl.enquiriesSwitchStatusFromConfirmed)
      ) {
        alert("Vous n'avez pas les autorisations nécessaires pour modifier une demande confirmée.");
      } else {
        if (newStatus === EnumEnquiryStatus.cancelled) {
          window['$'](this.modalCancelledElement.nativeElement).modal('show');
        } else if (newStatus === EnumEnquiryStatus.confirmed) {
          let noCotation: boolean = true;
          for (const offerId in this.enquiryCotationsByOffers) {
            for (const cotation of this.enquiryCotationsByOffers[offerId].cotations) {
              noCotation = false;
              break;
            }
          }
          if (noCotation) {
            alert("La demande n'a aucune cotation.");
          } else {
            window['$'](this.modalConfirmedElement.nativeElement).modal('show');
          }
        } else {
          this.setNewStatusToEnquiry({
            status: newStatus,
            cotationConfirmedIds: [],
            reasonCancelled: null
          } as IEnquiry);
        }
      }
    }
  }

  saveStatusCancelled(): void {
    window['$'](this.modalCancelledElement.nativeElement).modal('hide');

    let data: any = {
      status: EnumEnquiryStatus.cancelled,
      cotationConfirmedIds: [],
      reasonCancelled: Object.assign({}, this.formStatusCancelled.value)
    };

    this.formStatusCancelled.reset();

    this.setNewStatusToEnquiry(data);
  }

  async resetOfferConfirmed(): Promise<void> {
    for (const offerId in this.enquiryCotationsByOffers) {
      for (const cotation of this.enquiryCotationsByOffers[offerId].cotations) {
        if (cotation.status === EnumEnquiryCotationStatus.confirmed) {
          await this.changeCotationsStatus(
            [
              {
                cotation,
                offerId: cotation.offerId
              }
            ],
            EnumEnquiryCotationStatus.answered
          );
        }
      }
    }
  }

  async setFieldDataForConfirmedEnquiry(data: IEnquiry): Promise<any> {
    if (!this.enquiry.refContractNumber) {
      data.refContractYear = parseInt(moment().format('YY'));
      data.refContractMonth = moment().get('month') + 1;

      if (this.lastEnquiry) {
        data.refContractNumber = this.lastEnquiry.refContractNumber + 1;
      } else {
        data.refContractNumber = 1;
      }

      data.refContractPrefix = getEnumEnquiryPrefix(this.enquiry.type);

      data = this.setRefTitle('refContract', data);
    }

    return data;
  }

  setRefTitle(refField: string, data: IEnquiry): IEnquiry {
    if (data[refField + 'Number']) {
      data[refField + 'TitleWithoutPrefix'] = getEnquiryRefTitleWithoutPrefix(
        data[refField + 'Year'],
        data[refField + 'Month'],
        data[refField + 'Number']
      );

      data[refField + 'Title'] = getEnquiryRefTitle(
        data[refField + 'Prefix'],
        data[refField + 'Year'],
        data[refField + 'Month'],
        data[refField + 'Number']
      );
      data[refField + 'TitleWithoutPrefixDisplayed'] = getEnquiryRefTitleWithoutPrefix(
        data[refField + 'Year'],
        data[refField + 'Month'],
        data[refField + 'Number'],
        2
      );

      data[refField + 'TitleDisplayed'] = getEnquiryRefTitle(
        data[refField + 'Prefix'],
        data[refField + 'Year'],
        data[refField + 'Month'],
        data[refField + 'Number'],
        2
      );
    }

    return data;
  }

  async setNewStatusToEnquiry(data: IEnquiry): Promise<void> {
    try {
      if (data.status !== EnumEnquiryStatus.confirmed) {
        await this.resetOfferConfirmed();
      } else {
        data = await this.setFieldDataForConfirmedEnquiry(data);
      }

      switch (data.status) {
        case EnumEnquiryStatus.confirmed:
          data.dateConfirmed = new Date();
          data.dateCancelled = null;
          break;
        case EnumEnquiryStatus.cancelled:
          data.dateCancelled = new Date();
          data.dateConfirmed = null;
          break;
        default:
          data.dateConfirmed = null;
          data.dateCancelled = null;
          break;
      }

      await this.enquiryService.update(
        Object.assign(
          {
            id: this.enquiryId
          },
          data
        ) as IEnquiry
      );

      await this.updateDealInPipedrive();

      this.loading = false;
    } catch (err) {
      this.loading = false;

      alert(err.message);
    }
  }

  async updateDataOnPipedriveDeal(): Promise<void> {
    if (this.enquiry?.pipedriveDealId) {
    }
  }

  async deleteOffer(offerId: string): Promise<void> {
    const result = confirm(
      'La suppression de cet compagnie et appareil sera permanente. Êtes-vous sûr de vouloir continuer ?'
    );

    if (result) {
      try {
        await this.enquiryOfferService.delete(offerId);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    }
  }

  updateByPersonIncludedField(field: string): void {
    const isIncluded: boolean = this.form.value[field + 'Included'];

    if (field === 'taxesByPerson') {
      for (let i = 0; i < this.form.value.taxesByPersonByLeg.length; i++) {
        for (const fieldType of ['', 'InCurrency']) {
          (this.form.get('taxesByPersonByLeg') as FormArray)
            .at(i)
            .get('amount' + fieldType)
            .setValue(0);
        }
      }

      this.updatedTaxesByPersonByLeg(null, true);
    } else {
      this.form.get(field).setValue(isIncluded ? 0 : '');
    }
  }

  updateLegsId(legId: string): void {
    const currentFieldValues: any[] = [...this.form.value.legsId];
    const currentIndex: number = currentFieldValues.indexOf(legId);

    if (currentIndex === -1) {
      currentFieldValues.push(legId);
    } else {
      currentFieldValues.splice(currentIndex, 1);
    }

    this.form.get('legsId').setValue(currentFieldValues);

    this.refreshTaxesByPassengers();
  }

  refreshTaxesByPassengers(): void {
    const taxesByPersonByLegFormArray: FormArray = this.form.get('taxesByPersonByLeg') as FormArray;
    const taxesByPersonByAirportFormArray: FormArray = this.form.get(
      'taxesByPersonByAirport'
    ) as FormArray;

    const taxesByPersonByLegIndex: any = {};
    for (let i = 0; i < taxesByPersonByLegFormArray.value.length; i++) {
      taxesByPersonByLegIndex[taxesByPersonByLegFormArray.value[i].legId] = i;
    }
    const taxesByPersonByAirportIndex: any = {};
    for (let i = 0; i < taxesByPersonByAirportFormArray.value.length; i++) {
      taxesByPersonByAirportIndex[taxesByPersonByAirportFormArray.value[i].airport] = i;
    }

    const selectedLegsId: string[] = [...this.form.value.legsId];
    const selectedAirports: string[] = [];

    for (const legId in taxesByPersonByLegIndex) {
      if (!selectedLegsId.includes(legId)) {
        taxesByPersonByLegFormArray.removeAt(taxesByPersonByLegIndex[legId]);
      }
    }

    for (const legId of selectedLegsId) {
      if (typeof taxesByPersonByLegIndex[legId] === 'undefined') {
        this.addTaxesFormControlByPersonByLeg();

        taxesByPersonByLegFormArray
          .at(taxesByPersonByLegFormArray.length - 1)
          .get('legId')
          .setValue(legId);
      }

      for (const airportField of [
        'airportDepartCode',
        'commercialStopOverAirportCode',
        'fuelStopOverAirportCode',
        'airportDestinationCode'
      ]) {
        if (
          this.legsObj[legId][airportField] &&
          !selectedAirports.includes(this.legsObj[legId][airportField])
        ) {
          selectedAirports.push(this.legsObj[legId][airportField]);
        }
      }
    }

    for (const airport in taxesByPersonByAirportIndex) {
      if (!selectedAirports.includes(airport)) {
        taxesByPersonByAirportFormArray.removeAt(taxesByPersonByAirportIndex[airport]);
      }
    }

    for (const airport of selectedAirports) {
      if (typeof taxesByPersonByAirportIndex[airport] === 'undefined') {
        this.addTaxesFormControlByPersonByAirport();

        taxesByPersonByAirportFormArray
          .at(taxesByPersonByAirportFormArray.length - 1)
          .get('airport')
          .setValue(airport);
      }
    }
  }

  addTaxesFormControlByPersonByLeg(): void {
    const formArray: FormArray = this.form.get('taxesByPersonByLeg') as FormArray;

    formArray.push(
      this.formBuilder.group({
        legId: new FormControl(null, [Validators.required]),
        nbPax: new FormControl(0, [Validators.required]),
        amount: new FormControl(0, [Validators.required]),
        amountInCurrency: new FormControl(0, [Validators.required])
      })
    );
  }

  addTaxesFormControlByPersonByAirport(): void {
    const formArray: FormArray = this.form.get('taxesByPersonByAirport') as FormArray;

    formArray.push(
      this.formBuilder.group({
        airport: new FormControl(null, [Validators.required]),
        nbPax: new FormControl(0, [Validators.required]),
        amount: new FormControl(0, [Validators.required]),
        amountInCurrency: new FormControl(0, [Validators.required])
      })
    );
  }

  async loadAirportsOfEnquiry(): Promise<void> {
    if (this.enquiry?.itineraries) {
      for (const itinerary of this.enquiry.itineraries) {
        for (const trip of itinerary.trips) {
          for (const field of [
            'airportDepart',
            'airportDestination',
            'commercialStopOverAirport',
            'fuelStopOverAirport'
          ]) {
            if (trip[field]) {
              this.addAirportToLoad(trip[field]);
            }
          }
        }
      }
    }

    await this.loadAirports();
  }

  addAirportToLoad(airportId: string) {
    if (
      this.airportsToLoad.indexOf(airportId) === -1 &&
      typeof this.airportsObj[airportId] === 'undefined'
    ) {
      this.airportsToLoad.push(airportId);
    }
  }

  async loadAirports(): Promise<void> {
    if (this.airportsToLoad.length) {
      const docs: object[] = await this.remoteService.getDocumentsFromDocId(
        'airports',
        this.airportsToLoad
      );

      for (const doc of docs) {
        const airport = doc as IAirport;

        this.airportsObj[airport.id] = airport;
      }

      this.airportsToLoad = [];
    }
  }

  updatedValue(field: string, notDefaultCurrency: boolean): void {
    if (this.form.value.currency === this.defaultCurrency) {
      this.form.get(field).setValue(this.form.value[field + 'InCurrency']);
      this.form.get(field).updateValueAndValidity();
    } else if (notDefaultCurrency) {
      this.applyCurrencyExchangeRate(this.form.get(field), this.form.value[field + 'InCurrency']);
    }
  }

  updatedTaxesByPersonByLegAccordingToCurrency(): void {
    for (let i = 0; i < this.form.value.taxesByPersonByLeg.length; i++) {
      (this.form.get('taxesByPersonByLeg') as FormArray).at(i).get('amount').setValue('');

      this.updatedTaxesByPersonByLegAccordingInDefaultCurrency(i);
    }
  }

  updatedTaxesByPersonByLegAccordingInDefaultCurrency(i: number): void {
    if (this.form.value.currency === this.defaultCurrency) {
      (this.form.get('taxesByPersonByLeg') as FormArray)
        .at(i)
        .get('amount')
        .setValue(this.form.value.taxesByPersonByLeg[i].amountInCurrency);
    }

    (this.form.get('taxesByPersonByLeg') as FormArray).at(i).get('amount').updateValueAndValidity();
  }

  updatedTaxesByPersonByLeg(i: number = null, notDefaultCurrency: boolean = true): void {
    if (i !== null) {
      this.updatedTaxesByPersonByLegAccordingInDefaultCurrency(i);
    }

    let taxesByPersonTotal: {
      amount: number;
      amountInCurrency: number;
    } = {
      amount: 0,
      amountInCurrency: 0
    };

    for (const taxByPersonByLeg of this.form.value.taxesByPersonByLeg) {
      for (const fieldType of ['', 'InCurrency']) {
        taxesByPersonTotal['amount' + fieldType] +=
          taxByPersonByLeg['amount' + fieldType] * taxByPersonByLeg.nbPax;
      }
    }

    for (const fieldType of ['', 'InCurrency']) {
      this.form.get('taxesTotalPax' + fieldType).setValue(taxesByPersonTotal['amount' + fieldType]);
    }

    if (notDefaultCurrency) {
      this.applyCurrencyExchangeRate(
        (this.form.get('taxesByPersonByLeg') as FormArray).at(i).get('amount'),
        this.form.value.taxesByPersonByLeg[i].amountInCurrency
      );
    }
  }

  updatedTaxesByPersonByAirportAccordingToCurrency(): void {
    for (let i = 0; i < this.form.value.taxesByPersonByAirport.length; i++) {
      (this.form.get('taxesByPersonByAirport') as FormArray).at(i).get('amount').setValue('');

      this.updatedTaxesByPersonByAirportAccordingInDefaultCurrency(i);
    }
  }

  updatedTaxesByPersonByAirportAccordingInDefaultCurrency(i: number): void {
    if (this.form.value.currency === this.defaultCurrency) {
      (this.form.get('taxesByPersonByAirport') as FormArray)
        .at(i)
        .get('amount')
        .setValue(this.form.value.taxesByPersonByAirport[i].amountInCurrency);
    }

    (this.form.get('taxesByPersonByAirport') as FormArray)
      .at(i)
      .get('amount')
      .updateValueAndValidity();
  }

  updatedTaxesByPersonByAirport(i: number = null, notDefaultCurrency: boolean = true): void {
    if (i !== null) {
      this.updatedTaxesByPersonByAirportAccordingInDefaultCurrency(i);
    }

    let taxesByPersonTotal: {
      amount: number;
      amountInCurrency: number;
    } = {
      amount: 0,
      amountInCurrency: 0
    };

    const airportByTaxes: any = [];

    for (const taxByPersonByAirport of this.form.value.taxesByPersonByAirport) {
      airportByTaxes[taxByPersonByAirport.airport] = taxByPersonByAirport;
    }

    for (const legId of this.form.value.legsId) {
      for (const fieldAirport of [
        'airportDepartCode',
        'commercialStopOverAirportCode',
        'fuelStopOverAirportCode',
        'airportDestinationCode'
      ]) {
        if (
          this.legsObj[legId][fieldAirport] &&
          airportByTaxes[this.legsObj[legId][fieldAirport]]
        ) {
          for (const fieldType of ['', 'InCurrency']) {
            taxesByPersonTotal['amount' + fieldType] +=
              airportByTaxes[this.legsObj[legId][fieldAirport]]['amount' + fieldType] *
              airportByTaxes[this.legsObj[legId][fieldAirport]].nbPax;
          }
        }
      }
    }

    for (const fieldType of ['', 'InCurrency']) {
      this.form.get('taxesTotalPax' + fieldType).setValue(taxesByPersonTotal['amount' + fieldType]);
    }

    if (notDefaultCurrency) {
      this.applyCurrencyExchangeRate(
        (this.form.get('taxesByPersonByAirport') as FormArray).at(i).get('amount'),
        this.form.value.taxesByPersonByAirport[i].amountInCurrency
      );
    }
  }

  updatedCurrency(): void {
    for (const field of ['buyingPrice', 'sellingPrice', 'taxesTotalPax', 'cateringByPerson']) {
      if (this.form.value.currency === this.defaultCurrency) {
        this.updatedValue(field, true);
      } else {
        this.form.get(field).setValue('');
        this.form.get(field).updateValueAndValidity();

        this.applyCurrencyExchangeRate(this.form.get(field), this.form.value[field + 'InCurrency']);
      }
    }

    this.updatedTaxesByPersonByLegAccordingToCurrency();

    for (let i = 0; i < (this.form.get('taxesByPersonByLeg') as FormArray).length; i++) {
      this.applyCurrencyExchangeRate(
        (this.form.get('taxesByPersonByLeg') as FormArray).at(i).get('amount'),
        this.form.value.taxesByPersonByLeg[i].amountInCurrency
      );
    }

    for (let i = 0; i < (this.form.get('taxesByPersonByAirport') as FormArray).length; i++) {
      this.applyCurrencyExchangeRate(
        (this.form.get('taxesByPersonByAirport') as FormArray).at(i).get('amount'),
        this.form.value.taxesByPersonByAirport[i].amountInCurrency
      );
    }
  }

  applyCurrencyExchangeRate(formControl: AbstractControl, sourceValue: number): void {
    formControl.setValue(
      this.roundNumber(
        sourceValue / this.todayCurrencyExchangeRate.currencies[this.form.value.currency]
      )
    );
    formControl.updateValueAndValidity();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async createDealInPipedrive(): Promise<void> {
    this.creatingPipedriveDeal = true;

    const pipedriveDealId: number = await this.pipedriveService.createDealFromEnquiry(this.enquiry);

    await this.enquiryService.update({
      id: this.enquiryId,
      pipedriveDealId: pipedriveDealId
    } as IEnquiry);

    this.creatingPipedriveDeal = false;
  }

  async updateDealInPipedrive(): Promise<void> {
    this.creatingPipedriveDeal = true;

    if (this.enquiry.pipedriveDealId) {
      try {
        if (this.enquiry.status && this.enquiry.pipedriveDealId) {
          switch (this.enquiry.status) {
            case EnumEnquiryStatus.draft:
              await this.pipedriveService.openDeal(this.enquiry);
              break;
            case EnumEnquiryStatus.cancelled:
              await this.pipedriveService.lostDeal(this.enquiry);
              break;
            case EnumEnquiryStatus.confirmed:
              const confirmedCotations: IEnquiryCotation[] = [];

              for (const enquiryCotation of this.enquiryCotations) {
                if (this.selectedConfirmedCotationsId.includes(enquiryCotation.id)) {
                  confirmedCotations.push(enquiryCotation);
                }
              }

              await this.pipedriveService.wonDeal(this.enquiry, confirmedCotations);
              break;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    this.creatingPipedriveDeal = false;
  }

  toggleConfirmedCotation(cotationId: string): void {
    const index: number = this.selectedConfirmedCotationsId.indexOf(cotationId);

    if (index === -1) {
      this.selectedConfirmedCotationsId.push(cotationId);
    } else {
      this.selectedConfirmedCotationsId.splice(index, 1);
    }
  }

  async saveConfirmedCotations(): Promise<void> {
    window['$'](this.modalConfirmedElement.nativeElement).modal('hide');

    const cotationAndOfferIds: {
      offerId: string;
      cotation: IEnquiryCotation;
    }[] = [];
    for (const enquiryCotation of this.enquiryCotations) {
      if (this.selectedConfirmedCotationsId.includes(enquiryCotation.id)) {
        cotationAndOfferIds.push({
          offerId: enquiryCotation.offerId,
          cotation: enquiryCotation
        });
      }
    }

    if (cotationAndOfferIds.length) {
      await this.changeCotationsStatus(cotationAndOfferIds, EnumEnquiryCotationStatus.confirmed);
    }

    const data: IEnquiry = {
      status: EnumEnquiryStatus.confirmed,
      cotationConfirmedIds: this.selectedConfirmedCotationsId,
      reasonCancelled: null
    } as IEnquiry;

    this.setNewStatusToEnquiry(data);
  }

  updateItineraryIdOfCotation(): void {
    for (const legId of this.form.value.legsId) {
      this.updateLegsId(legId);
    }
    this.form.get('legsId').setValue([]);
    this.form.get('legsId').updateValueAndValidity();
  }

  updateTaxesByPersonType(isTaxesByPersonType: 'leg' | 'airport'): void {
    this.form.get('isTaxesByPersonType').setValue(isTaxesByPersonType);

    switch (this.form.value.isTaxesByPersonType) {
      case 'leg':
        for (let i = 0; i < this.form.value.taxesByPersonByLeg.length; i++) {
          this.updatedTaxesByPersonByLeg(i, true);
        }
        break;
      case 'airport':
        for (let i = 0; i < this.form.value.taxesByPersonByAirport.length; i++) {
          this.updatedTaxesByPersonByAirport(i);
        }

        break;
    }
  }

  async loadBankAccounts(): Promise<void> {
    const docs = await this.remoteService.getAllDocuments('bankAccounts', {
      field: 'name',
      direction: 'asc'
    });

    for (const doc of docs) {
      const bankAccount: IBankAccount = doc as IBankAccount;

      this.bankAccountsObj[bankAccount.id] = bankAccount;
    }
  }

  loadTodayCurrencyExchangeRate(): void {
    this.subscriptions.add(
      this.currencyExchangeRateService
        .getToday()
        .subscribe((todayCurrencyExchangeRate: ICurrencyExchangeRate) => {
          this.todayCurrencyExchangeRate = todayCurrencyExchangeRate;
        })
    );
  }

  async reopenEnquiry(): Promise<void> {
    if (this.enquiryId) {
      try {
        await this.loaderService.presentLoader('Envoi en cours...');

        await this.enquiryService.update({
          id: this.enquiryId,
          isCompleted: false,
          dateCompleted: null
        } as IEnquiry);

        await this.loaderService.hideLoaderOnSuccess();
      } catch (err: any) {
        await this.loaderService.hideLoaderOnFailure(err.message);
      }
    }
  }

  loadEnquiryFlights(): void {
    if (this.enquiryId) {
      this.loadingEnquiryFlights = true;

      if (this.subscriptionsFlights) {
        this.subscriptionsFlights.unsubscribe();
      }

      this.subscriptionsFlights = new Subscription();

      this.subscriptionsFlights.add(
        this.enquiryFlightService
          .getAllForEnquiry(this.enquiryId)
          .subscribe((enquiryFlights: IEnquiryFlight[]) => {
            this.enquiryFlights = enquiryFlights;

            this.enquiryFlights.sort((a: IEnquiryFlight, b: IEnquiryFlight) => {
              if (a.departDate < b.departDate) {
                return -1;
              } else if (a.departDate > b.departDate) {
                return 1;
              } else {
                if (a.departTimeLocal < b.departTimeLocal) {
                  return -1;
                } else if (a.departTimeLocal > b.departTimeLocal) {
                  return 1;
                }
              }

              return 0;
            });

            this.loadingEnquiryFlights = false;
          })
      );
    }
  }
}
