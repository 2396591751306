import { Injectable } from '@angular/core';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AirlineFirestoreService extends FirestoreService<IAirline> {
  protected basePath = 'airlines';
}
