<breadcrumbs
    [items]="[
        {
            title: 'Calendrier des vols',
            path: '/admin/flight-calendar'
        }
    ]"
></breadcrumbs>

<app-flights-calendar></app-flights-calendar>
