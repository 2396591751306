import { EnumEnquiryType } from '../enums/enquiry-type.enum';
import { IEnquiryFlight } from './enquiry-flight.interface';
import {
  EnumFlightChecklistTemplateFieldType,
  IFlightChecklistTemplate
} from './flight-checklist-template.interface';
import { IStoreData } from './store-data.interface';

export interface IFlightChecklist extends IStoreData {
  enquiryFlightId: string;
  enquiryId: string;
  enquiryType: EnumEnquiryType;
  fieldData: IFlightChecklistFieldData[];
}

export interface IFlightChecklistFieldData {
  fieldId: string;
  text: string;
  defaultOptionId: string | null;
  finalOptionId: string | null;
  history: IFlightChecklistFieldDataValue[];
}

export interface IFlightChecklistFieldDataValue {
  value: string | null;
  userId?: string;
  userInitials?: string;
  userFullName?: string;
  date?: Date;
}

export const initFlightChecklistFromTemplate = (
  enquiryFlight: IEnquiryFlight,
  flightChecklistTemplate: IFlightChecklistTemplate
): IFlightChecklist => {
  const flightChecklist: IFlightChecklist = {
    enquiryFlightId: enquiryFlight.id,
    enquiryId: enquiryFlight.enquiryId,
    enquiryType: enquiryFlight.enquiryType,
    fieldData: []
  } as IFlightChecklist;

  for (const module of flightChecklistTemplate.modules) {
    for (const field of module.fields) {
      flightChecklist.fieldData.push({
        fieldId: field.id,
        text: field.text,
        defaultOptionId: field.defaultOptionId,
        finalOptionId: field.finalOptionId,
        history: []
      });
    }
  }

  return flightChecklist;
};

export const getFinishedCheckOnTotalForFlight = (
  flightChecklistTemplate: IFlightChecklistTemplate,
  flightChecklist: IFlightChecklist | null
): string => {
  const total: number = getTotalCheckForFlight(flightChecklistTemplate);
  const countFinished: number = getFinishedCheckForFlight(flightChecklistTemplate, flightChecklist);

  return total > 0 ? countFinished + '/' + total : '';
};

export const getTotalCheckForFlight = (
  flightChecklistTemplate: IFlightChecklistTemplate
): number => {
  let count: number = 0;

  for (const module of flightChecklistTemplate.modules) {
    for (const field of module.fields) {
      if (field.type === EnumFlightChecklistTemplateFieldType.select) {
        count++;
      }
    }
  }

  return count;
};

export const getFinishedCheckForFlight = (
  flightChecklistTemplate: IFlightChecklistTemplate,
  flightChecklist: IFlightChecklist | null
): number => {
  let count: number = 0;

  const flightChecklistFieldValues: { [fieldId: string]: string | null } = {};

  if (flightChecklist) {
    for (const fieldData of flightChecklist.fieldData) {
      if (fieldData.history.length) {
        flightChecklistFieldValues[fieldData.fieldId] =
          fieldData.history[fieldData.history.length - 1].value;
      } else {
        flightChecklistFieldValues[fieldData.fieldId] = fieldData.defaultOptionId;
      }
    }
  }

  for (const module of flightChecklistTemplate.modules) {
    for (const field of module.fields) {
      if (
        field.type === EnumFlightChecklistTemplateFieldType.select &&
        field.finalOptionId &&
        typeof flightChecklistFieldValues[field.id] !== 'undefined' &&
        flightChecklistFieldValues[field.id] === field.finalOptionId
      ) {
        count++;
      }
    }
  }

  return count;
};
