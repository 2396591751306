import { Injectable } from '@angular/core';
import { IAircraftCompiled } from 'src/app/interfaces/aircraft-compiled.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AircraftCompiledFirestoreService extends FirestoreService<IAircraftCompiled> {
  protected basePath = 'aircraftsCompiled';
}
