import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from './component-can-deactivate';

@Injectable()
export class CanDeactivateGuard  {
  canDeactivate(component: ComponentCanDeactivate): boolean {
    if (!component.canDeactivate()) {
      if (
        confirm(
          'Vous avez des changements non-sauvegardés ! Si vous quittez cette page, vos changements seront perdus.'
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
