import { IStoreData } from './store-data.interface';

export interface ICrew extends IStoreData {
  airlineId: string;
  type: EnumCrewType;
  gender: EnumCrewGender;
  firstname: string;
  lastname: string;
  phoneNumber: string;
  comment: string;
}

export enum EnumCrewType {
  captain = 'captain',
  coPilot = 'co-pilot',
  stewardess = 'stewardess'
}

export const getCrewTypeLabel = (crewType: EnumCrewType): string => {
  switch (crewType) {
    default:
      return crewType;
    case EnumCrewType.captain:
      return 'Commandant';
    case EnumCrewType.coPilot:
      return 'Copilote';
    case EnumCrewType.stewardess:
      return 'Hôtesse';
  }
};

export enum EnumCrewGender {
  male = 'male',
  female = 'female'
}

export const getCrewGenderLabel = (crewGender: EnumCrewGender): string => {
  switch (crewGender) {
    default:
      return crewGender;
    case EnumCrewGender.male:
      return 'Homme';
    case EnumCrewGender.female:
      return 'Femme';
  }
};

export const getCrewFullname = (crew: ICrew | null): string => {
  return crew ? crew.firstname + ' ' + crew.lastname : '';
};
