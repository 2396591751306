export enum EnumInvoiceProductDescription {
  balance = 'balance',
  downPayment = 'down-payment',
  other = 'other'
}

export const getEnumInvoiceProductDescriptionLabel = (
  description: EnumInvoiceProductDescription
): string => {
  switch (description) {
    default:
      return description;
    case EnumInvoiceProductDescription.balance:
      return 'Solde';
    case EnumInvoiceProductDescription.downPayment:
      return 'Acompte';
    case EnumInvoiceProductDescription.other:
      return 'Autre';
  }
};
