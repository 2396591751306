import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import { getLanguageLabel } from 'src/app/enums/language.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IEmailTemplate } from 'src/app/interfaces/email-template.interface';

@Component({
  selector: 'app-list-email-templates',
  templateUrl: './list-email-templates.component.html',
  styleUrls: ['./list-email-templates.component.scss']
})
export class ListEmailTemplatesComponent implements OnInit {
  getLanguageLabel = getLanguageLabel;

  EnumAcl = EnumAcl;

  rootUrl: string = './';
  currentPagination: string = 'email-templates-list';
  emailTemplates: IEmailTemplate[] = [];

  constructor(public paginationService: PaginationService, private aclService: AclService) {}

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.emailTemplatesList);

    this.paginationService.pagination[this.currentPagination].currentPage = 0;

    this.fetchData();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    await this.paginationService.fetchData(this.currentPagination);
  }
}
