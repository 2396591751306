import { NgModule } from '@angular/core';
import { EnquiryFlightsTableComponent } from './enquiry-flights-table.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CalendarModule } from '../calendar/calendar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    CalendarModule
  ],
  declarations: [EnquiryFlightsTableComponent],
  exports: [EnquiryFlightsTableComponent]
})
export class EnquiryFlightsTableModule {}
