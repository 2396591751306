<breadcrumbs [items]="breadcrumbsItems"></breadcrumbs>

<app-enquiries-list-with-filters
    [userId]="userId"
    [status]="status"
    [filterByPeriod]="filterByPeriod"
    [forceSettings]="true"
    [enquiryType]="enquiryType"
    [cancelledReason]="cancelledReason"
></app-enquiries-list-with-filters>
