import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { IFinancialReport } from 'src/app/interfaces/financial-report.interface';

@Injectable({
  providedIn: 'root'
})
export class FinancialReportsFirestoreService extends FirestoreService<IFinancialReport> {
  protected basePath = 'financialReports';
}
