import { Injectable } from '@angular/core';
import { ICountry } from 'src/app/interfaces/country.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class CountryFirestoreService extends FirestoreService<ICountry> {
  protected basePath = 'countries';
}
