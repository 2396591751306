<breadcrumbs
    [items]="[
        {
            title: 'Liste des encaissements',
            path: '/admin/encaissements/list'
        },
        {
            title: 'Import opérations bancaires',
            path: '/admin/encaissements/import'
        }
    ]"
></breadcrumbs>

<div class="row justify-content-center" *ngIf="!displayEncaissementForm">
    <div class="col-md-6">
        <p>
            Veuillez importer le fichier extrait du compte bancaire.<br />
            Vous pouvez télécharger un exemple
            <a href="assets/exemple_import_encaissements.csv" target="_blank">ici</a>.
        </p>

        <div class="card mb-4">
            <div class="card-body text-center">
                <div class="form-group mb-0">
                    <input
                        type="file"
                        id="fileInput"
                        class="form-control"
                        (change)="changeInputFile($event)"
                        accept="text/csv"
                    />
                </div>
            </div>
        </div>

        <div class="card mb-4" *ngIf="data">
            <div class="card-header">Aperçu des données importées</div>
            <div class="card-body">
                <div class="form-group">
                    <label>Compte iban</label>
                    <div class="form-control fake-input disabled">
                        <span class="fake-input-text" [innerText]="data.ibanAccount"></span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Devise</label>
                    <div class="form-control fake-input disabled">
                        <span class="fake-input-text" [innerText]="data.currency"></span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Date de début</label>
                    <div class="form-control fake-input disabled">
                        <span class="fake-input-text" [innerText]="data.dateStart"></span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Date de fin</label>
                    <div class="form-control fake-input disabled">
                        <span class="fake-input-text" [innerText]="data.dateEnd"></span>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Date d'opération</th>
                                <th>Date de valeur</th>
                                <th>Libellé</th>
                                <th>DEBIT EUR</th>
                                <th>CREDIT EUR</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let operation of data.operations">
                                <td [innerText]="operation.dateOperation"></td>
                                <td [innerText]="operation.dateEffective"></td>
                                <td [innerText]="operation.title"></td>
                                <td [innerText]="operation.debit"></td>
                                <td [innerText]="operation.credit"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6" *ngIf="data">
        <div class="text-center mb-4">
            <p>
                Si tout est bon dans l'aperçu des données, cliquez ci-dessous pour générer les
                encaissements.
            </p>

            <button
                type="button"
                class="btn btn-primary"
                (click)="generateEncaissementsFromImportedData()"
            >
                Générer
            </button>
        </div>
    </div>
</div>
<div class="card" *ngIf="displayEncaissementForm">
    <div class="card-body">
        <h3 class="card-title text-center">Encaissements à valider</h3>
        <form
            (submit)="submitForm()"
            [formGroup]="form"
            (keydown.enter)="submitForm()"
            class="main-form"
        >
            <div class="row" formArrayName="encaissements">
                <div
                    class="col-md-6"
                    [formGroupName]="i"
                    *ngFor="
                        let encaissement of form.get('encaissements')['controls'];
                        let i = index
                    "
                >
                    <fieldset>
                        <legend>Encaissement {{ i + 1 }}</legend>
                        <div class="form-group">
                            <label for="id">ID unique</label>
                            <p class="text-muted">
                                Cet ID permet d'identifier l'opération dans la base de données en
                                cas de ré-import.
                            </p>
                            <input
                                class="form-control"
                                id="id"
                                formControlName="id"
                                type="text"
                                required
                                [class.is-invalid]="
                                    (form.get('encaissements').touched ||
                                        form.get('encaissements').get(i.toString()).get('id')
                                            .touched) &&
                                    form.get('encaissements').get(i.toString()).get('id').status ==
                                        'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.get('encaissements').touched ||
                                            form.get('encaissements').get(i.toString()).get('id')
                                                .touched) &&
                                        form.get('encaissements').get(i.toString()).get('id')
                                            .errors &&
                                        form.get('encaissements').get(i.toString()).get('id')
                                            .errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="name">Libellé</label>
                            <input
                                class="form-control"
                                id="libelle"
                                formControlName="libelle"
                                type="text"
                                required
                                (keyup)="getSuggestionInvoices(i)"
                                [class.is-invalid]="
                                    (form.get('encaissements').touched ||
                                        form.get('encaissements').get(i.toString()).get('libelle')
                                            .touched) &&
                                    form.get('encaissements').get(i.toString()).get('libelle')
                                        .status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.get('encaissements').touched ||
                                            form
                                                .get('encaissements')
                                                .get(i.toString())
                                                .get('libelle').touched) &&
                                        form.get('encaissements').get(i.toString()).get('libelle')
                                            .errors &&
                                        form.get('encaissements').get(i.toString()).get('libelle')
                                            .errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="encaissementTypeId">Type d'encaissement</label>
                            <select
                                class="form-select"
                                id="encaissementTypeId"
                                formControlName="encaissementTypeId"
                                required
                                [class.is-invalid]="
                                    (form.get('encaissements').touched ||
                                        form
                                            .get('encaissements')
                                            .get(i.toString())
                                            .get('encaissementTypeId').touched) &&
                                    form
                                        .get('encaissements')
                                        .get(i.toString())
                                        .get('encaissementTypeId').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            >
                                <option
                                    [value]="encaissementType.id"
                                    *ngFor="let encaissementType of encaissementTypesList"
                                    [innerHtml]="encaissementType.name"
                                ></option>
                            </select>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.get('encaissements').touched ||
                                            form
                                                .get('encaissements')
                                                .get(i.toString())
                                                .get('encaissementTypeId').touched) &&
                                        form
                                            .get('encaissements')
                                            .get(i.toString())
                                            .get('encaissementTypeId').errors &&
                                        form
                                            .get('encaissements')
                                            .get(i.toString())
                                            .get('encaissementTypeId').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="name">Montant</label>
                            <input
                                class="form-control"
                                id="amount"
                                formControlName="amount"
                                type="number"
                                required
                                (keyup)="getSuggestionInvoices(i)"
                                [class.is-invalid]="
                                    (form.get('encaissements').touched ||
                                        form.get('encaissements').get(i.toString()).get('amount')
                                            .touched) &&
                                    form.get('encaissements').get(i.toString()).get('amount')
                                        .status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.get('encaissements').touched ||
                                            form
                                                .get('encaissements')
                                                .get(i.toString())
                                                .get('amount').touched) &&
                                        form.get('encaissements').get(i.toString()).get('amount')
                                            .errors &&
                                        form.get('encaissements').get(i.toString()).get('amount')
                                            .errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="currency">Devise</label>
                            <select
                                class="form-select"
                                id="currency"
                                formControlName="currency"
                                required
                                (keyup)="getSuggestionInvoices(i)"
                                [class.is-invalid]="
                                    (form.get('encaissements').touched ||
                                        form.get('encaissements').get(i.toString()).get('currency')
                                            .touched) &&
                                    form.get('encaissements').get(i.toString()).get('currency')
                                        .status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            >
                                <option
                                    [value]="currency"
                                    *ngFor="let currency of getCurrencies()"
                                    [innerHtml]="
                                        getEnumCurrencySymbol(currency) +
                                        ' (' +
                                        getEnumCurrencyLabel(currency) +
                                        ')'
                                    "
                                ></option>
                            </select>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.get('encaissements').touched ||
                                            form
                                                .get('encaissements')
                                                .get(i.toString())
                                                .get('currency').touched) &&
                                        form.get('encaissements').get(i.toString()).get('currency')
                                            .errors &&
                                        form.get('encaissements').get(i.toString()).get('currency')
                                            .errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="bankAccountId">Compte bancaire</label>
                            <select
                                class="form-select"
                                id="bankAccountId"
                                formControlName="bankAccountId"
                                required
                                [class.is-invalid]="
                                    (form.get('encaissements').touched ||
                                        form
                                            .get('encaissements')
                                            .get(i.toString())
                                            .get('bankAccountId').touched) &&
                                    form.get('encaissements').get(i.toString()).get('bankAccountId')
                                        .status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            >
                                <option
                                    [value]="bankAccount.id"
                                    *ngFor="let bankAccount of bankAccountsList"
                                    [innerHtml]="bankAccount.name"
                                ></option>
                            </select>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.get('encaissements').touched ||
                                            form
                                                .get('encaissements')
                                                .get(i.toString())
                                                .get('bankAccountId').touched) &&
                                        form
                                            .get('encaissements')
                                            .get(i.toString())
                                            .get('bankAccountId').errors &&
                                        form
                                            .get('encaissements')
                                            .get(i.toString())
                                            .get('bankAccountId').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="date">Date</label>
                            <input
                                class="form-control"
                                id="date"
                                formControlName="date"
                                type="date"
                                required
                                [class.is-invalid]="
                                    (form.get('encaissements').touched ||
                                        form.get('encaissements').get(i.toString()).get('date')
                                            .touched) &&
                                    form.get('encaissements').get(i.toString()).get('date')
                                        .status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.get('encaissements').touched ||
                                            form.get('encaissements').get(i.toString()).get('date')
                                                .touched) &&
                                        form.get('encaissements').get(i.toString()).get('date')
                                            .errors &&
                                        form.get('encaissements').get(i.toString()).get('date')
                                            .errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="invoiceId">Facture / Avoir</label>
                            <app-invoice-ref-picker
                                [defaultValue]="form.value.encaissements[i].invoiceId"
                                inputName="invoiceId"
                                (setValueToFormControl)="setValueToFormControl($event)"
                            ></app-invoice-ref-picker>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.get('encaissements').touched ||
                                            form
                                                .get('encaissements')
                                                .get(i.toString())
                                                .get('invoiceId').touched) &&
                                        form.get('encaissements').get(i.toString()).get('invoiceId')
                                            .errors &&
                                        form.get('encaissements').get(i.toString()).get('invoiceId')
                                            .errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>

                            <div class="suggestion-invoices" *ngIf="suggestionInvoices[i].length">
                                <label *ngIf="suggestionInvoices[i].length <= 1"
                                    >Facture suggérée</label
                                >
                                <label *ngIf="suggestionInvoices[i].length > 1"
                                    >Factures suggérées</label
                                >
                                <div class="list-group">
                                    <button
                                        type="button"
                                        class="list-group-item list-group-item-info list-group-item-action"
                                        *ngFor="let suggestionInvoice of suggestionInvoices[i]"
                                        (click)="selectSuggestedInvoice(i, suggestionInvoice)"
                                    >
                                        <span class="icon">+</span>
                                        Facture {{ suggestionInvoice.invoice.ref }} :
                                        {{
                                            formatPrice(
                                                suggestionInvoice.invoice.amountTtcTotal,
                                                suggestionInvoice.invoice.currency
                                            )
                                        }}<br />
                                        Raison(s) :
                                        <ul *ngIf="suggestionInvoice.reasons.length" class="mb-0">
                                            <li
                                                *ngFor="let reason of suggestionInvoice.reasons"
                                                class="reason"
                                                [innerText]="
                                                    getSuggestionInvoiceReasonLabel(reason)
                                                "
                                            ></li>
                                        </ul>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="form-check mb-2">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="is-reconciled"
                                formControlName="isReconciled"
                            />
                            <label class="form-check-label" for="is-reconciled">Est pointé ?</label>
                        </div>

                        <div class="form-group">
                            <label for="comment">Commentaire</label>
                            <textarea
                                class="form-control"
                                id="comment"
                                formControlName="comment"
                                [class.is-invalid]="
                                    (form.touched ||
                                        form.get('encaissements').get(i.toString()).get('comment')
                                            .touched) &&
                                    form.get('encaissements').get(i.toString()).get('comment')
                                        .status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            ></textarea>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            form
                                                .get('encaissements')
                                                .get(i.toString())
                                                .get('comment').touched) &&
                                        form.get('encaissements').get(i.toString()).get('comment')
                                            .errors &&
                                        form.get('encaissements').get(i.toString()).get('comment')
                                            .errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>

            <div class="row justify-content-center text-center mt-4">
                <div class="col-md-4">
                    <button
                        type="submit"
                        class="btn btn-primary btn-block mb-4"
                        [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                        [disabled]="
                            form.get('encaissements').disabled ||
                            !form.get('encaissements').valid ||
                            sending
                        "
                    ></button>
                </div>
            </div>
        </form>
    </div>
</div>
