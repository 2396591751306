import { Injectable } from '@angular/core';
import { IAirlineSupervisor } from 'src/app/interfaces/airline-supervisor.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AirlineSupervisorFirestoreService extends FirestoreService<IAirlineSupervisor> {
  protected basePath = 'airlineSupervisors';
}
