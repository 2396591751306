<div
    *ngIf="errorMessageLastCrawl"
    class="alert alert-warning text-center"
    [innerText]="errorMessageLastCrawl"
></div>

<div *ngIf="errorMessageInErrors" class="alert alert-warning text-center">
    {{ errorMessageInErrors }}<br />

    <button
        type="button"
        class="btn btn-warning"
        (click)="restartCrawlerUrlsInError()"
        [disabled]="updating"
    >
        Réessayer à parcourir ces urls
    </button>

    <div
        class="progress mt-4"
        role="progressbar"
        *ngIf="updating"
        [attr.aria-valuemin]="0"
        [attr.aria-valuenow]="updatingCrawlers.index"
        [attr.aria-valuemax]="updatingCrawlers.total"
    >
        <div
            class="progress-bar progress-bar-striped progress-bar-animated overflow-visible"
            [style.width]="(updatingCrawlers.index / updatingCrawlers.total) * 100 + '%'"
        >
            {{ updatingCrawlers.index + "/" + updatingCrawlers.total }}
        </div>
    </div>
</div>
