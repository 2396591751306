<breadcrumbs
    [items]="
        exportRequestId
            ? [
                  {
                      title: 'Liste des exports',
                      path: '/admin/export-requests/list'
                  },
                  {
                      title: getExportRequestLabel(exportRequest),
                      path: '/admin/export-requests/view/' + exportRequestId
                  },
                  {
                      title: 'Édition d\'un export',
                      path: '/admin/export-requests/edit/' + exportRequestId
                  }
              ]
            : [
                  {
                      title: 'Liste des exports',
                      path: '/admin/export-requests/list'
                  },
                  {
                      title: 'Ajout d\'un export',
                      path: '/admin/export-requests/add'
                  }
              ]
    "
></breadcrumbs>

<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="form-group" *ngIf="getExportRequestTypes().length > 1">
                        <label for="type">Type</label>
                        <select
                            class="form-select"
                            formControlName="type"
                            [class.is-invalid]="
                                (form.touched || form.controls['type'].touched) &&
                                form.controls['type'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="exportRequestType"
                                *ngFor="let exportRequestType of getExportRequestTypes()"
                                [innerText]="getExportRequestTypeLabel(exportRequestType)"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['type'].touched) &&
                                    form.controls['type'].errors &&
                                    form.controls['type'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="filterType">Filtrer par</label>
                        <select
                            class="form-select"
                            formControlName="filterType"
                            [class.is-invalid]="
                                (form.touched || form.controls['filterType'].touched) &&
                                form.controls['filterType'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (change)="updatedFilterType()"
                        >
                            <option
                                [value]="filterType"
                                *ngFor="let filterType of getExportRequestFilterTypes()"
                                [innerText]="getExportRequestFilerTypeLabel(filterType)"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['filterType'].touched) &&
                                    form.controls['filterType'].errors &&
                                    form.controls['filterType'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div
                        class="form-group"
                        *ngIf="
                            form.controls['filterType'].value ===
                            EnumExportRequestFilterType.timePeriod
                        "
                    >
                        <select
                            class="form-select"
                            id="timePeriod"
                            formControlName="timePeriod"
                            [class.is-invalid]="
                                (form.touched || form.controls['timePeriod'].touched) &&
                                form.controls['timePeriod'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="filter.value"
                                *ngFor="let filter of filtersByPeriod"
                                [innerHtml]="filter.title"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['timePeriod'].touched) &&
                                    form.controls['timePeriod'].errors &&
                                    form.controls['timePeriod'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div
                        class="form-group"
                        *ngIf="
                            form.controls['filterType'].value ===
                            EnumExportRequestFilterType.enquiry
                        "
                    >
                        <app-enquiry-ref-picker
                            inputName="enquiryId"
                            [defaultValue]="form.value['enquiryId']"
                            (setValueToFormControl)="setValueToFormControl($event)"
                            [disabled]="form.disabled || sending"
                            [isInvalid]="
                                (form.touched || form.controls['enquiryId'].touched) &&
                                form.controls['enquiryId'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></app-enquiry-ref-picker>
                    </div>

                    <div
                        class="form-group"
                        *ngIf="
                            form.controls['filterType'].value === EnumExportRequestFilterType.client
                        "
                    >
                        <pipedrive-search-organization-input
                            (setValueToFormControl)="setValueToFormControl($event)"
                            inputName="clientId"
                            [defaultValue]="form.value['clientId']"
                            [disabled]="form.disabled || sending"
                            [isInvalid]="
                                (form.touched || form.controls['clientId'].touched) &&
                                form.controls['clientId'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></pipedrive-search-organization-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="
                    exportRequestId
                        ? '/admin/export-requests/view/' + exportRequestId
                        : '/admin/export-requests/list'
                "
                class="btn btn-outline-primary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>
