import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { AircraftFirestoreService } from './aircrafts-firestore.service';
import { IAircraft } from 'src/app/interfaces/aircraft.interface';

@Injectable({
  providedIn: 'root'
})
export class AircraftService {
  constructor(private aircraftFirestoreService: AircraftFirestoreService) {}

  create(aircraft: IAircraft): Promise<string> {
    return this.aircraftFirestoreService.create(aircraft);
  }

  update(aircraft: IAircraft): Promise<string> {
    return this.aircraftFirestoreService.update(aircraft);
  }

  delete(id: string): Promise<void> {
    return this.aircraftFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IAircraft> {
    return this.aircraftFirestoreService.docValue$(id);
  }

  getAll(): Observable<IAircraft[]> {
    return this.aircraftFirestoreService.collectionValues$();
  }

  getAllForAirlineAndModel(airlineId: string, aircraftModelId: string): Observable<IAircraft[]> {
    return this.aircraftFirestoreService.collectionValues$(ref =>
      ref.where('airlineId', '==', airlineId).where('aircraftModelId', '==', aircraftModelId)
    );
  }

  getAllForAirline(airlineId: string): Observable<IAircraft[]> {
    return this.aircraftFirestoreService.collectionValues$(ref =>
      ref.where('airlineId', '==', airlineId)
    );
  }

  getAllForAirlineWithZeroCapacity(airlineId: string): Observable<IAircraft[]> {
    return this.aircraftFirestoreService.collectionValues$(ref =>
      ref
        .where('airlineId', '==', airlineId)
        .where('seatTotal', '==', 0)
        .where('isActive', '==', true)
    );
  }

  getAllForAirlineCountryCodeWithZeroCapacity(
    paginationName: string,
    countryCode: string,
    itemsPerPage: number = 20
  ): Observable<IAircraft[]> {
    return this.aircraftFirestoreService.paginate(
      [
        {
          field: 'airlineCountryCode',
          operator: '==',
          value: countryCode
        },
        {
          field: 'seatTotal',
          operator: '==',
          value: 0
        },
        {
          field: 'isActive',
          operator: '==',
          value: true
        }
      ],
      {
        field: 'airlineId',
        direction: 'asc'
      },
      itemsPerPage,
      paginationName
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.aircraftFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IAircraft[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.aircraftFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }
}
