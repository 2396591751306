<breadcrumbs
    [items]="[
        {
            title: 'Liste des types d\'encaissement',
            path: '/admin/encaissement-types/list'
        },
        {
            title: encaissementType ? encaissementType.name : '',
            path: '/admin/encaissement-types/view/' + encaissementTypeId
        }
    ]"
></breadcrumbs>

<div class="row justify-content-center">
    <div class="col-md-6">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="skeleton-text"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && encaissementType">
                <h3 [innerHtml]="encaissementType.name" class="text-center"></h3>

                <div class="text-center mt-4">
                    <a
                        [routerLink]="'/admin/encaissement-types/edit/' + encaissementType.id"
                        class="btn btn-primary mr-4"
                        *ngIf="hasAclAccess(EnumAcl.encaissementTypesEdit)"
                        >Éditer</a
                    >
                    <button
                        type="button"
                        (click)="delete()"
                        class="btn btn-primary ml-4"
                        *ngIf="hasAclAccess(EnumAcl.encaissementTypesDelete)"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
