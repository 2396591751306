import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { Router } from '@angular/router';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { getContinentLabel, getCountryLabel } from 'src/app/enums/continent-code.enum';

@Component({
  selector: 'app-list-airports',
  templateUrl: './list-airports.component.html',
  styleUrls: ['./list-airports.component.scss']
})
export class ListAirportsComponent implements OnInit {
  EnumAcl = EnumAcl;

  getContinentLabel = getContinentLabel;
  getCountryLabel = getCountryLabel;

  rootUrl: string = './';
  currentPagination: string = 'airports-list';
  countriesList: Array<{
    title: string;
    value: string;
  }> = [];
  airports: IAirport[] = [];
  isLieuDit = false;

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private router: Router
  ) {
    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  async ngOnInit(): Promise<void> {
    this.isLieuDit = !!window.location.href.match('lieux-dits');
    await this.aclService.checkAclAccess(
      this.isLieuDit ? EnumAcl.lieuxDitsList : EnumAcl.airportsList
    );

    this.paginationService.pagination[this.currentPagination].currentPage = 0;

    this.fetchData();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    this.paginationService.pagination[this.currentPagination].conditions.push({
      field: 'isLieuDit',
      operator: '==',
      value: this.isLieuDit
    });

    await this.paginationService.fetchData(this.currentPagination);
  }

  async setValueToFormControl($event: {
    fields: {
      name: string;
      value: any;
    }[];
  }): Promise<void> {
    for (let field of $event.fields) {
      if (field.name === 'airportId') {
        this.router.navigateByUrl('/admin/airports/view/' + field.value);
      }
    }
  }
}
