<breadcrumbs
    [items]="
        enquiry
            ? [
                  {
                      title: enquiry ? getEnquiryBreadcrumbTitle(enquiry) : '',
                      path: '/admin/enquiry/view/' + enquiryId
                  },
                  {
                      title: 'Liste des flight briefs',
                      path: '/admin/flight-briefs/list/enquiry/' + enquiryId
                  }
              ]
            : [
                  {
                      title: 'Liste des flight briefs',
                      path: '/admin/flight-briefs/list/enquiry/' + enquiryId
                  }
              ]
    "
></breadcrumbs>

<div class="mb-4">
    <a
        [routerLink]="
            enquiryId ? '/admin/flight-briefs/add/' + enquiryId : '/admin/flight-briefs/add'
        "
        class="btn btn-primary"
        *ngIf="hasAclAccess(EnumAcl.flightBriefsAdd)"
        >+ Nouveau flight brief</a
    >
</div>

<ng-container *ngIf="paginationService.loading">
    <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th
                        scope="col"
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                        [class.is-sorted]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                            field.code
                        "
                        [class.asc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'asc'
                        "
                        [class.desc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'desc'
                        "
                        [innerHtml]="field.title"
                    ></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let number of [].constructor(10)">
                    <td
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                    >
                        <div class="skeleton-text"></div>
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-container>

<ng-container *ngIf="!paginationService.loading">
    <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th
                        scope="col"
                        class="sortable"
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                        [class.is-sorted]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                            field.code
                        "
                        [class.asc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'asc'
                        "
                        [class.desc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'desc'
                        "
                        (click)="paginationService.sortField(currentPagination, field.code)"
                        [innerHtml]="field.title"
                    ></th>
                </tr>
            </thead>
            <tbody *ngIf="paginationService.loading">
                <tr *ngFor="let number of [].constructor(10)">
                    <td
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                    >
                        <div class="skeleton-text"></div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="!paginationService.loading">
                <tr
                    *ngIf="
                        !paginationService.pagination[currentPagination].data ||
                        !paginationService.pagination[currentPagination].data.length
                    "
                    class="table-warning text-center"
                >
                    <td
                        [attr.colspan]="
                            paginationService.pagination[currentPagination].fields.length + 1
                        "
                    >
                        Aucun flight brief n'a été trouvé.
                    </td>
                </tr>
                <tr
                    *ngFor="let flightBrief of paginationService.pagination[currentPagination].data"
                    [routerLink]="'/admin/flight-briefs/view/' + flightBrief.id"
                    class="clickable"
                >
                    <td [innerHtml]="flightBrief.title"></td>
                    <td [innerHtml]="getLanguageLabel(flightBrief.language)"></td>
                    <td [innerHtml]="flightBrief.created | date : 'dd/MM/yyyy'"></td>
                    <td [innerHtml]="flightBrief.createdByFullname"></td>
                    <ng-container *ngIf="!flightBrief.isSent">
                        <td>À envoyer</td>
                        <td>-</td>
                    </ng-container>
                    <ng-container *ngIf="flightBrief.isSent">
                        <td [innerHtml]="flightBrief.sentDate | date : 'dd/MM/yyyy'"></td>
                        <td [innerHtml]="flightBrief.sentByFullname"></td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>

    <nav aria-label="Liste navigation" class="mt-4 mb-4" *ngIf="!paginationService.loading">
        <ul class="pagination justify-content-center">
            <li
                class="page-item"
                [class.disabled]="paginationService.pagination[currentPagination].currentPage === 0"
            >
                <button
                    type="button"
                    class="page-link"
                    (click)="paginationService.loadPreviousPage(currentPagination)"
                >
                    Précédent
                </button>
            </li>
            <li class="page-item disabled">
                <span
                    class="page-link"
                    [innerHtml]="paginationService.pagination[currentPagination].currentPage + 1"
                ></span>
            </li>
            <li
                class="page-item"
                [class.disabled]="
                    paginationService.pagination[currentPagination].data &&
                    (!paginationService.pagination[currentPagination].data.length ||
                        paginationService.pagination[currentPagination].data.length <
                            paginationService.pagination[currentPagination].limit)
                "
            >
                <button
                    type="button"
                    class="page-link"
                    (click)="paginationService.loadNextPage(currentPagination)"
                >
                    Suivant
                </button>
            </li>
        </ul>
    </nav>
</ng-container>
