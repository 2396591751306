import { EnumContinentCode } from '../enums/continent-code.enum';
import { IStoreData } from './store-data.interface';

export interface IAircraftCompiled extends IStoreData {
  airlineId: string;
  airlineIsActive: boolean;
  airlineTitle: string;
  airlineContinentCode: EnumContinentCode;
  airlineCountryCode: string;
  airlineGeographicalArea: string;
  isAirlineNationalCompany: boolean;
  airlineMustBeContacted: boolean;
  isBlacklisted: boolean;
  reasonBlackListed: string;
  model: string;
  modelTitle: string;
  family: string;
  type: string;
  seatTotal: number;
  count: number;
  classes: string[];
  target: string;
  ageTotal: number;
  ageAverage: number;
  ageCount: number;
  weight: number;
  volume: number;
  isCargo: boolean;
  aircraftsId: string[];
  aircraftModelId: string;
}

export const getAircraftCompiledModelTitle = (aircraftCompiled: IAircraftCompiled): string => {
  if (aircraftCompiled.modelTitle) {
    return aircraftCompiled.modelTitle;
  } else {
    return aircraftCompiled.model;
  }
};
