<breadcrumbs
    [items]="
        airlineId
            ? [
                  {
                      title: 'Liste des compagnies aériennes',
                      path: '/admin/airlines/list'
                  },
                  {
                      title: airline ? airline.title : '',
                      path: '/admin/airlines/view/' + airlineId
                  },
                  {
                      title: 'Ajout d\'un avion',
                      path: '/admin/aircrafts/add'
                  }
              ]
            : aircraftId
            ? [
                  {
                      title: 'Liste des appareils aériens',
                      path: '/admin/aircrafts/list'
                  },
                  {
                      title: aircraft ? aircraftModel?.title + ' - ' + aircraft.serialNumber : '',
                      path: '/admin/aircrafts/view/' + aircraftId
                  },
                  {
                      title: 'Édition d\'un avion',
                      path: '/admin/aircrafts/edit/' + aircraftId
                  }
              ]
            : [
                  {
                      title: 'Liste des appareils aériens',
                      path: '/admin/aircrafts/list'
                  },
                  {
                      title: 'Ajout d\'un avion',
                      path: '/admin/aircrafts/add'
                  }
              ]
    "
></breadcrumbs>

<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="row mb-4">
        <div class="col">
            <button
                type="button"
                [routerLink]="
                    airlineId
                        ? '/admin/airlines/view/' + airlineId
                        : aircraftId
                        ? '/admin/aircrafts/view/' + aircraftId
                        : '/admin/aircrafts/list'
                "
                class="btn btn-outline-primary"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col text-end">
            <button
                type="submit"
                class="btn btn-primary"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col-md-5">
            <div class="card">
                <div class="card-body">
                    <h3 class="card-title text-center">Informations générales</h3>
                    <div class="form-group">
                        <label for="serialNumber">MSN</label>
                        <input
                            class="form-control"
                            id="serialNumber"
                            formControlName="serialNumber"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['serialNumber'].touched) &&
                                form.controls['serialNumber'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['serialNumber'].touched) &&
                                    form.controls['serialNumber'].errors &&
                                    form.controls['serialNumber'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="status">Statut</label>
                        <select
                            class="form-select"
                            id="status"
                            formControlName="status"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['status'].touched) &&
                                form.controls['status'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="status.value"
                                *ngFor="let status of statusList"
                                [innerHtml]="status.title"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['status'].touched) &&
                                    form.controls['status'].errors &&
                                    form.controls['status'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button
                            type="button"
                            class="btn btn-primary btn-xs float-end"
                            (click)="addAircraftModel()"
                        >
                            + Nouveau modèle
                        </button>
                        <label for="aircraftModelId">Modèle</label>
                        <select
                            class="form-select"
                            id="aircraftModelId"
                            formControlName="aircraftModelId"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['aircraftModelId'].touched) &&
                                form.controls['aircraftModelId'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (change)="updateModel()"
                        >
                            <option
                                [value]="aircraftModel.id"
                                *ngFor="let aircraftModel of aircraftModels"
                                [innerHtml]="aircraftModel.title"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['aircraftModelId'].touched) &&
                                    form.controls['aircraftModelId'].errors &&
                                    form.controls['aircraftModelId'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="registration">Dernière Immatriculation</label>
                        <input
                            class="form-control"
                            id="registration"
                            formControlName="registration"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['registration'].touched) &&
                                form.controls['registration'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['registration'].touched) &&
                                    form.controls['registration'].errors &&
                                    form.controls['registration'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <airline-input
                        [disabled]="form.disabled || sending"
                        (setValueToFormControl)="setValueToFormControl($event)"
                        label="Sélection Opérateur"
                        [airlineId]="form.value.airlineId"
                        [airlineTitle]="form.value.airlineTitle"
                        [airlineCountryCode]="form.value.airlineCountryCode"
                        [airlineContinentCode]="form.value.airlineContinentCode"
                        inputNameAirlineId="airlineId"
                        inputNameAirlineTitle="airlineTitle"
                        inputNameAirlineCountryCode="airlineCountryCode"
                        inputNameAirlineContinentCode="airlineContinentCode"
                        inputNameAirlineCountryTitle="airlineCountryTitle"
                        inputNameAirlineContinentTitle="airlineContinentTitle"
                    ></airline-input>

                    <div
                        class="alert alert-danger"
                        *ngIf="
                            (form.touched || form.controls['airlineId'].touched) &&
                            form.controls['airlineId'].errors &&
                            form.controls['airlineId'].errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>

                    <div class="form-group">
                        <label for="firstFlightDate">Date premier vol</label>
                        <input
                            class="form-control"
                            id="firstFlightDate"
                            formControlName="firstFlightDate"
                            type="date"
                            [class.is-invalid]="
                                (form.touched || form.controls['firstFlightDate'].touched) &&
                                form.controls['firstFlightDate'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['firstFlightDate'].touched) &&
                                    form.controls['firstFlightDate'].errors &&
                                    form.controls['firstFlightDate'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="testRegistration">Immatriculation d'essai</label>
                        <input
                            class="form-control"
                            id="testRegistration"
                            formControlName="testRegistration"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['testRegistration'].touched) &&
                                form.controls['testRegistration'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['testRegistration'].touched) &&
                                    form.controls['testRegistration'].errors &&
                                    form.controls['testRegistration'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <fieldset>
                        <legend>Config siège</legend>
                        <div class="form-group">
                            <label for="seatFirst">Classe première</label>
                            <input
                                class="form-control"
                                id="seatFirst"
                                formControlName="seatFirst"
                                type="number"
                                min="0"
                                [class.is-invalid]="
                                    (form.touched || form.controls['seatFirst'].touched) &&
                                    form.controls['seatFirst'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || form.controls['seatFirst'].touched) &&
                                        form.controls['seatFirst'].errors &&
                                        form.controls['seatFirst'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="seatBusiness">Classe affaire</label>
                            <input
                                class="form-control"
                                id="seatBusiness"
                                formControlName="seatBusiness"
                                type="number"
                                min="0"
                                [class.is-invalid]="
                                    (form.touched || form.controls['seatBusiness'].touched) &&
                                    form.controls['seatBusiness'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || form.controls['seatBusiness'].touched) &&
                                        form.controls['seatBusiness'].errors &&
                                        form.controls['seatBusiness'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="seatPremiumEconomy">Classe économique premium</label>
                            <input
                                class="form-control"
                                id="seatPremiumEconomy"
                                formControlName="seatPremiumEconomy"
                                type="number"
                                min="0"
                                [class.is-invalid]="
                                    (form.touched || form.controls['seatPremiumEconomy'].touched) &&
                                    form.controls['seatPremiumEconomy'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            form.controls['seatPremiumEconomy'].touched) &&
                                        form.controls['seatPremiumEconomy'].errors &&
                                        form.controls['seatPremiumEconomy'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="seatEconomy">Classe économique</label>
                            <input
                                class="form-control"
                                id="seatEconomy"
                                formControlName="seatEconomy"
                                type="number"
                                min="0"
                                [class.is-invalid]="
                                    (form.touched || form.controls['seatEconomy'].touched) &&
                                    form.controls['seatEconomy'].status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || form.controls['seatEconomy'].touched) &&
                                        form.controls['seatEconomy'].errors &&
                                        form.controls['seatEconomy'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div class="form-group">
                        <label for="engines">Moteurs</label>
                        <input
                            class="form-control"
                            id="engines"
                            formControlName="engines"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['engines'].touched) &&
                                form.controls['engines'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['engines'].touched) &&
                                    form.controls['engines'].errors &&
                                    form.controls['engines'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="url">Airfleets URL</label>
                        <input
                            class="form-control"
                            id="url"
                            formControlName="url"
                            type="url"
                            [class.is-invalid]="
                                (form.touched || form.controls['url'].touched) &&
                                form.controls['url'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['url'].touched) &&
                                    form.controls['url'].errors &&
                                    form.controls['url'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="is-cargo"
                            formControlName="isCargo"
                            (change)="updateWeightAndVolume()"
                        />
                        <label class="form-check-label" for="is-cargo"
                            >Peut être utilisé pour le cargo</label
                        >
                    </div>
                    <div class="form-group">
                        <label for="homebase">Aéroport d'attache</label>
                        <input
                            class="form-control"
                            id="homebase"
                            formControlName="homebase"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['homebase'].touched) &&
                                form.controls['homebase'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['homebase'].touched) &&
                                    form.controls['homebase'].errors &&
                                    form.controls['homebase'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <!-- <div *ngIf="form.value.isCargo">
            <div class="form-group">
              <label for="weight">Poids (kg)</label>
              <input class="form-control" id="weight" formControlName="weight" type="number" min="0" [class.is-invalid]="(form.touched || form.controls['weight'].touched) && form.controls['weight'].status == 'INVALID' ? 'danger' : ''">

              <div class="invalid-tooltip">
                <div *ngIf="(form.touched || form.controls['weight'].touched) && form.controls['weight'].errors && form.controls['weight'].errors['required']">Ce champ est obligatoire.</div>
              </div>
            </div>
            <div class="form-group">
              <label for="volume">Volume (m<sup>3</sup>)</label>
              <input class="form-control" id="volume" formControlName="volume" type="number" min="0" [class.is-invalid]="(form.touched || form.controls['volume'].touched) && form.controls['volume'].status == 'INVALID' ? 'danger' : ''">

              <div class="invalid-tooltip">
                <div *ngIf="(form.touched || form.controls['volume'].touched) && form.controls['volume'].errors && form.controls['volume'].errors['required']">Ce champ est obligatoire.</div>
              </div>
            </div>
          </div> -->
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="card mb-4">
                <div class="card-body">
                    <h3 class="card-title text-center">Opérateurs de l'appareil</h3>
                    <ul class="list-group" formArrayName="history">
                        <li
                            class="list-group-item"
                            [formGroupName]="i"
                            *ngFor="
                                let oneHistory of form.get('history')['controls'];
                                let i = index
                            "
                        >
                            <strong>Historique {{ i + 1 }}</strong>

                            <button
                                type="button"
                                (click)="deleteOneHistory(i)"
                                class="btn btn-danger float-end"
                                [disabled]="form.disabled || sending"
                            >
                                X
                            </button>

                            <div class="clearfix"></div>

                            <div class="form-group">
                                <label for="registration">Immatriculation</label>
                                <input
                                    class="form-control"
                                    id="registration"
                                    formControlName="registration"
                                    type="text"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            form
                                                .get('history')
                                                .get(i.toString())
                                                .get('registration').touched) &&
                                        form.get('history').get(i.toString()).get('registration')
                                            .status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                form
                                                    .get('history')
                                                    .get(i.toString())
                                                    .get('registration').touched) &&
                                            form
                                                .get('history')
                                                .get(i.toString())
                                                .get('registration').errors &&
                                            form
                                                .get('history')
                                                .get(i.toString())
                                                .get('registration').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="deliveryDate">Date de livraison</label>
                                <input
                                    class="form-control"
                                    id="deliveryDate"
                                    formControlName="deliveryDate"
                                    type="date"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            form
                                                .get('history')
                                                .get(i.toString())
                                                .get('deliveryDate').touched) &&
                                        form.get('history').get(i.toString()).get('deliveryDate')
                                            .status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                form
                                                    .get('history')
                                                    .get(i.toString())
                                                    .get('deliveryDate').touched) &&
                                            form
                                                .get('history')
                                                .get(i.toString())
                                                .get('deliveryDate').errors &&
                                            form
                                                .get('history')
                                                .get(i.toString())
                                                .get('deliveryDate').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>

                            <airline-input
                                [disabled]="form.disabled || sending"
                                (setValueToFormControl)="setValueToFormControl($event)"
                                label="Sélection Opérateur"
                                [airlineId]="form.value.history[i].airlineId"
                                [airlineTitle]="form.value.history[i].airlineTitle"
                                [airlineCountryCode]="form.value.history[i].airlineCountryCode"
                                [airlineContinentCode]="form.value.history[i].airlineContinentCode"
                                [inputNameAirlineId]="'history.' + i + '.airlineId'"
                                [inputNameAirlineTitle]="'history.' + i + '.airlineTitle'"
                                [inputNameAirlineCountryCode]="
                                    'history.' + i + '.airlineCountryCode'
                                "
                                [inputNameAirlineContinentCode]="
                                    'history.' + i + '.airlineContinentCode'
                                "
                                [inputNameAirlineCountryTitle]="
                                    'history.' + i + '.airlineCountryTitle'
                                "
                                [inputNameAirlineContinentTitle]="
                                    'history.' + i + '.airlineContinentTitle'
                                "
                            ></airline-input>

                            <div
                                class="alert alert-danger"
                                *ngIf="
                                    (form.touched ||
                                        form.get('history').get(i.toString()).get('airlineId')
                                            .touched) &&
                                    form.get('history').get(i.toString()).get('airlineId').errors &&
                                    form.get('history').get(i.toString()).get('airlineId').errors[
                                        'required'
                                    ]
                                "
                            >
                                Ce champ est obligatoire.
                            </div>

                            <div class="form-group">
                                <label for="remark">Remarque</label>

                                <input
                                    class="form-control"
                                    id="remark"
                                    formControlName="remark"
                                    type="text"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            form.get('history').get(i.toString()).get('remark')
                                                .touched) &&
                                        form.get('history').get(i.toString()).get('remark')
                                            .status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                form.get('history').get(i.toString()).get('remark')
                                                    .touched) &&
                                            form.get('history').get(i.toString()).get('remark')
                                                .errors &&
                                            form.get('history').get(i.toString()).get('remark')
                                                .errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item text-center">
                            <button
                                type="button"
                                (click)="addOneHistory()"
                                class="btn btn-outline-primary"
                                [disabled]="form.disabled || sending"
                            >
                                + Nouvel historique
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="card mb-4">
                <div class="card-body">
                    <h3 class="card-title text-center">Images</h3>
                    <div class="row">
                        <div
                            class="col-md-6"
                            *ngFor="let imageField of ['imageOutside', 'imageInside']"
                        >
                            <div *ngIf="imageField === 'imageOutside'" class="text-center">
                                Photo extérieur
                            </div>
                            <div *ngIf="imageField === 'imageInside'" class="text-center">
                                Photo intérieur
                            </div>
                            <div class="image-upload-container">
                                <div
                                    class="image-upload-content"
                                    [class.no-image]="!form.value[imageField + 'Url']"
                                    (click)="triggerInputFile(imageField + 'Url')"
                                >
                                    <div
                                        class="image-container"
                                        *ngIf="form.value[imageField + 'Url']"
                                    >
                                        <div
                                            class="image"
                                            [id]="imageField"
                                            [style.background-image]="
                                                'url(' + form.value[imageField + 'Url'] + ')'
                                            "
                                            [style.webkitTransform]="
                                                'scale(' +
                                                form.value[imageField + 'ZoomLevel'] +
                                                ')'
                                            "
                                            [style.transform]="
                                                'scale(' +
                                                form.value[imageField + 'ZoomLevel'] +
                                                ')'
                                            "
                                        ></div>
                                    </div>
                                    <div class="image-upload-legend">
                                        <span *ngIf="!form.disabled">
                                            {{
                                                form.value[imageField + "Url"]
                                                    ? "Éditer l'image"
                                                    : "Ajouter une image"
                                            }}
                                        </span>
                                        <span *ngIf="form.disabled">...</span>
                                    </div>
                                    <input
                                        type="file"
                                        [id]="imageField + 'Url' + 'Input'"
                                        class="form-control"
                                        (change)="changeInputFile(imageField + 'Url', $event)"
                                        accept="image/*"
                                    />
                                </div>
                                <div
                                    class="form-group form-group-range"
                                    *ngIf="form.value[imageField + 'Url']"
                                >
                                    <label>Zoom</label>
                                    <input
                                        type="range"
                                        class="form-control-range"
                                        [formControlName]="imageField + 'ZoomLevel'"
                                        min="0.5"
                                        max="4"
                                        value="1"
                                        step="0.1"
                                    />
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    (click)="deleteImage(imageField + 'Url')"
                                    [disabled]="form.disabled"
                                    *ngIf="form.value[imageField + 'Url']"
                                >
                                    Supprimer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="modal" tabindex="-1" role="dialog" #modalAircraftModelAdd>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Ajout d'un nouveau modèle d'avion</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <app-aircraft-model-edit
                    inModal="true"
                    (getCreatedAircraftModel)="getCreatedAircraftModel($event)"
                    [triggerSaveAicraftModal]="triggerSaveAicraftModal"
                ></app-aircraft-model-edit>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button type="button" class="btn btn-primary" (click)="saveAircraftModel()">
                    Sauvegarder
                </button>
            </div>
        </div>
    </div>
</div>
