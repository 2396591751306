import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {

  rootUrl: string = './'

  constructor() {
    this.rootUrl = window['rootUrl']
  }

  ngOnInit(): void {
  }

  goBack(): void {
		history.back()
	}

}
