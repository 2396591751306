import { Component, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';
import { IEnquiryCotation } from 'src/app/interfaces/enquiry-cotation.interface';
import { EnquiryCotationService } from 'src/app/services/enquiry-cotation/enquiry-cotation.service';
import { RemoteService } from 'src/app/services/remote.service';

import moment from 'moment';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { EnquiryOfferService } from 'src/app/services/enquiry-offer/enquiry-offer.service';
import { IEnquiryOffer } from 'src/app/interfaces/enquiry-offer.interface';
import { EnumEnquiryStatus } from 'src/app/enums/enquiry-status.enum';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';

interface AircraftModelStat {
  aircraftModelId: string;
  model: string;
  modelTitle: string;
  family: string;
  count: number;
}

@Component({
  selector: 'app-statistics-aircraft-models',
  templateUrl: './statistics-aircraft-models.component.html',
  styleUrls: ['./statistics-aircraft-models.component.scss']
})
export class StatisticsAircraftModelsComponent implements OnInit {
  isLogged: boolean = false;
  loading: boolean = true;
  today: string;
  currentYear: number;
  startYear = 2021;

  EnumEnquiryType = EnumEnquiryType;

  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;

  filterPeriod: string = format(new Date(), 'yyyy-MM');
  filtersByPeriod: {
    title: string;
    value: string;
  }[] = [];
  enquiriesObj: { [key: string]: IEnquiry } = {};
  enquiryCotationsObj: { [key: string]: IEnquiryCotation[] } = {};
  stats: {
    [department: string]: {
      aircraftModelsObj: {
        [aircraftModelId: string]: AircraftModelStat;
      };
      data: AircraftModelStat[];
    };
  } = {};
  enquiryTypeSelected: 'all' | EnumEnquiryType = 'all';

  private subscriptions = new Subscription();
  private subscriptionsData = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private enquiryOfferService: EnquiryOfferService,
    private enquiryService: EnquiryService
  ) {
    this.today = moment().format('YYYY-MM-DD');
    this.currentYear = moment().get('year');

    this.generateFilterPeriodOptions();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.remoteService.isLoggedObservable.subscribe(
        (isLogged: boolean) => (this.isLogged = isLogged)
      )
    );

    this.loadData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsData.unsubscribe();
  }

  getEnquiryTypes(): Array<EnumEnquiryType | 'all'> {
    const types: Array<EnumEnquiryType | 'all'> = ['all'];

    return types.concat(Object.values(EnumEnquiryType));
  }

  generateFilterPeriodOptions(): void {
    const beginningOfMonth = moment().startOf('month');

    const startDate = moment(beginningOfMonth.get('year') - 2 + '-12-31');

    for (var m = beginningOfMonth; m.isAfter(startDate); m.add(-1, 'month')) {
      let title: string = m.format('MMMM YY');

      title = title.charAt(0).toUpperCase() + title.slice(1);

      this.filtersByPeriod.push({
        title: title,
        value: m.format('YYYY-MM')
      });
    }

    this.currentYear = moment().get('year');

    for (let dec = 0; dec <= 1; dec++) {
      const lastQuarter: number = dec === 0 ? moment().quarter() : 4;
      const year: number = this.currentYear - dec;

      for (let i = lastQuarter; i >= 1; i--) {
        this.filtersByPeriod.push({
          title: 'Q' + i + ' ' + year,
          value: 'q' + i + '-' + year
        });
      }
    }

    for (let year = this.currentYear; year >= this.startYear; year--) {
      this.filtersByPeriod.push({
        title: 'Année ' + year.toString(),
        value: year.toString()
      });
    }
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      this.resetData();

      this.loadEnquiries();
    } else {
      setTimeout(async () => {
        await this.loadData();
      }, 500);
    }
  }

  loadEnquiriesOffers(): void {
    this.subscriptionsData.add(
      this.enquiryOfferService
        .getAllConfirmedForEnquiries(Object.keys(this.enquiriesObj))
        .subscribe((enquiryOffersGrouped: IEnquiryOffer[][]) => {
          for (const enquiryOffers of enquiryOffersGrouped) {
            for (const enquiryOffer of enquiryOffers) {
              for (const enquiryType of ['all', this.enquiriesObj[enquiryOffer.enquiryId].type]) {
                if (typeof this.stats[enquiryType] === 'undefined') {
                  this.stats[enquiryType] = {
                    aircraftModelsObj: {},
                    data: []
                  };
                }

                if (
                  typeof this.stats[enquiryType].aircraftModelsObj[
                    enquiryOffer.aircraftCompiled.aircraftModelId
                  ] === 'undefined'
                ) {
                  this.stats[enquiryType].aircraftModelsObj[
                    enquiryOffer.aircraftCompiled.aircraftModelId
                  ] = {
                    aircraftModelId: enquiryOffer.aircraftCompiled.aircraftModelId,
                    model: enquiryOffer.aircraftCompiled.model,
                    modelTitle: enquiryOffer.aircraftCompiled.modelTitle.toUpperCase(),
                    family: enquiryOffer.aircraftCompiled.family.toUpperCase(),
                    count: 0
                  };
                }

                this.stats[enquiryType].aircraftModelsObj[
                  enquiryOffer.aircraftCompiled.aircraftModelId
                ].count++;
              }
            }
          }

          for (const enquiryType in this.stats) {
            this.stats[enquiryType].data = Object.values(
              this.stats[enquiryType].aircraftModelsObj
            ).sort((a, b) => {
              return a.count > b.count ? -1 : 1;
            });
          }

          this.loading = false;
        })
    );
  }

  loadEnquiries(): void {
    const splittedPeriod: string[] = this.filterPeriod.split('-');

    if (splittedPeriod.length === 1) {
      this.subscriptionsData.add(
        this.enquiryService
          .getAllEnquiryOfYear(true, parseInt(splittedPeriod[0]) - 2000)
          .subscribe((enquiries: IEnquiry[]) => {
            for (const enquiry of enquiries) {
              if (enquiry.status === EnumEnquiryStatus.confirmed) {
                this.enquiriesObj[enquiry.id] = enquiry;
              }
            }

            this.loadEnquiriesOffers();
          })
      );
    } else if (splittedPeriod.length === 2 && splittedPeriod[0].match('q')) {
      this.subscriptionsData.add(
        this.enquiryService
          .getAllEnquiryOfQuarter(
            true,
            parseInt(splittedPeriod[1]) - 2000,
            parseInt(splittedPeriod[0].replace('q', ''))
          )
          .subscribe((enquiries: IEnquiry[]) => {
            for (const enquiry of enquiries) {
              if (enquiry.status === EnumEnquiryStatus.confirmed) {
                this.enquiriesObj[enquiry.id] = enquiry;
              }
            }

            this.loadEnquiriesOffers();
          })
      );
    } else if (splittedPeriod.length === 2) {
      this.subscriptionsData.add(
        this.enquiryService
          .getAllEnquiryOfMonth(
            true,
            parseInt(splittedPeriod[0]) - 2000,
            parseInt(splittedPeriod[1])
          )
          .subscribe((enquiries: IEnquiry[]) => {
            for (const enquiry of enquiries) {
              if (enquiry.status === EnumEnquiryStatus.confirmed) {
                this.enquiriesObj[enquiry.id] = enquiry;
              }
            }

            this.loadEnquiriesOffers();
          })
      );
    }
  }

  resetData(): void {
    if (this.subscriptionsData) {
      this.subscriptionsData.unsubscribe();
    }

    this.subscriptionsData = new Subscription();

    this.stats = {};
    this.enquiriesObj = {};

    this.loading = true;
  }

  updatedFilterPeriod(): void {
    this.loadData();
  }

  selectEnquiryTypeTab(enquiryType: 'all' | EnumEnquiryType): void {
    this.enquiryTypeSelected = enquiryType;
  }
}
