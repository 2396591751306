<breadcrumbs
    [items]="[
        {
            title: 'Liste des compagnies aériennes',
            path: '/admin/airlines/list'
        },
        airlineSupervisor?.airlineId
            ? {
                  title: airlinesObj[airlineSupervisor.airlineId]
                      ? airlinesObj[airlineSupervisor.airlineId].title
                      : '',
                  path: '/admin/airlines/view/' + airlineSupervisor.airlineId
              }
            : null,
        {
            title: 'Liste des superviseurs',
            path: '/admin/airline-supervisors/list/' + airlineSupervisor?.airlineId
        },
        {
            title: getAirlineSupervisorFullname(airlineSupervisor),
            path: '/admin/airline-supervisors/view/' + airlineSupervisorId
        }
    ]"
></breadcrumbs>

<div class="btn-toolbar justify-content-center mb-4">
    <div class="btn-group">
        <a
            [routerLink]="'/admin/airline-supervisors/edit/' + airlineSupervisorId"
            class="btn btn-primary mr-4"
            *ngIf="hasAclAccess(EnumAcl.airlineSupervisorsEdit)"
            >Éditer</a
        >
        <button
            type="button"
            (click)="delete()"
            class="btn btn-danger ml-4"
            *ngIf="hasAclAccess(EnumAcl.airlineSupervisorsDelete)"
        >
            Supprimer
        </button>
    </div>
</div>

<div class="row justify-content-center">
    <div class="col-md-8">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="skeleton-text"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && airlineSupervisor">
                <h3
                    [innerHtml]="getAirlineSupervisorFullname(airlineSupervisor)"
                    class="text-center"
                ></h3>
                <dl class="row mb-0">
                    <dt class="col-md-6">Compagnie aérienne</dt>
                    <dd class="col-md-6">
                        <a
                            [routerLink]="'/admin/airlines/view/' + airlineSupervisor.airlineId"
                            [innerText]="airlinesObj[airlineSupervisor.airlineId].title"
                            *ngIf="airlinesObj[airlineSupervisor.airlineId]"
                        ></a>
                        <div
                            class="skeleton-text"
                            *ngIf="!airlinesObj[airlineSupervisor.airlineId]"
                        ></div>
                    </dd>
                    <dt class="col-md-6">Numéro de téléphone</dt>
                    <dd
                        class="col-md-6"
                        *ngIf="airlineSupervisor.phoneNumber"
                        [innerText]="airlineSupervisor.phoneNumber"
                    ></dd>
                    <dd class="col-md-6 text-muted" *ngIf="!airlineSupervisor.phoneNumber">Vide</dd>
                    <dt class="col-md-6">Observation</dt>
                    <dd class="col-md-6" [innerText]="airlineSupervisor.comment"></dd>
                </dl>
            </div>
        </div>
    </div>
</div>
