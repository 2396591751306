<div id="loader-container">
    <div class="card">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <fa-icon [icon]="faCheck" class="success-icon"></fa-icon>
        <fa-icon [icon]="faXmark" class="failure-icon"></fa-icon>
        <div id="loader-message"></div>
    </div>
</div>
