import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { IFinancialReportUser } from 'src/app/interfaces/financial-report-user.interface';

@Injectable({
  providedIn: 'root'
})
export class FinancialReportUsersFirestoreService extends FirestoreService<IFinancialReportUser> {
  protected basePath = 'financialReportsByUsers';
}
