import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { AlgoliaService } from 'src/app/services/algolia.service';

@Component({
  selector: 'airline-autocomplete',
  templateUrl: './airline-autocomplete.component.html',
  styleUrls: ['./airline-autocomplete.component.scss']
})
export class AirlineAutocompleteComponent implements OnChanges {
  @Input('placeholder') placeholder: string = 'Rechercher';
  @Input('isInvalid') isInvalid: boolean;
  @Input('inputName') inputName: string;
  @Input('disabled') disabled: boolean = false;
  @Input('noMargin') noMargin: boolean = false;
  @Input('defaultValue') defaultValue: IAirline | null;
  @Output() setValueToFormControl: EventEmitter<{
    fields: {
      name: string;
      value: IAirline;
    }[];
  }> = new EventEmitter();
  value: IAirline | null = null;
  optionsSuggested: IAirline[] = [];
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  // initiated: boolean = false
  rootUrl: string = '../';

  constructor(private algoliaService: AlgoliaService) {
    this.rootUrl = window['rootUrl'];
  }

  ngOnChanges() {
    // if (!this.initiated && this.defaultValue) {
    this.value = this.defaultValue;

    // this.initiated = true
    // }
  }

  updateFormValue(value: IAirline | null): void {
    this.value = value;

    this.setValueToFormControl.emit({
      fields: [
        {
          name: this.inputName,
          value: this.value
        }
      ]
    });

    this.optionsSuggested = [];
  }

  updateAutocomplete(query: string): void {
    this.optionsSuggested = [];

    const conditions = [];

    conditions.push({
      field: 'isActive',
      type: 'boolean',
      value: true
    });

    this.algoliaService
      .searchAirlines(query, conditions)
      .then((airlines: IAirline[]) => {
        this.optionsSuggested = airlines;
      })
      .catch(err => {
        console.log(err);
      });
  }

  clearValue(): void {
    this.optionsSuggested = [];
    this.updateFormValue(null);
    this.updateAutocomplete('');

    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    });
  }
}
