import { EnumEnquiryType } from '../enums/enquiry-type.enum';
import { EnumLanguage } from '../enums/language.enum';
import { IQuotationTrip } from './quotation-trip.interface';
import { IStoreData } from './store-data.interface';

export interface IQuotation extends IStoreData {
  title: string;
  enquiryId: string;
  enquiryType: EnumEnquiryType;
  date: string;
  versionNumber: number;
  language: EnumLanguage;
  clientId: string;
  clientTitle: string;
  contactId: string;
  contactFirstname: string;
  contactLastname: string;
  contactTitle: string;
  contactPhone: string;
  contactEmail: string;
  companyTitle: string;
  agentId: string;
  agentFirstname: string;
  agentLastname: string;
  agentTitle: string;
  agentPhone: string;
  agentEmail: string;
  trips: Array<IQuotationTrip>;
  notes: string;
  aircraftInfos: Array<{
    title: string;
    type: string;
    value: string | number;
  }>;
  priceInfos: Array<{
    title: string;
    type: string;
    value: string | number;
  }>;
  paymentConditions: string;
  paymentConditionsSettings: {
    firstDeposit: {
      percent: number;
      text: string;
    };
    lastDeposit: {
      percent: number;
      daysBefore: number;
      text: string;
    };
  };
  cancellingConditions: string;
  cancellingConditionsSettings: {
    firstStep: {
      percent: number;
      daysBefore: number;
      text: string;
    };
    secondStep: {
      percent: number;
      daysBeforeStart: number;
      daysBeforeEnd: number;
      text: string;
    };
    thirdStep: {
      percent: number;
      daysBeforeStart: number;
      hoursBeforeEnd: number;
      text: string;
    };
    fourthStep: {
      percent: number;
      hoursBefore: number;
      text: string;
    };
  };
  servicesSections: Array<{
    title: string;
    services: Array<{
      title: string;
      checked: boolean;
      comment: string;
    }>;
  }>;
  googleMapStaticUrl: string;
  image1Url: string;
  image2Url: string;
  image3Url: string;
  image1ZoomLevel: number;
  image2ZoomLevel: number;
  image3ZoomLevel: number;
  status: EnumQuotationStatus;
  garantiesAndEngagements: string;
  observationsTrips: string;
  totalAmountPayment: number;
  totalAmountPaymentCurrency: string;
}

export enum EnumQuotationStatus {
  draft = 'draft',
  rejected = 'rejected',
  confirmed = 'confirmed',
  sent = 'sent'
}

export const getQuotationStatusLabel = (status: EnumQuotationStatus): string => {
  switch (status) {
    default:
      return status;
    case EnumQuotationStatus.draft:
      return 'En cours';
    case EnumQuotationStatus.rejected:
      return 'Refusé';
    case EnumQuotationStatus.confirmed:
      return 'Confirmée';
    case EnumQuotationStatus.sent:
      return 'Envoyé';
  }
};
