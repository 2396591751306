import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { CanDeactivateGuard } from './components/can-deactivate/can-deactivate.guard';

import { LoginComponent } from './login/login.component';
import { PasswordForgottenComponent } from './password-forgotten/password-forgotten.component';
import { SetPasswordComponent } from './set-password/set-password.component';

import { Error404Component } from './error404/error404.component';

import { NotAuthorisedComponent } from './not-authorised/not-authorised.component';

import { DashboardComponent } from './admin/dashboard/dashboard.component';

import { EnquiryViewComponent } from './admin/enquiries/enquiry-view/enquiry-view.component';
import { EnquiryEditComponent } from './admin/enquiries/enquiry-edit/enquiry-edit.component';
import { SelectAirlinesComponent } from './admin/enquiries/select-airlines/select-airlines.component';
import { SendEmailsComponent } from './admin/send-emails/send-emails.component';
import { SendMessageToAirlinesComponent } from './admin/enquiries/send-message-to-airlines/send-message-to-airlines.component';
import { ListEnquiriesComponent } from './admin/enquiries/list-enquiries/list-enquiries.component';

import { ListAirlinesComponent } from './admin/airlines/list-airlines/list-airlines.component';
import { AirlineViewComponent } from './admin/airlines/airline-view/airline-view.component';
import { AirlineEditComponent } from './admin/airlines/airline-edit/airline-edit.component';

import { ListAirportsComponent } from './admin/airports/list-airports/list-airports.component';
import { AirportViewComponent } from './admin/airports/airport-view/airport-view.component';
import { AirportEditComponent } from './admin/airports/airport-edit/airport-edit.component';

import { ListAircraftsComponent } from './admin/aircrafts/list-aircrafts/list-aircrafts.component';
import { AircraftViewComponent } from './admin/aircrafts/aircraft-view/aircraft-view.component';
import { AircraftEditComponent } from './admin/aircrafts/aircraft-edit/aircraft-edit.component';
import { ListAircraftsNoCapacityComponent } from './admin/aircrafts/list-aircrafts-no-capacity/list-aircrafts-no-capacity.component';

import { ListAircraftModelsComponent } from './admin/aircrafts/aircraft-models/list-aircraft-models/list-aircraft-models.component';
import { AircraftModelViewComponent } from './admin/aircrafts/aircraft-models/aircraft-model-view/aircraft-model-view.component';
import { AircraftModelEditComponent } from './admin/aircrafts/aircraft-models/aircraft-model-edit/aircraft-model-edit.component';

import { SimulateurListAircraftModelsComponent } from './admin/simulateur-prix/simulateur-aircraft-models/list-simulateur-aircraft-models/list-simulateur-aircraft-models.component';
import { SimulateurAircraftModelViewComponent } from './admin/simulateur-prix/simulateur-aircraft-models/simulateur-aircraft-model-view/simulateur-aircraft-model-view.component';
import { SimulateurAircraftModelEditComponent } from './admin/simulateur-prix/simulateur-aircraft-models/simulateur-aircraft-model-edit/simulateur-aircraft-model-edit.component';

import { ListClientsComponent } from './admin/clients/list-clients/list-clients.component';
import { ClientViewComponent } from './admin/clients/client-view/client-view.component';
import { ClientEditComponent } from './admin/clients/client-edit/client-edit.component';

import { UsersListComponent } from './admin/users/users-list/users-list.component';
import { UsersViewComponent } from './admin/users/users-view/users-view.component';
import { UsersEditComponent } from './admin/users/users-edit/users-edit.component';

import { ListUserGroupsComponent } from './admin/user-groups/list-user-groups/list-user-groups.component';
import { UserGroupViewComponent } from './admin/user-groups/user-group-view/user-group-view.component';
import { UserGroupsEditComponent } from './admin/user-groups/user-groups-edit/user-groups-edit.component';

import { ListEmailTemplatesComponent } from './admin/email-templates/list-email-templates/list-email-templates.component';
import { EmailTemplateViewComponent } from './admin/email-templates/email-template-view/email-template-view.component';
import { EmailTemplateEditComponent } from './admin/email-templates/email-template-edit/email-template-edit.component';

import { ListQuotationsComponent } from './admin/quotations/list-quotations/list-quotations.component';
import { QuotationViewComponent } from './admin/quotations/quotation-view/quotation-view.component';
import { QuotationEditComponent } from './admin/quotations/quotation-edit/quotation-edit.component';

import { ListInvoicesComponent } from './admin/invoices/list-invoices/list-invoices.component';
import { InvoiceViewComponent } from './admin/invoices/invoice-view/invoice-view.component';
import { InvoiceEditComponent } from './admin/invoices/invoice-edit/invoice-edit.component';
import { InvoiceImportComponent } from './admin/invoices/invoice-import/invoice-import.component';

import { MyAccountComponent } from './admin/my-account/my-account.component';
import { MyAccountEditComponent } from './admin/my-account/my-account-edit/my-account-edit.component';
import { EditPasswordComponent } from './admin/my-account/edit-password/edit-password.component';

import { ReportComponent } from './admin/report/report.component';

import { StatisticsClientsComponent } from './admin/statistics/statistics-clients/statistics-clients.component';
import { StatisticsUsersComponent } from './admin/statistics/statistics-users/statistics-users.component';

import { ListEncaissementTypesComponent } from './admin/encaissement-types/list-encaissement-types/list-encaissement-types.component';
import { EncaissementTypeViewComponent } from './admin/encaissement-types/encaissement-type-view/encaissement-type-view.component';
import { EncaissementTypeEditComponent } from './admin/encaissement-types/encaissement-type-edit/encaissement-type-edit.component';

import { BankAccountViewComponent } from './admin/bank-accounts/bank-account-view/bank-account-view.component';
import { BankAccountEditComponent } from './admin/bank-accounts/bank-account-edit/bank-account-edit.component';
import { ListBankAccountsComponent } from './admin/bank-accounts/list-bank-accounts/list-bank-accounts.component';

import { FlightChecklistTemplateViewComponent } from './admin/flight-checklist-templates/flight-checklist-template-view/flight-checklist-template-view.component';
import { FlightChecklistTemplateEditComponent } from './admin/flight-checklist-templates/flight-checklist-template-edit/flight-checklist-template-edit.component';
import { ListFlightChecklistTemplatesComponent } from './admin/flight-checklist-templates/list-flight-checklist-templates/list-flight-checklist-templates.component';

import { EncaissementViewComponent } from './admin/encaissements/encaissement-view/encaissement-view.component';
import { EncaissementEditComponent } from './admin/encaissements/encaissement-edit/encaissement-edit.component';
import { ListEncaissementsComponent } from './admin/encaissements/list-encaissements/list-encaissements.component';
import { EncaissementImportComponent } from './admin/encaissements/encaissement-import/encaissement-import.component';

import { ListCrawlerUrlsComponent } from './admin/crawler-urls/list-crawler-urls/list-crawler-urls.component';
import { CrawlerUrlEditComponent } from './admin/crawler-urls/crawler-url-edit/crawler-url-edit.component';
import { EnquiryPaymentsSummaryComponent } from './admin/finance/finance.component';
import { StatisticsAircraftModelsComponent } from './admin/statistics/statistics-aircraft-models/statistics-aircraft-models.component';

import { ListFlightBriefsComponent } from './admin/flight-briefs/list-flight-briefs/list-flight-briefs.component';
import { FlightBriefViewComponent } from './admin/flight-briefs/flight-brief-view/flight-brief-view.component';
import { FlightBriefEditComponent } from './admin/flight-briefs/flight-brief-edit/flight-brief-edit.component';

import { ListFlightBriefCateringsComponent } from './admin/flight-brief-caterings/list-flight-brief-caterings/list-flight-brief-caterings.component';
import { FlightBriefCateringViewComponent } from './admin/flight-brief-caterings/flight-brief-catering-view/flight-brief-catering-view.component';
import { FlightBriefCateringEditComponent } from './admin/flight-brief-caterings/flight-brief-catering-edit/flight-brief-catering-edit.component';
import { ListBackupsComponent } from './admin/backups/list-backups/list-backups.component';

import { CountryViewComponent } from './admin/countries/country-view/country-view.component';
import { CountryEditComponent } from './admin/countries/country-edit/country-edit.component';
import { ListCountriesComponent } from './admin/countries/list-countries/list-countries.component';

import { InvoiceAddPdfComponent } from './admin/invoices/invoice-add-pdf/invoice-add-pdf.component';

import { ListEmployeeBonusRulesComponent } from './admin/employee-bonus-rules/list-employee-bonus-rules/list-employee-bonus-rules.component';
import { EmployeeBonusRuleViewComponent } from './admin/employee-bonus-rules/employee-bonus-rule-view/employee-bonus-rule-view.component';
import { EmployeeBonusRuleEditComponent } from './admin/employee-bonus-rules/employee-bonus-rule-edit/employee-bonus-rule-edit.component';
import { ListExportRequestsComponent } from './admin/export-requests/list-export-requests/list-export-requests.component';
import { ExportRequestViewComponent } from './admin/export-requests/export-request-view/export-request-view.component';
import { ExportRequestEditComponent } from './admin/export-requests/export-request-edit/export-request-edit.component';
import { EnquiryPaymentsSummaryImportComponent } from './admin/enquiry-payments-summary-import/enquiry-payments-summary-import.component';
import { FlightCalendarDashboardComponent } from './admin/flight-calendar-dashboard/flight-calendar-dashboard.component';
import { ListFbosComponent } from './admin/fbos/list-fbos/list-fbos.component';
import { FboViewComponent } from './admin/fbos/fbo-view/fbo-view.component';
import { FboEditComponent } from './admin/fbos/fbo-edit/fbo-edit.component';
import { ListCrewsComponent } from './admin/crews/list-crews/list-crews.component';
import { CrewViewComponent } from './admin/crews/crew-view/crew-view.component';
import { CrewEditComponent } from './admin/crews/crew-edit/crew-edit.component';
import { ListAirlineSupervisorsComponent } from './admin/airline-supervisors/list-airline-supervisors/list-airline-supervisors.component';
import { AirlineSupervisorViewComponent } from './admin/airline-supervisors/airline-supervisor-view/airline-supervisor-view.component';
import { AirlineSupervisorEditComponent } from './admin/airline-supervisors/airline-supervisor-edit/airline-supervisor-edit.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'password-forgotten', component: PasswordForgottenComponent },
  { path: 'set-password', component: SetPasswordComponent },
  { path: 'public', children: [{ path: 'enquiry/:enquiryId', component: EnquiryViewComponent }] },
  {
    path: 'admin',
    children: [
      { path: '', redirectTo: '/admin/dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      {
        path: 'enquiry',
        children: [
          { path: 'add', component: EnquiryEditComponent, canDeactivate: [CanDeactivateGuard] },
          {
            path: 'add-to-client/:clientId',
            component: EnquiryEditComponent,
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'edit/:enquiryId',
            component: EnquiryEditComponent,
            canDeactivate: [CanDeactivateGuard]
          },
          { path: 'select-airlines/:enquiryId', component: SelectAirlinesComponent },
          {
            path: 'send-message-to-airlines/:enquiryId',
            children: [
              {
                path: '',
                component: SendMessageToAirlinesComponent
              },
              {
                path: ':airlineId',
                component: SendMessageToAirlinesComponent
              }
            ]
          },
          {
            path: 'relance-airline/:enquiryId',
            children: [
              {
                path: '',
                component: SendMessageToAirlinesComponent
              },
              {
                path: ':airlineId',
                component: SendMessageToAirlinesComponent
              }
            ]
          },
          {
            path: 'cancel-email-airline/:enquiryId',
            children: [
              {
                path: '',
                component: SendMessageToAirlinesComponent
              },
              {
                path: ':airlineId',
                component: SendMessageToAirlinesComponent
              }
            ]
          },
          { path: 'view/:enquiryId', component: EnquiryViewComponent },
          {
            path: 'list/user/:userId/status/:status/period/:period/type/:enquiryType/cancelled-reason/:cancelledReason',
            component: ListEnquiriesComponent
          }
        ]
      },
      {
        path: 'airlines',
        children: [
          { path: '', redirectTo: '/admin/airlines/list', pathMatch: 'full' },
          { path: 'list', component: ListAirlinesComponent },
          { path: 'view/:airlineId', component: AirlineViewComponent },
          { path: 'edit/:airlineId', component: AirlineEditComponent },
          { path: 'add', component: AirlineEditComponent }
        ]
      },
      {
        path: 'airports',
        children: [
          { path: '', redirectTo: '/admin/airports/list', pathMatch: 'full' },
          { path: 'list', component: ListAirportsComponent },
          { path: 'view/:airportId', component: AirportViewComponent },
          { path: 'edit/:airportId', component: AirportEditComponent },
          { path: 'add', component: AirportEditComponent }
        ]
      },
      {
        path: 'fbos',
        children: [
          { path: '', redirectTo: '/admin/fbos/list', pathMatch: 'full' },
          {
            path: 'list',
            children: [
              {
                path: '',
                component: ListFbosComponent
              },
              {
                path: ':airportId',
                component: ListFbosComponent
              }
            ]
          },
          { path: 'view/:fboId', component: FboViewComponent },
          { path: 'edit/:fboId', component: FboEditComponent },
          {
            path: 'add',
            children: [
              {
                path: '',
                component: FboEditComponent
              },
              {
                path: ':airportId',
                component: FboEditComponent
              }
            ]
          }
        ]
      },
      {
        path: 'crews',
        children: [
          { path: '', redirectTo: '/admin/crews/list', pathMatch: 'full' },
          {
            path: 'list',
            children: [
              {
                path: '',
                component: ListCrewsComponent
              },
              {
                path: ':airlineId',
                component: ListCrewsComponent
              }
            ]
          },
          { path: 'view/:crewId', component: CrewViewComponent },
          { path: 'edit/:crewId', component: CrewEditComponent },
          {
            path: 'add',
            children: [
              {
                path: '',
                component: CrewEditComponent
              },
              {
                path: ':airlineId',
                component: CrewEditComponent
              }
            ]
          }
        ]
      },
      {
        path: 'airline-supervisors',
        children: [
          { path: '', redirectTo: '/admin/airline-supervisors/list', pathMatch: 'full' },
          {
            path: 'list',
            children: [
              {
                path: '',
                component: ListAirlineSupervisorsComponent
              },
              {
                path: ':airlineId',
                component: ListAirlineSupervisorsComponent
              }
            ]
          },
          { path: 'view/:airlineSupervisorId', component: AirlineSupervisorViewComponent },
          { path: 'edit/:airlineSupervisorId', component: AirlineSupervisorEditComponent },
          {
            path: 'add',
            children: [
              {
                path: '',
                component: AirlineSupervisorEditComponent
              },
              {
                path: ':airlineId',
                component: AirlineSupervisorEditComponent
              }
            ]
          }
        ]
      },
      {
        path: 'lieux-dits',
        children: [
          { path: '', redirectTo: '/admin/lieux-dits/list', pathMatch: 'full' },
          { path: 'list', component: ListAirportsComponent },
          { path: 'view/:airportId', component: AirportViewComponent },
          { path: 'edit/:airportId', component: AirportEditComponent },
          { path: 'add', component: AirportEditComponent }
        ]
      },
      {
        path: 'users',
        children: [
          { path: '', redirectTo: '/admin/users/list', pathMatch: 'full' },
          { path: 'list', component: UsersListComponent },
          { path: 'view/:userId', component: UsersViewComponent },
          { path: 'edit/:userId', component: UsersEditComponent },
          { path: 'add', component: UsersEditComponent }
        ]
      },
      {
        path: 'user-groups',
        children: [
          { path: '', redirectTo: '/admin/user-groups/list', pathMatch: 'full' },
          { path: 'list', component: ListUserGroupsComponent },
          { path: 'view/:userGroupId', component: UserGroupViewComponent },
          { path: 'edit/:userGroupId', component: UserGroupsEditComponent },
          { path: 'add', component: UserGroupsEditComponent }
        ]
      },
      {
        path: 'aircrafts',
        children: [
          { path: '', redirectTo: '/admin/aircrafts/list', pathMatch: 'full' },
          { path: 'list', component: ListAircraftsComponent },
          { path: 'list-no-capacity', component: ListAircraftsNoCapacityComponent },
          { path: 'view/:aircraftId', component: AircraftViewComponent },
          { path: 'edit/:aircraftId', component: AircraftEditComponent },
          {
            path: 'add',
            children: [
              { path: '', component: AircraftEditComponent },
              { path: ':airlineId', component: AircraftEditComponent }
            ]
          }
        ]
      },
      {
        path: 'aircraft-models',
        children: [
          { path: '', redirectTo: '/admin/aircraft-models/list', pathMatch: 'full' },
          { path: 'list', component: ListAircraftModelsComponent },
          { path: 'view/:aircraftModelId', component: AircraftModelViewComponent },
          { path: 'edit/:aircraftModelId', component: AircraftModelEditComponent },
          { path: 'add', component: AircraftModelEditComponent }
        ]
      },
      {
        path: 'simulateur-aircraft-models',
        children: [
          { path: '', redirectTo: '/admin/simulateur-aircraft-models/list', pathMatch: 'full' },
          { path: 'list', component: SimulateurListAircraftModelsComponent },
          {
            path: 'view/:simulateurAircraftModelId',
            component: SimulateurAircraftModelViewComponent
          },
          {
            path: 'edit/:simulateurAircraftModelId',
            component: SimulateurAircraftModelEditComponent
          },
          { path: 'add', component: SimulateurAircraftModelEditComponent }
        ]
      },
      {
        path: 'encaissement-types',
        children: [
          { path: '', redirectTo: '/admin/encaissement-types/list', pathMatch: 'full' },
          { path: 'list', component: ListEncaissementTypesComponent },
          { path: 'view/:encaissementTypeId', component: EncaissementTypeViewComponent },
          { path: 'edit/:encaissementTypeId', component: EncaissementTypeEditComponent },
          { path: 'add', component: EncaissementTypeEditComponent }
        ]
      },
      {
        path: 'bank-accounts',
        children: [
          { path: '', redirectTo: '/admin/bank-accounts/list', pathMatch: 'full' },
          { path: 'list', component: ListBankAccountsComponent },
          { path: 'view/:bankAccountId', component: BankAccountViewComponent },
          { path: 'edit/:bankAccountId', component: BankAccountEditComponent },
          { path: 'add', component: BankAccountEditComponent }
        ]
      },
      {
        path: 'flight-checklist-templates',
        children: [
          { path: '', redirectTo: '/admin/flight-checklist-templates/list', pathMatch: 'full' },
          { path: 'list', component: ListFlightChecklistTemplatesComponent },
          {
            path: 'view/:flightChecklistTemplateId',
            component: FlightChecklistTemplateViewComponent
          },
          {
            path: 'edit/:flightChecklistTemplateId',
            component: FlightChecklistTemplateEditComponent
          },
          { path: 'add', component: FlightChecklistTemplateEditComponent }
        ]
      },
      {
        path: 'crawler-urls',
        children: [
          { path: '', redirectTo: '/admin/crawler-urls/list', pathMatch: 'full' },
          { path: 'list', component: ListCrawlerUrlsComponent },
          { path: 'add', component: CrawlerUrlEditComponent },
          { path: 'edit/:crawlerUrlId', component: CrawlerUrlEditComponent }
        ]
      },
      {
        path: 'encaissements',
        children: [
          { path: '', redirectTo: '/admin/encaissements/list', pathMatch: 'full' },
          {
            path: 'list',
            children: [
              {
                path: '',
                component: ListEncaissementsComponent
              },
              {
                path: 'invoice/:invoiceId',
                component: ListEncaissementsComponent
              }
            ]
          },
          { path: 'view/:encaissementId', component: EncaissementViewComponent },
          { path: 'edit/:encaissementId', component: EncaissementEditComponent },
          {
            path: 'add',
            children: [
              {
                path: '',
                component: EncaissementEditComponent
              },
              {
                path: 'invoice/:invoiceId',
                component: EncaissementEditComponent
              }
            ]
          },
          {
            path: 'import',
            component: EncaissementImportComponent
          }
        ]
      },
      {
        path: 'clients',
        children: [
          { path: '', redirectTo: '/admin/clients/list', pathMatch: 'full' },
          { path: 'list', component: ListClientsComponent },
          { path: 'view/:clientId', component: ClientViewComponent },
          { path: 'edit/:clientId', component: ClientEditComponent },
          { path: 'add', component: ClientEditComponent }
        ]
      },
      {
        path: 'email-templates',
        children: [
          { path: '', redirectTo: '/admin/email-templates/list', pathMatch: 'full' },
          { path: 'list', component: ListEmailTemplatesComponent },
          { path: 'view/:emailTemplateId', component: EmailTemplateViewComponent },
          { path: 'edit/:emailTemplateId', component: EmailTemplateEditComponent },
          { path: 'add', component: EmailTemplateEditComponent }
        ]
      },
      {
        path: 'quotations',
        children: [
          { path: '', redirectTo: '/admin/quotations/list', pathMatch: 'full' },
          {
            path: 'list',
            children: [
              { path: '', component: ListQuotationsComponent },
              { path: 'enquiry/:enquiryId', component: ListQuotationsComponent }
            ]
          },
          { path: 'view/:quotationId', component: QuotationViewComponent },
          { path: 'edit/:quotationId', component: QuotationEditComponent },
          {
            path: 'add',
            children: [
              { path: '', component: QuotationEditComponent },
              {
                path: ':enquiryId',
                children: [
                  {
                    path: '',
                    component: QuotationEditComponent
                  },
                  {
                    path: ':offerId',
                    children: [
                      {
                        path: '',
                        component: QuotationEditComponent
                      },
                      {
                        path: ':cotationId',
                        component: QuotationEditComponent
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'invoices',
        children: [
          { path: '', redirectTo: '/admin/invoices/list', pathMatch: 'full' },
          {
            path: 'list',
            children: [
              { path: '', component: ListInvoicesComponent },
              { path: 'enquiry/:enquiryId', component: ListInvoicesComponent }
            ]
          },
          { path: 'import', component: InvoiceImportComponent },
          { path: 'view/:invoiceId', component: InvoiceViewComponent },
          { path: 'edit/:invoiceId', component: InvoiceEditComponent },
          {
            path: 'duplicate/:invoiceId',
            children: [
              {
                path: '',
                component: InvoiceEditComponent
              },
              {
                path: 'proforma-to-final-invoice',
                component: InvoiceEditComponent
              },
              {
                path: 'final-invoice-to-credit-note',
                component: InvoiceEditComponent
              }
            ]
          },
          {
            path: 'request-final-invoice',
            children: [
              {
                path: '',
                component: InvoiceEditComponent
              },
              {
                path: 'enquiry/:enquiryId',
                component: InvoiceEditComponent
              },
              {
                path: 'invoice/:invoiceId',
                component: InvoiceEditComponent
              }
            ]
          },
          {
            path: 'add-proforma',
            children: [
              {
                path: '',
                component: InvoiceEditComponent
              },
              {
                path: ':enquiryId',
                component: InvoiceEditComponent
              }
            ]
          },
          {
            path: 'add-final-invoice',
            children: [
              {
                path: '',
                component: InvoiceEditComponent
              },
              {
                path: ':enquiryId',
                component: InvoiceEditComponent
              }
            ]
          },
          {
            path: 'add-credit-note-invoice',
            children: [
              {
                path: '',
                component: InvoiceEditComponent
              },
              {
                path: ':enquiryId',
                component: InvoiceEditComponent
              }
            ]
          },
          {
            path: 'add-credit-note-proforma',
            children: [
              {
                path: '',
                component: InvoiceEditComponent
              },
              {
                path: ':enquiryId',
                component: InvoiceEditComponent
              }
            ]
          },
          {
            path: 'request-credit-note-invoice',
            children: [
              {
                path: '',
                component: InvoiceEditComponent
              },
              {
                path: 'enquiry/:enquiryId',
                component: InvoiceEditComponent
              },
              {
                path: 'invoice/:invoiceId',
                component: InvoiceEditComponent
              }
            ]
          },
          {
            path: 'add-purchase-invoice',
            children: [
              {
                path: '',
                component: InvoiceAddPdfComponent
              },
              {
                path: ':enquiryId',
                component: InvoiceAddPdfComponent
              }
            ]
          },
          {
            path: 'add-purchase-credit-note',
            children: [
              {
                path: '',
                component: InvoiceAddPdfComponent
              },
              {
                path: ':enquiryId',
                component: InvoiceAddPdfComponent
              }
            ]
          },
          {
            path: 'add',
            children: [
              { path: '', component: InvoiceEditComponent },
              {
                path: ':enquiryId',
                component: InvoiceEditComponent
              }
            ]
          }
        ]
      },
      {
        path: 'send-emails/:emailTemplateCode/:itemId',
        component: SendEmailsComponent
      },
      {
        path: 'my-account',
        children: [
          {
            path: '',
            component: MyAccountComponent
          },
          {
            path: 'edit',
            component: MyAccountEditComponent
          },
          {
            path: 'edit-password',
            component: EditPasswordComponent
          }
        ]
      },
      {
        path: 'reports',
        children: [
          { path: '', component: ReportComponent },
          { path: ':year', component: ReportComponent }
        ]
      },
      {
        path: 'finance',
        children: [
          {
            path: '',
            component: EnquiryPaymentsSummaryComponent
          },
          {
            path: 'import-enquiry-payments-summaries',
            component: EnquiryPaymentsSummaryImportComponent
          }
        ]
      },

      {
        path: 'statistics',
        children: [
          { path: '', component: StatisticsUsersComponent },
          { path: 'client/:clientId', component: StatisticsClientsComponent },
          { path: 'user/:userId', component: StatisticsUsersComponent },
          { path: 'current-user', component: StatisticsUsersComponent },
          { path: 'aircraft-models', component: StatisticsAircraftModelsComponent }
        ]
      },
      {
        path: 'flight-briefs',
        children: [
          { path: '', redirectTo: '/admin/flight-briefs/list', pathMatch: 'full' },
          {
            path: 'list',
            children: [
              { path: '', component: ListFlightBriefsComponent },
              { path: 'enquiry/:enquiryId', component: ListFlightBriefsComponent }
            ]
          },
          { path: 'view/:flightBriefId', component: FlightBriefViewComponent },
          { path: 'edit/:flightBriefId', component: FlightBriefEditComponent },
          {
            path: 'add',
            children: [
              { path: '', component: FlightBriefEditComponent },
              {
                path: 'from-quotation/:quotationId',
                component: FlightBriefEditComponent
              },
              {
                path: ':enquiryId',
                component: FlightBriefEditComponent
              }
            ]
          }
        ]
      },
      {
        path: 'flight-brief-caterings',
        children: [
          { path: '', redirectTo: '/admin/flight-brief-caterings/list', pathMatch: 'full' },
          {
            path: 'list',
            component: ListFlightBriefCateringsComponent
          },
          { path: 'view/:flightBriefCateringId', component: FlightBriefCateringViewComponent },
          { path: 'edit/:flightBriefCateringId', component: FlightBriefCateringEditComponent },
          {
            path: 'add',
            component: FlightBriefCateringEditComponent
          }
        ]
      },
      {
        path: 'backups',
        children: [
          {
            path: '',
            component: ListBackupsComponent
          }
        ]
      },
      {
        path: 'countries',
        children: [
          { path: '', redirectTo: '/admin/countries/list', pathMatch: 'full' },
          { path: 'list', component: ListCountriesComponent },
          { path: 'view/:countryId', component: CountryViewComponent },
          { path: 'edit/:countryId', component: CountryEditComponent },
          { path: 'add', component: CountryEditComponent }
        ]
      },
      {
        path: 'employee-bonus-rules',
        children: [
          { path: '', redirectTo: '/admin/employee-bonus-rules/list', pathMatch: 'full' },
          { path: 'list', component: ListEmployeeBonusRulesComponent },
          { path: 'view/:employeeBonusRuleId', component: EmployeeBonusRuleViewComponent },
          { path: 'edit/:employeeBonusRuleId', component: EmployeeBonusRuleEditComponent },
          { path: 'add', component: EmployeeBonusRuleEditComponent }
        ]
      },
      {
        path: 'export-requests',
        children: [
          { path: '', redirectTo: '/admin/export-requests/list', pathMatch: 'full' },
          { path: 'list', component: ListExportRequestsComponent },
          { path: 'view/:exportRequestId', component: ExportRequestViewComponent },
          { path: 'edit/:exportRequestId', component: ExportRequestEditComponent },
          { path: 'add', component: ExportRequestEditComponent }
        ]
      },
      {
        path: 'flight-calendar',
        component: FlightCalendarDashboardComponent
      }
    ],
    canActivate: [AuthGuardService]
  },
  { path: 'not-authorised', component: NotAuthorisedComponent },
  {
    path: '**',
    component: Error404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
