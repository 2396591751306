import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AirlineAutocompleteComponent } from './airline-autocomplete.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [AirlineAutocompleteComponent],
  exports: [AirlineAutocompleteComponent]
})
export class AirlineAutocompleteModule {}
