import { Injectable } from '@angular/core';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AircraftModelFirestoreService extends FirestoreService<IAircraftModel> {
  protected basePath = 'aircraftModels';
}
