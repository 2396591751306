import { Injectable } from '@angular/core';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class EnquiryFirestoreService extends FirestoreService<IEnquiry> {
  protected basePath = 'enquiries';
}
