export enum EnumCrawlerUrlSource {
  acukwik = 'acukwik',
  airfleets = 'airfleets',
  planespotters = 'planespotters'
}

export const getEnumCrawlerUrlSourceLabel = (source: EnumCrawlerUrlSource): string => {
  switch (source) {
    default:
      return source;
    case EnumCrawlerUrlSource.acukwik:
      return 'Acukwik';
    case EnumCrawlerUrlSource.airfleets:
      return 'Airfleets';
    case EnumCrawlerUrlSource.planespotters:
      return 'Planespotters';
  }
};
