import moment from 'moment';
import { EnumAircraftStatus } from '../enums/aircraft-status.enum';
import { IStoreData } from './store-data.interface';

export interface IAircraft extends IStoreData {
  url: string;
  airlineTitle: string;
  airlineId: string;
  airlineContinentCode: string;
  airlineContinentTitle: string;
  airlineCountryCode: string;
  airlineCountryTitle: string;
  status: EnumAircraftStatus;
  isActive: boolean;
  registration: string;
  serialNumber: string;
  model: string;
  type: string;
  firstFlightDate: string;
  testRegistration: string;
  classes: EnumAircraftClass[];
  seatTotal: number;
  seatFirst: number;
  seatBusiness: number;
  seatPremiumEconomy: number;
  seatEconomy: number;
  engines: string;
  history: {
    registration: string;
    deliveryDate: string;
    airlineTitle: string;
    airlineId: string;
    airlineContinentCode: string;
    airlineContinentTitle: string;
    airlineCountryCode: string;
    airlineCountryTitle: string;
    remark: string;
  }[];
  weight: number;
  volume: number;
  isCargo: boolean;
  isPassengers: boolean;
  aircraftModelId: string;
  homebase: string;
  imageOutsideUrl: string;
  imageOutsideZoomLevel: number;
  imageInsideUrl: string;
  imageInsideZoomLevel: number;
}

export enum EnumAircraftClass {
  first = 'F',
  business = 'C',
  premiumEconomy = 'W',
  economy = 'Y'
}

export const getAircraftAge = (aircraft: IAircraft): number | null => {
  if (aircraft.firstFlightDate) {
    return Math.round(moment().diff(moment(aircraft.firstFlightDate), 'years', true) * 10) / 10;
  } else if (aircraft.history.length && aircraft.history[0].deliveryDate) {
    return (
      Math.round(moment().diff(moment(aircraft.history[0].deliveryDate), 'years', true) * 10) / 10
    );
  }
  return null;
};

export const getAircraftYom = (aircraft: IAircraft): number | null => {
  if (aircraft.firstFlightDate) {
    return moment(aircraft.firstFlightDate).get('year');
  }

  return null;
};
