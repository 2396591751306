<breadcrumbs
    [items]="[
        {
            title: 'Liste des appareils aériens',
            path: '/admin/aircrafts/list'
        },
        {
            title: 'Appareils sans capacité',
            path: '/admin/aircrafts/list-no-capacity'
        }
    ]"
></breadcrumbs>

<div class="btn-toolbar mb-4" role="toolbar">
    <a
        routerLink="/admin/aircrafts"
        class="btn btn-secondary btn-sm"
        *ngIf="hasAclAccess(EnumAcl.aircraftsList)"
        ><fa-icon [icon]="faArrowLeft"></fa-icon> Retour liste</a
    >
</div>

<div class="row justify-content-center">
    <div class="col-md-6">
        <airline-input
            (setValueToFormControl)="setValueToFormControl($event)"
            label="Filtrer par compagnie aérienne"
            inputNameAirlineId="airlineId"
            inputNameAirlineTitle="airlineTitle"
            inputNameAirlineCountryCode="airlineCountryCode"
            inputNameAirlineContinentCode="airlineContinentCode"
            inputNameAirlineCountryTitle="airlineCountryTitle"
            inputNameAirlineContinentTitle="airlineContinentTitle"
        ></airline-input>
    </div>
</div>

<div class="table-responsive" *ngIf="selectedAirlineId || selectedCountryCode">
    <table class="table">
        <colgroup>
            <col [width]="200" />
        </colgroup>
        <thead>
            <tr>
                <th *ngIf="selectedAirlineId">{{ aircrafts.length }} appareils</th>
                <th *ngIf="!selectedAirlineId">Appareils</th>
                <th class="text-center" colspan="5">Sièges</th>
            </tr>
            <tr>
                <th></th>
                <th class="text-center">Eco</th>
                <th class="text-center">Premium eco</th>
                <th class="text-center">Business</th>
                <th class="text-center">Première</th>
                <th class="text-center">Total</th>
            </tr>
        </thead>
        <tbody *ngIf="loading">
            <tr *ngFor="let line of [].constructor(10)">
                <td *ngFor="let cell of [].constructor(7)">
                    <div class="skeleton-text"></div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="!loading">
            <tr *ngIf="!aircrafts.length" class="table-warning text-center">
                <td colspan="7">Aucun avion n'a été trouvé.</td>
            </tr>
            <ng-container *ngFor="let aircraft of aircrafts">
                <tr>
                    <td rowspan="2">
                        <dl class="mb-0">
                            <dt>MSN</dt>
                            <dd>
                                {{ aircraft.serialNumber }}
                                <a
                                    [routerLink]="'/admin/aircrafts/view/' + aircraft.id"
                                    target="_blank"
                                    ><fa-icon [icon]="faEye"></fa-icon
                                ></a>
                            </dd>
                            <dt *ngIf="!selectedAirlineId">Compagnie aérienne</dt>
                            <dd *ngIf="!selectedAirlineId" [innerText]="aircraft.airlineTitle"></dd>
                            <dd>
                                {{ aircraft.serialNumber }}
                                <a
                                    [routerLink]="'/admin/aircrafts/view/' + aircraft.id"
                                    target="_blank"
                                    ><fa-icon [icon]="faEye"></fa-icon
                                ></a>
                            </dd>
                            <dd *ngIf="aircraft.isCargo" class="text-info">Peut être cargo</dd>
                            <dt>Modèle</dt>
                            <dd>
                                {{
                                    aircraftModelsObj[aircraft.model]
                                        ? aircraftModelsObj[aircraft.model].title
                                        : aircraft.modelTitle?.toUpperCase()
                                }}<br />
                                {{ aircraft.type }}
                            </dd>
                            <dt>Immatriculation</dt>
                            <dd [innerText]="aircraft.registration"></dd>
                            <dt *ngIf="aircraft.url">Airfleet</dt>
                            <dd *ngIf="aircraft.url">
                                <a
                                    [href]="aircraft.url"
                                    class="btn btn-primary btn-sm"
                                    target="_blank"
                                    ><fa-icon [icon]="faExternalLink"></fa-icon> Lien</a
                                >
                            </dd>
                        </dl>
                    </td>
                    <td class="text-center">
                        <input
                            type="number"
                            class="form-control"
                            [(ngModel)]="aircraft.seatEconomy"
                            (keyup)="updatedAircraftSeat(aircraft)"
                            (change)="updatedAircraftSeat(aircraft)"
                            step="1"
                            min="0"
                            [placeholder]="EnumAircraftClass.economy"
                        />
                    </td>
                    <td class="text-center">
                        <input
                            type="number"
                            class="form-control"
                            [(ngModel)]="aircraft.seatPremiumEconomy"
                            (keyup)="updatedAircraftSeat(aircraft)"
                            (change)="updatedAircraftSeat(aircraft)"
                            step="1"
                            min="0"
                            [placeholder]="EnumAircraftClass.premiumEconomy"
                        />
                    </td>
                    <td class="text-center">
                        <input
                            type="number"
                            class="form-control"
                            [(ngModel)]="aircraft.seatBusiness"
                            (keyup)="updatedAircraftSeat(aircraft)"
                            (change)="updatedAircraftSeat(aircraft)"
                            step="1"
                            min="0"
                            [placeholder]="EnumAircraftClass.business"
                        />
                    </td>
                    <td class="text-center">
                        <input
                            type="number"
                            class="form-control"
                            [(ngModel)]="aircraft.seatFirst"
                            (keyup)="updatedAircraftSeat(aircraft)"
                            (change)="updatedAircraftSeat(aircraft)"
                            step="1"
                            min="0"
                            [placeholder]="EnumAircraftClass.first"
                        />
                    </td>
                    <td class="text-center" [innerText]="aircraft.seatTotal || 0"></td>
                </tr>
                <tr>
                    <td colspan="5" class="text-center" *ngIf="aircraftStatusChanges[aircraft.id]">
                        <button
                            type="button"
                            class="btn btn-success"
                            (click)="saveAircraft(aircraft)"
                            [disabled]="aircraftStatusChanges[aircraft.id] === 'saving'"
                            [innerText]="
                                aircraftStatusChanges[aircraft.id] === 'saving'
                                    ? 'Sauvegarde ...'
                                    : 'Sauvegarder'
                            "
                        ></button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>

<div
    class="text-center mt-4 mb-4"
    *ngIf="!selectedAirlineId && selectedCountryCode && !loading && !hasNoMore"
>
    <button
        type="button"
        class="btn btn-primary"
        (click)="fetchData(true)"
        [disabled]="loadingMore"
        [innerText]="loadingMore ? 'Chargement ...' : 'Charger plus'"
    ></button>
</div>
