<breadcrumbs
    [items]="
        enquiry
            ? [
                  {
                      title: enquiry ? getEnquiryBreadcrumbTitle(enquiry) : '',
                      path: '/admin/enquiry/view/' + enquiry.id
                  },
                  {
                      title: 'Liste des factures',
                      path: '/admin/invoices/list/enquiry/' + enquiry.id
                  },
                  {
                      title: getInvoiceTitle(invoice),
                      path: '/admin/invoices/view/' + invoiceId
                  }
              ]
            : [
                  {
                      title: 'Liste des factures',
                      path: '/admin/invoices/list'
                  },
                  {
                      title: getInvoiceTitle(invoice),
                      path: '/admin/invoices/view/' + invoiceId
                  }
              ]
    "
></breadcrumbs>

<div class="text-center mt-4 mb-4" *ngIf="invoice">
    <a
        [routerLink]="'/admin/invoices/edit/' + invoice.id"
        class="btn btn-primary ml-4 mr-4"
        *ngIf="
            (invoice.invoiceType === EnumInvoiceType.proforma &&
                hasAclAccess(EnumAcl.invoicesEditProforma)) ||
            (invoice.invoiceType === EnumInvoiceType.proformaCreditNote &&
                hasAclAccess(EnumAcl.invoicesEditProformaCreditNote)) ||
            (invoice.invoiceType === EnumInvoiceType.definitive &&
                hasAclAccess(EnumAcl.invoicesEditDefinitive)) ||
            (invoice.invoiceType === EnumInvoiceType.creditNote &&
                hasAclAccess(EnumAcl.invoicesEditCreditNote)) ||
            (invoice.invoiceType === EnumInvoiceType.proformaCreditNote &&
                hasAclAccess(EnumAcl.invoicesEditCreditNoteProforma)) ||
            (invoice.invoiceType === EnumInvoiceType.purchase &&
                hasAclAccess(EnumAcl.invoicesEditPurchase)) ||
            (invoice.invoiceType === EnumInvoiceType.purchaseCreditNote &&
                hasAclAccess(EnumAcl.invoicesEditPurchaseCreditNote))
        "
        >Édition</a
    >
    <a
        [routerLink]="'/admin/invoices/duplicate/' + invoice.id"
        class="btn btn-primary"
        *ngIf="
            (invoice.invoiceType === EnumInvoiceType.proforma &&
                hasAclAccess(EnumAcl.invoicesAddProforma)) ||
            (invoice.invoiceType === EnumInvoiceType.creditNote &&
                hasAclAccess(EnumAcl.invoicesAddCreditNoteFinal)) ||
            (invoice.invoiceType === EnumInvoiceType.definitive &&
                hasAclAccess(EnumAcl.invoicesAddFinalInvoice))
        "
        >Dupliquer</a
    >
    <a
        [routerLink]="'/admin/send-emails/invoice-requested/' + invoice.id"
        class="btn btn-primary"
        *ngIf="
            invoice.invoiceType === EnumInvoiceType.proforma &&
            !hasAclAccess(EnumAcl.invoicesAddFinalInvoice)
        "
        >Demande d'une facture définitive</a
    >
    <a
        [routerLink]="'/admin/send-emails/invoice-requested/' + invoice.id"
        class="btn btn-primary"
        *ngIf="
            invoice.invoiceType === EnumInvoiceType.proformaCreditNote &&
            !hasAclAccess(EnumAcl.invoicesAddCreditNoteFinal)
        "
        >Demande d'un avoir définitif</a
    >
    <a
        [routerLink]="'/admin/send-emails/invoice-added/' + invoice.id"
        class="btn btn-primary"
        *ngIf="
            invoice.ref &&
            ![EnumInvoiceType.proforma, EnumInvoiceType.proformaCreditNote].includes(
                invoice.invoiceType
            )
        "
        >Envoyer au commercial</a
    >

    <a
        [routerLink]="'/admin/invoices/duplicate/' + invoice.id + '/proforma-to-final-invoice'"
        class="btn btn-primary"
        *ngIf="
            invoice.invoiceType === EnumInvoiceType.proforma &&
            hasAclAccess(EnumAcl.invoicesAddFinalInvoice)
        "
        >Générer la facture définitive</a
    >
    <a
        [routerLink]="'/admin/invoices/duplicate/' + invoice.id + '/final-invoice-to-credit-note'"
        class="btn btn-primary"
        *ngIf="
            [EnumInvoiceType.proformaCreditNote].includes(invoice.invoiceType) &&
            hasAclAccess(EnumAcl.invoicesAddCreditNoteFinal)
        "
        >Générer un avoir définitif</a
    >

    <a
        [routerLink]="'/admin/invoices/duplicate/' + invoice.id + '/final-invoice-to-credit-note'"
        class="btn btn-primary"
        *ngIf="
            [EnumInvoiceType.definitive].includes(invoice.invoiceType) &&
            hasAclAccess(EnumAcl.invoicesAddCreditNoteFinal)
        "
        >Annuler par un avoir</a
    >

    <a
        [routerLink]="'/admin/invoices/duplicate/' + invoice.id + '/final-invoice-to-credit-note'"
        class="btn btn-primary"
        *ngIf="
            [EnumInvoiceType.proformaCreditNote].includes(invoice.invoiceType) &&
            hasAclAccess(EnumAcl.invoicesAddCreditNoteFinal)
        "
        >Générer un avoir définitif</a
    >

    <div
        class="dropdown"
        *ngIf="
            ![EnumInvoiceType.proforma, EnumInvoiceType.proformaCreditNote].includes(
                invoice.invoiceType
            )
        "
    >
        <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButton-status"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            [class.disabled]="!hasAclAccess(EnumAcl.invoicesSwitchStatus)"
        >
            Statut : {{ getEnumInvoiceStatusLabel(invoice.status) }}
        </button>
        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton-status">
            <button
                type="button"
                class="dropdown-item"
                *ngFor="let status of getInvoiceStatuses()"
                [innerHtml]="'Changer le statut en \'' + getEnumInvoiceStatusLabel(status) + '\''"
                (click)="changeStatus(status)"
                [class.disabled]="invoice.status === status"
            ></button>
        </div>
    </div>

    <div
        class="dropdown"
        *ngIf="
            hasAclAccess(EnumAcl.invoicesSendInvoiceToClients) &&
            invoice.invoiceType !== EnumInvoiceType.purchase
        "
    >
        <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            id="sent-client"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        >
            Envoyé au client : {{ invoice.sentToClient ? "Oui" : "Non" }}
        </button>
        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sent-client">
            <button
                type="button"
                class="dropdown-item"
                *ngFor="let sentToClient of sentToClientList"
                [innerHtml]="'Changer en \'' + sentToClient.title + '\''"
                (click)="changeSentToClient(sentToClient.value)"
                [class.disabled]="invoice.sentToClient === sentToClient.value"
            ></button>
        </div>
    </div>

    <div class="dropdown">
        <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            id="payed-invoice"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            *ngIf="invoice.isFullyPayed"
        >
            Facture soldée : {{ invoice.isFullyPayed ? "Oui" : "Non" }}
        </button>
        <button
            *ngIf="!invoice.isFullyPayed"
            class="btn btn-primary"
            [routerLink]="'/admin/encaissements/add/invoice/' + invoice?.id"
        >
            Solder la facture
        </button>
    </div>

    <button
        *ngIf="
            ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                invoice.invoiceType
            )
        "
        type="button"
        (click)="generatePdf()"
        class="btn btn-primary ml-4 mr-4"
        [disabled]="loading || generatingPdf"
    >
        Télécharger
    </button>
    <button
        type="button"
        [routerLink]="'/admin/encaissements/list/invoice/' + invoice?.id"
        class="btn btn-primary ml-4 mr-4"
        *ngIf="encaissements.length"
    >
        {{
            encaissements.length > 1
                ? encaissements.length + " encaissements"
                : encaissements.length + " encaissement"
        }}
    </button>
    <button
        type="button"
        (click)="delete()"
        class="btn btn-primary ml-4"
        *ngIf="hasAclAccess(EnumAcl.invoicesDelete)"
    >
        Supprimer
    </button>
</div>

<div class="row justify-content-center" *ngIf="loading">Chargement ...</div>

<div *ngIf="!loading && invoice" class="container mb-4">
    <div
        class="card mb-4"
        *ngIf="
            invoice.id &&
            ![
                EnumInvoiceType.proforma,
                EnumInvoiceType.proformaCreditNote,
                EnumInvoiceType.purchase,
                EnumInvoiceType.purchaseCreditNote
            ].includes(invoice.invoiceType)
        "
    >
        <div class="card-body">
            <h5 class="card-title text-center">
                Cette facture a été validée et n'est plus modifiable.
            </h5>
            Pour corriger une erreur sur la facture, la seule solution est :
            <ol class="mb-0">
                <li>D'annuler la facture en générant un avoir</li>
                <li>En dupliquant la facture</li>
            </ol>
        </div>
    </div>

    <div
        class="card mb-4"
        *ngIf="invoice.id && [EnumInvoiceType.purchase].includes(invoice.invoiceType)"
    >
        <div class="card-body">
            <h5 class="card-title text-center">Cette facture est une facture d'achat</h5>
        </div>
    </div>

    <div
        class="card mb-4"
        *ngIf="invoice.id && [EnumInvoiceType.purchaseCreditNote].includes(invoice.invoiceType)"
    >
        <div class="card-body">
            <h5 class="card-title text-center">
                Cette facture est un avoir reçu d'un fournisseur/prestataire
            </h5>
        </div>
    </div>

    <div
        class="alert alert-info text-center"
        *ngIf="
            (invoice.internalNote && invoice.internalNote.trim() !== '') ||
            invoice.buyingPriceSupplierPriceInfo
        "
    >
        <div *ngIf="invoice.internalNote && invoice.internalNote.trim() !== ''">
            <strong>Notes internes : </strong> {{ invoice.internalNote }}
        </div>
        <div *ngIf="invoice.buyingPriceSupplierPriceInfo">
            <strong>Prix d'achat : </strong> {{ invoice.buyingPriceSupplierPriceInfo }}
        </div>
    </div>

    <div class="text-center mt-4" *ngIf="loadingGeneratedPdf">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Chargement...</span>
        </div>
    </div>

    <div *ngIf="invoice.document?.url || (!loadingGeneratedPdf && invoice.generatedPdfPath)">
        <div class="pdf-embed-view" #pdfViewerEmbed></div>
        <div class="text-center">
            <button type="button" class="btn btn-light btn-sm" (click)="regenerateInvoicePdf()">
                <fa-icon [icon]="faRefresh"></fa-icon> Regénérer le PDF
            </button>
        </div>
    </div>

    <div id="pdf-content" *ngIf="invoice && !invoice.generatedPdfPath">
        <div class="pdf-content">
            <div class="row justify-content-center">
                <div
                    class="col-md-6"
                    *ngIf="
                        ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                            invoice.invoiceType
                        )
                    "
                >
                    <img src="/assets/img/logo-menu.png" alt="Artheau Aviation" /><br />
                    <strong
                        >ARTHEAU AVIATION<br />
                        16 boulevard St Germain<br />
                        75237 PARIS 05</strong
                    ><br />
                    {{ getDefaultStringTranslation("INVOICE.PHONE") }}: +330182285280<br />
                    www.flyaa.eu<br />
                    contact&#64;flyaa.eu
                </div>
                <div class="col-md-6">
                    <div class="text-md-end">
                        <div class="invoice-ref-title">
                            <span *ngIf="invoice.invoiceType === EnumInvoiceType.definitive">{{
                                getDefaultStringTranslation("INVOICE.INVOICE")
                            }}</span>
                            <span *ngIf="invoice.invoiceType === EnumInvoiceType.proforma">{{
                                getDefaultStringTranslation("INVOICE.PROFORMA")
                            }}</span>
                            <span *ngIf="invoice.invoiceType === EnumInvoiceType.creditNote">{{
                                getDefaultStringTranslation("INVOICE.CREDIT_NOTE")
                            }}</span>
                            <span
                                *ngIf="invoice.invoiceType === EnumInvoiceType.proformaCreditNote"
                                >{{ getDefaultStringTranslation("INVOICE.PROFORMA") }}</span
                            >
                            {{ invoice.ref }}
                        </div>
                        <div class="invoice-date">
                            {{ getDefaultStringTranslation("INVOICE.DATE") }}:
                            {{ invoice.issueDate | date : "dd/MM/yyyy" }}
                        </div>
                        <div class="contract-ref" *ngIf="enquiry?.refContractTitle">
                            {{ getDefaultStringTranslation("INVOICE.CONTRACT") }} :
                            {{ getDisplayedEnquiryRefTitle(enquiry, "refContract") }}<br />
                        </div>

                        <div
                            class="referal-invoice"
                            *ngIf="
                                referalInvoice &&
                                (invoice.invoiceType === EnumInvoiceType.creditNote ||
                                    referalInvoice?.invoiceType === EnumInvoiceType.proforma ||
                                    referalInvoice?.invoiceType ===
                                        EnumInvoiceType.proformaCreditNote)
                            "
                        >
                            <span *ngIf="invoice.invoiceType === EnumInvoiceType.creditNote">{{
                                getDefaultStringTranslation("INVOICE.CREDIT_NOTE_LINKED_INVOICE")
                            }}</span>
                            <span *ngIf="referalInvoice.invoiceType === EnumInvoiceType.proforma">{{
                                getDefaultStringTranslation("INVOICE.PROFORMA_ORIGIN")
                            }}</span>
                            : {{ referalInvoice.ref }}
                        </div>
                    </div>

                    <div class="client-container">
                        <strong
                            [innerText]="invoice.clientBillingInfos.clientName ?? client?.name"
                        ></strong
                        ><br />
                        {{ invoice.clientBillingInfos?.street }}<br />
                        {{ invoice.clientBillingInfos?.postalCode }}
                        {{ invoice.clientBillingInfos?.city }}
                        {{ countries[invoice.clientBillingInfos?.countryCode] }}<br />
                        <div
                            *ngIf="invoice.clientBillingInfos?.tvaNumber"
                            [innerText]="invoice.clientBillingInfos?.tvaNumber"
                        ></div>
                        <div
                            *ngIf="invoice.clientBillingInfos?.siret"
                            [innerText]="invoice.clientBillingInfos?.siret"
                        ></div>
                    </div>
                </div>
            </div>

            <p class="invoice-enquiry-infos">
                <span *ngIf="enquiry?.refContractTitle"
                    >{{ getDefaultStringTranslation("INVOICE.CONTRACT") }} :
                    {{ getDisplayedEnquiryRefTitle(enquiry, "refContract") }}<br
                /></span>

                <span
                    [innerHtml]="getCotationCacheDetail()"
                    *ngIf="
                        ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                            invoice.invoiceType
                        )
                    "
                ></span>

                <span *ngIf="invoice?.buyingPriceSupplierPaymentsInfo"
                    ><br />
                    {{ getDefaultStringTranslation("INVOICE.NOTES") }} :
                    {{ invoice.buyingPriceSupplierPaymentsInfo }}
                </span>
            </p>

            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>{{ getDefaultStringTranslation("INVOICE.DESCRIPTION") }}</th>
                            <th class="text-end">
                                {{ getDefaultStringTranslation("INVOICE.AMOUNT_HT") }}
                            </th>
                            <th class="text-end">
                                {{ getDefaultStringTranslation("INVOICE.TVA") }}
                            </th>
                            <th class="text-end">
                                {{ getDefaultStringTranslation("INVOICE.AMOUNT_TVA") }}
                            </th>
                            <th class="text-end">
                                {{ getDefaultStringTranslation("INVOICE.AMOUNT_TTC") }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of invoice.products; let i = index">
                            <td [innerText]="product.descriptionTitle"></td>
                            <td class="text-end" [innerText]="formatPrice(product.amountHt)"></td>
                            <td
                                class="text-end"
                                [innerText]="getEnumTvaRateLabel(product.tvaRate)"
                            ></td>
                            <td class="text-end" [innerText]="formatPrice(product.amountTva)"></td>
                            <td class="text-end" [innerText]="formatPrice(product.amountTtc)"></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="row justify-content-between">
                <div
                    class="col-md-8"
                    *ngIf="
                        ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                            invoice.invoiceType
                        )
                    "
                >
                    <div>
                        <span *ngIf="invoice.amountTvaTotal === 0">{{
                            getDefaultStringTranslation("INVOICE.NO_TVA_APPLIED_TEXT")
                        }}</span>
                        <span *ngIf="invoice.amountTvaTotal > 0">{{
                            getDefaultStringTranslation("INVOICE.TVA_APPLIED_TEXT")
                        }}</span>
                    </div>
                    <div>{{ getDefaultStringTranslation("INVOICE.LEGAL_TEXT_1") }}</div>
                    <div>
                        {{ getDefaultStringTranslation("INVOICE.DUE_DATE") }}:
                        {{ invoice.dueDate | date : "dd/MM/yyyy" }}
                    </div>
                    <div>
                        {{ getDefaultStringTranslation("INVOICE.PAYMENT_TYPE_BANK_TRANSFER") }}
                    </div>

                    <div class="payment-details">
                        <div>
                            <strong>{{
                                getDefaultStringTranslation("INVOICE.BANK_DETAIL_INFORMATION")
                            }}</strong
                            >: <br />
                            {{
                                invoice?.bankAccountData?.accountLabel ?? bankAccount?.accountLabel
                            }}
                        </div>
                        <div>
                            <strong>{{ getDefaultStringTranslation("INVOICE.IBAN") }}</strong
                            >: {{ invoice?.bankAccountData?.iban ?? bankAccount?.iban }}<br />
                        </div>
                        <div>
                            <strong>{{ getDefaultStringTranslation("INVOICE.BIC") }}</strong
                            >: {{ invoice?.bankAccountData?.bic ?? bankAccount?.bic }}
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-4"
                    [class.offset-md-8]="
                        [EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                            invoice.invoiceType
                        )
                    "
                >
                    <table class="table amounts-total">
                        <tbody>
                            <tr>
                                <td>{{ getDefaultStringTranslation("INVOICE.TOTAL_HT") }}</td>
                                <td
                                    [innerText]="formatPrice(invoice.amountHtTotal)"
                                    class="text-end"
                                ></td>
                            </tr>
                            <tr>
                                <td>{{ getDefaultStringTranslation("INVOICE.TOTAL_TVA") }}</td>
                                <td
                                    [innerText]="formatPrice(invoice.amountTvaTotal)"
                                    class="text-end"
                                ></td>
                            </tr>
                            <tr>
                                <td>{{ getDefaultStringTranslation("INVOICE.TOTAL_TTC") }}</td>
                                <td
                                    [innerText]="formatPrice(invoice.amountTtcTotal)"
                                    class="text-end"
                                ></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>{{ getDefaultStringTranslation("INVOICE.TOTAL_TO_PAY") }}</td>
                                <td
                                    [innerText]="formatPrice(invoice.amountTtcTotal)"
                                    class="text-end"
                                ></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

            <p
                class="invoice-warning-unpaid"
                *ngIf="
                    ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
                        invoice.invoiceType
                    )
                "
            >
                {{ getDefaultStringTranslation("INVOICE.WARNING_UNPAID_TEXT") }}
            </p>
        </div>
    </div>
</div>
