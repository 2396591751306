import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthFirestoreService {
  currentUserUid = new BehaviorSubject<string>(null);
  currentUserUid$ = this.currentUserUid.asObservable();

  constructor() {}

  updateCurrentUserUid(uid: string) {
    this.currentUserUid.next(uid);
  }
}
