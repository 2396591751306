import { EnumLanguage } from '../enums/language.enum';
import { EnumTvaRate } from '../enums/tva-rates.enum';
import { IStoreData } from './store-data.interface';

export enum EnumPdfParserValueSearchedPosition {
  before = 'before',
  current = 'current',
  after = 'after'
}

export const getPdfParserValueSearchedPositionLabel = (
  position: EnumPdfParserValueSearchedPosition
): string => {
  switch (position) {
    default:
      return position;
    case EnumPdfParserValueSearchedPosition.before:
      return 'Avant le texte recherché';
    case EnumPdfParserValueSearchedPosition.current:
      return 'Est le texte recherché';
    case EnumPdfParserValueSearchedPosition.after:
      return 'Après le texte recherché';
  }
};

export interface IPdfParserSettingFieldConfig {
  isValueInPdf: boolean;
  isValueInDays?: boolean;
  matchingText: string;
  nbOfHits: number;
  rules: IPDfParserSettingsRule[];
  valueSearchedPosition: EnumPdfParserValueSearchedPosition;
  result: string;
}

export interface IPdfParserSetting extends IStoreData {
  name: string;
  language: EnumLanguage;
  currency: string;
  countryCode: {
    result: string;
  };
  identifierText: {
    matchingText: string;
    nbOfHits: number;
  };
  ref: IPdfParserSettingFieldConfig;
  dueDate: IPdfParserSettingFieldConfig;
  issueDate: IPdfParserSettingFieldConfig;
  clientName: IPdfParserSettingFieldConfig;
  street: IPdfParserSettingFieldConfig;
  city: IPdfParserSettingFieldConfig;
  postalCode: IPdfParserSettingFieldConfig;
  siret: IPdfParserSettingFieldConfig;
  tvaNumber: IPdfParserSettingFieldConfig;
  productsColumnsConfig: {
    tableIndex: string;
    descriptionTitle: string;
    amountHt: string;
    isTvaValueInPdf: boolean;
    tvaRate: string;
    tvaRateSelected: EnumTvaRate;
    rulesForRows: IPDfParserSettingsProductColumnRule[];
  };
}

export interface IPDfParserSettingsRule {
  matchingText: string;
  ruleType: EnumPdfParserRuleType;
  replaceValue: string;
}

export interface IPDfParserSettingsProductColumnRule {
  matchingText: string;
  ruleType: EnumProductsColumnsConfigType;
}

export enum EnumPdfParserRuleType {
  remove = 'remove',
  replaceByText = 'replaceByText',
  removeAllTextBefore = 'removeAllTextBefore',
  removeAllTextAfter = 'removeAllTextAfter'
}

export enum EnumProductsColumnsConfigType {
  ignore = 'ignore'
}

export const getPdfParserRuleByTypeLabel = (ruleType: EnumPdfParserRuleType): string => {
  switch (ruleType) {
    default:
      return ruleType;
    case EnumPdfParserRuleType.remove:
      return 'Suppression';
    case EnumPdfParserRuleType.replaceByText:
      return 'Remplacement par un autre texte';
    case EnumPdfParserRuleType.removeAllTextBefore:
      return 'Supprimer tout le texte avant';
    case EnumPdfParserRuleType.removeAllTextAfter:
      return 'Supprimer tout le texte après';
  }
};

export const getProductsColumnsConfigTypeLabel = (
  ruleType: EnumProductsColumnsConfigType
): string => {
  switch (ruleType) {
    default:
      return ruleType;
    case EnumProductsColumnsConfigType.ignore:
      return 'Ignorer la ligne';
  }
};
