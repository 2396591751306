<breadcrumbs
    [items]="[
        {
            title: 'Liste des modèles d\'avions pour le simulateur',
            path: '/admin/simulateur-aircraft-models/list'
        }
    ]"
></breadcrumbs>

<div class="btn-toolbar mb-4" role="toolbar">
    <a
        routerLink="/admin/simulateur-aircraft-models/add"
        class="btn btn-primary"
        *ngIf="hasAclAccess(EnumAcl.simulateurAircraftModelsAdd)"
        >+ Nouveau modèle d'avion pour le simulateur</a
    >
</div>
<div class="table-responsive">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th
                    scope="col"
                    class="sortable"
                    *ngFor="let field of paginationService.pagination[currentPagination].fields"
                    [class.is-sorted]="
                        paginationService.pagination[currentPagination].orderBy.field == field.code
                    "
                    [class.asc]="
                        paginationService.pagination[currentPagination].orderBy.field ==
                            field.code &&
                        paginationService.pagination[currentPagination].orderBy.direction == 'asc'
                    "
                    [class.desc]="
                        paginationService.pagination[currentPagination].orderBy.field ==
                            field.code &&
                        paginationService.pagination[currentPagination].orderBy.direction == 'desc'
                    "
                    (click)="paginationService.sortField(currentPagination, field.code)"
                    [innerHtml]="field.title"
                ></th>
            </tr>
        </thead>
        <tbody *ngIf="paginationService.loading">
            <tr *ngFor="let number of [].constructor(10)">
                <td *ngFor="let field of paginationService.pagination[currentPagination].fields">
                    <div class="skeleton-text"></div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="!paginationService.loading">
            <tr
                *ngIf="
                    !paginationService.pagination[currentPagination].data ||
                    !paginationService.pagination[currentPagination].data.length
                "
                class="table-warning text-center"
            >
                <td [attr.colspan]="paginationService.pagination[currentPagination].fields.length">
                    Aucun modèle d'avion n'a été trouvé.
                </td>
            </tr>
            <tr
                *ngFor="
                    let simulateurAircraftModel of paginationService.pagination[currentPagination]
                        .data
                "
                [routerLink]="
                    '/admin/simulateur-aircraft-models/view/' + simulateurAircraftModel.id
                "
                class="clickable"
            >
                <td>
                    <div [innerHtml]="simulateurAircraftModel.title"></div>
                    <img
                        [src]="simulateurAircraftModel.imageUrl"
                        *ngIf="simulateurAircraftModel.imageUrl"
                        style="max-width: 300px"
                    />
                </td>
                <td [innerHtml]="simulateurAircraftModel.paxMax"></td>
                <td [innerHtml]="simulateurAircraftModel.speedInKmh"></td>
                <td [innerHtml]="simulateurAircraftModel.rangeMaxInKms"></td>
                <td [innerHtml]="simulateurAircraftModel.flyTimeInHours"></td>
                <td [innerText]="formatPrice(simulateurAircraftModel.priceByHour)"></td>
                <td [innerText]="formatPrice(simulateurAircraftModel.priceByPax)"></td>
                <td
                    [innerHtml]="
                        simulateurAircraftModel.fireCategories?.length
                            ? simulateurAircraftModel.fireCategories.join(', ')
                            : 'Aucune'
                    "
                ></td>
                <td
                    [innerHtml]="
                        simulateurAircraftModel.minHardSurfaceRunwayInMeters
                            ? simulateurAircraftModel.minHardSurfaceRunwayInMeters + ' m'
                            : ''
                    "
                ></td>
            </tr>
        </tbody>
    </table>
</div>

<nav
    aria-label="Liste navigation"
    class="mt-4 mb-4"
    *ngIf="
        !paginationService.loading &&
        paginationService.pagination[currentPagination].data &&
        paginationService.pagination[currentPagination].data.length
    "
>
    <ul class="pagination justify-content-center">
        <li
            class="page-item"
            [class.disabled]="paginationService.pagination[currentPagination].currentPage === 0"
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadPreviousPage(currentPagination)"
            >
                Précédent
            </button>
        </li>
        <li class="page-item disabled">
            <span
                class="page-link"
                [innerHtml]="paginationService.pagination[currentPagination].currentPage + 1"
            ></span>
        </li>
        <li
            class="page-item"
            [class.disabled]="
                paginationService.pagination[currentPagination].data &&
                (!paginationService.pagination[currentPagination].data.length ||
                    paginationService.pagination[currentPagination].data.length <
                        paginationService.pagination[currentPagination].limit)
            "
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadNextPage(currentPagination)"
            >
                Suivant
            </button>
        </li>
    </ul>
</nav>
