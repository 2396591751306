import { Component } from '@angular/core';

@Component({
  selector: 'app-flight-calendar-dashboard',
  templateUrl: './flight-calendar-dashboard.component.html',
  styleUrls: ['./flight-calendar-dashboard.component.scss']
})
export class FlightCalendarDashboardComponent {

}
