import { IStoreData } from './store-data.interface';

export interface IUserGroup extends IStoreData {
  title: string;
  rules: {
    id: string;
    access: boolean;
  }[];
}

export const getUserGroupRulesList = (userGroup: IUserGroup): string[] => {
  const rulesList: string[] = [];
  for (const rule of userGroup.rules) {
    if (rule.access) {
      rulesList.push(rule.id);
    }
  }

  return rulesList;
};
