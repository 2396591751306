<breadcrumbs
    [items]="[
        {
            title: 'Liste des logs de scripts',
            path: '/admin/crawler-urls/list'
        }
    ]"
></breadcrumbs>

<div class="row">
    <div class="col-md-3" [class.col-md-3]="!searchingByUrl" [class.col-md-9]="searchingByUrl">
        <div class="form-group">
            <label for="search-url">Rechercher url</label>
            <div class="input-group">
                <input
                    type="search"
                    class="form-control"
                    id="search-url"
                    [(ngModel)]="searchUrl"
                    required
                    [disabled]="source !== '' || status !== '' || searchingByUrl"
                />
                <button
                    class="btn btn-outline-secondary"
                    [class.btn-outline-secondary]="searchUrl === ''"
                    [class.btn-primary]="searchUrl !== ''"
                    type="button"
                    id="button-addon2"
                    (click)="searchingByUrl ? cancelSearchByUrl() : searchByUrl()"
                >
                    <fa-icon [icon]="faSearch" *ngIf="!searchingByUrl"></fa-icon>
                    <fa-icon [icon]="faBan" *ngIf="searchingByUrl"></fa-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-3" *ngIf="!searchingByUrl">
        <div class="form-group">
            <label for="status">Filtrées par statut</label>
            <select
                class="form-select"
                id="status"
                [(ngModel)]="status"
                required
                (change)="statusChanged()"
                [disabled]="source !== '' || searchUrl !== ''"
            >
                <option value="">Tous</option>
                <option
                    [value]="status"
                    *ngFor="let status of getCrawlerUrlStatuses()"
                    [innerText]="'Seulement ' + getEnumCrawlerUrlStatusLabel(status)"
                ></option>
            </select>
        </div>
    </div>
    <div class="col-md-3" *ngIf="!searchingByUrl">
        <div class="form-group">
            <label for="status">Filtrées par source</label>
            <select
                class="form-select"
                id="source"
                [(ngModel)]="source"
                required
                (change)="sourceChanged()"
                [disabled]="status !== '' || searchUrl !== ''"
            >
                <option value="">Tous</option>
                <option
                    [value]="status"
                    *ngFor="let status of getEnumCrawlerUrlSources()"
                    [innerText]="'Seulement ' + getEnumCrawlerUrlSourceLabel(status)"
                ></option>
            </select>
        </div>
    </div>
    <div class="col-md-3 text-end">
        <div class="btn-group mt-4" role="group">
            <a
                routerLink="/admin/crawler-urls/add"
                class="btn btn-primary"
                type="button"
                *ngIf="hasAclAccess(EnumAcl.crawlerUrlsAdd)"
            >
                Ajout
            </a>
            <ng-container *ngIf="hasAclAccess(EnumAcl.crawlerUrlsDelete)">
                <div class="dropdown">
                    <button
                        class="btn btn-danger dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton-delete"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Supprimer
                    </button>
                    <div
                        class="dropdown-menu dropdown-menu-end"
                        aria-labelledby="dropdownMenuButton-delete"
                    >
                        <button
                            type="button"
                            class="dropdown-item"
                            [innerText]=""
                            (click)="deleteAllByStatus(status)"
                            *ngFor="let status of getCrawlerUrlStatuses()"
                        >
                            Supprimer si status = {{ getEnumCrawlerUrlStatusLabel(status) }}
                        </button>
                        <button
                            type="button"
                            class="dropdown-item"
                            [innerText]=""
                            (click)="deleteAllBySource(source)"
                            *ngFor="let source of getEnumCrawlerUrlSources()"
                        >
                            Supprimer si source = {{ getEnumCrawlerUrlSourceLabel(source) }}
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-container *ngIf="loading">
    <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th
                        scope="col"
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                        [class.is-sorted]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                            field.code
                        "
                        [class.asc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'asc'
                        "
                        [class.desc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'desc'
                        "
                        [innerHtml]="field.title"
                    ></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let number of [].constructor(10)">
                    <td
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                    >
                        <div class="skeleton-text"></div>
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-container>

<ng-container *ngIf="!loading">
    <div class="table-responsive">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th
                        scope="col"
                        class="sortable"
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                        [class.is-sorted]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                            field.code
                        "
                        [class.asc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'asc'
                        "
                        [class.desc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'desc'
                        "
                        (click)="paginationService.sortField(currentPagination, field.code)"
                        [innerHtml]="field.title"
                    ></th>
                    <th></th>
                </tr>
            </thead>
            <tbody *ngIf="paginationService.loading">
                <tr *ngFor="let number of [].constructor(10)">
                    <td
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                    >
                        <div class="skeleton-text"></div>
                    </td>
                    <td></td>
                </tr>
            </tbody>
            <tbody *ngIf="!paginationService.loading">
                <tr
                    *ngIf="
                        !paginationService.pagination[currentPagination].data ||
                        !paginationService.pagination[currentPagination].data.length
                    "
                    class="table-warning text-center"
                >
                    <td
                        [attr.colspan]="
                            paginationService.pagination[currentPagination].fields.length + 1
                        "
                    >
                        Aucun log n'a été trouvé.
                    </td>
                </tr>
                <tr *ngFor="let crawlerUrl of paginationService.pagination[currentPagination].data">
                    <td>
                        <a
                            [href]="crawlerUrl.url"
                            target="_blank"
                            [innerText]="crawlerUrl.url"
                            *ngIf="crawlerUrl.url"
                        ></a>
                    </td>
                    <td>
                        <span
                            class="badge bg-{{ getEnumCrawlerUrlStatusColor(crawlerUrl.status) }}"
                            [innerText]="getEnumCrawlerUrlStatusLabel(crawlerUrl.status)"
                        ></span>
                        <div
                            *ngIf="crawlerUrl.status === EnumCrawlerUrlStatus.error"
                            [innerText]="crawlerUrl.error"
                        ></div>
                        <div
                            [innerText]="getCrawlerUrlDuration(crawlerUrl)"
                            *ngIf="
                                ![
                                    EnumCrawlerUrlStatus.fetchedRemoteContent,
                                    EnumCrawlerUrlStatus.notStarted,
                                    EnumCrawlerUrlStatus.started
                                ].includes(crawlerUrl.status)
                            "
                        ></div>
                    </td>
                    <td [innerText]="crawlerUrl.dateStart | date : 'dd/MM/yyyy HH:mm'"></td>
                    <td [innerText]="getEnumCrawlerUrlSourceLabel(crawlerUrl.source)"></td>
                    <td [innerText]="crawlerUrl.nextDateToUpdate | date : 'dd/MM/yyyy HH:mm'"></td>
                    <td [innerText]="crawlerUrl.created | date : 'dd/MM/yyyy HH:mm'"></td>
                    <td>
                        <button
                            type="button"
                            class="btn btn-primary"
                            (click)="retryOne(crawlerUrl.id)"
                        >
                            <fa-icon [icon]="faSync"></fa-icon>
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            (click)="view(crawlerUrl)"
                            data-bs-toggle="modal"
                            data-bs-target="#crawler-url-view-modal"
                            *ngIf="crawlerUrl.dataFetched"
                        >
                            <fa-icon [icon]="faEye"></fa-icon>
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger"
                            (click)="deleteOne(crawlerUrl.id)"
                            *ngIf="hasAclAccess(EnumAcl.crawlerUrlsDelete)"
                        >
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <nav aria-label="Liste navigation" class="mt-4 mb-4" *ngIf="!paginationService.loading">
        <ul class="pagination justify-content-center">
            <li
                class="page-item"
                [class.disabled]="paginationService.pagination[currentPagination].currentPage === 0"
            >
                <button
                    type="button"
                    class="page-link"
                    (click)="paginationService.loadPreviousPage(currentPagination)"
                >
                    Précédent
                </button>
            </li>
            <li class="page-item disabled">
                <span
                    class="page-link"
                    [innerHtml]="paginationService.pagination[currentPagination].currentPage + 1"
                ></span>
            </li>
            <li
                class="page-item"
                [class.disabled]="
                    paginationService.pagination[currentPagination].data &&
                    (!paginationService.pagination[currentPagination].data.length ||
                        paginationService.pagination[currentPagination].data.length <
                            paginationService.pagination[currentPagination].limit)
                "
            >
                <button
                    type="button"
                    class="page-link"
                    (click)="paginationService.loadNextPage(currentPagination)"
                >
                    Suivant
                </button>
            </li>
        </ul>
    </nav>
</ng-container>

<div
    class="modal fade"
    id="crawler-url-view-modal"
    tabindex="-1"
    aria-labelledby="crawler-url-view-modalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="crawler-url-view-modalLabel">
                    Données extraites depuis l'url
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                {{ currentCrawlerDataParsed | json }}
            </div>
        </div>
    </div>
</div>
