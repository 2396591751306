export enum EnumEnquiryPaymentStatus {
  noInvoice = 'noInvoice',
  payed = 'payed',
  paymentsMissing = 'paymentsMissing',
  overPayments = 'overPayments'
}

export const getEnumEnquiryPaymentStatusLabel = (status: EnumEnquiryPaymentStatus): string => {
  switch (status) {
    default:
      return status;
    case EnumEnquiryPaymentStatus.payed:
      return 'Ok - Tout est payé';
    case EnumEnquiryPaymentStatus.noInvoice:
      return 'Aucune facture associée';
    case EnumEnquiryPaymentStatus.paymentsMissing:
      return 'Reste à payer';
    case EnumEnquiryPaymentStatus.overPayments:
      return 'Trop perçu';
  }
};
