import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { StatsFirestoreService } from './stats-firestore.service';
import { IStats } from 'src/app/interfaces/stats.interface';

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  constructor(private statsFirestoreService: StatsFirestoreService) {}

  create(stats: IStats): Promise<string> {
    return this.statsFirestoreService.create(stats);
  }

  update(stats: IStats): Promise<string> {
    return this.statsFirestoreService.update(stats);
  }

  delete(id: string): Promise<void> {
    return this.statsFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IStats> {
    return this.statsFirestoreService.docValue$(id);
  }

  getAll(): Observable<IStats[]> {
    return this.statsFirestoreService.collectionValues$();
  }

  getAllForYear(year: number): Observable<IStats[]> {
    return this.statsFirestoreService.collectionValues$(ref =>
      ref.where('year', '==', year).where('userId', '==', null)
    );
  }

  getAllForYearAndMonthly(year: number): Observable<IStats[]> {
    return this.statsFirestoreService.collectionValues$(ref =>
      ref.where('year', '==', year).where('type', '==', 'monthly')
    );
  }

  getAllYearly(): Observable<IStats[]> {
    return this.statsFirestoreService.collectionValues$(ref => ref.where('type', '==', 'yearly'));
  }

  getAllForUser(userId: string | null): Observable<IStats[]> {
    return this.statsFirestoreService.collectionValues$(ref => ref.where('userId', '==', userId));
  }

  resetPagination(paginationName: string = 'list'): void {
    this.statsFirestoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = []
  ): Observable<IStats[]> {
    const orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'modified',
      direction: 'desc'
    };

    return this.statsFirestoreService.paginate(conditions, orderBy, 20, paginationName);
  }
}
