import { Injectable } from '@angular/core';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AirportFirestoreService extends FirestoreService<IAirport> {
  protected basePath = 'airports';
}
