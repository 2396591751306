import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { IFinancialReportAirline } from 'src/app/interfaces/financial-report-airline.interface';

@Injectable({
  providedIn: 'root'
})
export class FinancialReportAirlinesFirestoreService extends FirestoreService<IFinancialReportAirline> {
  protected basePath = 'financialReportsByAirlines';
}
