export enum EnumEnquiryCotationStatus {
  toContact = 'to-contact',
  waitingForAnswer = 'waiting-for-answer',
  answered = 'answered',
  rejected = 'rejected',
  confirmed = 'confirmed',
  cancelled = 'cancelled'
}

export const getEnumEnquiryCotationStatusLabel = (status: EnumEnquiryCotationStatus): string => {
  switch (status) {
    default:
      return status;
    case EnumEnquiryCotationStatus.toContact:
      return 'À contacter';
    case EnumEnquiryCotationStatus.waitingForAnswer:
      return 'En attente de réponse';
    case EnumEnquiryCotationStatus.answered:
      return 'Répondu';
    case EnumEnquiryCotationStatus.rejected:
      return 'Rejeté';
    case EnumEnquiryCotationStatus.confirmed:
      return 'Confirmé';
    case EnumEnquiryCotationStatus.cancelled:
      return 'Annulé';
  }
};
