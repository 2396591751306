export enum EnumEnquiryPrefilterAirline {
  target = 'target',
  aircraftModel = 'aircraftModel'
}

export const getEnumEnquiryPrefilterAirlineLabel = (
  filterBy: EnumEnquiryPrefilterAirline
): string => {
  switch (filterBy) {
    default:
      return filterBy;
    case EnumEnquiryPrefilterAirline.target:
      return 'Cible';
    case EnumEnquiryPrefilterAirline.aircraftModel:
      return 'Modèle';
  }
};
