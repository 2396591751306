<breadcrumbs
    [items]="
        emailTemplateId
            ? [
                  {
                      title: 'Liste des templates d\'email',
                      path: '/admin/email-templates/list'
                  },
                  {
                      title: emailTemplate ? emailTemplate.title : '',
                      path: '/admin/email-templates/view/' + emailTemplateId
                  },
                  {
                      title: 'Édition d\'un template d\'email',
                      path: '/admin/email-templates/edit/' + emailTemplateId
                  }
              ]
            : [
                  {
                      title: 'Liste des templates d\'email',
                      path: '/admin/email-templates/list'
                  },
                  {
                      title: 'Ajout d\'un template d\'email',
                      path: '/admin/email-templates/add'
                  }
              ]
    "
></breadcrumbs>

<form (submit)="submitForm()" [formGroup]="form" class="main-form">
    <div class="row justify-content-center">
        <div class="col-xl-6">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="title">Nom</label>
                        <input
                            class="form-control"
                            id="title"
                            formControlName="title"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['title'].touched) &&
                                form.controls['title'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['title'].touched) &&
                                    form.controls['title'].errors &&
                                    form.controls['title'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="language">Langue</label>
                        <select
                            class="form-select"
                            id="language"
                            formControlName="language"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['language'].touched) &&
                                form.controls['language'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="language"
                                *ngFor="let language of getLanguages()"
                                [innerHtml]="getLanguageLabel(language)"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['language'].touched) &&
                                    form.controls['language'].errors &&
                                    form.controls['language'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="subject">Sujet de l'email</label>
                        <input
                            class="form-control"
                            id="subject"
                            formControlName="subject"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['subject'].touched) &&
                                form.controls['subject'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            maxlength="255"
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['subject'].touched) &&
                                    form.controls['subject'].errors &&
                                    form.controls['subject'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                            <div
                                *ngIf="
                                    (form.touched || form.controls['subject'].touched) &&
                                    form.controls['subject'].errors &&
                                    form.controls['subject'].errors['maxlength']
                                "
                            >
                                Ce champ doit être maximum 255 caractères.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="messageHtml">Contenu de l'email</label>

                        <quill-editor formControlName="messageHtml"></quill-editor>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['messageHtml'].touched) &&
                                    form.controls['messageHtml'].errors &&
                                    form.controls['messageHtml'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>

                        <div class="accordion mt-4" id="accordion-variables">
                            <div class="accordion-item">
                                <div class="accordion-header" id="headingOne">
                                    <button
                                        class="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="false"
                                        aria-controls="collapseOne"
                                    >
                                        Variables disponibles pour les templates
                                    </button>
                                </div>

                                <div
                                    id="collapseOne"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion-variables"
                                >
                                    <ul class="list-group list-group-flush">
                                        <li
                                            class="list-group-item"
                                            *ngFor="
                                                let emailTemplateVariable of emailTemplateVariables
                                            "
                                            [innerText]="
                                                emailTemplateVariable.title +
                                                ' : ' +
                                                emailTemplateVariable.value
                                            "
                                        ></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="
                    emailTemplateId
                        ? '/admin/email-templates/view/' + emailTemplateId
                        : '/admin/email-templates/list'
                "
                class="btn btn-outline-primary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>
