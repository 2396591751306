import { Injectable } from '@angular/core';

enum EnumLoaderStatus {
  show = 'show',
  success = 'success',
  failure = 'failure',
  noSpinner = 'no-spinner'
}

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  constructor() {}

  presentLoader(message?: string): void {
    if (message) {
      this.setLoaderMessage(message);
    }

    this.setLoaderWithStatus(EnumLoaderStatus.show);
  }

  updateLoaderMessage(message: string): void {
    this.setLoaderMessage(message);
  }

  hideLoaderImmediately(): void {
    this.hideLoader();
  }

  hideLoaderOnSuccess(message?: string, duration: number = 1000): Promise<void> {
    return this.hideLoaderOnEvent(EnumLoaderStatus.success, message, duration);
  }

  hideLoaderOnFailure(errorMessage?: string, duration: number = 1000): Promise<void> {
    return this.hideLoaderOnEvent(
      EnumLoaderStatus.failure,
      errorMessage || 'Operation failed',
      duration
    );
  }

  private hideLoaderOnEvent(
    status: EnumLoaderStatus | null = null,
    message: string | null = null,
    duration: number = 750
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (status) {
        this.setLoaderWithStatus(status);
      }
      if (message) {
        this.setLoaderMessage(message);
      }

      setTimeout(() => {
        this.hideLoader();
        resolve();
      }, duration);
    });
  }

  private setLoaderWithStatus(status: EnumLoaderStatus): void {
    const loaderEl: HTMLElement | null = document.getElementById('loader-container');
    if (loaderEl) {
      loaderEl.classList.add(status);
    }
  }

  private setLoaderMessage(message: string): void {
    const loaderMessageEl: HTMLElement | null = document.getElementById('loader-message');
    if (loaderMessageEl) {
      loaderMessageEl.innerHTML = message;
    }
  }

  private hideLoader(): void {
    const loaderEl: HTMLElement | null = document.getElementById('loader-container');
    if (loaderEl) {
      for (const status of Object.keys(EnumLoaderStatus)) {
        loaderEl.classList.remove(status);
      }
      this.setLoaderMessage('');
    }
  }
}
