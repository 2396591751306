import { Injectable } from '@angular/core';
import { IFlightBriefCatering } from 'src/app/interfaces/flight-brief-catering.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class FlightBriefCateringFirestoreService extends FirestoreService<IFlightBriefCatering> {
  protected basePath = 'flightBriefCaterings';
}
