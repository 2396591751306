import { Injectable } from '@angular/core';
import { IEncaissement } from 'src/app/interfaces/encaissement.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class EncaissementFirestoreService extends FirestoreService<IEncaissement> {
  protected basePath = 'encaissements';
}
