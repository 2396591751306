import { Injectable } from '@angular/core';
import { ICrew } from 'src/app/interfaces/crew.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class CrewFirestoreService extends FirestoreService<ICrew> {
  protected basePath = 'crews';
}
