import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FunctionsService } from '../functions/functions.service';
import firebase from 'firebase/compat/app';

import { UsersFirestoreService } from './users-firestore.service';
import { IUser } from 'src/app/interfaces/user.interface';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: IUser;

  constructor(
    private usersStoreService: UsersFirestoreService,
    private functionsService: FunctionsService,
    private afAuth: AngularFireAuth,
    private afFirestore: AngularFirestore
  ) {}

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   *
   * USERS
   *
   */

  create(user: IUser): Promise<string> {
    return this.usersStoreService.create(user);
  }

  update(user: IUser): Promise<string> {
    return this.usersStoreService.update(user);
  }

  delete(id: string): Promise<void> {
    return this.usersStoreService.delete(id);
  }

  createAuthFromAdmin(email: string, password: string): Promise<string> {
    return this.functionsService.callFunctionAsPromise('createAuthUser', { email, password });
  }

  updateAuthFromAdmin(id: string, email: string): Promise<string> {
    return this.functionsService.callFunctionAsPromise('updateAuthUser', { id, email });
  }

  deleteFromAdmin(id: string): Promise<void> {
    return this.functionsService.callFunctionAsPromise('deleteUser', { id });
  }

  disableFromAdmin(id: string): Promise<void> {
    return this.functionsService.callFunctionAsPromise('disableUser', { id });
  }

  enableFromAdmin(id: string): Promise<void> {
    return this.functionsService.callFunctionAsPromise('enableUser', { id });
  }

  getFromId(id: string): Observable<IUser> {
    return this.usersStoreService.docValue$(id);
  }

  getFromAuthUid(uid: string): Observable<IUser> {
    return this.usersStoreService
      .collectionValues$(ref => ref.where('uidAuth', '==', uid))
      .pipe(
        map(results => {
          if (results && results.length === 1) {
            const user: IUser = results[0] as IUser;

            return user;
          } else {
            return null;
          }
        })
      );
  }

  getAll(): Observable<IUser[]> {
    return this.usersStoreService.collectionValues$();
  }

  getAllFromGroup(userGroupId: string): Observable<IUser[]> {
    return this.usersStoreService.collectionValues$(ref =>
      ref.where('userGroupId', '==', userGroupId)
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.usersStoreService.resetPaginate(paginationName);
  }

  getAllMostRecentFirst(
    paginationName: string = 'list',
    conditions: {
      field: string;
      operator: firebase.firestore.WhereFilterOp;
      value: any;
    }[] = [],
    orderBy: {
      field: string;
      direction: 'asc' | 'desc';
    } = {
      field: 'updated',
      direction: 'desc'
    }
  ): Observable<IUser[]> {
    return this.usersStoreService.paginate(conditions, orderBy, 20, paginationName);
  }

  async isSignInWithEmailLink(url: string): Promise<boolean> {
    return await this.afAuth.isSignInWithEmailLink(url);
  }

  signInWithEmailLink(email: string, url: string): Promise<firebase.auth.UserCredential> {
    return new Promise((resolve, reject) => {
      this.afAuth
        .signInWithEmailLink(email, url)
        .then((userCredential: firebase.auth.UserCredential) => {
          this.afFirestore
            .collection('users', ref => ref.where('email', '==', email))
            .get()
            .subscribe(async (querySnapshot: firebase.firestore.QuerySnapshot) => {
              if (querySnapshot.empty) {
                reject({
                  message: "User doc can't be found"
                });
              } else {
                const docIds: string[] = [];
                querySnapshot.forEach(doc => {
                  docIds.push(doc.id);
                });

                for (const docId of docIds) {
                  await this.update({
                    id: docId,
                    userAuthUID: userCredential.user.uid
                  } as IUser);
                }

                resolve(userCredential);
              }
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  sendSignInLinkToEmail(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      // let url = (window.location.origin == 'http://localhost:4200') ? 'https://simplyfly.artheauaviation.com' : window.location.origin
      let url = window.location.origin;
      url += '/set-password?email=' + email;

      const actionCodeSettings = {
        url: url,
        handleCodeInApp: true
      };

      this.afAuth
        .sendSignInLinkToEmail(email, actionCodeSettings)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
