<div class="row align-items-center justify-content-center h-100 mb-4">
	<div class="col-md-6 col-xl-4">
		<div class="text-center">
			<img [src]="rootUrl + 'assets/img/logo.png'" alt="Artheau Aviation" width="250">
			<h1>Connexion</h1>
		</div>
		<form (ngSubmit)="submitForm()" [formGroup]="form"  (keydown.enter)="submitForm()">
			<div class="form-group">
				<label for="email">Email</label>
				<input formControlName="email" id="email" class="form-control" type="email" required [class.is-invalid]="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)">

				<div class="invalid-tooltip">
					<div *ngIf="form.controls['email'].errors && form.controls['email'].errors['required']">Ce champ est obligatoire.</div>
					<div *ngIf="form.controls['email'].errors && form.controls['email'].errors['email']">Ce champ doit être un email valide.</div>
				</div>
			</div>
			<div class="form-group">
				<label for="password">Mot de passe</label>
				<input formControlName="password" id="password" class="form-control" type="password" required [class.is-invalid]="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)">

				<div class="invalid-tooltip">
					<div *ngIf="form.controls['password'].errors && form.controls['password'].errors['required']">Ce champ est obligatoire.</div>
					<div *ngIf="form.controls['password'].errors && form.controls['password'].errors['minlength']">Ce champ doit faire au moins {{ form.controls['password'].errors['minlength']['requiredLength'] }} caractères.</div>
				</div>
			</div>

			<div class="text-center">
				<button type="submit" class="btn btn-primary mb-4" [innerHtml]="sending ? 'Connexion en cours ...' : 'Connexion'" [disabled]="form.disabled"></button><br>
				<a routerLink="/password-forgotten">Mot de passe oublié</a>
			</div>
		</form>
	</div>
</div>
