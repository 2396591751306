import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { IEnquiryPaymentSummaryDetail } from 'src/app/interfaces/enquiry-payment-summary-detail.interface';

@Injectable({
  providedIn: 'root'
})
export class EnquiryPaymentSummaryDetailFirestoreService extends FirestoreService<IEnquiryPaymentSummaryDetail> {
  protected basePath = 'enquiryPaymentSummaryDetails';
}
