<breadcrumbs
    [items]="[
        {
            title: isLieuDit ? 'Liste des lieux-dits' : 'Liste des aéroports',
            path: isLieuDit ? '/admin/lieux-dits/list' : '/admin/airports/list'
        }
    ]"
></breadcrumbs>

<div class="row">
    <div class="col-md-3 mb-4">
        <a
            [routerLink]="isLieuDit ? '/admin/lieux-dits/add' : '/admin/airports/add'"
            class="btn btn-primary"
            *ngIf="hasAclAccess(isLieuDit ? EnumAcl.lieuxDitsAdd : EnumAcl.airportsAdd)"
            [innerText]="isLieuDit ? '+ Nouveau lieu-dit' : '+ Nouveau aéroport'"
        ></a>
    </div>
</div>

<div class="row justify-content-center">
    <div class="col-md-6">
        <app-airport-picker
            [inputName]="'airportId'"
            (setValueToFormControl)="setValueToFormControl($event)"
        >
        </app-airport-picker>
    </div>
</div>
<div class="table-responsive">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th
                    scope="col"
                    class="sortable"
                    *ngFor="let field of paginationService.pagination[currentPagination].fields"
                    [class.is-sorted]="
                        paginationService.pagination[currentPagination].orderBy.field == field.code
                    "
                    [class.asc]="
                        paginationService.pagination[currentPagination].orderBy.field ==
                            field.code &&
                        paginationService.pagination[currentPagination].orderBy.direction == 'asc'
                    "
                    [class.desc]="
                        paginationService.pagination[currentPagination].orderBy.field ==
                            field.code &&
                        paginationService.pagination[currentPagination].orderBy.direction == 'desc'
                    "
                    (click)="paginationService.sortField(currentPagination, field.code)"
                    [innerHtml]="field.title"
                ></th>
            </tr>
        </thead>
        <tbody *ngIf="paginationService.loading">
            <tr *ngFor="let number of [].constructor(10)">
                <td *ngFor="let field of paginationService.pagination[currentPagination].fields">
                    <div class="skeleton-text"></div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="!paginationService.loading">
            <tr
                *ngIf="
                    !paginationService.pagination[currentPagination].data ||
                    !paginationService.pagination[currentPagination].data.length
                "
                class="table-warning text-center"
            >
                <td
                    [attr.colspan]="paginationService.pagination[currentPagination].fields.length"
                    [innerText]="
                        isLieuDit
                            ? 'Aucun lieu-dit n\'a été trouvé.'
                            : 'Aucun aéroport n\'a été trouvé.'
                    "
                ></td>
            </tr>
            <tr
                *ngFor="let airport of paginationService.pagination[currentPagination].data"
                [routerLink]="
                    isLieuDit
                        ? '/admin/lieux-dits/view/' + airport.id
                        : '/admin/airports/view/' + airport.id
                "
                class="clickable"
            >
                <td [innerHtml]="airport.iataCode"></td>
                <td [innerHtml]="airport.title"></td>
                <td
                    [innerHtml]="
                        airport.continentCode + ' - ' + getContinentLabel(airport.continentCode)
                    "
                ></td>
                <td
                    [innerHtml]="airport.countryCode + ' - ' + getCountryLabel(airport.countryCode)"
                ></td>
            </tr>
        </tbody>
    </table>
</div>

<nav aria-label="Liste navigation" class="mt-4 mb-4" *ngIf="!paginationService.loading">
    <ul class="pagination justify-content-center">
        <li
            class="page-item"
            [class.disabled]="paginationService.pagination[currentPagination].currentPage === 0"
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadPreviousPage(currentPagination)"
            >
                Précédent
            </button>
        </li>
        <li class="page-item disabled">
            <span
                class="page-link"
                [innerHtml]="paginationService.pagination[currentPagination].currentPage + 1"
            ></span>
        </li>
        <li
            class="page-item"
            [class.disabled]="
                paginationService.pagination[currentPagination].data &&
                (!paginationService.pagination[currentPagination].data.length ||
                    paginationService.pagination[currentPagination].data.length <
                        paginationService.pagination[currentPagination].limit)
            "
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadNextPage(currentPagination)"
            >
                Suivant
            </button>
        </li>
    </ul>
</nav>
