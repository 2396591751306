<breadcrumbs
    [items]="[
        {
            title: 'Liste des factures',
            path: '/admin/invoices/list'
        },
        {
            title: 'Import de factures',
            path: '/admin/invoices/import'
        }
    ]"
></breadcrumbs>
<ng-container *ngIf="!sending">
    <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6">
            <p class="text-center">
                Veuillez importer le fichier d'import en se basant sur le fichier d'exemple
                <a href="assets/exemple_import_factures.xlsx" target="_blank">ici</a>.
            </p>

            <div class="card mb-4">
                <div class="card-body text-center">
                    <div class="form-group mb-0">
                        <input
                            type="file"
                            id="fileInput"
                            class="form-control"
                            (change)="changeInputFile($event)"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />
                    </div>
                </div>
            </div>

            <div class="card mb-4" *ngIf="data.headers.length && !imported">
                <div class="card-header text-center">
                    {{ columnsToAffect.length }} colonnes à affecter
                    <button
                        type="button"
                        class="btn btn-secondary btn-sm float-end"
                        *ngIf="!displayColumnAffectation"
                        (click)="editColumnAffectation()"
                    >
                        <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                </div>
                <ul class="list-group list-group-flush" *ngIf="displayColumnAffectation">
                    <li
                        class="list-group-item"
                        *ngFor="let columnToAffect of columnsToAffect; let i = index"
                    >
                        <div class="row form-group mb-0">
                            <label
                                [attr.for]="'column-to-affect-' + i"
                                [innerText]="columnToAffect.name"
                                class="col-md-6 col-lg-4"
                            ></label>
                            <div class="col-md-6 col-lg-8">
                                <select
                                    class="form-select"
                                    [id]="'column-to-affect-' + i"
                                    [(ngModel)]="columnToAffect.indexAffected"
                                    required
                                    [class.is-invalid]="columnToAffect.indexAffected === null"
                                >
                                    <option [ngValue]="null">
                                        -- Affecter la colonne du fichier
                                    </option>
                                    <option
                                        [value]="j"
                                        *ngFor="let header of data.headers; let j = index"
                                        [innerText]="header"
                                    ></option>
                                </select>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="card-footer text-center" *ngIf="displayColumnAffectation">
                    <button type="button" class="btn btn-primary" (click)="goPreview()">
                        Étape suivante
                    </button>
                </div>
            </div>
        </div>
    </div>

    <form
        *ngIf="!displayColumnAffectation && !imported"
        (submit)="submitForm()"
        [formGroup]="form"
        class="main-form"
    >
        <div class="card mb-4">
            <div class="card-header">
                Aperçu des {{ form.length }} factures à importer
                <div
                    class="text-end"
                    [class.text-success]="form.valid"
                    [class.text-danger]="!form.valid"
                >
                    {{ form.valid ? "Tout est bon." : "Des erreurs existent ci-dessous." }}
                    <button
                        type="button"
                        class="btn btn-primary btn-sm ms-2"
                        [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                        [disabled]="form.disabled || !form.valid || sending"
                        (click)="submitForm()"
                        *ngIf="form.valid"
                    ></button>
                </div>
            </div>
            <ul class="list-group list-group-flush">
                <li
                    class="list-group-item"
                    [formGroupName]="i"
                    *ngFor="let invoiceFormGroup of form['controls']; let i = index"
                >
                    <div *ngIf="!invoiceFormGroup.value.opened" class="invoice-collapsed">
                        <div>
                            <strong [innerText]="invoiceFormGroup.value.ref"></strong> /
                            {{ invoiceFormGroup.value.clientBillingInfos.clientName }} /
                            {{
                                formatPrice(
                                    invoiceFormGroup.value.amountTtcTotal,
                                    invoiceFormGroup.value.currency
                                )
                            }}
                            <button
                                type="button"
                                class="btn btn-secondary btn-sm ms-2"
                                (click)="openInvoiceForm(i)"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>
                        </div>
                        <div class="text-end">
                            {{ i + 1 }} / {{ form.length }}
                            <fa-icon
                                [icon]="
                                    invoiceFormGroup.valid ? faCircleCheck : faTriangleExclamation
                                "
                                class="valid-icon-information ms-2"
                                [class.success]="invoiceFormGroup.valid"
                                [class.danger]="!invoiceFormGroup.valid"
                            ></fa-icon>
                        </div>
                    </div>

                    <ng-container *ngIf="invoiceFormGroup.value.opened">
                        <div class="invoice-opened">
                            <button
                                type="button"
                                class="btn btn-secondary btn-sm"
                                (click)="closeInvoiceForm(i)"
                            >
                                <fa-icon [icon]="faCancel"></fa-icon> Fermer
                            </button>
                            <div class="text-end">
                                {{ i + 1 }} / {{ form.length }}
                                <fa-icon
                                    [icon]="
                                        invoiceFormGroup.valid
                                            ? faCircleCheck
                                            : faTriangleExclamation
                                    "
                                    class="valid-icon-information ms-2"
                                    [class.success]="invoiceFormGroup.valid"
                                    [class.danger]="!invoiceFormGroup.valid"
                                ></fa-icon>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label [attr.for]="i + '-ref'">Référence</label>
                                    <input
                                        class="form-control"
                                        [id]="i + '-ref'"
                                        formControlName="ref"
                                        type="text"
                                        [class.is-invalid]="
                                            getItemField(i, 'ref') &&
                                            getItemField(i, 'ref').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                getItemField(i, 'ref') &&
                                                getItemField(i, 'ref').errors &&
                                                getItemField(i, 'ref').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label [attr.for]="i + '-invoiceType'">Type de facture</label>
                                    <select
                                        formControlName="invoiceType"
                                        class="form-select"
                                        [class.is-invalid]="
                                            getItemField(i, 'invoiceType') &&
                                            getItemField(i, 'invoiceType').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    >
                                        <option [ngValue]="null">-- Choisissez --</option>
                                        <option
                                            [value]="invoiceType"
                                            *ngFor="let invoiceType of getInvoiceTypes()"
                                            [innerText]="getEnumInvoiceTypeLabel(invoiceType)"
                                        ></option>
                                    </select>
                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                getItemField(i, 'invoiceType') &&
                                                getItemField(i, 'invoiceType').errors &&
                                                getItemField(i, 'invoiceType').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label [attr.for]="i + '-language'">Langue</label>
                                    <select
                                        formControlName="language"
                                        class="form-select"
                                        [class.is-invalid]="
                                            getItemField(i, 'language') &&
                                            getItemField(i, 'language').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    >
                                        <option [ngValue]="null">-- Choisissez --</option>
                                        <option
                                            [value]="language"
                                            *ngFor="let language of getLanguages()"
                                            [innerText]="getInvoiceLanguageLabel(language)"
                                        ></option>
                                    </select>
                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                getItemField(i, 'language') &&
                                                getItemField(i, 'language').errors &&
                                                getItemField(i, 'language').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label [attr.for]="i + '-enquiryId'">Dossier</label>
                                    <app-enquiry-ref-picker
                                        [contractRefOnly]="true"
                                        [defaultValue]="getItemField(i, 'enquiryId').value"
                                        [inputName]="i + '.enquiryId'"
                                        (setValueToFormControl)="setValueToFormControl($event)"
                                        [isInvalid]="
                                            getItemField(i, 'enquiryId') &&
                                            getItemField(i, 'enquiryId').status == 'INVALID'
                                        "
                                    ></app-enquiry-ref-picker>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                getItemField(i, 'enquiryId') &&
                                                getItemField(i, 'enquiryId').errors &&
                                                getItemField(i, 'enquiryId').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label [attr.for]="i + '-currency'">Devise</label>
                                    <select
                                        formControlName="currency"
                                        class="form-select"
                                        [class.is-invalid]="
                                            getItemField(i, 'currency') &&
                                            getItemField(i, 'currency').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    >
                                        <option [ngValue]="null">-- Choisissez --</option>
                                        <option
                                            [value]="currency"
                                            *ngFor="let currency of getCurrencies()"
                                            [innerText]="getEnumCurrencyLabel(currency)"
                                        ></option>
                                    </select>
                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                getItemField(i, 'currency') &&
                                                getItemField(i, 'currency').errors &&
                                                getItemField(i, 'currency').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label [attr.for]="i + '-issueDate'">Date facture</label>
                                    <input
                                        class="form-control"
                                        [id]="i + '-issueDate'"
                                        formControlName="issueDate"
                                        type="date"
                                        [class.is-invalid]="
                                            getItemField(i, 'issueDate') &&
                                            getItemField(i, 'issueDate').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                getItemField(i, 'issueDate') &&
                                                getItemField(i, 'issueDate').errors &&
                                                getItemField(i, 'issueDate').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label [attr.for]="i + '-dueDate'">Date échéance</label>
                                    <input
                                        class="form-control"
                                        [id]="i + '-dueDate'"
                                        formControlName="dueDate"
                                        type="date"
                                        [class.is-invalid]="
                                            getItemField(i, 'dueDate') &&
                                            getItemField(i, 'dueDate').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                getItemField(i, 'dueDate') &&
                                                getItemField(i, 'dueDate').errors &&
                                                getItemField(i, 'dueDate').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label [attr.for]="i + '-internalNote'">Notes internes</label>
                                    <textarea
                                        [id]="i + '-internalNote'"
                                        formControlName="internalNote"
                                        class="form-control"
                                        rows="1"
                                        [class.is-invalid]="
                                            getItemField(i, 'internalNote') &&
                                            getItemField(i, 'internalNote').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    ></textarea>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                getItemField(i, 'internalNote') &&
                                                getItemField(i, 'internalNote').errors &&
                                                getItemField(i, 'internalNote').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <fieldset formGroupName="clientBillingInfos">
                            <legend>Client</legend>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label [attr.for]="i + 'clientBillingInfos-clientName'"
                                            >Nom du client</label
                                        >
                                        <input
                                            class="form-control"
                                            [id]="i + '-clientBillingInfos-clientName'"
                                            formControlName="clientName"
                                            type="text"
                                            [class.is-invalid]="
                                                getClientBillingInfosField(i, 'clientName') &&
                                                getClientBillingInfosField(i, 'clientName')
                                                    .status == 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    getClientBillingInfosField(i, 'clientName') &&
                                                    getClientBillingInfosField(i, 'clientName')
                                                        .errors &&
                                                    getClientBillingInfosField(i, 'clientName')
                                                        .errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label [attr.for]="i + 'clientBillingInfos-tvaNumber'"
                                            >TVA Intracommunautaire</label
                                        >
                                        <input
                                            class="form-control"
                                            [id]="i + '-clientBillingInfos-tvaNumber'"
                                            formControlName="tvaNumber"
                                            type="text"
                                            [class.is-invalid]="
                                                getClientBillingInfosField(i, 'tvaNumber') &&
                                                getClientBillingInfosField(i, 'tvaNumber').status ==
                                                    'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    getClientBillingInfosField(i, 'tvaNumber') &&
                                                    getClientBillingInfosField(i, 'tvaNumber')
                                                        .errors &&
                                                    getClientBillingInfosField(i, 'tvaNumber')
                                                        .errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label [attr.for]="i + 'clientBillingInfos-siret'"
                                            >Siret</label
                                        >
                                        <input
                                            class="form-control"
                                            [id]="i + '-clientBillingInfos-siret'"
                                            formControlName="siret"
                                            type="text"
                                            [class.is-invalid]="
                                                getClientBillingInfosField(i, 'siret') &&
                                                getClientBillingInfosField(i, 'siret').status ==
                                                    'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    getClientBillingInfosField(i, 'siret') &&
                                                    getClientBillingInfosField(i, 'siret').errors &&
                                                    getClientBillingInfosField(i, 'siret').errors[
                                                        'required'
                                                    ]
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label [attr.for]="i + 'clientBillingInfos-street'"
                                            >Rue</label
                                        >
                                        <input
                                            class="form-control"
                                            [id]="i + '-clientBillingInfos-street'"
                                            formControlName="street"
                                            type="text"
                                            [class.is-invalid]="
                                                getClientBillingInfosField(i, 'street') &&
                                                getClientBillingInfosField(i, 'street').status ==
                                                    'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    getClientBillingInfosField(i, 'street') &&
                                                    getClientBillingInfosField(i, 'street')
                                                        .errors &&
                                                    getClientBillingInfosField(i, 'street').errors[
                                                        'required'
                                                    ]
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label [attr.for]="i + 'clientBillingInfos-postalCode'"
                                            >Code postal</label
                                        >
                                        <input
                                            class="form-control"
                                            [id]="i + '-clientBillingInfos-postalCode'"
                                            formControlName="postalCode"
                                            type="text"
                                            [class.is-invalid]="
                                                getClientBillingInfosField(i, 'postalCode') &&
                                                getClientBillingInfosField(i, 'postalCode')
                                                    .status == 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    getClientBillingInfosField(i, 'postalCode') &&
                                                    getClientBillingInfosField(i, 'postalCode')
                                                        .errors &&
                                                    getClientBillingInfosField(i, 'postalCode')
                                                        .errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label [attr.for]="i + 'clientBillingInfos-city'"
                                            >Ville</label
                                        >
                                        <input
                                            class="form-control"
                                            [id]="i + '-clientBillingInfos-city'"
                                            formControlName="city"
                                            type="text"
                                            [class.is-invalid]="
                                                getClientBillingInfosField(i, 'city') &&
                                                getClientBillingInfosField(i, 'city').status ==
                                                    'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    getClientBillingInfosField(i, 'city') &&
                                                    getClientBillingInfosField(i, 'city').errors &&
                                                    getClientBillingInfosField(i, 'city').errors[
                                                        'required'
                                                    ]
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label [attr.for]="i + 'clientBillingInfos-countryCode'"
                                            >Pays</label
                                        >
                                        <select
                                            class="form-select"
                                            id="countryCode"
                                            formControlName="countryCode"
                                            [class.is-invalid]="
                                                getClientBillingInfosField(i, 'countryCode') &&
                                                getClientBillingInfosField(i, 'countryCode')
                                                    .status == 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        >
                                            <option [ngValue]="null">-- Choisissez --</option>
                                            <option
                                                [value]="country.value"
                                                *ngFor="let country of countriesList"
                                                [innerHtml]="country.title"
                                            ></option>
                                        </select>

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    getClientBillingInfosField(i, 'countryCode') &&
                                                    getClientBillingInfosField(i, 'countryCode')
                                                        .errors &&
                                                    getClientBillingInfosField(i, 'countryCode')
                                                        .errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>
                                {{ getProducts(i)?.length ?? 0 }}
                                {{ getProducts(i)?.length > 1 ? "produits" : "produit" }}
                            </legend>
                            <ul class="list-group mb-4" formArrayName="products">
                                <li
                                    class="list-group-item list-group-item-warning text-center"
                                    *ngIf="getProducts(i).errors?.missingProducts"
                                >
                                    Les produits sont manquants.
                                </li>
                                <li
                                    class="list-group-item"
                                    [formGroupName]="j"
                                    *ngFor="
                                        let productFormGroup of getProducts(i)['controls'];
                                        let j = index
                                    "
                                >
                                    <div class="row justify-content-center">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label [attr.for]="i + '-descriptionTitle'"
                                                    >Libellé</label
                                                >
                                                <input
                                                    class="form-control"
                                                    [id]="
                                                        i + '-products-' + j + '-descriptionTitle'
                                                    "
                                                    formControlName="descriptionTitle"
                                                    type="text"
                                                    [class.is-invalid]="
                                                        getProductField(i, j, 'descriptionTitle') &&
                                                        getProductField(i, j, 'descriptionTitle')
                                                            .status == 'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                />

                                                <div class="invalid-tooltip">
                                                    <div
                                                        *ngIf="
                                                            getProductField(
                                                                i,
                                                                j,
                                                                'descriptionTitle'
                                                            ) &&
                                                            getProductField(
                                                                i,
                                                                j,
                                                                'descriptionTitle'
                                                            ).errors &&
                                                            getProductField(
                                                                i,
                                                                j,
                                                                'descriptionTitle'
                                                            ).errors['required']
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label [attr.for]="i + '-amountHt'"
                                                    >Montant HT</label
                                                >
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        [id]="i + '-products-' + j + '-amountHt'"
                                                        formControlName="amountHt"
                                                        type="number"
                                                        [class.is-invalid]="
                                                            getProductField(i, j, 'amountHt') &&
                                                            getProductField(i, j, 'amountHt')
                                                                .status == 'INVALID'
                                                                ? 'danger'
                                                                : ''
                                                        "
                                                    />
                                                    <span
                                                        class="input-group-text input-group-text-end"
                                                        [innerText]="
                                                            getEnumCurrencySymbol(
                                                                invoiceFormGroup.value.currency
                                                            )
                                                        "
                                                        *ngIf="invoiceFormGroup.value.currency"
                                                    ></span>
                                                </div>

                                                <div class="invalid-tooltip">
                                                    <div
                                                        *ngIf="
                                                            getProductField(i, j, 'amountHt') &&
                                                            getProductField(i, j, 'amountHt')
                                                                .errors &&
                                                            getProductField(i, j, 'amountHt')
                                                                .errors['required']
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label [attr.for]="i + '-amountTtc'"
                                                    >Montant net TTC</label
                                                >
                                                <div class="input-group">
                                                    <input
                                                        class="form-control"
                                                        [id]="i + '-products-' + j + '-amountTtc'"
                                                        formControlName="amountTtc"
                                                        type="number"
                                                        [class.is-invalid]="
                                                            getProductField(i, j, 'amountTtc') &&
                                                            getProductField(i, j, 'amountTtc')
                                                                .status == 'INVALID'
                                                                ? 'danger'
                                                                : ''
                                                        "
                                                    />
                                                    <span
                                                        class="input-group-text input-group-text-end"
                                                        [innerText]="
                                                            getEnumCurrencySymbol(
                                                                invoiceFormGroup.value.currency
                                                            )
                                                        "
                                                        *ngIf="invoiceFormGroup.value.currency"
                                                    ></span>
                                                </div>

                                                <div class="invalid-tooltip">
                                                    <div
                                                        *ngIf="
                                                            getProductField(i, j, 'amountTtc') &&
                                                            getProductField(i, j, 'amountTtc')
                                                                .errors &&
                                                            getProductField(i, j, 'amountTtc')
                                                                .errors['required']
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="row justify-content-center">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label [attr.for]="i + '-amountHtTotal'">Total HT</label>
                                        <div class="input-group">
                                            <input
                                                class="form-control"
                                                [id]="i + '-amountHtTotal'"
                                                formControlName="amountHtTotal"
                                                type="number"
                                                [class.is-invalid]="
                                                    getItemField(i, 'amountHtTotal') &&
                                                    getItemField(i, 'amountHtTotal').status ==
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />
                                            <span
                                                class="input-group-text input-group-text-end"
                                                [innerText]="
                                                    getEnumCurrencySymbol(
                                                        invoiceFormGroup.value.currency
                                                    )
                                                "
                                                *ngIf="invoiceFormGroup.value.currency"
                                            ></span>

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        getItemField(i, 'amountHtTotal') &&
                                                        getItemField(i, 'amountHtTotal').errors &&
                                                        getItemField(i, 'amountHtTotal').errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>

                                                <div
                                                    *ngIf="
                                                        getItemField(i, 'amountHtTotal') &&
                                                        getItemField(i, 'amountHtTotal').errors &&
                                                        getItemField(i, 'amountHtTotal').errors[
                                                            'incorrectAmount'
                                                        ]
                                                    "
                                                >
                                                    Le montant est incorrect par rapport à la somme
                                                    du montant HT des produits ({{
                                                        getItemField(i, "amountHtTotal").errors[
                                                            "incorrectAmount"
                                                        ].sumAmount +
                                                            getEnumCurrencySymbol(
                                                                invoiceFormGroup.value.currency
                                                            )
                                                    }})
                                                </div>

                                                <div
                                                    *ngIf="
                                                        getItemField(i, 'amountHtTotal') &&
                                                        getItemField(i, 'amountHtTotal').errors &&
                                                        getItemField(i, 'amountHtTotal').errors[
                                                            'shouldNotBeZero'
                                                        ]
                                                    "
                                                >
                                                    Le montant doit être différent de zéro.
                                                </div>

                                                <div
                                                    *ngIf="
                                                        getItemField(i, 'amountHtTotal') &&
                                                        getItemField(i, 'amountHtTotal').errors &&
                                                        getItemField(i, 'amountHtTotal').errors[
                                                            'shouldBeNegative'
                                                        ]
                                                    "
                                                >
                                                    Le montant doit être négatif.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label [attr.for]="i + '-amountTtcTotal'">Total TTC</label>
                                        <div class="input-group">
                                            <input
                                                class="form-control"
                                                [id]="i + '-amountTtcTotal'"
                                                formControlName="amountTtcTotal"
                                                type="number"
                                                [class.is-invalid]="
                                                    getItemField(i, 'amountTtcTotal') &&
                                                    getItemField(i, 'amountTtcTotal').status ==
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />
                                            <span
                                                class="input-group-text input-group-text-end"
                                                [innerText]="
                                                    getEnumCurrencySymbol(
                                                        invoiceFormGroup.value.currency
                                                    )
                                                "
                                                *ngIf="invoiceFormGroup.value.currency"
                                            ></span>

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        getItemField(i, 'amountTtcTotal') &&
                                                        getItemField(i, 'amountTtcTotal').errors &&
                                                        getItemField(i, 'amountTtcTotal').errors[
                                                            'required'
                                                        ]
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>

                                                <div
                                                    *ngIf="
                                                        getItemField(i, 'amountTtcTotal') &&
                                                        getItemField(i, 'amountTtcTotal').errors &&
                                                        getItemField(i, 'amountTtcTotal').errors[
                                                            'incorrectAmount'
                                                        ]
                                                    "
                                                >
                                                    Le montant est incorrect par rapport à la somme
                                                    du montant TTC des produits ({{
                                                        getItemField(i, "amountTtcTotal").errors[
                                                            "incorrectAmount"
                                                        ].sumAmount +
                                                            getEnumCurrencySymbol(
                                                                invoiceFormGroup.value.currency
                                                            )
                                                    }})
                                                </div>

                                                <div
                                                    *ngIf="
                                                        getItemField(i, 'amountTtcTotal') &&
                                                        getItemField(i, 'amountTtcTotal').errors &&
                                                        getItemField(i, 'amountTtcTotal').errors[
                                                            'shouldNotBeZero'
                                                        ]
                                                    "
                                                >
                                                    Le montant doit être différent de zéro.
                                                </div>

                                                <div
                                                    *ngIf="
                                                        getItemField(i, 'amountTtcTotal') &&
                                                        getItemField(i, 'amountTtcTotal').errors &&
                                                        getItemField(i, 'amountTtcTotal').errors[
                                                            'shouldBeNegative'
                                                        ]
                                                    "
                                                >
                                                    Le montant doit être négatif.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </ng-container>
                </li>
            </ul>

            <div class="card-footer">
                <button type="button" class="btn btn-secondary" (click)="editColumnAffectation()">
                    Étape précédente
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                    [disabled]="form.disabled || !form.valid || sending"
                    (click)="submitForm()"
                ></button>
            </div>
        </div>
    </form>

    <div *ngIf="imported" class="alert alert-success text-center">
        Les {{ importingData.total }} factures ont été importées.
    </div>
</ng-container>

<div *ngIf="sending">
    <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6">
            <div class="card">
                <div class="card-header">Importation des factures en cours ...</div>
                <div class="card-body">
                    <div
                        class="progress"
                        role="progressbar"
                        aria-label="Example with label"
                        [attr.aria-valuemin]="0"
                        [attr.aria-valuenow]="importingData.index"
                        [attr.aria-valuemax]="importingData.total"
                    >
                        <div
                            class="progress-bar progress-bar-striped progress-bar-animated overflow-visible"
                            [style.width]="(importingData.index / importingData.total) * 100 + '%'"
                        >
                            {{ importingData.index + "/" + importingData.total }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
