<breadcrumbs
    [items]="
        encaissementId
            ? [
                  {
                      title: 'Liste des factures',
                      path: '/admin/invoices/list'
                  },
                  {
                      title: getInvoiceTitle(invoice),
                      path: '/admin/invoices/view/' + invoiceId
                  },
                  {
                      title: 'Liste des encaissements',
                      path: '/admin/encaissements/list/invoice/' + invoice?.id
                  },
                  {
                      title: encaissement ? encaissement.libelle : '',
                      path: '/admin/encaissements/view/' + encaissementId
                  },
                  {
                      title: 'Édition d\'un encaissement',
                      path: '/admin/encaissements/edit/' + encaissementId
                  }
              ]
            : [
                  {
                      title: 'Liste des factures',
                      path: '/admin/invoices/list'
                  },
                  {
                      title: getInvoiceTitle(invoice),
                      path: '/admin/invoices/view/' + invoiceId
                  },
                  {
                      title: 'Liste des encaissements',
                      path: '/admin/encaissements/list'
                  },
                  {
                      title: 'Ajout d\'un encaissement',
                      path: '/admin/encaissements/add'
                  }
              ]
    "
></breadcrumbs>

<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="name">Libellé sur le compte</label>
                        <input
                            class="form-control"
                            id="libelle"
                            formControlName="libelle"
                            type="text"
                            required
                            (keyup)="getSuggestionInvoices()"
                            [class.is-invalid]="
                                (form.touched || form.controls['libelle'].touched) &&
                                form.controls['libelle'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['libelle'].touched) &&
                                    form.controls['libelle'].errors &&
                                    form.controls['libelle'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="encaissementTypeId">Type d'encaissement</label>
                        <select
                            class="form-select"
                            id="encaissementTypeId"
                            formControlName="encaissementTypeId"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['encaissementTypeId'].touched) &&
                                form.controls['encaissementTypeId'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="encaissementType.id"
                                *ngFor="let encaissementType of encaissementTypesList"
                                [innerHtml]="encaissementType.name"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['encaissementTypeId'].touched) &&
                                    form.controls['encaissementTypeId'].errors &&
                                    form.controls['encaissementTypeId'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="name">Montant</label>
                        <input
                            class="form-control"
                            id="amount"
                            formControlName="amount"
                            type="number"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['amount'].touched) &&
                                form.controls['amount'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (keyup)="getSuggestionInvoices()"
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['amount'].touched) &&
                                    form.controls['amount'].errors &&
                                    form.controls['amount'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="currency">Devise</label>
                        <select
                            class="form-select"
                            id="currency"
                            formControlName="currency"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['currency'].touched) &&
                                form.controls['currency'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (keyup)="getSuggestionInvoices()"
                        >
                            <option
                                [value]="currency"
                                *ngFor="let currency of getCurrencies()"
                                [innerHtml]="
                                    getEnumCurrencySymbol(currency) +
                                    ' (' +
                                    getEnumCurrencyLabel(currency) +
                                    ')'
                                "
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['currency'].touched) &&
                                    form.controls['currency'].errors &&
                                    form.controls['currency'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="bankAccountId">Compte bancaire</label>
                        <select
                            class="form-select"
                            id="bankAccountId"
                            formControlName="bankAccountId"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['bankAccountId'].touched) &&
                                form.controls['bankAccountId'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="bankAccount.id"
                                *ngFor="let bankAccount of bankAccountsList"
                                [innerHtml]="bankAccount.name"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['bankAccountId'].touched) &&
                                    form.controls['bankAccountId'].errors &&
                                    form.controls['bankAccountId'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="date">Date</label>
                        <input
                            class="form-control"
                            id="date"
                            formControlName="date"
                            type="date"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['date'].touched) &&
                                form.controls['date'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['date'].touched) &&
                                    form.controls['date'].errors &&
                                    form.controls['date'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="invoiceId">Facture / Avoir</label>
                        <app-invoice-ref-picker
                            [defaultValue]="form.value.invoiceId"
                            inputName="invoiceId"
                            (setValueToFormControl)="setValueToFormControl($event)"
                        ></app-invoice-ref-picker>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['invoiceId'].touched) &&
                                    form.controls['invoiceId'].errors &&
                                    form.controls['invoiceId'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>

                        <div class="suggestion-invoices" *ngIf="suggestionInvoices.length">
                            <label *ngIf="suggestionInvoices.length <= 1">Facture suggérée</label>
                            <label *ngIf="suggestionInvoices.length > 1">Factures suggérées</label>
                            <div class="list-group">
                                <button
                                    type="button"
                                    class="list-group-item list-group-item-info list-group-item-action"
                                    *ngFor="let suggestionInvoice of suggestionInvoices"
                                    (click)="selectSuggestedInvoice(suggestionInvoice)"
                                >
                                    <span class="icon">+</span>
                                    Facture {{ suggestionInvoice.invoice.ref }} :
                                    {{
                                        formatPrice(
                                            suggestionInvoice.invoice.amountTtcTotal,
                                            suggestionInvoice.invoice.currency
                                        )
                                    }}<br />
                                    Raison(s) :
                                    <ul *ngIf="suggestionInvoice.reasons.length">
                                        <li
                                            *ngFor="let reason of suggestionInvoice.reasons"
                                            class="reason"
                                            [innerText]="getSuggestionInvoiceReasonLabel(reason)"
                                        ></li>
                                    </ul>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="is-reconciled"
                            formControlName="isReconciled"
                        />
                        <label class="form-check-label" for="is-reconciled">Est pointé ?</label>
                    </div>

                    <div class="form-group">
                        <label for="comment">Commentaire</label>
                        <textarea
                            class="form-control"
                            id="comment"
                            formControlName="comment"
                            [class.is-invalid]="
                                (form.touched || form.controls['comment'].touched) &&
                                form.controls['comment'].status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></textarea>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['comment'].touched) &&
                                    form.controls['comment'].errors &&
                                    form.controls['comment'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="
                    encaissementId
                        ? '/admin/encaissements/view/' + encaissementId
                        : '/admin/encaissements/list'
                "
                class="btn btn-outline-primary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>
