<div class="table-responsive">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th scope="col" *ngIf="!hideEnquiryColumns">N° Dossier</th>
                <th scope="col" *ngIf="!hideEnquiryColumns">Client/Agence</th>
                <th scope="col">Date du vol</th>
                <th scope="col">Avion</th>
                <th scope="col">Opérateur</th>
                <th scope="col">Routing</th>
                <th scope="col">ETD GMT</th>
                <th scope="col">ETD LT</th>
                <th scope="col" class="text-center">
                    PAX <br />
                    Poids/Volume
                </th>
                <th scope="col" class="text-center">Remarque</th>
                <th
                    scope="col"
                    class="text-center"
                    *ngIf="hasAclAccess(EnumAcl.flightChecklistsView)"
                >
                    Checklist
                </th>
            </tr>
        </thead>
        <tbody *ngIf="loading">
            <tr *ngFor="let number of [].constructor(10)">
                <td
                    *ngFor="
                        let number2 of [].constructor(
                            hideEnquiryColumns
                                ? hasAclAccess(EnumAcl.flightChecklistsView)
                                    ? 9
                                    : 8
                                : hasAclAccess(EnumAcl.flightChecklistsView)
                                ? 11
                                : 10
                        )
                    "
                >
                    <div class="skeleton-text"></div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="!loading">
            <tr *ngIf="!enquiryFlights.length" class="table-warning text-center">
                <td
                    [attr.colspan]="
                        hideEnquiryColumns
                            ? hasAclAccess(EnumAcl.flightChecklistsView)
                                ? 9
                                : 8
                            : hasAclAccess(EnumAcl.flightChecklistsView)
                            ? 11
                            : 10
                    "
                >
                    Aucun vol n'a été trouvé pour ces dates.
                </td>
            </tr>
            <tr *ngFor="let enquiryFlight of enquiryFlights">
                <td *ngIf="!hideEnquiryColumns">
                    <a
                        [routerLink]="'/admin/enquiry/view/' + enquiryFlight.enquiryId"
                        [innerText]="enquiryFlight.enquiryRefContractTitle"
                    ></a>
                </td>
                <td *ngIf="!hideEnquiryColumns">
                    <a
                        [routerLink]="'/admin/clients/view/' + enquiryFlight.clientId"
                        [innerText]="enquiryFlight.clientName"
                    ></a>
                </td>
                <td
                    [innerText]="
                        enquiryFlight.departDate | date : 'EE dd MMM yyyy' : undefined : 'fr'
                    "
                ></td>
                <td [innerText]="enquiryFlight.aircraftTitle"></td>
                <td>
                    <a
                        [routerLink]="'/admin/airlines/view/' + enquiryFlight.airlineId"
                        [innerText]="enquiryFlight.airlineTitle"
                    ></a>
                </td>
                <td>
                    <a
                        [routerLink]="'/admin/airports/view/' + enquiryFlight.airportDepartId"
                        [innerText]="
                            enquiryFlight.airportDepartIataCode ?? enquiryFlight.airportDepartTitle
                        "
                    ></a>
                    -
                    <a
                        [routerLink]="'/admin/airports/view/' + enquiryFlight.airportArrivalId"
                        [innerText]="
                            enquiryFlight.airportArrivalIataCode ??
                            enquiryFlight.airportArrivalTitle
                        "
                    ></a>
                </td>
                <td [innerText]="enquiryFlight.departTimeUtc"></td>
                <td [innerText]="enquiryFlight.departTimeLocal"></td>
                <td
                    class="text-center"
                    [innerText]="enquiryFlight.pax + ' pax'"
                    *ngIf="enquiryFlight.enquiryType !== EnumEnquiryType.cargo"
                ></td>
                <td
                    class="text-center"
                    [innerHtml]="
                        enquiryFlight.weight + 'kg - ' + enquiryFlight.volume + 'm<sup>3</sup>'
                    "
                    *ngIf="enquiryFlight.enquiryType === EnumEnquiryType.cargo"
                ></td>
                <td class="text-center">
                    <div [innerText]="enquiryFlight.comment"></div>
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        (click)="editComment(enquiryFlight)"
                    >
                        <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                </td>
                <td class="text-center" *ngIf="hasAclAccess(EnumAcl.flightChecklistsView)">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        (click)="viewFlightChecklist(enquiryFlight)"
                    >
                        <fa-icon [icon]="faEye"></fa-icon>
                        <span
                            class="ms-2"
                            [innerText]="
                                getFinishedCheckOnTotalForFlight(
                                    flightChecklistTemplateByEnquiryType[enquiryFlight.enquiryType],
                                    flightChecklistByEnquiryFlight[enquiryFlight.id]
                                )
                            "
                            *ngIf="flightChecklistTemplateByEnquiryType[enquiryFlight.enquiryType]"
                        ></span>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div
    class="modal fade"
    id="set-comment-modal"
    tabindex="-1"
    aria-labelledby="set-comment-modal-label"
    aria-hidden="true"
    #modalComment
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="set-comment-modal-label">Remarque</h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <form (submit)="submitFormComment()" [formGroup]="formComment" class="main-form">
                    <div class="form-group mb-0">
                        <textarea
                            formControlName="comment"
                            class="form-control"
                            rows="2"
                        ></textarea>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button
                    type="submit"
                    class="btn btn-primary"
                    [disabled]="formComment.disabled || !formComment.valid"
                    (click)="submitFormComment()"
                >
                    Sauvegarder
                </button>
            </div>
        </div>
    </div>
</div>

<div
    class="modal fade"
    id="checklist-modal"
    tabindex="-1"
    aria-labelledby="checklist-modal-label"
    aria-hidden="true"
    #modalChecklist
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="checklist-modal-label">
                    Checklist
                    <span
                        class="ms-1"
                        *ngIf="flightChecklistTemplate && flightChecklist"
                        [innerText]="
                            getFinishedCheckOnTotalForFlight(
                                flightChecklistTemplate,
                                flightChecklist
                            )
                        "
                    ></span>
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div
                class="accordion accordion-flush"
                id="accordion-checklist"
                *ngIf="loadingFlightChecklistTemplate"
            >
                <div class="accordion-item" *ngFor="let number of [].constructor(3); let i = index">
                    <h2 class="accordion-header">
                        <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapse-' + i"
                            aria-expanded="true"
                            [attr.aria-controls]="'collapse-' + i"
                        >
                            <div class="skeleton-text"></div>
                        </button>
                    </h2>
                    <div [id]="'collapse-' + i" class="accordion-collapse collapse show">
                        <div class="accordion-body">
                            <ul class="list-group">
                                <li
                                    class="list-group-item"
                                    *ngFor="let number2 of [].constructor(3)"
                                >
                                    <div class="skeleton-text"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="alert alert-warning text-center mb-0"
                *ngIf="!loadingFlightChecklistTemplate && !flightChecklistTemplate"
            >
                Aucun modèle de checklist trouvé pour ce département.
            </div>
            <div
                class="accordion accordion-flush"
                id="accordion-checklist"
                *ngIf="!loadingFlightChecklistTemplate && flightChecklistTemplate"
            >
                <div
                    class="accordion-item"
                    *ngFor="let module of flightChecklistTemplate?.modules; let i = index"
                >
                    <h2 class="accordion-header">
                        <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapse-' + i"
                            aria-expanded="true"
                            [attr.aria-controls]="'collapse-' + i"
                            [innerText]="module.title"
                        ></button>
                    </h2>
                    <div [id]="'collapse-' + i" class="accordion-collapse collapse show">
                        <div class="accordion-body">
                            <ul class="list-group">
                                <ng-container *ngFor="let field of module.fields">
                                    <li
                                        class="list-group-item"
                                        *ngIf="flightChecklistFieldValuesById[field.id]"
                                        [class.list-group-item-warning]="
                                            flightChecklistFieldValuesById[field.id].selectedOption
                                                ?.status ===
                                            EnumFlightChecklistTemplateFieldOptionStatus.warning
                                        "
                                        [class.list-group-item-danger]="
                                            flightChecklistFieldValuesById[field.id].selectedOption
                                                ?.status ===
                                            EnumFlightChecklistTemplateFieldOptionStatus.danger
                                        "
                                        [class.list-group-item-success]="
                                            flightChecklistFieldValuesById[field.id].selectedOption
                                                ?.status ===
                                            EnumFlightChecklistTemplateFieldOptionStatus.success
                                        "
                                        [class.list-group-item-info]="
                                            flightChecklistFieldValuesById[field.id].selectedOption
                                                ?.status ===
                                            EnumFlightChecklistTemplateFieldOptionStatus.info
                                        "
                                        [class.list-group-item-primary]="
                                            flightChecklistFieldValuesById[field.id].selectedOption
                                                ?.status ===
                                            EnumFlightChecklistTemplateFieldOptionStatus.primary
                                        "
                                        [class.list-group-item-secondary]="
                                            flightChecklistFieldValuesById[field.id].selectedOption
                                                ?.status ===
                                            EnumFlightChecklistTemplateFieldOptionStatus.secondary
                                        "
                                    >
                                        <div
                                            class="checklist-field"
                                            *ngIf="editingChecklistFieldId !== field.id"
                                        >
                                            <div class="checklist-field-label">
                                                {{
                                                    flightChecklistFieldValuesById[field.id].field
                                                        .text
                                                }}
                                                <button
                                                    type="button"
                                                    class="btn btn-light ms-2"
                                                    rel="tooltip"
                                                    data-bs-title="Historique"
                                                    (click)="showFieldHistory(field.id)"
                                                    *ngIf="
                                                        flightChecklistFieldValuesById[field.id]
                                                            .history.length
                                                    "
                                                >
                                                    <fa-icon [icon]="faClockRotateLeft"></fa-icon>
                                                </button>
                                            </div>
                                            <div
                                                class="checklist-field-value"
                                                *ngIf="
                                                    flightChecklistFieldValuesById[field.id].field
                                                        .type !==
                                                    EnumFlightChecklistTemplateFieldType.select
                                                "
                                            >
                                                <span
                                                    *ngIf="
                                                        flightChecklistFieldValuesById[field.id]
                                                            .value?.value
                                                    "
                                                    [innerText]="
                                                        flightChecklistFieldValuesById[field.id]
                                                            .value.value
                                                    "
                                                ></span>
                                                <em
                                                    class="text-muted"
                                                    *ngIf="
                                                        !flightChecklistFieldValuesById[field.id]
                                                            .value?.value
                                                    "
                                                    >Vide</em
                                                >
                                                <button
                                                    type="button"
                                                    class="btn btn-light ms-2"
                                                    rel="tooltip"
                                                    data-bs-title="Éditer"
                                                    (click)="editChecklistField(field.id)"
                                                    *ngIf="
                                                        hasAclAccess(EnumAcl.flightChecklistsEdit)
                                                    "
                                                >
                                                    <fa-icon [icon]="faEdit"></fa-icon>
                                                </button>
                                            </div>
                                            <div
                                                class="checklist-field-value"
                                                *ngIf="
                                                    flightChecklistFieldValuesById[field.id].field
                                                        .type ===
                                                    EnumFlightChecklistTemplateFieldType.select
                                                "
                                            >
                                                <span
                                                    *ngIf="
                                                        flightChecklistFieldValuesById[field.id]
                                                            .selectedOption
                                                    "
                                                    [innerText]="
                                                        flightChecklistFieldValuesById[field.id]
                                                            .selectedOption?.text
                                                    "
                                                ></span>
                                                <em
                                                    class="text-muted"
                                                    *ngIf="
                                                        !flightChecklistFieldValuesById[field.id]
                                                            .selectedOption
                                                    "
                                                    >Vide</em
                                                >
                                                <button
                                                    type="button"
                                                    class="btn btn-light ms-2"
                                                    rel="tooltip"
                                                    data-bs-title="Éditer"
                                                    (click)="editChecklistField(field.id)"
                                                >
                                                    <fa-icon [icon]="faEdit"></fa-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <ul
                                            class="list-group mt-2"
                                            *ngIf="
                                                editingChecklistFieldId !== field.id &&
                                                showHistoryFieldId === field.id
                                            "
                                        >
                                            <li
                                                class="list-group-item"
                                                *ngFor="
                                                    let item of flightChecklistFieldValuesById[
                                                        field.id
                                                    ].history
                                                "
                                                [class.list-group-item-warning]="
                                                    getFieldStatusFromOption(
                                                        field.id,
                                                        item.value
                                                    ) ===
                                                    EnumFlightChecklistTemplateFieldOptionStatus.warning
                                                "
                                                [class.list-group-item-danger]="
                                                    getFieldStatusFromOption(
                                                        field.id,
                                                        item.value
                                                    ) ===
                                                    EnumFlightChecklistTemplateFieldOptionStatus.danger
                                                "
                                                [class.list-group-item-success]="
                                                    getFieldStatusFromOption(
                                                        field.id,
                                                        item.value
                                                    ) ===
                                                    EnumFlightChecklistTemplateFieldOptionStatus.success
                                                "
                                                [class.list-group-item-info]="
                                                    getFieldStatusFromOption(
                                                        field.id,
                                                        item.value
                                                    ) ===
                                                    EnumFlightChecklistTemplateFieldOptionStatus.info
                                                "
                                                [class.list-group-item-primary]="
                                                    getFieldStatusFromOption(
                                                        field.id,
                                                        item.value
                                                    ) ===
                                                    EnumFlightChecklistTemplateFieldOptionStatus.primary
                                                "
                                                [class.list-group-item-secondary]="
                                                    getFieldStatusFromOption(
                                                        field.id,
                                                        item.value
                                                    ) ===
                                                    EnumFlightChecklistTemplateFieldOptionStatus.secondary
                                                "
                                            >
                                                <strong
                                                    [innerText]="
                                                        getFieldValueFromOption(
                                                            field.id,
                                                            item.value
                                                        )
                                                    "
                                                ></strong>
                                                par
                                                <a
                                                    [routerLink]="
                                                        '/admin/users/view/' + item.userId
                                                    "
                                                    target="_blank"
                                                    [innerText]="item.userFullName"
                                                ></a>
                                                le {{ item.date | date : "dd/MM/yyyy à HH:mm" }}
                                            </li>
                                        </ul>
                                        <div
                                            class="checklist-field"
                                            *ngIf="editingChecklistFieldId === field.id"
                                        >
                                            <div
                                                class="checklist-field-label"
                                                [innerText]="
                                                    flightChecklistFieldValuesById[field.id].field
                                                        .text
                                                "
                                            ></div>
                                            <div class="checklist-field-value">
                                                <div
                                                    class="form-group mb-0"
                                                    *ngIf="
                                                        field.type ===
                                                        EnumFlightChecklistTemplateFieldType.select
                                                    "
                                                >
                                                    <div class="input-group">
                                                        <select
                                                            class="form-select"
                                                            [id]="'checklist-field-' + field.id"
                                                            [(ngModel)]="
                                                                flightChecklistFieldValuesById[
                                                                    field.id
                                                                ].value.value
                                                            "
                                                        >
                                                            <option [ngValue]="null">
                                                                -- Choisissez --
                                                            </option>
                                                            <option
                                                                *ngFor="let option of field.options"
                                                                [value]="option.id"
                                                                [innerText]="option.text"
                                                            ></option>
                                                        </select>
                                                        <button
                                                            type="button"
                                                            class="btn btn-danger"
                                                            rel="tooltip"
                                                            data-bs-title="Annuler"
                                                            (click)="clearEditChecklistField()"
                                                        >
                                                            <fa-icon [icon]="faCancel"></fa-icon>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            class="btn btn-success"
                                                            rel="tooltip"
                                                            data-bs-title="Sauvegarder"
                                                            (click)="saveEditChecklistField()"
                                                        >
                                                            <fa-icon [icon]="faCheck"></fa-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="form-group mb-0"
                                                    *ngIf="
                                                        field.type !==
                                                        EnumFlightChecklistTemplateFieldType.select
                                                    "
                                                >
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            [id]="'checklist-field-' + field.id"
                                                            [(ngModel)]="
                                                                flightChecklistFieldValuesById[
                                                                    field.id
                                                                ].value.value
                                                            "
                                                        />
                                                        <button
                                                            type="button"
                                                            class="btn btn-danger"
                                                            rel="tooltip"
                                                            data-bs-title="Annuler"
                                                            (click)="clearEditChecklistField()"
                                                        >
                                                            <fa-icon [icon]="faCancel"></fa-icon>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            class="btn btn-success"
                                                            rel="tooltip"
                                                            data-bs-title="Sauvegarder"
                                                            (click)="saveEditChecklistField()"
                                                        >
                                                            <fa-icon [icon]="faCheck"></fa-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
