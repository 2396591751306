import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../../services/pagination.service';
import { AclService } from '../../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';

@Component({
  selector: 'app-list-simulateur-aircraft-models',
  templateUrl: './list-simulateur-aircraft-models.component.html',
  styleUrls: ['./list-simulateur-aircraft-models.component.scss']
})
export class SimulateurListAircraftModelsComponent implements OnInit {
  currentPagination: string = 'simulateur-aircraft-models-list';

  EnumAcl = EnumAcl;

  constructor(public paginationService: PaginationService, private aclService: AclService) {}

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.simulateurAircraftModelsList);

    this.fetchData();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async fetchData(): Promise<void> {
    await this.paginationService.fetchData(this.currentPagination);
  }

  formatPrice(value: number, currency: string = 'EUR'): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }
}
