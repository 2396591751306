<div class="row mt-4 justify-content-center">
    <div class="col-sm-6 col-xl-3">
        <div class="form-group">
            <select class="form-select" id="filterByYear" [(ngModel)]="currentYear">
                <option
                    [ngValue]="year.value"
                    *ngFor="let year of yearsList"
                    [innerHtml]="year.title"
                ></option>
            </select>
        </div>
    </div>

    <div class="col-sm-6 col-xl-4 d-flex justify-content-center justify-content-sm-end">
        <ul class="nav nav-pills mb-3" role="tablist">
            <li class="nav-item" role="presentation" *ngFor="let currency of getCurrencies()">
                <button
                    class="nav-link"
                    [class.active]="currentCurrency === currency"
                    [id]="'currency-' + currency"
                    type="button"
                    [attr.aria-selected]="currentCurrency === currency"
                    [innerText]="currency ? getEnumCurrencySymbol(currency) : 'Tous'"
                    (click)="changeCurrentCurrency(currency)"
                ></button>
            </li>
        </ul>
    </div>

    <div class="col-xl-5">
        <ul
            class="nav nav-pills mb-3 d-flex justify-content-center justify-content-xl-end"
            role="tablist"
        >
            <li class="nav-item" role="presentation" *ngFor="let tab of tabs">
                <button
                    class="nav-link"
                    [class.active]="tabOpened === tab.value"
                    [id]="tab.value + '-tab'"
                    data-bs-toggle="tab"
                    [attr.data-bs-target]="'#' + tab.value + '-tab-pane'"
                    type="button"
                    role="tab"
                    [attr.aria-controls]="tab.value + '-tab-pane'"
                    [attr.aria-selected]="tabOpened === tab.value"
                    (click)="updateTabOpened(tab)"
                    [innerText]="tab.title"
                ></button>
            </li>
        </ul>
    </div>
</div>

<div class="tab-content">
    <div
        *ngFor="let tab of tabs; let i = index"
        class="tab-pane fade"
        [class.show]="tabOpened === tab.value"
        [class.active]="tabOpened === tab.value"
        [id]="tab.value + '-tab-pane'"
        role="tabpanel"
        [attr.aria-labelledby]="tab.value + '-tab'"
        tabindex="i"
    >
        <app-financial-report-total
            [currentYear]="currentYear"
            [currentCurrency]="currentCurrency"
            [currentPeriod]="currentPeriod"
            (updateCurrentPeriod)="changeCurrentPeriod($event)"
            *ngIf="tabOpened === EnumFinancialReportTab.total"
        ></app-financial-report-total>

        <app-financial-report-team
            [currentYear]="currentYear"
            [currentCurrency]="currentCurrency"
            [currentPeriod]="currentPeriod"
            (updateCurrentPeriod)="changeCurrentPeriod($event)"
            *ngIf="tabOpened === EnumFinancialReportTab.team"
        ></app-financial-report-team>
        <app-financial-report-department
            [currentYear]="currentYear"
            [currentCurrency]="currentCurrency"
            [currentPeriod]="currentPeriod"
            (updateCurrentPeriod)="changeCurrentPeriod($event)"
            *ngIf="tabOpened === EnumFinancialReportTab.department"
        ></app-financial-report-department>
        <app-financial-report-client
            [currentYear]="currentYear"
            [currentCurrency]="currentCurrency"
            [currentPeriod]="currentPeriod"
            (updateCurrentPeriod)="changeCurrentPeriod($event)"
            *ngIf="tabOpened === EnumFinancialReportTab.clients"
        ></app-financial-report-client>
        <app-financial-report-airline
            [currentYear]="currentYear"
            [currentCurrency]="currentCurrency"
            [currentPeriod]="currentPeriod"
            (updateCurrentPeriod)="changeCurrentPeriod($event)"
            *ngIf="tabOpened === EnumFinancialReportTab.airlines"
        ></app-financial-report-airline>
    </div>
</div>
