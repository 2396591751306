<breadcrumbs
    [items]="
        flightChecklistTemplateId
            ? [
                  {
                      title: 'Liste des modèles de checklist de vols',
                      path: '/admin/flight-checklist-templates/list'
                  },
                  {
                      title: flightChecklistTemplate
                          ? getEnumEnquiryTypeLabel(flightChecklistTemplate.enquiryType)
                          : '',
                      path: '/admin/flight-checklist-templates/view/' + flightChecklistTemplateId
                  },
                  {
                      title: 'Édition d\'un modèle de checklist de vols',
                      path: '/admin/flight-checklist-templates/edit/' + flightChecklistTemplateId
                  }
              ]
            : [
                  {
                      title: 'Liste des modèles de checklist de vols',
                      path: '/admin/flight-checklist-templates/list'
                  },
                  {
                      title: 'Ajout d\'un modèle de checklist de vols',
                      path: '/admin/flight-checklist-templates/add'
                  }
              ]
    "
></breadcrumbs>

<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="row">
        <div class="col-md-4">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="form-group mb-0">
                        <label for="enquiryType">Département</label>
                        <select
                            class="form-select"
                            id="enquiryType"
                            formControlName="enquiryType"
                            [class.is-invalid]="
                                (form.touched || enquiryType.touched) &&
                                enquiryType.status == 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="item"
                                [innerText]="getEnumEnquiryTypeLabel(item)"
                                *ngFor="let item of getEnquiryTypes()"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || enquiryType.touched) &&
                                    enquiryType.errors &&
                                    enquiryType.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card mb-4">
                <div class="card-header">Modules</div>
                <div
                    class="accordion accordion-flush"
                    id="accordion-modules"
                    formArrayName="modules"
                >
                    <div
                        class="accordion-item"
                        *ngFor="let module of modules.controls; let i = index"
                        [formGroupName]="i"
                    >
                        <h2 class="accordion-header">
                            <button
                                class="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#accordion-modules-collapse-' + i"
                                aria-expanded="true"
                                [attr.aria-controls]="'accordion-modules-collapse-' + i"
                                [innerText]="module.value.title || 'Module ' + (i + 1)"
                            ></button>
                        </h2>
                        <div
                            [id]="'accordion-modules-collapse-' + i"
                            class="accordion-collapse collapse show"
                        >
                            <div class="accordion-body">
                                <div class="form-group">
                                    <label [attr.for]="'module-' + i + '-title'">Titre</label>
                                    <div class="btn-group float-end mb-2">
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-sm"
                                            (click)="moveModuleUp(i)"
                                            [disabled]="form.disabled || sending || i === 0"
                                        >
                                            <fa-icon [icon]="faArrowUp"></fa-icon>
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-sm"
                                            (click)="moveModuleDown(i)"
                                            [disabled]="
                                                form.disabled || sending || i >= modules.length - 1
                                            "
                                        >
                                            <fa-icon [icon]="faArrowDown"></fa-icon>
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-danger btn-sm"
                                            (click)="removeModule(i)"
                                            [disabled]="form.disabled || sending"
                                        >
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </div>

                                    <input
                                        type="text"
                                        class="form-control"
                                        [id]="'module-' + i + '-title'"
                                        formControlName="title"
                                        [class.is-invalid]="
                                            (form.touched || getModuleTitle(i).touched) &&
                                            getModuleTitle(i).status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched || getModuleTitle(i).touched) &&
                                                getModuleTitle(i).errors &&
                                                getModuleTitle(i).errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>

                                <div class="card mb-2">
                                    <div class="card-header">Champs</div>
                                    <ul class="list-group list-group-flush" formArrayName="fields">
                                        <li
                                            class="list-group-item"
                                            *ngFor="
                                                let field of getModuleFields(i).controls;
                                                let j = index
                                            "
                                            [formGroupName]="j"
                                        >
                                            <div class="btn-group float-end mb-2">
                                                <button
                                                    type="button"
                                                    class="btn btn-primary btn-sm"
                                                    (click)="moveModuleFieldUp(i, j)"
                                                    [disabled]="form.disabled || sending || j === 0"
                                                >
                                                    <fa-icon [icon]="faArrowUp"></fa-icon>
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-primary btn-sm"
                                                    (click)="moveModuleFieldDown(i, j)"
                                                    [disabled]="
                                                        form.disabled ||
                                                        sending ||
                                                        j >= getModuleFields(i).length - 1
                                                    "
                                                >
                                                    <fa-icon [icon]="faArrowDown"></fa-icon>
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-danger btn-sm"
                                                    (click)="removeModuleField(i, j)"
                                                    [disabled]="form.disabled || sending"
                                                >
                                                    <fa-icon [icon]="faTrash"></fa-icon>
                                                </button>
                                            </div>

                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label
                                                            [attr.for]="
                                                                'module-' +
                                                                i +
                                                                '-fields-' +
                                                                j +
                                                                '-text'
                                                            "
                                                            >Texte</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            [id]="
                                                                'module-' +
                                                                i +
                                                                '-fields-' +
                                                                j +
                                                                '-text'
                                                            "
                                                            formControlName="text"
                                                            [class.is-invalid]="
                                                                (form.touched ||
                                                                    getModuleFieldText(i, j)
                                                                        .touched) &&
                                                                getModuleFieldText(i, j).status ==
                                                                    'INVALID'
                                                                    ? 'danger'
                                                                    : ''
                                                            "
                                                        />

                                                        <div class="invalid-tooltip">
                                                            <div
                                                                *ngIf="
                                                                    (form.touched ||
                                                                        getModuleFieldText(i, j)
                                                                            .touched) &&
                                                                    getModuleFieldText(i, j)
                                                                        .errors &&
                                                                    getModuleFieldText(i, j).errors[
                                                                        'required'
                                                                    ]
                                                                "
                                                            >
                                                                Ce champ est obligatoire.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label
                                                            [attr.for]="
                                                                'module-' +
                                                                i +
                                                                '-fields-' +
                                                                j +
                                                                '-type'
                                                            "
                                                            >Type</label
                                                        >
                                                        <select
                                                            class="form-select"
                                                            [id]="
                                                                'module-' +
                                                                i +
                                                                '-fields-' +
                                                                j +
                                                                '-type'
                                                            "
                                                            formControlName="type"
                                                            [class.is-invalid]="
                                                                (form.touched ||
                                                                    getModuleFieldType(i, j)
                                                                        .touched) &&
                                                                getModuleFieldType(i, j).status ==
                                                                    'INVALID'
                                                                    ? 'danger'
                                                                    : ''
                                                            "
                                                        >
                                                            <option
                                                                [value]="item"
                                                                [innerText]="
                                                                    getFlightChecklistTemplateFieldTypeLabel(
                                                                        item
                                                                    )
                                                                "
                                                                *ngFor="let item of fieldTypes"
                                                            ></option>
                                                        </select>

                                                        <div class="invalid-tooltip">
                                                            <div
                                                                *ngIf="
                                                                    (form.touched ||
                                                                        getModuleFieldType(i, j)
                                                                            .touched) &&
                                                                    getModuleFieldType(i, j)
                                                                        .errors &&
                                                                    getModuleFieldType(i, j).errors[
                                                                        'required'
                                                                    ]
                                                                "
                                                            >
                                                                Ce champ est obligatoire.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="card mb-2"
                                                *ngIf="
                                                    field.value.type ===
                                                    EnumFlightChecklistTemplateFieldType.select
                                                "
                                            >
                                                <div class="card-header">Options</div>
                                                <ul
                                                    class="list-group list-group-flush"
                                                    formArrayName="options"
                                                >
                                                    <li
                                                        class="list-group-item"
                                                        *ngFor="
                                                            let option of getModuleFieldOptions(
                                                                i,
                                                                j
                                                            ).controls;
                                                            let k = index
                                                        "
                                                        [formGroupName]="k"
                                                        [class.list-group-item-warning]="
                                                            option.value.status ===
                                                            EnumFlightChecklistTemplateFieldOptionStatus.warning
                                                        "
                                                        [class.list-group-item-danger]="
                                                            option.value.status ===
                                                            EnumFlightChecklistTemplateFieldOptionStatus.danger
                                                        "
                                                        [class.list-group-item-success]="
                                                            option.value.status ===
                                                            EnumFlightChecklistTemplateFieldOptionStatus.success
                                                        "
                                                        [class.list-group-item-info]="
                                                            option.value.status ===
                                                            EnumFlightChecklistTemplateFieldOptionStatus.info
                                                        "
                                                        [class.list-group-item-primary]="
                                                            option.value.status ===
                                                            EnumFlightChecklistTemplateFieldOptionStatus.primary
                                                        "
                                                        [class.list-group-item-secondary]="
                                                            option.value.status ===
                                                            EnumFlightChecklistTemplateFieldOptionStatus.secondary
                                                        "
                                                    >
                                                        <button
                                                            type="button"
                                                            class="btn btn-danger btn-sm float-end mb-2"
                                                            (click)="
                                                                removeModuleFieldOption(i, j, k)
                                                            "
                                                            [disabled]="form.disabled || sending"
                                                        >
                                                            <fa-icon [icon]="faTrash"></fa-icon>
                                                        </button>
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <div class="form-group">
                                                                    <label
                                                                        [attr.for]="
                                                                            'module-' +
                                                                            i +
                                                                            '-fields-' +
                                                                            j +
                                                                            '-options-' +
                                                                            k +
                                                                            '-text'
                                                                        "
                                                                        >Texte</label
                                                                    >

                                                                    <input
                                                                        type="text"
                                                                        class="form-control"
                                                                        [id]="
                                                                            'module-' +
                                                                            i +
                                                                            '-fields-' +
                                                                            j +
                                                                            '-options-' +
                                                                            k +
                                                                            '-text'
                                                                        "
                                                                        formControlName="text"
                                                                        [class.is-invalid]="
                                                                            (form.touched ||
                                                                                getModuleFieldOptionField(
                                                                                    i,
                                                                                    j,
                                                                                    k,
                                                                                    'text'
                                                                                ).touched) &&
                                                                            getModuleFieldOptionField(
                                                                                i,
                                                                                j,
                                                                                k,
                                                                                'text'
                                                                            ).status == 'INVALID'
                                                                                ? 'danger'
                                                                                : ''
                                                                        "
                                                                    />

                                                                    <div class="invalid-tooltip">
                                                                        <div
                                                                            *ngIf="
                                                                                (form.touched ||
                                                                                    getModuleFieldOptionField(
                                                                                        i,
                                                                                        j,
                                                                                        k,
                                                                                        'text'
                                                                                    ).touched) &&
                                                                                getModuleFieldOptionField(
                                                                                    i,
                                                                                    j,
                                                                                    k,
                                                                                    'text'
                                                                                ).errors &&
                                                                                getModuleFieldOptionField(
                                                                                    i,
                                                                                    j,
                                                                                    k,
                                                                                    'text'
                                                                                ).errors['required']
                                                                            "
                                                                        >
                                                                            Ce champ est
                                                                            obligatoire.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <div class="form-group">
                                                                    <label
                                                                        [attr.for]="
                                                                            'module-' +
                                                                            i +
                                                                            '-fields-' +
                                                                            j +
                                                                            '-options-' +
                                                                            k +
                                                                            '-status'
                                                                        "
                                                                        >Status</label
                                                                    >

                                                                    <select
                                                                        class="form-select"
                                                                        [id]="
                                                                            'module-' +
                                                                            i +
                                                                            '-fields-' +
                                                                            j +
                                                                            '-options-' +
                                                                            k +
                                                                            '-status'
                                                                        "
                                                                        formControlName="status"
                                                                        [class.is-invalid]="
                                                                            (form.touched ||
                                                                                getModuleFieldOptionField(
                                                                                    i,
                                                                                    j,
                                                                                    k,
                                                                                    'status'
                                                                                ).touched) &&
                                                                            getModuleFieldOptionField(
                                                                                i,
                                                                                j,
                                                                                k,
                                                                                'status'
                                                                            ).status == 'INVALID'
                                                                                ? 'danger'
                                                                                : ''
                                                                        "
                                                                    >
                                                                        <option
                                                                            [value]="item"
                                                                            [innerText]="
                                                                                getFlightChecklistTemplateFieldOptionStatusLabel(
                                                                                    item
                                                                                )
                                                                            "
                                                                            *ngFor="
                                                                                let item of optionStatuses
                                                                            "
                                                                        ></option>
                                                                    </select>

                                                                    <div class="invalid-tooltip">
                                                                        <div
                                                                            *ngIf="
                                                                                (form.touched ||
                                                                                    getModuleFieldOptionField(
                                                                                        i,
                                                                                        j,
                                                                                        k,
                                                                                        'status'
                                                                                    ).touched) &&
                                                                                getModuleFieldOptionField(
                                                                                    i,
                                                                                    j,
                                                                                    k,
                                                                                    'status'
                                                                                ).errors &&
                                                                                getModuleFieldOptionField(
                                                                                    i,
                                                                                    j,
                                                                                    k,
                                                                                    'status'
                                                                                ).errors['required']
                                                                            "
                                                                        >
                                                                            Ce champ est
                                                                            obligatoire.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item text-center">
                                                        <button
                                                            type="button"
                                                            class="btn btn-primary"
                                                            (click)="addModuleFieldOption(i, j)"
                                                            [disabled]="
                                                                form.disabled ||
                                                                sending ||
                                                                getModuleFieldOptions(i, j).disabled
                                                            "
                                                        >
                                                            <fa-icon [icon]="faPlus"></fa-icon>
                                                            Ajouter option
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div
                                                class="form-group"
                                                *ngIf="
                                                    field.value.type ===
                                                    EnumFlightChecklistTemplateFieldType.select
                                                "
                                            >
                                                <label
                                                    [attr.for]="
                                                        'module-' +
                                                        i +
                                                        '-fields-' +
                                                        j +
                                                        '-defaultOptionId'
                                                    "
                                                    >Option par défaut</label
                                                >

                                                <select
                                                    class="form-select"
                                                    [id]="
                                                        'module-' +
                                                        i +
                                                        '-fields-' +
                                                        j +
                                                        '-defaultOptionId'
                                                    "
                                                    formControlName="defaultOptionId"
                                                    [class.is-invalid]="
                                                        (form.touched ||
                                                            getModuleFieldDefaultOptionId(i, j)
                                                                .touched) &&
                                                        getModuleFieldDefaultOptionId(i, j)
                                                            .status == 'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                >
                                                    <option [ngValue]="null">
                                                        -- Choisissez --
                                                    </option>
                                                    <option
                                                        [value]="option.id"
                                                        [innerText]="option.text"
                                                        *ngFor="
                                                            let option of getModuleFieldOptions(
                                                                i,
                                                                j
                                                            ).value
                                                        "
                                                    ></option>
                                                </select>

                                                <div class="invalid-tooltip">
                                                    <div
                                                        *ngIf="
                                                            (form.touched ||
                                                                getModuleFieldDefaultOptionId(i, j)
                                                                    .touched) &&
                                                            getModuleFieldDefaultOptionId(i, j)
                                                                .errors &&
                                                            getModuleFieldDefaultOptionId(i, j)
                                                                .errors['required']
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="form-group"
                                                *ngIf="
                                                    field.value.type ===
                                                    EnumFlightChecklistTemplateFieldType.select
                                                "
                                            >
                                                <label
                                                    [attr.for]="
                                                        'module-' +
                                                        i +
                                                        '-fields-' +
                                                        j +
                                                        '-finalOptionId'
                                                    "
                                                    >Option de fin</label
                                                >
                                                <div>
                                                    <small class="text-muted">
                                                        Option qui est la dernière étape complétée
                                                    </small>
                                                </div>

                                                <select
                                                    class="form-select"
                                                    [id]="
                                                        'module-' +
                                                        i +
                                                        '-fields-' +
                                                        j +
                                                        '-finalOptionId'
                                                    "
                                                    formControlName="finalOptionId"
                                                    [class.is-invalid]="
                                                        (form.touched ||
                                                            getModuleFieldFinalOptionId(i, j)
                                                                .touched) &&
                                                        getModuleFieldFinalOptionId(i, j).status ==
                                                            'INVALID'
                                                            ? 'danger'
                                                            : ''
                                                    "
                                                >
                                                    <option [ngValue]="null">
                                                        -- Choisissez --
                                                    </option>
                                                    <option
                                                        [value]="option.id"
                                                        [innerText]="option.text"
                                                        *ngFor="
                                                            let option of getModuleFieldOptions(
                                                                i,
                                                                j
                                                            ).value
                                                        "
                                                    ></option>
                                                </select>

                                                <div class="invalid-tooltip">
                                                    <div
                                                        *ngIf="
                                                            (form.touched ||
                                                                getModuleFieldFinalOptionId(i, j)
                                                                    .touched) &&
                                                            getModuleFieldFinalOptionId(i, j)
                                                                .errors &&
                                                            getModuleFieldFinalOptionId(i, j)
                                                                .errors['required']
                                                        "
                                                    >
                                                        Ce champ est obligatoire.
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="list-group-item text-center">
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                (click)="addModuleField(i)"
                                                [disabled]="
                                                    form.disabled ||
                                                    sending ||
                                                    getModuleFields(i).disabled
                                                "
                                            >
                                                <fa-icon [icon]="faPlus"></fa-icon> Ajouter champ
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-center">
                    <button
                        type="button"
                        class="btn btn-primary"
                        (click)="addModule()"
                        [disabled]="form.disabled || sending || modules.disabled"
                    >
                        <fa-icon [icon]="faPlus"></fa-icon> Ajouter module
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="
                    flightChecklistTemplateId
                        ? '/admin/flight-checklist-templates/view/' + flightChecklistTemplateId
                        : '/admin/flight-checklist-templates/list'
                "
                class="btn btn-outline-primary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>
